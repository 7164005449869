import axios from 'axios';
import { SevenApiRoutes } from '@/modules/seven';
import type { OperatorCardDataInfo } from '@/modules/card-reader/types';
import axiosInstance from './axiosInstance';

import type {
  BindUserCardData,
  GetUsersResponse,
  LoggedUserData,
  UserCardDetails,
} from './types';

const getOperators = (listFilter?: { search: string }) => axiosInstance.get<GetUsersResponse>(`${SevenApiRoutes.routes.mainUrl}/terminal-admin/users/operators.json`, {
  params: listFilter,
});

const getUserByUuid = (uuid: string, listFilter?: Record<string, any>) => axiosInstance.get<GetUsersResponse>(`${SevenApiRoutes.routes.mainUrl}/terminal-admin/users/uuid/${uuid}.json`, {
  params: listFilter,
});

const bindCard = (data: BindUserCardData) => axiosInstance.post<OperatorCardDataInfo>(`${SevenApiRoutes.routes.mainUrl}/terminal-admin/users/operators/cards/bind.json`, data);

const unbindCard = (data: UserCardDetails) => axiosInstance.delete<void>(`${SevenApiRoutes.routes.mainUrl}/terminal-admin/users/operators/cards/unbind.json`, { data });

const lockCard = (data: UserCardDetails) => axiosInstance.patch<void>(`${SevenApiRoutes.routes.mainUrl}/terminal-admin/users/operators/cards/lock.json`, data);

const unlockCard = (data: UserCardDetails) => axiosInstance.patch<void>(`${SevenApiRoutes.routes.mainUrl}/terminal-admin/users/operators/cards/unlock.json`, data);

const dispatchLoggedUser = (data: LoggedUserData): Promise<void> => axios.post(
  process.env.LOCAL_SCM_PUBLISH_URL,
  {
    channel: 'userLogin',
    type: 'userLoginEvent',
    data,
  },
);

export {
  bindCard,
  unbindCard,
  lockCard,
  unlockCard,
  dispatchLoggedUser,
  getOperators,
  getUserByUuid,
};
