import axios from 'axios';
import * as gravityInterceptor from '@/modules/interceptors/gravityInterceptor';

const REQ_TIMEOUT = 5000;
const defaultErrorHandler = (error: any) => Promise.reject(error);

const instance = axios.create({
  baseURL: process.env.GRAVITY_API_URL,
  timeout: REQ_TIMEOUT,
});

instance.interceptors.request.use(gravityInterceptor.requestSuccess, defaultErrorHandler);

const cfInstance = axios.create({
  baseURL: process.env.GRAVITY_API_URL_CF,
  timeout: REQ_TIMEOUT,
});

export {
  instance,
  cfInstance,
};
