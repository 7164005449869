<script setup lang="ts">
import PubSub from 'pubsub-js';
import { onUnmounted, ref } from 'vue';
import { SCButton } from '@nsftx/seven-components';

const acceptorAmount = ref(0);

const acceptorAmounChangeListener = PubSub.subscribe('Acceptor.Amount.Change', (e, data) => {
  acceptorAmount.value = data;
});

onUnmounted(() => {
  PubSub.unsubscribe(acceptorAmounChangeListener);
});
</script>

<template>
  <SCButton
    v-if="acceptorAmount"
    class="h-100 sc-overlay-default"
  >
    {{ acceptorAmount }}
  </SCButton>
</template>
