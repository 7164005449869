const widgetTypesList = [
  'PRODUCT_HOME',
  'TICKET_DETAILS',
  'TICKETS_HISTORY',
  'PRODUCT_RESULTS',
  'GENERIC_IFRAME',
] as const;

type WidgetTypes = typeof widgetTypesList[number];

enum WidgetSourceType {
  IFRAME = 'IFRAME',
  VUE_COMPONENT = 'VUE_COMPONENT',
}

type WidgetSource = {
  type: WidgetSourceType,
  url?: string,
  config?: {
    frameId: string,
    useGateway?: boolean,
    destroy?: boolean,
  }
};

type Widget = {
  name: string,
  order: number,
  source: WidgetSource,
  when?: {
    condition: string,
    values: Array<string>
  },
  type?: WidgetTypes,
  active?: boolean,
  component?: Object,
  positions?: Array<{ id: string }>,
  shouldRender?: boolean,
};

export type {
  Widget,
  WidgetTypes,
};

export {
  WidgetSourceType,
};
