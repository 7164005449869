import { computed } from 'vue';
import router from '@/router';
import { getService } from '@/common/services/ngBridge';
import { useWidgetsStore, WidgetSourceType } from '@/common/stores/widgets';
import LobbyFooterIcon from './components/LobbyFooterIcon.vue';
import { useGravityMenusStore } from '../cms/menus';
import type { CmsMenuItem } from '../cms/menus/types';
import type { LobbySettings } from './types';

const LS_KEY = 'settings.lobby';

const isVueRoute = computed(() => !!router.currentRoute.value.matched.length);
const isModalShown = computed(() => !!router.currentRoute.value.query.lobby);

export const setIsModalShown = (value: boolean) => {
  const $state: any = getService('$state');

  if (isVueRoute.value) {
    router.push({
      query: {
        lobby: value ? 'true' : undefined,
      },
    });
    return;
  }

  if ($state && $state.href($state.current.name)) {
    $state.go($state.current.name, {
      lobby: value ? 'true' : undefined,
    });
  }
};

export const getIsModalShown = (): boolean => isModalShown.value;

export const getSettings = (): LobbySettings => (
  JSON.parse(localStorage.getItem(LS_KEY) as string) || {}
);
export const updateSettings = (data: LobbySettings) => {
  localStorage.setItem(LS_KEY, JSON.stringify(data));
};

export const getMenu = (): CmsMenuItem[] => {
  const gravityMenusStore = useGravityMenusStore();
  const menuItems: CmsMenuItem[] = [...gravityMenusStore.getMenu('lobby')];
  return menuItems;
};

export const isActive = (): boolean => !!getMenu().length;

export const shouldOpenLobbyOnTerminalLoad = (): boolean => (
  isActive() && !!getSettings().openLobbyOnLoad
);
export const shouldOpenLobbyAfterIdleTime = (): boolean => (
  isActive() && !!getSettings().openLobbyAfterIdleTime
);

export const registerWidgets = () => {
  const widgetsStore = useWidgetsStore();
  widgetsStore.registerWidget({
    name: 'LobbyIcon',
    component: LobbyFooterIcon,
    order: 2,
    source: {
      type: WidgetSourceType.VUE_COMPONENT,
    },
    positions: [
      {
        id: 'footerLeft',
      },
    ],
  });
};

export const init = () => {
  registerWidgets();
};
