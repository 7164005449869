import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  cardInEventPubSub,
  CARD_IN_EVENT_ID,
  cardOutEventPubSub,
  CARD_OUT_EVENT_ID,
  cardReadEventPubSub,
  CARD_READ_EVENT_ID,
} from './cardReaderEventsService';
import { CardStatus } from './enums';
import type { SocketCardDataIn } from './types';
import type { CardDataInfo } from './interfaces';

const useCardReaderStore = defineStore('cardReaderStore', () => {
  const currentStatus = ref<string>(CardStatus.CARD_OUT);
  const cardData = ref<CardDataInfo>();

  // this is specific for NAR
  const onCardIn = (data: SocketCardDataIn) => {
    currentStatus.value = CardStatus.CARD_IN;
    cardInEventPubSub.publish(CARD_IN_EVENT_ID, data);
  };

  const onCardOut = () => {
    currentStatus.value = CardStatus.CARD_OUT;
    cardOutEventPubSub.publish(CARD_OUT_EVENT_ID);
    cardData.value = undefined;
  };

  const onCardRead = (data: CardDataInfo | undefined) => {
    cardData.value = data;
    cardReadEventPubSub.publish(CARD_READ_EVENT_ID, data);
    currentStatus.value = CardStatus.CARD_IN;
  };

  const getCurrentStatus = () => currentStatus.value;

  const setCurrentStatus = (cardStatus: string) => {
    currentStatus.value = cardStatus;
  };

  const getStatuses = () => CardStatus;

  const isCardIn = () => currentStatus.value === CardStatus.CARD_IN;

  return {
    onCardIn,
    onCardOut,
    getCurrentStatus,
    setCurrentStatus,
    getStatuses,
    isCardIn,
    onCardRead,
    cardData,
  };
});

export {
  useCardReaderStore,
};
