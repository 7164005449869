<script lang="ts" setup>
import { computed } from 'vue';
import router from '@/router';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import LobbyHeaderTitle from './LobbyHeaderTitle.vue';

const showLobbyHeader = computed(() => router.currentRoute.value.name === 'LobbyItem');

const redirectToHomePage = () => router.push({ name: 'Home' });
</script>

<template>
  <div
    v-if="showLobbyHeader"
    class="d-flex align-items-center justify-content-between w-100"
  >
    <LobbyHeaderTitle />
    <SCButton
      :variant="ButtonVariant.Primary"
      @click="redirectToHomePage"
    >
      {{ $t('terminal_lobby_back_to_betting_area') }}
    </SCButton>
  </div>
</template>
