/* eslint-disable angular/controller-name, angular/controller-as */

(function () {
  angular.module('7Terminal.Betslip')
    .controller('betSlipController', function betSlipController(
      $scope,
      $rootScope,
      BetslipService,
      NabNotifications,
      SevenGamesSvc,
      nabTrans,
      BetslipConfig,
      LimitsService
    ) {
      var activeGameWatcher;
      var numPadListener = $rootScope.$on('NumPad:setInputValue', function (e, data) {
        var value = data.value;

        // Added equal stake check currently used only for L6
        if (data.id === 'equalStake') value = BetslipService.calculateEqualStake(data.value);

        BetslipService.updateStake(
          value,
          {
            stakeTouched: true
          }
        );
      });

      var onPayinFailed = $rootScope.$on('7T:Tickets.PayingFailed', function (e, message) {
        handleTicketError(message);
      });

      /**
             * Update current active ticket meta
             *
             * @param e
             * @param {Object} eventData
             * @param {Object} eventData.data
             * @param {Number} eventData.data.stake
             */
      var updateMeta = $rootScope.$on('7T:Betslip.UpdateMeta', function (e, eventData) {
        if (eventData.data && angular.isDefined(eventData.data.stake)) {
          BetslipService.updateStake(
            eventData.data.stake,
            {
              stakeTouched: true
            }
          );
        }
      });

      $scope.betslip = BetslipService;
      $scope.predefinedAmount = BetslipConfig.moneyDenominations;
      $scope.limitActive = false;
      $scope.keys = Object.keys;
      $scope.activeGame = SevenGamesSvc.activeGame;

      if ($scope.betslip.ticket) {
        $scope.betslip.ticket.lastFocused = false;
      }

      $scope.roundEqualStake = function (value) {
        $scope.activeGame.gameInfo.equalStake.equalStakeAmount = value.toFixed(2);
      };

      /**
            * Get stake as ticket stake changes
            * @returns {String}
            */
      $scope.stake = function () {
        if (!BetslipService.ticket) return Number(1).toFixed(2); // Fallback if ticket failed to load

        if (angular.isDefined(BetslipService.ticket.stake)) {
          return BetslipService.ticket.stake.toFixed(2);
        }
        return BetslipService.ticket.config.stake.toFixed(2);
      };

      /**
             * Activate tab
             * @param {String} betslipId
             * @param {String} ticketId
             */
      $scope.activateTab = function (betslipId, ticketId) {
        BetslipService.activateTicket(
          betslipId,
          ticketId
        );
      };

      $scope.showNumPad = function () {
        $rootScope.$emit('NumPad:focusInput', {
          id: 'betslipAmount',
          value: BetslipService.ticket.stake
        });
      };

      $scope.setMaxStake = function () {
        if (SevenGamesSvc.getActiveGame().info.local.frame) {
          $scope.emitBetslipEvent('MaxStake', {
            ticket: BetslipService.ticket,
            productId: BetslipService.ticket.config.group.id
          });
        } else {
          BetslipService.setMaxStake();
        }
      };

      /**
             * Add predefined amount to stake
             * @param amount
             */
      $scope.addAmount = function (amount) {
        BetslipService.updateStake(
          amount,
          {
            stakeTouched: true
          }
        );
      };

      $scope.$on(
        '7T:Betslip.AddBet',
        /**
                 *
                 * @param e
                 * @param {Object} data
                 * @param {Object} data.data
                 * @param {Array.<Bet>} data.data.bets
                 * @param {Number} data.data.stake
                 */
        function (e, data) {
          $rootScope.$evalAsync(function () {
            var addedBets = BetslipService.addToBetslip(data.data.bets, data.data.stake);

            /**
                         * Emits event about new added bet
                         *
                         * @event "7T:Betslip.BetAdded"
                         * @type {Object}
                         * @property {String} action
                         * @property {Object} data
                         * @property {Object} data.addedBets
                         * @property {Array} data.ticket
                         * @property {Object} data.productId
                         */
            $rootScope.$broadcast('7T:Betslip.BetAdded', {
              addedBets: addedBets,
              ticket: BetslipService.ticket,
              productId: BetslipService.ticket.config.ticketGroup
            });
          });
        }
      );

      /**
             * Reset bet-slip of current game
             * to its previous state
             */
      $scope.resetBetslip = function () {
        var groupId = BetslipService.ticket.config.group.id;
        // reset bet-slip services
        BetslipService.resetBetslip({
          id: groupId
        });

        BetslipService.activateTicket(
          groupId,
          false
        );

        BetslipService.setOddChangeValue();
        // hide bet-slip area
        // note: only in portrait mode
        BetslipService.toggleBetslip(false, false);
        $rootScope.$emit('NumPad:clearAll');
      };

      $scope.removeBet = function (idx) {
        BetslipService.removeFromBetslip(idx);
        if (getBetsToRemove().length === 0) BetslipService.ticket.locked = false;
      };

      /**
             * Set bet as focused and broadcast event
             * @param {Object} bet
             * @param {Number} index
             */
      $scope.onBetFocus = function (bet, index) {
        if (bet.disableEdit) return;

        BetslipService.toggleBetslip();
        BetslipService.ticket.bets.forEach(function (b, i) {
          b.focus = (index === i) ? !b.focus : false;
        });
        $scope.betslip.ticket.lastFocused = bet.focus ? bet.uid : false;

        $rootScope.$broadcast(
          '7T:Betslip.BetSelected',
          {
            bet: bet,
            ticket: BetslipService.ticket,
            productId: BetslipService.ticket.config.ticketGroup
          }
        );
      };

      function handleTicketError(message) {
        var removalErrorCodes = ['betExistance', 'betUnlocked'];
        var betsToRemove = [];
        var i = 0;

        if (!message) {
          return;
        }

        for (i; i < message.errors.length; i++) {
          if (removalErrorCodes.indexOf(message.errors[i].code) !== -1) {
            betsToRemove.push(message.errors[i].transRef);
          }
        }

        /**
                 * Display error actions only if it is on first place in array of errors
                 */
        if (betsToRemove.length !== 0 || (removalErrorCodes.indexOf(message.errors[0].code) !== -1)) {
          handleInactiveBets(betsToRemove, message.errorMsg);
        } else {
          NabNotifications.show({
            message: message.errorMsg,
            type: 'warning',
            delay: 3000,
            id: message.errorMsg
          });
        }
      }

      function removeInactiveBets(bets) {
        var betFound;
        var betCollection = BetslipService.ticket.betsCollection;

        bets.forEach(function (bet) {
          betFound = betCollection.getFirstBy(
            bet.idMbo,
            'idMbo'
          );

          if (betFound) {
            BetslipService.removeFromBetslip(betFound.uid);
          }
        });
      }

      function handleInactiveBets(bets, errorMsg) {
        var autoPayinAlternateStake = BetslipService.ticket.config.autoPayinAlternateStake;
        NabNotifications.show({
          message: errorMsg,
          type: 'warning',
          showDefaultAction: true,
          actions: [
            {
              callback: function () {
                removeInactiveBets(bets);

                if (autoPayinAlternateStake) {
                  $scope.doPayin();
                }
              },
              autoClose: true,
              className: 'cancel_btn',
              text: autoPayinAlternateStake ? nabTrans.translate('actions.accept_and_proceed', {}, true)
                : nabTrans.translate('actions.accept', {}, true)
            }

          ]
        });
      }

      $scope.emitBetslipEvent = function (eventName, eventData) {
        $rootScope.$emit('7T:Betslip.' + eventName, eventData);
      };

      /**
             * !important - throttle is added because sometimes payin is triggered twice in same ms (probbably nw shit)
             */
      $scope.doPayin = _.throttle(function () {
        BetslipService.validatePayinAction().then(
          function () {
            NabNotifications.closeNotificationWithId('ticket.odds_changed');

            $rootScope.$emit('7T:Betslip.Payin', {
              productId: BetslipService.ticket.config.group.id,
              ticket: BetslipService.ticket
            });
          },
          function (response) {
            NabNotifications.show({
              message: response.message,
              type: response.type,
              closeDisabled: response.closeDisabled || false,
              delay: response.delay || 0,
              id: response.notificationId || ''
            });
          }
        );
      }, 300);

      $scope.acceptAndPayin = function () {
        removeLockedBets();
      };

      function removeLockedBets() {
        var i = 0;
        var betsToRemove = getBetsToRemove();

        for (i; i < betsToRemove.length; i++) BetslipService.removeFromBetslip(betsToRemove[i]);

        BetslipService.ticket.locked = false;

        $scope.doPayin();
      }

      function getBetsToRemove() {
        var betsToRemove = [];
        var betCollection = BetslipService.ticket.bets;
        betCollection.forEach(function (bet) {
          if (bet.locked) betsToRemove.push(bet.uid);
        });
        return betsToRemove;
      }

      const limitWatch = $rootScope.$watchGroup([function () {
        return LimitsService.getGeneralLimit();
      }, function () {
        return LimitsService.getGameLimit();
      }], function () {
        // TODO: use BetslipService.ticket.group.id instead of activeGame,
        // we need to get rid of SevenGamesSvc from betslip module.
        // I don't want to do this right now
        var activeGameId = SevenGamesSvc.getActiveGameId();
        $scope.limitActive = LimitsService.checkLimits(activeGameId);
      });

      $scope.$watch('betslip.oddsChanged', function (newVal) {
        if (!newVal) {
          BetslipService.setOddChangeValue();
        }
      });

      // TODO: We should emit game is activated event (just like in 7Shop)
      // and here should be $on callback. We must get rid of SevenGamesSvc
      // from betslip module
      activeGameWatcher = $rootScope.$watch(function () {
        return SevenGamesSvc.getActiveGameId();
      }, function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          BetslipService.setOddChangeValue();
        }
      });

      $scope.$on('$destroy', function () {
        numPadListener();
        onPayinFailed();
        updateMeta();
        activeGameWatcher();
        limitWatch();
        BetslipService.setOddChangeValue();
      });
    });
})();
