import { useSevenStore } from '@/modules/seven';
import { localAppConfigService } from '@/common/services/local-app-config';
import type { GravitySendSystemNotificationData, GravitySystemNotificationData } from './types';
import axiosInstance from './axiosInstance';

const sendSystemNotification = (data: GravitySystemNotificationData) => {
  const sevenStore = useSevenStore();
  const { title, body, type } = data;

  return axiosInstance
    .post<GravitySendSystemNotificationData>(
    '/terminal',
    {
      context: sevenStore.tenant.uuid,
      reference: [`Betshop.${sevenStore.betshop.uuid}`],
      title,
      body,
      type,
    },
    {
      headers: {
        'X-Nsft-SCD-Terminal-Uuid': localAppConfigService.data.appConfig.terminalAccount.uuid,
        'X-Nsft-SCD-Company-Uuid': sevenStore.tenant.uuid,
        Authorization: `Basic ${window.btoa(`${localAppConfigService.data.appConfig.terminalAccount.uuid}:${
          localAppConfigService.data.appConfig.terminalAccount.password}`)}`,
      },
    },
  );
};

export {
  sendSystemNotification,
};
