import {
  ProductTicketResponse,
  TicketInstance,
  TicketResponse,
  betslipApiHelpers,
} from '@/modules/seven-betslip-api';
import { SubscribeCallback } from '@nsftx/seven-gravity-gateway/master';
import { payoutService, ticketValidationService } from '@/modules/tickets';
import { errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import { useTicketCheckStore } from '@/modules/ticket-check';
import { isTboActive } from '@/common/services/helpers';
import { tboPayoutService } from '@/modules/tbo-tickets';
import type { IntegratorTicketResponse, GravityAsyncEventReject, GravityAsyncEventResolve } from '../types';

const LOG_PREFIX = '[gravityGatewayPayoutService]';

const decorateResponseForGame = (
  localTicket: TicketInstance,
  ticketResponse: TicketResponse | ProductTicketResponse,
) => {
  const ticketPin = localTicket.getPin();
  const res = ticketResponse as IntegratorTicketResponse;
  if (betslipApiHelpers.isV2inCms(res)) {
    res.slip.ticketPin = ticketPin;
  } else {
    res.ticketPin = ticketPin;
  }

  if (localTicket.taxData) {
    res.tax = localTicket.taxData;
  }

  return res;
};

const handleGatewayPayoutEvent: SubscribeCallback<
{ ticket: TicketResponse | ProductTicketResponse },
GravityAsyncEventResolve<{ ticket: IntegratorTicketResponse }>,
GravityAsyncEventReject
> = (data) => {
  const ticket = betslipApiHelpers.createTicket(data.data.ticket);

  logService.debug(`${LOG_PREFIX} Tickets.Payout event received`, {
    data: data.data,
    slave_id: data.slaveId,
    product_displayid: ticket.getProductDisplayId(),
    ticket_code: ticket.getDisplayId(),
    request_id: ticket.getRequestUuid(),
    code: 'T_INTEGRATOR_PAYOUT_EVENT',
  });

  const payoutTicket = isTboActive() ? tboPayoutService.payoutTicket : payoutService.payoutTicket;

  setTimeout(() => {
    // We may have scenario when user scans ticket which has PIN
    // bundled with code, ex. "X621G6XH6.2667".
    // So we first need to get this pin "2667" from store (which is set in the moment when
    // ticket is scanned), and then attach it to received ticket.
    const ticketCheckStore = useTicketCheckStore();
    const pin = ticketCheckStore.getTicketPin();
    ticketCheckStore.setResult(ticket);

    if (pin) {
      ticket.setPin(pin);
    }

    payoutTicket(ticketCheckStore.result as TicketInstance).then((res) => {
      const { localTicket } = res;
      const gameId = localTicket.getProductDisplayId();
      const reqUuid = localTicket.getRequestUuid();
      const ticketId = localTicket.getDisplayId();

      logService.info(`${LOG_PREFIX} Payout resolved`, {
        request_id: reqUuid,
        product_displayid: gameId,
        ticket_code: ticketId,
        code: 'T_INTEGRATOR_PAYOUT_SUCCESS',
      });

      const decorated = decorateResponseForGame(localTicket, data.data.ticket);
      data.resolve?.({
        ticket: decorated,
      });
    }).catch((payoutError) => {
      const { code, message } = payoutError;
      logService.warn(`${LOG_PREFIX} Payout rejected`, {
        upstream_message: message,
        upstream_code: code,
        request_id: ticket.getRequestUuid(),
        product_displayid: ticket.getProductDisplayId(),
        ticket_code: ticket.getDisplayId(),
        code: 'T_INTEGRATOR_PAYOUT_REJECTED',
      });
      data.reject?.(payoutError);
    });
  });
};

const handleGatewayValidatePayoutEvent: SubscribeCallback<
{ ticket: TicketResponse | ProductTicketResponse },
GravityAsyncEventResolve<{ ticket: IntegratorTicketResponse }>,
GravityAsyncEventReject
> = (data) => {
  const ticketCheckStore = useTicketCheckStore();
  const localTicket = betslipApiHelpers.createTicket(data.data.ticket);
  const pin = ticketCheckStore.getTicketPin();
  ticketCheckStore.setResult(localTicket);
  if (pin) {
    localTicket.setPin(ticketCheckStore.getTicketPin());
  }

  setTimeout(() => {
    ticketValidationService.validatePayout(localTicket)
      .then((response) => {
        const { ticket } = response;
        data.resolve?.({ ticket: decorateResponseForGame(ticket, data.data.ticket) });

        logService.info(`${LOG_PREFIX} Payout validate event resolved.`, {
          product_displayid: ticket.getProductDisplayId(),
          ticket_code: ticket.getDisplayId(),
          request_id: ticket.getRequestUuid(),
          code: 'T_INTEGRATOR_VALIDATE_PAYOUT_SUCCESS',
        });
      }).catch((error) => {
        data.reject?.(error);

        logService.warn(`${LOG_PREFIX} ValidatePayout error detected. ${data.action} event rejected.`, {
          localTicket,
          product_displayid: localTicket.getProductDisplayId(),
          ticket_code: localTicket.getDisplayId(),
          request_id: localTicket.getRequestUuid(),
          ...errorParser.parseUpstream(error),
          code: 'T_INTEGRATOR_VALIDATE_PAYOUT_FAILED',
        });
        ticketCheckStore.clearTicketPin();
      });
  });
};

export {
  handleGatewayValidatePayoutEvent,
  handleGatewayPayoutEvent,
};
