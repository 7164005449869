import { InternalAxiosRequestConfig } from 'axios';
import { useSevenStore } from '@/modules/seven';

const isValidUrl = (url: string) => /cashout/.test(url);

export const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (isValidUrl(request.url || '') && request.headers) {
    const sevenStore = useSevenStore();
    request.headers['HTTP-X-SEVEN-CLUB-UUID'] = sevenStore.tenant.uuid;
  }

  return request;
};
