export default function runBlock(
  $rootScope,
  loaderService
) {
  'ngInject';

  /**
  * Show/hide loader
  *
  * @event "7T:Widget.Loading"
  * @type {Object}
  * @memberOf module:'7Terminal.Common'
  * @property {Object} data
  * @property {Boolean} data.isLoading
  */
  $rootScope.$on('7T:Widget.Loading', function (event, eventData) {
    if (eventData.data.isLoading) {
      loaderService.showLoader();
    } else {
      loaderService.endLoader();
    }
  });
}
