import templateUrl from './betslipToggle.html';

(function () {
  angular.module('7Terminal.Betslip')
    .component('betSlipToggle', {
      templateUrl: templateUrl,
      controller: betSlipToggleCtrl
    });

  function betSlipToggleCtrl(BetslipService) {
    var ctrl = this;
    ctrl.$onInit = function () {
      ctrl.betslip = BetslipService;
    };

    ctrl.toggleBetslip = function () {
      BetslipService.toggleBetslip();
    };
  }
})();
