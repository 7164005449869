import master from '@nsftx/seven-gravity-gateway/master';

/**
 *
 * @ngInject
 */
function rootScopeDecorator($delegate, $log) {
  var Scope = $delegate.constructor;
  var origBroadcast = Scope.prototype.$broadcast;
  var origEmit = Scope.prototype.$emit;
  var eventWhiteList = [
    '7T:Slave.Shown',
    '7T:UI.Show',
    '7T:Betslip.Created',
    '7T:Betslip.TicketActivated',
    '7T:Betslip.BetsUpdated',
    '7T:Betslip.BetSelected',
    '7T:Betslip.SelectedBetUpdated',
    '7T:Betslip.Reset',
    '7T:Betslip.Payin',
    '7T:Betslip.BetAdded',
    '7T:Betslip.MaxStake',
    '7T:Betslip.BetRemoved',
    '7T:Betslip.ReCreated',
    '7T:Betslip.Blocked',
    '7T:Betslip.Unblocked',
    '7T:Tickets.PrePrint',
    '7T:Tickets.ReBet',
    '7T:Ticket.CheckWebCode',
    '7T:Tickets.AddSuccess',
    '7T:Widget.Event',
    '7T:User.BalanceChanged',
    '7T:Tickets.Payout',
    '7T:Tickets.Cancel',
    '7T:Peripherals.StateChanged',
    '7T:Tickets.Checked',
    '7T:Tickets.ValidatePayout',
    '7T:Tickets.ValidateCancel',
    '7T:Tickets.ValidatePayin',
    '7T:Widget.SettingsChanged',
    '7T:Slave.Mute',
    '7T:Slave.Snooze',
    '7T:Slave.Unmute',
    '7T:Slave.Awake',
    '7T:Tickets.FetchHistory',
    '7T:User.AuthorizationChanged'
  ];
    // force events althogut snooz is active
  var forceEvents = [
    'Tickets.PrePrint',
    'Betslip.Blocked',
    'Betslip.Unblocked',
    'Peripherals.StateChanged',
    'Tickets.ValidatePayout',
    'Tickets.ValidateCancel',
    'Tickets.ValidatePayin',
    'Widget.SettingsChanged',
    'User.BalanceChanged',
    'User.AuthorizationChanged'
  ];
  var warnMessageSent = false;

  function prepareData(data) {
    var dataToSend = angular.copy(data);

    // we must send pure json, without any methods
    if (dataToSend !== null
                  && typeof dataToSend === 'object') {
      delete dataToSend.productId; // no need to send this
      return JSON.parse(JSON.stringify(dataToSend));
    }

    return dataToSend;
  }

  function shouldSent(event) {
    return eventWhiteList.indexOf(event) >= 0;
  }

  function stripProduct(currentEvent) {
    var stripProductFrom = ['7T:Ticket:PrePrint'];
    var index;

    // strip product fro event name e.g.
    // 7T:Ticket:PrePrint.LiveBetting to 7T:Ticket:PrePrint
    stripProductFrom.forEach(function (event) {
      if (currentEvent.indexOf(event) >= 0) {
        index = currentEvent.lastIndexOf('.');
        currentEvent = currentEvent.slice(0, index);
      }
    });

    return currentEvent;
  }

  function sendEvent(data) {
    var eventName = data[0];
    var eventData = data[1];
    var gatwayMaster = master();

    eventName = stripProduct(eventName);

    // if event is not whitelisted
    // or product is not in iframe just quit
    if (!shouldSent(eventName)) {
      return;
    }

    if (gatwayMaster === false) {
      if (!warnMessageSent) {
        warnMessageSent = true;
        $log.warn('[7Terminal.Core] Gravity Gateway master not yet initialized.', {
          eventName,
          code: 'T_GRAVITY_GATEWAY_MASTER_NOT_INITIALIZED'
        });
      }
      return;
    }

    // strip 7T:
    if (eventName.slice(0, 3) === '7T:') {
      eventName = eventName.slice(3, eventName.length);
    }

    if (eventData.productId === '*') {
      master()
        .sendToAll({
          action: eventName,
          data: prepareData(eventData),
          enforceEvent: forceEvents.indexOf(eventName) >= 0
        });
    } else {
      master()
        .sendMessage(eventData.productId, {
          action: eventName,
          productId: eventData.productId,
          data: prepareData(eventData),
          enforceEvent: forceEvents.indexOf(eventName) >= 0
        });
    }
  }

  Scope.prototype.$broadcast = function () {
    sendEvent(arguments);
    return origBroadcast.apply(this, arguments);
  };
  Scope.prototype.$emit = function () {
    sendEvent(arguments);
    return origEmit.apply(this, arguments);
  };
  return $delegate;
}

export default rootScopeDecorator;
