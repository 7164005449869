import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';

const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const username = process.env.INTELLIGENT_GAMING_PLA_REPORTS_AUTH_USERNAME;
  const password = process.env.INTELLIGENT_GAMING_PLA_REPORTS_AUTH_PASSWORD;

  if (!request.headers) {
    request.headers = new AxiosHeaders();
  }

  const auth = btoa(`${username}:${password}`);
  request.headers.Authorization = `Basic ${auth}`;

  return request;
};

export default requestSuccess;
