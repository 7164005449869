import { ref } from 'vue';
import { defineStore } from 'pinia';
import PubSub from 'pubsub-js';
import { getService } from '@/common/services/ngBridge';
import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { apiService as lpsSocketApiService } from '@/modules/lps-socket';
import { useClientGamesStore } from '@/modules/client-games';
import type { SevenOddType } from './types';

const useOddTypeStore = defineStore('oddType', () => {
  const oddList: Array<SevenOddType> = [
    {
      type: 'Decimal',
      name: 'main.decimal_odds',
      format: '2.00',
      id: 1,
    },
    {
      type: 'Fractional',
      name: 'main.fractional_odds',
      format: '1/2',
      id: 2,
    },
    {
      type: 'American',
      name: 'main.american_odds',
      format: '+100',
      id: 3,
    },
  ];
  const activeOddType = ref<SevenOddType>(oddList[0]);

  const getAvailableOddTypes = (): Array<SevenOddType> => {
    const gravitySettingsStore = useGravitySettingsStore();
    const oddsPerCompany: Array<string> = gravitySettingsStore.getModuleDataKeyValue('config', 'oddsType');

    return oddList.filter((item) => oddsPerCompany?.includes(item.type)) || [];
  };

  const isOddTypeSupported = (type: string): boolean => {
    const availableOddTypes = getAvailableOddTypes();
    return !!availableOddTypes?.find((item) => item.type === type);
  };

  const updatePeripheralsConfig = (): void => {
    const SevenGamesSvc: any = getService('SevenGamesSvc');
    const $rootScope: any = getService('$rootScope');
    const activeGames = SevenGamesSvc.getGames();

    // update oddType for every game and every print game config
    activeGames.forEach((game: any) => {
      game.setOddType(activeOddType.value.type);
      $rootScope.$emit('7T:Peripherals.UpdateConfig', {
        productId: game.id,
        data: {
          printService: {
            oddsType: activeOddType.value.type,
          },
        },
      });
    });
  };

  const changeOddType = (selectedOddType: string): void => {
    const $rootScope: any = getService('$rootScope');
    const availableOddTypes = getAvailableOddTypes();
    const odd = availableOddTypes.find((item) => item.type === selectedOddType);
    const defaultOddType: SevenOddType = (availableOddTypes.length && availableOddTypes[0])
      || oddList[0];

    // Set odd type as active so we could track it across the app
    activeOddType.value = odd || defaultOddType;

    if (!isOddTypeSupported(activeOddType.value.type)) {
      activeOddType.value = defaultOddType;
    }

    localStorage.setItem('settings.oddsType', activeOddType.value.type);

    updatePeripheralsConfig();

    PubSub.publishSync(
      '7terminal.settings.oddTypeChanged',
      activeOddType.value.type,
    );

    $rootScope.$emit('7T:Widget.SettingsChanged', {
      productId: '*',
      oddType: activeOddType.value.id,
    });

    const publishData = {
      oddsType: activeOddType.value.type,
    };
    // Notify NAR about odds change
    lpsSocketApiService.dipatchOddsLanguageChange(publishData)
      .then(() => {
        lpsSocketApiService.setOddsLanguageState(publishData)
          .catch((err:any) => {
            logService.error('[oddTypeStore] Failed to set odd type change to LPS', {
              ...errorParser.parseUpstream(err),
              code: 'T_ERROR_LPS_SET_STATE',
            });
          });
      })
      .catch((err: any) => {
        logService.error('[oddTypeStore] Failed to publish odd type change to LPS', {
          ...errorParser.parseUpstream(err),
          code: 'T_ERROR_LPS_PUBLISH_STATE',
        });
      });
  };

  const setOddType = (selectedOddType: string): void => {
    const gamesStore = useClientGamesStore();
    const activeGames = gamesStore.games;

    // If no odd type is set or there is no odd in storage, set default one.
    let selectedOdd = selectedOddType || localStorage.getItem('settings.oddsType') || '';

    // Default odd explained here T9327#187558
    if (!selectedOdd) {
      selectedOdd = activeGames.length && activeGames[0].gameConfig?.odds ? activeGames[0].gameConfig.odds.default : 'Decimal';
    }

    changeOddType(selectedOdd);
  };

  const handleInitialOddType = (): void => {
    // If we receive exactly one odd type from backend per company, then
    // that type has priority over the one set in local storage (ls).
    // But, on the other hand, if that company odd type is not supported, then we will
    // not set it. We will set the one from ls (if there isn't any in ls, then we'll set
    // default one - Decimal)
    const gravitySettingsStore = useGravitySettingsStore();
    const oddsPerCompany: Array<string> = gravitySettingsStore.getModuleDataKeyValue('config', 'oddsType');
    if (oddsPerCompany?.length === 1 && isOddTypeSupported(oddsPerCompany[0])) {
      setOddType(oddsPerCompany[0]);
    } else {
      setOddType('');
    }
  };

  const shouldShowOddsPicker = (): boolean => getAvailableOddTypes().length > 1;

  return {
    activeOddType,
    setOddType,
    getAvailableOddTypes,
    handleInitialOddType,
    shouldShowOddsPicker,
    updatePeripheralsConfig,
  };
});

export {
  useOddTypeStore,
};
