import angular from 'angular';
import router from 'angular-ui-router';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import coreModule from '../core';
import gamesModule from '../games';

angular.module('7Terminal.Results', [
  router,
  'SevenClientCore',
  coreModule,
  gamesModule
]);
