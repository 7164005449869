<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useUserFundsStore } from '@/modules/user-funds';

const { balance } = storeToRefs(useUserFundsStore());
const showBalanceStatus = computed(() => balance.value === 0);

</script>

<template>
  <div
    v-if="showBalanceStatus"
    class="t-header-balance-status-text h-100 sc-text-body-2 text-right"
  >
    {{ $t('header_balance_helper_text') }}
  </div>
</template>

<style scoped lang="scss">
.t-header-balance-status-text {
  max-width: 14.25rem;
}

@media only screen and (max-width: 1024px) {
    .t-header-balance-status-text {
        display: none;
    }
}
</style>
