<template>
  <TimerBar
    ref="timerRef"
    :orientation="orientation"
    :position="position"
    :time="time"
    :transition-delay="transitionDelay"
    bg-color="var(--sc-brand)"
    loading-color="var(--sc-main-primary-faded)"
  />
</template>
<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useInactivityTimerStore } from '@/modules/inactivity-timer';
import idleService from '@/common/services/idle';
import TimerBar from './TimerBar.vue';

const props = defineProps({
  name: { type: String, required: true },
  time: { type: Number, required: true },
  onResetInactivityTimer: { type: Function, required: false, default: () => {} },
  onEndInactivityTimer: { type: Function, required: true },
  orientation: { type: String, required: false, default: 'vertical' },
  transitionDelay: { type: Number, required: false, default: 0 },
});

const timerRef = ref(null);

const position = {
  left: 0,
  bottom: props.orientation === 'horizontal' ? 0 : 'unset',
};

const timeToCallAdditionalAction = props.time + props.transitionDelay - 5000;

const resetIndicators = async () => {
  timerRef.value.resetTimerAnimation();
  const inactivityTimerStore = useInactivityTimerStore();
  inactivityTimerStore.setShowIdleModalNotification(false);
};

// This method will be called when inactivity timer is near end (5000 ms)
const showInactivityModalNotification = () => {
  const inactivityTimerStore = useInactivityTimerStore();
  inactivityTimerStore.setShowIdleModalNotification(true);
};

const init = () => {
  idleService.registerListener({
    name: props.name,
    onEndInactivityTimer() {
      props.onEndInactivityTimer();
    },
    onResetInactivityTimer() {
      resetIndicators();
      props.onResetInactivityTimer();
    },
    time: props.time,
    callResetAfterEndInactivity: false,
    inactivityTime: props.time,
    currentInactivityTime: 0,
    transitionDelay: props.transitionDelay,
    additionalActionData: {
      action: showInactivityModalNotification,
      time: timeToCallAdditionalAction,
      runOnlyOnce: false,
    },
  });

  resetIndicators();
};

onMounted(() => {
  init();
});

onUnmounted(() => {
  idleService.cancelInactivityTimer(props.name);
});
</script>
