import BaseError from '@/common/errors/BaseError';
import i18n from '@/plugins/i18n';
import { axiosErrorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { CashoutPayload } from '@/modules/seven-betslip-api';
import { TicketInstance } from '@/modules/seven-betslip-api/helpers';
import { cashoutTicket as betslipCashout } from '@/modules/seven-betslip-api/betslipApi';
import { CashoutResponse } from '@/modules/seven-betslip-api/types';
import { isTicketCheckAbortedError } from '@/modules/ticket-check';
import { createTicketMetaData } from '../helpers';
import { validatePayout } from './ticketValidationService';

const LOG_PREFIX = '[cashoutService]';
const CASHOUT_ERR_NOTIF = 'cashout_notif_error';
const { t } = i18n.global;

const showCashoutFailedNotification = (error: Error) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('ticket.pending_action');
  if (!isTicketCheckAbortedError(error)) {
    notificationsStore.show({
      message: error.message,
      delay: 3000,
      type: TNotificationTypeEnum.warning,
      id: CASHOUT_ERR_NOTIF,
    });
  }
};

const cashoutTicket = (
  payload: CashoutPayload,
  ticket: TicketInstance,
) => new Promise<CashoutResponse>((resolve, reject) => {
  const productDisplayId = ticket.getProductDisplayId();
  const notificationsStore = useNotificationsStore();
  const payloadData = payload;

  validatePayout(ticket).then(() => {
    payloadData.loyaltyCard = ticket.getLoyaltyCard();
    payloadData.metadata = createTicketMetaData(productDisplayId);
    payloadData.tax = ticket.getTaxData();
    payloadData.ticketPin = ticket.getPin();

    betslipCashout(payload)
      .then((response) => {
        if (!response.data.data.successful) {
          const formattedError = new BaseError(
            response.data.data.errorCode || 'Cashout API error',
            'T_TICKET_CASHOUT_HTTP_ERROR',
            {
              cause: response.data,
            },
          );
          notificationsStore.show({
            message: formattedError.message,
            type: TNotificationTypeEnum.error,
            delay: 3000,
          });

          logService.error(`${LOG_PREFIX} Cashout error detected.`, {
            code: formattedError.code,
            upstream_message: formattedError.message,
            product_displayid: productDisplayId,
            ticket_code: ticket.getDisplayId(),
            request_id: ticket.getRequestUuid(),
          });
          reject(formattedError);
        } else {
          notificationsStore.show({
            message: t('sportsbook.cashout_success'),
            delay: 3000,
            type: TNotificationTypeEnum.success,
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        const formattedError = new BaseError(
          axiosErrorParser.parseMessage(err),
          'T_TICKET_CASHOUT_HTTP_ERROR',
          {
            cause: err,
          },
        );
        showCashoutFailedNotification(formattedError);

        logService.error(`${LOG_PREFIX} Cashout error detected.`, {
          code: formattedError.code,
          upstream_message: formattedError.message,
          upstream_code: axiosErrorParser.parseCode(err),
          product_displayid: productDisplayId,
          ticket_code: ticket.getDisplayId(),
          request_id: ticket.getRequestUuid(),
        });
        reject(formattedError);
      });
  }).catch((error) => {
    const formattedError = new BaseError(error.message, 'T_TICKET_CASHOUT_VALIDATION_ERROR', {
      cause: error,
    });
    logService.error(`${LOG_PREFIX} Cashout validation error detected.`, {
      code: formattedError.code,
      upstream_message: formattedError.message,
      upstream_code: error.code,
      product_displayid: productDisplayId,
      ticket_code: ticket.getDisplayId(),
      request_id: ticket.getRequestUuid(),
    });
    showCashoutFailedNotification(formattedError);
    reject(formattedError);
  });
});

export {
  cashoutTicket,
};
