import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sc-text-button sc-px-5 sc-ml-2" }

import { computed } from 'vue';
import router from '@/router';
import { lobbyService } from '@/modules/lobby';


export default /*@__PURE__*/_defineComponent({
  __name: 'LobbyHeaderTitle',
  setup(__props) {

const index = computed(() => parseInt(router.currentRoute.value.params.id as string, 10));
const title = computed(() => lobbyService.getMenu()[index.value].title);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(title.value), 1 /* TEXT */))
}
}

})