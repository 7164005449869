import HooksManager from '@/common/services/HooksManager';
import type { TicketInstance } from '@/modules/seven-betslip-api';
import { useTicketCheckStore } from '@/modules/ticket-check';

const validateTBOPayout = (ticket: TicketInstance) => new Promise<{ ticket: TicketInstance }>(
  (resolve, reject) => {
    HooksManager.TboBeforeTicketPayout.promise({ ticket }).then(() => {
      const ticketCheckStore = useTicketCheckStore();
      if (ticketCheckStore.result) {
        ticket.setPin((ticketCheckStore.result as TicketInstance).getPin());
      }
      resolve({ ticket });
    }).catch((error) => {
      reject(error);
    });
  },
);

export {
  validateTBOPayout,
};
