import { has } from 'underscore';

function SevenTicketConfig() {
  var $log;
  var options = {
    url: 'http://admin-service-dev.nab-solutions.com:8090/app_dev.php/shop/',
    connectionChecker: 'http://localhost:5051/get_status',
    ticketHandling: {
      Voucher: {
        useTicketLocalStorage: true
      },
      // keep tickets in local storage or delete them after resolve
      saveLocally: false,
      ticketPrintLimit: 7,
      autoResolveUnknownTicketStatus: false
    }
  };

  var defaultTicketConfig = {
    availableTicketActions: {},
    statusConfig: {},
    pendingTicketCheckInterval: 10000,
    pendingTicketLimitByGame: 1,
    pendingTicketLimitTotal: 100,
    pendingTicketRejectPeriod: 30000,
    sentTicketRejectPeriod: 10000,
    useTicketLocalStorage: true
  };

  /**
       * @typedef {Object} SevenTicketConfigData
       * @property {boolean} [useTicketLocalStorage=true]
       * @property {number} [pendingTicketLimitTotal=100]
       * @property {number} [pendingTicketLimitByGame=1]
       * @property {number} [pendingTicketCheckInterval=10000]
       * @property {number} [pendingTicketRejectPeriod=30000]
       * @property {number} [sentTicketRejectPeriod=10000]
       * @property {Object.<String, Object>} [availableTicketActions=[]]
       * @property {Object.<String, Object>} [statusConfig={}]
       * @property {Object.<{value: LOCAL_TICKET_ACTIONS}>} statusConfig.localStatus
       * @property {Object.<String, *>} - any additional config
       */

  /**
       * Update ticket config for specific ticket instance
       * @param {String} id - unique ticket identifier
       * @param {SevenTicketConfigData} config
       * @returns {SevenTicketConfigData}
       */
  function setTicketConfig(id, config) {
    if (!has(options.ticketHandling, id)) {
      options.ticketHandling[id] = {};
    }

    // update ticket config, first apply default config then current config
    // and in the end new config
    options.ticketHandling[id] = angular.extend(
      {},
      defaultTicketConfig, // default
      options.ticketHandling[id], // current config
      config // new update
    );

    $log.debug('[SevenTicketCenter] Ticket config for ' + id + ' updated', angular.copy(options.ticketHandling[id]));

    return options.ticketHandling[id];
  }

  /**
       *
       * @param {string} id
       * @returns {SevenTicketConfigData}
       */
  function getTicketConfig(id) {
    if (has(options.ticketHandling, id)) {
      return options.ticketHandling[id];
    }

    return angular.copy(defaultTicketConfig);
  }

  return {
    /**
     *
     * @ngInject
     */
    $get: function ($injector) {
      $log = $injector.get('$log');
      return {
        getTicketConfig: getTicketConfig,
        options: options,
        setTicketConfig: setTicketConfig
      };
    },
    set: function (value) {
      angular.merge(options, value);
    },

    setTicketConfig: setTicketConfig
  };
}

export default SevenTicketConfig;
