/* eslint-disable import/no-cycle */
import TERMINAL_MODULES from '../modules';

(function () {
  angular.module('7Terminal.Modules').service('Modules', Modules);

  /**
   *
   * @constructor Modules
   */
  function Modules($q, $ocLazyLoad, $injector, $exceptionHandler) {
    /**
     *
     * @memberOf Modules
     * @type {{}}
     */
    this.terminalModules = {};

    /**
     * @memberOf Modules
     * @param {String} moduleName
     * @return {*|boolean}
     */
    this.isModuleEnabled = (moduleName) => {
      const module = this.terminalModules?.[moduleName] || TERMINAL_MODULES.getModule(moduleName);
      return !!(module?.enabled || module?.isEnabled?.());
    };

    /**
     * @memberOf Modules
     * @param {String} moduleName
     * @return {*|boolean}
     */
    this.isModuleLoaded = (moduleName) => this.getLoader().isLoaded(moduleName);

    this.registerModules = (modules) => {
      this.terminalModules = { ...this.terminalModules, ...modules };
    };

    this.loadModule = function (moduleId) {
      const module = TERMINAL_MODULES.getModule(moduleId);
      // run functions before
      const before = angular.extend([], angular.module('ng')._runBlocks);

      if (!module || !module.lazyLoad) {
        return $q.resolve();
      }

      return module.lazyLoad.load().then((mod) => {
        // run functions after lazy load
        const after = angular.module('ng')._runBlocks;

        angular.forEach(after, (fn) => {
          if (before.indexOf(fn) === -1) {
            if (fn.length && fn[0] !== '$templateCache') {
              $exceptionHandler('[7Terminal.Modules] Invoking run block that is not an $templateCache', fn.toString());
            }
            $injector.invoke(fn);
          }
        });
        if (!mod.default && !module.ng?.moduleName && !mod.init) {
          return $q.resolve(mod);
        }

        return this.getLoader().inject(mod.default || module.ng?.moduleName || mod.init?.());
      });
    };

    this.getLoader = () => $ocLazyLoad;

    this.loadModulesByPosition = function (position) {
      // lazyLoad modules, position: afterGravityBootstrap
      const modulesToLoad = TERMINAL_MODULES.filterLazyLoadModules(position);
      const loadPromises = [];

      // go through each locally defined module
      modulesToLoad.forEach((module) => {
        // module can be enabled through CMS or dynamically (ex. oktopay)
        // if enabled dynamically - it won't have defined gravityName
        const moduleId = module.gravityName ? module.gravityName : module.id;

        if (this.isModuleEnabled(moduleId)) {
          loadPromises.push(this.loadModule(module.id));
        }
      });

      return $q.all(loadPromises);
    };

    this.getModulesByPosition = (position) => TERMINAL_MODULES.filterLazyLoadModules(position);
  }
})();
