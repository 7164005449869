import { each } from 'underscore';

/**
 *
 * @ngInject
 */
function DatepickerController(
  $scope,
  datepickerHelperService
) {
  $scope.selectedPartial = 'month';

  /**
   * Date values selected by user
   * @type {{month: string, day: string, year: string}}
   */
  $scope.selected = {
    month: '',
    day: '',
    year: ''
  };

  /**
   * Initial date values
   * @type {{month: string, day: string, year: string}}
   */
  $scope.oldVal = {
    month: '',
    day: '',
    year: ''
  };

  $scope.keyboardButtons = [];
  $scope.displayDatepicker = false;

  function getKeyboardButtons() {
    var i;

    for (i = 1; i <= 9; i += 1) {
      $scope.keyboardButtons.push(i);
    }
  }

  getKeyboardButtons();

  /**
   * Date validation to prevent bad date format
   */
  $scope.validateDate = function () {
    var year = $scope.selected.year || $scope.oldVal.year;
    var month = $scope.selected.month || $scope.oldVal.month;
    var day = $scope.selected.day || $scope.oldVal.day;
    var monthDays;

    if (year) {
      $scope.selected.year = String(datepickerHelperService.validateYear(year, null, $scope.oldVal.year));
    }

    if (month) {
      $scope.selected.month = String(datepickerHelperService.validateMonth(month, $scope.oldVal.month));
    }
    if (day) {
      monthDays = String(datepickerHelperService.getMonthDays($scope.selected.month, $scope.selected.year));
      $scope.selected.day = String(datepickerHelperService.validateDays(monthDays, day, $scope.oldVal.day));
    }
  };

  $scope.addValue = function (value) {
    $scope.selected[$scope.selectedPartial] += String(value);
  };

  /**
   * Store value of selected date partial(i.e. month, year, day) in case user wants to change it
   * @param partial
   */
  $scope.selectPartial = function (partial) {
    $scope.validateDate();

    $scope.oldVal[partial] = $scope.selected[partial];
    $scope.selected[partial] = '';
    $scope.selectedPartial = partial;
  };

  $scope.toggleDatepicker = function () {
    $scope.selectedPartial = 'month';
    $scope.displayDatepicker = !$scope.displayDatepicker;
  };

  $scope.resetValue = function () {
    $scope.selected[$scope.selectedPartial] = '';
  };

  $scope.$watch('selectedPartial', function () {
    each($scope.selected, function (value, partial) {
      if ($scope.selected[partial] === '' && partial !== $scope.selectedPartial) {
        $scope.selected[partial] = $scope.oldVal[partial];
      }
    });
  });

  /**
   * Parse existing date, and display it to user
   *
   * @param date
   * @returns {boolean}
   */
  $scope.parseDate = function (date) {
    var dateArr;

    if (!date) {
      return;
    }

    if (typeof date === 'string') {
      dateArr = date.split('-');

      $scope.selected.day = dateArr[2];
      $scope.selected.month = dateArr[1];
      $scope.selected.year = dateArr[0];
    } else {
      $scope.selected.day = String(date.getDate());
      $scope.selected.month = String(date.getMonth() + 1);
      $scope.selected.year = String(date.getFullYear());
    }
  };

  $scope.resetDate = function () {
    $scope.selected.day = '';
    $scope.selected.month = '';
    $scope.selected.year = '';

    $scope.toggleDatepicker();
  };
}

export default DatepickerController;
