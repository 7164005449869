import templateUrl from './template.html';

(function () {
  angular.module('7Terminal.Common')
    .component('vgTabs', {
      transclude: true,
      bindings: {
        onClear: '&',
        onAdd: '&',
        onAddText: '@'
      },
      controller: function (
        $translate,
        flashButton
      ) {
        var ctrl = this;
        var panes = [];
        ctrl.panes = [];
        ctrl.panes = panes;

        ctrl.activePane = null;

        ctrl.select = function (pane) {
          angular.forEach(panes, function (p) {
            p.selected = false;
          });
          pane.selected = true;
          ctrl.activePane = pane;
        };

        ctrl.addPane = function (pane) {
          if (panes.length === 0) {
            ctrl.select(pane);
          }
          panes.push(pane);
        };

        /**
                 * When clear is clicked, call passed callback
                 */
        ctrl.clear = function () {
          ctrl.onClear({});
        };

        /**
                 * When add is clicked, call passed callback
                 */
        ctrl.add = function () {
          ctrl.onAdd({});
        };

        ctrl.$onInit = function () {
          ctrl.flashButton = flashButton;
          ctrl.onAddText = angular.isDefined(ctrl.onAddText) ? ctrl.onAddText : $translate.instant('actions.add');
        };
      },
      templateUrl: templateUrl
    })
    .component('vgTabsPane', {
      transclude: true,
      require: {
        tabsCtrl: '^vgTabs'
      },
      bindings: {
        title: '@',
        icon: '@',
        className: '@',
        showOnAdd: '@',
        showOnClear: '@'
      },
      controller: function () {
        var ctrl = this;

        ctrl.$onInit = function () {
          ctrl.tabsCtrl.addPane(ctrl);
          if (angular.isUndefined(ctrl.showOnAdd)) ctrl.showOnAdd = 'true';
          if (angular.isUndefined(ctrl.showOnClear)) ctrl.showOnClear = 'true';
        };
      },
      template: '<div class="vg-tabs__content" ng-show="$ctrl.selected" ng-transclude></div>'
    });
})();
