import moment from 'moment';

export enum TicketStatuses {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  LOST = 'LOST',
  WON = 'WON',
  PAIDOUT = 'PAIDOUT',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
}

export enum ProductTicketStatuses {
  pending = 'pending',
  open = 'open',
  accepted = 'accepted',
  in_play = 'in_play',
  rejected = 'rejected',
  won = 'won',
  won_approved = 'won_approved',
  cashback = 'cashback',
  closed = 'closed',
  payedout = 'payedout',
  paidout = 'paidout',
  cancelled = 'cancelled',
  canceled = 'canceled',
  lost = 'lost',
  used = 'used',
  expired = 'expired',
}

export enum LocalTicketStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  LOST = 'LOST',
  WON = 'WON',
  PAIDOUT = 'PAIDOUT',
  PAYEDOUT = 'PAYEDOUT',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
  CLOSED = 'CLOSED',
  USED = 'USED',
}

export enum TicketCodeTypes {
  svnbcd1 = 'svnbcd1',
  code39 = 'code39',
}

type TicketCode = {
  id: string,
  type: TicketCodeTypes
};

export enum ClientTicketActions {
  Add = 'Add',
  Cancel = 'Cancel',
  Payout = 'Payout',
  Cashout = 'Cashout',
}

interface TicketProcessedSlip {
  accountLink: string,
  codes: Array<TicketCode>,
  creationTime: number,
  productDisplayId: string,
  payin: { stake: number, total: number },
  payout: {
    tax: Array<{ type: string, total: number }>
    total: number,
    winnings: number
  },
  requestId: string,
  status: TicketStatuses | ProductTicketStatuses,
  ticketPin: string,
}

interface TicketCreateSlip {
  accountLink: string,
  creationTime: number,
  productDisplayId: string,
  payin: { total: number },
  requestId: string,
}

export interface ProductTicketStatus {
  value: ProductTicketStatuses,
  name: string
}

export type ProductBet = {
  event: {
    startsAt: moment.Moment,
  }
};
export interface ProductTicketResponse {
  id: string,
  payin: number,
  paymentId: string,
  product: string,
  payout: number,
  payoutTax: number,
  possiblePayout: number,
  possiblePayoutTax: number,
  requestUuid: string,
  superBonus?: { amount: number, status: { value: string } },
  cashout?: { cashoutEnabled: boolean, payout: number, winnings: number, tax: number },
  status: ProductTicketStatus,
  ticketPin: string | null,
  winnings: number,
  paidOutWinningAmount: number,
  ticketDateTimeUTC: number,
  ticketDateTime: number,
  stake: number,
  displayPayoutAmount?: number,
  paidOutAmount?: number,
  totalAmount?: number,
  displayPayout?: number,
  payoutAmountMax?: number,
  cancellable?: boolean,
  bets?: Array<Object>,
}

export interface ProductTicketSportsbookSMResponse extends Omit<ProductTicketResponse, 'bets'> {
  ticketHashes: Array<{ type: string, hash: string }>,
  paymentAmount: number,
  payment?: {
    value: number,
    type: string,
  },
  ticketBets?: Array<ProductBet>,
}

interface TicketResponse {
  slip: TicketProcessedSlip,
  ticket: ProductTicketResponse | any,
  clientVal: string,
  platformVal: string,
  tax: Object,
  loyaltyDetails?: Object | null;
}

export interface TicketClientRequest {
  slip: TicketCreateSlip,
  ticket: ProductTicketResponse | any,
  clientVal: string,
  platformVal: string,
  tax: Object,
  loyaltyDetails?: Object | null;
}

export interface CashoutParams {
  requestUuid: string
}

export interface TboCashoutResponse {
  data: {
    successful: boolean,
    errorCode: string | null,
    cashoutAmountBeforeTax: number,
    cashoutAmountAfterTax: number,
    taxPayer: string | null,
  }
}

export interface CashoutResponse extends TboCashoutResponse {
  balance: number,
}

export type {
  TicketProcessedSlip,
  TicketResponse,
};

export interface OperatorPayoutParams {
  requestUuid: string;
}

export interface TicketForCheck {
  requestUuid: string,
  productDisplayId: string,
  displayId?: string;
}

export enum CheckerErrors {
  PERIOD_PASSED = 'TCheckerPeriodPassed',
}
