import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center align-items-end sc-px-6" }
const _hoisted_2 = { class: "sc-text-primary-2 sc-text-caption-2" }
const _hoisted_3 = { class: "sc-text-primary-1 sc-text-h6" }

import { onMounted, onUnmounted, ref } from 'vue';
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'TClock',
  setup(__props) {

const date = ref<string>(moment().format('DD.MM.yyyy'));
const time = ref<string>(moment().format('HH:mm'));

let intervalId: number;

onMounted(() => {
  intervalId = window.setInterval(() => {
    date.value = moment().format('DD.MM.yyyy');
    time.value = moment().format('HH:mm');
  }, 5000);
});

onUnmounted(() => {
  window.clearInterval(intervalId);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(date.value), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, _toDisplayString(time.value), 1 /* TEXT */)
  ]))
}
}

})