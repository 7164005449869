import { useLuckySixGameStore } from '@/modules/client-games';

(function () {
  angular.module('terminalApp.nabLuckySixUi')
    .service('LuckySixUiService', LuckySixUiService);

  function LuckySixUiService(
    $rootScope,
    BetslipService,
    nabTrans,
    nabMessaging,
    SevenGamesSvc,
    ticketService,
    GravitySettings
  ) {
    // listen for new lucky six round and update
    // bets with new event number
    nabMessaging.subscribe('LuckySix:updateEvents', function (e, data) {
      var betsToUpdate = [];
      var event;
      var eventAfter = data.param;
      var ticket = BetslipService.getTicket('single', 'LuckySix');

      if (ticket) {
        ticket.getBets()
          .forEach(function (bet) {
            // update event when new round starts - be careful of schedule event
            event = bet.event.toString().split('-');
            eventAfter = event.length > 1 ? (Number(data.param) + 1) + '-' + (Number(data.param + 1) + (Number(event[1]) - Number(event[0]))) : (Number(data.param) + 1);
            betsToUpdate.push({
              event: eventAfter,
              uid: bet.uid
            });
          });

        $rootScope.$broadcast('7T:Betslip.UpdateBets', {
          data: {
            betslipId: 'LuckySix',
            ticketId: 'single',
            bets: betsToUpdate
          }
        });
      }
    });

    // listen for config changes
    nabMessaging.subscribe('NCM:gameConfigChanged', function (event, message) {
      if (message.game == 'LuckySix') {
        var game = SevenGamesSvc.getGame(message.game);
        game.setConfig().then(function () {
          game.doInitialConfiguration();
          $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
            data: {
              betslipId: 'LuckySix',
              ticketId: 'single',
              config: {
                group: {
                  id: 'LuckySix',
                  taxesConfig: game.config.taxes
                },
                stake: game.getRule('minPayinAmount', {
                  ticketType: {
                    id: 3,
                    name: 'single'
                  }
                })
              }
            }
          });

          nabMessaging.publish('config:gameConfigChanged', message.game);
        });
      }
    });

    // listen for betslip payin action
    $rootScope.$on('7T:Betslip.Payin',
      /**
             *
             * @param event
             * @param {Object} message
             * @param {String} message.productId
             * @param {Object} message.ticket
             */
      function (event, message) {
        if (message.productId === 'LuckySix') {
          ticketService.payinTicket(message).catch(angular.noop);
        }
      });

    $rootScope.$on('7T:Tickets.PrePrint', function (e, eventData) {
      if (eventData.productId === 'LuckySix') {
        const isInIframe = GravitySettings
        .getModuleDataKeyValue(`module.${eventData.productId}`, 'frame') === true;
        var gameService = SevenGamesSvc.getGame('LuckySix');
        var bettingService = gameService.getBettingSvc();

        if (isInIframe) {
          return;
        }

        eventData.responseData.parsedResponse = bettingService.handleBetsFromResponse(
          {
            bets: eventData.responseData.bets
          }
        );

        angular.merge(eventData.responseData, bettingService.prepareForPrint(
          eventData.responseData,
          gameService.config
        ));

        $rootScope.$emit('7T:Tickets.Print', eventData);
      }
    });

    this.boot = function () {
      const luckySixStore = useLuckySixGameStore();
      if (!BetslipService.getTicket('single', 'LuckySix')) {
        var productConfig = SevenGamesSvc.getGame('LuckySix');
        luckySixStore.$patch({
          productConfig: productConfig.config
        });

        $rootScope.$broadcast('7T:Betslip.Create', {
          data: {
            betslip: {
              id: 'LuckySix',
              tickets: [
                {
                  id: 'single', // if of ticket build of game id (PrematchBetting) and ticket type (normal)
                  active: true, // active in current group (LuckySix)
                  ticketGroup: 'LuckySix',
                  betsCollection: 'LuckySix',
                  group: {
                    id: 'LuckySix',
                    taxesConfig: productConfig.config.taxes
                  },
                  ticketType: {
                    id: 3,
                    name: 'single'
                  }, // this is only needed when when formatting data for server, it represent server id
                  stake: productConfig.getRule('minPayinAmount', {
                    ticketType: 3
                  }),
                  stakeTouched: false,
                  setStakeStrategy: 'sevenVirtualSetStake',
                  winningStrategy: false,
                  setFormatPayin: 'sevenVirtualFormataPayin',
                  hasSystem: false,
                  emptyBetslipText: nabTrans.translate('betslip.pick_numbers_message_first_line', {}, true),
                  betTemplate: 'virtual',
                  autoStakeSetting: true
                }
              ]
            }
          }
        });
      }

      $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
        data: {
          betslipId: 'LuckySix',
          ticketId: 'single',
          config: {
            emptyBetslipText: nabTrans.translate(
              'betslip.pick_numbers_message_first_line', {}, true
            )
          }
        }
      });
    };
  }
})();
