import { has } from 'underscore';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';

const createLogDataFromTicket = betslipApiHelpers.createLogDataFromTicket;

/**
 * @class ticketManager
 * @ngInject
 */
function ticketManagerService(
  $filter,
  $http,
  $q,
  $log,
  $translate,
  SevenTicketConfig,
  SevenTicket,
  SevenClientConfig,
  TicketActionStore,
  LOCAL_TICKET_STATUS,
  nabMessaging,
  NabNotifications,
  nabTrans,
  HooksManager,
  errorParser
) {
  const LOG_PREFIX = '[7T.Tickets]';
  var lang;
  var TICKET_SERVER_STATUSES = {
    open: 'open',
    accepted: 'accepted',
    in_play: 'in_play',
    rejected: 'rejected',
    closed: 'closed',
    payedout: 'payedout', // both for voucher and ticket
    cancelled: 'cancelled',
    canceled: 'canceled',
    used: 'used' // this one is for voucher
  };
  // TODO: It should be droped in favor of
  // product mapping using getTicketStatusDefinition
  var successfulStatuses = {
    add: [
      TICKET_SERVER_STATUSES.open,
      TICKET_SERVER_STATUSES.accepted,
      TICKET_SERVER_STATUSES.in_play
    ],
    cancel: [
      TICKET_SERVER_STATUSES.closed,
      TICKET_SERVER_STATUSES.cancelled,
      TICKET_SERVER_STATUSES.canceled
    ],
    payout: [
      TICKET_SERVER_STATUSES.used,
      TICKET_SERVER_STATUSES.payedout
    ]
  };
  var ticketManager = {
    tickets: {
      add: [],
      cancel: [],
      payout: []
    },

    pendingActions: {},

    /**
           * Add action to list of pending ctions
           * @param {String }namespace
           */
    addPendingAction: function (namespace) {
      ticketManager.pendingActions[namespace] = true;
    },

    /**
           * Remove pending action
           * @param namespace
           */
    removePendingAction: function (namespace) {
      ticketManager.pendingActions[namespace] = false;
    },

    /**
      * Check does pending action exist
      *
      * @param {String} namespace
      * @return {boolean}
      */
    isActionPending: function (namespace) {
      return has(ticketManager.pendingActions, namespace)
                               && ticketManager.pendingActions[namespace];
    },

    /**
           * Every sent action gets updated with some extra data
           * saved to tickets[action] array for latest tickets display
           * If local storage setting is on, it also gets saved to
           * local ticket[action] store
           * @param {SevenTicket} ticket - ticket data ready to be sent to server
           * @param {String} ticket.product
           * @param {String} ticket.requestUuid
           * @param {String} ticket.action
           * @param {Object} routes
           * @return {Promise.<SevenTicket>}
           */
    saveSentAction: function (ticket, routes) {
      var existingTicket = [];
      var ticketProductSetting = SevenTicketConfig.getTicketConfig(ticket.product);
      var property = ticket.action === 'Add' ? 'requestUuid' : 'id';
      var filter = {};

      filter[property] = ticket[property];
      ticket.id = ticket.id || '-';
      ticket.lang = lang;

      // Add action needs to be kept in order to block multiple
      // payin/payout attempts on same ticket from betslip
      if (['add', 'payout'].indexOf(ticket.action.toLowerCase()) >= 0) {
        ticketManager.addPendingAction(
          ticket.product + '.' + ticket.action.toLowerCase()
        );
      }

      // Set route for ticket check by requestUuid
      ticket.reqUuidCheck = routes.reqUuidUrl.baseUrl;
      ticket.check = routes.check;
      ticket.partialUrl = ticket.product + routes.reqUuidUrl.urlSuffix;

      // get translated name of game for print service
      ticket.translation = $translate.instant('games.' + ticket.product.toLowerCase());

      ticket.setAsPending();

      // Check if tickets is already in ticket list
      existingTicket = $filter('filter')(
        this.tickets[ticket.action.toLowerCase()],
        filter,
        true
      );

      // Make sure that payin action is idempotent
      if (!existingTicket.length) {
        this.tickets[ticket.action.toLowerCase()].unshift(ticket);
        $log.debug(
          `${LOG_PREFIX} Saving action ${ticket.action}
            from ${ticket.product} with id/requestUuid ${ticket.id}/${ticket.requestUuid}`,
          {
            ticket_code: ticket.id,
            request_id: ticket.requestUuid,
            product_displayid: ticket.product,
            ticket: ticket
          }
        );
        if (ticketProductSetting && ticketProductSetting.useTicketLocalStorage) {
          $log.debug(`${LOG_PREFIX} ... and into store`, {
            ticket_code: ticket.id,
            request_id: ticket.requestUuid,
            product_displayid: ticket.product,
            ticket: ticket
          });
          return TicketActionStore.add({
            store: 'ticket' + ticket.action,
            data: JSON.parse(JSON.stringify(ticket))
          }).then(function () {
            return ticket;
          });
        }
      }

      return $q.resolve(ticket);
    },

    /**
           * When action gets rejected on http request, update ticket
           * action instance to rejected status in memory and in indexdb
           * @param {Object} action
           * @param {SevenTicket} action.request
           * @param {Object} action.response
           * @returns {{error: boolean, reason: string, type: string}}
           */
    handleFailedAction: function (action) {
      var saveTicketLocally = SevenTicketConfig.options.ticketHandling.saveLocally;
      var localStorage = SevenTicketConfig.getTicketConfig(action.request.product).useTicketLocalStorage;
      var ticketToUpdate;
      action.request.localStatus = LOCAL_TICKET_STATUS.FAILED;
      // clear pending ticket actions
      ticketManager.removePendingAction(
        action.request.product + '.' + action.request.action.toLowerCase()
      );

      // Update in memory
      ticketToUpdate = updateTicketInMemoryWithinAction(
        action.request,
        action.request.action.toLowerCase()
      );

      // Ticket cleanup
      if (ticketToUpdate) {
        ticketToUpdate.stopCheckingStatus();
      }

      // Update status in IDB
      if (localStorage && saveTicketLocally && ticketToUpdate) {
        this.updateTicketStatus(ticketToUpdate);
      }

      return this.handleActionError(action.response, action.request);
    },

    /**
           * Update ticket status
           *
           * @param {Object} ticket
           */
    updateTicketStatus: function (ticket) {
      var findByProperty = ticket.action === 'Add' ? 'requestUuid' : 'id';
      var tmpTicketObj = {
        store: 'ticket' + ticket.action,
        findBy: findByProperty,
        data: ticket
      };
      tmpTicketObj[findByProperty] = ticket[findByProperty];
      TicketActionStore.update(tmpTicketObj);
    },

    removeActionFromStore: function (ticket) {
      var findByProperty = ticket.action === 'Add' ? 'requestUuid' : 'id';
      var tmpTicketObj = {
        store: 'ticket' + ticket.action,
        findBy: findByProperty
      };
      tmpTicketObj[findByProperty] = ticket[findByProperty];
      TicketActionStore.remove(tmpTicketObj);
    },

    /**
          * Supports three different version of error message
          * statusDetails version, details version and contextInfo version.
          *
          * @param {Object} ticket - Ticket response returned from server
          * @param {Object} requestDetails - Ticket request with missing info in response
          *
          *  {
          *      "httpCode": 400,
          *       "code": 20000,
          *       "message": "Restriction!",
          *       "details": [
          *           {
          *               "id_rule_event": 104,
          *               "id_rule": "17",
          *               "id_ticket": 38,
          *               "event_type": "1",
          *               "created_datetime": "2015-10-28 08:54:42",
          *               "message": "Category blocked "
          *           },
          *           {
          *               "id_rule_event": 105,
          *               "id_rule": "18",
          *               "id_ticket": 38,
          *               "event_type": "1",
          *               "created_datetime": "2015-10-28 08:54:42",
          *               "message": "Tournament blocked "
          *           },
          *           {
          *               "id_rule_event": 106,
          *               "id_rule": "20",
          *               "id_ticket": 38,
          *               "event_type": "1",
          *               "created_datetime": "2015-10-28 08:54:42",
          *               "message": "Match blocked "
          *           }
          *       ]
          *   }
          *
          * @returns {{error: boolean, reason: string, type: string}}
          */
    handleActionError: function (ticket, requestDetails) {
      var message = [];
      var i = 0;
      // do we have a ticket response?
      if (ticket) {
        // For vouchers we don't show details messages, just the main one
        if (requestDetails && requestDetails.product === 'Voucher') {
          message.push(ticket.message);
        } else {
          // check for details first
          // eslint-disable-next-line no-lonely-if
          if (ticket.details && ticket.details.length) {
            // just take first error and his message,
            // but care, sometimes backed doesn't send any message inside details,
            // that's why fallback to ticket.message
            if (ticket.details[0].message) {
              message = [];
              for (i = 0; i < ticket.details.length; i++) {
                message.push(ticket.details[i].message);
              }
            } else {
              message.push(ticket.message);
            }
          } else if (ticket.message) {
            // when no details are available, use main message
            message.push(ticket.message);
          } else {
            // if we don't have any message from server, just use default one
            message.push($translate.instant('ticket.action_rejected'));
          }
        }
      } else {
        // If ticket is undefined, request failed
        // with no response - typical for connection loss
        message.push($translate.instant('notifications.default_error_message'));
      }

      return {
        error: true,
        reason: message,
        type: 'warning',
        clientCode: 'T_TICKET_RESOLVE_BACKEND_ERROR',
        serverResponse: ticket
      };
    },

    /**
           *
           * @param {SevenTicket} ticket
           * @returns {{error: boolean, data: SevenTicket, reason: string, type: string}}
           */
    handleTicketAdd: function (ticket) {
      var product = ticket.product.toLowerCase() === 'voucher' ? 'voucher.' : 'ticket.';
      if (successfulStatuses.add.indexOf(ticket.localStatus.toLowerCase()) < 0) {
        return this.handleActionError(ticket);
      }

      return {
        error: false,
        data: ticket,
        reason: $translate.instant(product + 'add_success'),
        type: 'success'
      };
    }
  };

  nabMessaging.subscribe('ticket:failedAction', function (event, data) {
    // eslint-disable-next-line no-use-before-define
    ticketManager.handleFailedAction({
      request: {
        product: data.product,
        action: data.action,
        requestUuid: data.requestUuid
      },
      response: {
        message: data.message
      }
    });
  });

  nabMessaging.subscribe(
    'TicketManager:pendingTicketRejectPeriodPassed',
    function (event, data) {
      var ticket = data.ticket;
      var findByProperty = ticket.action === 'Add' ? 'requestUuid' : 'id';
      var tmpTicketObj = {
        store: 'ticket' + ticket.action,
        findBy: findByProperty,
        data: {
          localStatus: ticket.localStatus,
          localRevisions: ticket.localRevisions
        }
      };
      tmpTicketObj[findByProperty] = ticket[findByProperty];
      TicketActionStore.update(tmpTicketObj);
      // eslint-disable-next-line no-use-before-define
      ticketManager.removePendingAction(
        ticket.product + '.' + ticket.action.toLowerCase()
      );
    }
  );

  HooksManager.getHook('BeforeTicketPayin').tapPromise({
    name: 'BeforeTicketPayin.ActionPending',
    fn: function (params) {
      const gravitySettingsStore = useGravitySettingsStore();
      let skipTicketPendingCheck = gravitySettingsStore
        .getModuleDataKeyValue(`module.${params.productId}`, 'skipTicketPendingCheck');

      // We want to enable skip ticket pending check for all new games,
      // So if if they don't add this prop to module definition, it will be enabled by default
      if (skipTicketPendingCheck === undefined) skipTicketPendingCheck = true;

      if (skipTicketPendingCheck) {
        $log.debug(`${LOG_PREFIX} Skip ticket pending check`, {
          product_displayid: params.productId,
          code: 'T_SKIP_TICKET_PENDING_CHECK'
        });
        return $q.resolve();
      }

      if (ticketManager.isActionPending(params.productId + '.add')) {
        return $q.reject({
          message: nabTrans.translate('notifications.wait_pending_ticket', {}, true),
          code: 'T_PAYIN_PENDING'
        });
      }

      return $q.resolve();
    }
  });
  /**
       * Check if ticket is locally declared as failed
       * Ticket is usaually dclared as failed if we never got response for it
       * @param ticket
       * @param action
       * @returns {Boolean | SevenTicket}
       */
  function checkIfFailedTicketExistsInMemory(ticket, action) {
    var property = action === 'Add' ? 'requestUuid' : 'id';
    var r;
    var param = {
      localStatus: LOCAL_TICKET_STATUS.FAILED
    };
    param[property] = ticket[property];

    r = $filter('filter')(
      ticketManager.tickets[action.toLowerCase()],
      param,
      true
    );

    if (r.length) return r[0];

    return false;
  }

  /**
       * Detect if response from server is (socket) error
       * @param {Object} ticketResponse
       */
  function isErrorResponse(ticketResponse) {
    if (ticketResponse
          && ticketResponse.httpCode) {
      return true;
    }

    return false;
  }

  /**
       * Updates ticket saved in memory (latest ticket section)
       * @param ticket
       * @param action
       * @returns {Boolean} Ticket was updated in memory
       */
  function updateTicketInMemoryWithinAction(ticketData, action) {
    var ticketFromAddList = $filter('filter')(
      ticketManager.tickets[action.toLowerCase()],
      function (value) {
        return value.requestUuid === ticketData.requestUuid
                || value.id === ticketData.id;
      },
      true
    );

    if (ticketFromAddList.length) {
      // update ticket state on add list too, e.g. - ticket is canceled
      ticketFromAddList[0].update(ticketData);
    }

    return ticketFromAddList.length ? ticketFromAddList[0] : false;
  }

  /**
  * Format bets in a structure ready to be sent to
  * backend. This is only used for some old games like LuckySix
  *
  * @param {Object} data
  * @param {Object} data.ticket
  * @returns {*}
  */
  function formatTicket(data) {
    // get ticket formatter for current game,
    // it will format ticket according to backend product
    var ticketFormatter = _7.getService(data.ticket?.config?.setFormatPayin);

    // since we switched to event driven payin flow,
    // product can sent already formatted ticket so no need
    // for our formatting logic
    if (data.ticketFormatted || !ticketFormatter) {
      return data.ticketFormatted || data.ticket;
    }

    // now format ticket data which will be sent to server
    return ticketFormatter({
      ticketType: data.ticket.config.ticketType,
      ticket: data.ticket
    });
  }

  return {

    /**
           *
           * @memberOf ticketManager
           * Holds every ticket action that got sent
           * sorted by action type
           */
    tickets: ticketManager.tickets,

    /**
           * @memberOf ticketManager
           */
    setData: function () {
      lang = SevenClientConfig.getSettings().global.language;
    },

    /**
           * Check does pending action exist
           *
           * @memberOf ticketManager
           * @param {String} namespace
           * @return {boolean}
           */
    isActionPending: ticketManager.isActionPending,

    /**
           * Check does ticket exist in memotry with status UNKNOWN or PENDING
           * @param {Object} ticket
           * @param {String} action
           * @returns {Promise}
           */
    isTicketUnresolved: function (ticketData, action) {
      var property = action === 'Add' ? 'requestUuid' : 'id';
      var result = $filter('filter')(ticketManager.tickets[action.toLowerCase()], function (value) {
        return value[property] === ticketData[property]
                && (value.localStatus === LOCAL_TICKET_STATUS.PENDING || value.localStatus === LOCAL_TICKET_STATUS.UNKNOWN);
      });
      return result.length ? result[0] : false;
    },

    /**
           * Resolve ticket in memory and IDB and return resolved response
           * @param {SevenTicket} sevenTicket - ticket in db converted to SevenTicket
           * @param {Object} ticket - ticket response from server
           * @param {String} action - ticket action either Add, Cancel or Payout
           * @returns {Promise}
           */
    handleTicketSuccess: function (sevenTicket, ticketData, action) {
      var deffered = $q.defer();
      var saveTicketLocally = SevenTicketConfig.options.ticketHandling.saveLocally;
      var ticketProductSetting = SevenTicketConfig.getTicketConfig(ticketData.product);
      var property = action === 'Add' ? 'requestUuid' : 'id';
      var tmpTicketObj = {
        store: 'ticket' + action,
        findBy: property,
        data: sevenTicket
      };
      var promise = null;

      $log.debug(`${LOG_PREFIX} handleTicketSuccess for action ${action}`, {
        product_displayid: ticketData && ticketData.product,
        ticket_code: ticketData && ticketData.id,
        request_id: ticketData && ticketData.requestUuid
      });

      tmpTicketObj[property] = ticketData[property];
      sevenTicket.updateOnActionResolve(ticketData, action);
      // update ticket in memory inside 'add' array
      updateTicketInMemoryWithinAction(sevenTicket, 'add');
      // update ticket status inside indexDb
      if (ticketProductSetting && ticketProductSetting.useTicketLocalStorage) {
        if (saveTicketLocally) {
          promise = TicketActionStore.update(tmpTicketObj);
        } else {
          promise = TicketActionStore.remove(tmpTicketObj);
        }
      }

      $q.when(promise).then(function () {
        var resolvedTicket;
        if (typeof ticketManager['handleTicket' + action] === 'function') {
          resolvedTicket = ticketManager['handleTicket' + action](sevenTicket);
          if (resolvedTicket.error) {
            deffered.reject(resolvedTicket);
          } else {
            deffered.resolve(resolvedTicket);
          }
        } else {
          deffered.reject({
            type: 'warning',
            clientCode: 'T_TICKET_RESOLVE_ACTION_UNKNOWN', // Failed to handle ticket status
            ticket: sevenTicket,
            serverResponse: ticketData
          });
        }
      }, function () {
        deffered.reject({
          type: 'warning',
          clientCode: 'T_TICKET_RESOLVE_STORE_ERR', // Failed to resolve ticket
          ticket: sevenTicket,
          serverResponse: ticketData
        });
      });

      return deffered.promise;
    },

    /**
           * Resolve local ticket state depending on data from server
           * @param {Object} ticketData
           * @param {String} ticketData.requestUuid
           * @param {String} ticketData.id
           * @param {String} ticketData.product
           * @param {Object} ticketData.status
           * @param {String} ticketData.status.value
           * @param {String} action
           * @return {promise}
           */
    resolveTicket: function (ticketData, action) {
      var deffered = $q.defer();
      var sevenTicket = new SevenTicket();
      var unresolvedTicket;
      var failedTicket;
      var ticketMessage;

      // check do we have this ticket locally still unresolved,
      unresolvedTicket = this.isTicketUnresolved(ticketData, action);
      // Check for failed tickets
      failedTicket = checkIfFailedTicketExistsInMemory(ticketData, action);
      if (!unresolvedTicket && !failedTicket) {
        deffered.reject({
          type: 'warning',
          clientCode: 'T_TICKET_NOT_IN_MEMORY',
          ticket: sevenTicket,
          serverResponse: ticketData
        });
      } else {
        // ticket can be either failed or pending for both statuses flow is the same
        sevenTicket = unresolvedTicket || failedTicket;
        // clear pending ticket actions, allow next ticket to be sent to server
        ticketManager.removePendingAction(ticketData.product + '.' + action.toLowerCase());

        // is this error response?
        // it could socket response with error
        // in that case we don't want to update ticket data
        if (isErrorResponse(ticketData)) {
          ticketMessage = ticketManager.handleFailedAction(
            {
              response: ticketData,
              request: sevenTicket
            }
          );
          angular.extend(ticketMessage, { request: sevenTicket });
          deffered.reject(ticketMessage);
        } else {
          // update ticket state in memory and in indexdb
          this.handleTicketSuccess(sevenTicket, ticketData, action).then(function (response) {
            deffered.resolve(response);
          }, function (err) {
            deffered.reject(err);
          });
        }
      }

      return deffered.promise;
    },

    /**
           *
           * @memberOf ticketManager
           * @param {SevenTicket} ticket
           * @param routes
           * @return {*|SevenTicket}
           */
    saveSentAction: function (ticket, routes) {
      return ticketManager.saveSentAction(ticket, routes);
    },

    /**
           * @memberOf ticketManager
           * @param ticket
           * @return {*|{error: boolean, reason: string, type: string}}
           */
    handleFailedAction: function (ticket) {
      return ticketManager.handleFailedAction(ticket);
    },

    /**
           * Get tickets which are not confirmed from wallet as .
           * It means that they have status CASH_TRANSACTION_FAILED
           *
           * @memberOf ticketManager
           * @return {*}
           */
    getFailedPayments: function () {
      return TicketActionStore.getWithStatus(
        'ticketAdd',
        LOCAL_TICKET_STATUS.CASH_TRANSACTION_FAILED
      ).toArray(function (items) {
        return items.map(function (item) {
          return item.paymentId;
        });
      });
    },

    addRevision: function (sevenTicket, revision) {
      var action = sevenTicket.action;
      var tmpTicketObj = {
        store: 'ticket' + action,
        findBy: action === 'Add' ? 'requestUuid' : 'id',
        data: {
          localRevisions: sevenTicket.localRevisions
        }
      };
      tmpTicketObj[tmpTicketObj.findBy] = sevenTicket[tmpTicketObj.findBy];
      sevenTicket.addRevision(revision);
      return TicketActionStore.update(tmpTicketObj);
    },

    /**
     * Send new ticket to server
     *
     * @param {TicketClientRequest} data
     * @param {SevenTicket | ProductTicket} data.localTicket
     * @param {Object} data.ticket - ticket data ready to be sent to server
     * @param {Array} data.ticket.bets - list of bets
     * @param {Number} data.ticket.payin
     * @param {Object} data.metadata
     * @param {String} data.metadata.cpvUuid
     * @param {String} data.metadata.deliveryPlatform
     * @param {String} data.metadata.paymentMethod
     * @param {String} data.metadata.product
     * @param {String} data.metadata.requestUuid
     * @param {Array.<Object>} data.metadata.sources
     * @param {Object} [data.additionalInfo]
     * @param {String} [data.additionalInfo.printTemplate]
     * @param {Object} [data.loyaltyCard]
     * @param {Object} [data.platformVal]
     * @param {Object} config
     * @param {Object} config.ticketRoutes
     * @param {Object} config.requestUuid
     * @param {Object} options
     * @param {Boolean} options.ticketCheckerActive
     * @returns {Promise}
     */
    payinTicket: function (data, config, options) {
      let ticketMessage;
      let tzOffset;
      const { requestUuid, ticketRoutes } = config;
      const { localTicket } = data;
      const { ticketCheckerActive } = options;
      const GAME_ID = localTicket.getProductDisplayId();
      const isSevenTicket = localTicket instanceof SevenTicket;

      nabMessaging.publish('TicketManager.BeforeTicketPayin', {
        requestUuid
      });

      if (isSevenTicket) {
        // Only save SevenTicket into store, ProductTicket it not saved, for now, in store.
        // store is not blocker for payin tickets
        ticketManager.saveSentAction(localTicket, ticketRoutes);
        // this is old hook for formatting data for backend (LuckySix...)
        data.ticket = formatTicket(data);
        delete data.ticketFormatted;
      }

      if (data.additionalInfo) {
        tzOffset = new Date().getTimezoneOffset();
        tzOffset = tzOffset < 0 ? tzOffset / -60 : tzOffset / 60;
        data.additionalInfo.timezone = {
          offset: tzOffset
        };
      }

      // don't send it to backend
      delete data.localTicket;

      return $http({
        url: config.ticketRoutes.add,
        method: 'POST',
        data: data,
        params: {
          requestUuid
        },
        timeout: 15000
      }).then(
        function (response) {
          $log.info(`${LOG_PREFIX} Ticket pay in approved by Betslip API.`, {
            code: 'T_TICKET_PAY_IN_ACCEPTED_BETSLIP',
            request_id: requestUuid,
            product_displayid: GAME_ID,
            localTicket: createLogDataFromTicket(localTicket)
          });
          if (ticketCheckerActive && isSevenTicket && localTicket.localStatus === LOCAL_TICKET_STATUS.PENDING) {
            localTicket.startTicketChecker();
          }

          return {
            response,
            localTicket
          };
        },
        function (response) {
          $log.error(`${LOG_PREFIX} Betslip API rejected pay in.`, {
            product_displayid: GAME_ID,
            localTicket: createLogDataFromTicket(localTicket),
            serverResponse: response.data,
            request_id: requestUuid,
            code: 'T_TICKET_REJECTED_FROM_SERVER',
            ...errorParser.parseUpstream(response)
          });

          // If request is timed out or there are some network issues do not mark the ticket as failed
          if (response.status && (response.status <= 0 || response.status >= 499)) {
            if (ticketCheckerActive && isSevenTicket && localTicket.localStatus === LOCAL_TICKET_STATUS.PENDING) {
              localTicket.startTicketChecker();
            }
            // don't mark ticket as failed
            return {
              error: false,
              response,
              localTicket
            };
          }
          ticketMessage = ticketManager.handleFailedAction(
            {
              response: response.data,
              request: localTicket
            }
          );
          ticketMessage.localTicket = localTicket;
          return ticketMessage;
        }
      );
    },

    /**
       * Get ticket from memory
       * @param {String} action
       * @param {String} findBy ticket property to do search by (requestUuid ex.)
       * @param {any} value value to search for
       * @returns {Boolean | SevenTicket}
       */
    getTicketFromMemory: function (action, findBy, value) {
      return ticketManager.tickets[action.toLowerCase()].find((item) => item[findBy] === value);
    }
  };
}

export default ticketManagerService;
