import BaseError from '@/common/errors/BaseError';
import { getNumberDelimiter } from '@/common/filters/numberFormatter';
import { logService } from '@/common/services/logger';
import { getService } from '@/common/services/ngBridge';
import { useLanguageStore } from '@/common/stores/language';
import { useSevenStore } from '@/modules/seven';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useTicketLayoutsStore } from '@/modules/cms/ticket-layouts';
import { operatorService } from '@/modules/operator';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { getSpsRequestUrl } from '../printerHelpers';
import { usePrinterStore } from '../printerStore';
import { getSevenPrint, getSevenPrintLayouts, generatePrintTemplate } from './sevenPrintService';
import { PrintDataAdditionalData, PrintDataInfo } from '../types';

// Constants
const LOG_PREFIX = '[printTemplateService]';

const getTemplateByPrintDataInfo = (printDataInfo: PrintDataInfo) => {
  const { layout, layoutName = '', nameContainingProduct = '' } = printDataInfo || {};

  if (layout) {
    return {
      template: layout,
      templateName: layoutName,
    };
  }

  const { getLayout } = getSevenPrintLayouts();
  const { template } = getLayout(nameContainingProduct) || getLayout(layoutName) || {};
  const templateName = `${layoutName} (${nameContainingProduct})`;

  return {
    template,
    templateName,
  };
};

const getPrintTemplate = (template: string, bindings: unknown) => {
  const printTemplate = generatePrintTemplate(template, bindings);

  return printTemplate
    .replace(/\n/g, ' ')
    .replace(/\r/g, ' ');
};

const setPrintTemplateConfigData = () => {
  const { setPrintTemplateConfig } = usePrinterStore();
  const { tenant, betshop, device } = useSevenStore();
  const { getModuleDataKeyValue } = useGravitySettingsStore();
  const { ticketActions: { pinRequred: pinRequired } } = useConfigSettingsStore();
  const user = operatorService.data || {};
  const pinVisible = getModuleDataKeyValue<boolean>('config', 'pinVisible');
  const pinBundableWithBarcode = getModuleDataKeyValue<boolean>('config', 'pinBundableWithBarcode');
  const { currency } = tenant;
  const currencyPosition = 'suffix';
  const currencyPrefix = false;
  const confirmationTimeFormat = '%d.%m.%Y %H:%M';

  const mainData = {
    betshop,
    tenant,
    company: tenant, // needed for backwards compatibility
    device,
    user,
  };
  const options = {
    pinRequired,
    pinVisible,
    pinBundableWithBarcode,
    currencyPosition,
    currencyPrefix,
    currency,
    confirmationTimeFormat,
  };
  const configData = {
    mainData,
    options,
  };

  setPrintTemplateConfig(configData);
};

const setSevenPrintConfigData = () => {
  const { printTemplateConfig } = usePrinterStore();
  const ticketLayoutsStore = useTicketLayoutsStore();
  const numberDelimiter = getNumberDelimiter();
  const { currentLang } = useLanguageStore();
  const spsUrl = getSpsRequestUrl();

  getSevenPrintLayouts().addLayouts(ticketLayoutsStore.data);
  getSevenPrint().setConfig({
    locale: currentLang?.id,
    numberFormat: {
      group: numberDelimiter?.group,
      decimal: numberDelimiter?.decimal,
    },
    currency: {
      position: printTemplateConfig?.options.currencyPosition || 'suffix',
      value: printTemplateConfig?.options.currency || 'EUR',
    },
    spsUrl,
  });
};

const getTemplateDataForPrint = (
  printDataInfo: PrintDataInfo,
  context?: any,
  additionalData?: PrintDataAdditionalData,
) => {
  const { printTemplateConfig } = usePrinterStore();
  const { currentLang } = useLanguageStore();
  const { getByKey } = useGravitySettingsStore();
  const translate = getService<{ getTranslationTable: Function }>('$translate');

  const translations = translate?.getTranslationTable();
  const mainData = printTemplateConfig?.mainData;
  const lang = currentLang?.id;
  const ticketCode = context?.id || context?.ticketId || '';

  const { template, templateName } = getTemplateByPrintDataInfo(printDataInfo);
  if (!template) {
    logService.error(`${LOG_PREFIX} No ticket layout found.`, {
      code: 'T_MISSING_TICKET_LAYOUT',
      ticket_code: ticketCode,
      templateName,
    });
    throw new BaseError(
      `No ticket layout for ${templateName}`,
      'T_MISSING_TICKET_LAYOUT',
    );
  }

  const addData = {
    ...additionalData,
    printDateTimestamp: Date.now(),
  };
  const requestUuid = addData?.clientPrintJobIdentifier?.uuid;
  const additionalOptions = getByKey(`module.${printDataInfo.productId}.print`);

  let options = printTemplateConfig?.options;
  if (options && Object.keys(additionalOptions).length) {
    options = { ...options, ...additionalOptions };
  }

  const printTemplateData = {
    requestUuid,
    ticketCode,
    lang,
    template,
    templateName,
    mainData,
    addData,
    options,
    translations,
  };

  return printTemplateData;
};

const init = () => {
  setPrintTemplateConfigData();
  setSevenPrintConfigData();
};

export {
  init,
  getTemplateByPrintDataInfo,
  getPrintTemplate,
  setPrintTemplateConfigData,
  getTemplateDataForPrint,
};
