import pako from 'pako';

/* @ngInject */
function SEVENGcmSocketSvc(
  $q,
  $http,
  $log,
  SevenClientConfig,
  nabSocket,
  SevenGamesSvc,
  nabMessaging,
  errorParser,
  GravitySettings
) {
  // Create a promise instance
  var settings = SevenClientConfig.getSettings();
  var connToken = settings.ncm.token;
  var username = settings.ncm.username;
  // TODO: Add additionalChannels
  var conn = null;

  var supportedGames = [
    'GreyhoundRaces',
    'VirtualGreyhoundRaces',
    'VirtualHorseRaces',
    'LuckySix',
    'LuckyX',
    'VirtualMotorcycleSpeedway',
    'SlotCarRaces'
  ];

  var getGcmUrls = function () {
    return settings.api.ngs.lb;
  };

  var shuffle = function (a) {
    var j; var x; var
      i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  };

  var decodeMessage = function (message) {
    var data = message.data;
    var meta = message.meta;

    if (meta.contentEncoding) {
      if (meta.contentEncoding.toUpperCase() === 'DEFLATE') {
        try {
          data = JSON.parse(pako.inflate(data, {
            to: 'string'
          }));
        } catch (e) {
          $log.error('[7Terminal.Ngs] Failed to inflate data', {
            code: 'T_NGS_SCM_FAILED_DEFLATE'
          });
        }
      }
    }

    message.data = data;

    return message;
  };

  var publishToClient = function (game, message) {
    var foundGame = SevenGamesSvc.getGame(game);

    if (foundGame && foundGame.handleIncomingMessage) {
      // game should implement handleIncomingMessage method
      foundGame.handleIncomingMessage({
        game: game,
        data: message.data,
        type: message.type,
        sentTime: message.sentTime
      });
    } else {
      nabMessaging.publish('NCM:' + message.type, {
        productId: game,
        data: message
      });
    }
  };

  var handleChannelMessage = function (game, message) {
    publishToClient(game, decodeMessage(message));
  };

  var onConnectionEstabilished = function () {
    var id;
    var game;
    var i;

    for (i = 0; i < supportedGames.length; ++i) {
      id = supportedGames[i];
      game = SevenGamesSvc.getGame(id);
      const connectToGcm = GravitySettings
        .getModuleDataKeyValue(`module.${id}`, 'connectToGcm') !== false;

      if (game && game.info && game.info.cpvUuid && connectToGcm) {
        conn.on(game.info.cpvUuid).then(
          angular.noop,
          angular.noop,
          handleChannelMessage.bind(null, game.id)
        );

        conn.emit('subscribe', {
          channel: game.info.cpvUuid,
          subChannels: {
            language: settings.global.language,
            deliveryPlatform: settings.global.deliveryPlatform,
            deviceUuid: settings.device.uuid
          }
        });
      }
    }
  };

  var startConnection = function () {
    var defer = $q.defer();
    var gcmroute = shuffle(getGcmUrls())[0] + '/get-lb';

    $http.get(
      gcmroute,
      {
        withCredentials: false,
        headers: {
          Authorization: undefined,
          'SEVEN-LOCALE': undefined
        }
      }
    ).then(function (res) {
      conn = nabSocket.createConnection(
        {
          token: connToken,
          username: username
        },
        'GCM', // just a name in an object of different ids prefixed with GCM
        res.data.url
      );

      conn.on('connect').then(
        angular.noop,
        angular.noop,
        function () {
          onConnectionEstabilished();
          defer.resolve();
        }
      );
    }).catch(function (err) {
      $log.error('[7Terminal.NGS] Failed to fetch GCM route', {
        ...errorParser.parseUpstream(err),
        code: 'T_NGS_FETCH_GCM_HTTP_FAILED'
      });
    });

    return defer.promise;
  };

  return {
    startConnection: startConnection
  };
}

export default SEVENGcmSocketSvc;
