/**
 *
 * @ngInject
 */
function kenoSvc($http, SevenGamesSvc, SevenRoutes) {
  return {

    getResults: function () {
      var DC = window.BootStrapData.company.datacenter.split('_')[1];
      var url = SevenRoutes.ngs[DC] + '/api_open/retail/events.json';
      var kenoGame = SevenGamesSvc.getGame('Keno');

      return $http({
        method: 'GET',
        url: url,
        headers: {
          'X-Nsft-Ngs-Company': window.BootStrapData.company.uuid,
          'X-Nsft-Ngs-Product': kenoGame.info.cpvUuid
        },
        params: {
          product: 'Keno',
          cpvUuid: kenoGame.info.cpvUuid,
          count: 10
        }
      });
    }

  };
}

export default kenoSvc;
