import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { TicketLayout } from './index';

const useTicketLayoutsStore = defineStore('ticketLayoutsStore', () => {
  const data = ref<Array<TicketLayout>>([]);

  const setData = (layouts: Array<TicketLayout>) => {
    data.value = layouts;
  };

  const getLayout = (layoutName: string): TicketLayout | undefined => (
    data.value.find((layout) => layout.name === layoutName)
  );

  return {
    data,
    getLayout,
    setData,
  };
});

export {
  useTicketLayoutsStore,
};
