import { defineStore } from 'pinia';
import { ref } from 'vue';
import LuckySixProductConfig from '../interfaces/LuckySixProductConfig';

export const useLuckySixGameStore = defineStore('luckySixGameStore', () => {
  const productConfig = ref<LuckySixProductConfig | null>(null);

  return {
    productConfig,
  };
});
