import templateUrl from './luckySixUi.html';

(function () {
  angular.module('terminalApp.nabLuckySixUi')
    .component('luckySixUi', {
      templateUrl: templateUrl,
      controller: function (
        $rootScope,
        $filter,
        $scope,
        flashButton,
        SevenGamesSvc,
        nabTrans,
        NabNotifications,
        nabLuckySixBettingService,
        LuckySixUiService,
        BetslipService,
        GamesUiService
      ) {
        var ctrl = this;
        var LuckySix = SevenGamesSvc.getGame('LuckySix');
        var gameState = GamesUiService.checkGameActivity(LuckySix);
        var BettingSvc = LuckySix.getBettingSvc();
        var editMode = false;

        function checkSystemBet() {
          if (BettingSvc.selected.bet.clientId != 2
                        && BettingSvc.ticketInput.input.length > 6) {
            BettingSvc.activateBet(LuckySix.configuredBets[3]);
          }
        }

        function checkIfOneSelected() {
          var valid = true;
          if (BettingSvc.ticketInput.input.length != 1) { // maybe check will be necessary for special bet
            valid = false;
            NabNotifications.show({
              message: nabTrans.translate(
                'notifications.select_one_ball',
                {},
                true
              ),
              type: 'info',
              delay: 4000
            });
          }

          return valid;
        }

        ctrl.$onInit = function () {
          ctrl.activeGame = LuckySix;
          ctrl.gameInfo = LuckySix.getGameInfo();
          ctrl.activeBets = BettingSvc;
          ctrl.betslip = BetslipService;
          ctrl.resolutionMode = $rootScope.resolutionMode;
        };

        /**
                 * Clear currently selected bet
                 */
        ctrl.clearAll = function () {
          BettingSvc.clearAll();
          flashButton.flash = false;
        };

        ctrl.checkGameActivity = function () {
          if (!LuckySix.gameInfo.isActive) {
            NabNotifications.show({
              message: nabTrans.translate(
                'notifications.game_deactivated',
                { game: 'Lucky Six' },
                true
              ),
              type: 'warning',
              delay: 3000
            });

            BettingSvc.clearAll();
            return false;
          }

          if (LuckySix.gameDeactivating) {
            NabNotifications.show({
              message: nabTrans.translate(
                'notifications.game_deactivating',
                { game: 'Lucky Six' },
                true
              ),
              type: 'warning',
              delay: 3000
            });
            BettingSvc.clearAll();
            return false;
          }

          return true;
        };

        /**
                 * Handles incoming click events on all bets
                 * @param value - usually number (bet, value), or index
                 * @param clientId - bet type
                 */
        ctrl.selectSpecialBet = function (clientId, value) {
          if (!ctrl.checkGameActivity()) { return; }

          var bet = $filter('filter')(LuckySix.configuredBets,
            { clientId: clientId, value: true },
            true)[0];

          if (clientId == 12) {
            if (!checkIfOneSelected()) { return; }
          } else {
            BettingSvc.clearAll();
          }

          BettingSvc.activateBet(bet);
          BettingSvc.addToTicketInput(value);
          ctrl.updateFlashButton();
        };

        ctrl.updateFlashButton = function () {
          flashButton.flash = BettingSvc.validateCurrentInput();
        };

        /**
                 * Send created bet to betslip
                 */
        ctrl.sendBet = function () {
          if (!gameState.value) {
            NabNotifications.show(
              {
                message: nabTrans.translate(gameState.messageKey, { game: ctrl.activeGame.translation }, true),
                type: 'warning',
                delay: 3000
              }
            );
            return;
          }

          if (BettingSvc.ticketInput.input.length === 0) {
            NabNotifications.show(
              {
                message: nabTrans.translate(
                  'notifications.select_bet_first',
                  {},
                  true
                ),
                type: 'info',
                delay: 4000
              }
            );

            return;
          }

          if (!flashButton.flash) {
            NabNotifications.show(
              {
                message: nabTrans.translate(
                  'notifications.wrong_ball_number',
                  {},
                  true
                ),
                type: 'info',
                delay: 4000
              }
            );
          } else {
            checkSystemBet();

            var stake = (function () {
              // return current stake only in edit mode,
              // otherwise done't send anything,
              // betslip service will know what to do
              if (editMode) {
                return BetslipService.ticket.stake;
              }
            }());

            var newBet = BettingSvc.finalizeTicketInput();
            var equalStake = LuckySix.gameInfo.equalStake;

            // if equal stake is active enable just one bet type
            if (equalStake.active
                            && BetslipService.ticket.bets.length
                            && ((newBet[0].betType != BetslipService.ticket.bets[0].betType)
                            || newBet[0].combCount != BetslipService.ticket.bets[0].combCount)) {
              NabNotifications.show(
                {
                  message: nabTrans.translate('notifications.error_ticket_types', {}, true),
                  type: 'info',
                  delay: 2000,
                  focus: false
                }
              );
              return;
            }

            // this is used for system bets on equal stake to set equal stake on min system amount
            if (equalStake.active && newBet[0].combCount > 1) {
              equalStake.equalStakeAmount = (newBet[0].stake / newBet[0].numEvents).toFixed(2).toString();
            }

            var error = BetslipService.validateBet(
              newBet,
              editMode
            );

            if (error.msg) {
              NabNotifications.show({
                message: error.msg,
                type: 'warning',
                delay: 3000
              });
            } else {
              if (!editMode) {
                BetslipService.addToBetslip(
                  newBet,
                  stake
                );
                BettingSvc.resetFutureNumber();
              } else {
                BetslipService.editTicket(
                  newBet
                );
                editMode = false;
              }

              ctrl.resetBet();
            }
          }
        };

        /**
                 * Clear currently selected bet
                 */
        ctrl.resetBet = function () {
          BettingSvc.clearAll();
          flashButton.flash = false;
          BettingSvc.resetFutureNumber();
        };

        $scope.$on('7T:Betslip.BetSelected', function (e, eventData) {
          flashButton.flash = eventData.bet.focus;
          editMode = eventData.bet.focus;
          BettingSvc.showActiveBetslipBet(eventData.bet.focus ? eventData.bet : false);
          if (angular.isDefined(BettingSvc.editMode)) {
            BettingSvc.editMode = true;
          }
          if (!eventData.bet.focus) {
            BettingSvc.resetFutureNumber();
          }
        });

        var betslipResetListener = $rootScope.$on('7T:Betslip.Reset', function () {
          nabLuckySixBettingService.resetEqualStake();
          ctrl.resetBet();
        });

        var betslipBetsChangedListener = $rootScope.$on('7T.Betslip.BetsChanged', function (e, data) {
          if (data.type === 'betRemove') {
            nabLuckySixBettingService.resetEqualStake();
            ctrl.resetBet();

            if (data.data.wasSelected) {
              editMode = false;
              if (angular.isDefined(BettingSvc.editMode)) {
                BettingSvc.editMode = false;
              }
            }
          }
        });

        $scope.$on('$destroy', function () {
          betslipResetListener();
          betslipBetsChangedListener();
        });
      }
    });
})();
