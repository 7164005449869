<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import i18n from '@/plugins/i18n';
import { sevenCurrency } from '@/common/filters';
import { usePaymentsStore } from '@/modules/payments';
import { useUserFundsStore } from '@/modules/user-funds';

const { t } = i18n.global;
const paymentsStore = usePaymentsStore();

const { balance } = storeToRefs(useUserFundsStore());
const balanceTitle = computed(() => {
  if (paymentsStore.paymentSettings.methodName) {
    return paymentsStore.paymentSettings.headerBalanceSubtitle;
  }
  return t('main.cash_balance');
});
</script>

<template>
  <div class="sc-overlay-default h-100 sc-px-3 sc-py-2 text-right t-header-balance-mw-fc">
    <div class="sc-text-body-2">
      {{ balanceTitle }}
    </div>

    <div class="sc-text-h6">
      {{ sevenCurrency(balance) }}
    </div>
  </div>
</template>

<style>
.t-header-balance-mw-fc {
  min-width: fit-content;
}
</style>
