import resultsTemplate5Url from './templates/lucky_six_resultsV5.html';
import resultsTemplate6Url from './templates/lucky_six_resultsV6.html';

angular.module('terminalApp.nabLuckySixUi').controller('LuckySixResultsController', function (
  $scope,
  $filter,
  $stateParams,
  loaderService,
  nabLuckySixResults,
  SevenGamesSvc
) {
  this.$onInit = function () {
    var start = $stateParams.start_date && $stateParams.start_date !== 'false' ? new Date($stateParams.start_date) : new Date().setHours(0, 0, 0, 0);
    var end = $stateParams.end_date && $stateParams.end_date !== 'false' ? new Date($stateParams.end_date) : new Date().setHours(23, 59, 59, 999);

    var params = {
      timeFrom: $filter('date')(start, 'yyyy-MM-dd HH:mm:ss'),
      timeTo: $filter('date')(end, 'yyyy-MM-dd HH:mm:ss'),
      count: $stateParams.count || '10'
    };

    loaderService.showLoader();

    var LuckySix = SevenGamesSvc.getGame('LuckySix');

    $scope.gameVersion = LuckySix.version[0];
    $scope.resultTemplates = {
      5: resultsTemplate5Url,
      6: resultsTemplate6Url
    };

    nabLuckySixResults.getResults(params)
      .then(function () {
        var results = nabLuckySixResults.results;

        loaderService.endLoader();

        if (results && results.data && results.data.data && results.data.data.length > 0) {
          $scope.noResults = false;
          $scope.results = checkResultsData(results.data.data);
        } else {
          $scope.noResults = true;
        }
      });

    /**
         * Backend sends sometimes a response where not all balls are drawn
         * Later after that, socket sends results and duplicate results are rendered
         * See Bug - T48750
         */
    function checkResultsData(data) {
      var results = data;

      for (var i = 0; i < results.length; ++i) {
        if (results[i].draw.length < 35) {
          results.splice(i, 1);
          --i;
        }
      }

      return results;
    }
  };
});
