/* eslint-disable arrow-body-style */
import { ref, Ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import { logService } from '@/common/services/logger';
import * as gravityInterceptor from '@/modules/interceptors/gravityInterceptor';
import { getGravityAppName, getGravityAppNameCf } from '../cmsApiService';
import type { GravityApp } from '../types';

const useCmsAppsStore = defineStore('cmsAppsStore', () => {
  const app: Ref<GravityApp | null> = ref(null);
  const getApp = (tenantUuid: string) => {
    return getGravityAppNameCf(tenantUuid)
      .then((res) => {
        app.value = res.data.app;
        gravityInterceptor.setAppNameHeader(res.data.app.name);
        return res;
      })
      .catch((err) => {
        let code;

        if (axios.isAxiosError(err)) {
          code = err.status || 'unknown_status';
        } else {
          code = err.name;
        }

        logService.error('appsStore - Failed to fetch gravity app', {
          upstream_code: code,
          upstream_message: err.message,
          code: 'T_FETCH_GRAVITY_COMPANY_CF_FAILED',
        });
        return getGravityAppName(tenantUuid)
          .then((res2) => {
            app.value = res2.data.app;
            gravityInterceptor.setAppNameHeader(res2.data.app.name);
            return res2;
          })
          .catch((err2) => {
            let code2;

            if (axios.isAxiosError(err)) {
              code2 = err.status || 'unknow_status';
            } else {
              code2 = err.name;
            }
            logService.error('appsStore - Failed to fetch gravity app', {
              upstream_code: code2,
              upstream_message: err2.message,
              code: 'T_FETCH_GRAVITY_COMPANY_FAILED',
            });
            return false;
          });
      });
  };
  return {
    getApp,
    app,
  };
});

export {
  useCmsAppsStore,
};
