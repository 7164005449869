import { ref } from 'vue';
import { defineStore } from 'pinia';

type MarketingSlotItem = {
  active: boolean,
  createdAt: string,
  customData: null | {
    queryParams?: Map<string, string>,
  },
  endDate: string | null,
  id: number,
  priority: number,
  provider: number,
  reference: string,
  resource?: { name: string, url: string },
  startDate: string | null,
  textContent?: string,
  updatedAt: string,
  url?: string
};

type MarketingSlot = {
  active: boolean,
  app: number,
  createdAt: string,
  customData: object,
  id: number,
  items: Array<MarketingSlotItem>,
  name: string,
  title: string,
  updatedAt: string,
};

type CmsMarketingState = {
  slots: MarketingSlot[];
};

const useCmsMarketingStore = defineStore('cmsMarketing', () => {
  const slots = ref<MarketingSlot[]>([]);
  function addSlots(newSlots: MarketingSlot[]) {
    newSlots.forEach((slot: MarketingSlot) => slots.value.push(slot));
  }

  const getSlot = (slotName: string): MarketingSlot | undefined => {
    const filteredSlot = slots.value.find((slot: MarketingSlot) => slot.name === slotName);
    return filteredSlot;
  };

  const getVisibleSlotItems = (slotName: string): MarketingSlotItem[] => {
    const foundSlot = getSlot(slotName);
    let result:MarketingSlotItem[] = [];
    if (foundSlot) {
      result = foundSlot.items.filter((item: MarketingSlotItem) => {
        if (!item.resource?.url) {
          return false;
        }
        if (item.startDate === null || item.endDate === null) {
          return item.active;
        }
        const date = new Date();
        return ((new Date(item.endDate)).getTime()
         >= date.getTime() && (new Date(item.startDate)).getTime() <= date.getTime()
        && item.active);
      });
    }
    return result;
  };

  return {
    slots,
    addSlots,
    getSlot,
    getVisibleSlotItems,
  };
});

export {
  useCmsMarketingStore,
  CmsMarketingState,
  MarketingSlot,
  MarketingSlotItem,
};
