import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { getUserFunds } from './userFundsApiService';
import { useUserFundsStore } from '../userFundsStore';
import { emitBalanceChangeEvent } from './userFundsEventsService';

// Constants
const BALANCE_CHECK_INTERVAL_TIME = 1 * 60 * 1000;
const LOG_PREFIX = '[userFundsService]';

// Vars
let balanceCheckInterval: number;

const updateBalance = () => (
  getUserFunds().then((response) => {
    const { setBalance } = useUserFundsStore();
    const balanceAmount = response.data;

    setBalance(balanceAmount);
    emitBalanceChangeEvent();
  })
);

const startBalanceCheckInterval = () => {
  clearInterval(balanceCheckInterval);
  balanceCheckInterval = window.setInterval(
    () => {
      updateBalance().catch((error) => {
        logService.error(`${LOG_PREFIX} Failed to fetch balance in 60 second interval.`, {
          code: 'T_BALANCE_UPDATE_FETCH_FAILED',
          upstream_code: error.data?.httpCode || errorParser.parseCode(error),
          upstream_message: errorParser.parseMessage(error),
        });
      });
    },
    BALANCE_CHECK_INTERVAL_TIME,
  );
};

const endBalanceCheckInterval = () => {
  clearInterval(balanceCheckInterval);
};

const init = () => {
  updateBalance().catch((error) => {
    logService.error(`${LOG_PREFIX} Failed to fetch balance on init.`, {
      code: 'T_BALANCE_UPDATE_FETCH_FAILED',
      upstream_code: error.data?.httpCode || errorParser.parseCode(error),
      upstream_message: errorParser.parseMessage(error),
    });
  });
};

export {
  init,
  updateBalance,
  startBalanceCheckInterval,
  endBalanceCheckInterval,
};
