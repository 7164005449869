import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "t-footer" }
const _hoisted_2 = { class: "tbo-footer d-flex sc-bg-500" }

import operatorService from '@/modules/operator/operatorService';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import TClock from '@/common/components/TClock.vue';
import InactivityBar from '@/common/components/InactivityBar.vue';
import TerminalInfo from '@/common/components/TerminalInfo.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TboFooter',
  setup(__props) {

const configSettingsStore = useConfigSettingsStore();
const inactivityTime = configSettingsStore.data.backOfficeInactivityTime.value > 0
  ? configSettingsStore.data.backOfficeInactivityTime.value
  : 30000;

const onEndInactivityTimer = () => {
  operatorService.logout();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(InactivityBar, {
        name: "terminal-backoffice-inactivity",
        orientation: "vertical",
        "on-end-inactivity-timer": onEndInactivityTimer,
        "transition-delay": 5000,
        time: _unref(inactivityTime)
      }, null, 8 /* PROPS */, ["time"]),
      _createVNode(TerminalInfo, { class: "sc-ml-5" }),
      _createVNode(TClock, { class: "ml-auto" })
    ])
  ]))
}
}

})