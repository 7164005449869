import { useNotificationsStore } from '@/common/stores/notifications';
/**
* @class NotificationsService
* @memberof module:"7Terminal.Common"
*/

/* @ngInject */
function NotificationsService() {
  return {
    ...useNotificationsStore()
  };
}

export default NotificationsService;
