import type { DMAcceptorStatus, DMAcceptorType, Money } from '@nsftx/systems-sdk';
import { logService } from '@/common/services/logger';
import BaseError from '@/common/errors/BaseError';

interface ChangeAcceptorsStatusParams {
  money: Money;
  acceptor: DMAcceptorType;
  status: DMAcceptorStatus;
  resolve: CallableFunction;
  reject: CallableFunction;
}

const changeAcceptorStatus = ({
  money, acceptor, status, reject, resolve,
}: ChangeAcceptorsStatusParams) => {
  money.changeAcceptorStatus(acceptor, status)
    .then(() => {
      logService.info(`[DMAcceptorsService] Acceptors of type ${acceptor} successfully ${status === 'start' ? 'started' : 'stopped'}.`, {
        code: 'T_DM_ACCEPTOR_SUCCESSFULLY_CHANGED_STATUS',
        acceptorType: acceptor,
        action: status,
      });
      return resolve();
    })
    .catch((err: unknown) => {
      logService.error(`[DMAcceptorsService] Acceptors are not initialized. Could not ${status} ${acceptor} acceptor.`, {
        code: 'T_DM_ACCEPTORS_NOT_INITIALIZED',
        acceptorType: acceptor,
        action: status,
        err,
      });
      return reject(new BaseError(
        'Acceptors are not initialized.',
        'T_DM_ACCEPTORS_NOT_INITIALIZED',
      ));
    });
};

export {
  changeAcceptorStatus,
};
