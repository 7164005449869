/* eslint-disable vars-on-top */

/* @ngInject */
export default function nabValidator(ValidatorRules) {
  var NabValidator = function () {
    this._errors = [];

    /**
     * Defined default rules creator
     *
     * @param rules List of rules from server to validate
     * @param input Data to validate against
     * @returns {*}
     * @private
     */
    this._rulesCreator = function (rules, input) {
      var validationRules = [];
      // map server and our rules
      var rulesMapping = {
        GTE: 'MinOrEqual',
        LTE: 'MaxOrEqual',
        GT: 'Min',
        LT: 'Max',
        BO: 'Boolean'
      };
      if (input.length === 0 || rules.length === 0) return false;

      // In case when lucky six/greyhound races we
      // already created structure (in game rules service)
      // needed for validator. They don't have conditions
      // property so just return them if this is true
      if (!rules[0].conditions) return rules;

      // lets create rules to run through validator
      for (var i = 0; i < rules.length; i += 1) {
        var rule = rules[i];

        // when want to validate value from single bet then send
        // params with rule
        var ruleParams = rule.params ? angular.merge(input, rule.params) : input;

        for (var j = 0; j < rule.conditions.length; j += 1) {
          var condition = rule.conditions[j];
          var newRule = {
            transKey: rule.transKey,
            name: rulesMapping[condition.ruleType],
            code: rule.name,
            params: [
              ruleParams[condition.dataType],
              condition.value
            ],
            // eslint-disable-next-line no-loop-func
            transRef: (function () {
              var newInput = angular.copy(angular.merge({}, rule.params || {}, input));
              newInput.value = condition.value;
              return newInput;
            })()
          };

          validationRules.push(newRule);
        }
      }

      return validationRules;
    };
  };

  /**
   * Validate passed rules
   *
   * @param validationRules
   * @param input
   * @param reset
   * @param rulesCreator
   * @returns {Array}
   */
  NabValidator.prototype.validate = function (validationRules, input, reset, rulesCreator) {
    validationRules = rulesCreator ? rulesCreator(validationRules, input) : this._rulesCreator(validationRules, input);

    if (reset) {
      this._errors = [];
    }

    for (var i = 0; i < validationRules.length; i += 1) {
      // run validation for current rule
      this.validateRule(validationRules[i]);
    }

    return this._errors;
  };

  /**
   * Will match rule with rule service and run it
   * If we have error, it will add it to error array
   *
   * @param validationRule
   */
  NabValidator.prototype.validateRule = function (validationRule) {
    // do validation
    var hasError = ValidatorRules['validate' + validationRule.name].apply(this, validationRule.params);

    if (!hasError) {
      this._errors.push(validationRule);
    }
  };

  NabValidator.prototype.registerRulesCreator = function (creator) {
    this._rulesCreator = creator;
  };

  return new NabValidator();
}
