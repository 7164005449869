import moment from 'moment';
import {
  type TicketInstance,
  isProductTicketSMResponse,
  isTicketInstance,
  canVoucherBePaidout,
} from '@/modules/seven-betslip-api/helpers';
import { type ProductBet } from '@/modules/seven-betslip-api/types';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { useClientGamesStore } from '@/modules/client-games';
import { type VoucherResponse } from '@/modules/vouchers';
import { logService } from '@/common/services/logger';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import type TicketCheckResult from '../types/TicketCheckResult';
import type TicketCheckAction from '../types/TicketCheckAction';
import type V2TicketAction from '../types/V2TicketAction';
import TicketCheckActions from '../enums/TicketCheckActions';
import { doVoucherPayout } from './voucherActionsService';

const LOG_PREFIX = '[availableActionsHelper]';

const hasRebetAction = (ticket: TicketInstance) => {
  const disabledRaceGames = [
    'GreyhoundRaces',
    'VirtualGreyhoundRaces',
    'VirtualMotorcycleSpeedway',
    'HorseRaces',
    'VirtualHorseRaces',
    'SlotCarRaces',
  ];
  const sbDisabledRebetActions = ['WON', 'PAYEDOUT', 'LOST', 'CLOSED', 'EXPIRED'];
  const smDisabledRebetActions = ['WON', 'CLOSED', 'EXPIRED', 'PAIDOUT'];
  const productId = ticket.getProductDisplayId();
  const clientGamesStore = useClientGamesStore();
  const product = clientGamesStore.getGameById(productId);
  const ticketStatus = ticket.getStatus().toUpperCase();

  if (!product) return false;

  if (disabledRaceGames.includes(productId)) return false;

  if (productId === 'PreMatchBetting') {
    return !(sbDisabledRebetActions.includes(ticketStatus));
  }

  if (isProductTicketSMResponse(ticket.ticket)) {
    const smAllEventsNotStarted = ticket.getTicketBets().find((bet: ProductBet) => bet
      .event.startsAt > moment().utc());

    return !(!smAllEventsNotStarted || smDisabledRebetActions.includes(ticketStatus));
  }

  return true;
};

const getCancelAction = () => ({
  name: TicketCheckActions.cancel,
  translationKey: 'ticket.cancel',
});

const getCloseAction = (): TicketCheckAction => ({
  name: TicketCheckActions.close,
  translationKey: 'actions.close',
});

const getPayoutAction = () => ({
  name: TicketCheckActions.payout,
  translationKey: 'main.payout',
});

const getActivateAction = (): TicketCheckAction => ({
  name: TicketCheckActions.activate,
  translationKey: 'actions.activate_to_balance',
});

const getRebetAction = () => ({
  name: TicketCheckActions.rebet,
  translationKey: 'actions.rebet',
});

const shouldShowActionsInFrame = (productId: string) => {
  const gravitySettingsStore = useGravitySettingsStore();
  return gravitySettingsStore.getModuleDataKeyValue<boolean>(
    `module.${productId}.ticketCheck`,
    'showActionsInFrame',
  );
};

const loadAnonymousTicketActions = (ticket: TicketInstance) => {
  const actions = [];

  if (shouldShowActionsInFrame(ticket.getProductDisplayId())) {
    return [];
  }

  if (ticket.getStatus().toUpperCase() === 'OPEN' && ticket.isCancellable()) {
    actions.push(getCancelAction());
  }

  if (ticket.isWon()) {
    actions.push(getPayoutAction());
  }

  if (hasRebetAction(ticket)) {
    actions.push(getRebetAction());
  }

  actions.push(getCloseAction());
  return actions;
};

const loadTboTicketActions = (ticket: TicketInstance) => {
  const showActionsInFrame = shouldShowActionsInFrame(ticket.getProductDisplayId());

  if (!showActionsInFrame && ticket.isWon()) {
    return [getPayoutAction()];
  }

  return [];
};

const loadAnonymousVoucherActions = (voucher: VoucherResponse) => {
  const actions = [];
  const configSettingsStore = useConfigSettingsStore();

  if (configSettingsStore.data.voucherActivationConfirmation.value) {
    if (canVoucherBePaidout(voucher)) {
      actions.push(getActivateAction());
    }
    actions.push(getCloseAction());
  } else {
    doVoucherPayout(voucher).catch((error: Error) => {
      const notificationsStore = useNotificationsStore();
      notificationsStore.show({
        message: error.message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
    });
  }

  return actions;
};

const loadTboVoucherActions = (voucher: VoucherResponse) => (canVoucherBePaidout(voucher)
  ? [getActivateAction()] : []);

const loadV2TicketActions = (
  checkResult: TicketCheckResult,
  paramActions: V2TicketAction,
) => {
  const actions: Array<TicketCheckAction> = [];

  const isTicket = isTicketInstance(checkResult);
  Object.keys(paramActions).forEach((key) => {
    const value = paramActions[key as TicketCheckActions];
    const action = TicketCheckActions[key.toLowerCase() as TicketCheckActions];

    if (action && value?.active) {
      switch (action) {
        case TicketCheckActions.payout:
          actions.push(getPayoutAction()); break;
        case TicketCheckActions.cancel:
          actions.push(getCancelAction()); break;
        case TicketCheckActions.rebet:
          actions.push(getRebetAction()); break;
        default:
          logService.error(`${LOG_PREFIX} Action ${action} not supported`, {
            product_displayid: isTicket ? checkResult.getProductDisplayId() : checkResult.product,
            ticket_code: isTicket ? checkResult.getDisplayId() : checkResult.id,
            request_id: isTicket ? checkResult.getRequestUuid() : '',
            code: 'T_TICKET_CHECK_ACTION_NOT_SUPPORTED',
          });
      }
    }
  });

  actions.push(getCloseAction());

  return actions;
};

export default {
  loadAnonymousTicketActions,
  loadTboTicketActions,
  loadAnonymousVoucherActions,
  loadTboVoucherActions,
  loadV2TicketActions,
};
