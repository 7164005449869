import axios from 'axios';
import type { TOddsLangChangeData } from './types';

const dipatchOddsLanguageChange = (data: TOddsLangChangeData) => axios.post(
  process.env.LOCAL_SCM_PUBLISH_URL,
  {
    channel: 'localAppConfigChannel',
    type: 'oddsLanguageChange',
    data,
  },
);

const setOddsLanguageState = (data: TOddsLangChangeData) => axios.post(
  process.env.LOCAL_SCM_SET_STATE_URL,
  {
    channel: 'localAppConfigChannel',
    data,
  },
);

export {
  dipatchOddsLanguageChange,
  setOddsLanguageState,
};
