/**
   * Local ticket status
   *
   * @typedef {Object} LOCAL_TICKET_STATUS
   * @readonly
   * @enum {Object}
   */
const LOCAL_TICKET_STATUS = {
  SENT: 'SENT',
  // ticket send to server
  PENDING: 'PENDING',
  // ticket failed (rejected from server, request took too long...)
  FAILED: 'FAILED',
  // ticket accepted on server,
  // founds are reserved
  ACCEPTED: 'ACCEPTED',
  // ticket cash not written in cash register
  CASH_TRANSACTION_FAILED: 'CASH_TRANSACTION_FAILED',
  // for vouchers only
  USED: 'USED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  PAYEDOUT: 'PAYEDOUT',
  CANCELLED: 'CANCELLED',
  // ticket status is unknown (status was never fetched from backend)
  UNKNOWN: 'UNKNOWN',
  // ticket was received from backend and resolved locally
  RESOLVED: 'RESOLVED',
  // ticket was printed
  PRINTED: 'PRINTED',
  NOT_PRINTED: 'NOT_PRINTED'
};

/**
   * Local ticket actions
   *
   * @typedef {Object} LOCAL_TICKET_ACTIONS
   * @readonly
   * @enum {Object}
   */
const LOCAL_TICKET_ACTIONS = {
  REBET: {
    value: 'rebet'
  },
  CANCEL: {
    value: 'cancel'
  },
  PAYOUT: {
    value: 'payout'
  },
  REPRINTCONFIRMATION: {
    value: 'reprintConfirmation'
  },
  PRINTCOPY: {
    value: 'printCopy'
  }
};

/**
   * Pending ticket treshold
   * Ticket with status pending should be ignored after this period
   */
const PENDING_TICKET_TRESHOLD = 28800000; // 8 hours is limit for pending tickets

export {
  PENDING_TICKET_TRESHOLD,
  LOCAL_TICKET_ACTIONS,
  LOCAL_TICKET_STATUS
};
