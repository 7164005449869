import angular from 'angular';
import router from 'angular-ui-router';
import coreModule from '../../core';
import installationService from './installationService';
import tInstallDeviceComponent from './installDeviceComponent';

const moduleName = angular.module('7Terminal.Installation', [
  router,
  'SevenClientLib',
  'SevenClient.Config',
  coreModule
])
  .service('installationService', installationService)
  .component('tInstallDevice', tInstallDeviceComponent)
  .config(function ($stateProvider) {
    $stateProvider.state('app.install', {
      url: 'install',
      template: '<t-install-device></t-install-device>'
    });

    $stateProvider.state('app.reinstall', {
      url: 'reinstall',
      template: '<t-reinstall-device></t-reinstall-device>'
    });
  }).name;

export default moduleName;
