import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from 'vue';
import QrCreator from 'qr-creator';
import { QrCodeConfig } from '../types';


export default /*@__PURE__*/_defineComponent({
  __name: 'QrCode',
  props: {
    config: {}
  },
  setup(__props: any) {

const props = __props;

const canvas = ref();

onMounted(async () => {
  await QrCreator.render({
    text: props.config.text,
    ecLevel: props.config.correctLevel || 'L',
    background: props.config.background || null, // color or null for transparent
    size: props.config.size || 150, // in pixels
  }, canvas.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("canvas", {
    ref_key: "canvas",
    ref: canvas
  }, null, 512 /* NEED_PATCH */))
}
}

})