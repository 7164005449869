type SevenApiRoutes = {
  mainUrl: string,
  cmUrl: string,
  ngs: Array<string>,
  assetsUrl: string,
  tboMainUrl: string,
  reportingUrl: string,
};

export default {
  routes: {} as SevenApiRoutes,
  setRoutes(routes: SevenApiRoutes) {
    this.routes = routes;
  },
};
