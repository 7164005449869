<template>
  <SCAppBar>
    <div class="h-100 w-100">
      <div
        class="d-flex justify-content-between align-items-center
            w-100 h-100 flex-wrap sc-pl-5"
      >
        <img
          v-load-image="{ imgSrc, errImgSrc }"
          class="h-100"
          src=""
        >
        <div class="sc-pl-3 sc-py-3 d-flex h-100">
          <div class="sc-pr-5 text-right d-flex flex-column justify-content-center">
            <span>{{ $t('user.type_operator') }}</span>
            <span class="font-weight-bold">{{ user.username }}</span>
          </div>
          <SCButton
            v-ga-track-event="{
              eventName: 'Terminal_Backoffice',
              eventParams: { event: 'Exit TBO' },
            }"
            :variant="ButtonVariant.Primary"
            class="tbo-header__logout-btn h-100 text-center"
            @click="logout"
          >
            {{ $t('main.log_out') }}
          </SCButton>
        </div>
      </div>
    </div>
  </SCAppBar>
</template>

<script setup lang="ts">
import { SCButton, SCAppBar, ButtonVariant } from '@nsftx/seven-components';
import { vLoadImage } from '@/common/directives/LoadImage';
import resourcesService from '@/modules/cms/resources/resourcesService';
import operatorService from '@/modules/operator/operatorService';
import { OperatorData } from '@/modules/operator/types';
import { vGaTrackEvent } from '@/modules/google-analytics';

const user: OperatorData = <OperatorData>operatorService.data;
const imgSrc: string = resourcesService.getS3LogoUrl();
const errImgSrc: string = resourcesService.getLogoUrl();

const logout = () => operatorService.logout();
</script>

<style scoped lang="scss">
.tbo-header__logout-btn {
  width: 9.5rem;
}
</style>
