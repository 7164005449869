import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import axios, { AxiosError } from 'axios';
import i18n from '@/plugins/i18n';
import {
  useNotificationsStore, TNotificationTypeEnum,
} from '@/common/stores/notifications';
import { logService } from '@/common/services/logger';
import { getService } from '@/common/services/ngBridge';
import { useSevenStore } from '@/modules/seven';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import {
  useTicketCheckStore,
  ticketCheckStrategyService,
  closeTicketCheckModalsEventPubSub,
  CLOSE_TICKET_CHECK_MODALS_ID,
} from '@/modules/ticket-check';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { type VoucherResponse, voucherManager } from '@/modules/vouchers';
import { workingTimeService } from '@/modules/working-time';
import { betslipApi, betslipApiHelpers } from '@/modules/seven-betslip-api';
import type {
  CashoutPayload,
  TicketOptionsResponse,
  TicketInstance,
} from '@/modules/seven-betslip-api';
import { useSevenActiveShiftStore } from '@/modules/tbo-shift';
import tboGlobalDataService from '@/modules/tbo/tboGlobalDataService';
import { useTboModulesStore } from '@/modules/tbo/tboModulesStore';
import Ticket from '@/modules/seven-betslip-api/interfaces/Ticket';
import { printService } from '@/modules/print';
import VueHooksManager from '@/common/services/HooksManager';
import { sevenCurrency } from '@/common/filters';
import BaseError from '@/common/errors/BaseError';
import voucherValidationService from '@/modules/vouchers/services/voucherValidationService';
import { validateTBOPayout } from './tboTicketsValidationService';
import { validateOperatorPayoutPermission, payoutTicket } from './tboPayoutService';
import axiosInstance from './axiosInstance';
import {
  TboTicketType,
  type TboFormattedTicket,
  type TboTicketsListResponse,
  type TboTicketResponseItem,
  type TboFormattedVoucher,
} from './types';
import { createTicketMetaData } from './helpers';

const { t } = i18n.global;

const formatTicketData = (tickets: Array<TboTicketResponseItem>): Array<TboFormattedTicket> => {
  if (!tickets.length) {
    return [];
  }

  return tickets.map((ticket: any) => {
    const adjustedTicket = {
      ...ticket,
      sent: moment(ticket.ticketDateTimeUTC).format('DD.MM.yyyy HH:mm:ss'),
      translation: t(`games.${ticket.product.toLowerCase()}`),
    } as TboFormattedTicket;

    // leave first and second"if" when backend implement changes
    if (ticket.status?.name) {
      adjustedTicket.displayStatus = ticket.status.name.toUpperCase();
    } else if (ticket.status?.translated) {
      adjustedTicket.displayStatus = ticket.status.translated.toUpperCase();
    } else {
      adjustedTicket.displayStatus = ticket.status.toUpperCase();
    }

    return adjustedTicket;
  });
};

const formatVoucherData = (vouchers: Array<VoucherResponse>)
: Array<TboFormattedVoucher> => {
  if (!vouchers.length) {
    return [];
  }

  return vouchers.map((voucher:VoucherResponse) => ({
    ...voucher,
    sent: moment(voucher.voucherDateTimeUTC * 1000).format('DD.MM.yyyy HH:mm:ss'),
    translation: t('main.voucher'),
    displayStatus: voucher.status.toUpperCase(),
  } as TboFormattedVoucher));
};

const getVoucherList = (): Promise<Array<TboFormattedVoucher>> => {
  const notificationsStore = useNotificationsStore();

  return voucherManager.getVoucherList()
    .then((response) => formatVoucherData(response.data || []))
    .catch((err) => {
      notificationsStore.show({
        message: t('admin.voucher_history_fail'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
      logService.error('[tboTicketsService] getVoucherList failed', errorParser.parseUpstream(err));
      return err;
    });
};

const getTicketList = (product: string): Promise<Array<TboFormattedTicket>> => {
  const sevenStore = useSevenStore();
  const notificationsStore = useNotificationsStore();
  const configSettingsStore = useConfigSettingsStore();
  const url = tboGlobalDataService.tboGlobalData.ticketHistoryRoutes[product];
  const timeFrom = new Date();
  const timeTo = new Date();
  timeFrom.setMilliseconds(timeTo.getMilliseconds()
                  - (configSettingsStore.data.terminalAdminTicketHistoryRange.value || 300000));

  return axiosInstance.get<TboTicketsListResponse>(url, {
    params: {
      deviceUuid: sevenStore.device.uuid,
      timeFrom: moment.utc(timeFrom).format('yyyy-MM-DD HH:mm:ss'),
      timeTo: moment.utc(timeTo).format('yyyy-MM-DD HH:mm:ss'),
      count: 20,
    },
  }).then((response) => formatTicketData(
    response.data.data || response.data.message || [],
  )).catch((err) => {
    logService.error('[tboTicketsService] getTicketsList failed', errorParser.parseUpstream(err));
    notificationsStore.show({
      message: t('admin.ticket_history_fail'),
      type: TNotificationTypeEnum.warning,
      delay: 3000,
    });
    return err;
  });
};

const printTicketCopy = (ticket: TboFormattedTicket | TboFormattedVoucher, type: TboTicketType) => {
  const $rootScope: any = getService('$rootScope');
  const notificationsStore = useNotificationsStore();
  const printTicket = ticket;
  printTicket.isCopy = true;

  printService.getPrinterStatus().then(() => {
    if (type === TboTicketType.ticket) {
      $rootScope.$emit('7T:Tickets.PrePrint', {
        printAction: (printTicket as TboFormattedTicket).action,
        responseData: printTicket,
        productId: printTicket.product,
      });
    } else {
      printService.printJob({ type: 'voucher' }, printTicket)
        .catch((err: any) => {
          notificationsStore.show({
            message: err.message,
            type: TNotificationTypeEnum.warning,
            delay: 3000,
          });
        });
    }

    notificationsStore.show({
      message: t('print_copy_success'),
      type: TNotificationTypeEnum.info,
      delay: 3000,
    });
  }).catch((data: any) => {
    notificationsStore.show({
      message: data.message,
      type: TNotificationTypeEnum.error,
      delay: 3000,
      id: 'printer_error',
    });
  });
};

const validateCreateVoucher = (amount: number): boolean => {
  const notificationsStore = useNotificationsStore();
  const tboModuleStore = useTboModulesStore();

  if (!tboModuleStore.hasPermission('Tickets', 'createVouchers')) {
    notificationsStore.show({
      message: t('notifications.permissions_forbidden'),
      type: TNotificationTypeEnum.warning,
      delay: 3000,
    });

    return false;
  }

  if (!amount) {
    notificationsStore.show(
      {
        message: t('wallet_transfers_warning_missing_amount'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    return false;
  }

  const workingTimeError = workingTimeService.validateWorkingHours();

  if (workingTimeError.hasError) {
    notificationsStore.show({
      type: TNotificationTypeEnum.warning,
      message: workingTimeError.message || '',
      delay: 5000,
    });
    return false;
  }
  return true;
};

const createVoucher = (amount: number, onSuccess?: () => void) => {
  const notificationsStore = useNotificationsStore();
  if (!validateCreateVoucher(amount)) {
    return;
  }

  printService.getPrinterStatus().then(() => {
    const requestUuid = uuidv4();
    logService.info(`[tboTicketsService] Sending request to create voucher with amount: ${amount}`, {
      code: 'T_TBO_VOUCHER_CREATING',
      request_id: requestUuid,
      product_displayid: 'Voucher',
    });
    voucherManager.getVoucherByOperator(amount, { requestUuid }).then((response) => {
      logService.info('[tboTicketsService] Successfully created voucher.', {
        code: 'T_TBO_VOUCHER_CREATED',
        data: response,
        request_id: requestUuid,
        product_displayid: 'Voucher',
      });

      printService.printJob(
        { type: 'voucher' },
        response,
        {
          clientPrintJobIdentifier: {
            uuid: requestUuid,
            id: 'VoucherPayout',
          },
        },
      ).catch((err: any) => {
        logService.error('[tboTicketsService] Failed to print created voucher', {
          ...errorParser.parseUpstream(err),
          voucher_id: response?.id,
          request_id: requestUuid,
          code: 'T_TBO_VOUCHER_CREATED_PRINT',
        });
        notificationsStore.show({
          message: err.message,
          type: TNotificationTypeEnum.error,
          delay: 3000,
        });
      });
      if (onSuccess) onSuccess();

      notificationsStore.show({
        message: t('voucher.add_success'),
        type: TNotificationTypeEnum.success,
        delay: 3000,
      });
    }).catch((error) => {
      const errMsg = axiosErrorParser.parseMessage(error) || t('notifications.default_error_message');
      logService.error('[tboTicketsService] Failed to create voucher.', {
        code: 'T_TBO_VOUCHER_CREATE_ERROR',
        request_id: requestUuid,
        upstream_code: error?.code || error?.response?.status || '',
        upstream_message: errMsg,
      });
      notificationsStore.show(
        {
          message: errMsg,
          type: TNotificationTypeEnum.warning,
          delay: 3000,
        },
      );
    });
  }).catch((data: any) => {
    logService.error('[tboTicketsService] Voucher cannot be created - printer state is not valid.', {
      upstream_message: data.message,
      code: 'T_TBO_VOUCHER_CREATE_PRINT_STATUS',
    });
    // Something is wrong with printer
    notificationsStore.show({
      message: data.message,
      type: TNotificationTypeEnum.error,
      delay: 3000,
      id: 'printer_error',
    });
  });
};

const checkId = (ticketId: string) => {
  const sevenActiveShiftStore = useSevenActiveShiftStore();

  closeTicketCheckModalsEventPubSub.publish(CLOSE_TICKET_CHECK_MODALS_ID, { reason: 'New ticket is checked' });

  if (!sevenActiveShiftStore.isActive()) {
    const error = new BaseError(t('shift.start_warn'), 'TBO_TICKET_CHECK_SHIFT_NOT_STARTED');
    return Promise.reject(error);
  }

  const strategy = ticketCheckStrategyService.getTicketCheckStrategyDetails(ticketId);
  const params = {
    productId: strategy?.productId || '',
    code: ticketId,
  };

  if (!strategy) {
    const error = new BaseError('Strategy not supported', 'TBO_TICKET_CHECK_STRATEGY_ERROR');
    return Promise.reject(error);
  }

  return strategy.strategyService.checkTicketCode(params)
    .catch((err) => {
      if (err instanceof AxiosError) {
        return Promise.reject(new BaseError(axiosErrorParser.parseMessage(err), 'TBO_TICKET_CHECK_HTTP_ERROR', {
          cause: err,
        }));
      }
      return Promise.reject(new BaseError(errorParser.parseMessage(err), 'TBO_TICKET_CHECK_ERROR', {
        cause: err,
      }));
    });
};

const printPaidoutVoucher = (payoutResponse: VoucherResponse) => {
  printService.printJob(
    { type: 'voucher', action: 'Payout' },
    payoutResponse,
  )
    .then(() => {
      logService.info('[tboTicketsService] Voucher successfully printed', {
        code: 'TBO_VOUCHER_PAYOUT_PRINT_SUCCESS',
        product_displayid: 'Voucher',
        ticket_code: payoutResponse.id,
        totalAmount: payoutResponse.totalAmount,
      });
    })
    .catch((err) => {
      const { message } = err;
      const notificationsStore = useNotificationsStore();

      logService.warn('[tboTicketsService] Print has failed on voucher payout', {
        code: 'TBO_VOUCHER_PAYOUT_PRINT_ERROR',
        upstream_message: message,
      });
      notificationsStore.show({
        message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
    });
};

// 001MOUBF4

const doVoucherPayout = (voucher: VoucherResponse) => {
  const notificationsStore = useNotificationsStore();
  const tboModuleStore = useTboModulesStore();
  const hasPermission = tboModuleStore.hasPermission('Tickets', 'activateVoucher');

  if (!hasPermission) {
    return Promise.reject(
      new BaseError(t('notifications.permissions_forbidden'), 'TBO_VOUCHER_PAYOUT_MISSING_PERMISSION'),
    );
  }

  const workingTimeError = workingTimeService.validateWorkingHours();

  if (workingTimeError.hasError && workingTimeError.message) {
    return Promise.reject(
      new BaseError(workingTimeError.message, 'TBO_VOUCHER_PAYOUT_WORKING_TIME_ERROR'),
    );
  }

  const voucherData = {
    ...voucherManager.generatePayoutVoucherData(),
    id: voucher.id,
    ...(voucher.ticketPin && { ticketPin: voucher.ticketPin }),
  };

  return voucherValidationService.validatePayout(voucherData)
    .then(() => printService.getPrinterStatus()
      .then(() => voucherManager.payoutVoucherByOperator(voucherData, true)
        .then((payoutResponse) => {
          const ticketCheckStore = useTicketCheckStore();
          ticketCheckStore.setResult(payoutResponse);
          printPaidoutVoucher(payoutResponse as VoucherResponse);

          notificationsStore.show({
            message: t('voucher.payout_success', { amount: sevenCurrency(payoutResponse.totalAmount) }),
            type: TNotificationTypeEnum.success,
            delay: 3000,
          });

          logService.info('[tboTicketsService] Voucher successfully paid out', {
            code: 'TBO_VOUCHER_PAYOUT_SUCCESS',
            product_displayid: 'Voucher',
            ticket_code: payoutResponse.id,
            request_id: payoutResponse.requestUuid,
            totalAmount: payoutResponse.totalAmount,
            amount: payoutResponse.amount,
          });

          return Promise.resolve(payoutResponse);
        }))
      .catch((voucherPayoutError) => {
        let message = '';
        if (voucherPayoutError instanceof AxiosError) {
          message = axiosErrorParser.parseMessage(voucherPayoutError);
        } else {
          message = errorParser.parseMessage(voucherPayoutError);
        }

        axiosErrorParser.parseUpstream(voucherPayoutError);
        const formattedError = new BaseError(message, 'TBO_VOUCHER_PAYOUT_HTTP_FAILED', {
          cause: {
            error: voucherPayoutError,
          },
        });

        return Promise.reject(formattedError);
      }))
    .catch((err) => {
      const code = 'T_TBO_VOUCHER_PAYOUT_VALIDATION_ERROR';
      const error = new BaseError(errorParser.parseMessage(err), code, {
        cause: err,
      });

      logService.error('[tboTicketsService] Voucher payout validation error detected.', {
        code,
        ...errorParser.parseUpstream(err),
      });

      return Promise.reject(error);
    });
};

const doTicketPayout = (ticket: TicketInstance) => payoutTicket(ticket);

const cashoutByOperator = (
  ticketOptionsData: TicketOptionsResponse,
  ticket: TicketInstance,
) => {
  const notificationsStore = useNotificationsStore();
  const productDisplayId = ticket.getProductDisplayId();
  if (!ticket.getRequestUuid()) {
    ticket.setRequestUuid(uuidv4());
  }
  const requestUuid = ticket.getRequestUuid();
  const operatorMetaData = createTicketMetaData(productDisplayId, requestUuid);

  return validateTBOPayout(ticket)
    .then(() => {
      const payload: CashoutPayload = {
        requestedAmount: ticketOptionsData.requestedAmount,
        acceptAnyAmountChange: ticketOptionsData.acceptAnyAmountChange || false,
        acceptHigherAmountChange: ticketOptionsData.acceptHigherAmountChange || false,
        metadata: operatorMetaData,
        ticket: {
          id: ticket.getDisplayId(),
        },
        tax: ticket.getTaxData(),
        ticketPin: ticket.getPin(),
      };

      return betslipApi.cashoutTicketByOperator(payload)
        .then((res) => {
          if (!res.data.data.successful) {
            notificationsStore.closeNotificationWithId('ticket.pending_action');
            const message = res.data.data.errorCode || 'Cashout API error';
            notificationsStore.show({
              message,
              type: TNotificationTypeEnum.error,
              delay: 3000,
            });

            const error = new BaseError(message, 'TBO_CASHOUT_API_ERROR');
            return Promise.reject(error);
          }

          return Promise.resolve(res);
        })
        .catch((err) => {
          const ticketCheckStore = useTicketCheckStore();
          let errMessage: string = err.message || '';
          const code = axios.isAxiosError(err)
            ? axiosErrorParser.parseCode(err) : errorParser.parseCode(err);

          if (err.response) {
            errMessage = err.response.data.message;
          }

          if (betslipApiHelpers.isTicketInstance(ticketCheckStore.result)) {
            ticketCheckStore.result.setPin('');
          }

          notificationsStore.closeNotificationWithId('ticket.pending_action');
          notificationsStore.show({
            message: errMessage,
            type: TNotificationTypeEnum.warning,
            delay: 3000,
          });

          const error = new BaseError(errMessage, code);
          return Promise.reject(error);
        });
    });
};

const registerHooks = () => {
  VueHooksManager.getHook('TboBeforeTicketPayout').tapPromise({
    name: 'TboBeforeTicketPayout.Operator',
    // Trigger operator validation before pin validation
    before: 'tbobeforeticketpayout.PinValidation',
    fn: (params: { ticket: Ticket }) => validateOperatorPayoutPermission(params.ticket),
  });
};

const init = () => {
  registerHooks();
};

export {
  init,
  getVoucherList,
  getTicketList,
  printTicketCopy,
  createVoucher,
  checkId,
  doTicketPayout,
  doVoucherPayout,
  cashoutByOperator,
};
