import templateUrl from './keno.html';

/**
 *
 * @ngInject
 */
function kenoResultsController(kenoSvc) {
  this.$onInit = function () {
    var kenoVm = this;
    nabMessaging.doGlobalBroadcast('loader:stop', '');

    kenoSvc.getResults().then(function (response) {
      kenoVm.results = response.data;
    });
  };
}

export default {
  templateUrl: templateUrl,
  controller: kenoResultsController,
  controllerAs: 'kenoVm'
};
