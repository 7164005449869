import './app';
import './services/bootstrapDataProvider';
import './services/environmentProvider';
import './services/generalModulesService';
import './services/langProvider';
import './services/localAppConfigConstant';
import './services/localDataConstant';
import './services/metadataService';
import './services/nabEnvConstant';
import './services/sevenRoutesConstant';
import './services/terminalService';
import './widget/widgetIncludeComponent';
import './widget/widgetService';

export default '7Terminal.Core';
