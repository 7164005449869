import i18n from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { useSevenActiveShiftStore } from '@/modules/tbo-shift';
import { logService } from '@/common/services/logger';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';
import { scanEventPubSub, SCAN_EVENT_ID } from '@/modules/handheld-scan';
import { useTicketCheckStore } from '@/modules/ticket-check';
import { googleAnalyticsService } from '@/modules/google-analytics';
import * as tboTicketsService from './tboTicketsService';

const { t } = i18n.global;

export function useTicketScanInTbo() {
  const sevenActiveShiftStore = useSevenActiveShiftStore();
  const notificationsStore = useNotificationsStore();
  const ticketCheckStore = useTicketCheckStore();
  const router = useRouter();
  let listenerToken: string;

  function doTicketCheck(code: string) {
    googleAnalyticsService.trackEvent('Ticket_Check', {
      event: 'Scan',
      source: 'Terminal BackOffice',
    });
    ticketCheckStore.setId(code);
    tboTicketsService.checkId(ticketCheckStore.enteredId)
      .then((checkResponse) => {
        if (!checkResponse) return;

        ticketCheckStore.setResult(checkResponse.ticket);

        if (!betslipApiHelpers.hasTicketPin(code)) return;

        const pin = betslipApiHelpers.extractTicketPin(code);

        if (betslipApiHelpers.isTicketInstance(ticketCheckStore.result)) {
          ticketCheckStore.result.setPin(pin);
          return;
        }

        if (ticketCheckStore.result
          && betslipApiHelpers.isVoucherInstance(ticketCheckStore.result)
        ) {
          ticketCheckStore.result.ticketPin = pin;
        }
      })
      .catch((err) => {
        const message = err?.message || t('notifications.default_error_message');
        logService.error('[7Tbo] Ticket check failed.', {
          upstream_message: message,
          upstream_code: err.code || '',
          code: 'TBO_TICKET_CHECK_FAILED',
        });
        notificationsStore.show({
          message,
          type: TNotificationTypeEnum.warning,
          delay: 3000,
        });
      });
  }

  function attachListener() {
    listenerToken = scanEventPubSub.subscribe(SCAN_EVENT_ID, (_event, eventData) => {
      const code = eventData?.data.code;
      if (eventData?.callSign.sufix) {
        return;
      }

      if (document.activeElement) {
        // scanning automatically presses enter
        // we need to remove focus from all elements
        (document.activeElement as HTMLElement).blur();
      }

      if (sevenActiveShiftStore.isActive() && code) {
        router.push(
          {
            name: 'checkTicketsId',
            params: {
              id: code,
            },
          },
        ).then(() => { doTicketCheck(code); });
      } else {
        notificationsStore.show(
          {
            message: t('admin.shift_start_missing'),
            type: TNotificationTypeEnum.info,
            delay: 3000,
          },
        );
      }
    });
  }

  function removeListener() {
    PubSub.unsubscribe(listenerToken);
  }

  return {
    attachListener,
    removeListener,
  };
}
