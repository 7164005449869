/**
 * @ngInject
 */
function PerformanceMonitor(
  $window,
  $log,
  $translate,
  SevenGamesSvc,
  GravitySettings,
  NabNotifications,
  switchView
) {
  /**
   * @type {Object}
   * @property {Boolean} enabled
   * @property {Boolean} [reloadIframesOnHighUsage=false]
   */
  var settings = {};
  const FIVE_MINUTES = 1000 * 60 * 5;
  const MEASURE_INTERVAL = FIVE_MINUTES;

  function init() {
    settings = GravitySettings.getByKey('module.performance') || {};
    if (!settings.enabled) {
      $log.debug('[7Terminal.Performance] Performance optimization is not activated.');
      return;
    }
    if (!$window.performance || !$window.performance.memory) {
      $log.warn('[7Terminal.Performance] Memory object not available in window. Abort perfromance monitoring.', {
        code: 'T_PERFORMANCE_MONITORING_ERROR'
      });
      return;
    }
    startMeasuringPerformance();
  }

  function getMemoryUsage() {
    const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = $window.performance.memory;
    const heapUsage = totalJSHeapSize / jsHeapSizeLimit;
    const ramUsageMB = usedJSHeapSize / 1000 / 1000; // MB

    return {
      heapUsage, // *100 to get percentage
      ramUsageMB, // in MB
      jsHeapSizeLimit,
      totalJSHeapSize,
      usedJSHeapSize
    };
  }

  function startMeasuringPerformance() {
    // eslint-disable-next-line angular/interval-service
    setInterval(function () {
      const data = getMemoryUsage();
      const { heapUsage } = data;

      recordMemoryUsage(data);

      // Check memory usage percentage
      if (heapUsage > 0.85 && settings.reloadIframesOnHighUsage) {
        redirectUserToHomePage();
        destroyAllGames();
      } else if (heapUsage > 0.5 && settings.reloadIframesOnHighUsage) {
        destroyInactiveGames();
      }
    }, MEASURE_INTERVAL);
  }

  function recordMemoryUsage(data) {
    const { recordMemoryUsageTreshold, recordMemoryAbsoluteUnitTreshold } = settings;
    const {
      heapUsage, ramUsageMB, jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize
    } = data;
    const memoryUsagePercentage = heapUsage * 100;
    const log = (memoryUsagePercentage >= recordMemoryUsageTreshold) || (ramUsageMB >= recordMemoryAbsoluteUnitTreshold)
      ? $log.info : $log.debug;
    log('[7Terminal.Performance] Recording memory usage', {
      code: 'T_PERFORMANCE_MONITORING_USAGE_ALERT',
      count: memoryUsagePercentage,
      ramUsageMB,
      details: {
        jsHeapSizeLimit,
        totalJSHeapSize,
        usedJSHeapSize
      }
    });
  }

  function redirectUserToHomePage() {
    NabNotifications.show({
      message: $translate.instant('notifications.memory_optimized_err'),
      type: 'warning',
      delay: 5000
    });
    switchView.goToLandingPage();
  }

  function destroyAllGames() {
    var iframes = angular.element('iframe');
    var i = 0;

    for (i; i < iframes.length; i++) {
      $log.debug('[7Terminal.Performance] Remove iFrame for game:', iframes[i].id);
      iframes[i].remove();
    }
  }

  function destroyInactiveGames() {
    var iframes = angular.element('iframe');
    var activeGame = SevenGamesSvc.getActiveGameId();
    var i = 0;

    for (i; i < iframes.length; i++) {
      // Remove iFrame only if game is not active (ie - player is not using this game)
      if (iframes[i].id !== activeGame) {
        $log.debug('[7Terminal.Performance] Remove iFrame for game:', iframes[i].id);
        iframes[i].remove();
      }
    }
  }

  return {
    init
  };
}

export default PerformanceMonitor;
