import { defineStore } from 'pinia';
import { ref } from 'vue';
import { TboShiftData } from './types';

const useSevenActiveShiftStore = defineStore('sevenActiveShift', () => {
  const shift = ref<TboShiftData | null>(null);

  const setActiveShift = (data: TboShiftData): void => {
    shift.value = data;
  };

  const removeActiveShift = (): void => {
    shift.value = null;
  };

  const isActive = (): boolean => shift.value !== null;

  return {
    shift,
    setActiveShift,
    removeActiveShift,
    isActive,
  };
});

export {
  useSevenActiveShiftStore,
};
