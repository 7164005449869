angular.module('7Terminal.Common').provider('routesServiceProvider', function routesServiceProvider($stateProvider) {
  return {
    addRoute: function ({ state, url, template }) {
      $stateProvider.state(state, {
        url,
        template
      });
    },
    $get: function () {
      return {
        addRoute: this.addRoute
      };
    }
  };
});
