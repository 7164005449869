import { defineStore } from 'pinia';
import { ref } from 'vue';
import { loadProductImages } from '@/common/services/CmsProductImageLoader';
import type { CmsMenus, CmsMenuItem } from './types';

const useGravityMenusStore = defineStore('gravityMenus', () => {
  const data = ref<CmsMenus>({
    header: [],
    home_products: [],
    instructions: [],
    results: [],
    virtual_games: [],
    lobby: [],
  });

  const setData = ((menus: CmsMenus): void => {
    Object.assign(data.value, menus);
    Object.values(data.value).forEach((menu: CmsMenuItem[]) => {
      loadProductImages(menu);
    });
  });

  const getData = ((): CmsMenus => data.value);

  const getMenu = ((menuName: keyof CmsMenus): CmsMenuItem[] => data.value[menuName] || []);

  return {
    data: data.value,
    setData,
    getData,
    getMenu,
  };
});

export {
  useGravityMenusStore,
};
