import angular from 'angular';
import router from 'angular-ui-router';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import NotificationsService from './notifications/notificationsService';
import timeoutIndicator from './directives/timeoutIndicator/timeoutIndicator';
import numPad from './directives/numPad/numPadDirective';
import numPadInput from './directives/numPad/numPadInputDirective';
import runBlock from './runBlock';

/**
  * @ngdoc overview
  * @name 7Terminal.Common
  *
  * @description
  * Common logic used across application.
  *
  * @example
  *  <b>script.js</b>
  *  <pre>
  *  import commonModule from './common'
  *  angular.module('myModule', [commonModule]);
  *  </pre>
  *
*/
const moduleName = angular.module('7Terminal.Common', [
  'SevenClient.Config',
  'SevenClientLib',
  'pascalprecht.translate',
  router
])
  .service('NabNotifications', NotificationsService)
  .directive('timeoutIndicator', timeoutIndicator)
  .directive('numPad', numPad)
  .directive('numPadInput', numPadInput)
  .run(runBlock)
  .name;

export default moduleName;
