/**
 *
 * @constructor SevenTicketChecker
 * @ngInject
 */
function SevenTicketChecker(
  $interval,
  $q,
  $log,
  TicketActionStore,
  LOCAL_TICKET_STATUS,
  errorParser
) {
  var transactionCheckTimer = null;

  /**
       * Get accepted tickets
       * @return {*}
       */
  function getAcceptedTickets() {
    return TicketActionStore.getWithStatus(
      'ticketAdd',
      LOCAL_TICKET_STATUS.ACCEPTED
    ).toArray(function (items) {
      return items;
    });
  }

  function getFailedPayments() {
    return TicketActionStore.getWithStatus(
      'ticketAdd',
      LOCAL_TICKET_STATUS.CASH_TRANSACTION_FAILED
    ).toArray(function (items) {
      return items.map(function (item) {
        return item.paymentId;
      });
    });
  }

  /**
       * Sync locally failed tickets to ACCEPTED if they are now confirmed
       * @param {Array} pendingTickets
       */
  function syncFailedTickets(pendingTickets) {
    var pendingIds = pendingTickets.map(function (obj) {
      return obj.uuid;
    });

    // first of all get CASH_TRANSACTION_FAILED tickets
    // and update their status to accepted if they are confirmed
    getFailedPayments().then(
      function (response) {
        var i = 0;
        var ticket;
        for (i; i < response.length; i++) {
          ticket = response[i];
          if (pendingIds.indexOf(ticket.paymentId) < 0) {
            // do update
            ticket.localStatus = LOCAL_TICKET_STATUS.ACCEPTED;
            TicketActionStore.update({
              store: 'ticketAdd',
              findBy: 'requestUuid',
              data: ticket,
              requestUuid: ticket.requestUuid
            }).catch(function (err) {
              $log.warn('[SevenTicketCenter] Failed updating tickets inside store after getFailedPayments.', {
                ...errorParser.parseUpstream(err),
                code: 'T_UPDATE_TICKETS_IN_STORE_FAILED'
              });
            });
          }
        }
      }
    ).catch(function (err) {
      $log.warn('[SevenTicketCenter] getFailedPayments failed', {
        ...errorParser.parseUpstream(err),
        code: 'GET_FAILED_PAYMENTS_ERROR'
      });
    });
  }

  /**
       * Sync not confirmed tickets from backend with locally accepted tickets.
       * @param {Array.<Object>} pendingTickets
       * @param {String} pendingTickets.uuid - payment id
       */
  function syncAcceptedTickets(pendingTickets) {
    var pendingIds = pendingTickets.map(function (obj) {
      return obj.uuid;
    });

    // if there are no pending tickets we don't need to update our tickets to CASH_TRANSACTION_FAILED
    if (pendingTickets.length) {
      return getAcceptedTickets()
        .then(function (response) {
          var promises = {};
          var i = 0;
          var ticket;

          for (i; i < response.length; i++) {
            ticket = response[i];
            if (pendingIds.indexOf(ticket.paymentId) >= 0) {
              // do update
              ticket.localStatus = LOCAL_TICKET_STATUS.CASH_TRANSACTION_FAILED;
              promises[i] = TicketActionStore.update({
                store: 'ticketAdd',
                findBy: 'requestUuid',
                data: ticket,
                requestUuid: ticket.requestUuid
              });
            }
          }

          return $q.when(promises);
        });
    }

    return false;
  }

  /**
       *
       *
       * @callback sourceCallback
       * @return {Array} payments - list of payments
       */

  /**
       * Start checking transaction status between
       * local and external source of failed cash transaction tickets
       * @param {sourceCallback} source
       * @memberOf SevenTicketChecker
       */
  this.startTransactionCheck = function (source) {
    if (transactionCheckTimer) $interval.cancel(transactionCheckTimer);

    transactionCheckTimer = $interval(function () {
      $q.when(source()).then(
        function (data) {
          $q.when(syncAcceptedTickets(data)).then(
            function () {
              // in this case we will return back to ACCEPTED all CASH_TRANSACTION_FAILED ticket
              // if they are any
              syncFailedTickets(data);
            }
          ).catch(function (err) {
            $log.warn('[SevenTicketCenter] syncAcceptedTickets failed!', {
              ...errorParser.parseUpstream(err),
              code: 'T_SYNC_ACCEPTED_TICKETS_FAILED'
            });
          });
        }
      ).catch(function (err) {
        $log.warn('[SevenTicketCenter] Fetching pending transactions failed!', {
          data: err.data,
          ...errorParser.parseUpstream(err),
          code: 'T_FETCH_PENDING_TRANSACTIONS_FAILED'
        });
      });
    }, 1000 * 60 * 60, false); // 60 min
  };

  /**
       * End of check
       *
       * @memberOf SevenTicketChecker
       */
  this.endTransactionCheck = function () {
    $interval.cancel(transactionCheckTimer);
  };
}

export default SevenTicketChecker;
