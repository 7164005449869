import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-container" }

import { onMounted, toRefs } from 'vue';
import { SCModal } from '@nsftx/seven-components';
import { useModalsStore } from '@/common/stores/modals/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'TModal',
  props: {
  modalId: { type: String, required: true },
  withCloseIcon: { type: Boolean, required: false, default: false },
  modelValue: { type: Boolean, required: true, default: false },
  width: { type: String, required: false, default: undefined },
  top: { type: String, required: false, default: undefined },
  left: { type: String, required: false, default: undefined },
  preventClose: { type: Boolean, required: false, default: false },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const modalsStore = useModalsStore();

const props = __props;
const {
  modelValue,
  width,
  top,
  left,
  preventClose,
  modalId,
} = toRefs(props);

const emit = __emit;

const onClose = () => {
  if (!preventClose.value) {
    emit('update:modelValue', false);
  }
};

onMounted(() => {
  modalsStore.registerModal(props.modalId, modelValue, emit);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(SCModal), {
      id: _unref(modalId),
      "model-value": _unref(modelValue),
      "with-close-icon": props.withCloseIcon,
      width: _unref(width),
      top: _unref(top),
      left: _unref(left),
      "onUpdate:modelValue": onClose
    }, {
      title: _withCtx(() => [
        _renderSlot(_ctx.$slots, "title")
      ]),
      content: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      actions: _withCtx(() => [
        _renderSlot(_ctx.$slots, "actions")
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["id", "model-value", "with-close-icon", "width", "top", "left"])
  ]))
}
}

})