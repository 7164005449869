import { useSevenStore } from '@/modules/seven';
import { useLanguageStore } from '@/common/stores/language';
import type { GravityGetArticleResponse, GravityGetAppResponse } from './types';
import { instance, cfInstance } from './axiosInstance';

const V1_PREFIX = '/v1';

const getGravityAppName = (tenantUuid: string) => instance
  .get<GravityGetAppResponse>(
  `${V1_PREFIX}/terminal/apps/${tenantUuid}?extend=company`,
  {
    headers: {
      'X-Nsft-SCD-Merge': true,
    },
  },
);

const getGravityAppNameCf = (tenantUuid: string) => cfInstance
  .get<GravityGetAppResponse>(
  `${V1_PREFIX}/terminal/apps/${tenantUuid}?extend=company`,
  {
    headers: {
      'X-Nsft-SCD-Merge': true,
    },
  },
);

const getArticle = (articleName: string) => {
  const sevenStore = useSevenStore();
  const languageStore = useLanguageStore();

  return cfInstance
    .get<GravityGetArticleResponse>(
    `${V1_PREFIX}/terminal/articles/${articleName}`,
    {
      headers: {
        'X-Nsft-SCD-Company-Id': sevenStore.tenant.uuid,
        'X-Nsft-SCD-Locale': languageStore.getCurrentLanguage(),
      },
    },
  );
};

export {
  getGravityAppName,
  getGravityAppNameCf,
  getArticle,
};
