import templateUrl from './bettingArea.html';
import randomSystemTemplateUrl from '../betting_popups/random_system.html';

(function () {
  angular.module('terminalApp.nabLuckySixUi')
    .component('luckySixBettingArea', {
      require: {
        luckySixUi: '^luckySixUi'
      },
      templateUrl: templateUrl,
      controller: LuckySixBettingAreaController
    });

  function LuckySixBettingAreaController($filter,
    arrayService,
    flashButton,
    payinAmountService,
    nabTrans,
    SevenGamesSvc,
    NabNotifications,
    loaderService,
    $rootScope,
    popupService) {
    var ctrl = this;
    var LuckySix = SevenGamesSvc.getGame('LuckySix');
    var BettingSvc = LuckySix.getBettingSvc();
    var color;
    var checkGameActivity;

    var checkColor = function (value) {
      return (value - 1) % 8 === (color - 1);
    };

    ctrl.$onInit = function () {
      var specialBets;
      checkGameActivity = ctrl.luckySixUi.checkGameActivity;
      ctrl.randomSystemTemplateUrl = randomSystemTemplateUrl;
      ctrl.clearAll = ctrl.luckySixUi.clearAll;
      ctrl.selectSpecialBet = ctrl.luckySixUi.selectSpecialBet;
      ctrl.updateFlashButton = ctrl.luckySixUi.updateFlashButton;
      ctrl.activeBets = BettingSvc;
      ctrl.luckySixVersion = LuckySix.version[0];
      ctrl.resolutionMode = $rootScope.resolutionMode;
      ctrl.futureNmbr = arrayService.getArray(10, true);
      ctrl.ballTd = arrayService.getArray(8, true);
      ctrl.ballTr = arrayService.getArray(6, true);
      ctrl.Math = Math;
      flashButton.flash = false;
      ctrl.allColors = LuckySix.config.outcomesColours.value;
      ctrl.allSpecialBets = [];
      ctrl.firstBallColor = [];

      payinAmountService.activateRestriction(
        LuckySix.restrictions.minBetAmount,
        LuckySix.restrictions.minCombBetAmount
      );
      BettingSvc.initializeService(LuckySix);
      BettingSvc.editMode = false;

      /**
             * Get all special bets from configured bets in order
             * to group them for display
             */
      specialBets = LuckySix.getSpecialBets();

      // first bet on list is always firstBallColor
      ctrl.firstBallColor = specialBets.splice(0, 1)[0];
      // the rest of special bets
      ctrl.allSpecialBets = specialBets;

      loaderService.endLoader();
    };

    /** * Sets class for normal bet (ball selected)
         * */

    ctrl.checkBallClass = function (index) {
      var selectedBet;
      var ticketInput;
      // ui-router destroy scope before we leave
      // state so check do we have this property
      if (!BettingSvc.selected) {
        return false;
      }

      selectedBet = BettingSvc.selected.bet;
      ticketInput = BettingSvc.ticketInput;

      return ((selectedBet.clientId < 7 || selectedBet.clientId === 12)
            && ticketInput.input.indexOf(index) > -1);
    };

    ctrl.selectRandomSystem = function () {
      popupService.showPopup('system-bet');
    };

    ctrl.setFutureEvents = function (index) {
      BettingSvc.ticketInput.numEvents = index;
    };

    ctrl.checkMaxBallNumber = function (value) {
      if (!value) {
        return;
      }
      var ticketInput = BettingSvc.ticketInput.input;
      var valid = true;

      if (ticketInput.length === 10 && ticketInput.indexOf(value) < 0) {
        valid = false;

        NabNotifications.show({
          message: nabTrans.translate('notifications.max_ball_number',
            { number: 10 },
            true),
          type: 'info',
          delay: 4000
        });
      }

      return valid;
    };

    ctrl.checkFirstColor = function (value) {
      var ticketInput = BettingSvc.ticketInput.input;
      var valid = true;

      if (ticketInput.length == 4 && ticketInput.indexOf(value) < 0) {
        valid = false;
        NabNotifications.show({
          message: nabTrans.translate(
            'notifications.max_color_number',
            { number: 4 },
            true
          ),
          type: 'info',
          delay: 4000
        });
      }

      return valid;
    };

    ctrl.selectBallBet = function (clientId, value) {
      var bet;
      if (!checkGameActivity()) {
        return;
      }

      if (BettingSvc.selected.color.length > 1
                || BettingSvc.selected.bet.clientId > 6) {
        ctrl.clearAll();
      }

      bet = $filter('filter')(LuckySix.configuredBets,
        { clientId: clientId, value: true },
        true)[0];

      BettingSvc.activateBet(bet, value);

      if (clientId == 0 && ctrl.checkMaxBallNumber(value)) {
        BettingSvc.addToTicketInput(value);
      }

      /** Skip random* */

      if (bet.clientId != 1) {
        ctrl.checkColorBet(value);
      }

      /** If colour is select and you press random unmark color checker* */

      if (bet.clientId == 1) {
        if (BettingSvc.selected.color.length == 1) {
          BettingSvc.selected.color.splice(0);
        }
      }

      ctrl.updateFlashButton();
    };

    /** Activate/deactivate color bet if all same colors are checked* */

    ctrl.checkColorBet = function (value) {
      color = value % 8 != 0 ? value % 8 : 8;

      var balls = BettingSvc.ticketInput.input.filter(checkColor);

      if ((balls.length === 6 && BettingSvc.ticketInput.input.length === 6) && BettingSvc.selected.color.length < 1) {
        ctrl.toggleColorBet(color);
        return;
      }

      /** If color is selected and balls from other color are marked then unmarked
             * check balls and activate color checker if same color balls are marked* */

      if (BettingSvc.ticketInput.input.length == 6 && BettingSvc.selected.color.length < 1) {
        color = BettingSvc.ticketInput.input[BettingSvc.ticketInput.input.length - 1] % 8 != 0 ? BettingSvc.ticketInput.input[BettingSvc.ticketInput.input.length - 1] % 8 : 8;
        balls = BettingSvc.ticketInput.input.filter(checkColor);
        if (balls.length == 6) {
          ctrl.toggleColorBet(color);
          return;
        }
      }

      /** Deactivate color checker if there are more or less than six balls* */

      if ((balls.length < 6 || BettingSvc.ticketInput.input.length > 6) && BettingSvc.selected.color.length == 1) {
        BettingSvc.selected.color.splice(0);
      }
    };

    ctrl.checkIfOneSelected = function () {
      var valid = true;
      if (BettingSvc.ticketInput.input.length != 1) { // maybe check will be necessary for special bet
        valid = false;
        NabNotifications.show({
          message: nabTrans.translate(
            'notifications.select_one_ball',
            {},
            true
          ),
          type: 'info',
          delay: 4000
        });
      }

      return valid;
    };

    ctrl.updateFlashButton = function () {
      flashButton.flash = BettingSvc.validateCurrentInput();
    };

    ctrl.toggleColorBet = function (value) {
      if (!checkGameActivity()) {
        return;
      }
      value = value == 9 ? arrayService.getArray(8, true) : value;
      BettingSvc.selected.color.length > 1 ? ctrl.clearAll() : BettingSvc.resetTicket();
      BettingSvc.activateBet(LuckySix.configuredBets[2], value);
      BettingSvc.recalculateInput();
      ctrl.updateFlashButton();
    };

    ctrl.selectFirstBallColor = function (clientId, value) {
      if (!checkGameActivity()) {
        return;
      }

      if (BettingSvc.selected.bet.clientId != 7) {
        ctrl.clearAll();
      }

      var bet = $filter('filter')(LuckySix.configuredBets,
        { clientId: clientId, value: true }, true)[0];
      BettingSvc.activateBet(bet, value);

      if (ctrl.checkFirstColor(value)) {
        BettingSvc.addToTicketInput(value);
      }
      
      flashButton.flash=BettingSvc.ticketInput.input.length>0;
    };

    loaderService.endLoader();
  }
})();
