import {
  createRouter, createWebHashHistory, RouteRecordRaw, RouteLocationNormalized,
} from 'vue-router';

import TboPage from '@/views/TboPage.vue';
import { checkRoutePermission, getDefaultAdminRoute, getDefaultChildRoute } from '../modules/tbo/routeHelpers';

const MarketingContentPage = () => import(
  /* webpackChunkName: "marketing_content" */
  '@/views/MarketingContent.vue'
);
const TicketCheck = () => import(
  /* webpackChunkName: "ticket_check" */
  '@/views/TicketCheck.vue'
);
const CardOperationsPage = () => import(
  /* webpackChunkName: "tbo_card_operations" */
  '@/views/TboCardOperations.vue'
);
const TboOperatorCardsPage = () => import(
  /* webpackChunkName: "tbo_card_operations_operators" */
  '@/views/TboCardOperationsOperators.vue'
);
const TboTicketsPage = () => import(
  /* webpackChunkName: "tbo_tickets" */
  '@/views/TboTickets.vue'
);
const TboCreateVoucherPage = () => import(
  /* webpackChunkName: "tbo_card_operations_operators" */
  '@/views/TboCreateVoucher.vue'
);
const TboTicketCheckPage = () => import(
  /* webpackChunkName: "tbo_tickets_check" */
  '@/views/TboTicketsCheck.vue'
);
const TboTicketsViewPage = () => import(
  /* webpackChunkName: "tbo_tickets_view" */
  '@/views/TboTicketsView.vue'
);
const HomePage = () => import(
  /* webpackChunkName: "home_page" */
  '@/views/HomePage.vue'
);
const TermsPage = () => import(
  /* webpackChunkName: "terms_page" */
  '@/views/TermsPage.vue'
);
const GamePage = () => import(
  /* webpackChunkName: "game_page" */
  '@/views/GamePage.vue'
);

const GenericIframePage = () => import(
  /* webpackChunkName: "generc_iframe_page" */
  '@/views/GenericIframePage.vue'
);

const HelpPage = () => import(
  /* webpackChunkName: "help_page" */
  '@/views/HelpPage.vue'
);
const TboBillingPage = () => import(
  /* webpackChunkName: "tbo_billing_reports" */
  '@/views/TboBillingReports.vue'
);
const TboWallet = () => import(
  /* webpackChunkName: "tbo_wallet" */
  '@/views/TboWallet.vue'
);
const AcceptorAmount = () => import(
  /* webpackChunkName: "tbo_acceptor_amount" */
  '@/views/TboAcceptorAmount.vue'
);
const TerminalWallet = () => import(
  /* webpackChunkName: "tbo_wallet_terminal" */
  '@/views/TboWalletTerminal.vue'
);
const OperatorsWallet = () => import(
  /* webpackChunkName: "tbo_operators_wallet" */
  '@/views/TboOperatorsWallet.vue'
);

const TboOptions = () => import(
  /* webpackChunkName: "tbo_options" */
  '@/views/TboOptions.vue'
);

const TboOptionsDevice = () => import(
  /* webpackChunkName: "tbo_options_device" */
  '@/views/TboOptionsDevice.vue'
);

const TboOptionsLandingPage = () => import(
  /* webpackChunkName: "tbo_options_landing_page" */
  '@/views/TboOptionsLandingPage.vue'
);

const TboOptionsServiceMode = () => import(
  /* webpackChunkName: "tbo_options_service_mode" */
  '@/views/TboOptionsServiceMode.vue'
);

const TboReporting = () => import(
  /* webpackChunkName: "tbo_reporting" */
  '@/views/TboReporting.vue'
);

const TboProfitReport = () => import(
  /* webpackChunkName: "tbo_profit_report" */
  '@/views/TboProfitReport.vue'
);

const TboTerminalBalanceReport = () => import(
  /* webpackChunkName: "tbo_terminal_balance_report" */
  '@/views/TboTerminalBalanceReport.vue'
);

const TboTurnoverReport = () => import(
  /* webpackChunkName: "tbo_turnover_report" */
  '@/views/TboTurnoverReport.vue'
);

const TboCertificate = () => import(
  /* webpackChunkName: "tbo_certificate" */
  '@/views/TboCertificate.vue'
);

const TboTerminalBalance = () => import(
  /* webpackChunkName: "tbo_terminal_balance" */
  '@/views/TboTerminalBalance.vue'
);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/marketing',
    name: 'Marketing',
    props: (route) => ({ query: route.query }),
    component: MarketingContentPage,
  },
  {
    path: '/home',
    name: 'Home',
    props: (route) => route.query,
    component: HomePage,
  },
  {
    path: '/games',
    name: 'Games',
    props: (route) => route.query,
    component: GamePage,
  },
  {
    path: '/generic',
    name: 'GenerIframePage',
    props: (route) => route.query,
    component: GenericIframePage,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage,
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpPage,
  },
  {
    path: '/results/ticketCheck',
    name: 'TicketCheck',
    component: TicketCheck,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: TboPage,
    redirect: () => {
      if (checkRoutePermission('Wallet')) {
        return { name: 'Wallet' };
      }

      return { name: getDefaultAdminRoute() };
    },
    children: [
      {
        path: 'cardoperations',
        name: 'cardOperations',
        component: CardOperationsPage,
        redirect: () => ({ name: getDefaultChildRoute('cardOperations') }),
        children: [
          {
            path: 'operators',
            name: 'operatorCards',
            component: TboOperatorCardsPage,
          },
        ],
      },
      {
        path: 'wallet',
        name: 'Wallet',
        component: TboWallet,
        redirect: () => ({ name: getDefaultChildRoute('Wallet') }),
        children: [
          {
            path: 'shifthandling',
            name: 'OperatorWallet',
            component: OperatorsWallet,
          },
          {
            path: 'terminal',
            name: 'TerminalWallet',
            component: TerminalWallet,
          },
          {
            path: 'acceptoramount',
            name: 'AcceptorAmount',
            component: AcceptorAmount,
          },
          {
            path: 'terminalbalance',
            name: 'TerminalBalance',
            component: TboTerminalBalance,
          },
        ],
      },
      {
        path: 'reporting',
        name: 'Reporting',
        component: TboReporting,
        redirect: () => ({ name: getDefaultChildRoute('Reporting') }),
        children: [
          {
            path: 'profitreport',
            name: 'ProfitReport',
            component: TboProfitReport,
          },
          {
            path: 'terminalbalancereport',
            name: 'TerminalBalanceReport',
            component: TboTerminalBalanceReport,
          },
          {
            path: 'turnoverreport',
            name: 'TurnoverReport',
            component: TboTurnoverReport,
          },
        ],
      },
      {
        path: 'billing',
        name: 'Billing',
        component: TboBillingPage,
      },
      {
        path: 'tickets',
        component: TboTicketsPage,
        name: 'Tickets',
        redirect: () => ({ name: getDefaultChildRoute('Tickets') }),
        children: [
          {
            path: 'vouchers',
            name: 'createVouchers',
            component: TboCreateVoucherPage,
          },
          {
            path: 'check/:id',
            name: 'checkTicketsId',
            component: TboTicketCheckPage,
          },
          {
            path: 'check/',
            name: 'checkTickets',
            component: TboTicketCheckPage,
          },
          {
            path: 'list',
            name: 'viewTickets',
            component: TboTicketsViewPage,
          },
        ],
      },
      {
        path: 'systeminfo',
        name: 'SystemInfo',
        component: TboOptions,
        redirect: ({ name: 'OptionsDevice' }),
        children: [
          {
            path: 'device',
            name: 'OptionsDevice',
            component: TboOptionsDevice,
          },
          {
            path: 'landing-page',
            name: 'OptionsLandingPage',
            component: TboOptionsLandingPage,
          },
          {
            path: 'service-mode',
            name: 'OptionsServiceMode',
            component: TboOptionsServiceMode,
          },
        ],
      },
      {
        path: 'certificate',
        name: 'Certificate',
        component: TboCertificate,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to: RouteLocationNormalized) => {
  const routerViewElement: HTMLElement | null = document.getElementById('vue-router');
  if (routerViewElement) {
    if (to.matched && to.matched.length > 0) {
      routerViewElement.style.display = 'block';
    } else {
      routerViewElement.style.display = 'none';
    }
  }
});

export { routes };
export default router;
