import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PrinterStatus } from './interfaces/printerMessageData';
import { PrinterStatusCode } from './enums';

const usePrinterStatusStore = defineStore('printerStatusStore', () => {
  const printerStatusData = ref<PrinterStatus>({
    valid: false,
    code: PrinterStatusCode.Undefined,
    message: '',
    paperLow: false,
    paperOut: false,
  });

  const setPrinterStatusData = (printerStatus: PrinterStatus) => {
    printerStatusData.value = printerStatus;
  };

  const isStatusPaperLow = () => printerStatusData.value.paperLow;

  const isStatusPaperOut = () => printerStatusData.value.paperOut;

  const isStatusOffline = () => !printerStatusData.value.valid;

  const isStatusFromEvent = () => printerStatusData.value.statusUpdateSource === 'event';

  return {
    printerStatusData,
    setPrinterStatusData,
    isStatusPaperLow,
    isStatusPaperOut,
    isStatusOffline,
    isStatusFromEvent,
  };
});

export {
  usePrinterStatusStore,
};
