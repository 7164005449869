import BaseError from '@/common/errors/BaseError';
import { ProductTicketResponse, TicketInstance, TicketResponse } from '@/modules/seven-betslip-api';
import { TicketResolveErrorName } from '../types/TicketResolveErrorName';

export default class TicketResolveError extends BaseError<TicketResolveErrorName> {
  constructor(
    public message: string,
    public code: TicketResolveErrorName,
    public options: {
      cause?: unknown;
      context: {
        ticket?: TicketInstance,
        serverResponse: TicketResponse | ProductTicketResponse,
      }
    },
  ) {
    super(message, code, options);
    Object.setPrototypeOf(this, TicketResolveError.prototype);
    this.name = this.constructor.name;
  }

  toString() {
    return `${this.code}: ${this.message}`;
  }
}
