<template>
  <div
    class="d-flex flex-column justify-content-center sc-text-caption-2 sc-text-primary-2 h-100"
  >
    <div>
      <span
        class="fontBold"
      >V:</span> {{ clientVersion }} <span v-if="showBuildVersion">(B: {{ buildVersion }})</span>
    </div>
    <div>
      <span class="fontBold">BID</span>: {{ betshopName }}
    </div>
    <div>
      <span class="fontBold">TID</span>: {{ terminalName }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { useSevenStore } from '@/modules/seven';
import { useENVStore } from '@/common/stores/env';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';

const sevenStore = useSevenStore();
const envStore = useENVStore();
const gravitySettingsStore = useGravitySettingsStore();

const terminalName = sevenStore.device.name;
const betshopName = sevenStore.betshop.name;
const versions = gravitySettingsStore.getByKey('versions');
const showBuildVersion = gravitySettingsStore.getModuleDataKeyValue('config', 'showBuildVersion');
const clientVersion = (versions && versions[sevenStore.tenant.uuid]) || envStore
  .data.client_version;
const buildVersion = envStore.data.build;
</script>
