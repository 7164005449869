import { logService } from '@/common/services/logger';
import { safeAppReload } from '@/common/services/appHelpersService';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { AppMemoryConfig } from '../types';

const LOG_PREFIX = '[appMemoryService]';

function getMemoryUsage() {
  // @ts-ignore
  const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = window.performance.memory;
  const heapUsage = totalJSHeapSize / jsHeapSizeLimit;
  const ramUsageMB = usedJSHeapSize / 1000 / 1000; // MB

  return {
    heapUsage, // *100 to get percentage
    ramUsageMB, // in MB
    jsHeapSizeLimit,
    totalJSHeapSize,
    usedJSHeapSize,
  };
}

export function reloadIfAppOnHighMemoryUsage() {
  const cmsStore = useGravitySettingsStore();
  const settings = cmsStore.getByKey<AppMemoryConfig>('module.performance');
  const { recordAppOnHighMemoryUsage, recordAppOnHighMemoryUsageTreshold } = settings;
  const { ramUsageMB } = getMemoryUsage();
  if (recordAppOnHighMemoryUsage && ramUsageMB > recordAppOnHighMemoryUsageTreshold) {
    logService.warn(`${LOG_PREFIX} App reload on high memory usage.`, {
      ramUsageMB,
      code: 'T_PERFORMANCE_APP_RELOAD_HIGH_USAGE',
    });
    safeAppReload();
  }
}
