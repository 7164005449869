// Module
var code = `<div class="row main__body is--small max-height"> <div class="col-sm-24 greyhoundraces--racecardForecast"> <table class="forecast-table"> <tr ng-repeat="num in \$ctrl.combinations" class="h2h-row forecast-table-header-row"> <td> <div class="text-sm-left h2h-racers"> <div class="vms-racer-{{::num[0]}} h2h-racer">{{num[0] }}</div> <span class="vms-racer-label"> {{ 'games_racer' | translate }} {{ num[0] + ' - '}}</span> <div class="vms-racer-{{::num[2]}} h2h-racer">{{num[2] }}</div> <span class="vms-racer-label"> {{ 'games_racer' | translate }} {{ num[2]}}</span> </div> <div class="text-sm-right h2h-odds"> <button class="btn h2h-btn vms-button" ng-click="\$ctrl.sendBet(num[0],num[2])" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive
                                            || \$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[0] + '-' + num[2]] < 1" ng-class="{'is--active': (\$ctrl.vmsUISvc.headToHead[num[0] + '-' + num[2]] && (\$ctrl.activeGame.isScheduleActive ? \$ctrl.vmsUISvc.scheduledEvents[\$ctrl.activeGame._scheduleSvc.activeRound.eventId].headToHead[num[0] + '-' + num[2]] : true)),
                                            'odd-empty':num[0] < 1}"> <span ng-if="\$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[0] + '-' + num[2]] >= 1" class="bet-odds-forecast-value">{{\$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[0] + '-' + num[2]].toFixed(2) | oddsConverter:\$ctrl.activeGame.config.odds.active}}</span> <span ng-if="\$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[0] + '-' + num[2]] < 1" class="bet-odds-forecast-value">-</span> </button> <button class="btn h2h-btn vms-button" ng-click="\$ctrl.sendBet(num[2],num[0])" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive
                                             || \$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[2] + '-' + num[0]] < 1" ng-class="{'is--active': (\$ctrl.vmsUISvc.headToHead[num[2] + '-' + num[0]] && (\$ctrl.activeGame.isScheduleActive ? \$ctrl.vmsUISvc.scheduledEvents[\$ctrl.activeGame._scheduleSvc.activeRound.eventId].headToHead[num[2] + '-' + num[0]] : true)),
                                           'odd-empty':num[2] < 1}"> <span ng-if="\$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[2] + '-' + num[0]] >= 1" class="bet-odds-forecast-value">{{\$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[2] + '-' + num[0]].toFixed(2) | oddsConverter:\$ctrl.activeGame.config.odds.active}}</span> <span ng-if="\$ctrl.activeGame._bettingSvc.normalBetsOdds.h2h[num[2] + '-' + num[0]] < 1" class="bet-odds-forecast-value">-</span> </button> </div> </td> </tr> </table> </div> </div>`;
// Exports
var _module_exports =code;;
var path = '/games/virtual_motorcycle_speedway/h2hBettingArea.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;