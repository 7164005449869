import PubSub from 'pubsub-js';
import { type WriteCardResponse } from '@nsftx/systems-sdk';
import type { SocketCardDataIn } from './types';
import type { CardDataInfo } from './interfaces';

export const CARD_IN_EVENT_ID = Symbol('7T:CardReader.CardIn');
export const cardInEventPubSub:
PubSubJS.Base<SocketCardDataIn, typeof CARD_IN_EVENT_ID> = PubSub;

export const CARD_OUT_EVENT_ID = Symbol('7T:CardReader.CardOut');
export const cardOutEventPubSub:
PubSubJS.Base<void, typeof CARD_OUT_EVENT_ID> = PubSub;

export const CARD_READ_EVENT_ID = Symbol('7T:CardReader.OnRead');
export const cardReadEventPubSub:
PubSubJS.Base<CardDataInfo, typeof CARD_READ_EVENT_ID> = PubSub;

export const CARD_WRITE_EVENT_ID = Symbol('7T:CardReader.CardWrite');
export const cardWriteEventPubSub:
PubSubJS.Base<WriteCardResponse, typeof CARD_WRITE_EVENT_ID> = PubSub;
