angular.module('7Terminal.Common')
  .directive('preventDefault', function preventDefault() {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        var events = [];

        if (attr.preventDefault) {
          events = attr.preventDefault.split(',');
        } else {
          events.push('keydown');
        }

        events.forEach(function (eventType) {
          element.on(eventType, function (e) {
            e.preventDefault();
          });
        });
      }
    };
  });
