<script setup lang="ts">
import { ref } from 'vue';
import { SCTabs, SCTab, SCHorizontalScroll } from '@nsftx/seven-components';

import router from '@/router';
import i18n from '@/plugins/i18n';
import { useClientGamesStore } from '@/modules/client-games';
import { CmsMenuItem, useGravityMenusStore } from '@/modules/cms/menus';
import { getService } from '@/common/services/ngBridge';
import { CmsMenuResultItem } from '@/modules/results';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const $state: any = getService('$state');

const clientGamesStore = useClientGamesStore();
const gravityMenusStore = useGravityMenusStore();

const { t } = i18n.global;

const menuItems = gravityMenusStore.getMenu('results') as CmsMenuResultItem[];

// Make sure that ticket check item is a first menu item
const navigationItems: CmsMenuItem[] = [
  {
    title: t('ticket.check_ticket'),
    description: '',
    disabled: false,
    icon: '',
    menuName: '',
    priority: 0,
    subtitle: '',
    url: '',
    customData: {
      route: 'app.results.ticketcheck',
    },
  },
];

menuItems.forEach((menuItem: CmsMenuResultItem) => {
  const isGameActive = clientGamesStore.games.find(
    (game) => game.id === menuItem.customData.gameId,
  );

  const newMenuItem = {
    ...menuItem,
    title: t(`games.${menuItem.customData.gameId?.toLowerCase()}`),
    customData: {
      ...menuItem.customData,
      route:
      menuItem.customData.integration
        ? 'app.results.integration'
        : `app.results.${menuItem.customData.gameId?.toLowerCase()}`,
    },
  };

  if (isGameActive) {
    navigationItems.push(newMenuItem);
  }
});

const selectedTabIndex = ref(0);

const onTabSelectionChanged = (selectedMenuIndex: number) => {
  const selectedMenu = navigationItems[selectedMenuIndex] as CmsMenuResultItem;
  selectedTabIndex.value = selectedMenuIndex;

  $state.go(selectedMenu.customData.route, {
    gameId: selectedMenu.customData.gameId,
    id: selectedMenu.customData.gameId?.toLowerCase(),
    integration: selectedMenu.customData.integration,
  });
};

router.beforeEach((route) => {
  const index = navigationItems.findIndex((item: CmsMenuItem) => {
    const productId: string | undefined = item.customData?.gameId?.toLowerCase() || undefined;
    const regExp = new RegExp(`\\b${productId}\\b`);

    return !!regExp.test(route.path.toLowerCase());
  });

  selectedTabIndex.value = index !== -1 ? index : 0;
});
</script>

<template>
  <SCHorizontalScroll
    variant="secondary"
  >
    <SCTabs
      :model-value="selectedTabIndex"
    >
      <SCTab
        v-for="(item, index) in navigationItems"
        :key="index"
        @click="onTabSelectionChanged(index)"
      >
        {{ item.title }}
      </SCTab>
    </SCTabs>
  </SCHorizontalScroll>
</template>
