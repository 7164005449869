angular.module('7Terminal.Common').filter('capitalize', function capitalize() {
  /**
     * Capitalizes certain letters in input string
     * @param type {String} - all: capitalize first letters of every word; this is default
     *                        first: capitalize first letter of first word
     *                        team - uppercase words with two letters only (e.g. FC, AC...)
     */
  return function (input, type) {
    var filterOutput = '';
    var filterType = type || 'all';
    var inputWords;
    var result = [];

    if (!input) {
      filterOutput = input;
    }

    if (filterType === 'first') {
      filterOutput = input.charAt(0).toUpperCase() + input.slice(1);
    } else {
      inputWords = input.split(' ');
      result = [];

      inputWords.forEach(function (word) {
        if (word.length === 2 && filterType === 'team') {
          result.push(word.toUpperCase());
        } else {
          result.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        }
      });

      filterOutput = result.join(' ');
    }

    return filterOutput;
  };
});
