<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { SCToastNotificationGroup, ToastNotification } from '@nsftx/seven-components';
import { useNotificationsStore } from '@/common/stores/notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';

const notificationsStore = useNotificationsStore();
const gravitySettingsStore = useGravitySettingsStore();

const { notifications } = storeToRefs(notificationsStore);
const position = gravitySettingsStore.getModuleDataKeyValue('config', 'notificationsPosition');

const showOverlay = computed(() => notifications
  .value.some((notif) => notif.uiBlocking === true));

const onNotificationUpdate = (updatedNotifications: Array<ToastNotification>) => {
  notificationsStore.updateNotifications(updatedNotifications);
};

</script>

<template>
  <Teleport to="body">
    <div
      v-if="showOverlay"
      class="t-notification-overlay"
    />
    <SCToastNotificationGroup
      v-model="notifications"
      :position="position"
      @update:model-value="onNotificationUpdate"
    />
  </Teleport>
</template>

<style lang="scss">
.t-notification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--sc-zindex-modal);
    // TODO adjust this to the design when it is finished
    background-color: rgb(0,0,0);
    opacity: 60%;
}
</style>
