import HooksManager from '@/common/services/HooksManager';
import type { BeforeTicketActionParams } from '@/common/types';
import type { TicketClientRequest, TicketInstance } from '@/modules/seven-betslip-api';

const validatePayin = (data: { data: {
  localTicket: TicketInstance,
  ticket: TicketClientRequest,
  platformVal?: string,
}, productId: string,
}) => HooksManager
  .BeforeTicketPayin.promise(data);

const validateCancel = (ticket: TicketInstance) => new Promise<
{ ticket: TicketInstance }>((resolve, reject) => {
  HooksManager.BeforeTicketCancel.promise({ ticket }).then(() => {
    resolve({ ticket });
  }).catch((error) => {
    reject(error);
  });
});

const validatePayout = (ticket: TicketInstance) => new Promise<BeforeTicketActionParams>(
  (resolve, reject) => {
    HooksManager.BeforeTicketPayout.promise({ ticket }).then(() => {
      resolve({ ticket });
    }).catch((error) => {
      reject(error);
    });
  },
);

export {
  validatePayin,
  validateCancel,
  validatePayout,
};
