import { ref } from 'vue';
import { defineStore } from 'pinia';
import {
  TicketInstance,
  betslipApiHelpers,
} from '@/modules/seven-betslip-api';
import TicketCheckResult from './types/TicketCheckResult';
import TicketCheckAction from './types/TicketCheckAction';
import StrategyNames from './enums/StrategyNames';
import ticketActionsService from './services/ticketActionsService';

const useTicketCheckStore = defineStore('ticketCheck', () => {
  const fullTicketCode = ref(''); // ex. X62KI5GGF.4366
  const enteredId = ref(''); // ex. X62KI5GGF
  const ticketPin = ref(''); // 4366
  const result = ref<TicketCheckResult | null>(null);
  const actions = ref<Array<TicketCheckAction>>([]);
  const detectedStrategy = ref<StrategyNames | undefined>();

  const setActions = () => {
    actions.value = ticketActionsService.loadActions(result.value, detectedStrategy.value);
  };

  const updateActions = (values: Array<TicketCheckAction>) => {
    actions.value = values;
  };

  const clearActions = () => {
    actions.value.length = 0;
  };

  const setResult = (res: TicketCheckResult | null) => {
    result.value = res;

    if (!result.value) {
      ticketPin.value = '';
      enteredId.value = '';
      fullTicketCode.value = '';
      clearActions();
      return;
    }

    const isTicket = betslipApiHelpers.isTicketInstance(res);
    setActions();

    if (ticketPin.value && isTicket) {
      (result.value as TicketInstance).setPin(ticketPin.value);
    }
  };

  const setId = (id: string) => {
    fullTicketCode.value = id;
    if (betslipApiHelpers.hasTicketPin(id)) {
      enteredId.value = betslipApiHelpers.stripTicketPin(id);
      ticketPin.value = betslipApiHelpers.extractTicketPin(id);
    } else {
      enteredId.value = id;
      ticketPin.value = '';
    }
  };

  const getTicketPin = () => (result.value as TicketInstance)?.getPin() || ticketPin.value;

  const clearTicketPin = (forceClean = false) => {
    // only if PIN not bundled with barcode
    if (forceClean || !betslipApiHelpers.hasTicketPin(fullTicketCode.value)) {
      (result.value as TicketInstance)?.setPin('');
      ticketPin.value = '';
    }
  };

  const setDetectedStrategy = (name: StrategyNames) => {
    detectedStrategy.value = name;
  };

  const clearDetectedStrategy = () => {
    detectedStrategy.value = undefined;
  };

  const clearResult = () => {
    setResult(null);
    clearDetectedStrategy();
  };

  return {
    result,
    enteredId,
    ticketPin,
    actions,
    detectedStrategy,
    setResult,
    clearResult,
    setId,
    setActions,
    updateActions,
    clearActions,
    getTicketPin,
    clearTicketPin,
    setDetectedStrategy,
    clearDetectedStrategy,
  };
});

export {
  useTicketCheckStore,
};
