import i18n from '@/plugins/i18n';
import {
  ProductTicketResponse,
  ProductTicketStatuses,
  ClientTicketActions,
  LocalTicketStatus,
} from '../types';
import ClientTicket from './ClientTicket';

const { t } = i18n.global;

class ProductTicket<T extends ProductTicketResponse = ProductTicketResponse> implements Omit<ClientTicket, 'ticket'> {
  localPin: string;

  action?: ClientTicketActions;

  translation: string;

  localStatus?: LocalTicketStatus;

  ticket: T;

  taxData: Object;

  taxVerified: boolean = false;

  loyaltyCard?: Object | null;

  platformVal?: string;

  clientVal?: string;

  additionalData?: Object;

  constructor(ticket: T) {
    this.ticket = ticket;
    this.localPin = '';
    this.taxData = {};
    this.translation = t(`games.${this.getProductDisplayId().toLowerCase()}`);
    this.loyaltyCard = null;
  }

  getProductDisplayId() {
    return this.ticket.product;
  }

  getDisplayId() {
    return this.ticket.id;
  }

  setPin(pin: string) {
    this.localPin = pin;
  }

  getPin() {
    return this.localPin;
  }

  getStatus() {
    return this.ticket.status.value;
  }

  isSuperBonus() {
    return this.ticket
      .superBonus?.status?.value.toUpperCase() === ProductTicketStatuses.won.toUpperCase();
  }

  getFullPayoutAmount() {
    let winnings = this.getPayoutAmountWithTax();
    if (this.isSuperBonus()) {
      winnings += this.ticket.superBonus?.amount || 0;
    }

    return winnings;
  }

  getPayoutAmountWithTax() {
    return (this.ticket.cashout?.cashoutEnabled && this.ticket.cashout?.winnings)
    || this.ticket.winnings
    || this.ticket.paidOutWinningAmount
    || 0;
  }

  getPayoutAmount() {
    return this.ticket.paidOutAmount || this.ticket.payout
    || this.ticket.totalAmount || this.ticket.winnings;
  }

  getRequestUuid() {
    return this.ticket.requestUuid;
  }

  setRequestUuid(uuid: string) {
    this.ticket.requestUuid = uuid;
  }

  taxAuthVerified() {
    this.taxVerified = true;
  }

  getPayoutTax() {
    return this.ticket.payoutTax;
  }

  getPayInTimeInUTC() {
    return this.ticket.ticketDateTimeUTC;
  }

  getPayment() {
    return this.ticket.payin || this.ticket.stake;
  }

  getTaxData() {
    return this.taxData;
  }

  setTaxData(data: Object) {
    this.taxData = data;
  }

  setTranslation(translation: string) {
    this.translation = translation;
  }

  setAsPending() {
    this.localStatus = LocalTicketStatus.PENDING;
  }

  isWon(): boolean {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      in_play,
      won,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      won_approved,
      cashback,
      expired,
    } = ProductTicketStatuses;
    const status = this.getStatus().toLowerCase();
    // Thus line takes care of NGS games with superbonus set to won.
    // Ticket can be in lost state but if it has superbonus we need to payout ticket.
    // Also ticket can be in play but superbonus is set to won.
    // In that case we must wait for ticket to be resolved (not in play).
    // In case the ticket is expired, then it cannot be won even with superbonus
    if (
      this.isSuperBonus()
      && status !== in_play
      && status !== expired
    ) {
      return true;
    }
    return [won.toLowerCase(), won_approved.toLowerCase(), cashback.toLowerCase()]
      .indexOf(status) >= 0;
  }

  /*
    Prematch and Live use displayPayout prop
    Games products use possiblePayout prop
    Lottery uses payout prop
  */
  getPossiblePayoutAmount() {
    return this.ticket.displayPayoutAmount
    || this.ticket.paidOutAmount
    || this.ticket.displayPayout
    || this.ticket.possiblePayout
    || this.ticket.payoutAmountMax
    || this.ticket.payout;
  }

  setLoyaltyCard(loyaltyCard: Object) {
    this.loyaltyCard = loyaltyCard;
  }

  getLoyaltyCard() {
    return this.loyaltyCard;
  }

  getTicketBets() {
    return this.ticket.bets || [];
  }

  isCancellable() {
    return !(Object.prototype.hasOwnProperty.call(this.ticket, 'cancellable') && !this.ticket.cancellable);
  }
}

export default ProductTicket;
