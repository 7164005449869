import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';

export const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const username = process.env.INTELLIGENT_GAMING_AUTH_USERNAME;
  const password = process.env.INTELLIGENT_GAMING_AUTH_PASSWORD;

  if (!request.headers) {
    request.headers = new AxiosHeaders();
  }

  request.headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;

  return request;
};
