angular.module('7Terminal.Common').directive('animateRipple', function animateRipple() {
  return {
    link: function (scope, elem) {
      function init() {
        attachRipple();
      }

      function attachRipple() {
        var ink; var d; var x; var
          y;

        // prevent animation for going all ower page
        elem.css('overflow', 'hidden');
        elem.css('position', 'relative');

        elem.on('click', function (e) {
          if (elem.find('.ink').length === 0) {
            elem.prepend("<strong class='ink'></strong>"); // strong tag 'cause of collision prevention as strong tag is rarely used
          }
          ink = elem.find('.ink');
          ink.removeClass('animate');
          if (!ink.height() && !ink.width()) {
            d = Math.max(elem.outerWidth(), elem.outerHeight());
            ink.css({ height: d, width: d });
          }
          x = e.pageX - elem.offset().left - ink.width() / 2;
          y = e.pageY - elem.offset().top - ink.height() / 2;
          ink.css({ top: y + 'px', left: x + 'px' }).addClass('animate');
        });
      }

      init();
    }

  };
});
