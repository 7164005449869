import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import IntegratorAPI from './types/IntegratorApi';
import type IntegratorEventData from './types/IntegratorEventData';
import IntegratorName from './types/IntegratorName';

const services = new Map<IntegratorName, IntegratorAPI>();

const shouldSend = (name: IntegratorName, productId: string | undefined) => {
  const gravitySettingsStore = useGravitySettingsStore();
  const serviceName: IntegratorName = gravitySettingsStore.getModuleDataKeyValue(`module.${productId}`, 'clientEventsHandler');

  if (productId === '*' || !productId) {
    return true;
  }

  if (serviceName === name || !serviceName) {
    return true;
  }

  return false;
};

const registerIntegratorService = (name: IntegratorName, api: IntegratorAPI) => {
  services.set(name, api);
};

const sendEvent = (eventName: string, eventData: IntegratorEventData) => {
  const { productId } = eventData.context;
  services.forEach((service, key) => {
    if (shouldSend(key, productId)) {
      service.sendEvent(eventName, eventData);
    }
  });
};

export default {
  sendEvent,
  registerIntegratorService,
};
