import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100 d-block" }
const _hoisted_2 = { class: "tbo-page-content sc-mt-3 sc-mx-3" }
const _hoisted_3 = { class: "row sc-mx-0 h-100" }
const _hoisted_4 = { class: "col-sm-4 sc-px-0" }
const _hoisted_5 = { class: "col-sm-20 h-100" }
const _hoisted_6 = { class: "sc-mr-0 sc-ml-3 h-100 d-block" }

import { onMounted } from 'vue';
import i18n from '@/plugins/i18n';
import { acceptorsService } from '@/modules/acceptors';
import HooksManager from '@/common/services/HooksManager';
import TboSidebar from '@/modules/tbo/components/TboSidebar.vue';
import TboHeader from '@/modules/tbo/components/TboHeader.vue';
import TboFooter from '@/modules/tbo/components/footer/TboFooter.vue';
import { logService } from '@/common/services/logger';
import { axiosErrorParser } from '@/common/services/error-parser';
import { useTicketScanInTbo } from '@/modules/tbo-tickets';
import { useTboCardReadListeners } from '@/modules/tbo/composables/tboCardReadListeners';


export default /*@__PURE__*/_defineComponent({
  __name: 'TboPage',
  setup(__props) {

const { t } = i18n.global;
const { attachListener } = useTicketScanInTbo();
const { registerListeners } = useTboCardReadListeners();

attachListener();

const handleAcceptors = () => {
  logService.info('[TboPage] Stopping acceptors...', {
    code: 'T_DCD_STOP_ACCEPTORS_REQUEST',
    upstream_code: 'TBO',
  });
  acceptorsService.stopAcceptors(true).catch((err) => {
    logService.error('[TboPage] Failed to block accepting money on BackOffice init.', {
      ...axiosErrorParser.parseUpstream(err),
      code: 'TBO_STOP_ACCEPTORS_ERROR',
    });
  });
};

const registerHooks = () => {
  HooksManager.getHook('BeforeAcceptorsStart').tap({
    name: 'BeforeAcceptorsStart.Tbo',
    fn: (params: { errorResponses: Array<{
      message: string,
      code: string,
    }> }) => {
      params.errorResponses.push({
        message: t('acceptors_start_error_tbo'),
        code: 'T_BEFORE_ACCEPTORS_START_TBO_ERROR',
      });
    },
  });
};

onMounted(() => {
  handleAcceptors();
  registerHooks();
  registerListeners();
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TboHeader),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(TboSidebar)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ]),
    _createVNode(TboFooter)
  ]))
}
}

})