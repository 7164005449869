import { type AxiosResponse } from 'axios';
import {
  checkTicketPayInStatus,
  checkTicketByOperator,
  checkTicketByAnonymousPlayer,
  type BetslipApiResponse,
} from '../betslipApi';
import { createProductTicket, normalizeClientAction } from '../helpers';
import {
  ProductTicketStatuses,
  ClientTicketActions,
  ProductTicketResponse,
  TicketForCheck,
  CheckerErrors,
} from '../types';
import ProductTicket from '../models/ProductTicket';

export type CheckMethodReturn = {
  res: AxiosResponse<BetslipApiResponse>,
  updatedTicket: ProductTicket
};
export interface CheckerStartReturn {
  startChecker: () => Promise<CheckMethodReturn>;
  endChecker: () => void;
}

const getCheckMethod = (
  ticket: TicketForCheck,
  action: ClientTicketActions,
  isOperator: boolean,
) => {
  const { requestUuid, productDisplayId, displayId } = ticket;
  if (action === ClientTicketActions.Add) {
    return checkTicketPayInStatus(requestUuid, productDisplayId);
  }

  if (!displayId) {
    return Promise.reject(new Error('Missing display id to check ticket.'));
  }

  if (isOperator) {
    return checkTicketByOperator(displayId);
  }

  return checkTicketByAnonymousPlayer(displayId);
};

const checkTicketStatus = (
  ticket: TicketForCheck,
  action: ClientTicketActions,
  isOperator: boolean,
) => getCheckMethod(ticket, action, isOperator);

const isTicketResolved = (action: string, status: ProductTicketStatuses) => {
  // statuses that are send from product, are not always exactly equal
  // as ProductTicketStatuses. Sometimes we receive it as uppercase etc.
  const lowercasedStatus = status.toString().toLowerCase();

  // if ticket is add action and ticket still in pending state,
  if (action === ClientTicketActions.Add
    && lowercasedStatus === ProductTicketStatuses.pending) {
    return false;
  }

  // cancel action -> if ticket is not in closed or cancelled state
  if (action === ClientTicketActions.Payout
      && [ProductTicketStatuses.payedout.toString(), ProductTicketStatuses.paidout.toString()]
        .indexOf(lowercasedStatus) < 0) {
    return false;
  }

  // payout action -> if ticket is not in paidout or payedout state
  if (action === ClientTicketActions.Cancel
    && [ProductTicketStatuses.closed.toString(), ProductTicketStatuses.cancelled.toString()]
      .indexOf(lowercasedStatus) < 0) {
    return false;
  }
  return true;
};

export const createBetslipTicketChecker = (
  ticket: TicketForCheck,
  action: ClientTicketActions,
  options: {
    interval: number,
    isOperator: boolean,
    pendingTicketRejectPeriod: number,
  },
): CheckerStartReturn => {
  let statusCheckTimer: number;
  const normalizedAction = normalizeClientAction(action);

  function endChecker() {
    window.clearTimeout(statusCheckTimer);
  }

  // eslint-disable-next-line max-len
  const startChecker = () => new Promise<CheckMethodReturn>(
    (resolve, reject) => {
      const { pendingTicketRejectPeriod, isOperator, interval } = options;
      const pendingStartedAt = Date.now();
      const startCylce = () => {
        if (Date.now() - pendingStartedAt > pendingTicketRejectPeriod) {
          const periodPassedErr = new Error('Check period passed');
          periodPassedErr.name = CheckerErrors.PERIOD_PASSED;
          reject(periodPassedErr);
          return;
        }

        statusCheckTimer = window.setTimeout(() => {
          window.clearTimeout(statusCheckTimer);
          checkTicketStatus(ticket, normalizedAction, isOperator)
            .then((res) => {
              const updatedTicket = createProductTicket(
                res.data as ProductTicketResponse,
              );
              const updatedTicketStatus = updatedTicket.getStatus();
              // payout action -> if ticket is not in paidout or payedout state
              if (!isTicketResolved(normalizedAction, updatedTicketStatus)) {
                // re-check
                startCylce();
                return;
              }
              resolve({
                res,
                updatedTicket,
              });
            }).catch((err) => {
              if (err.response?.status === 499
                  || err.response?.status > 500
                  || ['ECONNABORTED', 'ETIMEDOUT'].indexOf(err.code) >= 0
              ) {
                startCylce();
                return;
              }
              reject(err);
            });
        }, interval);
      };
      // kick off check ticket cycle
      startCylce();
    },
  );
  return {
    endChecker,
    startChecker,
  };
};
