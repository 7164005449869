import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { AxiosError } from 'axios';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useAuthStore, authService } from '@/modules/auth';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import TModal from '@/common/components/TModal.vue';
import TPasswordInput from '@/common/components/TPasswordInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TPinLogin',
  setup(__props) {

const authStore = useAuthStore();
const { isPINLoginModalOpened } = storeToRefs(authStore);
const enteredPin = ref('');
const pinChanged = ref(false);
const showPinError = computed(() => !enteredPin.value && pinChanged.value);
const isFormValid = computed(() => !showPinError.value && pinChanged.value);

const { t } = i18n.global;

const onLoginClicked = () => {
  authService.login({
    ...authService.cardDataCredentials.value,
    pin: enteredPin.value,
  }).then((response) => {
    isPINLoginModalOpened.value = false;
    authService.switchToTBO(response.data);
    authService.clearCardData();
  }).catch((error: AxiosError) => {
    const notificationsStore = useNotificationsStore();

    notificationsStore.show({
      message: error.message || error.response?.statusText || error.status?.toString() || '',
      type: TNotificationTypeEnum.error,
      delay: 6000,
    });
  });
};

const cleanUpForm = () => {
  enteredPin.value = '';
  pinChanged.value = false;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: _unref(isPINLoginModalOpened),
    "onUpdate:modelValue": [
      _cache[2] || (_cache[2] = ($event: any) => (_isRef(isPINLoginModalOpened) ? (isPINLoginModalOpened).value = $event : null)),
      cleanUpForm
    ],
    "with-close-icon": "",
    "modal-id": "pin-login",
    top: "88px"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('auth_pin_login')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TPasswordInput, {
          modelValue: enteredPin.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((enteredPin).value = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (pinChanged.value = true))
          ],
          label: _unref(t)('user.pin'),
          error: showPinError.value,
          hint: showPinError.value ? _unref(t)('validation_pin_required') : ''
        }, null, 8 /* PROPS */, ["modelValue", "label", "error", "hint"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        width: "100%",
        disabled: !isFormValid.value,
        onClick: onLoginClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('main.log_in')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant", "disabled"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})