import { each, range } from 'underscore';
import resultsTemplate from './tricastBettingArea.html';

export default {
  templateUrl: resultsTemplate,
  /**
   * @ngInject
   */
  controller: function (
    flashButton,
    SevenGamesSvc,
    ScrUiService
  ) {
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');
    var BettingSvc = SCR.getBettingSvc();
    var self = this;

    this.$onInit = function () {
      this.racers = range(1, SCR.numberOfRacers + 1);
      this.activeGame = SCR;
      this.scrUISvc = ScrUiService;
      this.createRacers();
      this.scrUISvc.disableReverseTricast = false;
      this.scrUISvc.disableTricast = false;
    };

    /** Create objects of racers for tricast and reverse tricast
                 * (set them unchecked)
                 * and  racers array* */

    this.createRacers = function () {
      var racerIndex;
      ScrUiService.clearTricastActiveState();

      each(this.racers, function (position) {
        racerIndex = 'r' + (position);

        self.scrUISvc.tricastFirstPlace[racerIndex] = false;
        self.scrUISvc.tricastSecondPlace[racerIndex] = false;
        self.scrUISvc.tricastThirdPlace[racerIndex] = false;
        self.scrUISvc.tricastAny[racerIndex] = false;
      });
    };

    /** Check first place racer in tricast bet
                 * @param - Number - racer postion* */

    this.checkTricastFirstPlace = function (racer) {
      var tricastFirstPlace;
      this.scrUISvc.tricastFirstPlaces = [];
      BettingSvc.clearAll();

      tricastFirstPlace = this.scrUISvc.tricastFirstPlace;
      tricastFirstPlace[racer] = !tricastFirstPlace[racer]; // toggle racer status

      each(tricastFirstPlace, function (checked, key) {
        if (key.substring(0, 1) === 'r' && checked) {
          self.scrUISvc.tricastFirstPlaces.push(key.substring(1));
        }
      });

      this.validateTricast();
    };

    /** Check second place racer in tricast bet
                 * @param - Number - racer postion* */

    this.checkTricastSecondPlace = function (racer) {
      var tricastSecondPlace;
      this.scrUISvc.tricastSecondPlaces = [];
      BettingSvc.clearAll();

      tricastSecondPlace = this.scrUISvc.tricastSecondPlace;
      tricastSecondPlace[racer] = !tricastSecondPlace[racer]; // toggle racer status

      each(tricastSecondPlace, function (checked, key) {
        if (key.substring(0, 1) === 'r' && checked) {
          self.scrUISvc.tricastSecondPlaces.push(key.substring(1));
        }
      });

      this.validateTricast();
    };

    /** Check third place racer in tricast bet
                 * @param - Number - racer postion* */

    this.checkTricastThirdPlace = function (racer) {
      var tricastThirdPlace;
      this.scrUISvc.tricastThirdPlaces = [];
      BettingSvc.clearAll();

      tricastThirdPlace = this.scrUISvc.tricastThirdPlace;
      tricastThirdPlace[racer] = !tricastThirdPlace[racer]; // toggle racer status

      each(tricastThirdPlace, function (checked, key) {
        if (key.substring(0, 1) === 'r' && checked) {
          self.scrUISvc.tricastThirdPlaces.push(key.substring(1));
        }
      });

      this.validateTricast();
    };

    /**
                 * Check if there are same numbers in combination
                 * hide any bet if anything is checked
                 * */

    this.validateTricast = function () {
      var tricastPlaces = this.scrUISvc.getTricastPlaceData();

      for (var i in tricastPlaces.tricastFirstPlace) {
        if (((tricastPlaces.tricastFirstPlace[i] && tricastPlaces.tricastSecondPlace[i])
                            || (tricastPlaces.tricastFirstPlace[i] && tricastPlaces.tricastThirdPlace[i])
                            || (tricastPlaces.tricastSecondPlace[i] && tricastPlaces.tricastThirdPlace[i]))
                            && (tricastPlaces.tricastFirstPlaces.length < 3
                             || tricastPlaces.tricastSecondPlaces.length < 3
                             || tricastPlaces.tricastThirdPlaces.length < 3)) {
          this.scrUISvc.validForecast = false;
          flashButton.flash = false;
          return;
        }
      }

      this.scrUISvc.disableReverseTricast = tricastPlaces.tricastFirstPlaces.length > 0
                                                          || tricastPlaces.tricastSecondPlaces.length > 0
                                                          || tricastPlaces.tricastThirdPlaces.length > 0;

      flashButton.flash = !(tricastPlaces.tricastFirstPlaces.length < 1
                                          || tricastPlaces.tricastSecondPlaces.length < 1
                                          || tricastPlaces.tricastThirdPlaces.length < 1);

      this.scrUISvc.validForecast = true;
    };

    this.checkReverseTricastBet = function (racer) {
      this.scrUISvc.reverseTricastPlaces = [];
      BettingSvc.clearAll();

      this.scrUISvc.tricastAny[racer] = !this.scrUISvc.tricastAny[racer]; // toggle racer status

      each(this.scrUISvc.tricastAny, function (checked, key) {
        if (key.substring(0, 1) === 'r' && checked) {
          self.scrUISvc.reverseTricastPlaces.push(key.substring(1));
        }
      });

      this.validateReverseTricastBet();
    };

    /**
                 * Disable tricast if any bet is checked
                 * flash add button if there are two racers
                 * */

    this.validateReverseTricastBet = function () {
      this.scrUISvc.disableTricast = this.scrUISvc.reverseTricastPlaces.length > 0;

      if (this.scrUISvc.reverseTricastPlaces.length < 3) {
        flashButton.flash = false;
        this.scrUISvc.validReverseTricast = false;
      } else {
        flashButton.flash = true;
        this.scrUISvc.validReverseTricast = true;
      }
    };
  }

};
