import axios from 'axios';
import * as httpInterceptor from '@/modules/interceptors/httpInterceptor';
import * as dmInterceptor from './dmInterceptor';

const axiosInstance = axios.create();

const defaultErrorHandler = (error: any) => Promise.reject(error);

axiosInstance.interceptors.request.use(
  dmInterceptor.requestSuccess,
  defaultErrorHandler,
);

axiosInstance.interceptors.request.use(
  httpInterceptor.requestSuccess,
  httpInterceptor.requestError,
);

export default axiosInstance;
