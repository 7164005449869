import { defineStore } from 'pinia';
import { ref } from 'vue';
import i18n from '@/plugins/i18n';
import type {
  GetUsersResponse,
  GetUsersResponseColumn,
  GetUsersResponseUser,
} from '@/modules/user/types';

import type {
  CardOperationsAction,
  CardOperationsSearchParams,
  CardOperationsUser,
  CardOperationsUserColumn,
} from './types';

const { t } = i18n.global;

const useCardOperationsStore = defineStore('cardOperations', () => {
  const searchParams = ref<CardOperationsSearchParams>({
    type: '',
    card: '',
    text: {
      userType: '',
      params: {
        search: '',
      },
    },
  });

  const operatorPermissions = ref<Record<string, any>>({
    operatorCards: {
      active: false,
      actions: {},
    },
  });

  const userList = ref<{
    users: Array<CardOperationsUser>,
    columns: Array<CardOperationsUserColumn>
  }>({
    users: [],
    columns: [],
  });

  const getValidColumns = (data: Record<string, GetUsersResponseColumn>)
  : Array<CardOperationsUserColumn> => {
    const validColumns: Array<CardOperationsUserColumn> = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (data[key].visibility) {
        validColumns.push({
          value: key,
          text: t(`admin.table_header_${key}`),
        });
      }
    }

    return validColumns;
  };

  const formatUsers = (data: Array<GetUsersResponseUser>)
  : Array<CardOperationsUser> => data.map((user) => ({
    ...user,
    hasAuth: user.hasAuth ? t('main_yes') : t('main_no'),
    locked: user.locked ? t('main_yes') : t('main_no'),
    authorizationCodeAttemptsExceeded: user.authorizationCodeAttemptsExceeded ? t('main_yes') : t('main_no'),
    has_auth: user.hasAuth,
    is_locked: user.locked,
    authorization_code_attempts_exceeded: user.authorizationCodeAttemptsExceeded,
  }));

  const updateUserList = (usersData: GetUsersResponse): void => {
    userList.value.users = formatUsers(usersData.grid.data);
    userList.value.columns = getValidColumns(usersData.grid.columns);
  };

  const updateSearchParams = (type: string, params: { search: string }): void => {
    if (type === 'card') {
      searchParams.value.type = 'card';
      searchParams.value.card = params.search;
    } else {
      searchParams.value.type = 'text';
      searchParams.value.text.userType = 'operators';
      searchParams.value.text.params = params;
    }
  };

  const setOperatorPerimisionActions = (submodule: string, actions: CardOperationsAction[])
  : void => {
    actions.forEach((action: CardOperationsAction) => {
      operatorPermissions.value[submodule].actions[action.id] = true;
    });
  };
  const populateOperatorPermissions = (data: any): void => {
    // eslint-disable-next-line
    for (const i in operatorPermissions.value) {
      const submoduleId = i;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const submodule = data.submodules.filter((subModule: any) => subModule.id === submoduleId)[0];

      if (submodule) {
        operatorPermissions.value[i].active = true;
        setOperatorPerimisionActions(i, submodule.actions);
      }
    }
  };

  const checkOperatorPermissions = (action: string) => operatorPermissions.value.operatorCards.actions[`operator${action}`];

  const clearUserList = (): void => {
    userList.value.users = [];
    userList.value.columns = [];
  };

  return {
    userList,
    operatorPermissions,
    searchParams,
    updateUserList,
    getValidColumns,
    updateSearchParams,
    populateOperatorPermissions,
    checkOperatorPermissions,
    clearUserList,
  };
});

export {
  useCardOperationsStore,
};
