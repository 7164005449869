type ObjectGeneric = { [key: string]: any };

const formatToken = (token: string) => token.replace('{{', '').replace('}}', '').trim();

const retrieveValueFromToken = (retrieveFrom: ObjectGeneric, formattedToken: string) => (
  formattedToken.split('.').reduce(
    (accumulator, currentValue) => accumulator[currentValue],
    retrieveFrom,
  ) as ObjectGeneric | string
);

/**
 * Replaces the string's tokens that start and end with double curly brace
 *
 * @param str the string that will be interpolated
 * @param obj object whose keys are the same as the variables defined
 * inside the curly braces of `str`
 *
 * ### Example parameters:
 * * string: `Hello {{ name }} {{ role.name }}`
 * * obj: `{ name: 'person', role: { id: 1, name: 'Owner' } }`
 */
function interpolate(str: string, obj: ObjectGeneric) {
  const regEx = /{{(.*?)}}/g;
  let newStr: string = str.slice(); // make a copy
  const matches = str.match(regEx);

  if (matches) {
    matches.forEach((token: string) => {
      const formattedToken = formatToken(token);
      const value = retrieveValueFromToken(obj, formattedToken);
      newStr = newStr.replace(token, value as string);
    });
  }

  return newStr;
}

export { interpolate };
