import { slaveShownEventPubSub, SLAVE_SHOWN_EVENT_ID } from '@/modules/integrator';
import betslipTemplate from '../betslip/betslip.html';

/**
  * @this vm
  * @ngdoc controller
  * @name 7Terminal.Games.controller:GamesController
  *
  * @description
  * This controller will load games betting area using `t-game-iframe` directive.
*/

/**
 * @ngInject
 */
function GamesController(
  $scope,
  $rootScope,
  GravitySettings,
  BetslipService,
  switchView,
  GamesUiService,
  Widgets,
  NabENV
) {
  var vm = this;
  var listener;

  function setData() {
    vm.activeGame = switchView.activeGame;
    Widgets.getByType(['PRODUCT_HOME'], {
      value: switchView.activeGame.id
    }).then(function (widget) {
      if (!widget) {
        vm.onFinally();
      }
    });
    vm.hideBetslip = GravitySettings.getModuleDataKeyValue('module.' + vm.activeGame.id, 'hideBetslip');
  }

  vm.env = NabENV.type;
  vm.gamesUiService = GamesUiService;
  vm.betslipTemplate = betslipTemplate;

  /**
    * @ngdoc method
    * @name GamesController#onFinally
    *
    * @methodOf
    * 7Terminal.Games.controller:GamesController
    *
    * @description
    * This function will be executed when game is loaded. In this moment game
    * is ready for interaction and we can send additional events.
    *
    * We will send event to game that this is BettingArea state.
    *
  */
  vm.onFinally = function (config) {
    const frameId = config?.iframeId || vm.activeGame.id;
    slaveShownEventPubSub.publish(SLAVE_SHOWN_EVENT_ID, {
      productId: frameId,
      route: {
        to: 'home'
      }
    });

    // 7T:Slave.Shown needs to be @deprecated in favor of 7T:UI.Show
    $rootScope.$emit('7T:Slave.Shown', {
      productId: frameId,
      route: {
        to: 'home'
      }
    });
    $rootScope.$emit('7T:UI.Show', {
      productId: frameId,
      name: ['BettingArea']
    });
  };

  listener = $rootScope.$watch(function () {
    return switchView.activeGame.id;
  }, function (oldValue, newValue) {
    if (oldValue && oldValue !== newValue) {
      setData();
    }
  });

  /**
    * @ngdoc method
    * @name GamesController#closeBetslip
    *
    * @methodOf
    * 7Terminal.Games.controller:GamesController
    *
    * @deprecated
    * @description
    * We used this to close betslip for games that are part of terminal app (not in iframe).
    *
  */
  vm.closeBetslip = function () {
    BetslipService.toggleBetslip(
      switchView.activeGame.id,
      false
    );
  };

  $scope.$on('$destroy', function () {
    listener(); // call the de-register function on scope destroy
  });

  setData();
}

export default GamesController;
