import { useUserFundsStore } from '@/modules/user-funds';

(function () {
  angular.module('7Terminal.Tickets')
    .service('TicketValidationService', TicketValidationService);

  /**
  * @ngdoc service
  * @name 7Terminal.Tickets.services:TicketValidationService
  * @description
  * It validates ticket actions by running hooks so other part of the app can validate
  * ticket state or append some data.
  *
* */
  function TicketValidationService(
    $q,
    $translate,
    HooksManager,
    ProductTicket,
    SevenTicket,
    Peripherals
  ) {
    /**
      * @ngdoc method
      * @name 7Terminal.Tickets.services:TicketValidationService#validatePayin
      * @methodOf 7Terminal.Tickets.services:TicketValidationService
      * @param {Object} data
      * @param {SevenTicket | ProductTicket} data.localTicket
      * @param {TicketClientRequest} data.ticket TicketClientRequest object wrapped into instance. See model defintion [here](https://yandr.7platform.net/references/betslip-api/#ticketclientrequest).
      * @param {String} productId Seven Product Display Id e.g. LiveBetting, LuckySix...
      * @description Validate payin action.
      * @returns {Promise}
      */
    function validatePayin(data, productId) {
      const deffered = $q.defer();
      const { balance } = useUserFundsStore();

      const { localTicket } = data;
      const stake = localTicket.getPayment();
      const LOG_PREFIX = 'T_PAYIN_VALIDATE';

      if (balance < stake) {
        deffered.reject({
          message: $translate.instant('notifications.insufficient_funds'),
          code: `${LOG_PREFIX}_INSUFFICIENT_FUNDS`
        });
      } else {
        HooksManager.run('BeforeTicketPayin', { data, productId }).then(() => {
          $q.all({
            peripheralsSvc: Peripherals.validate()
          }).then(() => {
            deffered.resolve(data);
          }).catch((error) => {
            deffered.reject({
              message: error?.message,
              code: error?.code || `${LOG_PREFIX}_PERIPHERALS_ERROR`
            });
          });
        }).catch((error) => {
          deffered.reject(error);
        });
      }

      return deffered.promise;
    }

    return {
      validatePayin
    };
  }
})();
