import { useRouter } from 'vue-router';
import i18n from '@/plugins/i18n';
import {
  cardReaderService,
  cardReadEventPubSub,
  CARD_READ_EVENT_ID,
  cardOutEventPubSub,
  CARD_OUT_EVENT_ID,
  CardNid,
} from '@/modules/card-reader';
import { cardOperationsService } from '@/modules/tbo-card-operations';
import passCardsTboService from '@/modules/customer-card/pass-card/passCardsTboService';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { logService } from '@/common/services/logger';
import { axiosErrorParser } from '@/common/services/error-parser';

const { t } = i18n.global;

const useTboCardReadListeners = () => {
  const notificationsStore = useNotificationsStore();
  const router = useRouter();

  function registerListeners() {
    cardReadEventPubSub.subscribe(CARD_READ_EVENT_ID, (msg, data) => {
      notificationsStore.closeNotificationWithId('invalid_card_data');
      notificationsStore.closeNotificationWithId('valid_card_data');

      switch (data?.Nid) {
        case CardNid.hdioa762:
          cardOperationsService.handleTboCardReadEvent(data)
            .then(() => {
              router.push({ name: 'operatorCards' });
            }).catch((err) => {
              logService.error('[tboCardReadListeners] Error occured while handling operator card read event', {
                code: 'T_TBO_HANDLE_CARD_READ_EVENT_ERR',
                ...axiosErrorParser.parseUpstream(err),
              });
            });
          break;
        case CardNid.passCard:
          passCardsTboService.handleTboCardReadEvent();
          break;
        default:
          notificationsStore.show({
            message: t('notifications.invalid_card_data'),
            type: TNotificationTypeEnum.info,
            id: 'invalid_card_data',
            delay: null,
            actions: [{
              callback() {
                cardReaderService.formatCard().then(() => {
                  notificationsStore.show({
                    message: t('notifications.card_format_success'),
                    type: TNotificationTypeEnum.warning,
                    delay: 3000,
                  });
                }, () => {
                  notificationsStore.show({
                    message: t('notifications.card_format_failed'),
                    type: TNotificationTypeEnum.warning,
                    delay: 3000,
                  });
                });
              },
              text: t('actions.format_card'),
            }],
          });
          router.push({ name: 'operatorCards' });
      }
    });

    cardOutEventPubSub.subscribe(CARD_OUT_EVENT_ID, () => {
      notificationsStore.closeNotificationWithId('invalid_card_data');
      notificationsStore.closeNotificationWithId('overwrite_action');
      notificationsStore.closeNotificationWithId('valid_card_data');
    });
  }

  return {
    registerListeners,
  };
};

export {
  useTboCardReadListeners,
};
