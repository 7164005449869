enum WorkingTimeDays {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

type WorkingTimeData = {
  days: Record<WorkingTimeDays, Array<WorkingTimeDate>>,
  type: string,
  uuid: string,
};

type WorkingTimeDate = {
  start: string,
  end: string,
};

type WorkingTimeResultCheck = {
  id?: string,
  message: string,
  hasError: boolean,
};

export type {
  WorkingTimeData, WorkingTimeDate, WorkingTimeResultCheck,
};

export {
  WorkingTimeDays,
};
