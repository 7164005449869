import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

import { ref, toRefs, watch } from 'vue';
import {
  SCRadioButton, SCButton, ButtonVariant, SCScrollButtons,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useLanguageStore } from '@/common/stores/language';
import TModal from '@/common/components/TModal.vue';
import { vGaTrackEvent } from '@/modules/google-analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguagePicker',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modalOpenValue"],
  setup(__props: any, { emit: __emit }) {

const { t } = i18n.global;
const props = __props;
// eslint-disable-next-line @typescript-eslint/object-curly-spacing
const emit = __emit;
const { modelValue } = toRefs(props);
const langStore = useLanguageStore();
const isModalOpen = ref<boolean>(false);
const selectedItem = ref<string>(langStore.currentLang?.id || '');
const languagePickerRef = ref<HTMLElement | null>(null);

watch(modelValue, () => {
  isModalOpen.value = modelValue.value;
});

watch(isModalOpen, () => {
  emit(
    'update:modalOpenValue',
    isModalOpen.value,
  );
  // reset selected item when modal re-opened
  if (isModalOpen.value) {
    selectedItem.value = langStore.currentLang?.id || '';
  }
});

const saveLanguage = () => {
  langStore.changeLanguage(selectedItem.value);
  isModalOpen.value = false;
};

const onLangSelected = (value: string) => {
  selectedItem.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: isModalOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isModalOpen).value = $event)),
    "with-close-icon": "",
    "modal-id": "language-picker"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('main.language')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "languagePickerRef",
        ref: languagePickerRef,
        class: "w-100 lang-picker d-flex flex-column overflow-auto"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(langStore).availableLanguages, (lang) => {
          return (_openBlock(), _createBlock(_unref(SCRadioButton), {
            key: lang.id,
            "selected-value": selectedItem.value,
            value: lang.id,
            label: lang.name,
            class: "sc-mb-3",
            block: "",
            "onUpdate:modelValue": onLangSelected
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(["sc-pr-7 ml-auto", `flag flag-${lang.iso3.toUpperCase()}`])
              }, null, 2 /* CLASS */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["selected-value", "value", "label"]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 512 /* NEED_PATCH */),
      _createVNode(_unref(SCScrollButtons), { content: languagePickerRef.value }, null, 8 /* PROPS */, ["content"])
    ]),
    actions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        size: "md",
        width: "100%",
        disabled: !selectedItem.value,
        onClick: saveLanguage
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('actions.save')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant", "disabled"])), [
        [_unref(vGaTrackEvent), {
          eventName: 'Language_Change',
          eventParams: { action: 'Language Change (on Save)' }
        }]
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})