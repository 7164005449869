import { range, each, where } from 'underscore';
import templateUrl from './forecastBettingArea.html';

(function () {
  angular.module('terminalApp.nabGreyhoundRacesUi')
    .component('greyhoundForecastBettingAreaTable', {
      templateUrl: templateUrl,
      controller: function (
        SevenGamesSvc,
        $state,
        $scope,
        BetslipService,
        NabNotifications,
        GreyhoundRacesUiService
      ) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); var // switch virtual and regular greyhound races
          GreyhoundRaces = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = GreyhoundRaces.getBettingSvc();
        var betslipSvc = BetslipService;
        var self = this;

        this.$onInit = function () {
          this.racersFirstPlace = range(1, GreyhoundRaces.numberOfRacers + 1);
          this.racersSecondPlace = range(1, GreyhoundRaces.numberOfRacers + 1);
          this.reverseForecast = false;
          this.activeGame = GreyhoundRaces;
          this.grUiSvc = GreyhoundRacesUiService;
          this.createCombinations();
          this.grUiSvc.disableReverseForecast = false;
          this.grUiSvc.disableForecast = false;
        };

        this.deactivateForecastRacers = function () {
          each(self.grUiSvc.forecast, function (value, position, racer) {
            racer[position] = false;
          });
        };

        this.deactivateScheduledRacers = function () {
          each(self.grUiSvc.scheduledEvents, function (event) {
            each(event.forecast, function (value, position) {
              event.forecast[position] = false;
            });
          });
        };

        this.createCombinations = function () {
          each(self.racersFirstPlace, function (firstPlace) {
            each(self.racersSecondPlace, function (secondPlace) {
              if (firstPlace != secondPlace) {
                self.grUiSvc.forecast[firstPlace + '-' + secondPlace] = false;
              }
            });
          });
        };

        this.toggleReverseForecast = function () {
          this.reverseForecast = !this.reverseForecast;

          self.deactivateForecastRacers();

          if (self.activeGame.isScheduleActive) {
            self.deactivateScheduledRacers();
          }

          self.enableActiveBets(betslipSvc.ticket.bets);
        };

        this.createReverseForecastBet = function (first, second) {
          var bets = BetslipService.ticket.getBets();

          var activeBet = {
            key: 'reverseForecast',
            combinations: [
              first + ', ' + second,
              second + ', ' + first
            ]
          };

          var selectedBetComb1 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[0], eventId: this.activeGame._scheduleSvc.activeRound.eventId })[0];
          var selectedBetComb2 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[1], eventId: this.activeGame._scheduleSvc.activeRound.eventId })[0];

          if (selectedBetComb1 || selectedBetComb2) {
            var toRemove = selectedBetComb1 ? selectedBetComb1.uid : selectedBetComb2.uid;
            BetslipService.removeFromBetslip(toRemove, false);
            return;
          }
          var bet = where(GreyhoundRaces.configuredBets, { key: 'reverseForecast' });
          BettingSvc.activateBet(bet[0]);

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          BettingSvc.ticketInput.input = [second, first];

          var finalizedBet = BettingSvc.finalizeTicketInput()[0];

          if (GreyhoundRaces.isScheduleActive) {
            finalizedBet.eventId = GreyhoundRaces._scheduleSvc.activeRound.eventId;
            finalizedBet.event = GreyhoundRaces._scheduleSvc.activeRound.eventIdToday;
          }
          var error = BetslipService.validateBet(
            finalizedBet,
            false
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            BetslipService.addToBetslip(finalizedBet);
          }
        };

        this.createForecastBet = function (first, second) {
          GreyhoundRaces._bettingSvc.clearAll();
          GreyhoundRaces._bettingSvc.ticketInput.input.push(first);
          GreyhoundRaces._bettingSvc.ticketInput.secondPlace.push(second);

          var odd = GreyhoundRaces._bettingSvc.systemBets.forecastOdds[first + '-' + second];

          return {
            bet: 'forecast',
            value: odd,
            racers: first + '-' + second
          };
        };

        this.sendBet = function (first, second) {
          var betToSend = null;

          if (this.reverseForecast) {
            betToSend = this.createReverseForecastBet(first, second);
            return;
          }
          betToSend = this.createForecastBet(first, second);

          this.toggleBet(betToSend);
        };

        this.toggleBet = function (activeBet) {
          var bet;
          var finalizedBet;
          var bets = BetslipService.ticket.getBets();
          var selectedBet = where(bets, { key: activeBet.bet, displayValue: activeBet.racers.toString() })[0];

          /** If same bet is clicked - set it inactive and remove it from betslip* */
          if (this.grUiSvc.forecast[activeBet.racers]
                        && (this.activeGame.isScheduleActive ? this.grUiSvc.scheduledEvents[this.activeGame._scheduleSvc.activeRound.eventId].forecast[activeBet.racers] : true)) {
            if (this.activeGame.isScheduleActive) {
              this.grUiSvc.scheduledEvents[this.activeGame._scheduleSvc.activeRound.eventId].forecast[activeBet.racers] = false;
              selectedBet = where(bets, { key: activeBet.bet, displayValue: activeBet.racers, eventId: this.activeGame._scheduleSvc.activeRound.eventId })[0];
            }

            BetslipService.removeFromBetslip(selectedBet.uid, false);
            return;
          }

          var SCR = SevenGamesSvc.getGame('SlotCarRaces');
          var BettingSvc = GreyhoundRaces.getBettingSvc();

          bet = where(GreyhoundRaces.configuredBets, { key: activeBet.bet });
          BettingSvc.activateBet(bet[0]);
          finalizedBet = BettingSvc.finalizeTicketInput()[0];
          finalizedBet.disableEdit = true; // disable editing - winner,place
          finalizedBet.systemOdd = activeBet.value;

          finalizedBet.eventId = GreyhoundRaces.gameInfo.eventId;

          if (GreyhoundRaces.isScheduleActive) {
            finalizedBet.eventId = GreyhoundRaces._scheduleSvc.activeRound.eventId;
            finalizedBet.event = GreyhoundRaces._scheduleSvc.activeRound.eventIdToday;
          }

          var error = BetslipService.validateBet(
            finalizedBet,
            false
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            BetslipService.addToBetslip(finalizedBet);
          }
        };

        this.enableActiveBets = function (bets) {
          bets.forEach(function (item) {
            if (item.clientId == 10 && !self.reverseForecast) {
              self.grUiSvc.forecast[item.displayValue] = true;

              if (self.activeGame.isScheduleActive && (item.eventId >= self.activeGame.gameInfo.eventId)) {
                self.grUiSvc.scheduledEvents[item.eventId].forecast[item.displayValue] = true;
              }
            }

            if (item.clientId == 12 && self.reverseForecast) {
              self.grUiSvc.forecast[item.input[0] + '-' + item.input[1]] = true;
              self.grUiSvc.forecast[item.input[1] + '-' + item.input[0]] = true;

              if (self.activeGame.isScheduleActive && (item.eventId >= self.activeGame.gameInfo.eventId)) {
                self.grUiSvc.scheduledEvents[item.eventId].forecast[item.input[0] + '-' + item.input[1]] = true;
                self.grUiSvc.scheduledEvents[item.eventId].forecast[item.input[1] + '-' + item.input[0]] = true;
              }
            }
          });
        };

        this.disableActiveBets = function (bets) {

        };

        $scope.$watch(angular.bind(this, function () {
          return betslipSvc.ticket.bets; // this IS the this above!!
        }), function (newValue) {
          self.deactivateForecastRacers();

          if (self.activeGame.isScheduleActive) {
            self.deactivateScheduledRacers();
          }

          self.enableActiveBets(newValue);
        }, true);
      }
    });
})();
