(function () {
  angular.module('7Terminal.Core')
    .service('Widgets', Widgets);

  /**
   * @memberOf module:"7Terminal.Core"
   * @class Widgets
   *
   */
  function Widgets(
    $filter,
    $templateCache,
    $log,
    $q,
    Modules,
    errorParser
  ) {
    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this._PROVIDERS = {
      unpkg: {
        api: 'https://unpkg.com/'
      }
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this.WIDGET_SOURCE_TYPES = {
      IFRAME: 'IFRAME',
      WEB_COMPONENTS: 'WEB_COMPONENTS',
      ANGULARJS_COMPONENTS: 'ANGULARJS_COMPONENTS'
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this._widgets = [];

    /**
     *
     * @memberOf module:"7Terminal.Core".Widgets
     * @param {Object} widget
     * @param {String} widget.name
     * @param {String} widget.type
     * @param {Object} widget.source
     * @param {String} widget.source.url
     * @param {String} widget.source.type
     * @param {String} widget.source.element
     * @param {String} widget.source.element.tag
     * @param {String} widget.source.provider
     * @param {Boolean} widget.active
     * @param {Number} [widget.order=0]
     * @param {Object} widget.when
     */
    this.registerWidget = function (widget) {
      const widgetDefault = { order: 100, source: {} };
      const alreadyRegistered = this.getWidgetByName(widget.name);

      if (widget.active === false) {
        $log.warn('[7Terminal.Core] Widget is not active!', {
          widget,
          code: 'T_WIDGET_NOT_ACTIVE'
        });
        return false;
      }

      if (alreadyRegistered) {
        $log.warn('[7Terminal.Core] Widget is already registered!', {
          code: 'T_WIDGET_ALREADY_REGISTERED',
          widget
        });
        return false;
      }

      if (!widget.source) {
        $log.error('[7Terminal.Core] Widget source definition missing!', {
          widget,
          code: 'T_WIDGET_SOURCE_MISSING'
        });
      }

      _.defaults(widget, widgetDefault);

      const index = this._widgets.push(widget);

      switch (widget.source.type) {
      case this.WIDGET_SOURCE_TYPES.IFRAME:
        this._registerIframe(widget);
        break;
      case this.WIDGET_SOURCE_TYPES.WEB_COMPONENTS:
        this._registerWebComponent(widget);
        break;
      case this.WIDGET_SOURCE_TYPES.ANGULARJS_COMPONENTS:
        this._registerAngularjsComponent(widget);
        break;
      default:
        $log.error('[7Terminal.Core] Widget type unknown!', {
          widget,
          code: 'T_WIDGET_TYPE_UNKNOWN'
        });
        return false;
      }

      return this._widgets[index - 1];
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this._registerIframe = function () {
      // $log.info(widget);
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this._registerWebComponent = function (widget) {
      if (!widget.source.lazyLoad) {
        this._fetchWebComponent(widget).catch(function (result) {
          if (!result) {
            $log.error('[7Terminal.Core] Failed to fetch WC!', {
              widget,
              code: 'T_FETCH_WC_FAILED'
            });
          }
        });
      }

      return true;
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this._fetchWebComponent = function (widget) {
      var provider = widget.source.provider;
      var providerUrl = _.has(this._PROVIDERS, provider) ? this._PROVIDERS[provider].api : '';
      var finalUrl = providerUrl + widget.source.url;
      return Modules.getLoader().load(finalUrl)
        .then(function () {
          widget.source.fetched = true;
          return widget;
        }).catch(function (err) {
          $log.error('[7Terminal.Core] Failed to fetch web component from ' + finalUrl, {
            ...errorParser.parseUpstream(err),
            code: 'T_FETCH_WC_FAILED'
          });
          return false;
        });
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this._registerAngularjsComponent = function (widget) {
      $templateCache.put(widget.name, widget.component);
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this.getWidgetByName = function (name) {
      return _.find(this._widgets, function (widget) {
        return name === widget.name;
      });
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this.filterWidgetsFromSource = function (config) {
      return _.filter(config, function filter(value, key) {
        return key.startsWith('widget.');
      });
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this.getByType = function (types, whenScopeData) {
      var foundWidget = false;
      var result = $filter('filter')(this._widgets, function (widget) {
        // TODO: hardcoded to work only item matching in array (anyof)
        return types.indexOf(widget.type) >= 0
          && widget.when.values.indexOf(whenScopeData.value) >= 0
          && widget.active === true;
      });

      if (result && result.length) {
        foundWidget = result[0];
        if (foundWidget.source.lazyLoad) {
          // TODO: coupled step to WC implementation, it needs to work with any
          // lazy loadable approach
          this._fetchWebComponent(foundWidget)
            .then(function (fetchedWidget) {
              return $q.resolve(fetchedWidget);
            });
        }
      }

      return $q.resolve(foundWidget);
    };

    /**
     * @memberOf module:"7Terminal.Core".Widgets
     */
    this.getWidgetsByPosition = function (position) {
      return this._widgets.filter((widget) => {
        if (widget.positions) {
          return widget.positions.some((pos) => pos.id === position);
        }
        return false;
      }).sort((n, m) => n.order - m.order);
    };
  }
})();
