/**
 *
 * @constructor GamesUiService
 * @ngInject
 */
function GamesUiService(
  $rootScope
) {
  this.betslipConfig = {};

  /**
  *  @param activeGame
  * @returns {{value: boolean, messageKey: string}}
  */
  this.checkGameActivity = function (activeGame) {
    var activity = true;
    var messageKey = '';

    if (activeGame.gameInfo && !activeGame.gameInfo.isActive) {
      messageKey = 'notifications.game_deactivated';
      activity = false;
    }

    return {
      value: activity,
      messageKey: messageKey
    };
  };

  $rootScope.$on(
    '7T:Betslip.TicketActivated',
    function (event, data) {
      this.betslipConfig = data.betslip.config;
    }.bind(this)
  );
}

export default GamesUiService;
