import { getService } from '@/common/services/ngBridge';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useSevenStore } from '@/modules/seven';
import { localAppConfigService } from '@/common/services/local-app-config';
import { AppMode } from '@/common/enums';
import { operatorService } from '@/modules/operator';
import { cardOperationsService } from '@/modules/tbo-card-operations';
import { useClientGamesStore } from '@/modules/client-games';
import tboGlobalDataService from './tboGlobalDataService';
import { useTboModulesStore } from './tboModulesStore';
import { type TboSevenModule } from './types';

const handleConfiguration = () => {
  const Environment: any = getService('Environment');
  const environmentHandlerStore = useEnvironmentHandlerStore();

  Environment.setState('activeApp', AppMode.BACK_OFFICE);
  environmentHandlerStore.setState('activeApp', AppMode.BACK_OFFICE);
};

const getScmConfiguration = () => {
  const sevenStore = useSevenStore();
  const ncm = {
    token: localAppConfigService.data.appConfig.terminalAccount.password,
    username: localAppConfigService.data.appConfig.terminalAccount.uuid,
    additionalChannels: [
      {
        name: 'terminalChannel',
        id: localAppConfigService.data.appConfig.terminalAccount.uuid,
      },
      {
        // Channel for app config updates
        name: 'terminalAppConfig',
        id: `${sevenStore.tenant.uuid}TerminalSettings`,
      },
      {
        // Channel for operator permissions updates
        name: 'user',
        id: operatorService.data.uuid,
      },
      {
        // Channel for operator permissions updates
        name: 'betshop',
        id: sevenStore.betshop.uuid,
      },
    ],
  };
  return ncm;
};

const loadModules = (): void => {
  const generalModulesService: any = getService('GeneralModulesService');
  const tboModulesStore = useTboModulesStore();
  tboModulesStore.setModules(
    tboGlobalDataService.tboGlobalData
      .clientAppModules
      .filter((module): module is TboSevenModule => {
        if ('type' in module) {
          return false;
        }
        return true;
      }),
  );
  cardOperationsService.init(tboModulesStore.getModule('cardOperations'));
  generalModulesService.setModules(tboModulesStore.getModules());
};

const boot = () => {
  handleConfiguration();

  const gravitySettingsStore = useGravitySettingsStore();
  const sevenStore = useSevenStore();
  const gamesStore = useClientGamesStore();
  const SevenScmSvc: any = getService('SevenScmSvc');
  const connectGamesOnSCm = gamesStore.getGames().filter((game) => !gravitySettingsStore
    .getModuleDataKeyValue(
      `module.${game.id}.ticketCheck`,
      'showTicketDetailsFrameInBackOffice',
    ));

  SevenScmSvc.startScmConnection({
    channels: connectGamesOnSCm.map((gameData) => {
      // no matter what connect to all channels
      const config = {
        [gameData.id]: {
          cpvUuid: gameData.account.linkId,
          // also add local ncm config data to object
          subscribeConfig: {},
        },
      };
      return config;
    }),
    deliveryPlatform: 'AdminTerminal',
    deviceUuid: sevenStore.device.uuid,
    language: sevenStore.tenant.locale,
    ncm: getScmConfiguration(),
  });

  // init admin terminal data/services/listeners
  loadModules();
};

export default {
  boot,
};
