import PubSub from 'pubsub-js';
import BetslipBlockData from '../interfaces/BetslipBlockData';

const BETSLIP_BLOCK_EVENT_ID = Symbol('7T:Betslip.Block');
const betslipBlockEventPubSub: PubSubJS.Base<
BetslipBlockData,
typeof BETSLIP_BLOCK_EVENT_ID
> = PubSub;

export {
  BETSLIP_BLOCK_EVENT_ID,
  betslipBlockEventPubSub,
};
