import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { storeToRefs } from 'pinia';
import {
  SCButton,
  SCInlineNotification,
  NotificationVariant,
  ButtonVariant,
} from '@nsftx/seven-components';
import TModal from '@/common/components/TModal.vue';
import i18n from '@/plugins/i18n';
import logService from '@/common/services/logger/logService';
import { useIntelligentGamingStore } from '../store';
import { checkProvinceForBetshop } from '../provinceService';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProvinceNotSetModal',
  setup(__props) {

const { t } = i18n.global;

const { isProvinceModalShown } = storeToRefs(useIntelligentGamingStore());

const handleTryAgain = () => {
  checkProvinceForBetshop();
  logService.info('[IntelligentGaming] - Province not found, try again clicked', {
    code: 'T_IG_PROVINCE_MODAL_CHECK_PROVINCE',
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: _unref(isProvinceModalShown),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(isProvinceModalShown) ? (isProvinceModalShown).value = $event : null)),
    "modal-id": "province-not-set-modal",
    "prevent-close": ""
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('ig_province_modal_title')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createVNode(_unref(SCInlineNotification), {
        modelValue: _unref(isProvinceModalShown),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isProvinceModalShown) ? (isProvinceModalShown).value = $event : null)),
        block: "",
        messages: [_unref(t)('ig_province_modal_notification_message')],
        variant: _unref(NotificationVariant).Error
      }, null, 8 /* PROPS */, ["modelValue", "messages", "variant"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        width: "100%",
        onClick: handleTryAgain
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('ig_province_modal_submit')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})