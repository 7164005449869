import templateUrl from './bettingArea.html';
import randomBetTemplateUrl from '../betting_popups/random_bet.html';
import systemBetTemplateUrl from '../betting_popups/system_bet.html';

(function () {
  angular.module('terminalApp.nabLuckyXUi').component('luckyX', {
    templateUrl: templateUrl,
    controller: 'LuckyXBettingAreaController',
    controllerAs: 'luckyXVm'
  });

  angular.module('terminalApp.nabLuckyXUi').controller('LuckyXBettingAreaController', LuckyXBettingAreaController);

  function LuckyXBettingAreaController($filter,
    arrayService,
    nabTrans,
    SevenGamesSvc,
    flashButton,
    popupService,
    luckyXConfig,
    NabNotifications,
    loaderService,
    BetslipService,
    $rootScope) {
    var betType;
    var luckyXVm = this;
    var LuckyX = SevenGamesSvc.getGame('LuckyX');
    var BettingSvc = LuckyX.getBettingSvc();

    BettingSvc.initializeService(LuckyX);
    luckyXVm.randomBetTemplateUrl = randomBetTemplateUrl;
    luckyXVm.systemBetTemplateUrl = systemBetTemplateUrl;
    luckyXVm.betslip = BetslipService;
    luckyXVm.activeBets = BettingSvc;
    luckyXVm.futureEvents = arrayService.getArray(10, true);
    luckyXVm.bettingService = BettingSvc;
    luckyXVm.ballColors = BettingSvc.ballColors;
    luckyXVm.specialBets = $filter('filter')(LuckyX.betsArray, { betType: 'Special' }, true);
    luckyXVm.ballColors = BettingSvc.ballColors;
    luckyXVm.setFutureEvent = setFutureEvent;
    luckyXVm.selectBall = selectBall;
    luckyXVm.resolutionMode = $rootScope.resolutionMode;
    luckyXVm.selectBallColor = selectBallColor;
    luckyXVm.selectSpecialBetWithOutcomes = selectSpecialBetWithOutcomes;
    luckyXVm.setFirstBallColor = setFirstBallColor;
    luckyXVm.selectFirstBallInSix = selectFirstBallInSix;
    luckyXVm.selectRandom = selectRandom;
    luckyXVm.selectSystemBet = selectSystemBet;

    function checkGameActivity() {
      if (!LuckyX.gameInfo.isActive) {
        NabNotifications.show({
          message: nabTrans.translate(
            'notifications.game_deactivated',
            { game: 'Lucky X' },
            true
          ),
          type: 'warning',
          delay: 3000
        });

        BettingSvc.clearAll();
        return false;
      }

      if (LuckyX.gameDeactivating) {
        NabNotifications.show({
          message: nabTrans.translate(
            'notifications.game_deactivating',
            { game: 'Lucky X' },
            true
          ),
          type: 'warning',
          delay: 3000
        });
        BettingSvc.clearAll();
        return false;
      }

      return true;
    }

    /** Set future event
        * @param {Number} event* */

    function setFutureEvent(event) {
      BettingSvc.betInput.numEvents = event;
    }

    /** Select ball
         * and activate appropriate normal bet
         * @param {Object} ball* */

    function selectBall(ball) {
      if (!checkGameActivity()) { return; }

      /** If there are already ten balls selected disable to select more* */
      if (!ball.active && BettingSvc.getActiveBalls().length == 10) {
        NabNotifications.show({
          message: nabTrans.translate('notifications.max_ball_number', { number: 10 }, true),
          type: 'info',
          delay: 3000
        });
        return;
      }
      BettingSvc.selectBall(ball);
      betType = luckyXConfig.bets[BettingSvc.getActiveBalls().length];
      BettingSvc.setBetActive(betType);
      BettingSvc.getActiveBalls().length > 0 ? flashButton.flash = true : flashButton.flash = false;
    }

    /** Select ball color
         *  and activate appropriate normal bet
         *  @param {Object} color* */

    function selectBallColor(color) {
      if (!checkGameActivity()) { return; }
      BettingSvc.resetView();
      BettingSvc.selectBallColor(color);
      betType = luckyXConfig.bets[BettingSvc.getActiveBalls().length];
      BettingSvc.setBetActive(betType);
      BettingSvc.getActiveBalls().length > 0 ? flashButton.flash = true : flashButton.flash = false;
    }

    /** Set first ball color
         * toggle color if same color is clicked
         * @param {Object} bet
         * @param {Number} color* */

    function setFirstBallColor(bet, color) {
      if (!checkGameActivity()) { return; }

      /** If same color is clicked * */
      if (luckyXVm.activeBets.activeBet.bet.id == bet.id && BettingSvc.inputView.special == color) {
        flashButton.flash = false;
        BettingSvc.resetView();
        return;
      }

      BettingSvc.resetView();
      BettingSvc.deselectBalls();
      BettingSvc.deselectBallColors();
      BettingSvc.inputView.special = color;
      flashButton.flash = true;
      BettingSvc.setBetActive(bet);
    }

    /** Open random bet popup* */

    function selectRandom() {
      if (!checkGameActivity()) { return; }
      popupService.showPopup('random-bet');
    }

    /** Open system bet popup* */

    function selectSystemBet() {
      if (!checkGameActivity()) { return; }
      popupService.showPopup('system-bet');
    }

    /** Select first ball in first six
         * @param {Object} bet* */

    function selectFirstBallInSix(bet) {
      if (!checkGameActivity()) { return; }
      /** If there are no selected balls - notification to select ball* */
      if (BettingSvc.getActiveBalls().length < 1) {
        NabNotifications.show({
          message: nabTrans.translate('notifications.select_one_ball', {}, true),
          type: 'info',
          delay: 3000
        });
        return;
      }

      /** Set ball colors to false* */
      BettingSvc.deselectBallColors();
      /** Reset inputView* */
      BettingSvc.resetView();

      /** If there are more than one balls selected leave just first selected* */
      if (BettingSvc.getActiveBalls().length > 1) {
        BettingSvc.setFirstActiveBall();
      }

      BettingSvc.inputView.special = BettingSvc.getActiveBalls()[0].number;
      BettingSvc.setBetActive(bet);
    }

    /**
         * Activate special bet with outcomes and set outcome
         * @param {Object} bet
         * @param {Number}  outcome* */

    function selectSpecialBetWithOutcomes(bet, outcome) {
      if (!checkGameActivity()) { return; }
      /** If same outcome is clicked* */
      if (luckyXVm.activeBets.activeBet.bet.id == bet.id && BettingSvc.inputView.special == outcome) {
        flashButton.flash = false;
        BettingSvc.clearAll();
        return;
      }

      flashButton.flash = true;
      BettingSvc.resetView();
      BettingSvc.deselectBalls();
      BettingSvc.deselectBallColors();
      BettingSvc.setBetActive(bet);
      BettingSvc.inputView.special = outcome;
    }

    flashButton.flash = false;
    loaderService.endLoader();
  }
})();
