<script setup lang="ts">
import {
  ref, computed, toRefs, onUnmounted, onMounted,
} from 'vue';
import {
  SCInput,
  SCButton,
  SCKeyboard,
  ButtonVariant,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import TModal from '@/common/components/TModal.vue';
import { closeTicketCheckModalsEventPubSub, CLOSE_TICKET_CHECK_MODALS_ID, TicketCheckAbortedError } from '@/modules/ticket-check';
import { usePinValidationStore } from './pinValidationStore';

const { t } = i18n.global;
const LOG_PREFIX = '[SevenTicketPin]';
const pinValidationStore = usePinValidationStore();
const { showTicketPinModal } = toRefs(pinValidationStore);
const isKeyboardShown = ref(false);
const enteredPin = ref('');
const ticketPinChanged = ref(false);
const showRequiredError = computed(() => !enteredPin.value && ticketPinChanged.value);

const onUpdateTicketPin = () => {
  ticketPinChanged.value = true;
};

const onCancel = () => {
  logService.info(`${LOG_PREFIX} Pin modal has been closed by player`, {
    code: 'T_TICKET_PIN_CLOSED_BY_PLAYER',
  });
  pinValidationStore.rejectTicketPin({
    message: 'Pin enter aborted',
    code: 'T_TICKET_PIN_ENTER_ABORTED_BY_USER',
    delay: 3000,
  });

  showTicketPinModal.value = false;
  enteredPin.value = '';
  ticketPinChanged.value = false;
};

const onSend = () => {
  if (enteredPin.value) {
    pinValidationStore.resolveTicketPin(enteredPin.value);
  } else {
    pinValidationStore.rejectTicketPin({
      message: 'Empty pin field',
      code: 'T_TICKET_PIN_NOT_ENTERED',
      delay: 3000,
    });
  }

  showTicketPinModal.value = false;
  enteredPin.value = '';
  ticketPinChanged.value = false;
};

let closeModalsSubscriptionId = '';

onMounted(() => {
  closeModalsSubscriptionId = closeTicketCheckModalsEventPubSub
    .subscribe(CLOSE_TICKET_CHECK_MODALS_ID, (msg, data) => {
      if (showTicketPinModal.value) {
        logService.info(`${LOG_PREFIX} Closing modal.`, {
          code: 'T_TICKET_PIN_CLOSE_MODAL_EVENT',
          upstream_message: data?.reason,
        });
        const error = new TicketCheckAbortedError(`Pin modal has been closed, ${data?.reason}`, 'T_TICKET_PIN_CLOSE_MODAL_EVENT');
        pinValidationStore.rejectTicketPin(error);

        showTicketPinModal.value = false;
        enteredPin.value = '';
        ticketPinChanged.value = false;
      }
    });
});

onUnmounted(() => {
  if (closeModalsSubscriptionId) {
    closeTicketCheckModalsEventPubSub.unsubscribe(closeModalsSubscriptionId);
    closeModalsSubscriptionId = '';
  }
});

</script>

<template>
  <TModal
    v-model="showTicketPinModal"
    modal-id="ticket-pin"
    width="30rem"
    with-close-icon
    @update:model-value="onCancel"
  >
    <template #title>
      {{ t('ticket.pin') }}
    </template>
    <template #content>
      <div class="w-100 sc-pb-4">
        <p class="sc-text-paragraph">
          {{ t('seven_ticket_security_pin_subtitle') }}
        </p>
        <SCKeyboard
          v-model:show="isKeyboardShown"
          v-model="enteredPin"
          numeric
          selector="keyboard-ticket-pin-input"
          class="w-100 sc-p-0"
          @update:model-value="onUpdateTicketPin"
        >
          <SCInput
            v-model="enteredPin"
            class="sc-pb-9"
            :error="showRequiredError"
            :error-message="t('pin.not_entered')"
            :label="t('ticket.enter_pin')"
            block
            data-testid="pin"
            @update:model-value="onUpdateTicketPin"
          />
        </SCKeyboard>
      </div>
    </template>
    <template #actions>
      <div class="w-100 d-flex">
        <div class="w-50 sc-pr-3">
          <SCButton
            width="100%"
            data-testid="cancel"
            @click="onCancel"
          >
            {{ t('actions.cancel') }}
          </SCButton>
        </div>
        <div class="w-50">
          <SCButton
            :variant="ButtonVariant.Primary"
            width="100%"
            :disabled="!enteredPin"
            data-testid="send"
            @click="onSend"
          >
            {{ t('actions.send') }}
          </SCButton>
        </div>
      </div>
    </template>
  </TModal>
</template>
