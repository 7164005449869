import i18n from '@/plugins/i18n';
import { sevenCurrency } from '@/common/filters';
import { isTboActive } from '@/common/services/helpers';
import BaseError from '@/common/errors/BaseError';
import {
  ProductTicketResponse,
  ProductTicketStatuses,
  TicketInstance,
  TicketResponse,
  TicketStatuses,
  betslipApiHelpers,
} from '@/modules/seven-betslip-api';
import { isTicketPaidOut } from './helpers';

const { t } = i18n.global;

const isPayoutSuccessful = (
  responseTicket: TicketResponse | ProductTicketResponse,
  ticketFromStore: TicketInstance,
): string => {
  const ticket = betslipApiHelpers.createTicket(responseTicket);

  if (isTicketPaidOut(ticketFromStore)) {
    const error = new Error(`Ticket is already paid out. Local status is ${ticketFromStore.localStatus}. Server status is ${ticket.getStatus()}.`);
    error.name = 'T_TICKET_ALREADY_PAID_OUT';
    throw error;
  }

  const transKey = isTboActive() ? '_success' : '_success_to_balance';

  return t(`ticket.payout${transKey}`, {
    amount: sevenCurrency(ticket.getPayoutAmount(), 2),
  });
};

const isCancelSuccessful = (
  responseTicket: TicketResponse | ProductTicketResponse,
): string => {
  const successfulStatuses = [
    TicketStatuses.CANCELED.toLowerCase(),
    ProductTicketStatuses.canceled.toLowerCase(),
    ProductTicketStatuses.cancelled.toLowerCase(),
    ProductTicketStatuses.closed.toLowerCase(),
  ];
  const ticket = betslipApiHelpers.createTicket(responseTicket);
  const status = ticket.getStatus().toLowerCase();

  if (!successfulStatuses.includes(status)) {
    const error = new BaseError(
      `Ticket cannot be cancelled. Status is ${status}.`,
      'T_TICKET_CANCEL_STATUS_NOT_VALID',
    );
    throw error;
  }

  return t('ticket.cancel_success_to_balance', {
    amount: sevenCurrency(ticket.getPayment(), 2),
  });
};

export default {
  isPayoutSuccessful,
  isCancelSuccessful,
};
