import angular from 'angular';
import ngLibModule from '../../modules/ng_lib';
import '@nsftx/seven-ng-client-lucky-six/dist/luckysix';
import commonModule from '../../common';
import coreModule from '../../core';
import sevenGamesModule from '../index';
import resultsTemplateUrl from '../results/results.html';
import resultsContentTemplateUrl from './results/lucky_six.html';

angular.module('terminalApp.nabLuckySixUi', [
  ngLibModule,
  'nabLuckySix',
  commonModule,
  coreModule,
  sevenGamesModule
]).config(function (
  $stateProvider
) {
  var i = 0;
  var config;
  for (i; i < window.BootStrapData.clientAppModules.length; i++) {
    config = window.BootStrapData.clientAppModules[i];
    if (config.id === 'LuckySix') {
      window.SevenLuckySix.getConfigService()
        .set({
          companyUuid: window.BootStrapData.company.uuid
        });
    }
  }

  $stateProvider.state('app.games.luckysix', {
    url: '/luckySix',
    template: "<lucky-six-ui class='max-height'></lucky-six-ui>"
  });

  $stateProvider.state('app.results.luckysix', {
    url: '/luckysix?id',
    templateUrl: resultsTemplateUrl,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.luckysix.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentTemplateUrl,
        controller: 'LuckySixResultsController'
      }
    }
  });
}).run(function (
  LuckySixUiService,
  $rootScope
) {
  $rootScope.$on('LuckySix:ConfigLoaded', function () {
    LuckySixUiService.boot();
  });

  $rootScope.$on('7T:Slave.Shown', function (e, data) {
    if (data.productId === 'LuckySix') {
      $rootScope.$broadcast('7T:Betslip.ActivateTicket', {
        data: {
          betslipId: data.productId,
          ticketId: 'single'
        }
      });
    }
  });
});
