import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { RegisteredModals, ModalsSetters, ModalSetter } from '@/common/stores/modals/types';

const useModalsStore = defineStore('modalsStore', () => {
  const registeredModals: RegisteredModals = {};
  const modalsSetters: ModalsSetters = {};

  const registerModal = (modalId: string, initialValue: Ref<boolean>, modalSetter: ModalSetter) => {
    registeredModals[modalId] = ref(initialValue);
    modalsSetters[modalId] = modalSetter;
  };

  const openModal = (modalId: string) => {
    modalsSetters[modalId]('update:modelValue', true);
  };

  const closeModal = (modalId: string) => {
    modalsSetters[modalId]('update:modelValue', false);
  };

  const closeAllModals = () => {
    Object.keys(modalsSetters)
      .forEach((key: string) => {
        modalsSetters[key]('update:modelValue', false);
      });
  };

  return {
    registeredModals,
    registerModal,
    openModal,
    closeModal,
    closeAllModals,
  };
});

export {
  useModalsStore,
};
