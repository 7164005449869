import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { storeToRefs } from 'pinia';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import TModal from '@/common/components/TModal.vue';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { safeAppReload } from '@/common/services/appHelpersService';
import { useAuthStore } from '../authStore';

const LOG_PREFIX = '[TLoginFailedModal]';

export default /*@__PURE__*/_defineComponent({
  __name: 'TLoginFailedModal',
  setup(__props) {

const { t } = i18n.global;
const authStore = useAuthStore();
const { isLoginFailedModalOpened } = storeToRefs(authStore);

const reloadApp = () => {
  logService.info(`${LOG_PREFIX} Reload on login failed.`, {
    code: 'T_LOGIN_FAILED_RELOAD',
  });
  safeAppReload();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: _unref(isLoginFailedModalOpened),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isLoginFailedModalOpened) ? (isLoginFailedModalOpened).value = $event : null)),
    "modal-id": "t-login-failed",
    "prevent-close": ""
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('login_error')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('login_error_description')), 1 /* TEXT */)
    ]),
    actions: _withCtx(() => [
      _createVNode(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        width: "100%",
        onClick: reloadApp
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('main_reload')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})