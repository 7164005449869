import { extend } from 'underscore';
import { logService } from '@/common/services/logger';
import { getService } from '@/common/services/ngBridge';
import { slaveShownEventPubSub, SLAVE_SHOWN_EVENT_ID, integratorService } from '@/modules/integrator';
import { TicketInstance } from '@/modules/seven-betslip-api';
import { errorParser } from '@/common/services/error-parser';
import BaseError from '@/common/errors/BaseError';
import { googleAnalyticsService } from '@/modules/google-analytics';

const LOG_PREFIX = '[rebetService]';

export const rebetTicket = (ticketInstance: TicketInstance) => new Promise((resolve, reject) => {
  const switchView = getService<{ activeBets: { handleBetsFromResponse: Function }, selectService: Function }>('switchView');
  const { ticket } = ticketInstance;
  const productId = ticketInstance.getProductDisplayId();
  const ticketId = ticketInstance.getDisplayId();
  const requestUuid = ticketInstance.getRequestUuid();

  const slaveShownSubscription = slaveShownEventPubSub.subscribe(SLAVE_SHOWN_EVENT_ID, () => {
    logService.debug(`${LOG_PREFIX} Emitting 7T:Tickets.ReBet`, {
      product_displayid: productId,
      ticket,
      ticket_code: ticketId,
    });
    googleAnalyticsService.trackEvent('Ticket_Rebet', {
      module: productId,
      event: 'Ticket Rebet',
    });

    integratorService.sendEvent('Tickets.ReBet', {
      context: {
        productId,
      },
      data: { ticket },
    });

    if (switchView?.activeBets) {
      const rebets = switchView.activeBets.handleBetsFromResponse(extend({}, ticket), true);
      const BetslipService = getService<{ reCreateBetslip: Function }>('BetslipService');
      BetslipService?.reCreateBetslip(ticket, rebets);
    }
    slaveShownEventPubSub.unsubscribe(slaveShownSubscription);
  });

  switchView?.selectService(productId, true, {
    gameId: productId,
    genericId: productId,
  }).then(() => {
    resolve({
      response: null,
      localTicket: ticketInstance,
    });
  }).catch((err: unknown) => {
    logService.error(`${LOG_PREFIX} Failed to change route on rebet action`, {
      ...errorParser.parseUpstream(err),
      product_displayid: productId,
      ticket_code: ticketId,
      request_id: requestUuid,
      code: 'T_TICKET_REBET_SELECT_SERVICE_ERROR',
    });
    const error = new BaseError('Failed to change route on rebet action', 'T_TICKET_REBET_SELECT_SERVICE_ERROR');

    reject(error);
  });
});
