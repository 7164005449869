// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.t-header-balance-mw-fc {
  min-width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/header/THeaderBalance.vue"],"names":[],"mappings":";AAiCA;EACE,sBAAsB;AACxB","sourcesContent":["<script lang=\"ts\" setup>\nimport { storeToRefs } from 'pinia';\nimport { computed } from 'vue';\nimport i18n from '@/plugins/i18n';\nimport { sevenCurrency } from '@/common/filters';\nimport { usePaymentsStore } from '@/modules/payments';\nimport { useUserFundsStore } from '@/modules/user-funds';\n\nconst { t } = i18n.global;\nconst paymentsStore = usePaymentsStore();\n\nconst { balance } = storeToRefs(useUserFundsStore());\nconst balanceTitle = computed(() => {\n  if (paymentsStore.paymentSettings.methodName) {\n    return paymentsStore.paymentSettings.headerBalanceSubtitle;\n  }\n  return t('main.cash_balance');\n});\n</script>\n\n<template>\n  <div class=\"sc-overlay-default h-100 sc-px-3 sc-py-2 text-right t-header-balance-mw-fc\">\n    <div class=\"sc-text-body-2\">\n      {{ balanceTitle }}\n    </div>\n\n    <div class=\"sc-text-h6\">\n      {{ sevenCurrency(balance) }}\n    </div>\n  </div>\n</template>\n\n<style>\n.t-header-balance-mw-fc {\n  min-width: fit-content;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
