import angular from 'angular';
import configBlock from './config';

/**
  * @ngdoc overview
  * @name 7Terminal.Marketing
  *
  * @description
  * This module integrates marketing slots into 7Terminal application.
  * See {@link tutorial/modules.marketing Marketing} section for more details.
  *
  *
*/

const moduleName = angular.module('7Terminal.Marketing', [])
  .config(configBlock)
  .name;

export default moduleName;
