import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-100 d-flex flex-column side-menu-container" }
const _hoisted_2 = { class: "sc-pt-6 sc-pb-5 sc-px-6 sc-text-display-4" }

import {
  computed, ref, type VNode,
} from 'vue';
import { SCDrawer, SCThumbnail, SCScrollButtons } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { getService } from '@/common/services/ngBridge';
import { useSideMenuStore } from '@/common/stores/side-menu';
import { useHeaderMenuStore } from '@/common/stores/header-menu';
import { useMaintenanceStore } from '@/modules/maintenance';
import { getLoadedImage, getLoadedGhostImage } from '@/common/services/CmsProductImageLoader';
import { MenuItem } from '@/common/services/menu';
import { cmsHelper } from '@/modules/cms';
import { googleAnalyticsService } from '@/modules/google-analytics';

// stores

export default /*@__PURE__*/_defineComponent({
  __name: 'TSideMenu',
  setup(__props) {

const sideMenuStore = useSideMenuStore();
const headerMenuStore = useHeaderMenuStore();
const { isGameInMaintenanceState } = useMaintenanceStore();
// data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const switchView: any = getService('switchView');
const { t } = i18n.global;
const sideMenuItemsEl = ref<VNode | undefined>(undefined);
// computed
const isDrawerShown = computed(() => (
  !!sideMenuStore.sideMenuItems
));
const sideMenuListClasses = computed(() => ({
  'side-menu-container__item-list--odd-len': (sideMenuStore.sideMenuItems?.length || 0) % 2 !== 0,
}));

// methods
const onDrawerClose = () => {
  headerMenuStore.resolvePreviousTabIndex();
};
const selectGame = (menuItem: MenuItem) => {
  switchView.selectService(
    menuItem.context.id,
    true,
    {
      genericId: menuItem.context.id,
      gameId: menuItem.context.id,
    },
  );
  onDrawerClose();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SCDrawer), {
    "model-value": isDrawerShown.value,
    "with-close-icon": "",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (onDrawerClose()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createCommentVNode(" Title "),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('games.virtual_games')), 1 /* TEXT */),
        _createCommentVNode(" Items list "),
        _createElementVNode("div", {
          ref_key: "sideMenuItemsEl",
          ref: sideMenuItemsEl,
          class: _normalizeClass(["flex-grow overflow-auto sc-pt-5 sc-px-6 sc-pb-6 side-menu-container__item-list", sideMenuListClasses.value])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sideMenuStore).sideMenuItems, (menu) => {
            return (_openBlock(), _createBlock(_unref(SCThumbnail), {
              key: menu.id,
              image: _unref(getLoadedImage)(
            menu.item.url || menu.context?.classId
          ),
              "background-image": _unref(getLoadedGhostImage)(
            menu.item.url || menu.context?.classId
          ),
              "background-color": menu.item.customData?.backgroundColor || '' ,
              "overlay-background-image": menu.item.customData?.overlayBackgroundImage || '' ,
              alt: menu.item.url,
              blended: false,
              label: _unref(cmsHelper).getProductCardLabel(menu),
              disabled: _unref(isGameInMaintenanceState)(menu.item.url) ? 'DISABLED' : '',
              "min-height": "240px",
              tabindex: "0",
              class: "side-menu-container__item-list__item",
              onClick: ($event: any) => (selectGame(menu))
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(menu.translation), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["image", "background-image", "background-color", "overlay-background-image", "alt", "label", "disabled", "onClick"]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */),
        _createVNode(_unref(SCScrollButtons), {
          block: "",
          "to-top": false,
          content: sideMenuItemsEl.value,
          onContentScrolled: _cache[0] || (_cache[0] = (direction) => _unref(googleAnalyticsService).trackScrollEvent(
          direction,
          'Side menu'
        ))
        }, null, 8 /* PROPS */, ["content"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value"]))
}
}

})