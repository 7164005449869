import {
  has, find, isUndefined
} from 'underscore';
import { setConfig as setSevenComponentsConfig } from '@nsftx/seven-components';

import { useSevenStore, SevenApiRoutes } from '@/modules/seven';
import { useENVStore } from '@/common/stores/env';
import { setTranslations as setVueTranslations } from '@/plugins/i18n';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { localAppConfigService } from '@/common/services/local-app-config';
import { logService } from '@/common/services/logger';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useGravityMenusStore } from '@/modules/cms/menus';
import { acceptorsService } from '@/modules/acceptors';
import { useCmsMarketingStore } from '@/modules/cms/marketing';
import { useLanguageStore } from '@/common/stores/language';
import { useCmsAppsStore } from '@/modules/cms';
import { useCustomerCardStore } from '@/modules/customer-card';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import { useClientGamesStore } from '@/modules/client-games';
import { useTicketLayoutsStore } from '@/modules/cms/ticket-layouts';
import { useAppModulesStore } from '@/modules/anonymous-app';

import vueApp from '@/main';

(function () {
  angular.module('7Terminal.Bootstrap')
    .service('Bootstrap', Bootstrap);

  function Bootstrap(
    $http,
    $window,
    $q,
    $log,
    $translate,
    LocalData,
    NabENV,
    LocalAppConfig,
    Environment,
    $ocLazyLoad,
    GravitySettings,
    SevenRoutes,
    BootStrapData,
    lang,
    errorParser,
    Modules,
  ) {
    const sevenStore = useSevenStore();
    const gravitySettingsStore = useGravitySettingsStore();
    const ENVStore = useENVStore();
    const environmentHandlerStore = useEnvironmentHandlerStore();
    const languageStore = useLanguageStore();

    var LOCAL_DATA = {};
    var self = this;
    var fetchedTranslations;

    /** NAR calls for app with appName parameter
     * If one is passed, apply it and save it to local storage
     * If we do a reload without appName param passed use
     * local storage appName
     */
    function handleWindowTitle() {
      let queryParamAppTitle = Environment.getUrlParameter('appTitle');
      queryParamAppTitle = queryParamAppTitle ?? Environment.getUrlParameter('windowTitle');

      $window.document.title = $window.title || queryParamAppTitle || '7Terminal0';
      localStorage.setItem('window.title', $window.document.title);
    }

    function getTranslations(language, companyUuid) {
      return $http.get(NabENV.translations.gravityUrl, {
        headers: {
          'X-Nsft-SCD-Locale': language,
          'X-Nsft-SCD-Company-Id': companyUuid
        }
      }).then(function (response) {
        return setTranslations(response.data, language);
      }).catch(function (err) {
        $log.warn('[7Terminal.Bootstrap] Failed to fetch translations from Gravity API', {
          ...errorParser.parseUpstream(err),
          code: 'T_BOOTSTRAP_TRANSLATIONS_FETCH_FAILED',
          details: {
            language
          }
        });
        return setTranslations({});
      });
    }

    function fetchGravityBootstrap(params) {
      const cmsAppsStore = useCmsAppsStore();
      const envHandlerStore = useEnvironmentHandlerStore();
      const dc = envHandlerStore.getDc();
      var config = {
        headers: {
          'X-Nsft-SCD-Company-Uuid': params.companyUuid,
          'X-Nsft-SCD-Company-Id': LocalData.betshop.companyId,
          'X-Nsft-SCD-Betshop-Uuid': LocalData.betshop.uuid,
          'X-Nsft-SCD-Betshop-Id': LocalData.betshop.betshopId,
          'X-Nsft-SCD-Device-Uuid': LocalData.device.uuid,
          'X-Nsft-SCD-Locale': 'en', // Default locale
          'X-Nsft-SCD-Account-Id': LocalData.betshop.companyUuid,
          'X-Nsft-SCD-Version': '4',
          'X-Nsft-SCD-App-Name': cmsAppsStore.app.name,
          Authorization: 'Basic ' + $window.btoa(LocalAppConfig.appConfig.terminalAccount.uuid + ':'
          + LocalAppConfig.appConfig.terminalAccount.password)
        }
      };

      if (dc && dc !== 'gb') {
        config.headers['X-Nsft-SCD-DC'] = dc;
      }

      if (params.lang && params.lang.length) {
        config.headers['X-Nsft-SCD-Locale'] = params.lang;
      }

      return $http.get(params.url + '/v1/terminal/bootstrap', config);
    }

    function parseNonCFRoute(url) {
      var dcParsed = url.match(/-([a-zA-Z0-9]*)\./);
      var dc = dcParsed ? dcParsed[1] : false;
      return dc;
    }

    function parseDC(str) {
      const lastDotIndex = str.lastIndexOf('.');
      // Find the index of the next-to-last occurrence of '.'
      const secondLastDotIndex = str.lastIndexOf('.', lastDotIndex - 1);
      const thirdLastDotIndex = str.lastIndexOf('.', secondLastDotIndex - 1);

      if (thirdLastDotIndex === -1) {
        return parseNonCFRoute(str);
      }

      return str.substring(thirdLastDotIndex + 1, secondLastDotIndex).replace('-', '');
    }

    /**
     *
     * @param data
     */
    function setBootstrapProvider(data) {
      BootStrapData.setData(data);
      $window.BootStrapData = data;
    }

    function setTranslations(translations, language = 'en') {
      setVueTranslations(translations, language);
      setSevenComponentsConfig({
        SCCalendar: {
          // Datepicker component uses built in js locale formatter
          // for month and day names, but chrome still has no support for bs and sr locales
          locale: ['bs', 'sr'].indexOf(language) !== -1 ? 'sr-latn' : language,
          translations: {
            labels: {
              accept: translations['actions.accept'],
              cancel: translations['actions.cancel'],
              today: translations['main.today'],
              week: translations['main.week'],
              thisMonth: translations['main.this_month'],
              lastMonth: translations['main.last_month'],
              calendarTitle: translations.calendar_title,
              input: translations.common_time_period
            }
          }
        }
      });
      fetchedTranslations = translations;
    }

    function injectAngularLocale(newLang) {
      var mapper = NabENV.settings.angular.i18nMapper;
      var finalLang = has(mapper, newLang) ? mapper[newLang] : newLang;
      return $ocLazyLoad.load('node_modules/angular-i18n/angular-locale_' + finalLang + '.js');
    }

    function registerGames(clientAppModules) {
      const clientGamesStore = useClientGamesStore();
      clientAppModules.forEach((clientAppModule) => {
        if (clientAppModule.type === 'product') {
          clientGamesStore.registerGame({
            id: clientAppModule.id,
            info: {
              translation: clientAppModule.translation,
              priority: clientAppModule.priority,
              version: clientAppModule.version
            },
            account: {
              linkId: clientAppModule.cpvUuid,
              uuid: clientAppModule.uuid
            }
          });
        }
      });
    }

    function loadTheme(themeName, companyThemeName) {
      var themeToLoad = themeName;
      var THEMES = {
        default: () => import(
          /* webpackChunkName: "theme_default" */
          // eslint-disable-next-line import/no-unresolved
          'theme_default'
        ),
        'default-low': () => import(/* webpackChunkName: "theme_default_low" */
          // eslint-disable-next-line import/no-unresolved
          'theme_default_low'
        ),
        'gray-light': () => import(
          /* webpackChunkName: "theme_gray-light" */
          // eslint-disable-next-line import/no-unresolved
          'theme_gray_light'
        ),
        balkanbet: () => import(
          /* webpackChunkName: "theme_balkanbet" */
          // eslint-disable-next-line import/no-unresolved
          'theme_balkanbet'
        ),
        pionir: () => import(
          /* webpackChunkName: "theme_pionir" */
          // eslint-disable-next-line import/no-unresolved
          'theme_pionir'
        )
      };

      const storedTheme = localStorage.getItem('settings.theme');

      if (storedTheme) {
        themeToLoad = storedTheme;
      } else {
        themeToLoad = themeName;

        if (!themeToLoad && companyThemeName) {
          themeToLoad = companyThemeName;
        } else if (!themeToLoad) {
          themeToLoad = 'default';
        }
      }

      return THEMES[themeToLoad]();
    }

    function handleBootsrapDataResponse(response, storedLang) {
      const cmsAppsStore = useCmsAppsStore();
      const detectedLang = storedLang || response.data.company.locale;
      const marketingStore = useCmsMarketingStore();
      const configSettingsStore = useConfigSettingsStore();
      const gravityMenusStore = useGravityMenusStore();
      const ticketLayoutsStore = useTicketLayoutsStore();
      const appModulesStore = useAppModulesStore();

      var tcLangMapping = null;
      var supportedLanguages = [];

      marketingStore.addSlots(response.data.marketingSlots);

      lang.currentLang = detectedLang;
      languageStore.setCurrentLanguage(detectedLang);
      languageStore.setDisableLanguageSelector(response.data.appConfig.disableLanguageSelector?.value);
      // Create provider inside shared module with logged in data
      setBootstrapProvider(angular.extend(response.data, { appName: cmsAppsStore.app.name }));
      registerGames(response.data.clientAppModules);
      appModulesStore.$patch({
        appModules: response.data.clientAppModules
      });

      GravitySettings.setData(response.data.appSettings);
      gravitySettingsStore.setData(response.data.appSettings);
      _.each(GravitySettings.getData(), function (setting, key) {
        if (localStorage.getItem(key)) {
          GravitySettings.updateSetting(key, JSON.parse(localStorage.getItem(key)));
          gravitySettingsStore.updateSetting(key, JSON.parse(localStorage.getItem(key)));
        }
      });

      ticketLayoutsStore.setData(response.data.ticketLayouts);

      gravityMenusStore.setData(response.data.menus);

      // set log level from CMS
      // this will be applied only log level is not already set by
      // query params or local storage is not alredy set
      Environment.setLogLevel(
        Environment.detectLogLevel(GravitySettings.getModuleDataKeyValue('application', 'logLevel'))
      );

      // Gravity Settings are loaded so we need to detect log level again
      logService.setLogLevel();
      acceptorsService.init();

      sevenStore.$patch({
        tenant: response.data.company,
        betshop: response.data.betshop,
        terminal: response.data.terminal
      });
      configSettingsStore.$patch({
        data: response.data.appConfig,
        ticketActions: response.data.ticketActions
      });
      self.mergeGravityConfigWithProducts(response.data.clientAppModules);
      supportedLanguages = GravitySettings.getModuleDataKeyValue('languages', 'supportedLanguages');
      tcLangMapping = find(supportedLanguages, function (langValue) {
        return langValue.id === lang.currentLang && !isUndefined(langValue.tcMapping);
      });
      vueApp.mount('#app');
      return $q.all([
        getTranslations((tcLangMapping && tcLangMapping.tcMapping) || lang.currentLang, LocalData.betshop.companyUuid),
        loadTheme(
          Environment.getUrlParameter('theme'),
          GravitySettings.getModuleDataKeyValue('config', 'theme')
        ),
        injectAngularLocale(lang.currentLang)
      ]);
    }

    function loadCustomerCards(bootstrapResponse) {
      const companyUuid = LocalData.betshop.companyUuid;
      const storedLang = localStorage.getItem('settings.language');
      const customerCardsUrl = `${NabENV.api.customerCards}/tenants/${companyUuid}/customerCards?status=active&channel=terminal`;
      return $http.get(customerCardsUrl).then((customerCardsResponse) => {
        const customerCardStore = useCustomerCardStore();
        customerCardStore.activeCustomerCardInstances
          .push(...customerCardsResponse.data);
        if (customerCardsResponse.data?.length) {
          Modules.registerModules({
            customer_card: {
              enabled: true
            }
          });
        }
        return handleBootsrapDataResponse(bootstrapResponse, storedLang);
      }).catch((err) => {
        $log.error('[7Terminal.Bootstrap] Failed to fetch customer cards', {
          ...errorParser.parseUpstream(err),
          code: 'T_CUSTOMER_CARDS_FETCH_FAILED'
        });
        return handleBootsrapDataResponse(bootstrapResponse, storedLang);
      });
    }

    function loadBootstrap() {
      const companyUuid = LocalData.betshop.companyUuid;
      const storedLang = localStorage.getItem('settings.language');

      return fetchGravityBootstrap({
        url: process.env.GRAVITY_API_URL_CF,
        companyUuid,
        lang: storedLang
      })
        .then((response) => loadCustomerCards(response))
        .catch((err) => {
          $log.error('[7Terminal.Bootstrap] Failed to fetch gravity bootstrap.', {
            ...errorParser.parseUpstream(err),
            code: 'T_FETCH_GRAVITY_BOOTSTRAP_CF_FAILED'
          });
          throw err;
        });
    }

    this.mergeGravityConfigWithProducts = function (modules) {
      var moduleConfig = {};
      var configLocalOverwrite = {};
      modules.forEach(function (module) {
        if (module.type === 'product') {
          moduleConfig = GravitySettings.getByKey('module.' + module.id);
          // let's give option for DEV to override CMS config,
          // for example DEV wants to set URL to localhost
          configLocalOverwrite = JSON.parse(localStorage.getItem('module.' + module.id));
          if (configLocalOverwrite || moduleConfig) {
            module.local = configLocalOverwrite || moduleConfig;
            if (configLocalOverwrite) {
              // eslint-disable-next-line
              $log.debug('[7Terminal.Games] Product config loaded from local storage', {
                moduleId: module.id,
                localConfigOverwrite: configLocalOverwrite
              });
            }
          }
        }
      });
    };

    this.boot = function (config, runtimeService) {
      const cmsAppStore = useCmsAppsStore();
      handleWindowTitle();
      return runtimeService.getData({
        windowTitle: $window.document.title
      }).then(function (response) {
        var envDC;
        sevenStore.$patch({
          device: response.device.data.device
        });
        // Attach data to window
        angular.extend(NabENV, LOCAL_DATA.env);
        angular.extend(LocalAppConfig, response.appConfig.data);
        localAppConfigService.setData(response.appConfig.data);

        angular.extend(LocalData, response.device.data);
        envDC = parseDC(LocalAppConfig.sevenUrl);
        environmentHandlerStore.setDc(envDC);
        SevenApiRoutes.setRoutes({
          mainUrl: LocalAppConfig.sevenUrl,
          cmUrl: LocalAppConfig.sevenSocketUrl,
          ngs: NabENV.api.ngs,
          taxAuth: NabENV.taxAuth || '', // TODO remove this after ro tax moved to v2
          tboMainUrl: LocalAppConfig.sevenUrl + '/terminal-admin/',
          reportingUrl: NabENV.reporting
        });
        angular.extend(SevenRoutes, SevenApiRoutes.routes);
        return true;
      }).then(function () {
        return cmsAppStore.getApp(LocalData.betshop.companyUuid)
          .finally(() => loadBootstrap());
      });
    };

    this.getTranslations = function () {
      return fetchedTranslations;
    };

    this.getLocalConfig = function () {
      // eslint-disable-next-line no-undef
      var configPath = `data/config.${GIT_HASH}.json`;
      return $http.get(configPath);
    };

    this.setLocalConfig = function (config) {
      // we have environment set already by build process so set data
      LOCAL_DATA.env = config;
      ENVStore.setData(config);
    };

    this.fetchTranslations = function (language) {
      var browserLang = $translate.resolveClientLocale().substring(0, 2).toLowerCase();
      return $http.get(`${process.env.GRAVITY_API_URL_CF}/v1/terminal/translations`, {
        headers: {
          'X-Nsft-SCD-Locale': browserLang || language || 'en'
        }
      });
    };

    this.loadTheme = loadTheme;
    this.setTranslations = setTranslations;
  }
})();
