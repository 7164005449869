import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "t-footer-vue d-block sc-bg-500" }
const _hoisted_2 = { class: "float-left d-flex h-100 sc-px-3" }
const _hoisted_3 = { class: "sc-mr-3" }
const _hoisted_4 = { class: "t-footer-toggle" }
const _hoisted_5 = { class: "float-right d-flex h-100" }

import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import TClock from '@/common/components/TClock.vue';
import InactivityBar from '@/common/components/InactivityBar.vue';
import TerminalInfo from '@/common/components/TerminalInfo.vue';
import { onEndInactivityTimer } from '@/common/services/idle/inactivityTimerHelper';
import TFooterActionsRight from './TFooterActionsRight.vue';
import TFooterActionsLeft from './TFooterActionsLeft.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TFooter',
  setup(__props) {

const configSettingsStore = useConfigSettingsStore();
const inactivityTime = configSettingsStore.data.terminalInactivityTime.value || 60000;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(InactivityBar, {
          name: "terminal-inactivity",
          orientation: "vertical",
          "on-end-inactivity-timer": _unref(onEndInactivityTimer),
          "transition-delay": 5000,
          time: _unref(inactivityTime)
        }, null, 8 /* PROPS */, ["on-end-inactivity-timer", "time"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(TerminalInfo, { class: "sc-mr-3" })
      ]),
      _createVNode(TFooterActionsLeft)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(TFooterActionsRight),
      _createVNode(TClock)
    ])
  ]))
}
}

})