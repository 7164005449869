import axios from 'axios';
import i18n from '@/plugins/i18n';
import { sevenCurrency } from '@/common/filters';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { voucherManager, type VoucherResponse } from '@/modules/vouchers';
import { logService } from '@/common/services/logger';
import BaseError from '@/common/errors/BaseError';
import { workingTimeService } from '@/modules/working-time';
import { googleAnalyticsService } from '@/modules/google-analytics';
import voucherValidationService from '@/modules/vouchers/services/voucherValidationService';

const { t } = i18n.global;
const LOG_PREFIX = '[voucherActionsService]';

const doVoucherPayout = (voucher: VoucherResponse) => {
  const notificationId = 'voucher-payout-notif';
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId(notificationId);
  googleAnalyticsService.trackEvent('Voucher_Payout', {
    event: 'Voucher Payout',
  });

  if (voucherManager.checkIsVoucherPayoutDisabled()) {
    const message = t('voucher_activation_disabled');
    notificationsStore.show(
      {
        message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    logService.warn(`${LOG_PREFIX} Voucher payout is disabled.`, {
      code: 'T_VOUCHER_ACTIVATION_DISABLED',
      ticket_code: voucher.id,
      product_displayid: 'voucher',
    });
    const error = new BaseError(message, 'T_VOUCHER_ACTIVATION_DISABLED');
    return Promise.reject(error);
  }

  const { message, hasError } = workingTimeService.validateWorkingHours();

  if (hasError && message) {
    const code = 'T_VOUCHER_PAYOUT_WORKING_TIME_ERROR';
    notificationsStore.show(
      {
        message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    logService.warn(`${LOG_PREFIX} Voucher payout cannot be done since working time is not valid.`, {
      code,
      ticket_code: voucher.id,
      product_displayid: 'voucher',
    });
    return Promise.reject(new BaseError(message, code));
  }

  const voucherData = {
    ...voucherManager.generatePayoutVoucherData(),
    id: voucher.id.toUpperCase(),
    ...(voucher.ticketPin && { ticketPin: voucher.ticketPin }),
  };

  return voucherValidationService.validatePayout(voucherData)
    .then(() => voucherManager.payoutVoucher(voucherData)
      .then((response) => {
        notificationsStore.show(
          {
            message: t('voucher.payout_success_to_balance', { amount: sevenCurrency(response.totalAmount) }),
            type: TNotificationTypeEnum.success,
            delay: 4000,
          },
        );
        return response;
      })
      .catch((error) => {
        const parser = axios.isAxiosError(error) ? axiosErrorParser : errorParser;
        const details = parser.parseUpstream(error);

        notificationsStore.show(
          {
            message: details.upstream_message,
            type: TNotificationTypeEnum.warning,
            delay: 3000,
          },
        );

        logService.warn(`${LOG_PREFIX} Voucher payout error has occurred.`, {
          code: 'T_VOUCHER_HTTP_ERROR',
          ticket_code: voucher.id,
          product_displayid: 'voucher',
          ...details,
        });

        return Promise.reject(new BaseError(details.upstream_message, 'T_VOUCHER_HTTP_ERROR', {
          cause: error,
        }));
      }))
    .catch((err) => {
      const code = 'T_VOUCHER_PAYOUT_VALIDATION_ERROR';
      const error = new BaseError(errorParser.parseMessage(err), code, {
        cause: err,
      });

      logService.error(`${LOG_PREFIX} Voucher payout validation error detected.`, {
        code,
        ...errorParser.parseUpstream(err),
      });

      return Promise.reject(error);
    });
};

export {
  doVoucherPayout,
};
