import {
  TboTicketType,
  type TboFormattedVoucher,
  type TboFormattedTicket,
  type TicketListItem,
  type VoucherListItem,
} from '@/modules/tbo-tickets/types';
import * as tboTicketsService from './tboTicketsService';
import * as tboTicketsValidationService from './tboTicketsValidationService';
import * as tboPayoutService from './tboPayoutService';

export { useTicketScanInTbo } from './tboScanTicketService';
export const initTboTicketsModule = () => {
  tboTicketsService.init();
};
export {
  tboTicketsService,
  tboTicketsValidationService,
  tboPayoutService,
  TboTicketType,
};

export type {
  TboFormattedVoucher,
  TboFormattedTicket,
  TicketListItem,
  VoucherListItem,
};
