(function () {
  angular.module('7Terminal.Common')
    .directive('reqLoading', function reqLoading(
      $rootScope,
      $timeout
    ) {
      return {
        replace: true,
        scope: {
          left: '=left',
          right: '=right',
          bottom: '=bottom',
          top: '=top',
          message: '@message',
          duration: '=duration'
        },
        template: '<div class="loader-svg" '
          + 'ng-style="{\'left\': left+ \'%\', \'right\': right + \'%\', \'top\': top + \'%\', \'bottom\': bottom + \'%\'}"></div>',

        link: function (scope, elem) {
          var httpRequestListener;
          var httpResponseListener;
          var loaderStartListener;
          var loaderStopListener;

          var loading = function (enable) {
            if (enable) {
              elem.addClass('load-active');
            } else {
              elem.removeClass('load-active');
            }
            if (elem[0].parentElement) {
              elem[0].parentElement.disabled = enable;
            }
          };

          var timer = null;
          var duration = scope.duration || 10000;

          function startLoader(message) {
            if (message.indexOf(scope.message) !== -1) {
              loading(true);

              timer = $timeout(function () {
                loading(false);
              }, duration, false);
            }
          }

          function stopLoader(message) {
            if (message.indexOf(scope.message) !== -1) {
              if (elem[0].parentElement) {
                elem[0].parentElement.blur();
              }

              loading(false);

              $timeout.cancel(timer);
            }
          }

          httpRequestListener = $rootScope.$on('$http:request', function (event, message) { startLoader(message); });
          loaderStartListener = $rootScope.$on('Loader.Start', function (event, message) { startLoader(message); });

          httpResponseListener = $rootScope.$on('$http:response', function (event, message) { stopLoader(message); });
          loaderStopListener = $rootScope.$on('Loader.Stop', function (event, message) { stopLoader(message); });

          scope.$on('$destroy', function () {
            httpRequestListener();
            httpResponseListener();
            loaderStartListener();
            loaderStopListener();
          });
        }
      };
    });
})();
