import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-100 t-header-logo sc-ml-5"
}

import { ref, computed } from 'vue';
import router from '@/router';
import resourcesService from '@/modules/cms/resources/resourcesService';
import { vLoadImage } from '@/common/directives/LoadImage';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderLogo',
  setup(__props) {

const activeState = ref('');
const showLogo = computed(() => activeState.value === '/home');

router.beforeEach((route) => {
  activeState.value = route.path;
});

const imgSrc = resourcesService.getLogoUrl();
const errImgSrc = resourcesService.getS3LogoUrl();

return (_ctx: any,_cache: any) => {
  return (showLogo.value)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512 /* NEED_PATCH */)), [
        [_unref(vLoadImage), { imgSrc: _unref(imgSrc), errImgSrc: _unref(errImgSrc) }]
      ])
    : _createCommentVNode("v-if", true)
}
}

})