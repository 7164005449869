export enum CardStatus {
  CARD_IN = 'CARD_IN',
  CARD_OUT = 'CARD_OUT',
}

export enum CardNid {
  passCard = 'passCard',
  hdioa762 = 'hdioa762',
  formatted = '00000000',
}
