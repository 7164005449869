/* eslint-disable radix */
import templateUrl from './templates/scrollButtons.html';

(function () {
  angular.module('7Terminal.Common')
    .directive('customScroll', function customScroll($timeout, SevenClientLibConfig) {
      return {
        restrict: 'A',
        transclude: true,
        replace: true,
        scope: {
          accessor: '='
        },
        templateUrl: templateUrl,
        link: function (scope, elem, attrs) {
          var content = elem.find('.scroll__content');
          var navEl = elem.find('.scrollbox__navigation');
          var scrollStep = parseInt(elem.outerHeight() * 0.8);
          var scrollSpeed;
          var response = localStorage.getItem('settings.scrollAnimationSpeed');

          scrollSpeed = response ? parseInt(response) : 350;

          scope.showNavigation = SevenClientLibConfig.scroll.showNavigation;

          scope.toTop = parseInt(attrs.toTop);

          function showHideNavigation() {
            var contentOverflowed = content.get(0) ? content.get(0).scrollHeight > content.get(0).offsetHeight : false;

            if (contentOverflowed) {
              navEl.addClass('is--showed');
            } else {
              navEl.removeClass('is--showed');
            }
          }

          function setScrollStep() {
            scrollStep = parseInt(content.get(0).offsetHeight * 0.8);
          }

          scope.$watch(
            function () {
              return content.get(0).scrollHeight;
            },
            function () {
              showHideNavigation();
              setScrollStep();
            }
          );

          // Scroll box to the top
          scope.scrollToTop = function () {
            content.animate({ scrollTop: 0 }, scrollSpeed);
          };

          scope.scrollUp = function () {
            var elemPosition;
            // Stop previous animation
            content.stop();

            elemPosition = content.scrollTop();
            elemPosition -= scrollStep;

            content.animate({ scrollTop: elemPosition }, scrollSpeed);
          };

          scope.scrollDown = function () {
            var elemPosition;
            // Stop previous animation
            content.stop();

            elemPosition = content.scrollTop();
            elemPosition += scrollStep;

            content.animate({ scrollTop: elemPosition }, scrollSpeed);
          };

          // expose api
          if (scope.accessor) {
            scope.accessor = {
              scrollToTop: scope.scrollToTop,
              showHideNavigation: function () {
                $timeout(function () {
                  showHideNavigation();
                });
              }
            };
          }

          showHideNavigation();
        }
      };
    });
})();
