import { logService } from '@/common/services/logger';
/**
  * @ngdoc service
  * @name 7Terminal.Runners.decorator:runnerLogDecorate
  *
  * @description
  * Decorator around $log service that proxy logs to [seven-client-nw-runner
](https://github.com/nsftx/seven-client-nw-runner) using [Logger.log](https://github.com/nsftx/seven-client-nw-runner#loggerlog).
  *
* */

/* @ngInject */
function runnerLogDecorate($delegate) {
  function swap(originalFn, type) {
    return function () {
      var args = [].slice.call(arguments);
      logService.sendLog({ message: args[0], data: args[1] }, type);
    };
  }

  $delegate.warn = swap($delegate.warn, 'warn');
  $delegate.error = swap($delegate.error, 'error');
  $delegate.info = swap($delegate.info, 'info');
  $delegate.debug = swap($delegate.debug, 'debug');

  // Return the delegate
  return $delegate;
}

export default runnerLogDecorate;
