import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "t-notification-overlay"
}

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { SCToastNotificationGroup, ToastNotification } from '@nsftx/seven-components';
import { useNotificationsStore } from '@/common/stores/notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';


export default /*@__PURE__*/_defineComponent({
  __name: 'TNotifications',
  setup(__props) {

const notificationsStore = useNotificationsStore();
const gravitySettingsStore = useGravitySettingsStore();

const { notifications } = storeToRefs(notificationsStore);
const position = gravitySettingsStore.getModuleDataKeyValue('config', 'notificationsPosition');

const showOverlay = computed(() => notifications
  .value.some((notif) => notif.uiBlocking === true));

const onNotificationUpdate = (updatedNotifications: Array<ToastNotification>) => {
  notificationsStore.updateNotifications(updatedNotifications);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (showOverlay.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createVNode(_unref(SCToastNotificationGroup), {
      modelValue: _unref(notifications),
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (_isRef(notifications) ? (notifications).value = $event : null)),
        onNotificationUpdate
      ],
      position: _unref(position)
    }, null, 8 /* PROPS */, ["modelValue", "position"])
  ]))
}
}

})