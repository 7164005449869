/* eslint-disable import/no-cycle */
import angular from 'angular';
import coreModule from '../core';
import LimitsService from './limitsService';

const moduleName = angular.module('7Terminal.Limits', [
  coreModule
])
  .service('LimitsService', LimitsService)
  .name;

export default moduleName;
