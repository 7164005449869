import { useGlobalLoader } from '@/common/composables/useGlobalLoader';
import { getProvinceByBetshopUuid } from './pla-reports/apiService';
import { useIntelligentGamingStore } from './store';

export const checkProvinceForBetshop = () => {
  const intelligentGamingStore = useIntelligentGamingStore();
  const { showLoader, endLoader } = useGlobalLoader();

  showLoader();

  getProvinceByBetshopUuid()
    .then(() => {
      intelligentGamingStore.isProvinceModalShown = false;
    }).catch(() => {
      intelligentGamingStore.isProvinceModalShown = true;
    }).finally(() => {
      endLoader();
    });
};
