import { ref } from 'vue';
import { defineStore } from 'pinia';

const useAuthStore = defineStore('auth', () => {
  const isLoginModalOpened = ref(false);
  const preventLoginModalClose = ref(false);
  const isLoginFailedModalOpened = ref(false);
  const isPINLoginModalOpened = ref(false);

  return {
    isLoginModalOpened,
    preventLoginModalClose,
    isLoginFailedModalOpened,
    isPINLoginModalOpened,
  };
});

export {
  useAuthStore,
};
