(function () {
  angular.module('terminalApp.nabLuckyXUi')
    .service('LuckyXUiService', LuckyXUiService);

  function LuckyXUiService(
    $rootScope,
    BetslipService,
    nabTrans,
    nabMessaging,
    SevenGamesSvc,
    ticketService,
    GravitySettings
  ) {
    var TICKET_TYPES = {
      SINGLE: {
        id: 3,
        name: 'single'
      }
    };

    /** Listen for new luckyX round and update
        bets with new event number * */

    nabMessaging.subscribe('LuckyX:updateEvents', function (e, data) {
      var betsToUpdate = [];
      var event;
      var eventAfter = data.param;
      var ticket = BetslipService.getTicket(TICKET_TYPES.SINGLE.name, 'LuckyX');

      if (ticket) {
        ticket.getBets()
          .forEach(function (bet) {
            // update event when new round starts - be careful of schedule event
            event = bet.event.toString().split('-');
            eventAfter = event.length > 1 ? (Number(data.param) + 1) + '-' + (Number(data.param + 1) + (Number(event[1]) - Number(event[0]))) : (Number(data.param) + 1);
            betsToUpdate.push({
              event: eventAfter,
              uid: bet.uid
            });
          });

        $rootScope.$broadcast('7T:Betslip.UpdateBets', {
          data: {
            betslipId: 'LuckyX',
            ticketId: TICKET_TYPES.SINGLE.name,
            bets: betsToUpdate
          }
        });
      }
    });

    // listen for config changes
    nabMessaging.subscribe('NCM:gameConfigChanged', function (event, message) {
      if (message.game === 'LuckyX') {
        var game = SevenGamesSvc.getGame(message.game);
        game.setConfig().then(function () {
          game.doInitialConfiguration();
          $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
            data: {
              betslipId: 'LuckyX',
              ticketId: TICKET_TYPES.SINGLE.name,
              config: {
                group: {
                  id: 'LuckyX',
                  taxesConfig: game.config.taxes
                },
                stake: game.getRule('minPayinAmount', {
                  ticketType: TICKET_TYPES.SINGLE
                })
              }
            }
          });

          nabMessaging.publish('config:gameConfigChanged', message.game);
        });
      }
    });

    // listen for betslip payin action
    $rootScope.$on('7T:Betslip.Payin',
      /**
             *
             * @param event
             * @param {Object} message
             * @param {String} message.productId
             * @param {Object} message.ticket
             */
      function (event, message) {
        if (message.productId === 'LuckyX') {
          ticketService.payinTicket(message).catch(angular.noop);
        }
      });

    $rootScope.$on('7T:Tickets.PrePrint', function (e, eventData) {
      if (eventData.productId === 'LuckyX') {
        const isInIframe = GravitySettings
        .getModuleDataKeyValue(`module.${eventData.productId}`, 'frame') === true;
        var gameService = SevenGamesSvc.getGame('LuckyX');
        var bettingService = gameService.getBettingSvc();

        if (isInIframe) {
          return;
        }

        angular.merge(eventData.responseData, bettingService.prepareForPrint(
          eventData.responseData,
          gameService.config
        ));

        $rootScope.$emit('7T:Tickets.Print', eventData);
      }
    });

    this.boot = function () {
      if (!BetslipService.getTicket(TICKET_TYPES.SINGLE.name, 'LuckyX')) {
        var productConfig = SevenGamesSvc.getGame('LuckyX');

        $rootScope.$broadcast('7T:Betslip.Create', {
          data: {
            betslip: {
              id: 'LuckyX',
              tickets: [
                {
                  id: TICKET_TYPES.SINGLE.name, // if of ticket build of game id (PrematchBetting) and ticket type (normal)
                  active: true, // active in current group (LuckyX)
                  ticketGroup: 'LuckyX',
                  betsCollection: 'LuckyX',
                  group: {
                    id: 'LuckyX',
                    taxesConfig: productConfig.config.taxes
                  },
                  ticketType: TICKET_TYPES.SINGLE, // this is only needed when when formatting data for server, it represent server id
                  oddProp: 'mboOddValue', // this is badd :((
                  stake: productConfig.getRule('minPayinAmount', {
                    ticketType: TICKET_TYPES.SINGLE.id
                  }),
                  stakeTouched: false,
                  setStakeStrategy: 'sevenVirtualSetStake',
                  winningStrategy: false,
                  setFormatPayin: 'sevenVirtualFormataPayin',
                  hasSystem: false,
                  emptyBetslipText: nabTrans.translate('betslip.pick_numbers_message_first_line', {}, true),
                  autoStakeSetting: true
                }
              ]
            }
          }
        });
      }

      $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
        data: {
          betslipId: 'LuckyX',
          ticketId: TICKET_TYPES.SINGLE.name,
          config: {
            emptyBetslipText: nabTrans.translate(
              'betslip.pick_numbers_message_first_line', {}, true
            )
          }
        }
      });
    };
  }
})();
