
      var API = require("!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
      var domAPI = require("!../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js");
      var insertFn = require("!../../../../node_modules/style-loader/dist/runtime/insertBySelector.js");
      var setAttributes = require("!../../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js");
      var insertStyleElement = require("!../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js");
      var styleTagTransformFn = require("!../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js");
      var content = require("!!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-7.use[2]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/sass-loader/dist/cjs.js!../../../../node_modules/vue-loader/dist/index.js??ruleSet[0]!./THeaderVoucher.vue?vue&type=style&index=0&id=0667aa73&lang=scss");
      
      content = content.__esModule ? content.default : content;

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



module.exports = content && content.locals || {};
