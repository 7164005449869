import * as Sentry from '@sentry/vue';
import PubSub from 'pubsub-js';
import { App as Application } from 'vue';
import { Router } from 'vue-router';
import { operatorService } from '@/modules/operator';
import { useSevenStore } from '@/modules/seven';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';

const isSentryEnabled = () => {
  const envHandlerStore = useEnvironmentHandlerStore();
  return process.env.NODE_ENV === 'production'
  && !envHandlerStore.isDevelopmentMode();
};

const initSentry = (app: Application, router: Router) => {
  const sevenStore = useSevenStore();

  Sentry.init({
    app,
    debug: !isSentryEnabled(),
    enabled: isSentryEnabled(),
    dsn: 'https://63a95bbf692d498eabcc7532cc372340@o73276.ingest.us.sentry.io/1262724',
    release: `seven-terminal@${process.env.TERMINAL_VERSION}`,
    environment: process.env.SEVEN_ENVIRONMENT,
    ignoreErrors: [
      // error from NGS
      'Cannot read properties of undefined (reading \'eventId\')',
      'Cannot read property \'eventId\' of undefined',
      'Cannot read properties of undefined (reading \'removeDuplicateBets\')',
      'Cannot set properties of null (setting \'eventId\')',
      // AngularJS error on route change
      'transition superseded',
      'ResizeObserver loop limit exceeded',
    ],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });

  sevenStore.$subscribe((mutation, state) => {
    Sentry.setTag('tenantUuid', state.tenant.uuid);
    Sentry.setTag('betshopUuid', state.betshop.uuid);
    Sentry.setTag('deviceUuid', state.device.uuid);
    Sentry.setExtra('sevenContext', {
      tenantName: state.tenant.name,
      betshopName: state.betshop.name,
      deviceName: state.device.name,
    });
  });

  PubSub.subscribe('7T:User.AuthorizationChanged', () => {
    Sentry.setUser({
      username: operatorService.data.username,
      id: operatorService.data.uuid,
    });
  });
};

export {
  initSentry,
};
