import axios from 'axios';
import Logger, { ILogLevel } from 'js-logger';
import { useSevenStore } from '@/modules/seven';
import { useENVStore } from '@/common/stores/env';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import type {
  SevenLogLevel,
  SevenLog,
  SevenLogData,
  LocalLog,
} from './index';
import { errorParser } from '../error-parser';

const LOG_PREFIX = '[logService]';
const LS_KEY_PREFIX = 'terminal.logs-backup-';
const REQUEST_TIMEOUT = 10_000;
const BACKUP_ITEMS_LIMIT = 15;
const LOG_LEVELS: Record<string, number> = {
  debug: 2,
  info: 3,
  warn: 5,
  error: 8,
};

let intervalInstance: number | undefined;

let logs: Array<SevenLog> = [];

let currentLogLevel = 0;

let isLoggerSet = false;

let additionalLogData: SevenLogData = {};

// temporary fix until we remove NPS
const blacklistMessages = [
  '[NgPrintService] selectPrinter startPrinterChecker failed',
  '[NgPrintService] selectPrinter stop checker failed',
];

const clearLogs = () => {
  logs = [];
};

const deepCopyObject = (source: typeof logs) => {
  if (typeof structuredClone === 'function') {
    try {
      return structuredClone(source);
    } catch (err) {
      // cannot use $log here, it will throw Maximum call stack size exceeded error
      // eslint-disable-next-line no-console
      console.warn(`${LOG_PREFIX} Log copy failed. Fallback to JSON api`, {
        code: 'T_LOGGER_COPY_STRUCT_ERR',
        ...errorParser.parseUpstream(err),
      });
      return JSON.parse(JSON.stringify(source));
    }
  }

  return JSON.parse(JSON.stringify(source));
};

const sendLog = (
  { message, data }: { message: string, data?: SevenLogData },
  level: SevenLogLevel,
): void => {
  const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
  const localTime = (new Date(Date.now() - timeZoneOffset));
  const validMessage = message || 'T_NO_MESSAGE_PROVIDED';
  let log: LocalLog = {
    message: validMessage,
    level,
    timestamp: localTime.toISOString(),
  };

  if (typeof data === 'object' && !Array.isArray(data)) {
    log = { ...log, ...data, ...additionalLogData };
  }

  if (log.upstream_code) {
    log.upstream_code = log.upstream_code.toString();
  }

  Logger[level](validMessage, log);
};

const sendLogsToGraylog = (logsToSend: typeof logs) => {
  const sevenStore = useSevenStore();
  const envStore = useENVStore();
  const { tenant, betshop, device } = sevenStore;
  const tempLogs = logsToSend.map((log) => {
    const sevenLog: SevenLog = {
      ...log,
      channel: '7terminal',
      appName: localStorage.getItem('window.title'),
      tenant_id: tenant.uuid,
      tenant_name: tenant.name,
      betshop_id: betshop.uuid,
      betshop_name: betshop.name,
      device_id: device.uuid,
      device_name: device.name,
      version: envStore.data.client_version,
      http_user_agent: window.navigator.userAgent,
    };
    return sevenLog;
  });

  return axios.post(`${process.env.GRAPE_API_URL}/bulk_logs`, JSON.stringify(tempLogs), {
    timeout: REQUEST_TIMEOUT,
    headers: {
      Authorization: `Basic ${btoa(`${process.env.GRAPE_API_AUTH_USER}:${process.env.GRAPE_API_AUTH_PASS}`)}`,
      'Content-Type': 'application/json',
    },
  }).catch((err) => {
    // we need to catch this call or sentry will report error,
    // let's log error to console so we dont miss it in development/qa/production
    // eslint-disable-next-line no-console
    console.error('[logService] Send logs to graylog error', err);
    return Promise.reject(err);
  });
};

const saveLocallyFailedSend = (logsData: typeof logs, persistKey?: string) => {
  const key = persistKey || `${LS_KEY_PREFIX}${Date.now()}`;
  // Remove the oldest log if we exceed the limit
  const lsKeys = Object.keys(localStorage)
    .filter((lsKey) => lsKey.startsWith(LS_KEY_PREFIX)).sort();
  if (lsKeys.length >= BACKUP_ITEMS_LIMIT) {
    const oldestKey = lsKeys[0]; // Oldest key (first in the sorted list)
    localStorage.removeItem(oldestKey); // Remove oldest log
  }

  localStorage.setItem(key, JSON.stringify(logsData));
  return key;
};

const retrySendingLogsAfterFail = (logsData: typeof logs, lsKey: string) => {
  const LOGS_RESEND_TIMEOUT = 1000 * 60;
  // try to send them once again in 1 minute
  setTimeout(() => {
    // if sent in the mean time by retryFailedToSendLogsFromLS, do not proceed
    if (!localStorage.getItem(lsKey)) {
      return;
    }

    localStorage.removeItem(lsKey);

    sendLogsToGraylog(logsData).catch((err) => {
      // failed? we need to retry
      saveLocallyFailedSend(logsData, lsKey);
      sendLog({
        message: `${LOG_PREFIX} Failed to send logs on retry`,
        data: {
          code: 'T_LOGGER_RETRY_ERR',
          ...errorParser.parseUpstream(err),
        },
      }, 'error');
    });
  }, LOGS_RESEND_TIMEOUT);
};

const sendLogsAndClearLogsQueue = () => {
  const logsToSend = deepCopyObject(logs);
  clearLogs();
  return sendLogsToGraylog(logsToSend).catch((err) => {
    sendLog({
      message: `${LOG_PREFIX} Send logs on send and clear failed`,
      data: {
        code: 'T_LOGGER_SEND_AND_CLEAR_ERR',
        ...errorParser.parseUpstream(err),
      },
    }, 'error');

    // failed? we need to retry
    const lsKey = saveLocallyFailedSend(logsToSend);
    retrySendingLogsAfterFail(logsToSend, lsKey);
    return Promise.reject(err);
  });
};

const isDebugMode = (): boolean => currentLogLevel === LOG_LEVELS.debug;

const consoleHandler = Logger.createDefaultHandler();

const retryFailedToSendLogsFromLS = () => {
  const lsKeys = Object.keys(localStorage);
  const foundFailedKeys = lsKeys.filter((key) => key.startsWith(LS_KEY_PREFIX)).sort();

  if (foundFailedKeys.length) {
    // send only one batch, the oldest one
    const keyName = foundFailedKeys[foundFailedKeys.length - 1];
    const logsFromLs = JSON.parse(localStorage.getItem(keyName) || '');
    localStorage.removeItem(keyName);

    sendLogsToGraylog(logsFromLs).catch((err) => {
      sendLog({
        message: `${LOG_PREFIX} Send logs from LS failed`,
        data: {
          code: 'T_LOGGER_RETRY_FROM_LS_ERR',
          ...errorParser.parseUpstream(err),
        },
      }, 'error');
      // failed? we need to retry
      saveLocallyFailedSend(logsFromLs, keyName);
    });
  }
};
const sendLogsOnMessageLimit = (log: Array<SevenLog>): void => {
  if (blacklistMessages.indexOf(log[1].message) !== -1) {
    return;
  }

  logs.push(log[1]);
  if (logs.length >= parseInt(process.env.GRAPE_MESSAGE_LIMIT, 10)) {
    sendLogsAndClearLogsQueue().catch(() => {
      sendLog({
        message: `${LOG_PREFIX} Send logs on batch failed`,
        data: {
          code: 'T_LOGGER_SEND_BATCH_ERR',
        },
      }, 'error');
    });
  }
};

const startSendLogsInIntervals = (): void => {
  if (intervalInstance) {
    clearInterval(intervalInstance);
  }

  intervalInstance = window.setInterval(() => {
    if (logs.length > 0) {
      sendLogsAndClearLogsQueue().catch(() => {
        sendLog({
          message: `${LOG_PREFIX} Send logs on interval failed`,
          data: {
            code: 'T_LOGGER_SEND_AND_CLEAR_INTERVAL_ERR',
          },
        }, 'error');
      });
      retryFailedToSendLogsFromLS();
    }
  }, parseInt(process.env.GRAPE_INTERVAL_LIMIT, 10));
};

const setLoggerLevel = (level: ILogLevel) => {
  Logger.setLevel(level);
};

const getUrlParameter = (name: string): string => {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getLogLevel = (logLevel: string): number => LOG_LEVELS[logLevel] || LOG_LEVELS.error;

const detectLogLevel = (): string => {
  const debugModeParam = getUrlParameter('debugMode');
  const ls = localStorage.getItem('settings.logLevel');
  const qp = getUrlParameter('logLevel');

  let gravitySettingsStore;
  let logLevel = 'info';

  if (isLoggerSet) {
    gravitySettingsStore = useGravitySettingsStore();
    logLevel = gravitySettingsStore.getModuleDataKeyValue('application', 'logLevel');
  }

  if (debugModeParam) {
    return 'debug';
  }

  if (ls === null && (qp === 'false' || qp === '' || qp === 'undefined')) {
    return logLevel;
  }

  return ls || qp;
};

const onRightClick = (event: Event): void => event.preventDefault();

// When our stores are not yet initialzed, our default logLevel = OFF - meaning our logger will
// only work once pinia/stores are initialzed (on boot). However this is a problem, because we
// have many services that run before that so they will never work with logService.
// That's why we need this fn to set default logLevel before we even have any data from CMS .e.g
// Also, we need to call this function immediately once this file gets imported.
const setDefaultLogLevel = (): void => {
  const logLevel = detectLogLevel();

  Logger.useDefaults({
    defaultLevel: {
      value: getLogLevel(logLevel),
      name: logLevel.toUpperCase(),
    },
  });
};

setDefaultLogLevel();

const setLogLevel = (): void => {
  const logLevel = detectLogLevel();
  currentLogLevel = getLogLevel(logLevel);
  if (currentLogLevel > LOG_LEVELS.debug) {
    // if in not debug mode prevent right click (blocks inspect mode)
    window.addEventListener('contextmenu', onRightClick);
  } else {
    window.removeEventListener('contextmenu', onRightClick);
  }

  setLoggerLevel(
    {
      value: currentLogLevel,
      name: logLevel?.toUpperCase(),
    },
  );
};

const setAdditionalLogData = (data: SevenLogData) => {
  additionalLogData = { ...additionalLogData, ...data };
};

const initLogger = (isLogStreamEnabled: boolean) => {
  if (isLogStreamEnabled) {
    startSendLogsInIntervals();
  } else {
    // eslint-disable-next-line no-console
    console.debug(`${LOG_PREFIX} Log stream to grape not enabled. App will not stream logs to Grape.`);
  }

  // We need to call initLogger method twice because of CMS settings
  // are loaded afterwards. So to prevent calling Logger.setHandler twice,
  // that's why this logic with isLoggerSet.
  if (!isLoggerSet) {
    isLoggerSet = true;
    Logger.setHandler((log, context) => {
      consoleHandler(log, context);
      if (isLogStreamEnabled) {
        sendLogsOnMessageLimit(log);
      } else {
        // eslint-disable-next-line no-console
        console.debug(`${LOG_PREFIX} Log stream to grape not enabled. Batch of logs not sent.`);
      }
    });
  }
};

const logService = {
  error: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'error');
  },
  warn: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'warn');
  },
  info: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'info');
  },
  debug: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'debug');
  },
  sendLogsToGraylog,
  sendLogsAndClearLogsQueue,
  setLoggerLevel,
  sendLog,
  initLogger,
  isDebugMode,
  setLogLevel,
  detectLogLevel,
  setAdditionalLogData,
};

export default logService;
