import moment from 'moment';
import i18n from '@/plugins/i18n';
import { ReportIdentifiers } from '@/common/enums';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { printService } from '@/modules/print';
import type { TboShiftDetails, TboShiftReportData } from './types';

const { t } = i18n.global;

const parseData = (shift: TboShiftDetails, endDate: string, createdAt: string)
: TboShiftReportData => {
  const report: TboShiftReportData = {
    moneyOld: {
      name: t('reports.moneyOld'),
      value: shift.startingAmount,
      title: true,
    },
    adjustments: {
      name: t('main.transfers'),
      title: true,
    },
    totalAdjustments: {
      name: `${t('reports.total')} ${(t('main.transfers').toLowerCase())}`,
      value: shift.totals.transfers,
      title: false,
    },
    earnings: {
      name: t('product_cash_flow'),
      title: true,
    },
    moneyIn: {
      name: t('main.payin'),
      value: shift.productCashFlow.payment,
      title: false,
    },
    moneyOut: {
      name: t('main.payout'),
      value: shift.productCashFlow.payout,
      title: false,
    },
    totalEarnings: {
      name: `${t('reports.total')} ${t('product_cash_flow').toLowerCase()}`,
      value: shift.totals.productCashFlow,
      title: false,
    },
    total: {
      name: t('main.balance'),
      value: shift.totals.balance,
      title: true,
    },
    transfers: shift.transfers,
    startDate: shift.startedAt,
    startDateTimestamp: moment(shift.startedAt).valueOf(),
    endDate: endDate || shift.concludedAt,
    endDateTimestamp: moment(endDate || shift.concludedAt).valueOf(),
    createdAt: {
      name: t('reports.createdAt'),
      value: moment(createdAt).format('DD.MM.YYYY hh:mm:ss'),
    },
  };

  // in case we are printing already ended shift
  // don't print creation of report. Instead there will be
  // end shift date.
  if (report.endDate) {
    delete report.createdAt;
  }

  return report;
};

const printShiftReport = (data: {
  shift: TboShiftDetails,
  concludedAt: string,
  createdAt: string,
  requestUuid: string
}): Promise<void> => {
  const reportData = parseData(data.shift, data.concludedAt, data.createdAt);

  return printService.getPrinterStatus().then(() => {
    printService.printJob(
      { type: 'report' },
      reportData,
      {
        clientPrintJobIdentifier: {
          id: ReportIdentifiers.SHIFT_REPORT,
          uuid: data.requestUuid,
        },
      },
    ).then(() => Promise.resolve()).catch((printErr: any) => {
      const notificationsStore = useNotificationsStore();
      notificationsStore.show({
        message: printErr.message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
      return Promise.reject();
    });
  }).catch((printerStatusErr: any) => {
    const notificationsStore = useNotificationsStore();
    notificationsStore.show({
      message: printerStatusErr.message,
      type: TNotificationTypeEnum.error,
      delay: 3000,
      id: 'printer_error',
    });
    return Promise.reject();
  });
};

export {
  printShiftReport,
};
