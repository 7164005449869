import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { SCTabs, SCTab, SCHorizontalScroll } from '@nsftx/seven-components';

import router from '@/router';
import i18n from '@/plugins/i18n';
import { useClientGamesStore } from '@/modules/client-games';
import { CmsMenuItem, useGravityMenusStore } from '@/modules/cms/menus';
import { getService } from '@/common/services/ngBridge';
import { CmsMenuResultItem } from '@/modules/results';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default /*@__PURE__*/_defineComponent({
  __name: 'ResultsNavigation',
  setup(__props) {

const $state: any = getService('$state');

const clientGamesStore = useClientGamesStore();
const gravityMenusStore = useGravityMenusStore();

const { t } = i18n.global;

const menuItems = gravityMenusStore.getMenu('results') as CmsMenuResultItem[];

// Make sure that ticket check item is a first menu item
const navigationItems: CmsMenuItem[] = [
  {
    title: t('ticket.check_ticket'),
    description: '',
    disabled: false,
    icon: '',
    menuName: '',
    priority: 0,
    subtitle: '',
    url: '',
    customData: {
      route: 'app.results.ticketcheck',
    },
  },
];

menuItems.forEach((menuItem: CmsMenuResultItem) => {
  const isGameActive = clientGamesStore.games.find(
    (game) => game.id === menuItem.customData.gameId,
  );

  const newMenuItem = {
    ...menuItem,
    title: t(`games.${menuItem.customData.gameId?.toLowerCase()}`),
    customData: {
      ...menuItem.customData,
      route:
      menuItem.customData.integration
        ? 'app.results.integration'
        : `app.results.${menuItem.customData.gameId?.toLowerCase()}`,
    },
  };

  if (isGameActive) {
    navigationItems.push(newMenuItem);
  }
});

const selectedTabIndex = ref(0);

const onTabSelectionChanged = (selectedMenuIndex: number) => {
  const selectedMenu = navigationItems[selectedMenuIndex] as CmsMenuResultItem;
  selectedTabIndex.value = selectedMenuIndex;

  $state.go(selectedMenu.customData.route, {
    gameId: selectedMenu.customData.gameId,
    id: selectedMenu.customData.gameId?.toLowerCase(),
    integration: selectedMenu.customData.integration,
  });
};

router.beforeEach((route) => {
  const index = navigationItems.findIndex((item: CmsMenuItem) => {
    const productId: string | undefined = item.customData?.gameId?.toLowerCase() || undefined;
    const regExp = new RegExp(`\\b${productId}\\b`);

    return !!regExp.test(route.path.toLowerCase());
  });

  selectedTabIndex.value = index !== -1 ? index : 0;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SCHorizontalScroll), { variant: "secondary" }, {
    default: _withCtx(() => [
      _createVNode(_unref(SCTabs), { "model-value": selectedTabIndex.value }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navigationItems, (item, index) => {
            return _createVNode(_unref(SCTab), {
              key: index,
              onClick: ($event: any) => (onTabSelectionChanged(index))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
          }), 64 /* STABLE_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})