<script setup lang="ts">
import { SCIcon, SCButton, ButtonVariant } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { safeAppReload } from '../services/appHelpersService';

const { t } = i18n.global;
const LOG_PREFIX = '[AppBootError]';

document.documentElement.dataset.scChannel = 'terminal';
document.documentElement.dataset.scTheme = 'dark';
document.documentElement.dataset.scPalette = 'default';

const reloadApp = () => {
  logService.info(`${LOG_PREFIX} Reload on app boot error.`, {
    code: 'T_APP_BOOT_ERR_RELOAD',
  });
  safeAppReload();
};
</script>

<template>
  <div class="app-boot-error sc-bg-500 sc-p-3">
    <div class="app-boot-error__container w-100 h-100 sc-bg-300">
      <div class="app-boot-error__container__content">
        <SCIcon
          icon="n-i-disconnected"
          size="6xl"
        />
        <p class="app-boot-error__container__content__text">
          {{ t('app_boot_error_screen_info_message') }}
        </p>
        <SCButton
          :variant="ButtonVariant.Primary"
          @click="reloadApp"
        >
          {{ t('app_boot_error_screen_action_message') }}
        </SCButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .app-boot-error {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--sc-zindex-above);

    &__container {
      &__content {
        padding-top: 33vh;
        max-width: 430px;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__text {
          margin: 28px 0 70px;
          text-align: center;
        }
      }
    }
  }
</style>
