import {
  TicketInstance,
  betslipApiHelpers,
} from '@/modules/seven-betslip-api';
import { SubscribeCallback } from '@nsftx/seven-gravity-gateway/master';
import { cancelService, ticketValidationService } from '@/modules/tickets';
import { errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import { useTicketCheckStore } from '@/modules/ticket-check';
import { IntegratorTicketResponse, type GravityAsyncEventReject, type GravityAsyncEventResolve } from '../types';

const LOG_PREFIX = '[gravityGatewayCancelService]';

const decorateResponseForGame = (
  localTicket: TicketInstance,
  ticketResponse: IntegratorTicketResponse,
) => {
  const ticketPin = localTicket.getPin();
  const res = ticketResponse;
  if (betslipApiHelpers.isV2inCms(res)) {
    res.slip.ticketPin = ticketPin;
  } else {
    res.ticketPin = ticketPin;
  }
  return res;
};

const handleGatewayCancelEvent: SubscribeCallback<
{ ticket: IntegratorTicketResponse },
GravityAsyncEventResolve<{ ticket: IntegratorTicketResponse }>,
GravityAsyncEventReject
> = (
  data,
) => {
  const betslipTicket = betslipApiHelpers.createTicket(data.data.ticket);
  logService.debug(`${LOG_PREFIX} Tickets.Cancel event received`, {
    data: data.slaveId,
    product_displayid: betslipTicket.getProductDisplayId(),
    ticket_code: betslipTicket.getDisplayId(),
    request_id: betslipTicket.getRequestUuid(),
    code: 'T_INTEGRATOR_CANCEL_EVENT',
  });

  setTimeout(() => {
    // We may have scenario when user scans ticket which has PIN
    // bundled with code, ex. "X621G6XH6.2667".
    // So we first need to get this pin "2667" from store (which is set in the moment when
    // ticket is scanned), and then attach it to received ticket.
    const ticketCheckStore = useTicketCheckStore();
    const pin = ticketCheckStore.getTicketPin();
    ticketCheckStore.setResult(betslipTicket);

    if (pin) {
      betslipTicket.setPin(pin);
    }

    cancelService.cancelTicket(betslipTicket).then((res) => {
      const { localTicket } = res;
      data.resolve?.({
        ticket: decorateResponseForGame(localTicket, data.data.ticket),
      });

      logService.info(`${LOG_PREFIX} Cancel resolved to slave`, {
        code: 'T_INTEGRATOR_CANCEL_SUCCESS',
        product_displayid: betslipTicket.getProductDisplayId(),
        ticket_code: betslipTicket.getDisplayId(),
        request_id: betslipTicket.getRequestUuid(),
      });
    }).catch((error) => {
      data.reject?.(error);
      logService.error(`${LOG_PREFIX} Cancel rejected to slave`, {
        error,
        code: 'T_INTEGRATOR_CANCEL_REJECTED',
        upstream_code: error.code,
        upstream_message: error.message,
        product_displayid: betslipTicket.getProductDisplayId(),
        ticket_code: betslipTicket.getDisplayId(),
        request_id: betslipTicket.getRequestUuid(),
      });
      ticketCheckStore.clearTicketPin();
    });
  });
};

const handleGatewayValidateCancelEvent: SubscribeCallback<
{ ticket: IntegratorTicketResponse },
GravityAsyncEventResolve<{ ticket: IntegratorTicketResponse }>,
GravityAsyncEventReject
> = (
  data,
) => {
  const ticketInstance = betslipApiHelpers.createTicket(data.data.ticket);

  logService.debug(`${LOG_PREFIX} Tickets.ValidateCancel event received`, {
    data: data.slaveId,
    product_displayid: ticketInstance.getProductDisplayId(),
    ticket_code: ticketInstance.getDisplayId(),
    request_id: ticketInstance.getRequestUuid(),
    code: 'T_INTEGRATOR_VALIDATE_CANCEL_EVENT',
  });

  const ticketCheckStore = useTicketCheckStore();
  const pin = ticketCheckStore.getTicketPin();
  ticketCheckStore.setResult(ticketInstance);

  if (pin) {
    ticketInstance.setPin(pin);
  }

  ticketValidationService.validateCancel(ticketInstance)
    .then((response) => {
      const { ticket } = response;
      data.resolve?.({
        ticket: decorateResponseForGame(ticket, data.data.ticket),
      });
      logService.info(`${LOG_PREFIX} Cancel validate event resolved.`, {
        product_displayid: ticket.getProductDisplayId(),
        ticket_code: ticket.getDisplayId(),
        request_id: ticket.getRequestUuid(),
        code: 'T_INTEGRATOR_VALIDATE_CANCEL_SUCCESS',
      });
    }).catch((error) => {
      data.reject?.(error);
      logService.error(`${LOG_PREFIX} ValidateCancel error detected. ${data.action} event rejected. `, {
        localTicket: ticketInstance,
        product_displayid: ticketInstance.getProductDisplayId(),
        ticket_code: ticketInstance.getDisplayId(),
        request_id: ticketInstance.getRequestUuid(),
        ...errorParser.parseUpstream(error),
        code: 'T_INTEGRATOR_VALIDATE_CANCEL_FAILED',
      });
    });
};

export {
  handleGatewayValidateCancelEvent,
  handleGatewayCancelEvent,
};
