import { ref } from 'vue';
import { defineStore } from 'pinia';
import { type Ticket } from '@/modules/seven-betslip-api';
import { TicketCheckAbortedError } from '@/modules/ticket-check';
import { TransactionType } from './reports/PlayerData';

const useIntelligentGamingStore = defineStore('intelligentGamingStore', () => {
  const isModalShown = ref<boolean>(false);
  const ticket = ref<Ticket>({} as Ticket);
  const transactionType = ref<TransactionType>('Bet');

  const resolveCallback = ref<(value: unknown) => void>(() => {});
  const rejectCallback = ref<(value: { message: string, code: string }) => void>(() => {});

  const isProvinceModalShown = ref<boolean>(false);

  const setResolveCallback = (callback: (value: any) => void) => {
    resolveCallback.value = callback;
  };

  const setRejectCallback = (
    callback: (value: { message: string, code: string } | TicketCheckAbortedError
    ) => void,
  ) => {
    rejectCallback.value = callback;
  };

  const getIsModalShown = () => isModalShown.value;

  const setIsModalShown = (show: boolean) => {
    isModalShown.value = show;
  };

  const setTicket = (data: Ticket) => {
    ticket.value = data;
  };

  const getTicket = () => ticket.value;

  const setTransactionType = (type: TransactionType) => {
    transactionType.value = type;
  };

  const getTransactionType = () => transactionType.value;

  return {
    isModalShown,
    resolveCallback,
    rejectCallback,
    getIsModalShown,
    setIsModalShown,
    setResolveCallback,
    setRejectCallback,
    setTicket,
    getTicket,
    setTransactionType,
    getTransactionType,
    isProvinceModalShown,
  };
});

export {
  useIntelligentGamingStore,
};
