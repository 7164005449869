import { ref } from 'vue';
import resourcesService from '@/modules/cms/resources';
import type { CmsMenuItem } from '@/modules/cms/menus';

const cachedBackgroundImages = ref<string[]>([]);

const loadImg = (src: string) => {
  const img = new Image();
  const jpgImg = `${src}.jpg`;
  const webpImg = `${src}.webp`;

  return new Promise<string>((resolve, reject) => {
    img.src = webpImg;

    img.onload = () => {
      cachedBackgroundImages.value.push(webpImg);
      resolve(webpImg);
    };

    img.onerror = () => {
      img.src = jpgImg;
      img.onload = () => {
        cachedBackgroundImages.value.push(jpgImg);
        resolve(jpgImg);
      };
      img.onerror = null;
      reject();
    };
  });
};

const getHomeProductGhostImage = async (homeImgSrc: string): Promise<string> => {
  let loadedImage;

  const homeProductGhostImage = `${resourcesService.CMS_S3_BASE_URL}/public_general/seven-terminal-`
  + `${homeImgSrc.toLowerCase()}-home-ghost-bg`;

  if (cachedBackgroundImages.value.length) {
    // eslint-disable-next-line max-len
    const cachedImage = cachedBackgroundImages.value.find((image) => image === homeProductGhostImage);
    if (cachedImage) Promise.resolve(cachedImage);
  }

  try {
    loadedImage = await loadImg(homeProductGhostImage);
  } catch {
    loadedImage = '';
  }

  return loadedImage;
};

const getHomeProductImage = async (homeImgSrc: string): Promise<string> => {
  let loadedImage;

  const homeProductImage = `${resourcesService.CMS_S3_BASE_URL}/public_general/seven-terminal-`
  + `${homeImgSrc.toLowerCase()}-home-bg`;

  const companyImage = `${resourcesService.getFullPathToTenantContainer().toLowerCase()}`
  + `/seven_terminal_product_assets/seven-terminal-${homeImgSrc.toLowerCase()}-home-bg`;

  if (cachedBackgroundImages.value.length) {
    const cachedImage = cachedBackgroundImages.value.includes(companyImage)
      ? companyImage : homeProductImage;
    return Promise.resolve(cachedImage);
  }

  try {
    loadedImage = await loadImg(companyImage);
  } catch {
    try {
      loadedImage = await loadImg(homeProductImage);
    } catch {
      loadedImage = '';
    }
  }

  return loadedImage;
};

const getLoadedImage = (imageUrl: string) => cachedBackgroundImages.value.find(
  (image) => image.includes(`${imageUrl.toLocaleLowerCase()}-home-bg`),
);
const getLoadedGhostImage = (imageUrl: string) => cachedBackgroundImages.value.find(
  (image) => image.includes(`${imageUrl.toLocaleLowerCase()}-home-ghost-bg`),
);

const loadProductImages = (menuList: CmsMenuItem[]) => {
  menuList.forEach((product: CmsMenuItem) => {
    getHomeProductImage(product.url);
    getHomeProductGhostImage(product.url);
  });
};

export {
  getHomeProductImage,
  cachedBackgroundImages,
  getHomeProductGhostImage,
  getLoadedImage,
  getLoadedGhostImage,
  loadProductImages,
};
