<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import {
  SCRadioButton, SCButton, ButtonVariant, SCScrollButtons,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useLanguageStore } from '@/common/stores/language';
import TModal from '@/common/components/TModal.vue';
import { vGaTrackEvent } from '@/modules/google-analytics';

const { t } = i18n.global;
const props = defineProps<{
  modelValue: boolean,
}>();
// eslint-disable-next-line @typescript-eslint/object-curly-spacing
const emit = defineEmits<{(e: 'update:modalOpenValue', modelValue: boolean): void
}>();
const { modelValue } = toRefs(props);
const langStore = useLanguageStore();
const isModalOpen = ref<boolean>(false);
const selectedItem = ref<string>(langStore.currentLang?.id || '');
const languagePickerRef = ref<HTMLElement | null>(null);

watch(modelValue, () => {
  isModalOpen.value = modelValue.value;
});

watch(isModalOpen, () => {
  emit(
    'update:modalOpenValue',
    isModalOpen.value,
  );
  // reset selected item when modal re-opened
  if (isModalOpen.value) {
    selectedItem.value = langStore.currentLang?.id || '';
  }
});

const saveLanguage = () => {
  langStore.changeLanguage(selectedItem.value);
  isModalOpen.value = false;
};

const onLangSelected = (value: string) => {
  selectedItem.value = value;
};
</script>

<template>
  <TModal
    v-model="isModalOpen"
    with-close-icon
    modal-id="language-picker"
  >
    <template #title>
      {{ t('main.language') }}
    </template>
    <template #content>
      <div
        ref="languagePickerRef"
        class="w-100 lang-picker d-flex flex-column overflow-auto"
      >
        <SCRadioButton
          v-for="lang in langStore.availableLanguages"
          :key="lang.id"
          :selected-value="selectedItem"
          :value="lang.id"
          :label="lang.name"
          class="sc-mb-3"
          block
          @update:model-value="onLangSelected"
        >
          <i
            class="sc-pr-7 ml-auto"
            :class="`flag flag-${lang.iso3.toUpperCase()}`"
          />
        </SCRadioButton>
      </div>
      <SCScrollButtons :content="languagePickerRef" />
    </template>

    <template #actions>
      <SCButton
        v-ga-track-event="{
          eventName: 'Language_Change',
          eventParams: { action: 'Language Change (on Save)' }
        }"
        :variant="ButtonVariant.Primary"
        size="md"
        width="100%"
        :disabled="!selectedItem"
        @click="saveLanguage"
      >
        {{ t('actions.save') }}
      </SCButton>
    </template>
  </TModal>
</template>
