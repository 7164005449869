(function () {
  angular.module('7Terminal.Betslip')
    .provider('BetslipConfig', BetslipConfigProvider);

  function BetslipConfigProvider() {
    return {
      config: {
        moneyDenominations: [5, 10, 20, 50],
        isAnonymousBettingAllowed: true
      },

      $get: function () {
        return this.config;
      },
      set: function (newSettings) {
        return angular.merge(this.config, newSettings);
      }

    };
  }
})();
