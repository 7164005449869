import { isAxiosError } from 'axios';
import i18n from '@/plugins/i18n';
import BaseError from '@/common/errors/BaseError';
import { isTboActive } from '@/common/services/helpers';
import { logService } from '@/common/services/logger';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { betslipApiHelpers, betslipApi } from '@/modules/seven-betslip-api';
import type TicketCheckStrategyParams from '../types/TicketCheckStrategyParams';

const { t } = i18n.global;
const LOG_PREFIX = '[SevenTicketCheckStrategy]';

const getCheckMethod = () => (isTboActive()
  ? betslipApi.checkTicketByOperator : betslipApi.checkTicketByAnonymousPlayer);

const checkTicketCode = (params: TicketCheckStrategyParams) => {
  const code = params.code.replace(/ */, '').toUpperCase();
  const method = getCheckMethod();

  return method(code).then((response) => Promise.resolve({
    ticketResponse: response.data,
    ticket: betslipApiHelpers.getInstance(response.data),
  })).catch((err) => {
    const message = (isAxiosError(err) ? axiosErrorParser.parseMessage(err) : errorParser.parseMessage(err)) || t('ticket.check_unknown_id');
    const error = new BaseError(
      message,
      isTboActive() ? 'TBO_TICKET_CHECK_HTTP_ERROR' : 'T_TICKET_CHECK_HTTP_ERROR',
      {
        cause: err,
      },
    );
    logService.debug(`${LOG_PREFIX} Check ticket code error detected`, {
      upstream_message: message,
      upstream_code: error.code,
      code: 'T_SEVEN_TICKET_CHECK_STRATEGY_ERROR',
      ticket_code: code,
    });
    return Promise.reject(error);
  });
};

export default {
  checkTicketCode,
};
