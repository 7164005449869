import axios from 'axios';
import BaseError from '@/common/errors/BaseError';
import {
  betslipApi,
  betslipApiHelpers,
  ClientTicketActions,
  PayoutPayload,
  TicketActionPayload,
  TicketMetaData,
  TicketInstance,
  LocalTicketStatus,
  TicketResponse,
  ProductTicketResponse,
  TicketActionBetslipResponse,
} from '@/modules/seven-betslip-api';
import { logService } from '@/common/services/logger';
import { TNotificationTypeEnum } from '@/common/stores/notifications';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { getSevenBetslipErrorMessage } from '@/modules/seven-betslip-api/helpers';
import { useTicketsStore } from './ticketsStore';
import type ActionSuccessResponse from './types/ActionSuccessResponse';
import TicketPayoutError from './errors/TicketPayoutError';
import { handleTicketActionResponse } from './ticketActionsResponseHandler';

const LOG_PREFIX = '[ticketManager]';

const handleChecker = (
  ticket: TicketInstance,
  isInitiatedByOperator = false,
) => {
  const ticketsStore = useTicketsStore();
  /**
     * We don't want to start checker if ticket is already resolved:
     * - http is sent but we didn't receive response (due to slow network)
     * - in meantime we receive socket update and ticket get resolved
     * - http response received and we start checker but ticket is already resolved and printed
     * We need to refetch it from the store, to get its updated values
     */
  if (ticket.localStatus === LocalTicketStatus.PENDING) {
    const checker = ticketsStore.addChecker(ticket, isInitiatedByOperator);
    if (checker) {
      ticketsStore.startChecker(ticket, checker).then((checkerResponse) => {
        handleTicketActionResponse({
          action: ticket.action as ClientTicketActions,
          ticketData: checkerResponse.res.data as TicketResponse | ProductTicketResponse,
        });
      }).catch((err) => {
        logService.warn(`${LOG_PREFIX} Start checker has failed`, {
          code: 'T_TICKET_START_CHECKER_FAILED',
          ...axiosErrorParser.parseUpstream(err),
          request_id: ticket.getRequestUuid(),
          action: ticket.action,
          product_displayid: ticket.getProductDisplayId(),
          ticket_code: ticket.getDisplayId() || '',
        });
      });
    }
  }
};

const getPayoutMethod = (isInitiatedByOperator?: boolean) => {
  if (isInitiatedByOperator) {
    return betslipApi.payoutTicketByOperator;
  }
  return betslipApi.payoutTicket;
};

const payoutTicket = (
  ticket: TicketInstance,
  ticketMeta: TicketMetaData,
  isInitiatedByOperator?: boolean,
): Promise<ActionSuccessResponse> => {
  const reqUuid = ticketMeta.requestUuid;// same as reqUuid from ticket
  const ticketsStore = useTicketsStore();

  const ticketToPayout = ticket;
  ticketToPayout.action = ClientTicketActions.Payout;

  const ticketId = ticketToPayout.getDisplayId();
  const gameId = ticketToPayout.getProductDisplayId();

  const savedTicket = ticketsStore.saveTicket(ticketToPayout);

  const data: PayoutPayload = {
    ticket: {
      id: ticketId,
    },
    additionalInfo: {},
    metadata: ticketMeta,
    ticketPin: ticketToPayout.getPin(),
    loyaltyCard: ticketToPayout.getLoyaltyCard(),
    payoutAmount: ticketToPayout.getPossiblePayoutAmount(),
    tax: ticketToPayout.getTaxData(),
  };
  const payoutMethod = getPayoutMethod(isInitiatedByOperator);

  return payoutMethod(data).then((response) => {
    handleChecker(savedTicket, isInitiatedByOperator);
    logService.info(`${LOG_PREFIX} Ticket payout accepted by Betslip API.`, {
      code: 'T_TICKET_PAYOUT_HTTP_SUCCESS',
      request_id: reqUuid,
      ticket_code: ticketId,
      product_displayid: gameId,
      payload: data,
      localTicket: betslipApiHelpers.createLogDataFromTicket(ticketToPayout),
    });

    return {
      response,
      localTicket: ticketToPayout,
    };
  }).catch((error) => {
    ticketsStore.updateTicketActionAsFailed(ticketToPayout);
    const parsedMessage = getSevenBetslipErrorMessage(error.response?.data).join();
    const code = isInitiatedByOperator ? 'TBO_TICKET_PAYOUT_HTTP_ERR' : 'T_TICKET_PAYOUT_HTTP_ERR';

    logService.error(`${LOG_PREFIX} Payout ticket error ocurred.`, {
      product_displayid: gameId,
      localTicket: betslipApiHelpers.createLogDataFromTicket(ticketToPayout),
      serverResponse: error.response?.data,
      request_id: reqUuid,
      ticket_code: ticketId,
      code,
      upstream_code: axiosErrorParser.parseCode(error) || errorParser.parseCode(error),
      upstream_message: parsedMessage,
    });

    const formattedError = new TicketPayoutError(parsedMessage, code, {
      context: {
        notificationDetails: {
          type: TNotificationTypeEnum.warning,
        },
      },
      cause: error,
    });

    return Promise.reject(formattedError);
  });
};

const cancelTicket = (
  ticket: TicketInstance,
  ticketMeta: TicketMetaData,
): Promise<{
  response: TicketActionBetslipResponse,
  localTicket: TicketInstance,
}> => {
  const ticketsStore = useTicketsStore();
  const reqUuid = ticketMeta.requestUuid;
  const ticketToCancel = ticket;
  const gameId = ticketToCancel.getProductDisplayId();
  const ticketId = ticketToCancel.getDisplayId();
  ticketToCancel.action = ClientTicketActions.Cancel;

  const savedTicket = ticketsStore.saveTicket(ticketToCancel);

  const data: TicketActionPayload = {
    ticket: {
      id: ticketId,
    },
    additionalInfo: {},
    metadata: ticketMeta,
    ticketPin: ticketToCancel.getPin(),
    loyaltyCard: ticketToCancel.getLoyaltyCard(),
  };

  return betslipApi.cancelTicket(data)
    .then((response) => {
      handleChecker(savedTicket, false);

      logService.info(`${LOG_PREFIX} Ticket cancel accepted by Betslip API.`, {
        code: 'T_TICKET_CANCEL_HTTP_SUCCESS',
        request_id: reqUuid,
        ticket_code: ticketId,
        product_displayid: gameId,
        localTicket: betslipApiHelpers.createLogDataFromTicket(ticketToCancel),
      });

      return Promise.resolve({
        response: response.data,
        localTicket: ticketToCancel,
      });
    })
    .catch((error) => {
      ticketsStore.updateTicketActionAsFailed(ticketToCancel);
      const parsedMessage = getSevenBetslipErrorMessage(error.response?.data).join();
      const isAxiosErr = axios.isAxiosError(error);
      const code = isAxiosErr ? 'T_TICKET_CANCEL_REJECTED_FROM_SERVER' : 'T_TICKET_CANCEL_ERR';
      const upstreamCode = isAxiosErr ? axiosErrorParser.parseCode(error)
        : errorParser.parseCode(error);

      logService.error(`${LOG_PREFIX} Ticket cancel error ocurred.`, {
        product_displayid: gameId,
        localTicket: betslipApiHelpers.createLogDataFromTicket(ticketToCancel),
        serverResponse: error.response?.data,
        request_id: reqUuid,
        ticket_code: ticketId,
        code,
        upstream_code: upstreamCode,
        upstream_message: parsedMessage,
      });

      const formattedError = new BaseError(parsedMessage, code, { cause: error });
      return Promise.reject(formattedError);
    });
};

export default {
  payoutTicket,
  cancelTicket,
};
