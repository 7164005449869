import { errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';

const LOG_PREFIX = '[TaxAuthorityService]';
const TAX_AUTHORITES = {
  tax_authority_mne: 'tax-authority-mne',
  tax_authority_rom: 'tax-authority-rom',
};

const init = () => {
  const configSettingsStore = useConfigSettingsStore();
  const taxAuthority = configSettingsStore.data.taxAuthority?.value;
  if (taxAuthority) {
    const authPolicy = TAX_AUTHORITES[taxAuthority];

    if (authPolicy) {
      logService.info(`${LOG_PREFIX} Initating tax auth handler ${authPolicy}`, {
        code: 'T_TAX_AUTH_INITATING',
      });

      import(`@/modules/tax-authority/${authPolicy}`)
        .then((res) => {
          // module must export init method so we can init module
          // if auth is active
          res.init();
          logService.info(`${LOG_PREFIX} Tax auth handler initated ${authPolicy}`, {
            code: 'T_TAX_AUTH_INITATED',
          });
        }).catch((error) => {
          logService.error(
            `${LOG_PREFIX} Failed to initate tax auth handler`,
            {
              ...errorParser.parseUpstream(error),
              authPolicy,
              code: 'T_INITIATE_TAX_AUTH_HANDLER_FAILED',
            },
          );
        });
    } else {
      logService.warn(`${LOG_PREFIX} Tax auth policy not supported`, {
        code: 'T_TAX_AUTH_POLICY_NOT_SUPPORTED',
        taxAuthPolicy: taxAuthority,
      });
    }
  }
};

export default { init };
