import './app';
import './bettingArea/bettingBalls/bettingBallsV5';
import './bettingArea/bettingBalls/bettingBallsV6';
import './bettingArea/firstBallColorPopUp';
import './bettingArea/futureBetsPupUp';
import './bettingArea/luckySixBettingArea';
import './bettingArea/specialBets';
import './luckySixController';
import './luckySixUI';
import './luckySixUiService';
import './results/luckySixResultsController';
import './results/luckySixResultsDrv';

export default 'terminalApp.nabLuckySixUi';
