import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import type { SevenBetshop, SevenDevice } from '@/modules/seven';
import type { AppConfigResponse } from '@/common/services/local-app-config';
import type { NarSwitchAppInstancePayload, NarPublishPayload, NarSaveAppInstancePayload } from './types';

const narAppConfigUrl = 'http://127.0.0.1:8082';
const narDeviceUrl = 'http://127.0.0.1:8081';

const publish = (payload: NarPublishPayload) => axios.post<string>(
  process.env.LOCAL_SCM_PUBLISH_URL,
  payload,
);

const closeAppInstance = (profileUuid: string) => axios.post<any>(`${narAppConfigUrl}/remove_shop_instance`, {
  profileUuid,
});

const getAppInstances = () => axios.get<any>(`${narDeviceUrl}/device_profiles`);

const getAcceptorsInitializationStatus = () => axios.get<{
  initialized: boolean
}>(`${narDeviceUrl}/peripherals_initialization_status`);

const getMasterInstanceData = () => axios.get<{
  betshop: SevenBetshop,
  device: SevenDevice
}>(narDeviceUrl);

const newAppInstance = () => {
  const profileUuid = uuidv4();
  return axios.post<any>(`${narAppConfigUrl}/new_shop_instance`, {
    profileUuid,
    flags: `--installationMode=true --profileUuid=${profileUuid}`,
  });
};

const dispatchInfoPointActivation = () => axios.post(`${narAppConfigUrl}/switch_next_window`, {
  windowTitle: '7Terminal0',
});

const saveAppInstance = (data: NarSaveAppInstancePayload) => axios.post<any>(`${narDeviceUrl}/add_device_profile`, data);

const switchAppInstance = (data: NarSwitchAppInstancePayload) => axios.post(`${narAppConfigUrl}/expose_app_window`, data);

const removeAppInstance = (profileUuid: string) => {
  localStorage.removeItem('device.data');
  return axios.post<any>(`${narDeviceUrl}/remove_device_profile`, {
    profileUuid,
  });
};

const getAppConfig = (params: { windowTitle: string }) => axios
  .post<AppConfigResponse>(`${narAppConfigUrl}/get_app_config`, params);

const calibrateDisplay = () => axios.post(`${narAppConfigUrl}/touchscreen`, {
  action: 'opensource',
});

const turnOffOrRestartDevice = (action: string) => axios.post(`${narDeviceUrl}/device_power`, { action });

const activateDialog = (dialogName: string) => axios
  .post(`${narAppConfigUrl}/show_modal_dialog`, { dialog_name: dialogName });

const openDevTools = () => axios({
  url: `${narAppConfigUrl}/web_browser`,
  method: 'POST',
  data: {
    action: 'show',
    urlParams: 'http://127.0.0.1:9222',
  },
});

const pingNarToKeepAlive = (windowTitle: string) => axios.post(
  `${narAppConfigUrl}/publish`,
  {
    type: 'appAlive',
    data: {
      windowTitle,
    },
  },
  {
    timeout: 15000,
  },
);

export {
  publish,
  closeAppInstance,
  getAppInstances,
  getAcceptorsInitializationStatus,
  getAppConfig,
  removeAppInstance,
  switchAppInstance,
  saveAppInstance,
  getMasterInstanceData,
  newAppInstance,
  calibrateDisplay,
  turnOffOrRestartDevice,
  activateDialog,
  openDevTools,
  pingNarToKeepAlive,
  dispatchInfoPointActivation,
};
