import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "maintenance-modal"
}
const _hoisted_2 = { class: "maintenance-modal__content w-100" }
const _hoisted_3 = { class: "d-block sc-py-4 sc-pr-6" }
const _hoisted_4 = {
  key: 0,
  class: "maintenance-modal__content__products"
}
const _hoisted_5 = { class: "sc-mt-7" }
const _hoisted_6 = { class: "maintenance-modal__content__products__items" }

import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import {
  SCInlineNotification, SCButton, ButtonVariant, NotificationVariant,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { getService } from '@/common/services/ngBridge';
import TModal from '@/common/components/TModal.vue';
import * as maintenanceService from './services/maintenanceService';
import { useMaintenanceStore } from './maintenanceStore';

// Constants
const GAME_PATH = '/games';

export default /*@__PURE__*/_defineComponent({
  __name: 'MaintenanceModal',
  setup(__props) {

const { t } = i18n.global;
const maintenanceStore = useMaintenanceStore();
const { isGameInMaintenanceState } = maintenanceStore;
const {
  isMaintenanceActive,
  maintenanceData,
} = storeToRefs(maintenanceStore);
const route = useRoute();

const isGameDisabled = ref(false);
const showAlert = computed(() => !!maintenanceData?.value?.scheduleMessage?.body);
const showModal = computed(() => isMaintenanceActive.value && isGameDisabled.value);

const handleModalShowOnRouteChange = () => {
  watch([() => route.fullPath, maintenanceData], ([fullPath]) => {
    if (!isMaintenanceActive.value || !fullPath.startsWith(GAME_PATH)) {
      isGameDisabled.value = false;
      return;
    }

    const { gameId } = route.query;
    isGameDisabled.value = isGameInMaintenanceState(gameId?.toString() || '');
  }, { immediate: true });
};

const navigateToHome = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $state: any = getService('$state');
  $state.go('app.home');
};

handleModalShowOnRouteChange();

return (_ctx: any,_cache: any) => {
  return (showModal.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(TModal, {
          modelValue: showModal.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
          "modal-id": maintenanceService.MAINTENANCE_MODAL_ID,
          "prevent-close": "",
          width: "720px"
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('main.maintenance')), 1 /* TEXT */)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(SCInlineNotification), {
                modelValue: showAlert.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showAlert).value = $event)),
                variant: _unref(NotificationVariant).Warning
              }, {
                text: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(maintenanceData)?.scheduleMessage?.body), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "variant"]),
              (_unref(maintenanceData)?.disabledProducts?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)('main.affected_products')) + ": ", 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(maintenanceData).disabledProducts, (product, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "maintenance-modal__content__products__items__item sc-btn w-100"
                        }, _toDisplayString(product), 1 /* TEXT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          actions: _withCtx(() => [
            _createVNode(_unref(SCButton), {
              variant: _unref(ButtonVariant).Primary,
              width: "100%",
              onClick: navigateToHome
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('button_back_to_home')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["variant"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "modal-id"])
      ]))
    : _createCommentVNode("v-if", true)
}
}

})