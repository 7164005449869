import type { ParserError, UpstreamParserError } from './types';

const parseMessage = (error: any): string => error?.data?.message || error?.response?.message
|| error?.serverResponse?.message
|| error?.message || error?.msg || error?.statusText || '';

const parseCode = (error: any): string => error?.data?.code || error?.response?.status
|| error?.data?.clientCode || error?.clientCode
|| error?.status || error?.code || '';

const parse = (error: any): ParserError => ({
  message: parseMessage(error),
  code: parseCode(error),
});

const parseUpstream = (error: any): UpstreamParserError => ({
  upstream_message: parseMessage(error),
  upstream_code: parseCode(error),
});

const errorParser = {
  parse,
  parseUpstream,
  parseMessage,
  parseCode,
};

export default errorParser;
