angular.module('terminalApp').controller('RacerGameResultsController', function (
  $scope,
  $filter,
  $stateParams,
  loaderService,
  SevenGamesSvc
) {
  this.$onInit = function () {
    var start = $stateParams.start_date && $stateParams.start_date !== 'false' ? new Date($stateParams.start_date) : new Date().setHours(0, 0, 0, 0);
    var end = $stateParams.end_date && $stateParams.end_date !== 'false' ? new Date($stateParams.end_date) : new Date().setHours(23, 59, 59, 999);

    var params = {
      timeFrom: $filter('date')(start, 'yyyy-MM-dd HH:mm:ss'),
      timeTo: $filter('date')(end, 'yyyy-MM-dd HH:mm:ss'),
      count: $stateParams.count || '10'
    };

    var games = {
      greyhoundraces: 'GreyhoundRaces',
      gr: 'GreyhoundRaces',
      virtualgreyhoundraces: 'VirtualGreyhoundRaces',
      vgr: 'VirtualGreyhoundRaces',
      virtualhorseraces: 'VirtualHorseRaces',
      vhr: 'VirtualHorseRaces',
      virtualmotorcyclespeedway: 'VirtualMotorcycleSpeedway',
      vms: 'VirtualMotorcycleSpeedway',
      slotcarraces: 'SlotCarRaces',
      scr: 'SlotCarRaces'
    };

    if ($stateParams.id && games.hasOwnProperty($stateParams.id)) {
      var game = games[$stateParams.id];
      $scope.game = $stateParams.id;
    } else if ($scope.game && games.hasOwnProperty($scope.game.toLowerCase())) {
      var game = games[$scope.game.toLowerCase()];
      $scope.game = $scope.game.toLowerCase();
    } else {
      return;
    }

    loaderService.showLoader();

    var gameService = SevenGamesSvc.getGame(game);

    gameService._resultsSvc.getResults(gameService, params)
      .then(function () {
        loaderService.endLoader();

        gameService._resultsSvc.results.game = gameService.id;

        if (gameService._resultsSvc.results.previousRounds && gameService._resultsSvc.results.previousRounds.length > 0) {
          $scope.noResults = false;
          $scope.results = gameService._resultsSvc.results.previousRounds;
          $scope.activeGame = gameService.id;
        } else {
          $scope.noResults = true;
        }
      });
  };
});
