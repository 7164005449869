(function () {
  angular.module('7Terminal.Core')
    .service('MetadataService', function MetadataService() {
      var Meta = null;

      return {

        set: function (value) {
          Meta = value;
        },

        get: function () {
          return new Meta();
        }
      };
    });
})();
