/* eslint-disable no-param-reassign */
import HooksManager from '@/common/services/HooksManager';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { getService } from '@/common/services/ngBridge';
import { axiosErrorParser } from '@/common/services/error-parser';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { type PrintData, ClientIdentifierTiketActions } from '@/modules/print';
import { cancelService } from '@/modules/tickets';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';
import { getSequentialId } from './apiService';

const { t } = i18n.global;

const isPayin = (printData: PrintData) => printData
  .additionalData?.clientPrintJobIdentifier?.id === ClientIdentifierTiketActions.add;

const cancelTicket = (printData: PrintData) => {
  const ticketInstance = betslipApiHelpers.createTicket(printData.data);
  cancelService.cancelTicket(ticketInstance).catch((err: any) => {
    logService.warn('[sidHandler] Ticket Cancel has failed', {
      code: 'T_IG_TICKET_CANCEL_ERR',
      upstream_code: err.code,
      upstream_message: err.message,
      details: err,
      ...printData,
    });
  }).finally(() => {
    const notificationsStore = useNotificationsStore();
    notificationsStore.closeNotificationWithId('ticket.pending_action');
  });
};

const addBeforePrintHookListener = () => {
  HooksManager.getHook('BeforePrint').tapPromise({
    name: 'BeforePrint.IntelligentGaming.SID',
    fn(params: PrintData) {
      if (!params.additionalData?.clientPrintJobIdentifier
        || !params.additionalData?.clientPrintJobIdentifier?.id
      ) {
        logService.warn('[sidHandler] ClientJobIdentifier info is missing', {
          ...params,
          code: 'T_IG_SID_CLIENT_JOB_IDENTIFIER_MISSING',
        });
      }
      if ((isPayin(params) || params.data?.isCopy)) {
        logService.debug('[sidHandler] Getting sequentialId', {
          params,
        });
        // get ticket from memory by reqUuid and action so
        // we can correctly fetch ticket id
        const ticketManager: any = getService('ticketManager');
        const foundTicket = ticketManager.getTicketFromMemory(
          'add',
          'requestUuid',
          params.additionalData?.clientPrintJobIdentifier?.uuid,
        );
        /**
         * When doing print copy from TBO, if app was previously reloaded,
         * we are losing tickets from memory. So in that case we are trying to
         * get id from printData.
         */
        const ticketId = foundTicket?.id || params.data.id;

        return getSequentialId(ticketId).then((response) => {
          logService.debug('[sidHandler] SID response received', {
            data: response.data,
            sequentialId: response.data.sequentialId,
          });

          params.additionalData.sequentialId = response.data.sequentialId;
          return Promise.resolve(params);
        }).catch((error) => {
          logService.warn('[sidHandler] fetching SID failed', {
            ticket_code: ticketId,
            request_id: params.additionalData?.clientPrintJobIdentifier?.uuid,
            code: 'T_IG_SID_GET_FAILED',
            ...axiosErrorParser.parseUpstream(error),
          });

          if (!params.data?.isCopy) {
            cancelTicket(params);
          }

          return Promise.reject(error);
        });
      }

      return Promise.resolve(params);
    },
  });
};

const addNotificationsShowHookListener = () => {
  HooksManager.getHook('BeforeTicketActionNotificationShow').tap({
    name: 'BeforeTicketActionNotificationShow.IntelligentGaming',
    fn: (params: {
      action: string,
      errorResponses: Array<{ id: string, message: string, code: string }>
    }) => {
      const action = params.action.toLowerCase();

      if (action === 'add') {
        params.errorResponses.push({
          id: 'intelligent-gaming',
          message: 'Block showing messages cause Intelligent Gaming module is turned on',
          code: 'T_BEFORE_TICKET_ACTION_NOTIFICATION_INTELLIGENT_GAMING_BLOCKED',
        });
      }
    },
  });
};

const handleHooks = () => {
  addBeforePrintHookListener();
  addNotificationsShowHookListener();
};

const handlePrintListeners = () => {
  PubSub.subscribe('7T:PrintService.Error', (event, data: {
    printData: PrintData,
    reason: string
  }) => {
    if (isPayin(data.printData)) {
      cancelTicket(data.printData);
    }
  });

  PubSub.subscribe('7T:PrintService.PrintSuccess', (event, data: PrintData) => {
    const notificationsStore = useNotificationsStore();

    if (isPayin(data)) {
      notificationsStore.show({
        id: 'ig_successful_payin',
        message: t('ig_successful_payin'),
        type: TNotificationTypeEnum.success,
        delay: 3000,
      });
    }
  });
};

const init = () => {
  handleHooks();
  handlePrintListeners();
};

export default {
  init,
};
