import axios from 'axios';
import * as httpInterceptor from '@/modules/interceptors/httpInterceptor';

const axiosInstance = axios.create({
  baseURL: `${process.env.GRAVITY_API_URL}/v1/service-notifications`,
});

axiosInstance.interceptors.request
  .use(httpInterceptor.requestSuccess, httpInterceptor.requestError);

axiosInstance.interceptors.response
  .use(httpInterceptor.responseSuccess, httpInterceptor.responseError);

export default axiosInstance;
