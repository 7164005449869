import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  ClientTicketActions,
  createBetslipTicketChecker,
  CheckerStartReturn,
  TicketInstance,
  LocalTicketStatus,
} from '@/modules/seven-betslip-api';
import { logService } from '@/common/services/logger';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';

const LOG_PREFIX = '[ticketsStore]';

export const useTicketsStore = defineStore('ticketsStore', () => {
  const tickets = ref<Array<TicketInstance>>([]);

  const pendingActions = ref<Record<string, boolean>>({});

  const checkers = ref<Record<string, CheckerStartReturn>>({});

  /**
   * In form of `productDisplayId.action` (action is lowercase)
   * ex. LuckySix.payout
   */
  const addPendingAction = (namespace: string) => {
    pendingActions.value[namespace] = true;
  };

  const removePendingAction = (namespace: string) => {
    if (pendingActions.value[namespace]) {
      delete pendingActions.value[namespace];
    }
  };

  /**
   * Checks whether there is pending action for a game.
   * For ex. is there pending Payout action for LuckySix game.
   * @param {string} namespace
   * @returns {boolean}
   */
  const isActionPending = (namespace: string) => !!pendingActions.value[namespace];

  const getIndexOfTicket = (
    requestUuid: string,
    displayId: string,
    action?: ClientTicketActions,
  ) => {
    let index;
    if (action === ClientTicketActions.Add) {
      index = tickets.value.findIndex((el: TicketInstance) => el.action === action
      && el.getRequestUuid() === requestUuid);
    } else {
      index = tickets.value.findIndex((el: TicketInstance) => el.action === action
      && el.getDisplayId() === displayId);
    }

    return index;
  };

  const getTicket = (
    requestUuid: string,
    displayId: string,
    action?: ClientTicketActions,
  ) => {
    const index = getIndexOfTicket(requestUuid, displayId, action);
    if (index === -1) {
      return undefined;
    }
    return tickets.value[index];
  };

  const updateTicket = (updatedTicket: TicketInstance) : TicketInstance | undefined => {
    const storedTicket = getTicket(
      updatedTicket.getRequestUuid(),
      updatedTicket.getDisplayId(),
      updatedTicket.action,
    );

    if (!storedTicket) {
      logService.warn(`${LOG_PREFIX} Could not find ticket in ticketStore`, {
        action: updatedTicket.action,
        request_id: updatedTicket.getRequestUuid(),
        ticket_code: updatedTicket.getDisplayId() || '',
        code: 'T_TICKET_STORE_UPDATE_FAILED',
      });
      return undefined;
    }

    storedTicket.ticket = updatedTicket.ticket; // always update backend response
    storedTicket.localStatus = updatedTicket.localStatus;

    return storedTicket;
  };

  const saveTicket = (ticket: TicketInstance) => {
    if (
      ticket.action
      && [ClientTicketActions.Add, ClientTicketActions.Payout].indexOf(ticket.action) >= 0
    ) {
      addPendingAction(`${ticket.getProductDisplayId()}.${ticket.action.toLowerCase()}`);
    }

    ticket.setAsPending();

    // Check if tickets is already in ticket list
    const existingTicket = getTicket(
      ticket.getRequestUuid(),
      ticket.getDisplayId(),
      ticket.action,
    );

    if (!existingTicket) {
      tickets.value.unshift(ticket);

      logService.debug(
        `${LOG_PREFIX} Saving ticket with action ${ticket.action}
          from ${ticket.getProductDisplayId()}`,
        {
          ticket_code: ticket.getDisplayId(),
          request_id: ticket.getRequestUuid(),
          product_displayid: ticket.getProductDisplayId(),
          ticket,
        },
      );
    }

    return ticket;
  };

  const getCheckerDetails = (ticket: TicketInstance) => {
    const gravitySettingsStore = useGravitySettingsStore();
    const cmsData = gravitySettingsStore.getByKey(`module.${ticket.getProductDisplayId()}.ticketCheck`);
    // if undefined (not set in CMS), default value is true
    // only if explicitly set to false, we consider it as false
    const doTicketStatusCheck = cmsData.doTicketStatusCheck !== false;
    return {
      doTicketStatusCheck,
      interval: cmsData.pendingTicketCheckInterval || 10000,
      pendingTicketRejectPeriod: cmsData.pendingTicketRejectPeriod || 30000,
    };
  };

  const addChecker = (ticket: TicketInstance, isOperator?: boolean) => {
    const reqUuid = ticket.getRequestUuid();
    const { action } = ticket;
    const details = getCheckerDetails(ticket);

    if (!details.doTicketStatusCheck) {
      logService.info(`${LOG_PREFIX} Cannot add checker. doTicketStatusCheck disabled for game.`, {
        request_id: reqUuid,
        product_displayid: ticket.getProductDisplayId(),
        ticket_code: ticket.getDisplayId(),
        action,
        code: 'T_TICKET_ADD_CHECKER_DISABLED',
      });
      return undefined;
    }

    if (!action) {
      logService.warn(`${LOG_PREFIX} Could not add checker. Ticket action not defined`, {
        code: 'T_TICKET_ADD_CHECKER_ACTION_UNDEFINED',
        product_displayid: ticket.getProductDisplayId(),
        ticket_code: ticket.getDisplayId(),
        request_id: ticket.getRequestUuid(),
      });
      throw Error('Ticket action not defined. Cannot add checker.', {
        cause: {
          clientCode: 'T_TICKET_ADD_CHECKER_ACTION_UNDEFINED',
        },
      });
    }

    checkers.value[`${reqUuid}-${action}`] = createBetslipTicketChecker(
      {
        requestUuid: reqUuid,
        productDisplayId: ticket.getProductDisplayId(),
        displayId: ticket.getDisplayId(),
      },
      action,
      {
        interval: details.interval,
        isOperator: isOperator || false,
        pendingTicketRejectPeriod: details.pendingTicketRejectPeriod,
      },
    );

    return checkers.value[`${reqUuid}-${action}`];
  };

  const endChecker = (ticket: TicketInstance) => {
    const reqUuid = ticket.getRequestUuid();
    const { action } = ticket;
    const checker = checkers.value[`${reqUuid}-${action}`];

    if (checker) {
      checker.endChecker();
    } else {
      logService.warn(`${LOG_PREFIX} endChecker - Checker not found`, {
        request_id: reqUuid,
        action,
        product_displayid: ticket.getProductDisplayId(),
        ticket_code: ticket.getDisplayId(),
        code: 'T_TICKET_END_CHECKER_CHECKER_NOT_FOUND',
      });
    }

    delete checkers.value[`${reqUuid}-${action}`];
  };

  const updateTicketActionAsFailed = (ticket: TicketInstance): TicketInstance => {
    const updatedTicket = ticket;
    updatedTicket.localStatus = LocalTicketStatus.FAILED;
    removePendingAction(
      `${updatedTicket.getProductDisplayId()}.${updatedTicket.action?.toLowerCase()}`,
    );
    updateTicket(updatedTicket);
    endChecker(updatedTicket);

    return updatedTicket;
  };

  const startChecker = (ticket: TicketInstance, checker: CheckerStartReturn) => checker
    .startChecker().catch((err) => {
      updateTicketActionAsFailed(ticket);
      throw err;
    });

  return {
    tickets,
    pendingActions,
    saveTicket,
    updateTicket,
    getTicket,
    addPendingAction,
    removePendingAction,
    isActionPending,
    addChecker,
    startChecker,
    endChecker,
    updateTicketActionAsFailed,
    checkers,
  };
});
