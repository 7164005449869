import angular from 'angular';
import '@nsftx/seven-slot-car-races/dist/slotcarraces';
import commonModule from '../../common';
import coreModule from '../../core';
import sevenGamesModule from '../index';
import runBlock from './run';
import configBlock from './config';
import scrForecastBettingAreaComponent from './forecastBettingArea';
import scrRacecardBettingAreaComponent from './racecardBettingArea';
import scrUiComponent from './scrUI';
import ScrUiService from './scrUiService';
import scrTricastBettingAreaComponent from './tricastBettingArea';

const moduleName = angular.module('7Terminal.SlotCarRaces', [
  'sevenSCR',
  coreModule,
  commonModule,
  sevenGamesModule
])
  .component('scrForecastBettingArea', scrForecastBettingAreaComponent)
  .component('scrRacecardBettingArea', scrRacecardBettingAreaComponent)
  .component('scrUi', scrUiComponent)
  .component('scrTricastBettingArea', scrTricastBettingAreaComponent)
  .config(configBlock)
  .run(runBlock)
  .service('ScrUiService', ScrUiService)
  .name;

export default moduleName;
