import { range, each } from 'underscore';
import templateUrl from './tricastBettingArea.html';

(function () {
  angular.module('terminalApp.nabHorseRacesUi')
    .component('horseTricastBettingArea', {
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        $state,
        nabMessaging,
        HorseRacesUiService
      ) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); var // switch virtual and regular horse races
          HorseRaces = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = HorseRaces.getBettingSvc();
        var self = this;

        this.$onInit = function () {
          this.racers = range(1, HorseRaces.numberOfRacers + 1);
          this.activeGame = HorseRaces;
          this.horseRacesUISvc = HorseRacesUiService;
          this.createRacers();
          this.horseRacesUISvc.disableReverseTricast = false;
          this.horseRacesUISvc.disableTricast = false;
        };

        // listen for config changes
        nabMessaging.subscribe('config:gameConfigChanged', function (event, game) {
          if (game == 'HorseRaces' || game == 'VirtualHorseRaces') {
            var active_game = SevenGamesSvc.getGame(game);
            if (self.racers.length != active_game.numberOfRacers) {
              self.racers = range(1, active_game.numberOfRacers + 1);
              self.createRacers();
            }
          }
        });

        /** Create objects of racers for tricast and reverse tricast
                 * (set them unchecked)
                 * and  racers array* */

        this.createRacers = function () {
          HorseRacesUiService.clearTricastActiveState();
          each(this.racers, function (position) {
            self.horseRacesUISvc.tricastFirstPlace['r' + (position)] = false;
            self.horseRacesUISvc.tricastSecondPlace['r' + (position)] = false;
            self.horseRacesUISvc.tricastThirdPlace['r' + (position)] = false;
            self.horseRacesUISvc.tricastAny['r' + (position)] = false;
          });
        };

        /** Check first place racer in tricast bet
                 * @param - Number - racer postion* */

        this.checkTricastFirstPlace = function (racer) {
          this.horseRacesUISvc.tricastFirstPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.tricastFirstPlace[racer] = !this.horseRacesUISvc.tricastFirstPlace[racer]; // toggle racer status

          each(this.horseRacesUISvc.tricastFirstPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.tricastFirstPlaces.push(key.substring(1));
            }
          });

          this.validateTricast();
        };

        /** Check second place racer in tricast bet
                 * @param - Number - racer postion* */

        this.checkTricastSecondPlace = function (racer) {
          this.horseRacesUISvc.tricastSecondPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.tricastSecondPlace[racer] = !this.horseRacesUISvc.tricastSecondPlace[racer]; // toggle racer status

          each(this.horseRacesUISvc.tricastSecondPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.tricastSecondPlaces.push(key.substring(1));
            }
          });

          this.validateTricast();
        };

        /** Check third place racer in tricast bet
                 * @param - Number - racer postion* */

        this.checkTricastThirdPlace = function (racer) {
          this.horseRacesUISvc.tricastThirdPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.tricastThirdPlace[racer] = !this.horseRacesUISvc.tricastThirdPlace[racer]; // toggle racer status

          each(this.horseRacesUISvc.tricastThirdPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.tricastThirdPlaces.push(key.substring(1));
            }
          });

          this.validateTricast();
        };

        /**
                 * Check if there are same numbers in combination
                 * hide any bet if anything is checked
                 * */

        this.validateTricast = function () {
          for (var i in this.horseRacesUISvc.tricastFirstPlace) {
            if (((this.horseRacesUISvc.tricastFirstPlace[i] && this.horseRacesUISvc.tricastSecondPlace[i])
                            || (this.horseRacesUISvc.tricastFirstPlace[i] && this.horseRacesUISvc.tricastThirdPlace[i])
                            || (this.horseRacesUISvc.tricastSecondPlace[i] && this.horseRacesUISvc.tricastThirdPlace[i]))
                            && (this.horseRacesUISvc.tricastFirstPlaces.length < 3 || this.horseRacesUISvc.tricastSecondPlaces.length < 3
                            || this.horseRacesUISvc.tricastThirdPlaces.length < 3)) {
              this.horseRacesUISvc.validForecast = false;
              flashButton.flash = false;
              return;
            }
          }

          this.horseRacesUISvc.disableReverseTricast = this.horseRacesUISvc.tricastFirstPlaces.length > 0
                        || this.horseRacesUISvc.tricastSecondPlaces.length > 0 || this.horseRacesUISvc.tricastThirdPlaces.length > 0;
          flashButton.flash = !(this.horseRacesUISvc.tricastFirstPlaces.length < 1 || this.horseRacesUISvc.tricastSecondPlaces.length < 1
                    || this.horseRacesUISvc.tricastThirdPlaces.length < 1);
          this.horseRacesUISvc.validForecast = true;
        };

        this.checkReverseTricastBet = function (racer) {
          this.horseRacesUISvc.reverseTricastPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.tricastAny[racer] = !this.horseRacesUISvc.tricastAny[racer]; // toggle racer status

          each(this.horseRacesUISvc.tricastAny, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.reverseTricastPlaces.push(key.substring(1));
            }
          });

          this.validateReverseTricastBet();
        };

        /**
                 * Disable tricast if any bet is checked
                 * flash add button if there are two racers
                 * */

        this.validateReverseTricastBet = function () {
          this.horseRacesUISvc.disableTricast = this.horseRacesUISvc.reverseTricastPlaces.length > 0;

          if (this.horseRacesUISvc.reverseTricastPlaces.length < 3) {
            flashButton.flash = false;
            this.horseRacesUISvc.validReverseTricast = false;
          } else {
            flashButton.flash = true;
            this.horseRacesUISvc.validReverseTricast = true;
          }
        };
      }

    });
})();
