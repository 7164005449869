import PubSub from 'pubsub-js';
import io from 'socket.io-client';
import { logService } from '@/common/services/logger';
import { useCardReaderStore } from '@/modules/card-reader';
import { connectionStatusService } from '@/modules/connection-status';
import type { TLPSSocketMessage, TAuditSocketData } from './types';
import { TSocketChannelName } from './enums';

let connection: SocketIOClient.Socket;

const setCardReaderListener = () => {
  connection.on(TSocketChannelName.CARD_READER_CHANNEL, (data: TLPSSocketMessage) => {
    const event = data.data;
    const cardReaderStore = useCardReaderStore();

    switch (event.type || data.type) {
      case 'CardIn':
        cardReaderStore.onCardIn(event.message);
        break;
      case 'CardOut':
        cardReaderStore.onCardOut();
        break;
      case 'state':
        // not handled
        break;
      default:
        logService.warn('[lpsSocketManager] Unknown card reader event', {
          data,
          code: 'T_LPS_UNKNOWN_CARD_READER_EVENT',
        });
    }
  });
};

const setConnectionCheckerListener = () => {
  connection.on(
    TSocketChannelName.CONNECTION_CHECKER_CHANNEL,
    (message: TLPSSocketMessage) => {
      if (message.type === 'stateChanged') {
        connectionStatusService.isConnected.value = message.data.ok === 'true';
      }
    },
  );
};

const setPrinterStatusListener = () => {
  connection.on(TSocketChannelName.PRINTER_STATE_CHANNEL, (message: TLPSSocketMessage) => {
    if (message.type === 'statusChange') {
      PubSub.publish('Printer:statusChange', message);
    }
  });
};

const setPocketListener = () => {
  connection.on(TSocketChannelName.MONEY_ACCEPTORS_CHANNEL, (message: TLPSSocketMessage) => {
    switch (message.type) {
      case 'coinAcceptorEvent':
        PubSub.publish('CoinAcceptor:moneyProcessing', message.data);
        break;
      case 'billAcceptorEvent':
        PubSub.publish('BillAcceptor:moneyProcessing', message.data);
        break;
      case 'pocketEvent':
        PubSub.publish('Pocket:moneyUnprocessed', message.data);
        break;
      case 'state':
        // not handled
        break;
      default:
        logService.warn(
          '[lpsSocketManager] Unknown money acceptor event',
          {
            eventMessage: message,
            code: 'T_LPS_UNKNOWN_POCKET_READER_EVENT',
          },
        );
    }
  });
};

const setDeviceEventsListener = () => {
  connection.on(
    TSocketChannelName.DEVICE_EVENTS_CHANNEL,
    (data: { data: TAuditSocketData }) => {
      logService.debug('[lpsSocketManager] message on channel deviceEvents received.', data);
      PubSub.publish('deviceEvents:messageReceived', data);
    },
  );
};

const setListeners = () => {
  setCardReaderListener();
  setConnectionCheckerListener();
  setPrinterStatusListener();
  setPocketListener();
  setDeviceEventsListener();
};

const subscribeToChannels = () => {
  connection.emit('subscribe', TSocketChannelName.CARD_READER_CHANNEL);
  connection.emit('subscribe', TSocketChannelName.PRINTER_STATE_CHANNEL);
  connection.emit('subscribe', TSocketChannelName.MONEY_ACCEPTORS_CHANNEL);
  connection.emit('subscribe', TSocketChannelName.CONNECTION_CHECKER_CHANNEL);
  connection.emit('subscribe', TSocketChannelName.DEVICE_EVENTS_CHANNEL);
};

const onConnectionEstablished = () => {
  logService.debug('[lpsSocketManager] localScm:connectionEstablished');
  subscribeToChannels();
  setListeners();
};

const createConnection = () => {
  connection = io.connect(
    process.env.LOCAL_SCM_URL,
    {
      forceNew: true,
    },
  );

  connection.on('connect', onConnectionEstablished);

  connection.on('disconnect', () => {
    logService.warn('[lpsSocketManager] localScm:disconnect', {
      code: 'T_LPS_DISCONNECT',
    });
  });

  connection.on('reconnecting', () => {
    logService.debug('[lpsSocketManager] localScm:reconnecting', {
      code: 'T_LPS_RECONNECTING',
    });
  });

  connection.on('reconnect', () => {
    logService.info('[lpsSocketManager] localScm:reconnect', {
      code: 'T_LPS_RECONNECT',
    });
  });
};

const getLpsConnection = () => connection;

const init = () => {
  createConnection();
};

export {
  init,
  getLpsConnection,
};
