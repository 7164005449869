angular.module('terminalApp.nabLuckyXUi').controller('LuckyXResultsController', function (
  $scope,
  $filter,
  $stateParams,
  loaderService,
  nabLuckyXResults
) {
  this.$onInit = function () {
    var start = $stateParams.start_date && $stateParams.start_date !== 'false' ? new Date($stateParams.start_date) : new Date().setHours(0, 0, 0, 0);
    var end = $stateParams.end_date && $stateParams.end_date !== 'false' ? new Date($stateParams.end_date) : new Date().setHours(23, 59, 59, 999);

    var params = {
      timeFrom: $filter('date')(start, 'yyyy-MM-dd HH:mm:ss'),
      timeTo: $filter('date')(end, 'yyyy-MM-dd HH:mm:ss'),
      count: $stateParams.count || '10'
    };

    loaderService.showLoader();

    nabLuckyXResults.getResults(params)
      .then(function () {
        var results = nabLuckyXResults.results;

        loaderService.endLoader();

        if (results && results.data && results.data.data && results.data.data.length > 0) {
          $scope.noResults = false;
          $scope.results = checkResultsData(results.data.data);
        } else {
          $scope.noResults = true;
        }
      });

    /**
         * Backend sends sometimes a response where not all balls are drawn
         * Later after that, socket sends results and duplicate results are rendered
         * See Bug - T48750
         */
    function checkResultsData(data) {
      var results = data;

      for (var i = 0; i < results.length; ++i) {
        if (results[i].draw.length < 36) {
          results.splice(i, 1);
          --i;
        }
      }

      return results;
    }
  };
});
