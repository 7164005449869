import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-100" }

import { ref, toRefs, watch } from 'vue';
import {
  SCRadioGroup, SCRadioButton, SCButton, ButtonVariant,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useOddTypeStore } from '@/common/stores/odd-type';
import TModal from '@/common/components/TModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OddTypePicker',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modalOpenValue"],
  setup(__props: any, { emit: __emit }) {

const { t } = i18n.global;
const props = __props;
// eslint-disable-next-line @typescript-eslint/object-curly-spacing
const emit = __emit;
const { modelValue } = toRefs(props);

const oddTypeStore = useOddTypeStore();
const isModalOpen = ref<boolean>(false);
const selectedItem = ref<string>(oddTypeStore.activeOddType.type);

const availableOddList = oddTypeStore.getAvailableOddTypes();

watch(modelValue, () => {
  isModalOpen.value = modelValue.value;

  // reset selected item when modal re-opened
  if (isModalOpen.value) {
    selectedItem.value = oddTypeStore.activeOddType.type;
  }
});

watch(isModalOpen, () => {
  emit(
    'update:modalOpenValue',
    isModalOpen.value,
  );
});

const saveOddType = () => {
  oddTypeStore.setOddType(selectedItem.value);
  isModalOpen.value = false;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: isModalOpen.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isModalOpen).value = $event)),
    "with-close-icon": "",
    "modal-id": "oddsTypePicker"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('main.odds_type')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(SCRadioGroup), {
          modelValue: selectedItem.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedItem).value = $event)),
          block: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availableOddList), (oddType) => {
              return (_openBlock(), _createBlock(_unref(SCRadioButton), {
                key: oddType.id,
                value: oddType.type,
                label: `${_unref(t)(oddType.name)} ${oddType.format}`
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        size: "md",
        width: "100%",
        disabled: !selectedItem.value,
        onClick: saveOddType
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('actions.save')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant", "disabled"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})