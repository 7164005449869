// Module
var code = `<div class="row main__body is--small max-height px-1" ng-class="{'mb-1':\$ctrl.resolutionMode == 'portrait'}"> <div class="vms--racecardForecast"> <div class="row"> <p ng-class="{'col-sm-4':\$ctrl.activeGame.config.bets[2].value && \$ctrl.activeGame.config.bets[0].value}" class="text-warning text-sm-center text-info mb-0"> </p> <p ng-if="\$ctrl.activeGame.config.bets[0].value" ng-class="{'col-sm-10' :\$ctrl.activeGame.config.bets[0].value && \$ctrl.activeGame.config.bets[2].value ,
                       'col-sm-20': !\$ctrl.activeGame.config.bets[0].value || !\$ctrl.activeGame.config.bets[2].value}" class="text-warning text-sm-center racers-bet-header text-info mb-0"> {{ 'virtualgame_bets_0' | translate}} </p> <p ng-if="\$ctrl.activeGame.config.bets[2].value" ng-class="{'col-sm-10' :\$ctrl.activeGame.config.bets[0].value && \$ctrl.activeGame.config.bets[2].value ,
                       'col-sm-20': !\$ctrl.activeGame.config.bets[0].value || !\$ctrl.activeGame.config.bets[2].value}" class="text-warning text-sm-center racers-bet-header text-info mb-0"> {{ 'virtualgame_bets_2' | translate}} </p> <div class="col-sm-4 px-0-5"> <div ng-repeat="num in \$ctrl.racers" class="btn btn-block mb-0-5 mt-0 vms-racer-{{::num}}"> <div> {{::num}} </div> </div> </div> <div ng-if="\$ctrl.activeGame.config.bets[0].value" ng-class="{'col-sm-10' :\$ctrl.activeGame.config.bets[0].value && \$ctrl.activeGame.config.bets[2].value ,
                       'col-sm-20': !\$ctrl.activeGame.config.bets[0].value || !\$ctrl.activeGame.config.bets[2].value}" class="pl-0 pr-0-5"> <div ng-repeat="bet in \$ctrl.bets.winner"> <button animate-ripple class="btn vms-racecard-btn btn-block mb-0-5" ng-class="{'is--active': (\$ctrl.vmsUISvc.winner['r' + bet.racers[0]] && (\$ctrl.activeGame.isScheduleActive ? \$ctrl.vmsUISvc.scheduledEvents[\$ctrl.activeGame._scheduleSvc.activeRound.eventId].winner['r' + bet.racers[0]] : true))}" ng-click="\$ctrl.sendBet(bet)" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive || !bet.value"> {{!bet.value ? '-' : (bet.value | oddsConverter:\$ctrl.activeGame.config.odds.active)}} </button> </div> </div> <div ng-if="\$ctrl.activeGame.config.bets[2].value" ng-class="{'col-sm-10' :\$ctrl.activeGame.config.bets[0].value && \$ctrl.activeGame.config.bets[2].value ,
                       'col-sm-20': !\$ctrl.activeGame.config.bets[0].value || !\$ctrl.activeGame.config.bets[2].value}" class="pl-0 pr-0-5"> <div ng-repeat="bet in \$ctrl.bets.place"> <button animate-ripple class="btn vms-racecard-btn btn-block mb-0-5" ng-class="{'is--active': (\$ctrl.vmsUISvc.place['r' + bet.racers[0]] && (\$ctrl.activeGame.isScheduleActive ? \$ctrl.vmsUISvc.scheduledEvents[\$ctrl.activeGame._scheduleSvc.activeRound.eventId].place['r' + bet.racers[0]] : true))}" ng-click="\$ctrl.sendBet(bet)" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive || !bet.value"> {{!bet.value ? '-' : (bet.value | oddsConverter:\$ctrl.activeGame.config.odds.active)}} </button> </div> </div> </div> </div> </div>`;
// Exports
var _module_exports =code;;
var path = '/games/virtual_motorcycle_speedway/racecardBettingArea.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;