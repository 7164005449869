import { ref } from 'vue';
import { defineStore } from 'pinia';
import type {
  SevenBetshop, SevenDevice, SevenTenant, SevenTerminalDevice,
} from './types';

const useSevenStore = defineStore('seven', () => {
  const tenant = ref<SevenTenant>({
    currency: '',
    datacenter: '',
    fractionDigits: 2,
    locale: '',
    name: '',
    uuid: '',
    id: 0,
  });

  const betshop = ref<SevenBetshop>({
    address: '',
    city: '',
    displayId: '',
    id: '',
    name: '',
    uuid: '',
  });

  const terminal = ref<SevenTerminalDevice>({
    uuid: '',
    deviceUuid: '',
    balance: 0,
    name: '',
    moneyDenominations: [],
    cashRegisterUuid: '',
  });

  const device = ref<SevenDevice>({
    deviceId: 0,
    name: '',
    token: '',
    uuid: '',
  });

  // this function returns the normalized company name without special characters,
  // replace all special, whitespace characters (equivalent to [\r\n\t\f\v ])
  // with emtpy string, also replace character š with s
  // eslint-disable-next-line no-useless-escape
  const getCompanyNameNormalized = () => tenant.value.name.replace(/[`~!@#$%^&*()_\s|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/š/gi, 's');

  return {
    tenant,
    betshop,
    terminal,
    device,
    getCompanyNameNormalized,
  };
});

export {
  useSevenStore,
};
