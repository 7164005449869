import resultsTemplate from '../results/results.html';
import resultsContentTemplateUrl from '../results/racer_games/content.html';

/**
 *
 * @ngInject
 */
export default function ($stateProvider) {
  for (var i = 0; i < window.BootStrapData.clientAppModules.length; i++) {
    var config = window.BootStrapData.clientAppModules[i];
    if (config.id === 'SlotCarRaces') {
      window.SevenSCR.getConfigService()
        .set({
          companyUuid: window.BootStrapData.company.uuid
        });
    }
  }

  $stateProvider.state('app.games.slotcarraces', {
    url: '/slotCarRaces',
    template: '<scr-ui></scr-ui>'
  });

  $stateProvider.state('app.results.slotcarraces', {
    url: '/slotcarraces?id',
    templateUrl: resultsTemplate,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.slotcarraces.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentTemplateUrl,
        controller: 'RacerGameResultsController'
      }
    }
  });
}
