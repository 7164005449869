import { AppMode } from '../enums';
import { useEnvironmentHandlerStore } from '../stores/environment-handler';

const isTboActive = () => {
  const envHandlerStore = useEnvironmentHandlerStore();

  return (AppMode.BACK_OFFICE === envHandlerStore.getState('activeApp'));
};

const copyObject = (source: Object) => (typeof
structuredClone === 'function' ? structuredClone(source)
  : JSON.parse(JSON.stringify(source)));

const extend = (obj1: Object, obj2: Object) => {
  const o = {} as any;
  Object.keys(obj1).forEach((k) => {
    if (Object.prototype.hasOwnProperty.call(obj1, k)) {
      o[k] = (obj1 as any)[k]?.constructor === Object
        ? extend(o[k] || {}, (obj1 as any)[k])
        : (obj1 as any)[k];
    }
  });

  Object.keys(obj2).forEach((k) => {
    if (Object.prototype.hasOwnProperty.call(obj2, k)) {
      o[k] = (obj2 as any)[k]?.constructor === Object
        ? extend(o[k] || {}, (obj2 as any)[k])
        : (obj2 as any)[k];
    }
  });

  return o;
};

const mergeObjects = (object1: Object, object2: Object) => {
  const res = extend(object1, object2);
  return copyObject(res);
};

export {
  isTboActive,
  copyObject,
  mergeObjects,
};
