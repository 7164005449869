import { useOddTypeStore } from '@/common/stores/odd-type';
import { useLanguageStore } from '@/common/stores/language';
import { apiService as lpsSocketApiService } from '@/modules/lps-socket';
import { logService } from './logger';
import { errorParser } from './error-parser';

const setInitialLangOddsState = () => {
  const oddTypeStore = useOddTypeStore();
  const langStore = useLanguageStore();

  lpsSocketApiService.setOddsLanguageState({
    oddsType: oddTypeStore.activeOddType.type,
    language: langStore.currentLang?.id,
  }).catch((err) => {
    logService.error('[commonService] Failed to publish odd and languge state on init to LPS', {
      ...errorParser.parseUpstream(err),
      code: 'T_ERROR_LPS_PUBLISH_STATE',
    });
  });
};

export {
  setInitialLangOddsState,
};
