import PubSub from 'pubsub-js';
import { ScanEvent } from './ScanEvent';

const SCAN_EVENT_ID = Symbol('7T:Scanner.ScanningFinished');
const scanEventPubSub: PubSubJS.Base<ScanEvent, symbol> = PubSub;

export {
  SCAN_EVENT_ID,
  scanEventPubSub,
};
