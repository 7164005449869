import { useSevenStore } from '@/modules/seven';
import { useCmsAppsStore } from '../apps/appsStore';

const CMS_S3_BASE_URL = process.env.GRAVITY_API_S3_URL;

/**
 * Returns full path, including base url, to tenant container on AWS S3
 * @returns {string}
 */
const getFullPathToTenantContainer = (): string => {
  const useCmsStore = useCmsAppsStore();
  return `${CMS_S3_BASE_URL}/${useCmsStore.app?.company?.name}`;
};

const getS3LogoUrl = (): string => {
  const useCmsStore = useCmsAppsStore();
  const baseUrl = getFullPathToTenantContainer();
  const companyName = useCmsStore.app?.company?.name;
  const containerName = `${companyName}_logo-images`;

  return `${baseUrl}/${containerName}/terminal_logo.png`;
};

const getLogoUrl = (): string => {
  const { getCompanyNameNormalized } = useSevenStore();

  const companyName: string = getCompanyNameNormalized().toLowerCase();
  const url: string = 'https://assets.nsoft-cdn.com/public/';
  return `${url}${companyName}/media/logo.png`;
};

export default {
  CMS_S3_BASE_URL,
  getS3LogoUrl,
  getLogoUrl,
  getFullPathToTenantContainer,
};
