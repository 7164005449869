import { useSevenStore } from '@/modules/seven';
import { type LoggedUserData, UserApi } from '@/modules/user';
import { operatorService } from '@/modules/operator';

const changeAcceptorsState = (acceptMoney: boolean, isOperator?: boolean) => {
  const sevenStore = useSevenStore();
  const data: LoggedUserData = isOperator
    ? {
      userType: 'Operator',
      userUuid: operatorService.data.uuid,
      acceptMoney,
    }
    : {
      userType: 'Anonymous',
      userUuid: sevenStore.device.uuid,
      userName: 'Anonymous',
      acceptMoney,
    };

  return UserApi.dispatchLoggedUser(data);
};

export {
  changeAcceptorsState,
};
