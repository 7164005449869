import { range, each } from 'underscore';
import resultsTemplate from './forecastBettingArea.html';

export default {
  templateUrl: resultsTemplate,
  /**
     * @ngInject
     */
  controller: function (
    SevenGamesSvc,
    ScrUiService,
    BetslipService
  ) {
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');

    this.$onInit = function () {
      this.racersFirstPlace = range(1, SCR.numberOfRacers + 1);
      this.racersSecondPlace = range(1, SCR.numberOfRacers + 1);
      this.activeGame = SCR;
      this.scrUISvc = ScrUiService;
      this.createCombinations();
      this.scrUISvc.reverseForecastActive = false;
      this.scrUISvc.disableReverseForecast = false;
      this.scrUISvc.disableForecast = false;
    };

    this.createCombinations = function () {
      var self = this;

      each(self.racersFirstPlace, function (firstPlace) {
        each(self.racersSecondPlace, function (secondPlace) {
          if (firstPlace != secondPlace) {
            ScrUiService.forecast[firstPlace + '-' + secondPlace] = false;
          }
        });
      });
    };

    this.toggleReverseForecast = function () {
      this.scrUISvc.clearActiveBets();

      this.scrUISvc.reverseForecastActive = !this.scrUISvc.reverseForecastActive;

      this.scrUISvc.enableActiveBets(BetslipService.ticket.bets);
    };

    this.createReverseForecastBet = function (first, second) {
      var BettingSvc = SCR.getBettingSvc();

      BettingSvc.ticketInput.input = [first, second];

      return {
        key: 'reverseForecast',
        combinations: [
          first + ', ' + second,
          second + ', ' + first
        ]
      };
    };

    this.createForecastBet = function (first, second) {
      SCR._bettingSvc.clearAll();
      SCR._bettingSvc.ticketInput.input.push(first);
      SCR._bettingSvc.ticketInput.secondPlace.push(second);

      var odd = SCR._bettingSvc.systemBets.forecastOdds[first + '-' + second];

      return {
        bet: 'forecast',
        value: odd,
        racers: first + '-' + second
      };
    };

    this.sendBet = function (first, second) {
      var betToSend = null;

      if (this.scrUISvc.reverseForecastActive) {
        betToSend = this.createReverseForecastBet(first, second);
      } else {
        betToSend = this.createForecastBet(first, second);
      }

      ScrUiService.addBet(betToSend);
    };
  }
};
