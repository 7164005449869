import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import PubSub from 'pubsub-js';
import { onUnmounted, ref } from 'vue';
import { SCButton } from '@nsftx/seven-components';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderAcceptorAmount',
  setup(__props) {

const acceptorAmount = ref(0);

const acceptorAmounChangeListener = PubSub.subscribe('Acceptor.Amount.Change', (e, data) => {
  acceptorAmount.value = data;
});

onUnmounted(() => {
  PubSub.unsubscribe(acceptorAmounChangeListener);
});

return (_ctx: any,_cache: any) => {
  return (acceptorAmount.value)
    ? (_openBlock(), _createBlock(_unref(SCButton), { key: 0 }, {
        default: _withCtx(() => [
          _createTextVNode(" +" + _toDisplayString(acceptorAmount.value), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}
}

})