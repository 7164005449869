<template>
  <div class="t-footer">
    <div class="tbo-footer d-flex sc-bg-500">
      <InactivityBar
        name="terminal-backoffice-inactivity"
        orientation="vertical"
        :on-end-inactivity-timer="onEndInactivityTimer"
        :transition-delay="5000"
        :time="inactivityTime"
      />
      <TerminalInfo class="sc-ml-5" />
      <TClock class="ml-auto" />
    </div>
  </div>
</template>

<script setup lang="ts">
import operatorService from '@/modules/operator/operatorService';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import TClock from '@/common/components/TClock.vue';
import InactivityBar from '@/common/components/InactivityBar.vue';
import TerminalInfo from '@/common/components/TerminalInfo.vue';

const configSettingsStore = useConfigSettingsStore();
const inactivityTime = configSettingsStore.data.backOfficeInactivityTime.value > 0
  ? configSettingsStore.data.backOfficeInactivityTime.value
  : 30000;

const onEndInactivityTimer = () => {
  operatorService.logout();
};
</script>

<style lang="scss">
$footer-height: 64px;

.tbo-footer {
  position: relative;
  width: 100%;
  height: $footer-height;
  z-index: 1;
  box-shadow: var(--sc-elevation-2);
}
</style>
