import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import PubSub from 'pubsub-js';
import i18n from '@/plugins/i18n';
import router from '@/router';
import { useSevenStore, SevenUserTypes } from '@/modules/seven';
import { operatorService } from '@/modules/operator';
import { tboGlobalDataService, type TboGlobalDataResponse, tboBootstrapService } from '@/modules/tbo';
import { getService } from '@/common/services/ngBridge';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { useLanguageStore } from '@/common/stores/language';
import { logService } from '@/common/services/logger';
import { useENVStore } from '@/common/stores/env';
import HooksManager from '@/common/services/HooksManager';
import {
  type CardDataInfo,
  cardReadEventPubSub,
  CARD_READ_EVENT_ID,
  CardNid,
} from '@/modules/card-reader';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import ticketCheckEventsService from '@/modules/ticket-check/services/ticketCheckEventsService';
import { initTboTicketsModule } from '@/modules/tbo-tickets';
import { googleAnalyticsService } from '@/modules/google-analytics';
import { userFundsService } from '@/modules/user-funds';
import { type LoginData, LoginType } from './types';
import * as authApiService from './apiService';
import { useAuthStore } from './authStore';

let handleCardInEventListener: string;
let operatorAuthenticatedListener: string;
let showLoginListener: string;
const cardDataCredentials = ref<LoginData>({} as LoginData);

const { t } = i18n.global;

const clearEvents = () => {
  PubSub.unsubscribe(handleCardInEventListener);
  PubSub.unsubscribe(operatorAuthenticatedListener);
  PubSub.unsubscribe(showLoginListener);
};

const clearCardData = () => {
  Object.keys(cardDataCredentials.value).forEach((key: string) => {
    delete cardDataCredentials.value[key as keyof LoginData];
  });
};

const populateTBOData = (loginData: TboGlobalDataResponse) => {
  const sevenStore = useSevenStore();
  const nabSocket: any = getService('nabSocket');
  const notificationsStore = useNotificationsStore();

  notificationsStore.closeNotificationWithId('login-notification');
  clearEvents();
  clearCardData();
  operatorService.init(loginData.operator);
  initTboTicketsModule();
  userFundsService.endBalanceCheckInterval();
  tboGlobalDataService.setData(loginData);
  logService.setAdditionalLogData({ operator_id: loginData.operator.uuid });
  tboBootstrapService.boot();

  if (nabSocket.connections.Terminal) {
    nabSocket.connections.Terminal.disconnect();
    delete nabSocket.connections.Terminal;
  }

  sevenStore.$patch({
    tenant: loginData.company,
  });

  logService.debug('[authService] switchToTBO - tbo data populated. Loading back_office module...');
};

const onFailedTboLoad = () => {
  const authStore = useAuthStore();
  localStorage.removeItem('user.token');
  authStore.isLoginFailedModalOpened = true;
  googleAnalyticsService.trackEvent('Terminal_Backoffice', {
    event: 'TBO Login failed',
  });
};

const switchToTBO = (loginData: TboGlobalDataResponse) => {
  const authStore = useAuthStore();
  const $rootScope: any = getService('$rootScope');

  populateTBOData(loginData);

  $rootScope.$emit('7T:User.AuthorizationChanged', {
    productId: '*',
    id: operatorService.data.uuid,
    token: localStorage.getItem('user.token'),
    userType: SevenUserTypes.Operator.toUpperCase(),
  });

  PubSub.publish('7T:User.AuthorizationChanged');

  if (authStore.isLoginModalOpened) {
    authStore.isLoginModalOpened = false;
  }

  ticketCheckEventsService.unload();

  logService.info('[authService] Redirecting to admin state.', {
    code: 'T_LOGIN_LOAD_BACK_OFFICE_TO_START_ROUTE',
  });

  router.push({
    name: 'Admin',
  }).then(() => {
    logService.info('[authService] Admin route lodaded.', {
      code: 'T_LOGIN_LOAD_BACK_OFFICE_START_ROUTE_FINISH',
    });
    googleAnalyticsService.trackEvent('Terminal_Backoffice', {
      event: 'TBO Login',
    });
  }).catch((err) => {
    logService.error('[authService] Redirecting to admin route failed', {
      code: 'T_LOGIN_LOAD_BACK_OFFICE_START_ROUTE_ERROR',
      details: err,
    });
    onFailedTboLoad();
  });
};

const validateLogin = () => HooksManager.run('BeforeTboLogin')
  .catch((err) => {
    logService.error('[authService] BeforeTboLogin error detected.', {
      upstream_message: err.message,
      upstream_code: err.code,
      code: 'T_BEFORE_LOGIN_VALIDATION_FAILED',
    });
    return Promise.reject(err);
  });

const cardLogin = (cardData: CardDataInfo) => {
  const authStore = useAuthStore();
  const notificationsStore = useNotificationsStore();
  logService.info('[authService] Card login', {
    details: cardData,
    code: 'T_LOGIN_WITH_CARD',
  });

  validateLogin().then(() => {
    cardDataCredentials.value.username = cardData.uuid || '';
    cardDataCredentials.value.password = cardData.hash || '';
    cardDataCredentials.value.loginType = LoginType.card;
    if (cardData.pinRequired === '1') {
      logService.info('[authService] Pin Login required. Opening PIN login modal...', {
        code: 'T_LOGIN_WITH_CARD_PIN_REQ',
      });
      authStore.isPINLoginModalOpened = true;
    } else {
      authApiService.login(cardDataCredentials.value).then(
        (response) => {
          logService.info('[authService] Login with card successfull. Switching to TBO...', {
            code: 'T_LOGIN_WITH_CARD_SUCCESS',
          });
          switchToTBO(response.data);
        },
      ).catch((error) => {
        const errMsg = error.message || error.response?.data?.message || error.response?.data;
        logService.warn('[authService] Login with card failed.', {
          upstream_message: errMsg,
          upstream_code: error.status || error.response?.status,
          code: 'T_CARD_LOGIN_FAILED',
        });

        clearCardData();
        notificationsStore.show({
          message: errMsg,
          type: TNotificationTypeEnum.warning,
          delay: 3000,
        });
      });
    }
  }).catch(() => {});
};

const handleCardInEvent = (cardData: CardDataInfo) => {
  const authStore = useAuthStore();
  const notificationsStore = useNotificationsStore();
  authStore.isLoginModalOpened = false;
  if (cardData.cardEmpty) {
    logService.warn('[authService] Handling card In event - card is empty', {
      cardData,
      code: 'T_CARD_IN_CARD_EMPTY',
    });
    notificationsStore.show({
      message: t('notifications.card_empty'),
      type: TNotificationTypeEnum.warning,
      delay: 3000,
    });
  } else {
    cardLogin(cardData);
  }
};

const setUpEvents = () => {
  handleCardInEventListener = cardReadEventPubSub.subscribe(CARD_READ_EVENT_ID, (message, data) => {
    logService.info('[authService] 7T:CardReader.OnRead event received', {
      data,
      code: 'T_LOGIN_CARD_READ_EVENT',
    });
    // Nid is set to hdioa762 on operator card used for logging
    if (data?.Nid === CardNid.hdioa762) {
      handleCardInEvent(data);
    }
  });

  operatorAuthenticatedListener = PubSub.subscribe('Operator.Authenticated', (msg, data) => {
    authApiService.loginCheck(data.token).then((response) => {
      switchToTBO(response.data);
    }).catch((err) => {
      logService.warn('[authService] loginCheck has failed on Operator.Authenticated', {
        code: 'T_LOGIN_CHECK_FAILED',
        err,
      });
      const notificationsStore = useNotificationsStore();
      notificationsStore.show({
        message: err.data.message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
    });
  });

  showLoginListener = PubSub.subscribe('7T:Login.ShowLoginModal', (event, data) => {
    const authStore = useAuthStore();
    const { preventLoginModalClose, isLoginModalOpened } = storeToRefs(authStore);
    if (data.preventClose) {
      preventLoginModalClose.value = data.preventClose;
    }

    validateLogin().then(() => {
      isLoginModalOpened.value = true;
    }).catch(() => {});
  });
};

const isQrCodeEnabled = (): boolean => {
  const envStore = useENVStore();
  const gravitySettingsStore = useGravitySettingsStore();
  return !!(envStore.data.gravityAuth.url && gravitySettingsStore
    .getData().config?.qrLoginEnabled);
};

function getQrCodeData() {
  const envStore = useENVStore();
  const sevenStore = useSevenStore();
  const langStore = useLanguageStore();

  const loginUrl = `${envStore.data.gravityAuth?.url}/login?bId=
  ${sevenStore.betshop.id}&cId=${sevenStore.tenant.id}&dUuid=
  ${sevenStore.device.uuid}&dp=Terminal&l=${langStore.currentLang?.id}`;

  return {
    text: loginUrl,
    size: 150,
    background: 'white',
  };
}

const login = (credentials: LoginData) => validateLogin()
  .then(() => authApiService.login(credentials));

export {
  switchToTBO,
  setUpEvents,
  isQrCodeEnabled,
  getQrCodeData,
  login,
  validateLogin,
  cardDataCredentials,
  clearCardData,
  clearEvents,
};
