import { setConfig as setSevenComponentsConfig } from '@nsftx/seven-components';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useLanguageStore } from '@/common/stores/language';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import type { VirtualKeyboardConfig } from './types';

const getKeyboardLayout = () => {
  const { getByKey } = useGravitySettingsStore();
  const { defaultLayout, layoutByLanguage } = getByKey<VirtualKeyboardConfig>('virtualKeyboard') || {};
  const { getCurrentLanguage } = useLanguageStore();
  const lang = getCurrentLanguage();

  return layoutByLanguage?.[lang] || defaultLayout;
};

const isKeyboardEnabled = () => {
  const gravitySettingsStore = useGravitySettingsStore();
  const envStore = useEnvironmentHandlerStore();
  const isKeyboardEnabledInCms = gravitySettingsStore.getModuleDataKeyValue('virtualKeyboard', 'enabled');
  const isDmMode = envStore.isDmApplicationRuntime();

  return !(isDmMode && !isKeyboardEnabledInCms);
};

const init = () => {
  setSevenComponentsConfig({
    SCKeyboard: {
      layout: getKeyboardLayout(),
      enabled: isKeyboardEnabled(),
    },
  });
};

export {
  init,
  getKeyboardLayout,
  isKeyboardEnabled,
};
