/* eslint-disable angular/controller-as-route */
import angular from 'angular';
import ngAnimate from 'angular-animate';
import router from 'angular-ui-router';
import { setConfig as setSevenComponentsConfig } from '@nsftx/seven-components';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import '@nsftx/seven-ng-print-service/dist/ng-print-service';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { logService } from '@/common/services/logger';
import { useOddTypeStore } from '@/common/stores/odd-type';
import { setInitialLangOddsState } from '@/common/services/commonService';
import { workingTimeService } from '@/modules/working-time';
import { authService } from '@/modules/auth';
import { usePaymentsStore } from '@/modules/payments';
import { initCardReaderModule } from '@/modules/card-reader';
import commonModule from './src/common';
import coreModule from './src/core';
import limitModule from './src/limits';
import memoryOptimizatorModule from './src/modules/memory_optimizator';
import integratorModule from './src/modules/integrator';
import gamesModule from './src/games';
import betslipModule from './src/betslip';
import resultsModule from './src/results';
import marketingModule from './src/modules/marketing';
import ngsModule from './src/games/ngs';
import ticketsModule from './src/tickets';
import sevenTicketCenterModule from './src/modules/ticket_center';
import kenoUiModule from './src/games/keno';
import slotCarRacesModule from './src/games/slot_car_races';
import virtualMotorcycleSpeedwayModule from './src/games/virtual_motorcycle_speedway';
import greyhoundRacesUiModule from './src/games/greyhound_races';
import luckyXUiModule from './src/games/lucky_x';
import luckySixUiModule from './src/games/lucky_six';
import horseRacesUiModule from './src/games/horse_races';
import peripheralsModule from './src/peripherals';
import modulesModule from './src/modules/index';
import gravityModule from './src/modules/gravity';
import runnersModule from './src/modules/runners';
import popupWrapperTemplate from './src/common/template/popupWrapper.html';
import resultIntegrationTemplate from './src/results/integration/results_integration.html';
import resultsTemplate from './src/results/results.html';
import gamesTemplate from './src/games/games.html';

// Define angular app
(function () {
  // default modules in angular app
  var modules = [
    router,
    ngAnimate,
    'pascalprecht.translate',
    'nabSocketClient',
    sevenTicketCenterModule,
    'nabTicket',
    'nabPrintService',
    betslipModule,
    'SevenClient.Config',
    'ngLocale',
    'SevenClientCore',
    'SevenClientLib',
    greyhoundRacesUiModule,
    horseRacesUiModule,
    luckySixUiModule,
    luckyXUiModule,
    virtualMotorcycleSpeedwayModule,
    slotCarRacesModule,
    kenoUiModule,
    limitModule,
    integratorModule,
    commonModule,
    peripheralsModule,
    ticketsModule,
    coreModule,
    ngsModule,
    marketingModule,
    modulesModule,
    gravityModule,
    gamesModule,
    resultsModule,
    memoryOptimizatorModule,
    runnersModule
  ];

  angular.module(
    'terminalApp',
    modules
  ).config(
    function (
      $httpProvider,
      $stateProvider,
      $compileProvider,
      $windowProvider,
      NabSocketConfigProvider,
      SevenTicketConfigProvider,
      langProvider,
      LocalData,
      LocalAppConfig,
      NabENV,
      SevenRoutes,
      SevenClientConfigProvider,
      BootStrapDataProvider,
      printEngineProviderProvider,
      SevenClientLibConfigProvider,
      BetslipConfigProvider,
      modalSvcConfigProvider,
      GravitySettingsProvider
    ) {
      const environmentHandlerStore = useEnvironmentHandlerStore();
      var $window = $windowProvider.$get();
      var printServiceIp = $window.localStorage.getItem('settings.printServiceIp');
      const scrollButtonsEnabled = BootStrapDataProvider.appConfig.scrollButtons.value;
      environmentHandlerStore.setState('activeApp', 'Terminal');

      BetslipConfigProvider.set({
        moneyDenominations: BootStrapDataProvider.appConfig.moneyDenominations.value
      });

      setSevenComponentsConfig({
        SCScrollButtons: {
          enabled: scrollButtonsEnabled
        },
        SCCalendar: {
          backdrop: true
        }
      });

      SevenClientLibConfigProvider.set({
        scroll: {
          showNavigation: scrollButtonsEnabled
        },
        popup: {
          templateUrl: popupWrapperTemplate
        }
      });

      printEngineProviderProvider.set({
        url: printServiceIp ? 'http://' + printServiceIp + ':8080/' : NabENV.settings.printService.url,
        printServiceActive: true,
        pinRequired: BootStrapDataProvider.ticketActions.pinRequred,
        pinVisible: BootStrapDataProvider.appSettings.config
          && typeof BootStrapDataProvider.appSettings.config.pinVisible !== 'undefined'
          ? BootStrapDataProvider.appSettings.config.pinVisible : true,
        pinBundableWithBarcode: BootStrapDataProvider.appSettings.config
          && BootStrapDataProvider.appSettings.config.pinBundableWithBarcode,
        currency: BootStrapDataProvider.company.currency
      });

      // Set modal default node
      modalSvcConfigProvider.set({ rootEl: 'terminal-app' });

      // disable debug see https://docs.angularjs.org/guide/production#disabling-debug-data
      $compileProvider.debugInfoEnabled(logService.isDebugMode());
      SevenClientConfigProvider.set(angular.merge({
        global: {
          language: langProvider.currentLang,
          deliveryPlatform: 'Terminal', // Retail, Terminal, TerminalAdmin
          apiPoint: SevenRoutes.mainUrl + '/api/terminal'
        },
        device: {
          uuid: LocalData.device.uuid
        },
        ncm: {
          token: LocalAppConfig.appConfig.terminalAccount.password,
          username: LocalAppConfig.appConfig.terminalAccount.uuid,
          additionalChannels: [
            {
              name: 'terminalChannel',
              id: LocalAppConfig.appConfig.terminalAccount.uuid
            },
            {
              // Channel for app config updates
              name: 'terminalAppConfig',
              id: BootStrapDataProvider.company.uuid + 'TerminalSettings'
            },
            {
              // Channel for betshop permissions updates
              name: 'betshop',
              id: BootStrapDataProvider.betshop.uuid
            },
            {
              // Channel for device permissions updates
              name: 'device',
              id: LocalData.device.uuid
            },
            {
              // Channel for app config updates
              name: 'company',
              id: BootStrapDataProvider.company.uuid
            }
          ]
        }
      }, NabENV.settings, NabENV));

      SevenTicketConfigProvider.set({
        url: SevenRoutes.mainUrl + '/api/terminal/',
        scmUrl: SevenRoutes.cmUrl,
        ticketHandling: {
          LuckySix: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 40000,
            pendingTicketRejectPeriod: 120000,
            sentTicketRejectPeriod: 10000
          },
          LuckyX: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 10000,
            pendingTicketRejectPeriod: 30000,
            sentTicketRejectPeriod: 10000
          },
          GreyhoundRaces: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 40000,
            pendingTicketRejectPeriod: 120000,
            sentTicketRejectPeriod: 10000
          },
          VirtualGreyhoundRaces: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 40000,
            pendingTicketRejectPeriod: 120000,
            sentTicketRejectPeriod: 10000
          },
          VirtualMotorcycleSpeedway: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 15000,
            pendingTicketRejectPeriod: 60000,
            sentTicketRejectPeriod: 10000
          },
          SlotCarRaces: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 15000,
            pendingTicketRejectPeriod: 60000,
            sentTicketRejectPeriod: 10000
          },
          HorseRaces: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 15000,
            pendingTicketRejectPeriod: 60000,
            sentTicketRejectPeriod: 10000
          },
          VirtualHorseRaces: {
            useTicketLocalStorage: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 15000,
            pendingTicketRejectPeriod: 60000,
            sentTicketRejectPeriod: 10000
          },
          Voucher: {
            useTicketLocalStorage: true
          }
        }
      });

      // eslint-disable-next-line
      var config = GravitySettingsProvider.getByKey('config');

      NabSocketConfigProvider.set({
        url: SevenRoutes.cmUrl,
        queryParams: {
          clientType: 'Api',
          clientSubType: 'terminal',
          contentEncoding: 'deflate'
        },
        params: angular.extend(
          (config && config.webSocketsOnly) !== false
            ? { transports: ['websocket'], upgrade: false } : {},
          { forceNew: true }
        )
      });

      // overrides the default regular expression
      // that is used for whitelisting of safe urls during img[src]
      // eslint-disable-next-line
      $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|chrome|chrome-extension):|data:image\//);
      $compileProvider.commentDirectivesEnabled(false);
      $compileProvider.cssClassDirectivesEnabled(false);

      $httpProvider.defaults.headers.common.Authorization = 'Basic '
      + $window.btoa(LocalAppConfig.appConfig.terminalAccount.uuid + ':' + LocalAppConfig.appConfig.terminalAccount.password);

      $stateProvider.state('app.home', {
        url: 'home?lobby',
        template: '<div ng-non-bindable></div>',
        resolve: {
          onEnter: function (SevenGamesSvc) {
            SevenGamesSvc.resetActiveGame();
          }
        }
      });

      $stateProvider.state('app.lobbyitem', {
        url: 'lobby/:id',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('app.instructions', {
        url: 'help',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('app.termsandconditions', {
        url: 'terms',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('app.games', {
        url: 'games?:gameId&lobby',
        templateUrl: gamesTemplate,
        controller: 'GamesController as vm'
      });

      $stateProvider.state('app.generic', {
        url: 'generic?:genericId?lobby',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('app.results', {
        url: 'results?lobby',
        templateUrl: resultsTemplate,
        controller: 'ResultsController as vm'
      });

      $stateProvider.state('app.results.ticketcheck', {
        url: '/ticketCheck',
        template: '<div ng-non-bindable/>'
      });

      $stateProvider.state('app.results.integration', {
        url: '/integration/:gameId',
        templateUrl: resultIntegrationTemplate,
        controller: 'ResultsIntegrationController as vm'
      });
      $stateProvider.state('admin', {
        url: '/admin',
        template: '<div ng-non-bindable></div>'
      });
      // Tickets
      $stateProvider.state('admin.tickets', {
        url: '/tickets',
        redirectTo: true,
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.tickets.checktickets', {
        url: '/check/:code',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.tickets.viewtickets', {
        url: '/list',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.tickets.createvouchers', {
        url: '/vouchers',
        template: '<div ng-non-bindable></div>'
      });

      // Wallet
      $stateProvider.state('admin.wallet', {
        url: '/wallet',
        template: '<div ng-non-bindable></div>'
      });

      /** * wallet terminal ** */
      $stateProvider.state('admin.wallet.terminalwallet', {
        url: '/terminal',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.wallet.acceptoramount', {
        url: '/acceptoramount',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.wallet.operatorwallet', {
        url: '/shifthandling',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.wallet.terminalbalance', {
        url: '/terminalbalance',
        template: '<div ng-non-bindable></div>'
      });

      /** ********* reporting ********** */
      $stateProvider.state('admin.reporting', {
        url: '/reporting',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.reporting.profitreport', {
        url: '/profitreport',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.reporting.terminalbalancereport', {
        url: '/terminalbalancereport',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.reporting.turnoverreport', {
        url: '/turnoverreport',
        template: '<div ng-non-bindable></div>'
      });

      /** ****** Options (System Info) *************** */
      $stateProvider.state('admin.systeminfo', {
        url: '/systeminfo',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.systeminfo.device', {
        url: '/device',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.systeminfo.landingPage', {
        url: '/landing-page',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.systeminfo.serviceMode', {
        url: '/service-mode',
        template: '<div ng-non-bindable></div>'
      });

      /** ****** card operations *************** */
      $stateProvider.state('admin.cardoperations', {
        url: '/cardoperations',
        template: '<div ng-non-bindable></div>'
      });

      $stateProvider.state('admin.cardoperations.operatorcards', {
        url: '/operators',
        template: '<div ng-non-bindable></div>'
      });

      /** ****** billing *************** */
      $stateProvider.state('admin.billing', {
        url: '/billing',
        template: '<div ng-non-bindable></div>'
      });

      /** ****** Certificate *************** */
      $stateProvider.state('admin.certificate', {
        url: '/certificate',
        template: '<div ng-non-bindable></div>'
      });
    }
  ).run(
    function (
      $rootScope,
      $log,
      GravitySettings,
      BootStrapData,
      Modules
    ) {
      Modules.registerModules(GravitySettings.getByKey('modules') || {}); // modules defined in gravity (CMS)

      if (BootStrapData.payments?.length > 0) {
        const paymentsStore = usePaymentsStore();
        paymentsStore.setPayments(BootStrapData.payments);
      }

      Modules.loadModulesByPosition('beforeAppReady').finally(function () {
        workingTimeService.init(BootStrapData.workingTime);
        initCardReaderModule();

        const oddTypeStore = useOddTypeStore();
        oddTypeStore.handleInitialOddType();
        // Set initial Language and odd state on NAR
        // Further changes will be published
        setInitialLangOddsState();

        authService.setUpEvents();

        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
          $rootScope.uiRouterpreviousState = from;
          $rootScope.uiRouterpreviousStateParams = fromParams;
        });

        // Because ui-route resolve doesn't throw error, this is a workaround to catch them
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
          $log.error('[7T] State change default error', {
            code: 'T_ERROR_DEFAULT_STATE_CHANGE',
            details: error
          });
        });
      });
    }
  );
})(window);
