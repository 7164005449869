<script lang="ts" setup>
import { computed } from 'vue';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
// eslint-disable-next-line import/no-cycle
import { setIsModalShown, getIsModalShown } from '@/modules/lobby/lobbyService';
import { vGaTrackEvent } from '@/modules/google-analytics';

const { t } = i18n.global;

const openLobby = () => {
  setIsModalShown(true);
};

const isActive = computed(() => getIsModalShown());
</script>
<template>
  <div class="t-footer-button sc-ml-1 sc-mr-1">
    <SCButton
      v-ga-track-event="{
        eventName: 'Open_Module',
        eventParams: { 'module': 'Lobby', 'source': 'Footer' },
      }"
      width="100%"
      prepend-icon="globe-a2"
      class="sc-mr-1 sc-my-1"
      :variant="isActive ? ButtonVariant.Brand : ButtonVariant.Secondary"
      height="60px"
      @click="openLobby"
    >
      <span class="sc-ml-3 footer-button-label">
        {{ t('terminal_lobby_footer') }}
      </span>
    </SCButton>
  </div>
</template>
