import angular from 'angular';
import coreModule from '../../core';
import configBlock from './config';
import kenoSvc from './results/kenoResultsSvc';
import kenoResultsComponent from './results/kenoComponent';

const moduleName = angular.module('7Terminal.KenoUI', [
  coreModule
])
  .component('kenoResults', kenoResultsComponent)
  .config(configBlock)
  .service('kenoSvc', kenoSvc)
  .name;

export default moduleName;
