import templateUrl from './featureBetsPopUp.html';

(function () {
  angular
    .module('terminalApp.nabLuckySixUi')
    .component('luckySixUiFutureBets', {
      require: {
        luckySixBettingArea: '^luckySixBettingArea'
      },
      templateUrl: templateUrl,
      controller: function (popupService) {
        var ctrl = this;

        ctrl.setFutureEvents = function (nmbr) {
          ctrl.luckySixBettingArea.setFutureEvents(nmbr);
          popupService.closePopup('luckySixUiFutureBets');
        };
      }
    });
})();
