import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import i18n from '@/plugins/i18n';
import { reloadIfAppOnHighMemoryUsage } from '@/modules/performance';
import { useMarketingScreenSaverStore } from '@/modules/cms/marketing/marketingScreenSaverStore';
import { setIsModalShown, shouldOpenLobbyAfterIdleTime } from '@/modules/lobby/lobbyService';
import { narApiService } from '@/modules/nar';
import { localAppConfigService } from '../local-app-config';
import { getService } from '../ngBridge';
import {
  ACTIVITY_SESSION_END_EVENT_ID,
  activitySessionEndEventPubSub,
} from './activitySessionEndEvent';

const onEndInactivityTimer = () => {
  const { t } = i18n.global;
  const $rootScope = getService<angular.IRootScopeService>('$rootScope');
  const loaderService = getService<{ endLoader:() => void }>('loaderService');
  const BetslipService = getService<{ resetBetslip:() => void }>('BetslipService');
  const switchView = getService<{ goToLandingPage:(idleTimeFinished: boolean) => void }>(
    'switchView');
  const { isScreenSaverEnabled, showScreenSaver } = useMarketingScreenSaverStore();
  const notificationsStore = useNotificationsStore();

  // Close numpad
  $rootScope?.$emit('closeNumPad:stop', '');
  // Stop loader
  loaderService?.endLoader();
  // Emit end of session activity
  activitySessionEndEventPubSub.publish(ACTIVITY_SESSION_END_EVENT_ID);
  // Reset every game betslip
  BetslipService?.resetBetslip();
  // Notify NAR if infoPoint mode is active
  if (localAppConfigService.data.infoPointActive) {
    narApiService.dispatchInfoPointActivation();
  }
  // Go back to landing page
  switchView?.goToLandingPage(true);

  // Scroll to top after inactivity timer ends
  const homeEl = document.getElementById('home-page');
  homeEl?.scrollTo({ behavior: 'smooth', top: 0 });

  if (shouldOpenLobbyAfterIdleTime()) {
    setIsModalShown(true);
  }

  if (isScreenSaverEnabled()) {
    showScreenSaver();
  }

  reloadIfAppOnHighMemoryUsage();

  notificationsStore.show({
    message: t('notifications_landing_page_inactivity'),
    type: TNotificationTypeEnum.info,
    delay: 3000,
  });
};

export { onEndInactivityTimer };
