import BaseError from '@/common/errors/BaseError';
import { TNotificationTypeEnum } from '@/common/stores/notifications';
import { TicketPayoutErrorName } from '../types/TicketPayoutErrorName';

export default class TicketPayoutError extends BaseError<TicketPayoutErrorName> {
  constructor(
    public message: string,
    public code: TicketPayoutErrorName,
    public options: {
      cause?: unknown;
      context: {
        notificationDetails: {
          type: TNotificationTypeEnum
        }
      }
    },
  ) {
    super(message, code, options);
    Object.setPrototypeOf(this, TicketPayoutError.prototype);
    this.name = this.constructor.name;
  }

  toString() {
    return `${this.code}: ${this.message}`;
  }
}
