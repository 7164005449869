import { uniq, where } from 'underscore';
import templateUrl from './greyhound_races.html';
import inactiveImage from '../../../assets/images/app/dogs_inactive_bg.png';

(function () {
  angular.module('terminalApp.nabGreyhoundRacesUi')
    .component('greyhoundRacesUi', {
      controllerAs: 'greyhoundRacesVm',
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        nabTrans,
        NabNotifications,
        BetslipService,
        GreyhoundRacesUiService,
        $scope,
        $rootScope,
        $state,
        loaderService,
        GamesUiService,
        arrayService
      ) {
        var odds;
        var finalizedBet;
        var bet;
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); // switch virtual and regular greyhound races
        var GreyhoundRaces = SevenGamesSvc.getGame(activeGame);
        var gameState = GamesUiService.checkGameActivity(GreyhoundRaces);
        var self = this;
        var betslipResetListener;
        var BettingSvc = GreyhoundRaces.getBettingSvc();

        this.$onInit = function () {
          this.activeGame = GreyhoundRaces;
          this.gameInfo = GreyhoundRaces.getGameInfo();
          odds = GreyhoundRaces.odds;
          this.resolutionMode = $rootScope.resolutionMode;
          BettingSvc.initializeService(GreyhoundRaces);
          this.betslipSvc = BetslipService;
          this.grehoundRacesUISvc = GreyhoundRacesUiService;
          this.tabs = {
            name: ['racecard', 'forecast', 'tricast']
          };

          // GreyhoundRacesUiService.boot();

          if (this.activeGame.isScheduleActive) {
            this.grehoundRacesUISvc.createScheduledEvents(this.activeGame.offer);
            this.watchDisabled();
          }
          this.inactiveImage = inactiveImage;
          $scope.futureRounds = arrayService.getArray(10, true);
        };

        /**
                 * Send created bet to betslip
                 */

        this.sendBet = function () {
          if (!gameState.value) {
            NabNotifications.show(
              {
                message: nabTrans.translate(gameState.messageKey, { game: this.activeGame.translation }, true),
                type: 'warning',
                delay: 3000
              }
            );
            return;
          }

          if (!flashButton.flash) {
            return;
          }

          if (this.grehoundRacesUISvc.reverseForecastPlaces.length > 0) {
            this.formatReverseForecastBet();
          }

          if (this.grehoundRacesUISvc.reverseTricastPlaces.length > 0) {
            this.formatReverseTricastBet();
          }

          if (this.grehoundRacesUISvc.forecastFirstPlaces.length > 0 && this.grehoundRacesUISvc.forecastSecondPlaces.length > 0) {
            this.formatForecastBet();
          }

          if (this.grehoundRacesUISvc.tricastFirstPlaces.length > 0
                        && this.grehoundRacesUISvc.tricastSecondPlaces.length > 0 && this.grehoundRacesUISvc.tricastThirdPlaces.length > 0) {
            this.formatTricastBet();
          }

          finalizedBet = BettingSvc.finalizeTicketInput();

          if (this.activeGame.isScheduleActive) {
            finalizedBet[0].eventId = this.activeGame._scheduleSvc.activeRound.eventId;
            finalizedBet[0].event = this.activeGame._scheduleSvc.activeRound.eventIdToday;
          }

          var error = BetslipService.validateBet(
            finalizedBet,
            false
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            BetslipService.addToBetslip(finalizedBet);
          }

          this.resetBet();
        };

        /** Format reverse forecast bet for sending to server* */

        this.formatReverseForecastBet = function () {
          /** Remove duplicates form array* */

          this.grehoundRacesUISvc.reverseForecastPlaces = uniq(this.grehoundRacesUISvc.reverseForecastPlaces);

          bet = where(GreyhoundRaces.configuredBets, { key: 'reverseForecast' });
          BettingSvc.activateBet(bet[0]);

          /** Remove set forecast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.grehoundRacesUISvc.reverseForecastPlaces;
        };

        /** Format  forecast bet for sending to server* */

        this.formatForecastBet = function () {
          bet = where(GreyhoundRaces.configuredBets, { key: 'forecast' });

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Remove duplicates form array* */

          this.grehoundRacesUISvc.forecastFirstPlaces = uniq(this.grehoundRacesUISvc.forecastFirstPlaces);
          this.grehoundRacesUISvc.forecastSecondPlaces = uniq(this.grehoundRacesUISvc.forecastSecondPlaces);

          BettingSvc.activateBet(bet[0]);

          /** Add odd for forecast bet* */

          if (this.grehoundRacesUISvc.forecastFirstPlaces.length == 1 && this.grehoundRacesUISvc.forecastSecondPlaces.length == 1) {
            BettingSvc.ticketInput.systemOdd = GreyhoundRaces._bettingSvc.systemBets.forecastOdds[this.grehoundRacesUISvc.forecastFirstPlaces[0] + '-' + this.grehoundRacesUISvc.forecastSecondPlaces[0]];

            if (this.activeGame.isScheduleActive) {
              BettingSvc.ticketInput.systemOdd = this.activeGame._scheduleSvc.activeRound.bets.systemBets.forecastOdds[this.grehoundRacesUISvc.forecastFirstPlaces[0] + '-' + this.grehoundRacesUISvc.forecastSecondPlaces[0]];
            }
          } else {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.grehoundRacesUISvc.forecastFirstPlaces;
          BettingSvc.ticketInput.secondPlace = this.grehoundRacesUISvc.forecastSecondPlaces;
        };

        /** Format  tricast bet for sending to server* */

        this.formatTricastBet = function () {
          /** Remove set forecast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          bet = where(GreyhoundRaces.configuredBets, { key: 'tricast' });

          /** Remove duplicates form array* */

          this.grehoundRacesUISvc.tricastFirstPlaces = uniq(this.grehoundRacesUISvc.tricastFirstPlaces);
          this.grehoundRacesUISvc.tricastSecondPlaces = uniq(this.grehoundRacesUISvc.tricastSecondPlaces);
          this.grehoundRacesUISvc.tricastThirdPlaces = uniq(this.grehoundRacesUISvc.tricastThirdPlaces);

          if (this.grehoundRacesUISvc.tricastFirstPlaces.length == 1 && this.grehoundRacesUISvc.tricastSecondPlaces.length == 1 && this.grehoundRacesUISvc.tricastThirdPlaces.length == 1) {
            BettingSvc.ticketInput.systemOdd = GreyhoundRaces._bettingSvc.systemBets.tricastOdds[this.grehoundRacesUISvc.tricastFirstPlaces[0] + '-' + this.grehoundRacesUISvc.tricastSecondPlaces[0] + '-' + this.grehoundRacesUISvc.tricastThirdPlaces[0]];

            if (this.activeGame.isScheduleActive) {
              BettingSvc.ticketInput.systemOdd = this.activeGame._scheduleSvc.activeRound.bets.systemBets.tricastOdds[this.grehoundRacesUISvc.tricastFirstPlaces[0] + '-' + this.grehoundRacesUISvc.tricastSecondPlaces[0] + '-' + this.grehoundRacesUISvc.tricastThirdPlaces[0]];
            }
          } else {
            delete BettingSvc.ticketInput.systemOdd;
          }

          BettingSvc.activateBet(bet[0]);

          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.grehoundRacesUISvc.tricastFirstPlaces;
          BettingSvc.ticketInput.secondPlace = this.grehoundRacesUISvc.tricastSecondPlaces;
          BettingSvc.ticketInput.thirdPlace = this.grehoundRacesUISvc.tricastThirdPlaces;
        };

        /** Format reverse tricast bet for sending to server* */

        this.formatReverseTricastBet = function () {
          /** Remove set forecast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Remove duplicates form array* */

          this.grehoundRacesUISvc.reverseTricastPlaces = uniq(this.grehoundRacesUISvc.reverseTricastPlaces);
          bet = where(GreyhoundRaces.configuredBets, { key: 'reverseTricast' });

          BettingSvc.activateBet(bet[0]);
          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.grehoundRacesUISvc.reverseTricastPlaces;
        };

        this.clearAll = function () {
          this.grehoundRacesUISvc.disableReverseForecast = false;
          this.grehoundRacesUISvc.disableForecast = false;
          this.grehoundRacesUISvc.disableReverseTricast = false;
          this.grehoundRacesUISvc.disableTricast = false;
          this.grehoundRacesUISvc.forecastFirstPlaces = [];
          this.grehoundRacesUISvc.forecastSecondPlaces = [];
          this.grehoundRacesUISvc.reverseForecastPlaces = [];
          this.grehoundRacesUISvc.tricastFirstPlaces = [];
          this.grehoundRacesUISvc.tricastSecondPlaces = [];
          this.grehoundRacesUISvc.tricastThirdPlaces = [];
          this.grehoundRacesUISvc.reverseTricastPlaces = [];
          this.grehoundRacesUISvc.deactivateForecastRacers();
          this.grehoundRacesUISvc.deactivateTricastRacers();
          this.grehoundRacesUISvc.deactivateRacecardRacers();
        };

        this.checkState = function (btn) {
          if (this.grehoundRacesUISvc.reverseForecastPlaces.length > 0) {
            btn = true;
          }

          if (this.grehoundRacesUISvc.reverseTricastPlaces.length > 0) {
            btn = true;
          }

          if (this.grehoundRacesUISvc.forecastFirstPlaces.length > 0 && this.grehoundRacesUISvc.forecastSecondPlaces.length > 0) {
            btn = true;
          }

          if (this.grehoundRacesUISvc.tricastFirstPlaces.length > 0
                        && this.grehoundRacesUISvc.tricastSecondPlaces.length > 0 && this.grehoundRacesUISvc.tricastThirdPlaces.length > 0) {
            btn = true;
          }

          return btn;
        };
        /**
                 * Clear currently selected bet
                 */
        this.watchDisabled = function () {
          $scope.$watch(function () {
            return self.activeGame.gameInfo.betsBlocked;
          }, function (newValue) {
            if (newValue) {
              flashButton.flash = false;
            } else if (self.checkState(flashButton.flash)) flashButton.flash = true;
          }, true);
        };

        this.resetBet = function () {
          BettingSvc.clearAll();
          this.clearAll();
          flashButton.flash = false;
        };

        betslipResetListener = $rootScope.$on('7T:Betslip.Reset', function () {
          self.resetBet();
        });

        var betslipBetsChangedListener = $rootScope.$on('7T.Betslip.BetsChanged', function (e, data) {
          if (data.type === 'betRemove') {
            self.resetBet();
          }
        });

        $scope.$on('$destroy', function () {
          betslipResetListener();
          betslipBetsChangedListener();
        });

        flashButton.flash = false;
        loaderService.endLoader();
      }
    });
})();
