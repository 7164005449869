import templateUrl from './lucky_x.html';

angular.module('terminalApp.nabLuckyXUi')
  .directive('luckyXResults', function luckyXResults() {
    return {
      restrict: 'E',
      templateUrl: templateUrl,
      controller: 'LuckyXResultsController'
    };
  });
