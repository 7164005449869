import resultsTemplate from './content.html';

angular.module('terminalApp')
  .directive('racerGameResults', function racerGameResults() {
    return {
      scope: {
        game: '<'
      },
      templateUrl: resultsTemplate,
      controller: 'RacerGameResultsController'
    };
  });
