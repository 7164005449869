import templateUrl from './templates/numPad.html';
/**
 * Holds numPad keys
 * Create them on init
 */
const numberValues = (function () {
  const arr = new Array(9);
  let i = 1;

  for (i; i <= 9; i++) {
    arr[i - 1] = i;
  }

  return arr.concat(['.', '0', 'C']);
})();

const addNumPadAmount = function (value, numbers) {
  // don't allow more than 12 number in numPad
  if (numbers.length > 12 && value !== 'C') return numbers;

  // allow only one comma and only two number values after comma!
  if (value !== 'C' && numbers.indexOf('.') > -1 && (numbers.join('').split('.')[1].length > 1 || value === '.')) return numbers;

  // if value is 'C' remove last number from numPad, else add it to numPad
  if (value === 'C') {
    numbers.pop();
  } else {
    numbers.push(value);
  }

  return numbers;
};

/**
 *
 * @ngInject
 */
function numPad($rootScope) {
  return {
    replace: true,
    scope: {
      numPadTemplate: '@numPadTemplate',
      numPadVisibility: '@numPadVisibility'
    },
    templateUrl,
    controller: function ($scope) {
      'ngInject';

      /**
       * Hold id of current focused input
       * @type {null}
       */
      let currentInptId = null;

      /**
       * numbers in num pad
       * @type {string}
       */
      $scope.numbers = [];
      $scope.numberValues = numberValues;

      /**
       * Show/hide popup
       * @type {boolean}
       */
      $scope.visibility = false;

      /**
       * @type {number}
       */
      $scope.firstClick = 0;

      /**
       * Show numPad
       */
      function showNumPad(numbers) {
        $scope.deleteAll();
        $scope.visibility = true;
        $scope.firstClick = 0;
        numbers = numbers || '0';
        $scope.numbers.push(numbers.toString().split('.'));
        $scope.numPadCssClass = $rootScope.numPadCssClass;
      }

      /**
       * Listen for focus even. This event will
       * be emitted by numPadInput directive
       */
      const focustInputListener = $rootScope.$on('NumPad:focusInput', function (e, data) {
        $scope.numPadContent = data.numPadContent;
        currentInptId = data.id;

        if ($scope.numPadTemplate) {
          return;
        }

        showNumPad(data.value);
      });

      /**
       * Format value and add to final result
       * @param value - number value, or '.' or 'c'-delete
       */
      $scope.addNumPadAmount = function (value) {
        // if first click on numpad, clear input
        $scope.firstClick += 1;
        if ($scope.firstClick === 1) {
          $scope.numbers = [];
        }
        $scope.numbers = addNumPadAmount(value, $scope.numbers);
        if ($scope.numPadTemplate) {
          $scope.setNumPadValue();
        }
      };

      $scope.setNumPadValue = function () {
        $rootScope.$broadcast('NumPad:setNumPadValue', {
          value: Number($scope.numbers.join(''))
        });
      };

      /**
       *  Send numbers to numPadInput and close numpad
       */
      $scope.sendNumbers = function () {
        // emit final result to focused input
        const emitValue = $scope.numPadContent !== 'text'
          ? Number($scope.numbers.join(''))
          : String($scope.numbers.join(''));

        $rootScope.$emit('NumPad:setInputValue', {
          id: currentInptId,
          value: emitValue
        });
        // close input
        $scope.closeNumPad();
      };

      const clearAllListener = $rootScope.$on('NumPad:clearAll', function () {
        $scope.deleteAll();
      });

      /**
       * Clear numpad input
       */
      $scope.deleteAll = function () {
        $scope.numbers = [];
      };

      /**
       * Close popup numPad
       */
      $scope.closeNumPad = function () {
        $scope.visibility = false;
        $scope.firstClick = 0;
        currentInptId = null;
      };

      /** * when idle directive on terminal switches screen to 'main_menu',
       * we must close numPad
       */
      const closeNumPadListener = $rootScope.$on('closeNumPad:stop', function () {
        $scope.closeNumPad();
      });

      $scope.$on('$destroy', function () {
        focustInputListener();
        clearAllListener();
        closeNumPadListener();
      });
    }
  };
}
export default numPad;
