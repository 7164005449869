import PubSub from 'pubsub-js';
import { watch } from 'vue';
import { acceptorsService } from '@/modules/acceptors';
import {
  printService,
  printerMessagesService,
  usePrinterStatusStore,
  printerMessagesParser,
  TPrintErrorCodes
} from '@/modules/print';
import { connectionStatusService } from '@/modules/connection-status';
import { userFundsService } from '@/modules/user-funds';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';

(function () {
  angular.module('7Terminal.Peripherals')
    .service('Peripherals', Peripherals);

  function Peripherals(
    $q,
    $rootScope,
    $log,
    nabTrans,
    printServiceApi,
    NabNotifications,
    GravitySettings,
    errorParser,
  ) {
    var peripheralsState = {
      connection: {
        valid: true
      },
      printer: {
        valid: true,
        statusLevel: 'OK',
        errorMessage: ''
      },
      moneyAcceptors: {
        valid: true
      }
    };

    return {
      init: function () {
        const envStore = useEnvironmentHandlerStore();
        this.setPrinterListeners();
        this.setAcceptorListeners();

        if (!envStore.isDmApplicationRuntime()) {
          this.setCCListeners();
        }
      },

      updatePeripheralsState: function (type, value) {
        angular.extend(peripheralsState[type], value);
        peripheralsState.productId = '*';

        $log.debug('[7Terminal.Peripherals]  update ' + type + 'state', {
          type: type,
          value: value,
          code: 'T_PERIPHERALS_STATE_UPDATE'
        });

        $rootScope.$emit('7T:Peripherals.StateChanged', peripheralsState);
      },

      getState: function () {
        return peripheralsState;
      },

      getCCStatus: function () {
        var canUseIgnoreConnectionOption = GravitySettings.getModuleDataKeyValue('config', 'canUseIgnoreConnectionOption');
        var ignoreConnChecker = JSON.parse(localStorage.getItem('ignoreConnectionChecker'));
        if (!canUseIgnoreConnectionOption) {
          ignoreConnChecker = false;
        }

        if (!ignoreConnChecker && !peripheralsState.connection.valid) {
          return {
            message: nabTrans.translate('notifications.device_offline', {}, true),
            type: 'error',
            notificationId: 'device_offline'
          };
        }
        return true;
      },

      getPrinterStatus: function () {
        if (peripheralsState.printer.errorMessage) {
          return {
            message: peripheralsState.printer.errorMessage,
            type: peripheralsState.printer.statusLevel
          };
        }
        return true;
      },

      validate: function () {
        var defer = $q.defer();
        var ccStatus = this.getCCStatus();
        var printerStatus = this.getPrinterStatus();

        if (ccStatus.type === 'error') {
          defer.reject(ccStatus);
        } else if (printerStatus.type?.toLowerCase() === 'error') {
          defer.reject(printerStatus);
        } else {
          defer.resolve();
        }

        return defer.promise;
      },
      setInitialPrinterState: function () {
        const self = this;
        let data;

        printService.getPrinterStatus()
          .then((response) => {
            data = {
              valid: response.valid,
              statusLevel: response.code,
              errorMessage: ''
            };
            $log.debug('[7Terminal.Peripherals] Set initial printer state succeeded.', {
              data,
              code: 'T_SET_PRINTER_STATUS_SUCCESS',
              response
            });
          })
          .catch((response) => {
            data = {
              valid: response?.valid,
              statusLevel: response?.code,
              errorMessage: response?.message
            };
            $log.error('[7Terminal.Peripherals] Set initial printer state failed.', {
              data,
              code: 'T_SET_PRINTER_STATE_ERROR'
            });
          })
          .finally(() => {
            self.updatePeripheralsState('printer', data);
          });
      },
      setPrinterListeners: function () {
        const self = this;
        self.setInitialPrinterState();

        PubSub.subscribe('Printer:statusChange', function (e, data) {
          const { setPrinterStatusData } = usePrinterStatusStore();
          const socketData = data.data;
          const socketStatus = printerMessagesParser.parsePrinterStatusResponse(socketData);
          const parsedData = {
            valid: socketStatus.valid,
            statusLevel: socketStatus.code,
            errorMessage: socketStatus.message
          };

          setPrinterStatusData(socketStatus);

          if (socketStatus.valid) {
            const showWarnings = GravitySettings.getModuleDataKeyValue('print', 'showWarnings');
            NabNotifications.closeNotificationWithId('printer_error');
            NabNotifications.closeNotificationWithId('printer_warning');

            $log.debug('[7Terminal.Peripherals] Printer status change - warning detected.', {
              data: parsedData,
              code: 'T_PRINTER_STATUS_WARNING'
            });

            if (showWarnings && socketStatus.code !== 'undefined' && socketStatus.message) {
              NabNotifications.show({
                id: 'printer_warning',
                type: socketStatus.code,
                message: `${parsedData.errorMessage}.`,
                delay: null
              });
            }
          } else {
            $log.error('[7Terminal.Peripherals] Printer status change - error detected.', {
              data: parsedData,
              code: 'T_PRINTER_LPS_STATUS_ERROR',
              upstream_message: socketStatus.message || 'Printer socket status is invalid'
            });
            printerMessagesService.handlePrinterErrorStatus();
          }

          self.updatePeripheralsState('printer', parsedData);
        });
        $rootScope.$on(
          '7T:Peripherals.Print',
          /**
         * @event "7T:Peripherals.Print"
         * @type {Object}
         *
         * @param event
         * @param {Object} eventData
         * @param {Object} eventData.data
         * @param {String} eventData.data.type
         * @param {String} eventData.data.action
         * @param {String} eventData.data.productId
         * @param {String} eventData.data.layoutName
         * @param {String} eventData.data.layout
         * @param {Object} eventData.data.data
         * @param {Object} eventData.data.additionalData
         * @param {Function} [eventData.data.resolve]
         * @param {Function} [eventData.data.reject]
         */
          function (event, eventData) {
            const productId = eventData.data.productId;
            const additionalData = {
              ...(eventData.data.additionalData || {})
            };
            printService.performPrint({
              info: {
                type: eventData.data.type,
                action: eventData.data.action,
                productId,
                layoutName: eventData.data.layoutName,
                layout: eventData.data.layout
              },
              data: eventData.data.data,
              additionalData
            }).then(() => {
              if (eventData.resolve) {
                eventData.resolve();
              }
            }).catch((error) => {
              $log.error('[7Terminal.Peripherals] Print failed.', {
                upstream_code: error.upstream_code,
                upstream_message: error.upstream_message,
                product_displayid: productId,
                code: 'T_INTEGRATOR_PRINT_REJECTED'
              });
              if (error.type === TPrintErrorCodes.T_PRINTER_STATUS_ERROR) {
                NabNotifications.show({
                  message: error.upstream_message,
                  type: 'error',
                  delay: 5000,
                  id: 'printer_error'
                });
              }
              if (eventData.reject) {
                eventData.reject(error);
              }
            });
          }
        );

        $rootScope.$on(
          '7T:Peripherals.UpdateConfig',
          /**
         * @event "7T:Peripherals.UpdateConfig"
         * @type {Object}
         *
         * @param event
         * @param {Object} eventData
         * @param {String} eventData.productId
         * @param {String} eventData.data.printService.oddsType
         * @param {Object} eventData.data.printService.ticketConfig
         * @param {Object} eventData.data.printService.ticketDetailsConfig
         * @param {Object} eventData.data.printService.printItemTemplate
         * @param {String} eventData.data.printService.printItemTemplate.section
         * @param {String} eventData.data.printService.printItemTemplate.type
         * @param {Object} eventData.data.printService.printItemTemplate.data
         */
          function (event, eventData) {
            let taxConfig;
            if (eventData.data.printService) {
              if (eventData.data.printService.taxes) {
                taxConfig = {
                  tax: {}
                };
                taxConfig.tax[eventData.productId] = eventData.data.printService.taxes
                || { payin: { value: false }, payout: { value: false } };
                printServiceApi.updateTicketConfig(taxConfig);
              }
              if (eventData.data.printService.oddsType) {
                printServiceApi.updateOddsConfig(
                  eventData.productId,
                  eventData.data.printService.oddsType
                );
              }

              if (eventData.data.printService.ticketConfig) {
                printServiceApi.updateTicketConfig(eventData.data.printService.ticketConfig);
              }

              if (eventData.data.printService.ticketDetailsConfig) {
                printServiceApi.updateTicketDetailsConfig(
                  eventData.productId,
                  eventData.data.printService.ticketDetailsConfig
                );
              }

              if (eventData.data.printService.printItemTemplate) {
                printServiceApi.extendPrintItemTemplate(
                  eventData.data.printService.printItemTemplate.section,
                  eventData.data.printService.printItemTemplate.type,
                  eventData.data.printService.printItemTemplate.data
                );
              }
            }
          }
        );
      },
      setAcceptorListeners: function () {
        PubSub.subscribe('CoinAcceptor:moneyProcessing', function (e, data) {
          acceptorsService.showMoneyNotification(data);
          if (data.type === 'moneyProcessed') {
            userFundsService.updateBalance().catch((err) => {
              $log.error('[7Terminal.Peripherals] Failed to update balance after coin money is processed.', {
                ...errorParser.parseUpstream(err),
                code: 'T_BALANCE_UPDATE_FETCH_FAILED'
              });
            });
          }
        });

        PubSub.subscribe('BillAcceptor:moneyProcessing', function (e, data) {
          acceptorsService.showMoneyNotification(data);
          if (data.type === 'moneyProcessed') {
            userFundsService.updateBalance().catch((err) => {
              $log.error('[7Terminal.Peripherals] Failed to update balance after bill money is processed.', {
                ...errorParser.parseUpstream(err),
                code: 'T_BALANCE_UPDATE_FETCH_FAILED'
              });
            });
          }
        });
      },
      setCCListeners: function () {
        var self = this;
        watch(function () {
          return connectionStatusService.isConnected.value;
        }, function (newData, oldData) {
          if (newData && !oldData) {
            // connection back on
            NabNotifications.closeNotificationWithId('device_offline');
          } else if (!newData && oldData) {
            // connection lost
            NabNotifications.show({
              message: nabTrans.translate('notifications.device_offline', {}, true),
              id: 'device_offline',
              type: 'error'
            });
          }
          self.updatePeripheralsState('connection', { valid: !!newData });
        });
      }
    };
  }
})();
