import { SevenAuthCredentials } from '@/modules/operator';

enum LoginType {
  card = 'card',
}

type GravityAuthCredentials = SevenAuthCredentials & {
  _loginType?: string,
  _pin?: string,
  _authorizationCode?: string,
};

type LoginData = {
  username: string,
  password: string,
  loginType?: LoginType,
  pin?: string,
  authorizationCode?: string,
};

export type {
  GravityAuthCredentials,
  LoginData,
  SevenAuthCredentials,
};

export { LoginType };
