import type { ScrollButtonsActionDirection } from '@nsftx/seven-components';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useSevenStore } from '@/modules/seven';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import type { GoogleAnalyticsTrackEvent } from './types';

const loadGtagScript = () => {
  const { isDevelopmentMode } = useEnvironmentHandlerStore();
  if (isDevelopmentMode()) {
    return;
  }

  const { getModuleDataKeyValue } = useGravitySettingsStore();
  const { device: { uuid: deviceUuid } } = useSevenStore();
  const trackingId = getModuleDataKeyValue<string>('analytics', 'trackingId');
  if (!trackingId) {
    return;
  }

  const gtagScript = document.createElement('script');
  const gtagConfigScript = document.createElement('script');
  gtagScript.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}`;
  gtagScript.async = true;
  gtagConfigScript.innerText = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${trackingId}');
    gtag('set', { client_id: '${deviceUuid}' });`;

  document.head.appendChild(gtagScript);
  document.head.appendChild(gtagConfigScript);
};

const init = () => {
  loadGtagScript();
};

const trackEvent = (
  eventName: GoogleAnalyticsTrackEvent['eventName'],
  eventParams: GoogleAnalyticsTrackEvent['eventParams'],
) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', eventName, eventParams);
  }
};

const trackScrollEvent = (direction: ScrollButtonsActionDirection, context: string) => {
  let event = '';
  switch (direction) {
    case 'down':
      event = 'Down click';
      break;
    case 'up':
      event = 'Up click';
      break;
    case 'top':
      event = 'Scroll to top click';
      break;
    default:
      break;
  }

  trackEvent('Scroll', {
    event,
    module: context,
  });
};

export {
  init,
  trackEvent,
  trackScrollEvent,
};
