angular.module('7Terminal.Common').service('arrayService', function arrayService() {
  this.getArray = function (num, populate) {
    var myArray = new Array(num);
    var i = 1;

    if (!populate) {
      return myArray;
    }

    for (i; i <= num; i++) {
      myArray[i - 1] = i;
    }

    return myArray;
  };

  /**
     * returns a new array without duplicates
     * */
  this.getUniqueEntries = function (data) {
    var sortedArray = data.sort(function (a, b) {
      return a - b;
    });

    return sortedArray.filter(function (item, pos) {
      // eslint-disable-next-line
      return !pos || item != data[pos - 1];
    });
  };

  /**
     * returns the number of defined members in an array
     * */
  this.defined = function (arr) {
    var amount = 0;

    arr.forEach(function (member) {
      if (member) {
        // eslint-disable-next-line
        amount++;
      }
    });

    return amount;
  };
});
