import { InternalAxiosRequestConfig } from 'axios';
import { useSevenStore } from '@/modules/seven';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useLanguageStore } from '@/common/stores/language';

let appNameHeader: string = '';

export const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const sevenStore = useSevenStore();
  const environmentHandlerStore = useEnvironmentHandlerStore();
  const languageStore = useLanguageStore();
  const language = languageStore.getCurrentLanguage();

  if (!request.headers['X-Nsft-SCD-Version']) {
    request.headers['X-Nsft-SCD-Version'] = '1';
  }

  if (language) {
    request.headers['X-Nsft-SCD-Locale'] = language;
  }

  request.headers['X-Nsft-SCD-Company-Uuid'] = sevenStore.tenant.uuid;
  request.headers['X-Nsft-SCD-Betshop-Id'] = sevenStore.betshop.id;
  request.headers['X-Nsft-SCD-Device-Id'] = sevenStore.device.uuid;
  request.headers['X-Nsft-SCD-Device-Uuid'] = sevenStore.device.uuid;
  request.headers['X-Nsft-SCD-Device-Token'] = sevenStore.device.token;
  request.headers['X-Nsft-SCD-Account-Id'] = sevenStore.tenant.uuid;

  // this is qick fix where gravity api requires uuid instead of id
  // like in like CMSService.getArticle
  if (!(request.headers['X-Nsft-SCD-Company-Id'])) {
    request.headers['X-Nsft-SCD-Company-Id'] = sevenStore.tenant.id;
  }

  // if some http request wants to delete this header from
  // request it can send false as value (like CMSService.getArticle)
  if (request.headers['X-Nsft-SCD-App-Name'] === false) {
    delete request.headers['X-Nsft-SCD-App-Name'];
  } else if (appNameHeader) {
    request.headers['X-Nsft-SCD-App-Name'] = appNameHeader;
  }

  if (environmentHandlerStore.getDc() && environmentHandlerStore.getDc() !== 'gb') {
    request.headers['X-Nsft-SCD-DC'] = environmentHandlerStore.getDc();
  }

  return request;
};

/**
 * Set app name header from outside that will be attached to
 * each HTTP request that goes to Gravity API.
 *
 * @param appName {string} - gravity app name (e.g. prva_firma_terminal)
 */
export const setAppNameHeader = (appName: string) => {
  appNameHeader = appName;
};
