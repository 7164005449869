import { SubscribeCallback } from '@nsftx/seven-gravity-gateway/master';
import { googleAnalyticsService } from '@/modules/google-analytics';
import type { AnalyticsSendEventData } from '../types';

const handleAnalyticsSendEvent: SubscribeCallback<
AnalyticsSendEventData, unknown, unknown> = (slaveData) => {
  const { type, params } = slaveData.data;
  if (type !== 'PageView') {
    return;
  }

  const { category, content } = params;
  const [{ context }] = content;

  googleAnalyticsService.trackEvent('Event_View', {
    module: category,
    event: context,
  });
};

export {
  handleAnalyticsSendEvent,
};
