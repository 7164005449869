import templateUrl from './template.html';

(function () {
  angular.module('7Terminal.Common')
    .component('gamesScheduleTab', {
      templateUrl: templateUrl,
      transclude: true,
      bindings: {
        game: '=',
        offer: '<'
      },
      controller: function () {
        var self = this;
        var offer;
        var page = 0;

        self.maxPages = function () {
          let futureBetsLength = self.game.offer.length;
          let numberOfPages = 0;

          while (futureBetsLength > 5) {
            futureBetsLength -= 5;
            numberOfPages += 1;
          }

          return numberOfPages;
        };

        self.pagerNext = function () {
          if (self.visualOffer.length === 1) {
            page = 0;
          } else {
            page = page === self.maxPages() ? 0 : page + 1;
          }
          self.visualOffer = offer.slice(page * 5);
        };

        self.pagerPrevious = function () {
          page = page === 0 ? self.maxPages() : page - 1;
          self.visualOffer = offer.slice(page * 5);
        };

        self.$onInit = function () {
          page = 0;
          offer = self.offer;
          self.visualOffer = offer;
        };

        self.$onChanges = function () {
          offer = self.offer;
          self.visualOffer = offer;
        };
      }
    });
})();
