import { MaybeRef } from 'vue';
import { NotificationVariant } from '@nsftx/seven-components';
import { TNotificationTypeEnum } from '../../stores/notifications/enums';
import { TNotificationConfig, TNotificationMessage } from '../../stores/notifications/types';

export const mapNotificationVariant = (
  type: TNotificationTypeEnum,
): NotificationVariant | undefined => {
  const notificationTypes = {
    [TNotificationTypeEnum.error]: NotificationVariant.Error,
    [TNotificationTypeEnum.warning]: NotificationVariant.Warning,
    [TNotificationTypeEnum.info]: NotificationVariant.Informational,
    [TNotificationTypeEnum.success]: NotificationVariant.Success,
  };

  return notificationTypes[type];
};

export const isTNotificationMessage = (
  message: TNotificationMessage | MaybeRef<string>,
): message is TNotificationMessage => (message as TNotificationMessage).content !== undefined;

export const mapNotificationMessage = (
  message: TNotificationConfig['message'],
): Array<MaybeRef<string>> => {
  if (Array.isArray(message)) {
    return message.map((msg) => {
      if (isTNotificationMessage(msg)) {
        return msg.content;
      }
      return msg;
    });
  }

  return [message];
};
