import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { EnvResponse } from './types';

const useENVStore = defineStore('ENV', () => {
  const data = ref<EnvResponse>({
    api: {
      customerCards: '',
      oktopay: '',
      seven: { base: '' },
      ngs: {
        london: 'test',
      },
    },
    build: '',
    gravityAuth: {
      url: '',
    },
    reporting: {
      url: '',
    },
    settings: {
      billAcceptorService: {
        url: '',
      },
      coinAcceptorService: {
        url: '',
      },
      printService: {
        spsUrl: '',
        url: '',
      },
      fiscalService: {
        url: '',
      },
    },
    taxAuth: '',
    translations: {
      gravityUrl: '',
    },
    version: '',
    client_version: '',
    type: '',
    analytics: {
      trackingId: '',
    },
  });

  return {
    data: data.value,
    setData(newData: EnvResponse) {
      Object.assign(this.data, newData);
    },
  };
});

export {
  useENVStore,
};
