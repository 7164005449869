/* eslint-disable angular/document-service */
import 'socket.io-client';
import 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui-themes/themes/dark-hive/jquery-ui.css';
import 'moment';
import 'pako';
import 'underscore';
import angular from 'angular';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import '@nsftx/seven-ng-print-service/dist/ng-print-service';
import bootstrapModule from './src/modules/bootstrap';
import './app';
import './src/layouts/defaultLayoutComponent';
import './src/games/results/gamesResultsController';
import './src/games/results/racer_games/racerGameResultsController';
import './src/games/results/racer_games/racerGameResultsDrv';

/* eslint-disable angular/window-service */
// make sure the DOM is fully loaded before bootstrapping angular app
angular.element(document).ready(function () {
  // eslint-disable-next-line
  window._7Loader.setConfig({
    blackList: [
      'http://127.0.0.1:5051/get_status', // device connection checker
      'http://127.0.0.1:8078/st', // bill acceptor start&stop requests
      'http://127.0.0.1:8079/st', // coin acceptor start&stop requests
      'http://127.0.0.1:8080', // print service
      'api/terminal/funds.json', // device balance
      'http://127.0.0.1:8082/expose_app_window', // tab change dispatch route
      'http://127.0.0.1:8082/publish',
      'http://127.0.0.1:8081/user_login', // active account dispatch route
      'http://127.0.0.1:8070/socket.io/', // local pub sub
      ':8008', // SCM port
      ':8443', // SCM CF port
      '.html', // html files load
      'distribution-2.neosoft.ba',
      '/events.json',
      'distribution-2.neosoft.ba', // sportsbook socket distribution
      'sportbook-router', // sportsbook socket router
      'sportbook-public', // one more sportbook router
      'sentry.io',
      'maintenance/api/v1/schedule',
      'http://127.0.0.1:8071/publish',
      'web/profile/balance.json',
      'https://svn-payment-plugin-oktopay.staging.de-3.nsoft.cloud/tenants',
      'https://svn-payment-plugin-oktopay.de-3.nsoft.cloud/tenants',
      'http://127.0.0.1:8081/peripherals_initialization_status',
      'https://int-payment-plugin-superbet.staging.de-3.nsoft.cloud/api/v1/withdraw/status',
      'https://int-payment-plugin-superbet.de-3.nsoft.cloud/api/v1/withdraw/status',
      'https://int-grape.staging.de-3.nsoft.cloud',
      'https://int-grape.de-2.nsoft.cloud',
      'https://int-woodpecker-http-collector.staging.de-3.nsoft.cloud',
      'https://int-woodpecker-http-collector.de-3.nsoft.cloud',
      'http://127.0.0.1:8083/init'
    ]
  });

  localStorage.removeItem('user.token');
  angular.bootstrap(document, [bootstrapModule]);
});
