import { range, each } from 'underscore';
import templateUrl from './forecastBettingArea.html';

(function () {
  angular.module('terminalApp.nabHorseRacesUi')
    .component('horseForecastBettingArea', {
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        $state,
        nabMessaging,
        HorseRacesUiService
      ) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); var // switch virtual and regular horse races
          HorseRaces = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = HorseRaces.getBettingSvc();
        var self = this;

        this.$onInit = function () {
          this.racers = range(1, HorseRaces.numberOfRacers + 1);
          this.activeGame = HorseRaces;
          this.horseRacesUISvc = HorseRacesUiService;
          this.createRacers();
          this.horseRacesUISvc.disableReverseForecast = false;
          this.horseRacesUISvc.disableForecast = false;
        };

        /** Create objects of racers for forecast and reverse forecast
                 * (set them unchecked)
                 * and  racers array* */

        this.createRacers = function () {
          HorseRacesUiService.clearForecastActiveState();
          each(this.racers, function (position) {
            self.horseRacesUISvc.forecastFirstPlace['r' + (position)] = false;
            self.horseRacesUISvc.forecastSecondPlace['r' + (position)] = false;
            self.horseRacesUISvc.forecastAny['r' + (position)] = false;
          });
        };

        // listen for config changes
        nabMessaging.subscribe('config:gameConfigChanged', function (event, game) {
          if (game == 'HorseRaces' || game == 'VirtualHorseRaces') {
            var active_game = SevenGamesSvc.getGame(game);
            if (self.racers.length != active_game.numberOfRacers) {
              self.racers = range(1, active_game.numberOfRacers + 1);
              self.createRacers();
            }
          }
        });

        /**
                 * Check which racer is checked in forecast bet
                 * push them in arrays depends on place
                 * r - racer
                 * * */

        this.checkForecastFirstPlace = function (racer) {
          this.horseRacesUISvc.forecastFirstPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.forecastFirstPlace[racer] = !this.horseRacesUISvc.forecastFirstPlace[racer]; // toggle racer status

          each(this.horseRacesUISvc.forecastFirstPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.forecastFirstPlaces.push(key.substring(1));
            }
          });

          this.validateForecast();
        };

        /** Check second place racer in forecast bet
                 * @param - Number - racer postion* */

        this.checkForecastSecondPlace = function (racer) {
          this.horseRacesUISvc.forecastSecondPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.forecastSecondPlace[racer] = !this.horseRacesUISvc.forecastSecondPlace[racer]; // toggle racer status

          each(this.horseRacesUISvc.forecastSecondPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.forecastSecondPlaces.push(key.substring(1));
            }
          });

          this.validateForecast();
        };

        /**
                 * Check if there are same numbers in combination
                 * hide any bet if anything is checked
                 * * */

        this.validateForecast = function () {
          for (var i in this.horseRacesUISvc.forecastFirstPlace) {
            if ((this.horseRacesUISvc.forecastFirstPlace[i] && this.horseRacesUISvc.forecastSecondPlace[i])
                            && (this.horseRacesUISvc.forecastFirstPlaces.length < 2 || this.horseRacesUISvc.forecastSecondPlaces.length < 2)) {
              this.horseRacesUISvc.validForecast = false;
              flashButton.flash = false;
              return;
            }
          }

          this.horseRacesUISvc.disableReverseForecast = this.horseRacesUISvc.forecastFirstPlaces.length > 0 || this.horseRacesUISvc.forecastSecondPlaces.length > 0;
          flashButton.flash = !(this.horseRacesUISvc.forecastFirstPlaces.length < 1 || this.horseRacesUISvc.forecastSecondPlaces.length < 1);
          this.horseRacesUISvc.validForecast = true;
        };

        this.checkReverseForecastBet = function (racer) {
          this.horseRacesUISvc.reverseForecastPlaces = [];
          BettingSvc.clearAll();

          this.horseRacesUISvc.forecastAny[racer] = !this.horseRacesUISvc.forecastAny[racer]; // toggle racer status

          each(this.horseRacesUISvc.forecastAny, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.horseRacesUISvc.reverseForecastPlaces.push(key.substring(1));
            }
          });

          this.validateReverseForecastBet();
        };

        /**
                 * Disable forecast if any bet is checked
                 * flash add button if there are two racers
                 *
                 * * */

        this.validateReverseForecastBet = function () {
          this.horseRacesUISvc.disableForecast = this.horseRacesUISvc.reverseForecastPlaces.length > 0;

          if (this.horseRacesUISvc.reverseForecastPlaces.length < 2) {
            flashButton.flash = false;
            this.horseRacesUISvc.validReverseForecast = false;
          } else {
            flashButton.flash = true;
            this.horseRacesUISvc.validReverseForecast = true;
          }
        };
      }

    });
})();
