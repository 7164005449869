import i18n from '@/plugins/i18n';
import BaseError from '@/common/errors/BaseError';
import { getService } from '@/common/services/ngBridge';
import { logService } from '@/common/services/logger';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { TicketInstance, TicketActionBetslipResponse } from '@/modules/seven-betslip-api';
import { SLAVE_SHOWN_EVENT_ID, integratorService, slaveShownEventPubSub } from '@/modules/integrator';
import { isTicketCheckAbortedError } from '@/modules/ticket-check';
import { createTicketMetaData } from '../helpers';
import { validateCancel } from './ticketValidationService';
import ticketManager from '../ticketManager';

const LOG_PREFIX = '[cancelService]';
const { t } = i18n.global;
const CANCEL_ERR_NOTIF = 'cancel_notif_error';

const handleCancelFromGameFrame = (ticket: TicketInstance) => {
  const switchView: any = getService('switchView');
  const productId = ticket.getProductDisplayId();
  const notificationsStore = useNotificationsStore();

  logService.info(`${LOG_PREFIX} Ticket cancel sent to iframe to be processed.`, {
    ticket,
    product_displayid: ticket.getProductDisplayId(),
    ticket_code: ticket.getDisplayId(),
    request_id: ticket.getRequestUuid(),
    code: 'T_TICKET_IFRAME_CANCEL',
  });

  const onSlaveLoadedListener = slaveShownEventPubSub.subscribe(SLAVE_SHOWN_EVENT_ID, () => {
    integratorService.sendEvent('Tickets.Cancel', {
      data: {
        ticket: ticket.ticket,
      },
      context: {
        productId,
      },
    });
    slaveShownEventPubSub.unsubscribe(onSlaveLoadedListener);
  });
  switchView.selectService(productId, true, {
    gameId: productId,
    genericId: productId,
  });

  notificationsStore.closeNotificationWithId('ticket.pending_action');
};

const showCancelFailedNotification = (error: Error) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('ticket.pending_action');

  if (!isTicketCheckAbortedError(error)) {
    notificationsStore.show({
      message: error.message,
      delay: 3000,
      type: TNotificationTypeEnum.warning,
      id: CANCEL_ERR_NOTIF,
    });
  }
};

const cancelTicket = (ticket: TicketInstance) => new Promise<
{
  response?: TicketActionBetslipResponse,
  localTicket: TicketInstance,
}>((resolve, reject) => {
  const productDisplayId = ticket.getProductDisplayId();
  const notificationsStore = useNotificationsStore();
  const gravitySettingsStore = useGravitySettingsStore();
  const ticketSettings = gravitySettingsStore.getByKey<{
    cancel?: {
      fromGameFrame: boolean,
    }
  }>(`module.${productDisplayId}.tickets`);

  validateCancel(ticket)
    .then(() => {
      if (ticketSettings?.cancel?.fromGameFrame) {
        handleCancelFromGameFrame(ticket);
        resolve({
          localTicket: ticket,
        });
        return;
      }

      const metadata = createTicketMetaData(
        productDisplayId,
        ticket.getRequestUuid(),
      );

      notificationsStore.show({
        message: t('ticket.pending_action'),
        type: TNotificationTypeEnum.info,
        id: 'ticket.pending_action',
      });
      logService.info(`${LOG_PREFIX} Sending ticket on cancel.`, {
        ticket,
        product_displayid: productDisplayId,
        ticket_code: ticket.getDisplayId(),
        request_id: ticket.getRequestUuid(),
        code: 'T_TICKET_CANCEL_SEND',
      });
      ticketManager.cancelTicket(ticket, metadata)
        .then((res) => {
          resolve(res);
        })
        .catch((cancelError) => {
          ticket.setPin('');
          showCancelFailedNotification(cancelError);
          reject(cancelError);
        });
    }).catch((err) => {
      const code = 'T_TICKET_CANCEL_VALIDATE_ERROR';
      const formattedError = new BaseError(err.message, code, { cause: err });
      showCancelFailedNotification(err);

      logService.error(`${LOG_PREFIX} BeforeTicketCancel error detected.`, {
        code,
        upstream_message: err.message,
        upstream_code: err.code,
        product_displayid: productDisplayId,
        ticket_code: ticket.getDisplayId(),
        request_id: ticket.getRequestUuid(),
      });

      reject(formattedError);
    });
});

export {
  cancelTicket,
};
