import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "t-footer-button sc-ml-1 sc-mr-1" }
const _hoisted_2 = { class: "sc-ml-3 footer-button-label" }

import { computed } from 'vue';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
// eslint-disable-next-line import/no-cycle
import { setIsModalShown, getIsModalShown } from '@/modules/lobby/lobbyService';
import { vGaTrackEvent } from '@/modules/google-analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'LobbyFooterIcon',
  setup(__props) {

const { t } = i18n.global;

const openLobby = () => {
  setIsModalShown(true);
};

const isActive = computed(() => getIsModalShown());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
      width: "100%",
      "prepend-icon": "globe-a2",
      class: "sc-mr-1 sc-my-1",
      variant: isActive.value ? _unref(ButtonVariant).Brand : _unref(ButtonVariant).Secondary,
      height: "60px",
      onClick: openLobby
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('terminal_lobby_footer')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["variant"])), [
      [_unref(vGaTrackEvent), {
        eventName: 'Open_Module',
        eventParams: { 'module': 'Lobby', 'source': 'Footer' },
      }]
    ])
  ]))
}
}

})