import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row-reverse" }
const _hoisted_2 = { class: "t-footer-button sc-my-1" }
const _hoisted_3 = { class: "sc-ml-3 footer-button-label" }

import { ref } from 'vue';
import { SCButton } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useOddTypeStore } from '@/common/stores/odd-type';
import { useLanguageStore } from '@/common/stores/language';
import OddTypePicker from '@/modules/settings/OddTypePicker.vue';
import LanguagePicker from '@/modules/settings/LanguagePicker.vue';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import { vGaTrackEvent } from '@/modules/google-analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'TFooterActionsRight',
  setup(__props) {

const { t } = i18n.global;
const isOddTypePickerModalOpen = ref(false);
const isLangPickerModalOpen = ref(false);

const oddTypeStore = useOddTypeStore();
const langStore = useLanguageStore();

const showOddsTypePicker = () => {
  isOddTypePickerModalOpen.value = true;
};

const showLangPicker = () => {
  isLangPickerModalOpen.value = true;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(oddTypeStore).shouldShowOddsPicker())
        ? _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
            key: 0,
            width: "100%",
            "prepend-icon": "odds-on-coupon",
            active: isOddTypePickerModalOpen.value,
            height: "60px",
            onClick: showOddsTypePicker
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)(_unref(oddTypeStore).activeOddType.name)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["active"])), [
            [_unref(vGaTrackEvent), {
          eventName: 'Open_Module',
          eventParams: { module: 'Odds picker', source: 'Footer' },
        }]
          ])
        : _createCommentVNode("v-if", true)
    ]),
    (!_unref(langStore).shouldHideLangPicker())
      ? _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
          key: 0,
          class: "sc-my-1 sc-mr-1",
          active: isLangPickerModalOpen.value,
          width: "64px",
          height: "60px",
          onClick: showLangPicker
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(["lang-flag", `flag flag-${_unref(langStore).currentLang?.iso3?.toUpperCase()}`])
            }, null, 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["active"])), [
          [_unref(vGaTrackEvent), {
        eventName: 'Open_Module',
        eventParams: { module: 'Language modal', source: 'Footer' },
      }]
        ])
      : _createCommentVNode("v-if", true),
    _createVNode(WidgetsRenderer, { position: "footerRight" }),
    _createVNode(OddTypePicker, {
      "model-value": isOddTypePickerModalOpen.value,
      "onUpdate:modalOpenValue": _cache[0] || (_cache[0] = (value) => isOddTypePickerModalOpen.value = value)
    }, null, 8 /* PROPS */, ["model-value"]),
    _createVNode(LanguagePicker, {
      "model-value": isLangPickerModalOpen.value,
      "onUpdate:modalOpenValue": _cache[1] || (_cache[1] = (value) => isLangPickerModalOpen.value = value)
    }, null, 8 /* PROPS */, ["model-value"])
  ]))
}
}

})