import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { SevenConfigSettings } from './types';
import type { SevenTicketActions } from '../types';

const useConfigSettingsStore = defineStore('configSettingsStore', () => {
  const data = ref<SevenConfigSettings>({
    aggregatorSettings: {
      anonymousPayout: true,
      maxPayout: 0,
    },
    availableLanguages: {
      value: [],
    },
    backOfficeInactivityTime: {
      value: 0,
    },
    dateFormat: {
      value: '',
    },
    disableAnonymousTicketPayout: {
      value: false,
    },
    terminalAdminTicketHistoryRange: {
      value: 0,
    },
    infoPoint: {
      value: false,
    },
    moneyDenominations: {
      value: [],
    },
    numbersFormat: {
      value: '',
      decimalSeparator: null,
      thousandsSeparator: null,
    },
    scrollButtons: {
      value: true,
    },
    terminalCursor: {
      value: false,
    },
    terminalInactivityTime: {
      value: 0,
    },
    voucherActivationConfirmation: {
      value: true,
    },
    userTypes: {
      anonymous: {
        value: true,
      },
      player: {
        value: true,
      },
    },
    printerPaperLowEffects: {
      disableTicketPayin: false,
      disableMoneyAcceptance: false,
    },
    taxAuthority: {
      value: null,
    },
  });

  const ticketActions = ref<SevenTicketActions>({
    pinRequred: false,
  });

  const setData = (applicationSettings: SevenConfigSettings): void => {
    Object.assign(data.value, applicationSettings);
  };

  const getData = (): SevenConfigSettings => data.value;

  return {
    data: data.value,
    ticketActions,
    setData,
    getData,
  };
});

export {
  useConfigSettingsStore,
};
