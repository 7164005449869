<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import {
  SCInlineNotification, SCButton, ButtonVariant, NotificationVariant,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { getService } from '@/common/services/ngBridge';
import TModal from '@/common/components/TModal.vue';
import * as maintenanceService from './services/maintenanceService';
import { useMaintenanceStore } from './maintenanceStore';

// Constants
const { t } = i18n.global;
const maintenanceStore = useMaintenanceStore();
const { isGameInMaintenanceState } = maintenanceStore;
const {
  isMaintenanceActive,
  maintenanceData,
} = storeToRefs(maintenanceStore);
const route = useRoute();

const GAME_PATH = '/games';
const isGameDisabled = ref(false);
const showAlert = computed(() => !!maintenanceData?.value?.scheduleMessage?.body);
const showModal = computed(() => isMaintenanceActive.value && isGameDisabled.value);

const handleModalShowOnRouteChange = () => {
  watch([() => route.fullPath, maintenanceData], ([fullPath]) => {
    if (!isMaintenanceActive.value || !fullPath.startsWith(GAME_PATH)) {
      isGameDisabled.value = false;
      return;
    }

    const { gameId } = route.query;
    isGameDisabled.value = isGameInMaintenanceState(gameId?.toString() || '');
  }, { immediate: true });
};

const navigateToHome = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $state: any = getService('$state');
  $state.go('app.home');
};

handleModalShowOnRouteChange();
</script>

<template>
  <div
    v-if="showModal"
    class="maintenance-modal"
  >
    <TModal
      v-model="showModal"
      :modal-id="maintenanceService.MAINTENANCE_MODAL_ID"
      prevent-close
      width="720px"
    >
      <template #title>
        {{ t('main.maintenance') }}
      </template>
      <template #content>
        <div class="maintenance-modal__content w-100">
          <SCInlineNotification
            v-model="showAlert"
            :variant="NotificationVariant.Warning"
          >
            <template #text>
              <span class="d-block sc-py-4 sc-pr-6">
                {{ maintenanceData?.scheduleMessage?.body }}
              </span>
            </template>
          </SCInlineNotification>
          <div
            v-if="maintenanceData?.disabledProducts?.length"
            class="maintenance-modal__content__products"
          >
            <p class="sc-mt-7">
              {{ t('main.affected_products') }}:
            </p>
            <div class="maintenance-modal__content__products__items">
              <div
                v-for="(product, index) in maintenanceData.disabledProducts"
                :key="index"
                class="maintenance-modal__content__products__items__item sc-btn w-100"
              >
                {{ product }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <SCButton
          :variant="ButtonVariant.Primary"
          width="100%"
          @click="navigateToHome"
        >
          {{ t('button_back_to_home') }}
        </SCButton>
      </template>
    </TModal>
  </div>
</template>

<style lang="scss" scoped>
  .maintenance-modal {
    &__content {
      margin-top: 10px;

      &__products {
        --product-item-width: 330px;

        &__items {
          display: grid;
          grid-gap: 8px;
          grid-template-columns: repeat(auto-fit, minmax(var(--product-item-width), 1fr));
        }
      }

      // reboot.scss from bootstrap is setting margin for p
      &:deep(.sc-alert__text) {
        margin: 0;
      }
    }
  }
</style>
