import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sc-mr-6 d-flex"
}
const _hoisted_2 = { class: "sc-p-6 qr-wrapper" }
const _hoisted_3 = { class: "sc-text-secondary-1 sc-text-caption-2 qr-descritpion" }

import PubSub from 'pubsub-js';
import { onUnmounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import {
  SCInput,
  SCButton,
  ButtonVariant,
  SCKeyboard,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { authService, useAuthStore } from '@/modules/auth';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import TModal from '@/common/components/TModal.vue';
import QrCode from '@/common/components/QrCode.vue';
import { logService } from '@/common/services/logger';
import TPasswordInput from '@/common/components/TPasswordInput.vue';
import { googleAnalyticsService } from '@/modules/google-analytics';
import { ACTIVITY_SESSION_END_EVENT_ID, activitySessionEndEventPubSub } from '@/common/services/idle/activitySessionEndEvent';


export default /*@__PURE__*/_defineComponent({
  __name: 'TLogin',
  setup(__props) {

const authStore = useAuthStore();
const isLoginInProgress = ref(false);
const enteredUsername = ref('');
const enteredPassword = ref('');
const usernameInputChanged = ref(false);
const passwordInputChanged = ref(false);
const { preventLoginModalClose, isLoginModalOpened } = storeToRefs(authStore);
const showUsernameError = computed(() => !enteredUsername.value && usernameInputChanged.value);
const showPasswordError = computed(() => !enteredPassword.value && passwordInputChanged.value);
const isFormValid = computed(() => (!showUsernameError.value && !showPasswordError.value)
    && (usernameInputChanged.value && passwordInputChanged.value));
const showQR = ref(authService.isQrCodeEnabled());
const modalWidth = computed(() => (showQR.value ? '720px' : '480px'));
const contentClass = computed(() => (showQR.value ? '' : 'login-content'));
const showKeyboard = ref(false);

const { t } = i18n.global;

const notificationsStore = useNotificationsStore();
const ERRORS = [
  {
    status: 401,
    text: t('user.error_wrong_credentials'),
  },
  {
    status: 402,
    text: t('notifications.permissions_forbidden'),
  },
  {
    status: 403,
    text: t('notifications.request_too_long'),
  },
];

const inactivityTimeEndListener = activitySessionEndEventPubSub.subscribe(
  ACTIVITY_SESSION_END_EVENT_ID,
  () => {
    if (!preventLoginModalClose.value) {
      isLoginModalOpened.value = false;
    }
  },
);

onUnmounted(() => {
  if (inactivityTimeEndListener) {
    PubSub.unsubscribe(inactivityTimeEndListener);
  }
});

const onLoginClicked = () => {
  notificationsStore.closeNotificationWithId('login-err');
  isLoginInProgress.value = true;
  authService.login({
    username: enteredUsername.value,
    password: enteredPassword.value,
  }).then((response) => {
    isLoginInProgress.value = false;
    authService.switchToTBO(response.data);
  }).catch((err) => {
    isLoginInProgress.value = false;
    const fondErr = ERRORS.find((error) => error.status.toString() === err
      .response?.status?.toString());
    const message = fondErr?.text || err.message || err.response?.data?.message;
    logService.warn('[TLogin] Login failed', {
      upstream_message: message,
      err,
      code: 'T_LOGIN_FAILED',
    });
    googleAnalyticsService.trackEvent('Terminal_Backoffice', {
      event: 'TBO Login failed',
    });
    notificationsStore.show({
      id: 'login-err',
      message,
      type: TNotificationTypeEnum.error,
      delay: 6000,
    });
  });
};

const cleanUpForm = (value: boolean) => {
  enteredUsername.value = '';
  enteredPassword.value = '';
  usernameInputChanged.value = false;
  passwordInputChanged.value = false;
  isLoginInProgress.value = false;
  isLoginModalOpened.value = value;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: _unref(isLoginModalOpened),
    "onUpdate:modelValue": [
      _cache[7] || (_cache[7] = ($event: any) => (_isRef(isLoginModalOpened) ? (isLoginModalOpened).value = $event : null)),
      cleanUpForm
    ],
    "with-close-icon": "",
    "modal-id": "t-login",
    width: modalWidth.value,
    "prevent-close": _unref(preventLoginModalClose),
    top: "88px"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('main.login')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      (showQR.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(QrCode, {
                config: _unref(authService).getQrCodeData()
              }, null, 8 /* PROPS */, ["config"]),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)('auth_qr_scan')), 1 /* TEXT */)
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("form", {
        class: _normalizeClass(["w-100 d-flex flex-column align-items-start", contentClass.value]),
        onSubmit: _withModifiers(() => {}, ["prevent"])
      }, [
        _createVNode(_unref(SCKeyboard), {
          modelValue: enteredUsername.value,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((enteredUsername).value = $event)),
            _cache[4] || (_cache[4] = ($event: any) => (usernameInputChanged.value = true))
          ],
          show: showKeyboard.value,
          "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((showKeyboard).value = $event)),
          class: "w-100"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SCInput), {
              modelValue: enteredUsername.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((enteredUsername).value = $event)),
              label: _unref(t)('user.username'),
              error: showUsernameError.value,
              hint: showUsernameError.value ? _unref(t)('validation_username_required') : '',
              block: "",
              "data-testid": "inputUsername",
              onInput: _cache[1] || (_cache[1] = ($event: any) => (usernameInputChanged.value = true))
            }, null, 8 /* PROPS */, ["modelValue", "label", "error", "hint"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "show"]),
        _createVNode(TPasswordInput, {
          modelValue: enteredPassword.value,
          "onUpdate:modelValue": [
            _cache[5] || (_cache[5] = ($event: any) => ((enteredPassword).value = $event)),
            _cache[6] || (_cache[6] = ($event: any) => (passwordInputChanged.value = true))
          ],
          class: "sc-mt-2",
          label: _unref(t)('main.password'),
          error: showPasswordError.value,
          hint: showPasswordError.value ? _unref(t)('validation_password_required') : '',
          "data-testid": "inputPassword"
        }, null, 8 /* PROPS */, ["modelValue", "label", "error", "hint"]),
        _createVNode(_unref(SCButton), {
          variant: _unref(ButtonVariant).Primary,
          width: "100%",
          disabled: !isFormValid.value || isLoginInProgress.value,
          loading: isLoginInProgress.value,
          type: "button",
          "data-testid": "loginButton",
          class: "mt-auto",
          onClick: onLoginClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('main.log_in')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["variant", "disabled", "loading"])
      ], 34 /* CLASS, NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "width", "prevent-close"]))
}
}

})