import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-100 w-100" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center w-100 h-100 flex-wrap sc-pl-5" }
const _hoisted_3 = {
  class: "h-100",
  src: ""
}
const _hoisted_4 = { class: "sc-pl-3 sc-py-3 d-flex h-100" }
const _hoisted_5 = { class: "sc-pr-5 text-right d-flex flex-column justify-content-center" }
const _hoisted_6 = { class: "font-weight-bold" }

import { SCButton, SCAppBar, ButtonVariant } from '@nsftx/seven-components';
import { vLoadImage } from '@/common/directives/LoadImage';
import resourcesService from '@/modules/cms/resources/resourcesService';
import operatorService from '@/modules/operator/operatorService';
import { OperatorData } from '@/modules/operator/types';
import { vGaTrackEvent } from '@/modules/google-analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'TboHeader',
  setup(__props) {

const user: OperatorData = <OperatorData>operatorService.data;
const imgSrc: string = resourcesService.getS3LogoUrl();
const errImgSrc: string = resourcesService.getLogoUrl();

const logout = () => operatorService.logout();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SCAppBar), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("img", _hoisted_3, null, 512 /* NEED_PATCH */), [
            [_unref(vLoadImage), { imgSrc: _unref(imgSrc), errImgSrc: _unref(errImgSrc) }]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.type_operator')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(user).username), 1 /* TEXT */)
            ]),
            _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
              variant: _unref(ButtonVariant).Primary,
              class: "tbo-header__logout-btn h-100 text-center",
              onClick: logout
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('main.log_out')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["variant"])), [
              [_unref(vGaTrackEvent), {
              eventName: 'Terminal_Backoffice',
              eventParams: { event: 'Exit TBO' },
            }]
            ])
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})