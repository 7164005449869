import angular from 'angular';
import runBlock from './run';
import SEVENGcmSocketSvcService from './gcmSocketSvc';

const moduleName = angular.module('7Terminal.Ngs', [])
  .service('SEVENGcmSocketSvc', SEVENGcmSocketSvcService)
  .run(runBlock)
  .name;

export default moduleName;
