<script setup lang="ts">
import {
  computed, onMounted, onUnmounted, ref,
} from 'vue';
import { TimerProgression } from '../types';

type BarPosition = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

interface TimerBarProps {
  time: number;
  orientation?: string;
  transitionDelay?: number;
  position: BarPosition;
  loadingColor: string;
  bgColor: string;
  onTimeoutCallback?: (() => void);
  progressionStyle?: TimerProgression;
}
const props = withDefaults(defineProps<TimerBarProps>(), {
  orientation: 'vertical',
  transitionDelay: 0,
  onTimeoutCallback: undefined,
  progressionStyle: TimerProgression.DESC,
});

const startProgress = props.progressionStyle === TimerProgression.DESC ? '0' : '100%';
const endProgress = props.progressionStyle === TimerProgression.DESC ? '100%' : '0';
const progress = ref(startProgress);
const transition = ref(
  `${props.orientation === 'horizontal' ? 'width' : 'height'} ${props.time}ms linear ${props.transitionDelay}ms`,
);
const timer = ref<ReturnType<typeof setTimeout>>();

const verticalBarStyle = computed(() => ({
  height: progress.value,
  transition: transition.value,
  background: props.loadingColor,
  ...props.position,
}));

const horizontalBarStyle = computed(() => ({
  width: progress.value,
  transition: transition.value,
  background: props.loadingColor,
  ...props.position,
}));

const fullBarStyle = {
  background: props.bgColor,
  ...props.position,
};

const progressBarStyle = props.orientation === 'horizontal' ? horizontalBarStyle : verticalBarStyle;

const setTimer = () => {
  if (props.onTimeoutCallback) {
    timer.value = setTimeout(() => {
      props.onTimeoutCallback?.();
    }, props.time);
  }
};

const resetTimerAnimation = () => {
  // reset progress to 0 without animation
  progress.value = startProgress;
  transition.value = 'none';

  clearTimeout(timer.value);
  setTimer();

  setTimeout(() => {
    // start animation again
    progress.value = endProgress;
    // set transition to starting value
    transition.value = `${props.orientation === 'horizontal' ? 'width' : 'height'} ${props.time}ms linear ${props.transitionDelay}ms`;
  }, 100);
};

onMounted(() => {
  resetTimerAnimation();
});

onUnmounted(() => {
  clearTimeout(timer.value);
});

defineExpose({
  resetTimerAnimation,
});
</script>

<template>
  <div
    class="loading-bar"
    :class="[orientation]"
    :style="fullBarStyle"
  />
  <div
    :class="['loading-progress-bar-' + orientation]"
    :style="progressBarStyle"
  />
</template>

<style scoped lang="scss">
.loading-bar {
  position: absolute;
  border-radius: 3px;

  &.vertical {
    height: 100%;
    width: 6px;
  }

  &.horizontal {
    width: 100%;
    height: 6px;
  }
}
.loading-progress-bar-vertical {
  position: absolute;
  width: 6px;
  overflow: hidden;
  border-radius: 3px;
  height: 100%;
  transform-origin: top;
}

.loading-progress-bar-horizontal {
  position: absolute;
  height: 6px;
  overflow: hidden;
}
</style>
