import { InternalAxiosRequestConfig } from 'axios';
import { localAppConfigService } from '@/common/services/local-app-config';
import { useSevenStore } from '@/modules/seven';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useLanguageStore } from '@/common/stores/language';

function isAnonymousApi(url: string): boolean {
  return /api\/terminal/.test(url);
}

export const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const sevenStore = useSevenStore();
  const environmentHandlerStore = useEnvironmentHandlerStore();
  const languageStore = useLanguageStore();

  request.headers['HTTP-X-NAB-COMPANY-ID'] = sevenStore.tenant.id;
  request.headers['HTTP-X-NAB-DEVICE-UUID'] = sevenStore.device.uuid;
  request.headers['HTTP-X-NAB-DP'] = environmentHandlerStore.getState('activeApp');
  request.headers['HTTP-X-NAB-BETSHOP-ID'] = sevenStore.betshop.id;
  request.headers['X-NSFT-SEVEN-COMPANY-UUID'] = sevenStore.tenant.uuid;
  request.headers['SEVEN-LOCALE'] = languageStore.getCurrentLanguage();

  if (request.url && isAnonymousApi(request.url)) {
    request.headers.Authorization = `Basic ${window.btoa(
      `${localAppConfigService.data.appConfig.terminalAccount.uuid}:${localAppConfigService.data.appConfig.terminalAccount.password}`,
    )}`;
  }

  return request;
};
