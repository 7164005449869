import idleService from '@/common/services/idle';

/**
  * @ngdoc directive
  * @name 7Terminal.Common.directive:timeoutIndicator
  * @restrict 'A'
  * @element div
  * @scope
  * priority: 0
  * @description
  * Executes callback when timer runs out (Similar to activity indicator. The difference
  * is that activity indicator will reset on click, this one will not - nothing will reset timeout to zero.)
  *
  * @param {String} timeoutIndicatorListenerName Name of listener which uses timeout indicator
  * @param {Boolean} timeoutIndicatorActive Show/hide indicator track
  * @param {Number} timeoutIndicatorTreshold Time countdown in milliseconds
  * @param {Function} timeoutIndicatorEndActivity Callback to execute when X amount of time is finished
  * @param {Number} timeoutIndicatorTransitionDelay "Tolerance" time, before starting timer
  *
  * @example
  *  <div timeout-indicator
  *       timeout-indicator-listener-name="'terminal'"
  *       timeout-indicator-active="true"
  *       timeout-indicator-treshold="60000"
  *       timeout-indicator-end-activity="$ctrl.timeoutIndicatorEndActivity"
  *       timeout-indicator-transition-delay="5000">
  *  </div>
* */

/**
 *
 * @ngInject
 */
function timeoutIndicator(
  $timeout,
) {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      timeoutIndicatorListenerName: '<',
      timeoutIndicatorActive: '=',
      timeoutIndicatorTreshold: '=',
      timeoutIndicatorEndActivity: '=',
      timeoutIndicatorTransitionDelay: '<'
    },
    template: '<div ng-show="timeoutIndicatorActive" class="activity-indicator"></div>',
    link: function (scope, elem) {
      var inactivityTimeout;

      function endInactivityTimer() {
        resetInactivityTimer(true);
        scope.timeoutIndicatorEndActivity();
      }

      function resetInactivityTimer(removeCompletely) {
        elem[0].style.transition = 'none';
        elem[0].style.transform = 'translateX(-100%)';
        $timeout.cancel(inactivityTimeout);

        if (removeCompletely) {
          idleService.cancelInactivityTimer(scope.timeoutIndicatorListenerName);
        } else {
          setInactivityTimeout();
        }
      }

      function setInactivityTimeout() {
        // Delay transition a ${scope.timeoutIndicatorTransitionDelay}
        inactivityTimeout = $timeout(function () {
          var transitionDuration = scope.timeoutIndicatorTreshold - scope.timeoutIndicatorTransitionDelay;
          elem[0].style.transition = 'transform ' + transitionDuration + 'ms linear';
          elem[0].style.transform = 'translateX(0%)';
        }, scope.timeoutIndicatorTransitionDelay, 0, false);
      }

      function startInactivityTimer() {
        if (scope.timeoutIndicatorActive) {
          idleService.registerListener({
            name: scope.timeoutIndicatorListenerName,
            time: scope.timeoutIndicatorTreshold,
            transitionDelay: scope.timeoutIndicatorTransitionDelay,
            onResetInactivityTimer: resetInactivityTimer,
            onEndInactivityTimer: endInactivityTimer,
            disableResetListeners: true
          });
          setInactivityTimeout();
        } else {
          idleService.cancelInactivityTimer(scope.timeoutIndicatorListenerName);
        }
      }

      scope.$on('$destroy', function () {
        idleService.cancelInactivityTimer(scope.timeoutIndicatorListenerName);
      });

      scope.$watch('timeoutIndicatorActive', function (newVal, oldVal) {
        if (scope.timeoutIndicatorActive) {
          startInactivityTimer();
        } else if (oldVal) {
          endInactivityTimer();
        }
      });
    }
  };
}

export default timeoutIndicator;
