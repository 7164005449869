import { useSevenStore } from '@/modules/seven';
import axiosInstance from './axiosInstance';
import type BetshopProvinceData from '../interfaces/BetshopProvinceData';

const getProvinceByBetshopUuid = () => {
  const sevenStore = useSevenStore();

  return axiosInstance.get<BetshopProvinceData>(`/api/v1/province/${sevenStore.betshop.uuid}`);
};

export {
  getProvinceByBetshopUuid,
};
