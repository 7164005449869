import { type VoucherResponse } from '@/modules/vouchers';

enum TboTicketType {
  ticket = 'ticket',
  voucher = 'voucher',
}

type TboTicketResponseItem = {
  action?: string,
  bet: number,
  bets: Array<unknown>,
  betshopGroupId?: number,
  betshopGroupName?: string,
  betshopId?: number,
  betshopName?: string,
  cancelledAmount?: number,
  cancelledPayinTax?: number,
  cashRegisterName?: string,
  cashRegisterUuid?: string,
  cpvUuid?: string,
  createdAt: string,
  currency?: string,
  deliveryPlatform: string,
  deviceId?: number,
  deviceName?: string,
  id: string,
  maxPossiblePayout: number,
  maxPossiblePayoutTax: number,
  maxPossibleWin: number,
  operatorFirstName?: string,
  operatorId?: number,
  operatorLastName?: string,
  operatorUserName?: string,
  payedOutAt?: string,
  payedOutAtUTC?: string,
  payin?: number,
  payinTax: number,
  paymentId?: number,
  payout?: number,
  payoutTax?: number,
  playerFirstName?: string,
  playerId?: number,
  playerLastName?: string,
  playerUserName?: string,
  possiblePayout?: number,
  possiblePayoutTax?: number,
  product: string,
  productVersion?: string,
  requestUuid: string,
  responseMessage?: string,
  status?: {
    id: string,
    name: string,
    value: string
  },
  superBonus?: number,
  ticketDateTime: number,
  ticketDateTimeUTC: number,
  ticketPin?: string,
  winnings?: number,
};

type TboTicketsListResponse = {
  message?: Array<TboTicketResponseItem>,
  data?: Array<TboTicketResponseItem>,
};

type TboFormattedData = {
  sent: string,
  displayStatus: string,
  translation?: string,
  isCopy?: boolean,
};

type TboFormattedTicket = TboFormattedData & TboTicketResponseItem;

type TboFormattedVoucher = TboFormattedData & VoucherResponse;

type TicketListItem = {
  name: string,
  ticketId: string,
  ticketDateTime: string,
  payin?: number,
  status: string,
};

type VoucherListItem = {
  name: string,
  voucherId: string,
  voucherDateTimeUTC: string,
  amount?: number,
  status: string,
};

export {
  TboTicketType,
};

export type {
  TboTicketsListResponse,
  TboTicketResponseItem,
  TboFormattedTicket,
  TboFormattedVoucher,
  TicketListItem,
  VoucherListItem,
};
