import { each } from 'underscore';
import { acceptorsApiService } from '@/modules/acceptors';
import { useWidgetsStore } from '@/common/stores/widgets';
import { safeAppReload } from '@/common/services/appHelpersService';

(function () {
  angular.module('7Terminal.Bootstrap')
    .service('AppResolve', AppResolve);

  function AppResolve(
    $timeout,
    $log,
    BootStrapData,
    LocalData,
    lang,
    SevenClientCoreSvc,
    SevenClientConfig,
    SevenScmSvc,
    GravitySettings,
    Environment,
    SevenGamesSvc,
    Widgets
  ) {
    this.boot = function () {
      let shouldReload = false;

      acceptorsApiService.init();

      // detect channel and do reload if we did not pick up channel assets
      shouldReload = Environment.setChannel(BootStrapData.betshop.id, LocalData.device.deviceId);
      if (shouldReload) {
        $log.info('[7Terminal.AppResolveService] Reload to change channel', {
          code: 'T_BOOTSTRAP_CHANNEL_CHANGE_RELOAD'
        });
        safeAppReload();
      }
      return $timeout(function () {
        var widgetsToRegister = Widgets.filterWidgetsFromSource(GravitySettings.getData());
        each(widgetsToRegister, function (widget) {
          Widgets.registerWidget(widget);
        });

        const widgetsStore = useWidgetsStore();
        widgetsStore.loadGravityWidgets(GravitySettings.getData());
        // TODO: @miletic.dinko implement this inside to client-core run method
        if (!lang.currentLang.length) {
          SevenClientConfig.updateSettings({
            global: {
              language: BootStrapData.company.locale
            }
          });
        }

        // start client core
        return SevenClientCoreSvc.run(BootStrapData).then(
          function () {
            return SevenScmSvc.startScmConnection({
              channels: SevenGamesSvc.getGamesChannels(),
              deliveryPlatform: 'Terminal',
              deviceUuid: LocalData.device.uuid,
              language: SevenClientConfig.getSettings().global.language,
              ncm: SevenClientConfig.getSettings().ncm
            });
          }
        );
      }, shouldReload ? 6000 : 0, false);
    };
  }
})();
