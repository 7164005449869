declare global {
  interface Window {
    _7Loader: {
      showLoader: (message?: string) => void;
      endLoader: () => void;
    };
  }
}

export const useGlobalLoader = () => {
  const showLoader = (message?: string) => {
    // eslint-disable-next-line no-underscore-dangle
    window._7Loader.showLoader(message);
  };

  const endLoader = () => {
    // eslint-disable-next-line no-underscore-dangle
    window._7Loader.endLoader();
  };

  return {
    showLoader,
    endLoader,
  };
};
