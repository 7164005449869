import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "t-header-balance-status-text h-100 sc-text-body-2 text-right"
}

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useUserFundsStore } from '@/modules/user-funds';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderBalanceHelper',
  setup(__props) {

const { balance } = storeToRefs(useUserFundsStore());
const showBalanceStatus = computed(() => balance.value === 0);


return (_ctx: any,_cache: any) => {
  return (showBalanceStatus.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('header_balance_helper_text')), 1 /* TEXT */))
    : _createCommentVNode("v-if", true)
}
}

})