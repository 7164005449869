import inactiveImage from '../../../assets/images/placeholder-image.png';
import templateUrl from './vms.html';

(function () {
  angular.module('terminalApp.sevenVirtualMotorcycleSpeedway')
    .component('vmsUi', {
      controllerAs: 'vmsVm',
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        nabTrans,
        NabNotifications,
        BetslipService,
        VmsUiService,
        $scope,
        $rootScope,
        loaderService,
        GamesUiService,
        arrayService
      ) {
        var finalizedBet;
        var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
        var gameState = GamesUiService.checkGameActivity(VMS);
        var self = this;
        var betslipResetListener;
        var BettingSvc = VMS.getBettingSvc();

        this.$onInit = function () {
          this.activeGame = VMS;
          this.gameInfo = VMS.getGameInfo();
          this.resolutionMode = $rootScope.resolutionMode;
          BettingSvc.initializeService(VMS);
          this.betslipSvc = BetslipService;
          this.vmsUISvc = VmsUiService;
          this.tabs = {
            name: ['racecard', 'forecast', 'h2h']
          };

          if (this.activeGame.isScheduleActive) {
            this.vmsUISvc.createScheduledEvents(this.activeGame.offer);
            // this.watchDisabled();
          }
          this.inactiveImage = inactiveImage;
          $scope.futureRounds = arrayService.getArray(10, true);
        };

        /**
                 * Send created bet to betslip
                 */

        this.sendBet = function () {
          var error;
          if (!gameState.value) {
            NabNotifications.show(
              {
                message: nabTrans.translate(gameState.messageKey, { game: this.activeGame.translation }, true),
                type: 'warning',
                delay: 3000
              }
            );
            return;
          }

          if (!flashButton.flash) {
            return;
          }

          finalizedBet = BettingSvc.finalizeTicketInput();

          if (this.activeGame.isScheduleActive) {
            finalizedBet[0].eventId = this.activeGame._scheduleSvc.activeRound.eventId;
            finalizedBet[0].event = this.activeGame._scheduleSvc.activeRound.eventIdToday;
          }

          error = BetslipService.validateBet(
            finalizedBet,
            false
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            BetslipService.addToBetslip(finalizedBet);
          }

          this.resetBet();
        };

        /** Watch active bet and add active class
                 * i have to use angular.bind because i didn't want to set betslipSvc on $scope* */
        // TODO - FIND BETTER WAY
        $scope.$watch(angular.bind(this, function () {
          return self.betslipSvc.ticket.bets; // this IS the this above!!
        }), function (newValue) {
          self.clearAll();

          self.vmsUISvc.enableActiveBets(newValue);
        }, true);

        this.clearAll = function () { // clear all active bets
          this.vmsUISvc.clearActiveBets();
        };

        /**
                 * Clear currently selected bet
                 */

        this.resetBet = function () {
          BettingSvc.clearAll();
          this.clearAll();
          flashButton.flash = false;
        };

        betslipResetListener = $rootScope.$on('7T:Betslip.Reset', function () {
          self.resetBet();
        });

        var betslipBetsChangedListener = $rootScope.$on('7T.Betslip.BetsChanged', function (e, data) {
          if (data.type === 'betRemove') {
            self.resetBet();
          }
        });

        $scope.$on('$destroy', function () {
          betslipResetListener();
          betslipBetsChangedListener();
        });

        flashButton.flash = false;
        loaderService.endLoader();
      }
    });
})();
