import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { logService } from '@/common/services/logger';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import i18n from '@/plugins/i18n';
import type { SevenModuleTboGeneralAction, TboSevenModule } from './types';

const { t } = i18n.global;

const useTboModulesStore = defineStore('tboModulesStore', () => {
  let modules: Array<TboSevenModule> = reactive([]);

  const getModule = (moduleId: string, submoduleId?: string): TboSevenModule | null => {
    let foundModule = modules.filter((module: TboSevenModule) => module.id === moduleId);

    if (submoduleId && foundModule?.length && foundModule[0].submodules) {
      foundModule = foundModule[0].submodules?.filter(
        (submodule: TboSevenModule) => submodule.id === submoduleId,
      );
    }

    return foundModule && foundModule[0];
  };

  const appendModule = (module: TboSevenModule): void => {
    modules.push(module);
  };

  const handleSystemInfoModule = (): void => {
    /**
     * Options (aka SystemInfo) tab needs specific handling, because we're mixing
     * props received from backend + from  CMS.
     * For more details check https://app.clickup.com/t/861mckgfh
     */
    const gravitySettingsStore = useGravitySettingsStore();
    const systemInfoModule = getModule('SystemInfo');
    const config = gravitySettingsStore.getByKey('config');
    const cmsSubmodules = [];

    if (config?.landingPage) {
      cmsSubmodules.push({
        translation: t('main.landing_page'),
        id: 'OptionsLandingPage',
        mode: null,
      });
    }

    if (config?.serviceMode) {
      cmsSubmodules.push({
        translation: t('main.service_mode'),
        id: 'OptionsServiceMode',
        mode: null,
      });
    }

    if (systemInfoModule) {
      systemInfoModule.submodules = [
        {
          translation: t('main.device'),
          id: 'OptionsDevice',
          mode: null,
        },
        ...cmsSubmodules,
      ];
    } else if (cmsSubmodules.length) {
      appendModule({
        id: 'SystemInfo',
        translation: t('tbo_module_system_info'),
        mode: null,
        submodules: cmsSubmodules,
      });
    }
  };

  const setModules = (tboModules: Array<TboSevenModule>): void => {
    modules = tboModules;
    handleSystemInfoModule();
  };

  const appendSubmodules = (moduleId: string, submodules: Array<TboSevenModule>): void => {
    const foundModule = getModule(moduleId);

    if (foundModule) {
      if (!foundModule.submodules) {
        foundModule.submodules = [];
      }
      foundModule.submodules.push(...submodules);
    } else {
      logService.warn(`[tboModulesStore] Submodules cannot be appended, since module with ${moduleId} id not found.`, {
        code: 'T_SUBMODULE_APPEND_ERROR',
      });
    }
  };

  const getModules = (): Array<TboSevenModule> => modules;

  const hasPermission = (moduleId: string, actionId: string): boolean => {
    const module = getModule(moduleId);
    const foundAction = module?.actions?.find(
      (action: SevenModuleTboGeneralAction) => action.id === actionId,
    );
    return !!foundAction;
  };

  return {
    modules,
    setModules,
    appendModule,
    appendSubmodules,
    getModules,
    getModule,
    hasPermission,
  };
});

export {
  useTboModulesStore,
};
