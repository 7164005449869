<script setup lang="ts">
import {
  ref, reactive, onMounted,
} from 'vue';
import { SCNavigationList, SCNavigationListItem, SCIcon } from '@nsftx/seven-components';
import router from '@/router';
import { useTboModulesStore, type TboSevenModule } from '@/modules/tbo';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';

const tboModulesStore = useTboModulesStore();
const gravitySettingsStore = useGravitySettingsStore();
const globalModules = reactive<Array<TboSevenModule>>(tboModulesStore.getModules());
const activeState = ref('admin/wallet');// first view to redirect on after login
const activeItemIndex = ref(0);

const iconList: Record<string, string> = {
  tickets: 'n-i-betslip-a',
  wallet: 'n-i-balance',
  players: 'n-i-user-details',
  reporting: 'n-i-reporting',
  systeminfo: 'n-i-terminal-a',
  cardoperations: 'n-i-user-name',
  billing: 'n-i-bills',
  certificate: 'n-i-article',
};

const onNavItemClicked = () => {
  const item = globalModules[activeItemIndex.value];
  const routePath = router.currentRoute.value.fullPath;
  const isRouteActive = routePath.includes(item.id.toLowerCase());

  if (isRouteActive) {
    return;
  }

  router.push({
    name: item.id,
  });
};

const setActiveIndex = () => {
  activeItemIndex.value = globalModules
    .findIndex((el: TboSevenModule) => activeState.value.includes(`admin/${el.id.toLowerCase()}`));
};

router.beforeEach((route) => {
  activeState.value = route.path;
  setActiveIndex();
});

const loadAdditionalNavigationItems = () => {
  const certificateEnabled = !!gravitySettingsStore.data.config?.certificate;

  if (certificateEnabled) {
    globalModules.push({
      id: 'Certificate',
      translation: 'Certifikat',
      mode: null,
    });
  }
};

onMounted(() => {
  loadAdditionalNavigationItems();
  setActiveIndex();
});

</script>

<template>
  <div class="sc-px-0 sc-bg-300 h-100 tbo-sidebar">
    <div class="sc-bg-600">
      <SCNavigationList
        v-model="activeItemIndex"
        large
        @update:model-value="onNavItemClicked"
      >
        <SCNavigationListItem
          v-for="(module) in globalModules"
          :key="module.id"
        >
          <template #prepend-icon>
            <SCIcon
              :icon="iconList[module.id.toLowerCase()]"
              size="lg"
            />
          </template>

          <span>{{ module.translation }}</span>
        </SCNavigationListItem>
      </SCNavigationList>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tbo-sidebar{
  z-index: 1;
}

.sc-list {
  gap: 1px;
}
</style>
