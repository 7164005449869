import QrCreator from 'qr-creator';
import { UIShowViewName } from '@/modules/integrator';
import { TicketClientRequest } from '@/modules/seven-betslip-api';
import { TicketInstance } from '@/modules/seven-betslip-api/helpers';

// If we have, for ex. two optional props, and we want
// to require one of two properties to exist
// Check more at https://stackoverflow.com/questions/40510611/typescript-interface-require-one-of-two-properties-to-exist
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
T,
Exclude<keyof T, Keys>
> &
{
  [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
}[Keys];

export type BeforeTicketPayinHookParams = { data: {
  localTicket: TicketInstance,
  ticket: TicketClientRequest,
  platformVal?: string,
  additionalInfo?: Object & { printTemplate: string },
}, productId: string,
};

export type RoTaxPlayerDataParams = {
  lastName: string,
  firstName: string,
  cnp: string,
  customerCardNumberID: string,
} | null;

export type BeforeTicketActionParams = {
  ticket: TicketInstance,
};

export type DropdownValue = { name: string, value: string };

export type FormValidation = {
  pattern?: string,
  isRequired?: boolean
};

export type ViewResolvedParam = {
  viewName: UIShowViewName
};

export type QrCodeConfig = {
  text: string,
  correctLevel?: QrCreator.ErrorCorrectionLevel, // L, M, Q, H - L is default
  size?: number, // 150 is default
  background?: string | null
};

export enum TimerProgression {
  ASC = 'ASC',
  DESC = 'DESC',
}
