import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TLogin from './TLogin.vue';
import TPinLogin from './TPinLogin.vue';
import TLoginFailedModal from './TLoginFailedModal.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthModalsHandler',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TLogin),
    _createVNode(TPinLogin),
    _createVNode(TLoginFailedModal)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})