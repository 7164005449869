/* eslint-disable no-param-reassign */
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { type ToastNotification } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { mapNotificationMessage, mapNotificationVariant } from '@/common/services/notifications';
import type {
  TNotification,
  TNotificationConfig,
} from './types';
import { TNotificationTypeEnum } from './enums';

const { t } = i18n.global;

const useNotificationsStore = defineStore('notificationsStore', () => {
  const notifications = ref<Array<TNotification>>([]);

  const generateNotification = (config: TNotificationConfig): TNotification => ({
    messages: mapNotificationMessage(config.message) as Array<string>,
    variant: mapNotificationVariant(config.type),
    actions: config.actions?.map((action) => ({
      text: action.text || '',
      callback: action?.callback ? () => {
        action?.callback?.(action.callbackParams);
      } : () => {},
    })),
    uuid: uuidv4(),
    id: config.id,
    uiBlocking: config.uiBlocking,
    hideCloseIcon: config?.closeDisabled,
    timeout: config.delay,
  });

  const removeNotification = (notificationUuid: string): void => {
    const index = notifications.value.findIndex(({ uuid }) => uuid === notificationUuid);
    if (index !== -1) {
      notifications.value.splice(index, 1);
    }
  };

  const closeNotificationWithId = (notificationId: string): void => {
    const notification = notifications.value.find(({ id }) => id === notificationId);
    if (notification) {
      removeNotification(notification.uuid);
    }
  };

  const addDefaultAction = (notification: TNotification): void => {
    const defaultAction = {
      text: t('actions.back'),
      callback: () => {
        removeNotification(notification.uuid);
      },
    };

    notification.actions?.push(defaultAction);
  };

  const show = (config: TNotificationConfig): void => {
    if (config.id) {
      closeNotificationWithId(config.id);
    }
    config.actions = config.actions || [];
    config.type = config.type || TNotificationTypeEnum.info;

    const { message } = config;

    if (!Array.isArray(message)) {
      config.message = [message];
    }

    const newNotification = generateNotification(config);

    if (config.showDefaultAction) {
      addDefaultAction(newNotification);
    }

    notifications.value.push(newNotification);
  };

  const updateNotifications = (updatedNotifications: Array<ToastNotification>) => {
    const newArray: Array<TNotification> = [];

    updatedNotifications.forEach((notification) => {
      const existingNotification = notifications.value.find((el) => el.uuid === notification.uuid);

      if (existingNotification) {
        newArray.push(existingNotification);
      }
    });

    notifications.value = newArray;
  };

  return {
    notifications,
    show,
    closeNotificationWithId,
    removeNotification,
    addDefaultAction,
    updateNotifications,
  };
});

export {
  useNotificationsStore,
};
