import axios from 'axios';
import { SevenApiRoutes, useSevenStore } from '@/modules/seven';
import AppMode from '@/common/enums/TerminalApps';
import type MaintenanceScheduleResponse from '../interfaces/MaintenanceScheduleResponse';

const getSchedule = () => {
  const sevenStore = useSevenStore();
  const url = `${SevenApiRoutes.routes.mainUrl}/maintenance/api/v1/schedule`;
  const params = {
    company: sevenStore.tenant.uuid,
    channel: AppMode.ANONYMOUS,
  };

  return axios.get<MaintenanceScheduleResponse>(url, { params });
};

export {
  getSchedule,
};
