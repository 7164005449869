import { logService } from '@/common/services/logger';
import {
  Core,
  Display,
} from '@nsftx/systems-sdk';
import DmDisplayError from '../errors/DmDisplayError';

const LOG_PREFIX = '[deviceManagementDisplayService]';
let displayInstance: Display | null = null;
let displayReady = false;

const initDmDisplay = (core: Core) => new Promise<Display>((resolve, reject) => {
  const rejectTimeout = window.setTimeout(() => {
    reject(new DmDisplayError(
      `${LOG_PREFIX} Display instance did not receive ready event on init.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_READY',
    ));
  }, 5000);
  displayInstance = new Display(core);
  displayInstance.on('ready', () => {
    window.clearTimeout(rejectTimeout);
    displayReady = true;
    logService.debug(`${LOG_PREFIX} Display module ready.`);
    resolve(displayInstance as Display);
  });
});

const getDisplayInstance = (): Display => {
  if (!displayInstance) {
    throw new DmDisplayError(
      `${LOG_PREFIX} Display instance is not initialized.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_INIT',
    );
  }

  if (!displayReady) {
    throw new DmDisplayError(
      `${LOG_PREFIX} Display instance did not receive ready event.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_READY',
    );
  }

  return displayInstance;
};

const isDmDisplayReady = () => new Promise<void>((resolve, reject) => {
  if (displayReady) {
    resolve();
  } else {
    reject(new DmDisplayError(
      `${LOG_PREFIX} Display instance not yet ready.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_READY',
    ));
  }
});

const switchDisplay = async (accountId: string) => {
  await isDmDisplayReady();
  const display = getDisplayInstance();
  const res = await display.BringAppToFront(accountId);
  return res;
};

export {
  initDmDisplay,
  switchDisplay,
};
