import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-boot-error sc-bg-500 sc-p-3" }
const _hoisted_2 = { class: "app-boot-error__container w-100 h-100 sc-bg-300" }
const _hoisted_3 = { class: "app-boot-error__container__content" }
const _hoisted_4 = { class: "app-boot-error__container__content__text" }

import { SCIcon, SCButton, ButtonVariant } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { safeAppReload } from '../services/appHelpersService';

const LOG_PREFIX = '[AppBootError]';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppBootError',
  setup(__props) {

const { t } = i18n.global;
document.documentElement.dataset.scChannel = 'terminal';
document.documentElement.dataset.scTheme = 'dark';
document.documentElement.dataset.scPalette = 'default';

const reloadApp = () => {
  logService.info(`${LOG_PREFIX} Reload on app boot error.`, {
    code: 'T_APP_BOOT_ERR_RELOAD',
  });
  safeAppReload();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(SCIcon), {
          icon: "n-i-disconnected",
          size: "6xl"
        }),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('app_boot_error_screen_info_message')), 1 /* TEXT */),
        _createVNode(_unref(SCButton), {
          variant: _unref(ButtonVariant).Primary,
          onClick: reloadApp
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('app_boot_error_screen_action_message')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["variant"])
      ])
    ])
  ]))
}
}

})