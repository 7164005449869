import { uniq, where } from 'underscore';
import resultsTemplate from './scr.html';
import inactiveImage from '../../../assets/images/placeholder-image.png';

export default {
  controllerAs: 'scrVm',
  templateUrl: resultsTemplate,
  controller: /* @ngInject */ function (
    flashButton,
    SevenGamesSvc,
    nabTrans,
    NabNotifications,
    BetslipService,
    ScrUiService,
    $scope,
    $rootScope,
    loaderService,
    GamesUiService,
    arrayService
  ) {
    var finalizedBet;
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');
    var gameState = GamesUiService.checkGameActivity(SCR);
    var self = this;
    var betslipResetListener;
    var BettingSvc = SCR.getBettingSvc();

    this.$onInit = function () {
      this.inactiveImage = inactiveImage;
      this.activeGame = SCR;
      this.gameInfo = SCR.getGameInfo();
      this.resolutionMode = $rootScope.resolutionMode;
      BettingSvc.initializeService(SCR);
      this.betslipSvc = BetslipService;
      this.scrUISvc = ScrUiService;
      this.tabs = {
        name: ['racecard', 'forecast', 'tricast']
      };

      if (this.activeGame.isScheduleActive) {
        this.scrUISvc.createScheduledEvents(this.activeGame.offer);
        this.watchDisabled();
      }

      $scope.futureRounds = arrayService.getArray(10, true);
    };

    /**
                 * Send created bet to betslip
                 */

    this.sendBet = function () {
      if (!gameState.value) {
        NabNotifications.show(
          {
            message: nabTrans.translate(gameState.messageKey, { game: this.activeGame.translation }, true),
            type: 'warning',
            delay: 3000
          }
        );
        return;
      }

      if (!flashButton.flash) {
        return;
      }

      if (this.scrUISvc.reverseForecastPlaces.length > 0) {
        this.formatReverseForecastBet();
      }

      if (this.scrUISvc.reverseTricastPlaces.length > 0) {
        this.formatReverseTricastBet();
      }

      if (this.scrUISvc.forecastFirstPlaces.length > 0 && this.scrUISvc.forecastSecondPlaces.length > 0) {
        this.formatForecastBet();
      }

      if (this.scrUISvc.tricastFirstPlaces.length > 0
                       && this.scrUISvc.tricastSecondPlaces.length > 0 && this.scrUISvc.tricastThirdPlaces.length > 0) {
        this.formatTricastBet();
      }

      finalizedBet = BettingSvc.finalizeTicketInput();

      if (this.activeGame.isScheduleActive) {
        finalizedBet[0].eventId = this.activeGame._scheduleSvc.activeRound.eventId;
        finalizedBet[0].event = this.activeGame._scheduleSvc.activeRound.eventIdToday;
      }

      var error = BetslipService.validateBet(
        finalizedBet,
        false
      );

      if (error.msg) {
        NabNotifications.show({
          message: error.msg,
          type: 'warning',
          delay: 3000
        });
      } else {
        BetslipService.addToBetslip(finalizedBet);
      }

      this.resetBet();
    };

    /** Format reverse forecast bet for sending to server* */

    this.formatReverseForecastBet = function () {
      /** Remove duplicates form array* */

      this.scrUISvc.reverseForecastPlaces = uniq(this.scrUISvc.reverseForecastPlaces);

      bet = where(SCR.configuredBets, { key: 'reverseForecast' });
      BettingSvc.activateBet(bet[0]);

      /** Remove set forecast odd if exist* */

      if (BettingSvc.ticketInput.systemOdd) {
        delete BettingSvc.ticketInput.systemOdd;
      }

      /** Format array for bet service - same format as 7shop * */

      BettingSvc.ticketInput.input = this.scrUISvc.reverseForecastPlaces;
    };

    /** Format  forecast bet for sending to server* */

    this.formatForecastBet = function () {
      bet = where(SCR.configuredBets, { key: 'forecast' });

      if (BettingSvc.ticketInput.systemOdd) {
        delete BettingSvc.ticketInput.systemOdd;
      }

      /** Remove duplicates form array* */

      this.scrUISvc.forecastFirstPlaces = uniq(this.scrUISvc.forecastFirstPlaces);
      this.scrUISvc.forecastSecondPlaces = uniq(this.scrUISvc.forecastSecondPlaces);

      BettingSvc.activateBet(bet[0]);

      /** Add odd for forecast bet* */

      if (this.scrUISvc.forecastFirstPlaces.length == 1 && this.scrUISvc.forecastSecondPlaces.length == 1) {
        BettingSvc.ticketInput.systemOdd = SCR._bettingSvc.systemBets.forecastOdds[this.scrUISvc.forecastFirstPlaces[0] + '-' + this.scrUISvc.forecastSecondPlaces[0]];

        if (this.activeGame.isScheduleActive) {
          BettingSvc.ticketInput.systemOdd = this.activeGame._scheduleSvc.activeRound.bets.systemBets.forecastOdds[this.scrUISvc.forecastFirstPlaces[0] + '-' + this.scrUISvc.forecastSecondPlaces[0]];
        }
      } else {
        delete BettingSvc.ticketInput.systemOdd;
      }

      /** Format array for bet service - same format as 7shop * */
      BettingSvc.ticketInput.input = this.scrUISvc.forecastFirstPlaces;
      BettingSvc.ticketInput.secondPlace = this.scrUISvc.forecastSecondPlaces;
    };

    /** Format  tricast bet for sending to server* */

    this.formatTricastBet = function () {
      var bet;
      /** Remove set forecast odd if exist* */

      if (BettingSvc.ticketInput.systemOdd) {
        delete BettingSvc.ticketInput.systemOdd;
      }

      bet = where(SCR.configuredBets, { key: 'tricast' });

      /** Remove duplicates form array* */

      this.scrUISvc.tricastFirstPlaces = uniq(this.scrUISvc.tricastFirstPlaces);
      this.scrUISvc.tricastSecondPlaces = uniq(this.scrUISvc.tricastSecondPlaces);
      this.scrUISvc.tricastThirdPlaces = uniq(this.scrUISvc.tricastThirdPlaces);

      if (this.scrUISvc.tricastFirstPlaces.length == 1 && this.scrUISvc.tricastSecondPlaces.length == 1 && this.scrUISvc.tricastThirdPlaces.length == 1) {
        BettingSvc.ticketInput.systemOdd = SCR._bettingSvc.systemBets.tricastOdds[this.scrUISvc.tricastFirstPlaces[0] + '-' + this.scrUISvc.tricastSecondPlaces[0] + '-' + this.scrUISvc.tricastThirdPlaces[0]];

        if (this.activeGame.isScheduleActive) {
          BettingSvc.ticketInput.systemOdd = this.activeGame._scheduleSvc.activeRound.bets.systemBets.tricastOdds[this.scrUISvc.tricastFirstPlaces[0] + '-' + this.scrUISvc.tricastSecondPlaces[0] + '-' + this.scrUISvc.tricastThirdPlaces[0]];
        }
      } else {
        delete BettingSvc.ticketInput.systemOdd;
      }

      BettingSvc.activateBet(bet[0]);

      /** Format array for bet service - same format as 7shop * */

      BettingSvc.ticketInput.input = this.scrUISvc.tricastFirstPlaces;
      BettingSvc.ticketInput.secondPlace = this.scrUISvc.tricastSecondPlaces;
      BettingSvc.ticketInput.thirdPlace = this.scrUISvc.tricastThirdPlaces;
    };

    /** Format reverse tricast bet for sending to server* */

    this.formatReverseTricastBet = function () {
      var bet;
      /** Remove set forecast odd if exist* */

      if (BettingSvc.ticketInput.systemOdd) {
        delete BettingSvc.ticketInput.systemOdd;
      }

      /** Remove duplicates form array* */

      this.scrUISvc.reverseTricastPlaces = uniq(this.scrUISvc.reverseTricastPlaces);
      bet = where(SCR.configuredBets, { key: 'reverseTricast' });

      BettingSvc.activateBet(bet[0]);
      /** Format array for bet service - same format as 7shop * */

      BettingSvc.ticketInput.input = this.scrUISvc.reverseTricastPlaces;
    };

    /** Watch active bet and add active class
                 * I have to use angular.bind because I didn't want to set betslipSvc on $scope* */
    // TODO - FIND BETTER WAY
    $scope.$watch(angular.bind(this, function () {
      return self.betslipSvc.ticket.bets; // this IS the this above!!
    }), function (newValue) {
      self.clearAll();

      self.scrUISvc.enableActiveBets(newValue);
    }, true);

    this.clearAll = function () { // clear all active bets
      this.scrUISvc.clearActiveBets();
    };

    this.checkState = function (btn) {
      if (this.scrUISvc.reverseForecastPlaces.length > 0) {
        btn = true;
      }

      if (this.scrUISvc.reverseTricastPlaces.length > 0) {
        btn = true;
      }

      if (this.scrUISvc.forecastFirstPlaces.length > 0 && this.scrUISvc.forecastSecondPlaces.length > 0) {
        btn = true;
      }

      if (this.scrUISvc.tricastFirstPlaces.length > 0
                        && this.scrUISvc.tricastSecondPlaces.length > 0 && this.scrUISvc.tricastThirdPlaces.length > 0) {
        btn = true;
      }

      return btn;
    };

    this.watchDisabled = function () {
      $scope.$watch(function () {
        return self.activeGame.gameInfo.betsBlocked;
      }, function (newValue) {
        if (newValue) {
          flashButton.flash = false;
        } else if (self.checkState(flashButton.flash)) flashButton.flash = true;
      }, true);
    };

    /**
                 * Clear currently selected bet
                 */

    this.resetBet = function () {
      BettingSvc.clearAll();
      this.clearAll();
      flashButton.flash = false;
    };

    betslipResetListener = $rootScope.$on('7T:Betslip.Reset', function () {
      self.resetBet();
    });

    $scope.$on('$destroy', function () {
      betslipResetListener();
    });

    flashButton.flash = false;
    loaderService.endLoader();
  }
};
