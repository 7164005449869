// Module
var code = `<div class="row mb-1"> <div class="col-sm-24 pl-0"> <ul class="row mx-0-5 my-0-5"> <li ng-repeat="color in \$ctrl.luckySixBettingArea.allColors" ng-if="\$even" ng-click="\$ctrl.luckySixBettingArea.selectFirstBallColor(7, \$index + 1)" class="pl-0-5 special-bets__first-ball-color col-sm-6"> <button class="btn btn-block btn-primary"> <span class="n-i n-i-circle is--transparent"></span> <i class="n-i n-i-circle center-xy" ng-class="{'n-i-check-a': \$ctrl.luckySixBettingArea.activeBets.selected.bet.clientId == 7 &&
                                                \$ctrl.luckySixBettingArea.activeBets.ticketInput.input.indexOf(\$index + 1) >= 0,
                                                'check_v{{\$ctrl.luckySixBettingArea.luckySixVersion}}_{{\$index}}' : true}"> </i> </button> </li> </ul> <ul class="row mx-0-5 my-0-5"> <li ng-repeat="color in \$ctrl.luckySixBettingArea.allColors" ng-if="\$odd" ng-click="\$ctrl.luckySixBettingArea.selectFirstBallColor(7, \$index + 1)" class="pl-0-5 special-bets__first-ball-color col-sm-6"> <button class="btn btn-block btn-primary"> <span class="n-i n-i-circle is--transparent"></span> <i class="n-i n-i-circle center-xy" ng-class="{'n-i-check-a':\$ctrl.luckySixBettingArea.activeBets.selected.bet.clientId == 7 &&
                                                \$ctrl.luckySixBettingArea.activeBets.ticketInput.input.indexOf(\$index + 1) >= 0,
                                                'check_v{{\$ctrl.luckySixBettingArea.luckySixVersion}}_{{\$index}}' : true }"> </i> </button> </li> </ul> </div> </div> <div class="row mx-0-5 my-0-5"> <div class="col-sm-16 pl-0"> <button class="btn btn-block btn-primary mb-1" ng-click="\$ctrl.close()" translate="actions.cancel"> </button> </div> <div class="col-sm-8"> <button class="btn btn-block btn-success mb-1" popup-notification ng-click="\$ctrl.accept()" popup-id="luckySixUiFirstBallColor"> {{'actions.ok' | translate}} </button> </div> </div>`;
// Exports
var _module_exports =code;;
var path = '/games/lucky_six/bettingArea/firstBallColorPopUp.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;