/* eslint-disable no-underscore-dangle */
import { useSevenStore } from '@/modules/seven';
import { localAppConfigService } from '@/common/services/local-app-config';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import type { TboGlobalDataResponse } from '@/modules/tbo';
import axiosInstance from './axiosInstance';
import {
  type GravityAuthCredentials,
  type LoginData,
  LoginType,
} from './types';

const getLoginHeaders = () => {
  const sevenStore = useSevenStore();

  return {
    'X-Nsft-SCD-Betshop-Uuid': sevenStore.betshop.uuid,
  };
};

const login = (credentials: LoginData) => {
  const sevenStore = useSevenStore();
  const envHandlerStore = useEnvironmentHandlerStore();

  const headers: any = {
    ...getLoginHeaders(),
    Authorization: `Basic ${window.btoa(`${localAppConfigService.data.appConfig.terminalAccount.uuid}:${
      localAppConfigService.data.appConfig.terminalAccount.password}`)}`,
  };

  const loginBody: GravityAuthCredentials = {
    _username: credentials.username,
    _password: credentials.password,
  };

  if (envHandlerStore.getDc() && envHandlerStore.getDc() !== 'gb') {
    headers['X-Nsft-SCD-DC'] = envHandlerStore.getDc();
  }

  // Extend the login body with card params (empty values are ignored on server)
  if (credentials.loginType === LoginType.card) {
    loginBody._pin = credentials.pin || '';
    loginBody._authorizationCode = credentials.authorizationCode || '';
    Object.assign(headers, {
      'X-Nsft-SCD-Club-Uuid': sevenStore.tenant.uuid,
      'X-Nsft-SCD-Auth-Method': 'CardReader',
    });
  }

  return axiosInstance.post<TboGlobalDataResponse>(
    `${process.env.GRAVITY_API_URL_CF}/v1/terminal-backoffice/bootstrap`,
    loginBody,
    {
      headers,
      timeout: 60000,
    },
  ).then((response) => {
    if (response.status === 200) {
      const token = response.headers['access-token'];
      localStorage.setItem('user.token', token);

      return Promise.resolve(response);
    }
    return Promise.reject(response);
  });
};

const loginCheck = (token: string) => {
  const headers = {
    ...getLoginHeaders(),
    'X-Nsft-SCD-App-Token': `Basic ${window.btoa(`${localAppConfigService.data.appConfig.terminalAccount.uuid}:${
      localAppConfigService.data.appConfig.terminalAccount.password}`)}`,
    Authorization: `Bearer ${token}`,
  };
  return axiosInstance.get<TboGlobalDataResponse>(`${process.env.GRAVITY_API_URL_CF}/v1/terminal-backoffice/bootstrap`, {
    headers,
  }).then((response) => {
    if (response.status === 200) {
      const responseToken = response.headers['access-token'];
      localStorage.setItem('user.token', responseToken);

      return Promise.resolve(response);
    }
    return Promise.reject(response);
  });
};

export {
  login,
  loginCheck,
};
