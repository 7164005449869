import { BETSLIP_BLOCK_EVENT_ID, betslipBlockEventPubSub } from '@/modules/betslip';

/* @ngInject */
function LimitsService(
  $http,
  $rootScope,
  $log,
  SevenRoutes,
  SevenGamesSvc,
  nabTrans,
  nabMessaging,
  LocalData
) {
  var generalLimitReached = false;
  var gameLimitReached = {};

  function init() {
    // Get limit state
    $http.get(SevenRoutes.mainUrl + '/get/unit/state/entityUuid/' + LocalData.betshop.uuid).then(function (response) {
      if (response.data.limitReached) {
        response.data.type.forEach(function (game) {
          if (game === 'general') {
            generalLimitReached = true;
          } else {
            gameLimitReached[game] = true;
          }

          betslipBlockEventPubSub.publish(BETSLIP_BLOCK_EVENT_ID, {
            id: 'LimitReached:' + game,
            message: checkLimits(game),
            productId: game === 'general' ? '*' : game
          });
        });
      }
    }).catch((err) => {
      $log.error('[7T.Limits] Failed to fetch limits on app startup', {
        code: 'T_SVN_LIMITS_INIT_FAILED',
        errorDetails: err.data,
        upstream_code: err.status,
        upstream_status: err.statusText
      });
    });
  }

  nabMessaging.subscribe('betshop:limitReached', function (event, message) {
    var type = message.data.type;

    if (type === 'general') {
      generalLimitReached = true;
    } else {
      gameLimitReached[type] = true;
    }

    betslipBlockEventPubSub.publish(BETSLIP_BLOCK_EVENT_ID, {
      id: 'LimitReached:' + type,
      message: checkLimits(type),
      productId: type === 'general' ? '*' : type
    });
  });

  nabMessaging.subscribe('betshop:limitRemoved', function (event, message) {
    var type = message.data.type;

    if (message.data.type === 'general') {
      generalLimitReached = false;
    } else {
      gameLimitReached[type] = false;
    }

    $rootScope.$emit(
      '7T:Betslip.Unblock',
      {
        namespace: 'LimitReached:' + type
      }
    );
  });

  function checkLimits(gameId) {
    var game = SevenGamesSvc.getGame(gameId);

    if (generalLimitReached) {
      return nabTrans.translate('limits.general_limit_reached', {}, true);
    } if (game && gameLimitReached[gameId]) {
      return nabTrans.translate('limits.game_limit_reached', { game: game.translation }, true);
    }

    return false;
  }

  return {
    getGeneralLimit: function () {
      return generalLimitReached;
    },
    getGameLimit: function () {
      return gameLimitReached;
    },
    init: init,
    checkLimits: checkLimits
  };
}

export default LimitsService;
