import { defineStore } from 'pinia';
import { ref } from 'vue';
import { AppMode } from '@/common/enums';
import HooksManager from '@/common/services/HooksManager';
import { BeforeTicketActionParams } from '@/common/types';
import { TicketInstance } from '@/modules/seven-betslip-api';
import { tboGlobalDataService } from '@/modules/tbo';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { TicketCheckAbortedError } from '@/modules/ticket-check';
import type { TicketPayoutNotification } from './types';

const hooks = [
  HooksManager.TboBeforeTicketPayout,
  HooksManager.BeforeTicketCancel,
  HooksManager.BeforeTicketPayout,
];

export const usePinValidationStore = defineStore('pinValidationStore', () => {
  const showTicketPinModal = ref(false);
  const resolveTicketPin = ref<(value: string) => void>(() => {});
  const rejectTicketPin = ref<(
  value: TicketPayoutNotification | TicketCheckAbortedError
  ) => void>(() => {},
    );

  const isPinValidationRequired = (ticket: TicketInstance) => {
    const envHandlerStore = useEnvironmentHandlerStore();
    const configSettingsStore = useConfigSettingsStore();
    const gravitySettingsStore = useGravitySettingsStore();
    const skipPinForGame = gravitySettingsStore.getModuleDataKeyValue(`module.${ticket.getProductDisplayId()}`, 'pinRequired');
    let isPinRequired: boolean;

    if (AppMode.BACK_OFFICE === envHandlerStore.getState('activeApp')) {
      isPinRequired = tboGlobalDataService.tboGlobalData.ticketActions.pinRequred;
    } else {
      isPinRequired = configSettingsStore.ticketActions.pinRequred;
    }

    const pinSet = ticket.getPin();

    return isPinRequired && skipPinForGame !== false && !pinSet;
  };

  const pinValidationHookMethod = (params: BeforeTicketActionParams) => new Promise<void>(
    (resolve, reject) => {
      if (!isPinValidationRequired(params.ticket)) {
        resolve();
        return;
      }

      showTicketPinModal.value = true;

      resolveTicketPin.value = (pin: string) => {
        params.ticket.setPin(pin);
        resolve();
      };
      rejectTicketPin.value = reject;
    },
  );

  hooks.forEach((hook) => {
    hook.tapPromise(
      {
        name: 'PinValidation',
      },
      pinValidationHookMethod,
    );
  });

  return {
    showTicketPinModal,
    resolveTicketPin,
    rejectTicketPin,
    isPinValidationRequired,
  };
});
