import i18n from '@/plugins/i18n';
import BaseError from '@/common/errors/BaseError';
import { logService } from '@/common/services/logger';
import { scanEventPubSub, SCAN_EVENT_ID } from '@/modules/handheld-scan';
import { getService } from '@/common/services/ngBridge';
import HooksManager from '@/common/services/HooksManager';
import { errorParser } from '@/common/services/error-parser';
import { isPayoutDisabled } from '@/modules/tickets/services/payoutService';
import { BeforeTicketActionParams } from '@/common/types';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { googleAnalyticsService } from '@/modules/google-analytics';
import ticketCheckStrategyService from './ticketCheckStrategyService';
import ticketCheckService from './ticketCheckService';

const LOG_PREFIX = '[ticketCheckEventsService]';
const { t } = i18n.global;
let scanListener : string | undefined;

const handleScanListener = () => {
  scanListener = scanEventPubSub.subscribe(SCAN_EVENT_ID, (event, eventData) => {
    if ((!eventData?.callSign || !eventData?.callSign.sufix) && eventData?.data?.code) {
      const strategyDetails = ticketCheckStrategyService
        .getTicketCheckStrategyDetails(eventData.data.code);
      const { hash } = window.location;

      if (strategyDetails?.productId) {
        const { productId } = strategyDetails;
        const switchView: { selectService: Function } | undefined = getService('switchView');
        logService.debug(`${LOG_PREFIX} Scanning finished, redirecting to ticket check.`, {
          code: 'T_TICKET_SCAN_FINISHED',
          ticket_code: eventData.data.code,
          product_displayid: productId,
          details: {
            currentRoute: hash,
          },
        });
        googleAnalyticsService.trackEvent('Ticket_Check', {
          module: productId,
          event: 'Scan',
        });
        switchView?.selectService('Results', false, false).then(() => {
          logService.debug(`${LOG_PREFIX} Redirected to ticket check.`, {
            code: 'T_TICKET_SCAN_REDIRECT',
            ticket_code: eventData.data.code,
            roduct_displayid: productId,
            details: {
              currentRoute: hash,
            },
          });
          ticketCheckService.checkTicketCode(eventData.data.code);
        }).catch((err: unknown) => {
          logService.warn(`${LOG_PREFIX} Redirection to ticket check failed.`, {
            code: 'T_TICKET_SCAN_REDIRECT_ERROR',
            roduct_displayid: productId,
            ticket_code: eventData.data.code,
            details: {
              currentRoute: hash,
            },
            ...errorParser.parseUpstream(err),
          });
        });
      } else {
        logService.warn(`${LOG_PREFIX} Strategy not found to.`, {
          code: 'T_TICKET_SCAN_STRATEGY_NOT_FOUND',
          ticket_code: eventData.data.code,
          details: {
            currentRoute: hash,
          },
        });
      }
    }
  });
};

const registerHooks = () => {
  HooksManager.BeforeTicketPayout.tapPromise({
    name: 'BeforeTicketPayout.PayoutDisabledCheck',
  }, (params: BeforeTicketActionParams) => {
    if (isPayoutDisabled(params.ticket.getPossiblePayoutAmount())) {
      const notificationsStore = useNotificationsStore();
      notificationsStore.closeNotificationWithId('ticket.pending_action');
      const message = t('ticket.disable_anonymous_payout');
      const code = 'PAYOUT_DISABLED';
      const error = new BaseError(message, code);

      logService.warn(`${LOG_PREFIX} Ticket cannot be paid out, payout is disabled.`, {
        code,
        ticket_code: params.ticket.getDisplayId(),
        request_id: params.ticket.getRequestUuid(),
        product_displayid: params.ticket.getProductDisplayId(),
      });

      notificationsStore.show({
        message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });

      return Promise.reject(error);
    }
    return Promise.resolve();
  });
};

const unload = () => {
  if (scanListener) {
    scanEventPubSub.unsubscribe(scanListener);
    scanListener = '';
  }
};

const init = () => {
  handleScanListener();
  registerHooks();
};

export default { init, unload };
