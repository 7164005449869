import PubSub from 'pubsub-js';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { logService } from '@/common/services/logger';
import { getService } from '@/common/services/ngBridge';
import type { SevenPayment, PaymentSettings } from './types';
import { PAYMENT_METHOD_CHANGED_EVENT_ID, paymentMethodChangedEventPubSub } from './paymentMethodChangedEvent';

const usePaymentsStore = defineStore('paymentsStore', () => {
  const paymentSettings = ref<PaymentSettings>({});
  const payments = ref<Array<SevenPayment>>([]);
  const promisesArray = ref<Array<{ id: string, cb: Function }>>([]);

  const changePaymentMethod = (settings: PaymentSettings) => {
    Object.assign(paymentSettings.value, settings);

    /**
   * @ngdoc event
   * @eventType emit on payment method change
   *
   * @description Fired when payment method changes. This will happen atleast two times, first when
   * method is active, second time when method is disabled.
   * @param {Object} eventData
   * @param {String} eventData.methodName Name of the method
   * @param {String} eventData.headerBalanceSubtitle
   * @param {String} eventData.headerBalanceClass
   */
    paymentMethodChangedEventPubSub.publish(PAYMENT_METHOD_CHANGED_EVENT_ID, paymentSettings.value);

    logService.debug('[paymentsStore] Payment method changed.', {
      ...paymentSettings.value,
      code: 'T_PAYMENT_METHOD_CHANGED',
    });
  };

  const getPayment = (integrationName:string) => payments.value
    .find((payment) => payment.integrationName === integrationName);

  const registerOnWalletPluginChange = (id: string, cb: Function) => {
    promisesArray.value.push({ id, cb });

    PubSub.subscribe('Wallet.PluginUpdate', (message, data) => {
      logService.info('[paymentsStore] Received plugin update from SCM. Changing wallet plugin state', {
        code: 'T_WALLET_PLUGIN_UPDATE',
        data,
        id,
      });
      promisesArray.value.forEach((promise) => {
        if (data.name === promise.id) {
          cb(data);
        }
      });
    });
  };

  const restoreToDefaultMethod = () => {
    changePaymentMethod({
      methodName: undefined,
      headerBalanceSubtitle: undefined,
      headerBalanceClass: undefined,
    });
  };

  const setPayments = (data: Array<SevenPayment>) => {
    const Modules: any = getService('Modules');
    const searchRegExp = /-/g;
    const whitelistedModules = ['deviceCashDeposit']; // exceptional modules that need to be registred even though enabled prop is false

    payments.value = [...data.filter(
      (payment) => payment.enabled || whitelistedModules.includes(payment.integrationName),
    )];

    payments.value.forEach((payment) => {
      const parsedName = payment.integrationName.toLowerCase().replace(searchRegExp, '_');
      const item = {
        [parsedName]: {
          ...payment,
          enabled: true,
        },
      };
      Modules.registerModules(item); // modules defined dynamically
    });
  };

  const getPayments = () => payments.value;

  return {
    paymentSettings,
    restoreToDefaultMethod,
    changePaymentMethod,
    getPayment,
    registerOnWalletPluginChange,
    setPayments,
    getPayments,
  };
});

export {
  usePaymentsStore,
};
