type SevenTenant = {
  currency: string,
  datacenter: string,
  fractionDigits: number,
  locale: string,
  name: string,
  uuid: string,
  taxAuthority?: string,
  id: number
};

type BackOfficeSevenTenant = {
  currency: string,
  fractionDigits: number,
  locale: string,
  name: string,
  uuid: string,
  taxAuthority?: string,
  id?: number
  language: string,
};

type SevenBetshop = {
  address: string,
  city: string,
  displayId: string,
  id: string,
  name: string,
  uuid: string
};

type SevenTerminalDevice = {
  uuid: string,
  deviceUuid: string,
  balance: number,
  name: string,
  moneyDenominations: number[],
  cashRegisterUuid: string,
};

type SevenDevice = {
  deviceId: number,
  name: string,
  token: string,
  uuid: string
};

type SevenModuleGeneral = {
  actions: Array<{ id: string, translation: string }>,
  id: string,
  priority: number,
  submodules?: Array<unknown>,
  translation: string,
  type: 'general'
};

type SevenModuleGame = {
  category: string,
  cpvUuid: string,
  directConfigRoute: string | null,
  uuid: string,
  version: string,
  actions: Array<{ id: string, translation: string }>,
  id: string,
  priority: number,
  submodules?: Array<unknown>,
  translation: string,
  type: 'product'
};

type SevenTicketActions = {
  pinRequred: boolean,
  voucherPinEnabledOnTerminal: boolean
};

export enum SevenUserTypes {
  Operator = 'Operator',
  Anonymous = 'Anonymous',
}

export enum SevenAppType {
  TerminalAdmin = 'TerminalAdmin',
}

export type {
  SevenBetshop,
  SevenTerminalDevice,
  SevenDevice,
  SevenTenant,
  BackOfficeSevenTenant,
  SevenModuleGeneral,
  SevenModuleGame,
  SevenTicketActions,
};
