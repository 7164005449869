import axios from 'axios';
import type { SevenDetectedPrinters } from '../types';
import { getSpsRequestUrl } from '../printerHelpers';

const getDetectedPrinters = () => (
  axios.get<{ printers: SevenDetectedPrinters }>(getSpsRequestUrl())
);

export {
  getDetectedPrinters,
};
