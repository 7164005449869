import { defineStore } from 'pinia';
import { ref } from 'vue';
import type MaintenanceData from './interfaces/MaintenanceData';

const useMaintenanceStore = defineStore('maintenanceStore', () => {
  const isMaintenanceActive = ref<boolean>(false);
  const maintenanceData = ref<MaintenanceData | null>(null);

  const activateMaintenance = (data: MaintenanceData) => {
    isMaintenanceActive.value = true;
    maintenanceData.value = data;
  };

  const deactivateMaintenance = () => {
    isMaintenanceActive.value = false;
    maintenanceData.value = null;
  };

  const isGameInMaintenanceState = (productId: string) => (
    !!maintenanceData.value?.disabledProducts?.some((id: string) => id === productId)
  );

  return {
    isMaintenanceActive,
    maintenanceData,
    activateMaintenance,
    deactivateMaintenance,
    isGameInMaintenanceState,
  };
});

export {
  useMaintenanceStore,
};
