import '@nsftx/seven-ng-print-service/dist/ng-print-service';
import angular from 'angular';
import commonModule from '../common';
import coreModule from '../core';

angular.module('7Terminal.Peripherals', [
  coreModule,
  commonModule,
  'nabPrintService'
]);
