import { defineStore } from 'pinia';
import { ref } from 'vue';

const useInactivityTimerStore = defineStore('inactivityTimerStore', () => {
  const showIdleModalNotification = ref(false);

  const setShowIdleModalNotification = (value: boolean) => {
    showIdleModalNotification.value = value;
  };

  return {
    showIdleModalNotification,
    setShowIdleModalNotification,
  };
});

export {
  useInactivityTimerStore,
};
