import { isAxiosError } from 'axios';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { getService } from '@/common/services/ngBridge';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import i18n from '@/plugins/i18n';
import { scanEventPubSub, SCAN_EVENT_ID } from '@/modules/handheld-scan';
import { logService } from '@/common/services/logger';
import { useCustomerCardStore } from './store';
import { fetchCustomerCardUserDataById } from './apiService';

const LOG_PREFIX = '[customerCardService]';
const { t } = i18n.global;
let scanEventListener: string | undefined;

const getCustomerCardDefinition = () => {
  const customerCardStore = useCustomerCardStore();
  const cardInstance = customerCardStore.activeCustomerCardInstances;
  return cardInstance?.[0];
};

const loadCustomerCardModules = () => {
  const customerCardDefinition = getCustomerCardDefinition();
  const Modules: any = getService('Modules');

  if (customerCardDefinition && customerCardDefinition.tasks?.length) {
    const searchRegExp = /-/g;
    customerCardDefinition.tasks.forEach((task) => {
      const taskName = task.textId.toLowerCase().replace(searchRegExp, '_');
      Modules.registerModules({
        [taskName]: {
          enabled: true,
        },
      });
    });

    Modules.loadModulesByPosition('afterCustomerCardModuleReady');
  }
};

const scanListener = () => {
  const customerCardConfig = getCustomerCardDefinition();

  // do not attach listener if don't active customer card instance
  if (!customerCardConfig) {
    return;
  }

  scanEventListener = scanEventPubSub.subscribe(SCAN_EVENT_ID, (event, eventData) => {
    const { validationPattern, id } = customerCardConfig;
    const re = new RegExp(validationPattern);
    const code = eventData?.data.code;

    logService.info(`${LOG_PREFIX} Scan event received`, {
      code: 'T_CUSTOMER_CARD_SCAN_EVENT_RECEIVED',
      cardDetails: eventData?.data,
      cardNumber: code,
    });

    if (code && re.test(code)) {
      logService.info(`${LOG_PREFIX} Customer card received on scan`, {
        code: 'T_CUSTOMER_CARD_SCANNED',
        cardDetails: eventData?.data,
        cardNumber: code,
      });
      fetchCustomerCardUserDataById(
        id || '',
        eventData.data.code,
      ).then((response) => {
        logService.info(`${LOG_PREFIX} Customer card data received from API`, {
          code: 'T_CUSTOMER_CARD_GET_DETAILS_HTTP_RESPONSE',
          cardDetails: eventData?.data,
          cardNumber: code,
          response,
        });
        const customerCardStore = useCustomerCardStore();
        customerCardStore.setCustomerCardUserData(response.data);
      }).catch((err) => {
        const notificationsStore = useNotificationsStore();
        const parser = isAxiosError(err) ? axiosErrorParser : errorParser;
        logService.warn(`${LOG_PREFIX} Customer card data API rejected from server`, {
          code: 'T_CUSTOMER_CARD_GET_DETAILS_HTTP_ERROR',
          err,
          ...parser.parseUpstream(err),
        });
        notificationsStore.show(
          {
            message: err.response?.status === 404 ? t('customer_cards_unknown_card') : (err.response?.data as any)?.description || err.message,
            type: TNotificationTypeEnum.error,
            delay: 5000,
          },
        );
      });
    }
  });
};

const unsubscribeFromScan = () => {
  if (scanEventListener) {
    scanEventPubSub.unsubscribe(scanEventListener);
    scanEventListener = '';
  }
};

function init() {
  loadCustomerCardModules();
  scanListener();
}

export {
  init,
  getCustomerCardDefinition,
  unsubscribeFromScan,
  scanListener,
};
