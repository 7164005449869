import { defineStore } from 'pinia';
import { ref } from 'vue';
import BaseError from '@/common/errors/BaseError';
import type { Printer, DetectedPrinters, PrintTemplateConfig } from '@/modules/print';
import type { PrinterService } from './types/printerServiceType';

export const SELECTED_PRINTER_LS_KEY = 'settings.printerId';

const usePrinterStore = defineStore('printerStore', () => {
  const selectedPrinter = ref<Printer | null>(null);
  const detectedPrinters = ref<DetectedPrinters>([]);
  const printerService = ref<PrinterService | null>(null);
  const printTemplateConfig = ref<PrintTemplateConfig | null>(null);

  const getPrinterService = () => {
    if (!printerService.value) {
      throw new BaseError(
        'Printer service is not set in the app',
        'T_PRINT_SERVICE_SET_FAILED',
      );
    }

    return printerService.value;
  };

  const setPrinterService = (service: PrinterService) => {
    printerService.value = service;
  };

  const setSelectedPrinter = (printer: Printer) => {
    selectedPrinter.value = printer;
    localStorage.setItem(SELECTED_PRINTER_LS_KEY, JSON.stringify(printer));
  };

  const removeSelectedPrinter = () => {
    selectedPrinter.value = null;
    localStorage.removeItem(SELECTED_PRINTER_LS_KEY);
  };

  const setDetectedPrinters = (printers: DetectedPrinters) => {
    detectedPrinters.value = printers;
  };

  const setPrintTemplateConfig = (configData: PrintTemplateConfig) => {
    printTemplateConfig.value = configData;
  };

  return {
    getPrinterService,
    setPrinterService,
    setSelectedPrinter,
    setDetectedPrinters,
    setPrintTemplateConfig,
    printerService,
    selectedPrinter,
    detectedPrinters,
    printTemplateConfig,
    removeSelectedPrinter,
  };
});

export {
  usePrinterStore,
};
