enum VoucherStatus {
  OPEN = 'open',
  USED = 'used',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

const SEVEN_VOUCHER_DISPLAY_ID = 'Voucher';

type VoucherPayoutPayload = {
  deliveryPlatform: string,
  paymentMethod: string,
  deviceUuid: string,
  requestUuid?: string,
  user: string,
  userProcessor: {
    type: string,
    uuid: string
  },
  id?: string,
  ticketPin?: string
};

type VoucherGetPayload = {
  amount: number,
  totalAmount: number, // backend requires both amount and totalAmount
  requestUuid: string
};

type VoucherAdditionalData = {
  requestUuid?: string,
  id?: string,
  action?: string,
  amount?: number,
  product?: string, // Voucher
};

type VoucherResponse = {
  cancellable?: boolean,
  createdAt: string,
  currency: string,
  id: string,
  product: string,
  status: VoucherStatus,
  totalAmount: number,
  updatedAt?: string | null,
  validUntil: string,
  validUntilUTC: number,
  voucherDateTimeUTC: number,
  ticketPin?: string,
};

type VoucherActionResponse = VoucherResponse & VoucherAdditionalData;

enum WithdrawBalanceOptions {
  PRINT = 'print',
  DIGITAL = 'digital',
}

type WithdrawBalanceOptionsListItem = {
  value: WithdrawBalanceOptions,
  label: string,
};

export {
  VoucherStatus,
  WithdrawBalanceOptions,
  SEVEN_VOUCHER_DISPLAY_ID,
};

export type {
  VoucherPayoutPayload,
  VoucherGetPayload,
  VoucherActionResponse,
  VoucherAdditionalData,
  VoucherResponse,
  WithdrawBalanceOptionsListItem,
};
