enum ReportIdentifiers {
  SHIFT_REPORT = 301,
  WALLET_MONEY_REPORT = 302,
  BILLING_REPORT = 101,
  PROFIT_REPORT = 201,
  PRODUCT_PROFIT_REPORT = 202,
  TERNINAL_BALANCE_REPORT = 203,
  TURNOVER_REPORT = 204,
}

export { ReportIdentifiers };
