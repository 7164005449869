<template>
  <canvas ref="canvas" />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import QrCreator from 'qr-creator';
import { QrCodeConfig } from '../types';

const props = defineProps<{
  config: QrCodeConfig
}>();

const canvas = ref();

onMounted(async () => {
  await QrCreator.render({
    text: props.config.text,
    ecLevel: props.config.correctLevel || 'L',
    background: props.config.background || null, // color or null for transparent
    size: props.config.size || 150, // in pixels
  }, canvas.value);
});
</script>
