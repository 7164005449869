<script setup lang="ts">
import { onMounted } from 'vue';
import i18n from '@/plugins/i18n';
import { acceptorsService } from '@/modules/acceptors';
import HooksManager from '@/common/services/HooksManager';
import TboSidebar from '@/modules/tbo/components/TboSidebar.vue';
import TboHeader from '@/modules/tbo/components/TboHeader.vue';
import TboFooter from '@/modules/tbo/components/footer/TboFooter.vue';
import { logService } from '@/common/services/logger';
import { axiosErrorParser } from '@/common/services/error-parser';
import { useTicketScanInTbo } from '@/modules/tbo-tickets';
import { useTboCardReadListeners } from '@/modules/tbo/composables/tboCardReadListeners';

const { t } = i18n.global;
const { attachListener } = useTicketScanInTbo();
const { registerListeners } = useTboCardReadListeners();

attachListener();

const handleAcceptors = () => {
  logService.info('[TboPage] Stopping acceptors...', {
    code: 'T_DCD_STOP_ACCEPTORS_REQUEST',
    upstream_code: 'TBO',
  });
  acceptorsService.stopAcceptors(true).catch((err) => {
    logService.error('[TboPage] Failed to block accepting money on BackOffice init.', {
      ...axiosErrorParser.parseUpstream(err),
      code: 'TBO_STOP_ACCEPTORS_ERROR',
    });
  });
};

const registerHooks = () => {
  HooksManager.getHook('BeforeAcceptorsStart').tap({
    name: 'BeforeAcceptorsStart.Tbo',
    fn: (params: { errorResponses: Array<{
      message: string,
      code: string,
    }> }) => {
      params.errorResponses.push({
        message: t('acceptors_start_error_tbo'),
        code: 'T_BEFORE_ACCEPTORS_START_TBO_ERROR',
      });
    },
  });
};

onMounted(() => {
  handleAcceptors();
  registerHooks();
  registerListeners();
});

</script>

<template>
  <div class="h-100 d-block">
    <TboHeader />

    <div class="tbo-page-content sc-mt-3 sc-mx-3">
      <div class="row sc-mx-0 h-100">
        <div class="col-sm-4 sc-px-0">
          <TboSidebar />
        </div>

        <div class="col-sm-20 h-100">
          <div class="sc-mr-0 sc-ml-3 h-100 d-block">
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <TboFooter />
  </div>
</template>

<style lang="scss">
$footer-height: 64px;

.tbo-page-content {
  position: relative;
  height: calc(100% - #{$footer-height} - var(--sc-spacing-3));
}
</style>
