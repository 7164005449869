enum PrinterStatusCode {
  Ok = 'ok',
  Error = 'error',
  Warning = 'warning',
  Undefined = 'undefined',
}

enum PrinterSpsStatusSeverity {
  Off = 0,
  Info = 1,
  Warning = 2,
  Error = 3,
}

enum PrinterPaperStatusChangeAction {
  PaperLow = 'paperLow',
  PaperOut = 'paperOut',
  Offline = 'offline',
}

export {
  PrinterStatusCode,
  PrinterSpsStatusSeverity,
  PrinterPaperStatusChangeAction,
};
