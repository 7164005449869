import { range, each } from 'underscore';
import templateUrl from './h2hBettingArea.html';

(function () {
  angular.module('terminalApp.sevenVirtualMotorcycleSpeedway')
    .component('vmsH2hBettingArea', {
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        $state,
        VmsUiService
      ) {
        var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');

        this.$onInit = function () {
          this.racersFirstPlace = range(1, VMS.numberOfRacers + 1);
          this.racersSecondPlace = range(1, VMS.numberOfRacers + 1);
          this.activeGame = VMS;
          this.vmsUISvc = VmsUiService;
          this.createCombinations();
        };

        /** Create combinations for h2h bet * */

        this.createCombinations = function () {
          var self = this;
          this.combinations = [];

          each(self.racersFirstPlace, function (firstPlace) {
            each(self.racersSecondPlace, function (secondPlace) {
              if (firstPlace !== secondPlace) {
                VmsUiService.headToHead[firstPlace + '-' + secondPlace] = false; // keep track of active bet

                if (self.combinations.indexOf(secondPlace + '-' + firstPlace) < 0) {
                  self.combinations.push(firstPlace + '-' + secondPlace); //  store six combinations for betting view
                }
              }
            });
          });
        };

        this.sendBet = function (first, second) {
          var odd = VMS._bettingSvc.normalBetsOdds.h2h[first + '-' + second];
          var betToSend = {
            bet: 'headToHead',
            value: odd,
            racers: first + '-' + second
          };

          VMS._bettingSvc.clearAll();
          VMS._bettingSvc.ticketInput.input.push(first);
          VMS._bettingSvc.ticketInput.secondPlace.push(second);
          VMS._bettingSvc.ticketInput.h2hFirstPlace = first;
          VmsUiService.addBet(betToSend);
        };
      }
    });
})();
