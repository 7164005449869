import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, computed } from 'vue';
import { SCIcon } from '@nsftx/seven-components';
import router from '@/router';
import { getService } from '@/common/services/ngBridge';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderHome',
  setup(__props) {

const activeState = ref('');
const showHomeIcon = computed(() => activeState.value !== '/home' && !activeState.value.includes('/lobby/'));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const switchView: any = getService('switchView');

router.beforeEach((route) => {
  activeState.value = route.path;
});

const redirectToHome = () => {
  switchView.selectService('home', false, false);
};

return (_ctx: any,_cache: any) => {
  return (showHomeIcon.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "h-100 d-flex align-items-center sc-pl-5 sc-pr-7",
        onClick: redirectToHome
      }, [
        _createVNode(_unref(SCIcon), {
          icon: "n-i-dashboard",
          size: "2xl"
        })
      ]))
    : _createCommentVNode("v-if", true)
}
}

})