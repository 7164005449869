import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-100 d-flex flex-column align-items-start sc-pt-9" }
const _hoisted_2 = { class: "sc-mb-7 w-100" }

import {
  computed, onMounted, onUnmounted, reactive, ref, watch,
} from 'vue';
import { dateTimeFilter } from '@/common/filters';
import {
  SCInput,
  SCButton,
  SCInlineNotification,
  NotificationVariant,
  ButtonVariant,
  SCKeyboard,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import TModal from '@/common/components/TModal.vue';
import { useSevenStore } from '@/modules/seven';
import { useIntelligentGamingStore } from '@/modules/intelligent-gaming/store';
import { savePlayerData } from '@/modules/intelligent-gaming/reports/apiService';
import { PlayerData } from '@/modules/intelligent-gaming/reports/PlayerData';
import { axiosErrorParser } from '@/common/services/error-parser';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { CLOSE_TICKET_CHECK_MODALS_ID, TicketCheckAbortedError, closeTicketCheckModalsEventPubSub } from '@/modules/ticket-check';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerInformationModal',
  setup(__props) {

const intelligentGamingStore = useIntelligentGamingStore();
const notificationsStore = useNotificationsStore();

const { t } = i18n.global;
const fullName = ref<string>('');
const idNumber = ref<string>('');
const contact = ref<string>('');
const address = ref<string>('');

const isSubmitted = ref<boolean>(false);
const isAlertVisible = ref<boolean>(false);

const showKeyboard = reactive({
  fullName: false,
  idNumber: false,
  contact: false,
  address: false,
});

const fullNameLabel = t('ig_player_form_full_name');
const idNumberLabel = t('ig_player_form_id_number');
const contactLabel = t('ig_player_form_contact');
const addressLabel = t('ig_player_form_address');

const fullNameHasError = computed(() => isSubmitted.value && !fullName.value);
const idNumberHasError = computed(() => isSubmitted.value && !idNumber.value);
const contactHasError = computed(() => isSubmitted.value && !contact.value);
const addressHasError = computed(() => isSubmitted.value && !address.value);

const fullNameErrorMessage = computed(
  () => (fullNameHasError.value
    ? t('main_field_is_required', { field: fullNameLabel })
    : ''
  ),
);

const idNumberErrorMessage = computed(
  () => (idNumberHasError.value
    ? t('main_field_is_required', { field: idNumberLabel })
    : ''
  ),
);

const contactErrorMessage = computed(
  () => (contactHasError.value
    ? t('main_field_is_required', { field: contactLabel })
    : ''
  ),
);

const addressErrorMessage = computed(
  () => (addressHasError.value
    ? t('main_field_is_required', { field: addressLabel })
    : ''
  ),
);
const formHasError = computed(() => [
  fullNameHasError.value,
  idNumberHasError.value,
  contactHasError.value,
  addressHasError.value,
].some((value) => value));

const resetForm = () => {
  fullName.value = '';
  idNumber.value = '';
  contact.value = '';
  address.value = '';
  isSubmitted.value = false;
};

const handleSubmit = () => {
  const sevenStore = useSevenStore();
  isSubmitted.value = true;
  isAlertVisible.value = formHasError.value;

  if (!formHasError.value) {
    const ticket = intelligentGamingStore.getTicket();

    const transactionType = intelligentGamingStore.getTransactionType();
    const payinAmount = ticket.getPayment();
    const payoutAmount = ticket.getPayoutAmountWithTax();
    const requestUuid = ticket.getRequestUuid();

    // Send it only on ticket payout
    const ticketId = ticket.getDisplayId();

    const payload: PlayerData = {
      address: address.value,
      contact: contact.value,
      date: dateTimeFilter(new Date(), 'YYYY-MM-DD'),
      fullName: fullName.value,
      idNumber: idNumber.value,
      terminalId: sevenStore.terminal.uuid,
      requestUuid,
      ticketId,
      time: dateTimeFilter(new Date(), 'HH:mm'),
      transactionAmount: transactionType === 'Bet' ? payinAmount : payoutAmount,
      transactionType,
    };

    if (transactionType === 'Bet') {
      // We don't have ticketId on ticket payin, so we don't need to send it
      // Also, on some products, ticket id is equal to ticket type (combo, single...)
      delete payload.ticketId;
    }

    savePlayerData(payload).then(() => {
      intelligentGamingStore.resolveCallback(true);
      intelligentGamingStore.setIsModalShown(false);
      resetForm();
    }).catch((err) => {
      notificationsStore.show({
        message: axiosErrorParser.parseMessage(err),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });

      logService.error('[IntelligentGaming] Fail to save player data.', {
        ...payload,
        ...axiosErrorParser.parseUpstream(err),
        code: 'T_IG_FAIL_TO_SAVE_PLAYER_DATA',
      });
    });
  }
};

const handleUpdateModelValue = (): void => {
  const transactionType = intelligentGamingStore.getTransactionType();

  intelligentGamingStore.rejectCallback({
    message: t('ig_modal_closed_notification'),
    code: transactionType === 'Bet' ? 'IG_PAYIN_CANCELED' : 'IG_PAYOUT_CANCELED',
  });
  intelligentGamingStore.setIsModalShown(false);
  resetForm();
};

watch(formHasError, (value) => {
  isAlertVisible.value = value;
});

let closeModalsSubscriptionId = '';

const errorMessage = [t('ig_player_form_error_message')];

onMounted(() => {
  closeModalsSubscriptionId = closeTicketCheckModalsEventPubSub
    .subscribe(CLOSE_TICKET_CHECK_MODALS_ID, (msg, data) => {
      if (intelligentGamingStore.isModalShown) {
        const code = 'T_IG_PLAYER_INFORMATION_MODAL_CLOSE_MODAL_EVENT';
        const error = new TicketCheckAbortedError('Closing PlayerInformationModal modal due to closeTicketCheckModalsEventPubSub', code);

        logService.warn('[PlayerInformationModal] Closing modal due to closeTicketCheckModalsEventPubSub', {
          code,
          upstream_message: data?.reason,
        });

        intelligentGamingStore.rejectCallback(error);
        intelligentGamingStore.setIsModalShown(false);
        resetForm();
      }
    });
});

onUnmounted(() => {
  if (closeModalsSubscriptionId) {
    closeTicketCheckModalsEventPubSub.unsubscribe(closeModalsSubscriptionId);
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: _unref(intelligentGamingStore).isModalShown,
    "onUpdate:modelValue": [
      _cache[13] || (_cache[13] = ($event: any) => ((_unref(intelligentGamingStore).isModalShown) = $event)),
      handleUpdateModelValue
    ],
    "modal-id": "player-information-modal",
    "with-close-icon": ""
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)("ig_player_modal_title")), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(SCKeyboard), {
          modelValue: fullName.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fullName).value = $event)),
          show: showKeyboard.fullName,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((showKeyboard.fullName) = $event)),
          class: "w-100 sc-p-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SCInput), {
              modelValue: fullName.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((fullName).value = $event)),
              block: "",
              label: _unref(fullNameLabel),
              error: fullNameHasError.value,
              "error-message": fullNameErrorMessage.value
            }, null, 8 /* PROPS */, ["modelValue", "label", "error", "error-message"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "show"]),
        _createVNode(_unref(SCKeyboard), {
          modelValue: idNumber.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((idNumber).value = $event)),
          show: showKeyboard.idNumber,
          "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((showKeyboard.idNumber) = $event)),
          class: "w-100 sc-p-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SCInput), {
              modelValue: idNumber.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((idNumber).value = $event)),
              block: "",
              label: _unref(idNumberLabel),
              error: idNumberHasError.value,
              "error-message": idNumberErrorMessage.value
            }, null, 8 /* PROPS */, ["modelValue", "label", "error", "error-message"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "show"]),
        _createVNode(_unref(SCKeyboard), {
          modelValue: contact.value,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((contact).value = $event)),
          show: showKeyboard.contact,
          "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((showKeyboard.contact) = $event)),
          class: "w-100 sc-p-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SCInput), {
              modelValue: contact.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((contact).value = $event)),
              block: "",
              label: _unref(contactLabel),
              error: contactHasError.value,
              "error-message": contactErrorMessage.value
            }, null, 8 /* PROPS */, ["modelValue", "label", "error", "error-message"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "show"]),
        _createVNode(_unref(SCKeyboard), {
          modelValue: address.value,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((address).value = $event)),
          show: showKeyboard.address,
          "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((showKeyboard.address) = $event)),
          class: "w-100 sc-p-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SCInput), {
              modelValue: address.value,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((address).value = $event)),
              block: "",
              label: _unref(addressLabel),
              error: addressHasError.value,
              "error-message": addressErrorMessage.value
            }, null, 8 /* PROPS */, ["modelValue", "label", "error", "error-message"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "show"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(SCInlineNotification), {
            modelValue: isAlertVisible.value,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((isAlertVisible).value = $event)),
            messages: errorMessage,
            variant: _unref(NotificationVariant).Error
          }, null, 8 /* PROPS */, ["modelValue", "variant"])
        ])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        width: "100%",
        block: "",
        onClick: handleSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)("ig_player_form_submit")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})