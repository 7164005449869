import { AsyncSeriesHook } from 'tapable';

(function () {
  angular.module('7Terminal.Common')
    .service('HooksManager', HooksManager);

  function HooksManager($log, $q) {
    this.hooks = {
      BeforeTicketPayin: new AsyncSeriesHook(['data', 'productId'])
    };

    this.run = function (hookName, params) {
      if (!this.hooks[hookName]) {
        $log.error(`[7Terminal.Common] Hook ${hookName} doesn't exist.`);
        return $q.reject({
          message: `[7Terminal.Common] Hook ${hookName} doesn't exist.`,
          code: 'T_HOOK_NOT_FOUND'
        });
      }

      return this.hooks[hookName].promise(params);
    };

    this.getHook = function (hookName) {
      return this.hooks[hookName];
    };
  }
})();
