import { SevenUserTypes } from '@/modules/seven';
import { useSevenActiveShiftStore } from '@/modules/tbo-shift';
import type { TboGlobalDataResponse } from './types';

const tboGlobalData: TboGlobalDataResponse = {
  appConfig: {
    avaliableLanguages: {
      value: [],
    },
    backOfficeInactivityTime: {
      value: 0,
    },
    dateFormat: {
      value: '',
    },
    disableAnonymousTicketPayout: {
      value: false,
    },
    infoPoint: {
      value: false,
    },
    moneyDenominations: {
      value: [],
    },
    numbersFormat: {
      decimalSeparator: null,
      thousandsSeparator: null,
      value: '',
    },
    scrollButtons: {
      value: false,
    },
    taxAuthority: {
      value: '',
    },
    terminalAdminTicketHistoryRange: {
      value: 0,
    },
    terminalCursor: {
      value: false,
    },
    terminalInactivityTime: {
      value: 0,
    },
    transactionTypes: {
      deposit: {
        balanceAdjustment: {},
        bankWire: {},
        deviceCashDeposit: {},
        directDeposit: {},
        iPay: {},
        nestPay: {},
        paysafecard: {},
        quickteller: {},
        retailWithdrawal: {},
        skrill: {},
        voucher: {},
        webPay: {},
      },
      withdrawal: {
        balanceAdjustment: {},
        bankWire: {},
        deviceCashDeposit: {},
        directDeposit: {},
        iPay: {},
        nestPay: {},
        paysafecard: {},
        quickteller: {},
        retailWithdrawal: {},
        skrill: {},
        voucher: {},
        webPay: {},
      },
    },
    userTypes: {
      anonymous: {
        value: false,
      },
      player: {
        value: false,
      },
    },
    voucherActivationConfirmation: {
      value: false,
    },
  },
  betshop: {
    address: '',
    city: '',
    displayId: '',
    id: '',
    name: '',
    uuid: '',
  },
  clientAppModules: [],
  company: {
    currency: '',
    fractionDigits: 0,
    id: 0,
    locale: '',
    name: '',
    taxAuthority: '',
    uuid: '',
    language: '',
  },
  configRoutes: {},
  deviceCashRegister: {
    hasActiveShift: false,
    name: '',
    uuid: '',
  },
  operator: {
    firstName: '',
    hasActiveShift: false,
    lastName: '',
    username: '',
    uuid: '',
  },
  staffCashRegisterOwned: null,
  terminal: {
    deviceUuid: '',
    name: '',
    uuid: '',
  },
  ticketActions: {
    pinRequred: false,
  },
  ticketHistoryRoutes: {},
  translations: [],
  userType: SevenUserTypes.Operator,
  workingTime: {
    days: {
      friday: [],
      monday: [],
      saturday: [],
      sunday: [],
      thursday: [],
      tuesday: [],
      wednesday: [],
    },
    type: '',
    uuid: '',
  },
};

const setData = (globalData: any): void => {
  const sevenActiveShift = useSevenActiveShiftStore();

  if (globalData) {
    Object.assign(tboGlobalData, globalData);
  }

  if (tboGlobalData.staffCashRegisterOwned) {
    sevenActiveShift.setActiveShift(tboGlobalData.staffCashRegisterOwned);
  }
};

export default {
  setData,
  tboGlobalData,
};
