import BaseError from '@/common/errors/BaseError';
import type { DmDisplayErrorType } from '../types/dmDisplayTypes';

export default class DmDisplayError extends BaseError<DmDisplayErrorType> {
  constructor(
    public message: string,
    public code: DmDisplayErrorType,
    public options?: {
      cause?: unknown;
    },
  ) {
    super(message, code, options);
    Object.setPrototypeOf(this, DmDisplayError.prototype);
    this.name = this.constructor.name;
  }
}
