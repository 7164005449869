import { defineStore } from 'pinia';
import { ref } from 'vue';

const useGravitySettingsStore = defineStore('gravitySettings', () => {
  const data = ref<Record<string, any>>({});

  const setData = (applicationSettings: any): void => {
    Object.assign(data.value, applicationSettings);
  };

  const getData = (): Record<string, any> => data.value;

  const getByKey = <T = Record<string, any>>(key: string): T => data.value[key] || {};

  const getModuleDataKeyValue = <T = any>(moduleName: string, key: string): T => {
    const moduleData = getByKey(moduleName) || {};
    return moduleData[key];
  };

  const updateSetting = (moduleName: string, newData: any): void => {
    const moduleData = getByKey(moduleName);
    if (moduleData) {
      Object.assign(moduleData, newData);
    }
  };

  return {
    data: data.value,
    setData,
    getData,
    getByKey,
    getModuleDataKeyValue,
    updateSetting,
  };
});

export {
  useGravitySettingsStore,
};
