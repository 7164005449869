import axios from 'axios';
import { PlayerData } from './PlayerData';

const axiosInstance = axios.create({
  baseURL: process.env.INTELLIGENT_GAMING_FICA_URL,
});

const savePlayerData = (payload: PlayerData) => axiosInstance({
  method: 'POST',
  url: '/api/v1/fica/player/data',
  data: payload,
});

export {
  savePlayerData,
};
