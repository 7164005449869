import {
  Core,
  Print,
  DmPrinter,
  DmDetectedPrinters,
  DmPrinterAddJobResponse,
} from '@nsftx/systems-sdk';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { usePrinterStore } from '@/modules/print/printerStore';
import { usePrinterStatusStore } from '@/modules/print/printerStatusStore';
import { parsePrinterStatusResponse } from '@/modules/print/printerMessagesParser';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { PrintDataAdditionalData, PrintDataInfo } from '@/modules/print/types';
import { getPrintTemplate, getTemplateDataForPrint } from '@/modules/print/services/printTemplateService';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { handlePrinterErrorStatus } from '@/modules/print/printerMessagesService';
import { getService } from '@/common/services/ngBridge';
import { PrinterStatusCode } from '@/modules/print/enums';
import DmPrintError from '../errors/DmPrintError';
import deviceManagementService from './deviceManagementService';

// Constants
const LOG_PREFIX = '[deviceManagementPrintService]';
const PRINT_READY_EVENT_TIMEOUT_DELAY_SEC = 10;

// Vars
let print: Print;
let isPrintReady = false;
let printReadyTimeout: number | undefined;

const saveSelectedPrinter = (printer: DmPrinter) => {
  const { setSelectedPrinter } = usePrinterStore();

  logService.info(`${LOG_PREFIX} Setting printer`, {
    code: 'T_DEVICE_MANAGEMENT_PRINT_SET_PRINTER',
    printer,
  });
  setSelectedPrinter(printer);
};

const getPrinterToSave = (printer: DmPrinter | null, detectedPrinters: DmDetectedPrinters) => {
  if (!printer) {
    return detectedPrinters[0].printer;
  }

  const isSelectedPrinterFound = detectedPrinters.some((detectedPrinter) => (
    detectedPrinter.printer.id === printer.id));
  if (isSelectedPrinterFound) {
    return printer;
  }

  return detectedPrinters[0].printer;
};

const setPrinterData = (printer: DmPrinter | null, detectedPrinters: DmDetectedPrinters) => {
  const { setDetectedPrinters } = usePrinterStore();
  const selectedPrinter = getPrinterToSave(printer, detectedPrinters);

  setDetectedPrinters(detectedPrinters);
  saveSelectedPrinter(selectedPrinter);
};

const getPrint = (core: Core): Promise<Print> => new Promise((resolve, reject) => {
  if (isPrintReady) {
    resolve(print);
    return;
  }

  print = new Print(core, false);
  print.on('ready', () => {
    logService.info(`${LOG_PREFIX} Print module successfully initialized.`, {
      code: 'T_DEVICE_MANAGEMENT_PRINT_INIT_SUCCESS',
    });
    isPrintReady = true;
    clearTimeout(printReadyTimeout);
    resolve(print);
  });

  printReadyTimeout = window.setTimeout(() => {
    reject(new DmPrintError(
      `Failed to resolve print ready event from DM runtime after ${PRINT_READY_EVENT_TIMEOUT_DELAY_SEC} seconds`,
      'T_DEVICE_MANAGEMENT_PRINT_INIT_ERROR',
    ));
  }, PRINT_READY_EVENT_TIMEOUT_DELAY_SEC * 1000);
});

const getDetectedPrinters = async () => {
  const core = await deviceManagementService.getCore();
  print = await getPrint(core);

  return print.index();
};

const setPrinterListeners = async () => {
  const core = await deviceManagementService.getCore();
  print = await getPrint(core);

  print.on('statusChange', (response) => {
    const { setPrinterStatusData } = usePrinterStatusStore();
    const peripheralsService = getService<{
      updatePeripheralsState:(type: string, value: Object) => void
    }>('Peripherals');
    const notificationsStore = useNotificationsStore();
    const printerStatus = parsePrinterStatusResponse(response);
    const printerStatusData = {
      valid: printerStatus.valid,
      statusLevel: printerStatus.code,
      errorMessage: printerStatus.message,
    };

    setPrinterStatusData(printerStatus);

    if (!printerStatus.valid) {
      logService.error(`${LOG_PREFIX} Printer status change - error detected.`, {
        data: printerStatusData,
        code: 'T_PRINTER_LPS_STATUS_ERROR',
        upstream_message: printerStatus.message || 'Printer socket status is invalid',
      });
      handlePrinterErrorStatus();
    } else {
      const { getModuleDataKeyValue } = useGravitySettingsStore();
      const showWarnings = getModuleDataKeyValue<boolean>('print', 'showWarnings');
      const shouldShowWarningNotification = showWarnings
      && printerStatus.code !== PrinterStatusCode.Undefined
      && printerStatus.message;

      notificationsStore.closeNotificationWithId('printer_error');
      notificationsStore.closeNotificationWithId('printer_warning');

      logService.debug(`${LOG_PREFIX} Printer status change - warning detected.`, {
        data: printerStatusData,
        code: 'T_PRINTER_STATUS_WARNING',
      });

      if (shouldShowWarningNotification) {
        notificationsStore.show({
          id: 'printer_warning',
          type: printerStatus.code as unknown as TNotificationTypeEnum,
          message: `${printerStatus.message}.`,
          delay: 3000,
        });
      }
    }

    peripheralsService?.updatePeripheralsState('printer', printerStatusData);
  });
};

/**
 * @throws {DmPrintError}
 */
const init = async (selectedPrinter: DmPrinter | null) => {
  const response = await getDetectedPrinters();
  const detectedPrinters = response.printers;

  if (!detectedPrinters?.length) {
    logService.warn(`${LOG_PREFIX} No detected printers`, {
      selectedPrinter,
      code: 'T_SDK_NO_DETECTED_PRINTERS',
    });
    return Promise.reject(new DmPrintError(
      'Cannot set printer because there is no detected printers',
      'T_DEVICE_MANAGEMENT_PRINT_NO_DETECTED_PRINTERS',
    ));
  }

  setPrinterData(selectedPrinter, detectedPrinters);
  setPrinterListeners();

  return Promise.resolve(selectedPrinter);
};

const getPrinterStatus = async () => {
  const { selectedPrinter } = usePrinterStore();
  const { id, queueName } = selectedPrinter as DmPrinter || {};
  const core = await deviceManagementService.getCore();
  print = await getPrint(core);

  return print.getStatus(queueName, id);
};

/**
 * @throws {DmPrintError}
 */
const getStatus = async () => {
  const { selectedPrinter } = usePrinterStore();
  const { setPrinterStatusData } = usePrinterStatusStore();
  const { t } = i18n.global;

  if (!selectedPrinter) {
    return Promise.reject(new DmPrintError(
      t('print.printer_not_selected'),
      'T_DEVICE_MANAGEMENT_PRINT_STATUS_ERROR',
    ));
  }

  const response = await getPrinterStatus();
  const printerStatus = parsePrinterStatusResponse(response);

  setPrinterStatusData(printerStatus);

  if (!printerStatus.valid) {
    logService.error(`${LOG_PREFIX} Printer status check failed`, {
      ...errorParser.parseUpstream(printerStatus),
      code: 'T_PRINTER_SDK_STATUS_ERROR',
    });
    return Promise.reject(new DmPrintError(
      printerStatus.message,
      'T_DEVICE_MANAGEMENT_PRINT_STATUS_ERROR',
      {
        context: { printerStatus },
      },
    ));
  }

  return Promise.resolve(printerStatus);
};

const isAddPrintJobResponseSuccess = (response: DmPrinterAddJobResponse):
response is { success: boolean } => (
  typeof response === 'object' && response.success
);

const addPrintJob = async (printTemplate: string) => {
  const { selectedPrinter } = usePrinterStore();
  const { id, model } = selectedPrinter as DmPrinter || {};
  const core = await deviceManagementService.getCore();

  print = await getPrint(core);
  const response = await print.addPrintJob(printTemplate, model, id);
  if (isAddPrintJobResponseSuccess(response)) {
    return Promise.resolve(response);
  }

  return Promise.reject(new DmPrintError(
    response,
    'T_DEVICE_MANAGEMENT_PRINT_ADD_JOB_ERROR',
  ));
};

const printJob = (
  printDataInfo: PrintDataInfo,
  context?: any,
  additionalData?: PrintDataAdditionalData,
) => {
  const { getModuleDataKeyValue } = useGravitySettingsStore();
  const config = getModuleDataKeyValue<Record<string, any>>(
    'sps',
    'templateConfig',
  );
  const {
    requestUuid,
    ticketCode,
    lang,
    template,
    templateName,
    mainData,
    addData,
    options,
    translations,
  } = getTemplateDataForPrint(printDataInfo, context, additionalData);
  const bindings = {
    additionalData: addData,
    mainData,
    context,
    translations,
    options,
    lang,
    config,
  };

  logService.debug(`${LOG_PREFIX} Sending template to print service.`, {
    additionalData: addData,
    ticket_code: ticketCode,
    request_id: requestUuid,
    context,
    templateName,
    mainData,
    options,
    lang,
    code: 'T_DM_SEND_TEMPLATE_TO_PRINT',
  });

  let printTemplate;
  try {
    printTemplate = getPrintTemplate(template, bindings);
  } catch (error) {
    return Promise.reject(new DmPrintError(
      errorParser.parseMessage(error),
      'T_DEVICE_MANAGEMENT_PRINT_GET_TEMPLATE_ERROR',
    ));
  }

  return addPrintJob(printTemplate);
};

export default {
  init,
  getDetectedPrinters,
  saveSelectedPrinter,
  getStatus,
  printJob,
};
