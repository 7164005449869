import { isTboActive } from '@/common/services/helpers';
import { hasTicketPin, stripTicketPin } from '@/modules/seven-betslip-api/helpers';
import { useClientGamesStore } from '@/modules/client-games';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { voucherManager } from '@/modules/vouchers';
import SevenTicketCheckStrategy from './SevenTicketCheckStrategy';
import SlaveTicketCheckStrategy from './SlaveTicketCheckStrategy';
import WebCodeTicketCheckStrategy from './WebCodeTicketCheckStrategy';
import type CMSStrategy from '../types/CMSStrategy';
import type StrategyService from '../types/StrategyService';
import StrategyNames from '../enums/StrategyNames';

const TICKET_CHECK_STRATEGIES: Record<StrategyNames, StrategyService> = {
  [StrategyNames.seven]: SevenTicketCheckStrategy,
  [StrategyNames.slave]: SlaveTicketCheckStrategy,
  [StrategyNames.web_code]: WebCodeTicketCheckStrategy,
};

const getStrategyService = (strategy: StrategyNames) => {
  // web_code strategy is not supported in back office
  if (isTboActive() && strategy === 'web_code') {
    return TICKET_CHECK_STRATEGIES.seven;
  }

  return TICKET_CHECK_STRATEGIES[strategy];
};

const getTicketCheckStrategyDetails = (codeToCheck: string): {
  productId: string,
  strategyService: StrategyService,
  name: StrategyNames,
} | undefined => {
  const clientGamesStore = useClientGamesStore();
  const products = clientGamesStore.getGames();
  let code = codeToCheck.replace(/ */, '');

  if (hasTicketPin(code)) {
    code = stripTicketPin(code);
  }

  let foundStrategy = StrategyNames.seven;
  const gravitySettingsStore = useGravitySettingsStore();
  const foundProduct = products.find((product) => {
    const strategies = gravitySettingsStore.getModuleDataKeyValue<Array<CMSStrategy>>(
      `module.${product.id}.ticketCheck`,
      'strategies',
    );

    const foundPattern = strategies?.find((strategy) => {
      const re = new RegExp(strategy.regex.pattern, strategy.regex.flags);
      return re.test(code);
    });

    if (foundPattern) {
      foundStrategy = foundPattern.strategy;
      return true;
    }

    return false;
  });

  if (!foundProduct && voucherManager.isVoucherCode(code)) {
    return {
      productId: 'Voucher',
      strategyService: TICKET_CHECK_STRATEGIES.seven,
      name: StrategyNames.seven,
    };
  }

  return {
    productId: foundProduct?.id || '',
    strategyService: getStrategyService(foundStrategy),
    name: foundStrategy,
  };
};

export default {
  getTicketCheckStrategyDetails,
};
