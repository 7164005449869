import { localAppConfigService } from '@/common/services/local-app-config';
import { useClientGamesStore } from '@/modules/client-games';
import { operatorService } from '@/modules/operator';
import { useSevenStore } from '@/modules/seven';
import { TicketMetaData } from '@/modules/seven-betslip-api';
import { useSevenActiveShiftStore } from '@/modules/tbo-shift';

const createTicketMetaData = (gameId: string, requestUuid: string): TicketMetaData => {
  const clientGamesStore = useClientGamesStore();
  const sevenStore = useSevenStore();
  const sevenActiveShiftStore = useSevenActiveShiftStore();

  const game = clientGamesStore.getGameById(gameId);

  return {
    product: game?.id || gameId,
    deliveryPlatform: 'Terminal',
    cpvUuid: game?.account.linkId || '',
    paymentMethod: 'Cash',
    requestUuid,
    appDeviceUuid: sevenStore.device.uuid,
    uuid: game?.account.uuid || '',
    sources: [
      {
        type: 'betshop',
        uuid: sevenStore.betshop.uuid,
      },
      {
        type: 'terminal',
        uuid: localAppConfigService.data.appConfig.terminalAccount.uuid,
      },
      {
        type: 'device',
        uuid: sevenStore.device.uuid,
      },
      {
        type: 'operator',
        uuid: operatorService.data.uuid,
      },
      {
        type: 'staffCashRegister',
        uuid: sevenActiveShiftStore.shift?.uuid || '',
      },
    ],
  };
};

export {
  createTicketMetaData,
};
