import { ref } from 'vue';
import type { AppConfigResponse } from './types';

const data = ref<AppConfigResponse>(
  {
    appConfig: {
      cashRegister: '',
      isHidden: false,
      products: [''],
      terminalAccount: {
        uuid: '',
        password: '',
      },
    },
    cpvUuids: [''],
    environment: '',
    isHidden: false,
    moneyCancelUrl: '',
    moneyCheckUrl: '',
    moneyDepositUrl: '',
    sevenSocketUrl: '',
    sevenUrl: '',
    windowTitle: '',
  },
);

const localAppConfigService = {
  data: data.value,
  setData(newData: AppConfigResponse) {
    Object.assign(this.data, newData);
  },

  getParsedEnvironment() {
    const splitted = data.value.environment.split('_');
    if (splitted.length) {
      const env = (splitted[splitted.length - 1]);

      return env.replace(/^./, env[0].toUpperCase());// uppercase first letter
    }

    return '';
  },
};

export default localAppConfigService;
