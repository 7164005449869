import { logService } from '@/common/services/logger';
import { CardNid } from '../enums';
import type { CardDataInfo, DmCardData } from '../interfaces';
import { useCardReaderStore } from '../store';

// Constants
const LOG_PREFIX = '[dmCardDataService]';
const CARD_WRITE_DATA_LIMIT = 128;
const CARD_WRITE_DATA_JUNK_LENGTH = 7;
const CARD_WRITE_DATA_JUNK_FILL_CHAR = '0';

const parseCardData = (rawCardData: string) => {
  let cardData: DmCardData | undefined;

  try {
    cardData = JSON.parse(rawCardData);
  } catch (err) {
    logService.error(`${LOG_PREFIX} Cannot parse received card_data.`, {
      code: 'DM_CARD_MODULE_PARSE_CARD_DATA_ERROR',
      rawCardData,
    });
  }

  return cardData;
};

const isNidValid = (nid: CardNid | undefined) => {
  const validNids = [CardNid.hdioa762, CardNid.passCard];

  return validNids.includes(nid || CardNid.formatted);
};

const isCardEmpty = () => {
  const { cardData } = useCardReaderStore();

  return !cardData || !isNidValid(cardData.Nid);
};

const getCardData = (cardData: DmCardData): CardDataInfo => ({
  uuid: cardData.u,
  hash: cardData.h,
  Nid: cardData.n,
  pinRequired: cardData.p,
});

const enrichCardData = (cardData: DmCardData) => {
  const { length } = JSON.stringify(cardData);

  if (length < CARD_WRITE_DATA_LIMIT) {
    const enrichedCardData = { ...cardData, j: '' };
    const diff = CARD_WRITE_DATA_LIMIT - length - CARD_WRITE_DATA_JUNK_LENGTH;
    enrichedCardData.j = ''.padEnd(diff, CARD_WRITE_DATA_JUNK_FILL_CHAR);

    return JSON.stringify(enrichedCardData);
  }

  return JSON.stringify(cardData);
};

export {
  parseCardData,
  getCardData,
  enrichCardData,
  isCardEmpty,
};
