import { getService } from '@/common/services/ngBridge';
import { useLanguageStore } from '@/common/stores/language';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';

const init = () => {
  const { getByKey } = useGravitySettingsStore();
  const { currentLang } = useLanguageStore();
  const printServiceApi = getService<{
    initializeService:(printer: string | number | object | null, lang?: string) => Promise<void>,
    updatePrintTemplate:(templateId: string) => void,
  }>('printServiceApi');
  const lang = currentLang?.id;

  printServiceApi?.initializeService(null, lang).then(() => {
    const { printTemplate } = getByKey('config') || {};
    if (printTemplate) {
      printServiceApi.updatePrintTemplate(printTemplate);
    }
  });
};

export {
  init,
};
