angular.module('7Terminal.Bootstrap')
  .factory('nabHTTPInterceptor', nabHTTPInterceptor);

/* @ngInject */
function nabHTTPInterceptor(
  $q,
  $log,
  $translate,
  $rootScope
) {
  function handleTimeoutedRejection(rejection) {
    if ((rejection.status === -1 || rejection.xhrStatus === 'timeout') && !rejection?.data?.message) {
      rejection.data = {
        message: $translate.instant('notifications.network_error'),
        code: 'T_REQUEST_TIMEOUT'
      };
      $log.debug('[7Terminal.Bootstrap] Request timeouted.', {
        message: $translate.instant('notifications.network_error'),
        code: 'T_REQUEST_TIMEOUT',
        upstream_code: rejection.status || rejection.xhrStatus,
        url: rejection.config?.url,
        params: rejection.config?.params,
        method: rejection.config?.method
      });
    }
  }

  return {
    request: function (config) {
      $rootScope.$emit('$http:request', config.url);
      return config;
    },

    requestError: function (rejection) {
      handleTimeoutedRejection(rejection);
      $rootScope.$emit('$http:response', rejection.config.url);
      return $q.reject(rejection);
    },

    response: function (response) {
      $rootScope.$emit('$http:response', response.config.url);
      return response;
    },

    responseError: function (rejection) {
      handleTimeoutedRejection(rejection);
      $rootScope.$emit('$http:response', rejection.config.url);

      if (rejection.status === 401) {
        $rootScope.$emit('$http:unauthorizedRequest', rejection.data);
      }

      return $q.reject(rejection);
    }
  };
}
