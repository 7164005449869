import { defineStore } from 'pinia';
import { ref } from 'vue';
import { AppMode } from '@/common/enums';
import HooksManager from '@/common/services/HooksManager';
import { BeforeTicketActionParams } from '@/common/types';
import { TicketInstance } from '@/modules/seven-betslip-api';
import { tboGlobalDataService } from '@/modules/tbo';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { TicketCheckAbortedError } from '@/modules/ticket-check';
import type { VoucherPayoutPayload } from '@/modules/vouchers/types';
import type { TicketPayoutNotification } from './types';

const ticketHooks = [
  HooksManager.TboBeforeTicketPayout,
  HooksManager.BeforeTicketCancel,
  HooksManager.BeforeTicketPayout,
];

const voucherHooks = [HooksManager.BeforeVoucherPayout];

export const usePinValidationStore = defineStore('pinValidationStore', () => {
  const showSecurityPinModal = ref(false);
  const resolveSecurityPin = ref<(value: string) => void>(() => {});
  const rejectSecurityPin = ref<(
  value: TicketPayoutNotification | TicketCheckAbortedError
  ) => void>(() => {},
    );

  const isTicketPinValidationRequired = (ticket: TicketInstance): boolean => {
    const envHandlerStore = useEnvironmentHandlerStore();
    const configSettingsStore = useConfigSettingsStore();
    const gravitySettingsStore = useGravitySettingsStore();

    const skipPinForGame = gravitySettingsStore.getModuleDataKeyValue(
      `module.${ticket.getProductDisplayId()}`,
      'pinRequired',
    );

    const isPinRequired = envHandlerStore.getState('activeApp') === AppMode.BACK_OFFICE
      ? tboGlobalDataService.tboGlobalData.ticketActions.pinRequred
      : configSettingsStore.ticketActions.pinRequred;

    return isPinRequired && skipPinForGame !== false && !ticket.getPin();
  };

  const isVoucherPinValidationRequired = (voucher: VoucherPayoutPayload): boolean => {
    const envHandlerStore = useEnvironmentHandlerStore();
    const configSettingsStore = useConfigSettingsStore();

    const isVoucherPinEnabledOnTerminal = envHandlerStore.getState('activeApp') === AppMode.BACK_OFFICE
      ? tboGlobalDataService.tboGlobalData.ticketActions.voucherPinEnabledOnTerminal
      : configSettingsStore.ticketActions.voucherPinEnabledOnTerminal;

    return isVoucherPinEnabledOnTerminal && !voucher.ticketPin;
  };

  const handleTicketPinValidation = (params: BeforeTicketActionParams) => new Promise<void>(
    (resolve, reject) => {
      if (!isTicketPinValidationRequired(params.ticket)) {
        resolve();
        return;
      }

      showSecurityPinModal.value = true;
      resolveSecurityPin.value = (pin: string) => {
        params.ticket.setPin(pin);
        resolve();
      };
      rejectSecurityPin.value = reject;
    },
  );

  const handleVoucherPinValidation = (voucherData: VoucherPayoutPayload) => new Promise<void>(
    (resolve, reject) => {
      if (!isVoucherPinValidationRequired(voucherData)) {
        resolve();
        return;
      }

      showSecurityPinModal.value = true;
      resolveSecurityPin.value = (pin: string) => {
        const updatedVoucherData = { ...voucherData, ticketPin: pin };
        Object.assign(voucherData, updatedVoucherData);
        resolve();
      };
      rejectSecurityPin.value = reject;
    },
  );

  ticketHooks.forEach((hook) => hook.tapPromise({ name: 'TicketPinValidation' }, handleTicketPinValidation));
  voucherHooks.forEach((hook) => hook.tapPromise({ name: 'VoucherPinValidation' }, handleVoucherPinValidation));

  return {
    showSecurityPinModal,
    resolveSecurityPin,
    rejectSecurityPin,
    isTicketPinValidationRequired,
    isVoucherPinValidationRequired,
    handleVoucherPinValidation,
  };
});
