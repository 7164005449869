import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { SevenComponentsPlugin } from '@nsftx/seven-components';
import { translationPlugin } from '@/plugins/i18n';
import { initSentry } from '@/modules/sentry';
import { logService } from '@/common/services/logger';
import App from './App.vue';

import router from './router';
import { isRunOnLocalhost, isRunBySevenCli } from './common/services/appHelpersService';

const isLogStreamEnabled = !isRunOnLocalhost()
&& !isRunBySevenCli();
const app = createApp(App);
app.use(createPinia());
initSentry(app, router);
app.use(router);
app.use(translationPlugin);
app.use(SevenComponentsPlugin, {
  SCKeyboard: { bottom: '64px' },
  SCCalendar: { backdrop: true },
});

logService.initLogger(isLogStreamEnabled);
logService.setLogLevel();

export default app;
