import { SevenPrint, SevenPrintLayouts } from '@nsftx/seven-print-js';

// Vars
let sevenPrint: SevenPrint;
let sevenPrintLayouts: SevenPrintLayouts;

const getSevenPrint = () => {
  if (!sevenPrint) {
    sevenPrint = new SevenPrint();
  }

  return sevenPrint;
};

const getSevenPrintLayouts = () => {
  if (!sevenPrintLayouts) {
    sevenPrintLayouts = new SevenPrintLayouts();
  }

  return sevenPrintLayouts;
};

const generatePrintTemplate = (template: string, bindings: unknown) => (
  getSevenPrint().generateTemplate(template, bindings)
);

export {
  getSevenPrint,
  getSevenPrintLayouts,
  generatePrintTemplate,
};
