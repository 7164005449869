import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center justify-content-between w-100"
}

import { computed } from 'vue';
import router from '@/router';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import LobbyHeaderTitle from './LobbyHeaderTitle.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LobbyHeader',
  setup(__props) {

const showLobbyHeader = computed(() => router.currentRoute.value.name === 'LobbyItem');

const redirectToHomePage = () => router.push({ name: 'Home' });

return (_ctx: any,_cache: any) => {
  return (showLobbyHeader.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(LobbyHeaderTitle),
        _createVNode(_unref(SCButton), {
          variant: _unref(ButtonVariant).Primary,
          onClick: redirectToHomePage
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('terminal_lobby_back_to_betting_area')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["variant"])
      ]))
    : _createCommentVNode("v-if", true)
}
}

})