import {
  type DMAcceptorStatus, type DMAcceptorType, Money,
} from '@nsftx/systems-sdk';
import { logService } from '@/common/services/logger';
import deviceManagementService from '@/modules/device-management/services/deviceManagementService';
import BaseError from '@/common/errors/BaseError';

const LOG_PREFIX = '[dmAcceptorsService]';
interface ChangeAcceptorsStatusParams {
  acceptor: DMAcceptorType;
  status: DMAcceptorStatus;
}

let money: Money;
let isMoneyReady: boolean = false;

const changeAcceptorStatus = ({
  acceptor, status,
}: ChangeAcceptorsStatusParams) => money.changeAcceptorStatus(acceptor, status);

const changeAcceptorStatusAll = (start: boolean) => money.changeAcceptorStatusAll(start);

const getMoneyInstance = () => {
  if (isMoneyReady) return money;

  throw (new BaseError(
    'Money is not yet initialized',
    'T_DM_MONEY_NOT_INITIALIZED',
  ));
};

const initDmMoney = async () => {
  logService.info(`${LOG_PREFIX} Acceptor service is using DM Application runtime.`, {
    code: 'T_DM_MONEY_INIT',
  });

  const core = deviceManagementService.getCore();

  return new Promise<void>((resolve, reject) => {
    const rejectTimeout = window.setTimeout(() => {
      reject(new BaseError(
        `${LOG_PREFIX} Money instance did not receive ready event on init.`,
        'T_DM_MONEY_NOT_READY',
      ));
    }, 15000);

    money = new Money(core);
    money.on('ready', () => {
      window.clearTimeout(rejectTimeout);
      isMoneyReady = true;
      resolve();
    });
  });
};

export {
  getMoneyInstance,
  initDmMoney,
  changeAcceptorStatus,
  changeAcceptorStatusAll,
};
