import { type SubscribeCallback } from '@nsftx/seven-gravity-gateway/master';
import {
  updateTicketCheckStateEventPubSub,
  UPDATE_TICKET_CHECK_STATE_ID,
  type UpdateTicketCheckStateData,
} from '@/modules/ticket-check';
import type { GravityAsyncEventResolve, GravityAsyncEventReject } from '../types';

export const handleUpdateTicketCheckStateEvent: SubscribeCallback<
UpdateTicketCheckStateData,
GravityAsyncEventResolve<UpdateTicketCheckStateData>,
GravityAsyncEventReject
> = (slaveData) => {
  updateTicketCheckStateEventPubSub.publish(UPDATE_TICKET_CHECK_STATE_ID, slaveData.data);
};
