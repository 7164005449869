import { LocalTicketStatus, ProductTicketStatuses, TicketStatuses } from '@/modules/seven-betslip-api';

// maps status received from socket for example, to local status
const mapServerStatusToLocal = (status: ProductTicketStatuses | TicketStatuses)
: LocalTicketStatus => {
  switch (status.toLowerCase()) {
    case 'open':
    case 'accepted':
    case 'in_play':
    case 'won':
    case 'won_approved':
    case 'lost':
    case 'afterclosed': return LocalTicketStatus.ACCEPTED;
    case 'rejected': return LocalTicketStatus.REJECTED;
    case 'closed': return LocalTicketStatus.CLOSED;
    case 'payedout':
    case 'paidout': return LocalTicketStatus.PAYEDOUT;
    case 'canceled':
    case 'cancelled': return LocalTicketStatus.CANCELED;
    case 'used': return LocalTicketStatus.USED;
    default: return LocalTicketStatus.UNKNOWN;
  }
};

export default { mapServerStatusToLocal };
