import { AxiosError } from 'axios';
import { isObject } from 'underscore';
import type { ParserError, UpstreamParserError } from './types';

const parseDataFromResponse = (error: AxiosError): string | undefined => {
  if (isObject(error?.response?.data)) {
    return (error?.response?.data as any).message;
  }
  return error?.response?.data?.toString();
};

const parseMessage = (error: AxiosError): string => parseDataFromResponse(error) || error.message;

const parseCode = (error: AxiosError): string => error.code
  || error.status?.toString()
  || error.response?.status?.toString()
  || error.response?.statusText
  || '';

const parse = (error: AxiosError): ParserError => ({
  message: parseMessage(error),
  code: parseCode(error),
});

const parseUpstream = (error: AxiosError): UpstreamParserError => ({
  upstream_message: parseMessage(error),
  upstream_code: parseCode(error),
});

const errorParser = {
  parse,
  parseUpstream,
  parseMessage,
  parseCode,
};

export default errorParser;
