(function () {
  angular.module('7Terminal.Common')
    .directive('datepickerRestoreValue', function datepickerRestoreValue($parse) {
      return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
          var regex;

          modelCtrl.$formatters.push(function (modelValue) {
            if (!modelValue) {
              return '';
            }

            regex = new RegExp(attrs.ngPattern);

            if (!regex.test(modelValue)) {
              $parse(attrs.ngModel).assign(scope, modelCtrl.$viewValue);
              return modelCtrl.$viewValue;
            }
            return modelValue;
          });
        }
      };
    });
})();
