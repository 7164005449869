import cloudflareService from '@/common/services/cloudflareService';

(function () {
  angular.module('7Terminal.Bootstrap')
    .service('sevenInterceptor', sevenInterceptor);

  function sevenInterceptor(
    $window,
    LocalData,
    SevenRoutes,
    LocalAppConfig,
    lang,
    NabENV
  ) {
    function isValidUrl(url) {
      var i;
      var whiteListedUrl;
      var whiteList = [
        cloudflareService.generateCloudflareUrl(NabENV.api.ngs.london),
        cloudflareService.generateCloudflareUrl(NabENV.api.ngs.ro),
        cloudflareService.generateCloudflareUrl(NabENV.reporting.url),
        cloudflareService.generateCloudflareUrl(SevenRoutes.mainUrl)
      ];

      for (i = 0; i < whiteList.length; i += 1) {
        whiteListedUrl = whiteList[i];
        if (url.substring(0, whiteListedUrl.length) === whiteListedUrl) {
          return true;
        }
      }

      return false;
    }

    function isAnonymousApi(url) {
      return /api\/terminals/.test(url);
    }

    function removeCredentialsApi(url) {
      var assetsUrl = SevenRoutes.assetsUrl;

      return url.indexOf(assetsUrl) !== -1 || url.indexOf('127.0.0.1') !== -1;
    }

    return {
      request: function (request) {
        request.url = cloudflareService.generateCloudflareUrl(request.url);

        if (!Object.keys(NabENV).length) {
          // data not yet ready to be attached
          return request;
        }

        if (isValidUrl(request.url)) {
          request.headers['HTTP-X-NAB-COMPANY-ID'] = LocalData.betshop.companyId;
          request.headers['HTTP-X-NAB-DEVICE-UUID'] = LocalData.device.uuid;
          request.headers['HTTP-X-NAB-DP'] = 'Terminal';
          request.headers['HTTP-X-NAB-BETSHOP-ID'] = LocalData.betshop.betshopId;
          request.headers['X-NSFT-SEVEN-COMPANY-UUID'] = LocalData.betshop.companyUuid;
          request.headers['SEVEN-LOCALE'] = lang.currentLang;
        }

        if (isAnonymousApi(request.url)) {
          request.headers.Authorization = 'Basic ' + $window.btoa(
            LocalAppConfig.appConfig.terminalAccount.uuid + ':' + LocalAppConfig.appConfig.terminalAccount.password
          );
        }

        if (removeCredentialsApi(request.url)) {
          delete request.headers.Authorization;
          request.withCredentials = false;
        }

        return request;
      }
    };
  }
})();
