/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { findWhere } from 'underscore';
import deviceManagementService from '@/modules/device-management/services/deviceManagementService';
import { DmInstaller } from './services/dmInstallerService';
import { LSInstaller } from './services/lsInstallerService';
import { DeviceInstaller } from './interfaces/DeviceInstaller';

const useEnvironmentHandlerStore = defineStore('environmentHandler', () => {
  const dc = ref<string>('');
  const states = ref<Record<string, any>>([]);

  const setDc = (newDc: string): void => {
    dc.value = newDc;
  };

  const getDc = (): string => dc.value;

  const getUrlParameter = (name: string): string => {
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  // eslint-disable-next-line no-return-assign
  const setState = (state: string, value: any): void => states.value[state] = value;

  const getState = (state: string): any => states.value[state];

  const getApplicationRuntime = (): { flag: string, service: DeviceInstaller } => {
    const runtimes = {
      NAR: { // nsoft appliction runtime
        flag: 'nar',
        service: DmInstaller,
      },
      LS: { // local storage
        flag: 'ls',
        service: LSInstaller,
      },
      DM: { // device management
        flag: 'dm',
        service: deviceManagementService as unknown as DeviceInstaller,
      },
    };
    const runtime = findWhere(runtimes, { flag: getUrlParameter('ar') });
    return runtime || runtimes.NAR;
  };

  const isDmApplicationRuntime = (): boolean => getApplicationRuntime().flag === 'dm';
  /**
   * In future we must change this code to use development parametar
   * instead of debug. Debug is used for log level while development would
   * mark development mode.
   */
  const isDevelopmentMode = () => !!getUrlParameter('debugMode');

  return {
    getUrlParameter,
    setDc,
    getDc,
    setState,
    getState,
    getApplicationRuntime,
    isDmApplicationRuntime,
    isDevelopmentMode,
  };
});

export {
  useEnvironmentHandlerStore,
};
