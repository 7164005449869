import { Pocket, pocketOnCallback, type MoneyProcessingCallbackData } from '@nsftx/systems-sdk';
import PubSub from 'pubsub-js';
import { logService } from '@/common/services/logger';
import deviceManagementService from '@/modules/device-management/services/deviceManagementService';
import BaseError from '@/common/errors/BaseError';

const LOG_PREFIX = '[dmPocketService]';
const POCKET_MAX_LOAD_TIME = 15000;
let pocket: Pocket;
let isReady: boolean = false;

const mapSourceToAcceptorEventName = (source: MoneyProcessingCallbackData['source']) => {
  const names: Record<MoneyProcessingCallbackData['source'], string> = {
    1: 'CoinAcceptor',
    2: 'BillAcceptor',
    3: 'Paylink',
  };
  return names[source];
};

const onMoneyEventHandler: pocketOnCallback = (moneyEventData) => {
  logService.debug(
    `${LOG_PREFIX} Got data from ${moneyEventData.type} event using SystemsSDK`,
    {
      code: 'T_DM_POCKET_MONEY_PROCESSING',
      details: {
        moneyEventData,
      },
    },
  );
  const name = mapSourceToAcceptorEventName(moneyEventData.source);
  PubSub.publish(`${name}:moneyProcessing`, moneyEventData);
};

const initPocket = async () => {
  isReady = false;
  const core = deviceManagementService.getCore();
  return new Promise<void>((resolve, reject) => {
    const rejectTimeout = window.setTimeout(() => {
      reject(new BaseError(
        'Pocket ready event not received',
        'T_DM_POCKET_READY_NOT_RECEIVED',
      ));
    }, POCKET_MAX_LOAD_TIME);
    pocket = new Pocket(core);

    pocket.on('ready', () => {
      isReady = true;
      window.clearTimeout(rejectTimeout);
      resolve();
    });
  });
};

const getPocket = () => {
  if (isReady) return pocket;

  throw new BaseError(
    'Pocket not yet initialized',
    'T_DEVICE_MANAGEMENT_POCKET_INIT_ERROR',
  );
};

const attachPocketListeners = () => {
  getPocket().on('moneyProcessing', onMoneyEventHandler);
  getPocket().on('moneyProcessed', onMoneyEventHandler);
  getPocket().on('moneyUnprocessed', onMoneyEventHandler);
};

const fetchTransactions = async () => getPocket().fetchTransactions();

const fetchTransApoens = async () => getPocket().fetchTransApoens();

const transfer = async () => getPocket().emptyCashBox();

const payinCancel = async (id: string) => getPocket().payinCancel(id);

const payinRetry = async (id: string) => getPocket().payinRetry(id);

export {
  fetchTransactions,
  fetchTransApoens,
  transfer,
  payinCancel,
  getPocket,
  initPocket,
  attachPocketListeners,
  payinRetry,
};
