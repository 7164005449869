import { range, each } from 'underscore';
import templateUrl from './forecastBettingArea.html';

(function () {
  angular.module('terminalApp.nabGreyhoundRacesUi')
    .component('greyhoundForecastBettingArea', {
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        $state,
        nabMessaging,
        GreyhoundRacesUiService
      ) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); var // switch virtual and regular greyhound races
          GreyhoundRaces = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = GreyhoundRaces.getBettingSvc();
        var self = this;

        this.$onInit = function () {
          this.racers = range(1, GreyhoundRaces.numberOfRacers + 1);
          this.activeGame = GreyhoundRaces;
          this.grehoundRacesUISvc = GreyhoundRacesUiService;
          this.createRacers();
          this.grehoundRacesUISvc.disableReverseForecast = false;
          this.grehoundRacesUISvc.disableForecast = false;
        };

        /** Create objects of racers for forecast and reverse forecast
                 * (set them unchecked)
                 * and  racers array* */

        this.createRacers = function () {
          GreyhoundRacesUiService.clearForecastActiveState();
          each(this.racers, function (position) {
            self.grehoundRacesUISvc.forecastFirstPlace['r' + (position)] = false;
            self.grehoundRacesUISvc.forecastSecondPlace['r' + (position)] = false;
            self.grehoundRacesUISvc.forecastAny['r' + (position)] = false;
          });
        };

        // listen for config changes
        nabMessaging.subscribe('config:gameConfigChanged', function (event, game) {
          if (game == 'GreyhoundRaces' || game == 'VirtualGreyhoundRaces') {
            var active_game = SevenGamesSvc.getGame(game);
            if (self.racers.length != active_game.numberOfRacers) {
              self.racers = range(1, active_game.numberOfRacers + 1);
            }
          }
        });

        /**
                 * Check which racer is checked in forecast bet
                 * push them in arrays depends on place
                 * r - racer
                 * * */

        this.checkForecastFirstPlace = function (racer) {
          this.grehoundRacesUISvc.forecastFirstPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.forecastFirstPlace[racer] = !this.grehoundRacesUISvc.forecastFirstPlace[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.forecastFirstPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.forecastFirstPlaces.push(key.substring(1));
            }
          });

          this.validateForecast();
        };

        /** Check second place racer in forecast bet
                 * @param - Number - racer postion* */

        this.checkForecastSecondPlace = function (racer) {
          this.grehoundRacesUISvc.forecastSecondPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.forecastSecondPlace[racer] = !this.grehoundRacesUISvc.forecastSecondPlace[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.forecastSecondPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.forecastSecondPlaces.push(key.substring(1));
            }
          });

          this.validateForecast();
        };

        /**
                 * Check if there are same numbers in combination
                 * hide any bet if anything is checked
                 * * */

        this.validateForecast = function () {
          for (var i in this.grehoundRacesUISvc.forecastFirstPlace) {
            if ((this.grehoundRacesUISvc.forecastFirstPlace[i] && this.grehoundRacesUISvc.forecastSecondPlace[i])
                            && (this.grehoundRacesUISvc.forecastFirstPlaces.length < 2 || this.grehoundRacesUISvc.forecastSecondPlaces.length < 2)) {
              this.grehoundRacesUISvc.validForecast = false;
              flashButton.flash = false;
              return;
            }
          }

          this.grehoundRacesUISvc.disableReverseForecast = this.grehoundRacesUISvc.forecastFirstPlaces.length > 0 || this.grehoundRacesUISvc.forecastSecondPlaces.length > 0;
          flashButton.flash = !(this.grehoundRacesUISvc.forecastFirstPlaces.length < 1 || this.grehoundRacesUISvc.forecastSecondPlaces.length < 1);
          this.grehoundRacesUISvc.validForecast = true;
        };

        this.checkReverseForecastBet = function (racer) {
          this.grehoundRacesUISvc.reverseForecastPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.forecastAny[racer] = !this.grehoundRacesUISvc.forecastAny[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.forecastAny, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.reverseForecastPlaces.push(key.substring(1));
            }
          });

          this.validateReverseForecastBet();
        };

        /**
                 * Disable forecast if any bet is checked
                 * flash add button if there are two racers
                 *
                 * * */

        this.validateReverseForecastBet = function () {
          this.grehoundRacesUISvc.disableForecast = this.grehoundRacesUISvc.reverseForecastPlaces.length > 0;

          if (this.grehoundRacesUISvc.reverseForecastPlaces.length < 2) {
            flashButton.flash = false;
            this.grehoundRacesUISvc.validReverseForecast = false;
          } else {
            flashButton.flash = true;
            this.grehoundRacesUISvc.validReverseForecast = true;
          }
        };
      }

    });
})();
