import {
  AsyncSeriesHook,
  SyncHook,
  SyncBailHook,
  AsyncSeriesWaterfallHook,
} from 'tapable';
import { logService } from '@/common/services/logger';
import { CustomerCardUserData } from '@/modules/customer-card/types';
import { type PrintData } from '@/modules/print';
import { ClientTicketActions } from '@/modules/seven-betslip-api';
import type { AcceptorHookParam } from '@/modules/acceptors/types';
import type { VoucherPayoutPayload } from '@/modules/vouchers/types';
import type {
  BeforeTicketPayinHookParams,
  RoTaxPlayerDataParams,
  BeforeTicketActionParams,
} from '../types';

const CustomerCardOptOut = new AsyncSeriesHook<{ cardData: CustomerCardUserData }>(['cardData']);
const BeforeOperatorLogout = new AsyncSeriesHook<{ onLogout: Function }>(['onLogout']);
const BeforePrint = new AsyncSeriesHook<PrintData>(['printData']);
const BeforeTicketActionNotificationShow = new SyncHook<{
  action: ClientTicketActions,
  errorResponses: Array<string>
}>(['data']);
const BeforeTicketCancel = new AsyncSeriesHook<BeforeTicketActionParams>(['ticket']);
const BeforeTicketPayout = new AsyncSeriesHook<BeforeTicketActionParams>(['ticket']);
const TboBeforeTicketPayout = new AsyncSeriesHook<BeforeTicketActionParams>(['ticket']);
const BeforeTicketPayin = new AsyncSeriesHook<BeforeTicketPayinHookParams>(['data']);
const RomTaxGetPlayerData = new AsyncSeriesWaterfallHook<RoTaxPlayerDataParams>(['cnpFields']);
const CustomerCardDetailsFetched = new SyncBailHook<{ cardData: CustomerCardUserData }, void>(['cardData']);
const BeforeTboLogin = new AsyncSeriesHook();
const BeforeAcceptorsStart = new SyncHook<AcceptorHookParam>(['errorResponses']);
const BeforeVoucherPayout = new AsyncSeriesHook<VoucherPayoutPayload>(['voucherData']);

// we will also remove this and run hooks without run function
const hooks: { [key: string]: any } = {
  BeforeAcceptorsStart,
  BeforeTboLogin,
  TboBeforeTicketPayout,
  CustomerCardDetailsFetched,
  BeforePrint,
  BeforeTicketActionNotificationShow,
  BeforeTicketCancel,
  BeforeTicketPayout,
  BeforeTicketPayin,
  RomTaxGetPlayerData,
};

const run = (hookName: string, params?: any): Promise<any> => {
  if (!hooks[hookName]) {
    logService.error(`[HooksManager] Hook ${hookName} doesn't exist.`, {});
    return Promise.reject(new Error(`[HooksManager] Hook ${hookName} doesn't exist.`));
  }

  return hooks[hookName].promise(params);
};

// we will remove this function in the future and access hooks directly as exported
const getHook = (hookName: string): any => hooks[hookName];

export default {
  getHook,
  run,
  BeforeOperatorLogout,
  CustomerCardOptOut,
  BeforePrint,
  BeforeTicketActionNotificationShow,
  BeforeTicketCancel,
  BeforeTicketPayout,
  TboBeforeTicketPayout,
  BeforeTicketPayin,
  RomTaxGetPlayerData,
  CustomerCardDetailsFetched,
  BeforeTboLogin,
  BeforeAcceptorsStart,
  BeforeVoucherPayout,
};
