import { getService } from '@/common/services/ngBridge';
import { getProvinceByBetshopUuid } from './pla-reports/apiService';
import { useIntelligentGamingStore } from './store';

export const checkProvinceForBetshop = () => {
  type TLoaderService = { showLoader: Function, endLoader: Function };
  const intelligentGamingStore = useIntelligentGamingStore();
  const loaderService = getService('loaderService') as TLoaderService;

  loaderService.showLoader();

  getProvinceByBetshopUuid()
    .then(() => {
      intelligentGamingStore.isProvinceModalShown = false;
    }).catch(() => {
      intelligentGamingStore.isProvinceModalShown = true;
    }).finally(() => {
      loaderService.endLoader();
    });
};
