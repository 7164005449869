import { where, range, each } from 'underscore';
import templateUrl from './racecardBettingArea.html';

(function () {
  angular.module('terminalApp.nabHorseRacesUi')
    .component('horseRacecardBettingArea', {
      templateUrl: templateUrl,
      controller: function (flashButton,
        SevenGamesSvc,
        BetslipService,
        $scope,
        $state,
        nabTrans,
        $filter,
        nabMessaging,
        NabNotifications,
        HorseRacesUiService,
        $rootScope) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); var // switch virtual and regular horse races
          HorseRaces = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = HorseRaces.getBettingSvc();
        var self = this;
        var bet;
        var finalizedBet;
        var selectedBet;
        var bets;
        var odds;

        this.$onInit = function () {
          odds = SevenGamesSvc.getGame(activeGame).odds;
          this.activeGame = HorseRaces;
          this.racers = range(1, HorseRaces.numberOfRacers + 1);
          this.resolutionMode = $rootScope.resolutionMode;
          this.ticketInput = BettingSvc.ticketInput;
          this.bets = {};
          this.betslipSvc = BetslipService;
          this.horseRacesUISvc = HorseRacesUiService;
          this.updateOdds();
          this.deactivateRacers();

          if (this.activeGame._scheduleSvc.isScheduleActive) self.horseRacesUISvc.createRacecardSchedule();
        };

        /** Set racers for winner,show,place as inactive* */

        this.deactivateRacers = function () {
          each(this.racers, function (position) {
            self.horseRacesUISvc.winner['r' + (position)] = false;
            self.horseRacesUISvc.show['r' + (position)] = false;
            self.horseRacesUISvc.place['r' + (position)] = false;
          });
        };

        this.deactivateScheduledRacers = function () {
          each(this.racers, function (position) {
            for (var i in self.horseRacesUISvc.scheduledEvents) {
              self.horseRacesUISvc.scheduledEvents[i].winner['r' + (position)] = false;
              self.horseRacesUISvc.scheduledEvents[i].show['r' + (position)] = false;
              self.horseRacesUISvc.scheduledEvents[i].place['r' + (position)] = false;
            }
          });
        };

        /** Update odds on racecard betting area and create racers* */

        this.updateOdds = function () {
          this.bets = {
            winner: odds.winner,
            place: odds.place,
            show: odds.show
          };

          for (var i in this.bets) {
            for (var j = 0; j < this.bets[i].length; j++) {
              this.bets[i][j].bet = i;
            }
          }
        };

        // listen for config changes
        nabMessaging.subscribe('config:gameConfigChanged', function (event, game) {
          if (game == 'HorseRaces' || game == 'VirtualHorseRaces') {
            var active_game = SevenGamesSvc.getGame(game);
            if (self.racers.length != active_game.numberOfRacers) {
              self.racers = range(1, active_game.numberOfRacers + 1);
              HorseRacesUiService.clearRacecardActiveState();
              self.updateOdds();
            }
          }
        });

        /** Watch active bet and add active class
                 * i have to use angular.bind because i didn't want to set betslipSvc on $scope* */

        $scope.$watch(angular.bind(this, function () {
          return self.betslipSvc.ticket.bets; // this IS the this above!!
        }), function (newValue) {
          self.deactivateRacers();

          if (self.activeGame.isScheduleActive) {
            self.deactivateScheduledRacers();
          }

          newValue.forEach(function (item) {
            if (item.clientId < 10) { // just winner,show,place
              self.horseRacesUISvc[item.key]['r' + item.displayValue] = true;

              if (self.activeGame.isScheduleActive && (item.eventId >= self.activeGame.gameInfo.eventId)) {
                self.horseRacesUISvc.scheduledEvents[item.eventId][item.key]['r' + item.displayValue] = true;
              }
            }
          });
        }, true);

        /**
                 * Update odds when new round starts
                 *i have to use angular.bind because i didn't want to set activeGame on $scope* */

        $scope.$watch(angular.bind(this, function () {
          return self.activeGame.odds; // this IS the this above!!
        }), function (newValue) {
          if (self.racers.length != HorseRaces.numberOfRacers) {
            self.racers = range(1, HorseRaces.numberOfRacers + 1);
          }

          if (newValue && HorseRaces.odds) {
            HorseRaces.gameInfo.isActive = true;
            odds = HorseRaces.odds;
            self.updateOdds();
          }

          if (!HorseRaces.odds) {
            HorseRaces.gameInfo.isActive = false;
          }
        });

        /**
                 * Toggle winner,show,place
                 * @param {Object} - bet
                 ** */

        this.toggleBet = function (activeBet) {
          bets = BetslipService.ticket.getBets();
          selectedBet = where(bets, { key: activeBet.bet, displayValue: activeBet.racers[0].toString() })[0];

          /** If same bet is clicked - set it inactive and remove it from betslip* */
          if (this.horseRacesUISvc[activeBet.bet]['r' + activeBet.racers[0]]
                        && (this.activeGame.isScheduleActive ? this.horseRacesUISvc.scheduledEvents[this.activeGame._scheduleSvc.activeRound.eventId][activeBet.bet]['r' + activeBet.racers[0]] : true)) {
            if (this.activeGame.isScheduleActive) {
              self.horseRacesUISvc.scheduledEvents[self.activeGame._scheduleSvc.activeRound.eventId][activeBet.bet]['r' + activeBet.racers[0]] = false;
              selectedBet = where(bets, { key: activeBet.bet, displayValue: activeBet.racers[0].toString(), eventId: this.activeGame._scheduleSvc.activeRound.eventId })[0];
            }
            this.betslipSvc.removeFromBetslip(selectedBet.uid, false);
            return;
          }

          /** Remove set forecast and tricast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          BettingSvc.clearAll();
          bet = where(HorseRaces.configuredBets, { key: activeBet.bet });
          BettingSvc.activateBet(bet[0]);
          this.ticketInput.input[0] = activeBet.racers[0].toString();
          finalizedBet = BettingSvc.finalizeTicketInput()[0];
          finalizedBet.disableEdit = true; // disable editing - show,winner,place
          finalizedBet.odd = activeBet.value; // disable editing - show,winner,place
          finalizedBet.eventId = HorseRaces.gameInfo.eventId;

          if (HorseRaces.isScheduleActive) {
            finalizedBet.eventId = HorseRaces._scheduleSvc.activeRound.eventId;
            finalizedBet.event = HorseRaces._scheduleSvc.activeRound.eventIdToday;
          }

          var error = BetslipService.validateBet(
            finalizedBet,
            false
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            BetslipService.addToBetslip(finalizedBet);
          }
        };
      }

    });
})();
