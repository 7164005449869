import angular from 'angular';
import runnerLogDecorate from './runnerLogDecorator';

/**
  * @ngdoc overview
  * @name 7Terminal.Runners
  *
  * @description
  * This module communicates with https://github.com/nsftx/seven-client-nw-runner
  * See details in {@link tutorial/development.logging Development|Logging}.
  *
  * @example
  *  <b>script.js</b>
  *  <pre>
  *  import runnerModule from './modules/runners'
  *  angular.module('myModule', [runnerModule]);
  *  </pre>
  *
*/

const moduleName = angular.module('7Terminal.Runners', ['7Terminal.Core'])
  .decorator('$log', runnerLogDecorate)
  .name;

export default moduleName;
