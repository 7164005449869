import type { ObjectDirective } from 'vue';
import { trackEvent } from './googleAnalyticsService';
import type { GoogleAnalyticsTrackEvent } from './types';

export const vGaTrackEvent: ObjectDirective<HTMLElement, GoogleAnalyticsTrackEvent> = {
  mounted: (el, binding) => {
    el.addEventListener('click', () => {
      const { eventName, eventParams } = binding.value;
      trackEvent(eventName, eventParams);
    });
  },
};
