// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import * as tokenInterceptor from '@/modules/interceptors/tokenInterceptor';
import * as sevenInterceptor from '@/modules/interceptors/sevenInterceptor';
import * as httpInterceptor from '@/modules/interceptors/httpInterceptor';

const axiosInstance = axios.create({});
const defaultErrorHandler = (error:any) => Promise.reject(error);

axiosInstance.interceptors.request.use(sevenInterceptor.requestSuccess, defaultErrorHandler);
axiosInstance.interceptors.request.use(tokenInterceptor.requestSuccess, defaultErrorHandler);
axiosInstance.interceptors.request
  .use(httpInterceptor.requestSuccess, httpInterceptor.requestError);

axiosInstance.interceptors.response.use(tokenInterceptor.responseSuccess, defaultErrorHandler);
axiosInstance.interceptors.response
  .use(httpInterceptor.responseSuccess, httpInterceptor.responseError);

export default axiosInstance;
