import angular from 'angular';
import '@nsftx/seven-virtual-motorcycle-speedway/dist/virtualmotorcyclespeedway';
import commonModule from '../../common';
import sevenGamesModule from '../index';
import resultsTemplateUrl from '../results/results.html';
import resultsContentTemplateUrl from '../results/racer_games/content.html';

angular.module('terminalApp.sevenVirtualMotorcycleSpeedway', [
  'sevenVMS',
  commonModule,
  sevenGamesModule
]).config(function ($stateProvider) {
  var i = 0;
  var config;
  for (i; i < window.BootStrapData.clientAppModules.length; i++) {
    config = window.BootStrapData.clientAppModules[i];
    if (config.id === 'VirtualMotorcycleSpeedway') {
      window.SevenVMS.getConfigService()
        .set({
          companyUuid: window.BootStrapData.company.uuid
        });
    }
  }

  $stateProvider.state('app.games.virtualmotorcyclespeedway', {
    url: '/virtualMotorcycleSpeedway',
    template: '<vms-ui></vms-ui>'
  });

  $stateProvider.state('app.results.virtualmotorcyclespeedway', {
    url: '/vms?id',
    templateUrl: resultsTemplateUrl,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.virtualmotorcyclespeedway.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentTemplateUrl,
        controller: 'RacerGameResultsController'
      }
    }
  });
}).run(function (
  VmsUiService,
  $rootScope
) {
  $rootScope.$on('VMS:ConfigLoaded', function () {
    $rootScope.$emit('VMS:SetRunningProduct', {
      runningProduct: '7Terminal'
    });
    VmsUiService.boot();
  });

  $rootScope.$on('7T:Slave.Shown', function (e, data) {
    if (data.productId === 'VirtualMotorcycleSpeedway') {
      $rootScope.$broadcast('7T:Betslip.ActivateTicket', {
        data: {
          betslipId: data.productId,
          ticketId: 'single'
        }
      });
    }
  });
});
