import ProductTicket from './ProductTicket';
import { ProductTicketSportsbookSMResponse } from '../types';

class ProductTicketSM extends ProductTicket {
  ticket: ProductTicketSportsbookSMResponse;

  constructor(ticket: ProductTicketSportsbookSMResponse) {
    super(ticket);
    this.ticket = ticket;
  }

  getDisplayId() {
    const fondHash = this.ticket.ticketHashes
      .find((hash: { type: string, hash: string, }) => hash.type === 'Normal');
    return fondHash?.hash as string;
  }

  getPayment() {
    return this.ticket.paymentAmount || this.ticket.payment?.value || 0;
  }

  getTicketBets() {
    return this.ticket.ticketBets || [];
  }
}

export default ProductTicketSM;
