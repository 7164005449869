import PubSub from 'pubsub-js';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { logService } from '@/common/services/logger';
import { axiosErrorParser } from '@/common/services/error-parser';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { getSchedule } from './maintenanceApiService';
import { useMaintenanceStore } from '../maintenanceStore';
import type { MaintenanceScheduleMessageScope } from '../maintenanceTypes';
import type MaintenanceScheduleRange from '../interfaces/MaintenanceScheduleRange';
import type MaintenanceScheduleMessage from '../interfaces/MaintenanceScheduleMessage';

// Constants
const INTERVAL_MAINTENANCE_SCHEDULE_CHECK = 2 * 60 * 1000;
const MAINTENANCE_MODAL_ID = 'maintenance';

// Vars
let scheduledCheckTimeout: number;
let intervalCheck: number;

const showNotification = () => {
  const notificationsStore = useNotificationsStore();
  const { maintenanceData } = useMaintenanceStore();

  notificationsStore.show({
    id: 'maintenance_mode',
    type: TNotificationTypeEnum.warning,
    message: maintenanceData?.scheduleMessage?.title || '',
    closeDisabled: true,
    delay: null,
  });
};

const closeNotification = () => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('maintenance_mode');
};

const getDisabledProducts = (scheduleMessageScope: MaintenanceScheduleMessageScope) => {
  const disabledProducts = Object.values(scheduleMessageScope)
    .map((channel) => channel.Terminal)
    .flatMap((products) => products)
    .filter(Boolean);

  return disabledProducts.length ? disabledProducts as string[] : undefined;
};

const getMaintenanceDurationTime = (scheduleRanges: MaintenanceScheduleRange[]) => {
  const currentTime = new Date().getTime();
  let durationTime: number | undefined;

  scheduleRanges.forEach((scheduleRange) => {
    const scheduleStartTime = new Date(scheduleRange.start).getTime();
    const scheduleEndTime = new Date(scheduleRange.end).getTime();
    const isValidTimeRange = currentTime >= scheduleStartTime && currentTime < scheduleEndTime;

    if (isValidTimeRange) {
      durationTime = scheduleEndTime - currentTime;
    }
  });

  return durationTime;
};

const activateMaintenanceMode = (scheduleMessage: MaintenanceScheduleMessage) => {
  const maintenanceStore = useMaintenanceStore();
  const disabledProducts = getDisabledProducts(scheduleMessage.scope);
  maintenanceStore.activateMaintenance({ scheduleMessage, disabledProducts });

  logService.info('[maintenanceService] Maintenance mode started', {
    code: 'T_MAINTENANCE_START',
    products: disabledProducts,
  });
};

const removeMaintenanceMode = () => {
  const maintenanceStore = useMaintenanceStore();

  maintenanceStore.deactivateMaintenance();
  closeNotification();
  logService.info('[maintenanceService] Maintenance mode removed', {
    code: 'T_MAINTENANCE_REMOVE',
  });
};

const handleMaintenanceSchedule = () => {
  const maintenanceStore = useMaintenanceStore();
  const { isMaintenanceActive } = storeToRefs(maintenanceStore);

  getSchedule().then(({ data }) => {
    const { total, results } = data;
    if (!total) {
      if (isMaintenanceActive.value) {
        removeMaintenanceMode();
      }
      return;
    }

    const { schedule: scheduleRanges, message: scheduleMessage } = results[0];
    if (!scheduleRanges?.length) {
      removeMaintenanceMode();
      return;
    }

    const durationTime = getMaintenanceDurationTime(scheduleRanges);
    if (!durationTime) {
      return;
    }

    activateMaintenanceMode(scheduleMessage);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    scheduleEndTimeCheck(durationTime);
  }).catch((err) => {
    logService.error('[maintenanceService] Failed to fetch maintenance schedule', {
      ...axiosErrorParser.parseUpstream(err),
      code: 'T_MAINTENANCE_API_SCHEDULE_FAILED',
    });
  });
};

// setTimeout limit is MAX_INT32=(2^31-1)
const getValidSetTimeoutTime = (endTime: number) => (endTime > 0x7FFFFFFF ? 0x7FFFFFFF : endTime);

const scheduleEndTimeCheck = (endTime: number) => {
  clearTimeout(scheduledCheckTimeout);
  scheduledCheckTimeout = window.setTimeout(
    handleMaintenanceSchedule,
    getValidSetTimeoutTime(endTime),
  );
};

const startIntervalCheck = () => {
  clearInterval(intervalCheck);
  intervalCheck = window.setInterval(
    handleMaintenanceSchedule,
    INTERVAL_MAINTENANCE_SCHEDULE_CHECK,
  );
};

const registerListeners = () => {
  PubSub.subscribe('Maintenance.Update', handleMaintenanceSchedule);
};

const toggleNotification = () => {
  const maintenanceStore = useMaintenanceStore();
  const { maintenanceData, isMaintenanceActive } = storeToRefs(maintenanceStore);

  watch([router.currentRoute, maintenanceData], ([route]) => {
    const HOME_PATH = '/home';
    const GAME_PATH = '/games';

    if (!isMaintenanceActive.value) {
      closeNotification();
      return;
    }

    if (route.fullPath.startsWith(HOME_PATH)) {
      showNotification();
      return;
    }

    const { isGameInMaintenanceState } = maintenanceStore;
    if (route.fullPath.startsWith(GAME_PATH)
      && isGameInMaintenanceState(route.query.gameId?.toString() || '')) {
      showNotification();
      return;
    }

    closeNotification();
  }, { immediate: true });
};

const init = () => {
  handleMaintenanceSchedule();
  startIntervalCheck();
  registerListeners();
  toggleNotification();
};

export {
  init,
  showNotification,
  closeNotification,
  MAINTENANCE_MODAL_ID,
};
