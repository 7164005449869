import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import type { OperatorCardDataInfo } from './types';
import type { CardDataInfo } from './interfaces';
import { narCardReaderService, dmCardReaderService } from './services';

let apiService: typeof narCardReaderService | typeof dmCardReaderService;

const readCardData = () => apiService.readCardData();

const writeToOperatorCard = (cardInfo: OperatorCardDataInfo) => apiService
  .writeToOperatorCard(cardInfo);

const writeToPassCard = () => apiService.writeToPassCard();

const formatCard = () => apiService.formatCard();

const isCardEmpty = (cardData: CardDataInfo | undefined)
: boolean => apiService.isCardEmpty(cardData);

const init = (): void => {
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();

  apiService = isDmApplicationRuntime() ? dmCardReaderService : narCardReaderService;
  apiService.init();
};

export {
  init,
  readCardData,
  isCardEmpty,
  writeToOperatorCard,
  writeToPassCard,
  formatCard,
};
