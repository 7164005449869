import angular from 'angular';
import translate from 'angular-translate';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import { PENDING_TICKET_TRESHOLD, LOCAL_TICKET_ACTIONS, LOCAL_TICKET_STATUS } from './constants';
import ProductTicketService from './productTicket';
import SevenTicketService from './sevenTicket';
import SevenTicketChecker from './sevenTicketChecker';
import TicketActionStore from './ticketActionStore';
import './ticketFormatters';
import SevenTicketConfigProvider from './ticketConfigProvider';
import ticketManagerService from './ticketManagerService';
import commonModule from '../../common';

/**
  * @ngdoc overview
  * @name 7Terminal.SevenTicketCenter
  *
  * @description
  * Covers ticket check logic.
  *
  * @example
  *  <b>script.js</b>
  *  <pre>
  *  import MODULE_NAME from './src/modules/ticket_center'
  *  angular.module('myModule', [MODULE_NAME]);
  *  </pre>
  *
*/
const moduleName = angular.module('SevenTicketCenter', [
  translate,
  'SevenClientLib',
  'SevenClient.Config',
  commonModule
])
  .constant('LOCAL_TICKET_STATUS', LOCAL_TICKET_STATUS)
  .constant('LOCAL_TICKET_ACTIONS', LOCAL_TICKET_ACTIONS)
  .constant('PENDING_TICKET_TRESHOLD', PENDING_TICKET_TRESHOLD)
  .factory('ProductTicket', ProductTicketService)
  .factory('SevenTicket', SevenTicketService)
  .factory('TicketActionStore', TicketActionStore)
  .provider('SevenTicketConfig', SevenTicketConfigProvider)
  .service('SevenTicketChecker', SevenTicketChecker)
  .service('ticketManager', ticketManagerService)
  .name;

export default moduleName;
