import { range, each } from 'underscore';
import templateUrl from './forecastBettingArea.html';

(function () {
  angular.module('terminalApp.sevenVirtualMotorcycleSpeedway')
    .component('vmsForecastBettingArea', {
      templateUrl: templateUrl,
      controller: function (
        SevenGamesSvc,
        VmsUiService,
        BetslipService
      ) {
        var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');

        this.$onInit = function () {
          this.racersFirstPlace = range(1, VMS.numberOfRacers + 1);
          this.racersSecondPlace = range(1, VMS.numberOfRacers + 1);
          this.activeGame = VMS;
          this.vmsUISvc = VmsUiService;
          this.createCombinations();
          this.vmsUISvc.reverseForecastActive = false;
        };

        this.createCombinations = function () {
          var self = this;
          each(self.racersFirstPlace, function (firstPlace) {
            each(self.racersSecondPlace, function (secondPlace) {
              if (firstPlace !== secondPlace) {
                VmsUiService.forecast[firstPlace + '-' + secondPlace] = false;
              }
            });
          });
        };

        this.toggleReverseForecast = function () {
          this.vmsUISvc.clearActiveBets();

          this.vmsUISvc.reverseForecastActive = !this.vmsUISvc.reverseForecastActive;

          this.vmsUISvc.enableActiveBets(BetslipService.ticket.bets);
        };

        this.createReverseForecastBet = function (first, second) {
          var BettingSvc = VMS.getBettingSvc();

          BettingSvc.ticketInput.input = [first, second];

          return {
            key: 'reverseForecast',
            combinations: [
              first + ', ' + second,
              second + ', ' + first
            ]
          };
        };

        this.createForecastBet = function (first, second) {
          var odd;
          VMS._bettingSvc.clearAll();
          VMS._bettingSvc.ticketInput.input.push(first);
          VMS._bettingSvc.ticketInput.secondPlace.push(second);

          odd = VMS._bettingSvc.systemBets.forecastOdds[first + '-' + second];

          return {
            bet: 'forecast',
            value: odd,
            racers: first + '-' + second
          };
        };

        this.sendBet = function (first, second) {
          var betToSend = null;

          if (this.vmsUISvc.reverseForecastActive) {
            betToSend = this.createReverseForecastBet(first, second);
          } else {
            betToSend = this.createForecastBet(first, second);
          }

          VmsUiService.addBet(betToSend);
        };
      }

    });
})();
