import axios from 'axios';
import { useENVStore } from '@/common/stores/env';
import { useSevenStore } from '@/modules/seven';
import type { CustomerCardUserData } from './types';

const fetchCustomerCardUserDataById = (customerCardId: string, identifier: string) => {
  const envStore = useENVStore();
  const sevenStore = useSevenStore();
  const companyUuid = sevenStore.tenant.uuid;

  const url = `${envStore.data.api.customerCards}/tenants/${companyUuid}/CustomerCards/${customerCardId}/cards/${identifier}`;
  return axios.get<CustomerCardUserData>(url);
};

export {
  fetchCustomerCardUserDataById,
};
