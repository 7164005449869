import templateUrl from './luckyXUi.html';

(function () {
  angular.module('terminalApp.nabLuckyXUi')
    .component('luckyXUi', {
      templateUrl: templateUrl,
      controller: function (
        $scope,
        flashButton,
        SevenGamesSvc,
        nabTrans,
        $rootScope,
        NabNotifications,
        BetslipService,
        LuckyXUiService,
        GamesUiService
      ) {
        var LuckyX = SevenGamesSvc.getGame('LuckyX');
        var gameState = GamesUiService.checkGameActivity(LuckyX);
        var BettingSvc = LuckyX.getBettingSvc();
        var editMode = false;
        var ctrl = this;

        this.$onInit = function () {
          this.activeGame = LuckyX;
          this.gameInfo = LuckyX.getGameInfo();
          this.bettingService = BettingSvc;
          this.betslip = BetslipService;
          this.resolutionMode = $rootScope.resolutionMode;
        };

        /**
                 * Send created bet to betslip
                 */
        this.sendBet = function () {
          if (!gameState.value) {
            NabNotifications.show(
              {
                message: nabTrans.translate(gameState.messageKey, { game: this.activeGame.translation }, true),
                type: 'warning',
                delay: 3000
              }
            );
            return;
          }

          /** If there is no selected bet* */
          if ((this.bettingService.activeBet.bet.id < 20 && this.bettingService.getActiveBalls().length < 1)
                        || (this.bettingService.activeBet.bet.id > 20 && !this.bettingService.inputView.special && this.bettingService.activeBet.bet.id != 23)
                        || (this.bettingService.activeBet.bet.id == 23 && this.bettingService.getActiveBalls().length < 1)) {
            NabNotifications.show({
              message: nabTrans.translate('notifications.select_bet_first', {}, true),
              type: 'info',
              delay: 3000
            });
            return;
          }

          BettingSvc.setBetInputNumbers();

          var newBet = BettingSvc.finalizeBet(angular.merge(BettingSvc.betInput, BettingSvc.inputView));

          var stake = (function () {
            // return current stake only in edit mode,
            // otherwise done't send anything,
            // betslip service will know what to do
            if (editMode) {
              return BetslipService.ticket.stake;
            }
          }());

          var error = BetslipService.validateBet(
            newBet,
            editMode
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            if (!editMode) {
              BetslipService.addToBetslip(
                newBet,
                stake
              );
            } else {
              BetslipService.editTicket(
                newBet
              );
              editMode = false;
            }

            this.resetBet();
          }
        };

        /**
                 * Clear currently selected bet
                 */
        this.resetBet = function () {
          this.bettingService.clearAll();
          flashButton.flash = false;
        };

        $scope.$on('7T:Betslip.BetSelected', function (e, data) {
          flashButton.flash = data.bet.focus;
          editMode = data.bet.focus;
          BettingSvc.showActiveBetslipBet(data.bet.focus ? data.bet : false);
          if (angular.isDefined(BettingSvc.editMode)) {
            BettingSvc.editMode = true;
          }
        });

        var betslipResetListener = $rootScope.$on('7T:Betslip.Reset', function () {
          ctrl.resetBet();
        });

        var betslipBetsChangedListener = $rootScope.$on('7T.Betslip.BetsChanged', function (e, data) {
          if (data.type === 'betRemove') {
            ctrl.resetBet();

            if (data.data.wasSelected) {
              editMode = false;
              if (angular.isDefined(BettingSvc.editMode)) {
                BettingSvc.editMode = false;
              }
            }
          }
        });

        $scope.$on('$destroy', function () {
          betslipResetListener();
          betslipBetsChangedListener();
        });
      }
    });
})();
