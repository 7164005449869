(function () {
  (function () {
    angular.module('7Terminal.Core').component('tWidgetInclude', {
      controller: tWidgetIncludeCtrl,
      transclude: true,
      bindings: {
        widget: '<',
        data: '<'
      }
    });

    function tWidgetIncludeCtrl($scope, $element, $window) {
      var ctrl = this;
      var element;
      ctrl.$onInit = function () {
        element = angular.element($window.document.createElement(ctrl.widget.source.element.tag));
        _.each(ctrl.data, function (value, key) {
          var finalData = value;
          if (angular.isObject(finalData)) {
            finalData = JSON.stringify(finalData);
          }
          element.attr(key, finalData);
        });

        $element.append(element);
      };
    }
  })();
})();
