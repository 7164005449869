angular.module('7Terminal.Common').filter('orCondition', function orCondition($filter) {
  return function (input, params) {
    var result = [];

    params.forEach(function (param) {
      var something = $filter('filter')(input, { $: param });
      result = result.concat(something);
    });

    return result;
  };
});
