import { each, where, range } from 'underscore';
import resultsTemplate from './racecardBettingArea.html';

export default {
  templateUrl: resultsTemplate,
  /**
             * @ngInject
             */
  controller: function (SevenGamesSvc,
    BetslipService,
    $scope,
    $state,
    NabNotifications,
    ScrUiService,
    $rootScope) {
    var currentUrl = $state.current.url.substring(1, $state.current.url.length);
    var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString();
    var SCR = SevenGamesSvc.getGame(activeGame);
    var BettingSvc = SCR.getBettingSvc();
    var self = this;
    var selectedBet;
    var bets;
    var odds;

    this.$onInit = function () {
      odds = SCR.odds;
      this.activeGame = SCR;
      this.racers = range(1, SCR.numberOfRacers + 1);
      this.resolutionMode = $rootScope.resolutionMode;
      this.ticketInput = BettingSvc.ticketInput;
      this.bets = {};
      this.betslipSvc = BetslipService;
      this.scrUISvc = ScrUiService;
      this.updateOdds();
      this.deactivateRacers();

      if (this.activeGame._scheduleSvc.isScheduleActive) self.scrUISvc.createRacecardSchedule();
    };

    /** Set racers for winner, place as inactive* */

    this.deactivateRacers = function () {
      each(this.racers, function (position) {
        self.scrUISvc.winner['r' + (position)] = false;
        self.scrUISvc.show['r' + (position)] = false;
        self.scrUISvc.place['r' + (position)] = false;
      });
    };

    this.deactivateScheduledRacers = function () {
      each(this.racers, function (position) {
        for (var i in self.scrUISvc.scheduledEvents) {
          self.scrUISvc.scheduledEvents[i].winner['r' + (position)] = false;
          self.scrUISvc.scheduledEvents[i].show['r' + (position)] = false;
          self.scrUISvc.scheduledEvents[i].place['r' + (position)] = false;
        }
      });
    };

    /** Update odds on racecard betting area and create racers* */
    this.updateOdds = function () {
      var i;
      var j;
      var currentBet;
      var currentBetLength;

      this.bets = {
        winner: odds.winner,
        place: odds.place,
        show: odds.show
      };

      for (i in this.bets) {
        currentBet = this.bets[i];
        currentBetLength = currentBet.length;

        for (j = 0; j < currentBetLength; j++) {
          currentBet[j].bet = i;
        }
      }
    };

    /** Watch active bet and add active class
                * I have to use angular.bind because I didn't want to set betslipSvc on $scope* */

    $scope.$watch(angular.bind(this, function () {
      return self.betslipSvc.ticket.bets; // this IS the this above!!
    }), function (newValue) {
      self.deactivateRacers();

      if (self.activeGame.isScheduleActive) {
        self.deactivateScheduledRacers();
      }

      newValue.forEach(function (item) {
        if (item.clientId < 10) { // just winner, show, place
          self.scrUISvc[item.key]['r' + item.displayValue] = true;

          if (self.activeGame.isScheduleActive && (item.eventId >= self.activeGame.gameInfo.eventId)) {
            self.scrUISvc.scheduledEvents[item.eventId][item.key]['r' + item.displayValue] = true;
          }
        }
      });
    }, true);

    /**
                 * Update odds when new round starts
                 *I have to use angular.bind because I didn't want to set activeGame on $scope* */

    $scope.$watch(angular.bind(this, function () {
      return self.activeGame.odds; // this IS the this above!!
    }), function (newValue) {
      if (newValue && SCR.odds) {
        SCR.gameInfo.isActive = true;
        odds = SCR.odds;
        self.updateOdds();
      }

      if (!SCR.odds) {
        SCR.gameInfo.isActive = false;
      }
    });

    /**
                 * Toggle winner, show, place
                 * @param {Object} - bet
                 ** */

    this.toggleBet = function (activeBet) {
      var bet;
      var finalizedBet;
      bets = BetslipService.ticket.getBets();
      selectedBet = where(bets, { key: activeBet.bet, displayValue: activeBet.racers[0].toString() })[0];

      /** If same bet is clicked - set it inactive and remove it from betslip* */
      if (this.scrUISvc[activeBet.bet]['r' + activeBet.racers[0]]
                    && (this.activeGame.isScheduleActive ? this.scrUISvc.scheduledEvents[this.activeGame._scheduleSvc.activeRound.eventId][activeBet.bet]['r' + activeBet.racers[0]] : true)) {
        if (this.activeGame.isScheduleActive) {
          self.scrUISvc.scheduledEvents[self.activeGame._scheduleSvc.activeRound.eventId][activeBet.bet]['r' + activeBet.racers[0]] = false;
          selectedBet = where(bets, { key: activeBet.bet, displayValue: activeBet.racers[0].toString(), eventId: this.activeGame._scheduleSvc.activeRound.eventId })[0];
        }

        this.betslipSvc.removeFromBetslip(selectedBet.uid, false);
        return;
      }

      /** Remove set forecast and tricast odd if exist* */

      if (BettingSvc.ticketInput.systemOdd) {
        delete BettingSvc.ticketInput.systemOdd;
      }

      BettingSvc.clearAll();
      bet = where(SCR.configuredBets, { key: activeBet.bet });
      BettingSvc.activateBet(bet[0]);
      this.ticketInput.input[0] = activeBet.racers[0].toString();

      finalizedBet = BettingSvc.finalizeTicketInput()[0];
      finalizedBet.disableEdit = true; // disable editing - show, winner, place
      finalizedBet.odd = activeBet.value; // disable editing - show, winner, place
      finalizedBet.eventId = SCR.gameInfo.eventId;

      if (SCR.isScheduleActive) {
        finalizedBet.eventId = SCR._scheduleSvc.activeRound.eventId;
        finalizedBet.event = SCR._scheduleSvc.activeRound.eventIdToday;
      }

      var error = BetslipService.validateBet(
        finalizedBet,
        false
      );

      if (error.msg) {
        NabNotifications.show({
          message: error.msg,
          type: 'warning',
          delay: 3000
        });
      } else {
        BetslipService.addToBetslip(finalizedBet);
      }
    };
  }
};
