angular.module('7Terminal.Common').directive('resolutionMode', function resolutionMode(
  $rootScope,
  $window,
  $timeout
) {
  return {
    link: function () {
      var modes = {
        1: 'landscape',
        2: 'portrait'
      };

      function discoverResulutionMode() {
        if ($window.innerHeight > $window.innerWidth) {
          $rootScope.resolutionMode = modes[2];
        } else {
          $rootScope.resolutionMode = modes[1];
        }
      }

      discoverResulutionMode();

      $window.onresize = function () {
        $timeout(function () {
          discoverResulutionMode();
        }, 0);
      };
    }
  };
});
