<script setup lang="ts">
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import TClock from '@/common/components/TClock.vue';
import InactivityBar from '@/common/components/InactivityBar.vue';
import TerminalInfo from '@/common/components/TerminalInfo.vue';
import { onEndInactivityTimer } from '@/common/services/idle/inactivityTimerHelper';
import TFooterActionsRight from './TFooterActionsRight.vue';
import TFooterActionsLeft from './TFooterActionsLeft.vue';

const configSettingsStore = useConfigSettingsStore();
const inactivityTime = configSettingsStore.data.terminalInactivityTime.value || 60000;

</script>

<template>
  <div class="t-footer-vue d-block sc-bg-500">
    <div class="float-left d-flex h-100 sc-px-3">
      <div
        class="sc-mr-3"
      >
        <InactivityBar
          name="terminal-inactivity"
          orientation="vertical"
          :on-end-inactivity-timer="onEndInactivityTimer"
          :transition-delay="5000"
          :time="inactivityTime"
        />
      </div>

      <div class="t-footer-toggle">
        <TerminalInfo class="sc-mr-3" />
      </div>

      <TFooterActionsLeft />
    </div>

    <div class="float-right d-flex h-100">
      <TFooterActionsRight />
      <TClock />
    </div>
  </div>
</template>

<style lang="scss">
@import 'app/styles/sass/z-index';
$footer-height: 64px;

.t-footer-vue {
    height: $footer-height;
    position: relative;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12), 0px -4px 16px rgba(0, 0, 0, 0.12);
    z-index: 1;
}

@media only screen and (max-width: 1024px) {
    .t-footer-toggle {
        display: none !important;
    }
}

.footer-button-label {
  display: none;
}

@media screen and (min-width: 1281px) {
  .footer-button-label {
    display: inline;
  }
}

@media screen and (max-width: 1280px) {
  .t-footer-button {
      width: 64px;
  }
}
</style>
