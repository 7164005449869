import templateUrl from './install_device.html';

/* @ngInject */
function tInstallDevice(
  $http,
  $log,
  installationService,
  Environment,
  errorParser
) {
  var ctrl = this;
  var profileUuid;
  var dcData = {};

  function finishInstallation() {
    $http({
      method: 'PATCH',
      url: dcData.baseUrl + '/device-install/code.json?showTerminalAccount=true',
      data: {
        code: ctrl.installCode,
        virtualDisplay: true
      }
    }).then(function (response) {
      ctrl.invalidCodeEntered = '';
      saveProfileData(response.data);
    }).catch(function (err) {
      ctrl.invalidCodeEntered = err.message || err.data.message;
    });
  }

  function saveProfileData(data) {
    var profileData = {
      sevenUrl: dcData.baseUrl,
      sevenSocketUrl: dcData.cmUrl,
      profileUuid: profileUuid,
      selfServed: true,
      terminalAccount: data.terminalAccount,
      device: {
        token: data.deviceToken,
        name: data.deviceName,
        deviceId: data.deviceId,
        uuid: data.deviceUuid
      },
      betshop: {
        uuid: data.betshopUuid,
        betshopId: data.betshopId,
        displayId: data.displayId,
        companyName: data.companyName,
        companyUuid: data.companyUuid,
        companyId: data.companyId,
        name: data.betshopName
      }
    };

    ctrl.installer.save(profileData)
      .then(angular.noop)
      .catch(function (err) {
        ctrl.invalidCodeEntered = 'Error on saving data';
        $log.error('[7Terminal.Bootstrap] Error on saving data when installing device', {
          ...errorParser.parseUpstream(err),
          code: 'T_INSTALL_SAVE_DATA_ERR'
        });
      });
  }

  ctrl.closeAppInstance = function () {
    this.installer.onBack();
  };

  ctrl.installDevice = function () {
    var dc;
    var dataCenterInfoUrl;
    var baseDc = process.env.SEVEN_MONOLITH_API_GB;
    if (!ctrl.installCode) {
      return;
    }
    ctrl.installCode = ctrl.installCode.trim();
    // First 2 letters mark DC
    dc = ctrl.installCode.substring(0, 2);
    dataCenterInfoUrl = baseDc + '/data-centers/code/' + dc + '.json';

    $http.get(dataCenterInfoUrl).then(function (response) {
      dcData = response.data;
      finishInstallation();
    }).catch(function (err) {
      ctrl.invalidCodeEntered = err.message || err.data.message;
    });
  };

  ctrl.$onInit = function () {
    ctrl.installCode = '';
    ctrl.invalidCodeEntered = '';
    profileUuid = Environment.getUrlParameter('profileUuid') || '';
    ctrl.installer = installationService.getInstaller();
  };
}

export default {
  templateUrl: templateUrl,
  controller: tInstallDevice
};
