import angular from 'angular';
import integratorModule from '../modules/integrator';
import coreModule from '../core';
import betslipModule from '../betslip';
import peripheralsModule from '../peripherals';
import GamesUiService from './gameUiService';
import GamesController from './gamesController';

/**
  * @ngdoc overview
  * @name 7Terminal.Games
  *
  * @description
  * This module loads and communicates with loaded game betting area.
  *
  *
*/
const moduleName = angular.module('7Terminal.Games', [
  'SevenClientLib',
  peripheralsModule,
  betslipModule,
  coreModule,
  integratorModule
])
  .controller('GamesController', GamesController)
  .service('GamesUiService', GamesUiService)
  .name;

export default moduleName;
