<template>
  <div
    class="d-flex flex-column justify-content-center align-items-end sc-px-6"
  >
    <div class="sc-text-primary-2 sc-text-caption-2">
      {{ date }}
    </div>

    <div class="sc-text-primary-1 sc-text-h6">
      {{ time }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import moment from 'moment';

const date = ref<string>(moment().format('DD.MM.yyyy'));
const time = ref<string>(moment().format('HH:mm'));

let intervalId: number;

onMounted(() => {
  intervalId = window.setInterval(() => {
    date.value = moment().format('DD.MM.yyyy');
    time.value = moment().format('HH:mm');
  }, 5000);
});

onUnmounted(() => {
  window.clearInterval(intervalId);
});
</script>
