import BaseError from '@/common/errors/BaseError';
import { TicketCheckAbortedErrorName } from '../types/TicketCheckAbortedErrorName';

export default class TicketCheckAbortedError extends BaseError<TicketCheckAbortedErrorName> {
  constructor(
    public message: string,
    public code: TicketCheckAbortedErrorName,
  ) {
    super(message, code);
    Object.setPrototypeOf(this, TicketCheckAbortedError.prototype);
    this.name = this.constructor.name;
  }

  toString() {
    return `${this.code}: ${this.message}`;
  }
}
