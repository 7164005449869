import { defineStore } from 'pinia';
import { getHeaderMenuList, getSideMenuList } from '@/common/services/menu';
import { computed } from 'vue';
import { useHeaderMenuStore } from '../header-menu';

const useSideMenuStore = defineStore('sideMenu', () => {
  // stores
  const headerMenuStore = useHeaderMenuStore();
  const headerMenuItems = getHeaderMenuList();
  // data
  const selectedItemReferenceMenuName = computed(() => (
    headerMenuStore.selectedTabIndex !== undefined
      ? headerMenuItems[headerMenuStore.selectedTabIndex]?.item.customData?.referenceMenuName
      : undefined
  ));
  const sideMenuItems = computed(() => (
    selectedItemReferenceMenuName.value
      ? getSideMenuList(selectedItemReferenceMenuName.value)
      : undefined
  ));

  return {
    sideMenuItems,
  };
});

export {
  useSideMenuStore,
};
