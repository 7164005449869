import i18n from '@/plugins/i18n';
import Ticket from '../interfaces/Ticket';
import {
  TicketResponse,
  TicketStatuses,
  TicketCodeTypes,
  ClientTicketActions,
  LocalTicketStatus,
} from '../types';

const { t } = i18n.global;

class ClientTicket implements Ticket {
  localPin: string;

  action?: ClientTicketActions;

  translation: string;

  localStatus?: LocalTicketStatus;

  ticket: TicketResponse;

  taxData: Object;

  taxVerified: boolean = false;

  loyaltyCard?: Object | null;

  platformVal?: string;

  clientVal?: string;

  additionalData?: Object;

  constructor(ticket: TicketResponse) {
    this.ticket = ticket;
    this.localPin = '';
    this.taxData = {};
    this.translation = t(`games.${this.getProductDisplayId().toLowerCase()}`);
    this.loyaltyCard = null;
  }

  getProductDisplayId() {
    return this.ticket.slip.productDisplayId;
  }

  getDisplayId() {
    const sevenCode = this.ticket.slip.codes.find((code) => code.type === TicketCodeTypes.svnbcd1);
    const code39 = this.ticket.slip.codes.find((code) => code.type === TicketCodeTypes.code39);
    return sevenCode?.id || code39?.id || '';
  }

  setPin(pin: string) {
    this.localPin = pin;
  }

  getPin() {
    return this.localPin;
  }

  getStatus() {
    return this.ticket.slip.status;
  }

  isSuperBonus() {
    return this.ticket.ticket.superBonus?.status?.value.toUpperCase() === TicketStatuses.WON;
  }

  getFullPayoutAmount() {
    return this.ticket.slip.payout.total;
  }

  getPayoutAmountWithTax() {
    const { slip } = this.ticket;

    if (slip.payout) {
      let amount = slip.payout.winnings;

      if (slip.payout.tax) {
        amount = slip.payout.tax
          .reduce((prev, currentItem) => prev + (currentItem.total || 0), amount);
      }

      return amount;
    }

    return 0;
  }

  getPayoutAmount() {
    return this.ticket.slip.payout.total;
  }

  getRequestUuid() {
    return this.ticket.slip.requestId;
  }

  setRequestUuid(uuid: string) {
    this.ticket.slip.requestId = uuid;
  }

  taxAuthVerified() {
    this.taxVerified = true;
  }

  getPayoutTax() {
    return this.ticket.slip.payout.tax
      .map((taxObj) => taxObj.total)
      .reduce((previousTax, currentTax) => previousTax + currentTax);
  }

  getPayInTimeInUTC() {
    return this.ticket.slip.creationTime || 0;
  }

  getPayment() {
    return this.ticket.slip.payin.total;
  }

  getTaxData() {
    return this.taxData;
  }

  setTaxData(data: Object) {
    this.taxData = data;
  }

  setTranslation(translation: string) {
    this.translation = translation;
  }

  setAsPending() {
    this.localStatus = LocalTicketStatus.PENDING;
  }

  isWon() {
    return this.ticket.slip.status === TicketStatuses.WON;
  }

  getPossiblePayoutAmount() {
    return this.ticket.ticket.displayPayoutAmount
          || this.ticket.ticket.paidOutAmount
          || this.ticket.ticket.displayPayout
          || this.ticket.ticket.possiblePayout
          || this.ticket.ticket.payoutAmountMax;
  }

  setLoyaltyCard(loyaltyCard: Object) {
    this.loyaltyCard = loyaltyCard;
    this.ticket.loyaltyDetails = loyaltyCard;
  }

  getLoyaltyCard() {
    return this.loyaltyCard || this.ticket.loyaltyDetails;
  }

  getTicketBets() {
    return this.ticket.ticket.bets;
  }

  isCancellable() {
    return !(Object.prototype.hasOwnProperty.call(this.ticket.ticket, 'cancellable') && !this.ticket.ticket.cancellable);
  }
}

export default ClientTicket;
