<script setup lang="ts">
import { ref, computed } from 'vue';
import { SCIcon } from '@nsftx/seven-components';
import router from '@/router';
import { getService } from '@/common/services/ngBridge';

const activeState = ref('');
const showHomeIcon = computed(() => activeState.value !== '/home' && !activeState.value.includes('/lobby/'));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const switchView: any = getService('switchView');

router.beforeEach((route) => {
  activeState.value = route.path;
});

const redirectToHome = () => {
  switchView.selectService('home', false, false);
};
</script>

<template>
  <div
    v-if="showHomeIcon"
    class="h-100 d-flex align-items-center sc-pl-5 sc-pr-7"
    @click="redirectToHome"
  >
    <SCIcon
      icon="n-i-dashboard"
      size="2xl"
    />
  </div>
</template>
