import {
  ProductTicketResponse,
  ProductTicketStatuses,
  TicketResponse,
  betslipApiHelpers,
} from '@/modules/seven-betslip-api';
import TicketCheckStrategyParams from '../types/TicketCheckStrategyParams';

const checkTicketCode = (params: TicketCheckStrategyParams) => {
  const isV2Ticket = betslipApiHelpers.isV2inCmsByProductId(params.productId);
  const clientTicket = {
    slip: {
      productDisplayId: params.productId,
      codes: [
        {
          type: 'code39',
          id: params.code,
        },
      ],
    },
  } as TicketResponse;
  const productTicket = {
    product: params.productId,
    id: params.code,
    status: {
      value: ProductTicketStatuses.pending,
      name: 'pending',
    },
  } as ProductTicketResponse;
  const ticketData = isV2Ticket ? clientTicket : productTicket;
  const ticket = betslipApiHelpers.createTicket(ticketData);

  return Promise.resolve({
    ticketResponse: ticketData,
    ticket,
  });
};

export default {
  checkTicketCode,
};
