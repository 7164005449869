import angular from 'angular';
import '@nsftx/seven-ng-client-horse-races/dist/horseraces';
import commonModule from '../../common';
import coreModule from '../../core';
import gamesModule from '../index';
import resultsUrl from '../results/results.html';
import resultsContentUrl from '../results/racer_games/content.html';

angular.module('terminalApp.nabHorseRacesUi', [
  'nabHorseRaces',
  commonModule,
  coreModule,
  gamesModule
]).config(function ($stateProvider, NabHorseRacesConfigProvider, SevenRoutes) {
  for (var i = 0; i < window.BootStrapData.clientAppModules.length; i++) {
    var config = window.BootStrapData.clientAppModules[i];
    if (config.id === 'VirtualHorseRaces') {
      window.SevenHorseRaces.getConfigService()
        .set({
          companyUuid: window.BootStrapData.company.uuid
        });
    }
  }

  $stateProvider.state('app.games.horseraces', {
    url: '/horseRaces',
    template: '<horse-races-ui></horse-races-ui>'

  });

  $stateProvider.state('app.results.horseraces', {
    url: '/horseraces?id',
    templateUrl: resultsUrl,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.horseraces.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentUrl,
        controller: 'RacerGameResultsController'
      }
    }
  });

  $stateProvider.state('app.games.virtualhorseraces', {
    url: '/virtualHorseRaces',
    template: '<horse-races-ui></horse-races-ui>'

  });

  $stateProvider.state('app.results.virtualhorseraces', {
    url: '/virtualhorseraces?id',
    templateUrl: resultsUrl,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.virtualhorseraces.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentUrl,
        controller: 'RacerGameResultsController'
      }
    }
  });
}).run(function (
  HorseRacesUiService,
  $rootScope
) {
  $rootScope.$on('HorseRaces:ConfigLoaded', function (e, game) {
    $rootScope.$emit('HorseRaces:SetRunningProduct', {
      runningProduct: '7Terminal'
    });
    HorseRacesUiService.boot(game);
  });

  $rootScope.$on('7T:Slave.Shown', function (e, data) {
    if (data.productId === 'HorseRaces'
            || data.productId === 'VirtualHorseRaces') {
      $rootScope.$broadcast('7T:Betslip.ActivateTicket', {
        data: {
          betslipId: data.productId,
          ticketId: 'single'
        }
      });
    }
  });
});
