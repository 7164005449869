import { where, range, each } from 'underscore';

/* @ngInject */
function ScrUiService(
  $rootScope,
  nabMessaging,
  nabTrans,
  SevenGamesSvc,
  SevenClientConfig,
  BetslipService,
  NabNotifications,
  ticketService,
  GravitySettings
) {
  var TICKET_TYPES = SevenClientConfig.getSettings().global.ticketTypes;
  var bet;
  var finalizedBet;
  var selectedBet;
  var bets;

  var self = this;

  /** Status of bets and active racers* */

  this.disableReverseForecast = false;
  this.disableForecast = false;
  this.disableReverseTricast = false;
  this.disableTricast = false;
  this.reverseForecastActive = false;

  this.activeRacer = {};
  this.winner = {};
  this.place = {};
  this.show = {};
  this.forecast = {};
  this.forecastFirstPlace = {};
  this.forecastSecondPlace = {};
  this.forecastAny = {};
  this.tricastFirstPlace = {};
  this.tricastSecondPlace = {};
  this.tricastThirdPlace = {};
  this.tricastAny = {};
  this.forecastFirstPlaces = [];
  this.forecastSecondPlaces = [];
  this.tricastFirstPlaces = [];
  this.tricastSecondPlaces = [];
  this.tricastThirdPlaces = [];
  this.reverseForecastPlaces = [];
  this.reverseTricastPlaces = [];
  /** Scheduled events */
  this.scheduledEvents = {};

  this.createScheduledEvents = function (events) {
    for (var i = 0; i < events.length; i++) {
      var event = events[i].eventId;
      this.scheduledEvents[event] = {};

      this.createRacecardSchedule(event);
      this.createForecastSchedule(event);
      this.createTricastSchedule(event);
    }
  };

  this.updateScheduledEvents = function (events) {
    var ids = Object.keys(this.scheduledEvents);

    self.createScheduledEvents([events[events.length - 1]]);
  };

  this.clearScheduledEvents = function (currentEvent) {
    delete this.scheduledEvents[currentEvent];
  };

  this.getTricastPlaceData = function () {
    return {
      tricastFirstPlace: this.tricastFirstPlace,
      tricastSecondPlace: this.tricastSecondPlace,
      tricastThirdPlace: this.tricastThirdPlace,
      tricastFirstPlaces: this.tricastFirstPlaces,
      tricastSecondPlaces: this.tricastSecondPlaces,
      tricastThirdPlaces: this.tricastThirdPlaces
    };
  };

  this.createRacecardSchedule = function (event) {
    this.scheduledEvents[event].winner = {};
    this.scheduledEvents[event].place = {};
    this.scheduledEvents[event].show = {};
  };

  this.createForecastSchedule = function (event) {
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');
    var racersFirstPlace = range(1, SCR.numberOfRacers + 1);
    var racersSecondPlace = range(1, SCR.numberOfRacers + 1);

    self.scheduledEvents[event].forecast = {};

    each(racersFirstPlace, function (firstPlace) {
      each(racersSecondPlace, function (secondPlace) {
        if (firstPlace != secondPlace) {
          self.scheduledEvents[event].forecast[firstPlace + '-' + secondPlace] = false;
        }
      });
    });
  };

  this.createTricastSchedule = function (event) {
    this.scheduledEvents[event].tricastFirstPlace = {};
    this.scheduledEvents[event].tricastSecondPlace = {};
    this.scheduledEvents[event].tricastThirdPlace = {};
    this.scheduledEvents[event].tricastAny = {};
  };

  this.clearForecastActiveState = function () {
    this.forecastFirstPlace = {};
    this.forecastSecondPlace = {};
    this.forecastAny = {};
  };

  this.clearTricastActiveState = function () {
    this.tricastFirstPlace = {};
    this.tricastSecondPlace = {};
    this.tricastThirdPlace = {};
    this.tricastAny = {};
  };

  this.clearRacecardActiveState = function () {
    this.winner = {};
    this.place = {};
    this.show = {};
  };

  this.deactivateForecastRacers = function () {
    each(this.forecast, function (value, position, racer) {
      racer[position] = false;
    });

    each(this.forecastFirstPlace, function (value, position, racer) {
      racer[position] = false;
    });

    each(this.forecastSecondPlace, function (value, position, racer) {
      racer[position] = false;
    });

    each(this.forecastAny, function (value, position, racer) {
      racer[position] = false;
    });
  };

  this.deactivateTricastRacers = function () {
    each(this.tricastFirstPlace, function (value, position, racer) {
      racer[position] = false;
    });

    each(this.tricastSecondPlace, function (value, position, racer) {
      racer[position] = false;
    });

    each(this.tricastThirdPlace, function (value, position, racer) {
      racer[position] = false;
    });

    each(this.tricastAny, function (value, position, racer) {
      racer[position] = false;
    });
  };

  this.deactivateRacecardRacers = function () {
    var racerIndex;

    each(this.racers, function (position) {
      racerIndex = 'r' + (position);

      self.winner[racerIndex] = false;
      self.show[racerIndex] = false;
      self.place[racerIndex] = false;
    });
  };

  this.deactivateScheduleForecast = function () {
    for (var i in self.scheduledEvents) {
      self.createForecastSchedule(i);
    }
  };

  // enable active bets
  this.enableActiveBets = function (bets) {
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');

    bets.forEach(function (item) {
      if (item.clientId < 10) { // just winner, place
        self[item.key]['r' + item.displayValue] = true;
      }
      if (item.clientId == 10 && !self.reverseForecastActive) { // update forecast
        if (SevenGamesSvc.getGame('SlotCarRaces').isScheduleActive && (item.eventId >= SCR.gameInfo.eventId)) {
          self.scheduledEvents[item.eventId].forecast[item.displayValue] = true;
        }

        self.forecast[item.displayValue] = true;
      }

      if (item.clientId == 12 && self.reverseForecastActive) {
        self.forecast[item.input[0] + '-' + item.input[1]] = true;
        self.forecast[item.input[1] + '-' + item.input[0]] = true;

        if (SCR.isScheduleActive && (item.eventId >= SCR.gameInfo.eventId)) {
          self.scheduledEvents[item.eventId].forecast[item.input[0] + '-' + item.input[1]] = true;
          self.scheduledEvents[item.eventId].forecast[item.input[1] + '-' + item.input[0]] = true;
        }
      }
    });
  };

  // reset view bets state
  this.clearActiveBets = function () {
    self.disableReverseForecast = false;
    self.disableForecast = false;
    self.disableReverseTricast = false;
    self.disableTricast = false;

    self.forecastFirstPlaces = [];
    self.forecastSecondPlaces = [];
    self.reverseForecastPlaces = [];
    self.tricastFirstPlaces = [];
    self.tricastSecondPlaces = [];
    self.tricastThirdPlaces = [];
    self.reverseTricastPlaces = [];

    self.deactivateForecastRacers();
    self.deactivateTricastRacers();
    self.deactivateRacecardRacers();

    if (SevenGamesSvc.getGame('SlotCarRaces').isScheduleActive) {
      self.deactivateScheduleForecast();
    }
  };

  this.formatTicketBets = function (message) {
    message.ticketFormatted = formatter(message.ticket);

    function formatter(ticket) {
      var ticket = ticket;
      var bets = ticket.bets;
      var result = { bets: [], payin: 0 };

      for (var j = 0; j < bets.length; j++) {
        result.bets.push({
          payin: bets[j].stake ? bets[j].stake : false,
          type: bets[j].id,
          value: bets[j].value,
          eventId: bets[j].eventId,
          numEvents: bets[j].numEvents ? bets[j].numEvents : 1
        });

        result.payin += bets[j].stake;
      }
      return result;
    }
  };

  function createTicket() {
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');

    if (SCR && !BetslipService.getTicket('SlotCarRaces' + TICKET_TYPES.SINGLE.name)) {
      BetslipService.createTicket({
        id: TICKET_TYPES.SINGLE.name,
        active: true,
        ticketGroup: 'SlotCarRaces',
        betsCollection: 'SlotCarRaces',
        group: {
          id: 'SlotCarRaces',
          taxesConfig: SCR.config.taxes
        },
        ticketType: TICKET_TYPES.SINGLE,
        oddProp: 'mboOddValue',
        stake: SCR.getRule('minPayinAmount', {
          ticketType: TICKET_TYPES.SINGLE.id
        }),
        stakeTouched: false,
        setStakeStrategy: 'sevenVirtualSetStake',
        autoStakeSetting: true,
        winningStrategy: false,
        setFormatPayin: 'sevenVirtualFormataPayin',
        hasSystem: false,
        emptyBetslipText: nabTrans.translate(
          'betslip.pick_numbers_message_first_line', {}, true
        )
      });
    }
  }

  // listen for config changes
  nabMessaging.subscribe('NCM:gameConfigChanged', function (event, message) {
    if (message.game == 'SlotCarRaces') {
      var game = SevenGamesSvc.getGame(message.game);

      game.setConfig().then(function () {
        game.doInitialConfiguration();

        BetslipService.updateTicketConfig(game.id + TICKET_TYPES.SINGLE.name, {
          group: {
            id: game.id,
            taxesConfig: game.config.taxes
          },
          stake: game.getRule('minPayinAmount', {
            ticketType: TICKET_TYPES.SINGLE
          })
        });

        nabMessaging.publish('config:gameConfigChanged', message.game);
      });
    }
  });

  nabMessaging.subscribe('SCR:raceStart', function (e, data) {
    var betsToRemove = [];
    var eventId = data.eventId;
    var SCR = SevenGamesSvc.getGame(data.game);
    var ticket = BetslipService.getTicket('single', data.game);

    if (ticket) {
      ticket.getBets().forEach(function (bet) {
        if (bet.eventId == SCR.gameInfo.eventId) {
          bet.locked = true;
          betsToRemove.push(bet.uid);
        }
      });

      if (betsToRemove.length) {
        ticket.locked = true;
      }
    }
  });

  nabMessaging.subscribe('SCR:updateEventsNew', function (e, data) {
    var game = SevenGamesSvc.getGame(data.game);
    self.updateScheduledEvents(game.offer, game)
  });

  // update rounds and odds on betslip
  nabMessaging.subscribe('SCR:updateEventsOld', function (e, data) {
    var betsToUpdate = [];
    var eventAfter = data.param;
    var eventId = data.eventId;
    var SCR = SevenGamesSvc.getGame(data.game);
    var ticket = BetslipService.getTicket('single', data.game);

    if (ticket) {
      ticket.getBets()
        .forEach(function (bet) {
          betsToUpdate.push({
            event: eventAfter,
            eventId: eventId,
            uid: bet.uid
          });

          if (SCR.isScheduleActive) {
            // if(bet.eventId < eventId) {
            //   betsToRemove.push(bet.uid);
            // }
          } else {
            /** Update odds when new round starts * */

            if (bet.id >= 10 && bet.numOfComb == 1) { // odds are display just for one combination
              bet.odd = bet.id == 10 ? SCR._bettingSvc.systemBets.forecastOdds[bet.displayValue] : SCR._bettingSvc.systemBets.tricastOdds[bet.displayValue];
            }

            if (bet.id < 10) {
              bet.odd = SCR._bettingSvc.normalBetsOdds[bet.key][bet.displayValue];
            }
          }
        });

      if (SCR.isScheduleActive) {
        self.updateScheduledEvents(SCR.offer, SCR);
      } else {
        $rootScope.$broadcast('7T:Betslip.UpdateBets', {
          data: {
            betslipId: data.game,
            bets: betsToUpdate
          }
        });
      }
    }
  });

  // listen for betslip payin action
  $rootScope.$on('7T:Betslip.Payin',
    /**
         *
         * @param event
         * @param {Object} message
         * @param {String} message.productId
         * @param {Object} message.ticket
         */
    function (event, message) {
      if (message.productId === 'SlotCarRaces') {
        self.formatTicketBets(message);
        ticketService.payinTicket(message).catch(angular.noop);
      }
    });

  $rootScope.$on('7T:Tickets.PrePrint', function (e, eventData) {
    var SCR;
    var BettingSvc;
    const productId = eventData.productId;
    const isInIframe = GravitySettings
        .getModuleDataKeyValue(`module.${productId}`, 'frame') === true;

    if (isInIframe) {
      return;
    }

    if (eventData.productId === 'SlotCarRaces') {
      SCR = SevenGamesSvc.getGame('SlotCarRaces');
      BettingSvc = SCR.getBettingSvc();
      angular.merge(eventData.responseData, BettingSvc.prepareForPrint(
        eventData.responseData,
        SCR.config
      ));

      $rootScope.$emit('7T:Tickets.Print', eventData);
    }
  });

  this.boot = function () {
    createTicket();

    // translation could be changed so always update
    BetslipService.updateTicketConfig('SlotCarRaces', {
      emptyBetslipText: nabTrans.translate('betslip.pick_numbers_message_first_line', {}, true)
    });
  };

  this.addReverseForecastBet = function (activeBet) {
    var bets = BetslipService.ticket.getBets();
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');
    var BettingSvc = SCR.getBettingSvc();

    var selectedBetComb1 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[0] })[0];
    var selectedBetComb2 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[1] })[0];

    if (SCR.isScheduleActive) {
      selectedBetComb1 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[0], eventId: SCR._scheduleSvc.activeRound.eventId })[0];
      selectedBetComb2 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[1], eventId: SCR._scheduleSvc.activeRound.eventId })[0];
    }

    if (selectedBetComb1 || selectedBetComb2) {
      var toRemove = selectedBetComb1 ? selectedBetComb1.uid : selectedBetComb2.uid;
      BetslipService.removeFromBetslip(toRemove, false);
      return;
    }

    var bet = where(SCR.configuredBets, { key: 'reverseForecast' });
    BettingSvc.activateBet(bet[0]);

    if (BettingSvc.ticketInput.systemOdd) {
      delete BettingSvc.ticketInput.systemOdd;
    }
    var finalizedBet = BettingSvc.finalizeTicketInput()[0];

    if (SCR.isScheduleActive) {
      finalizedBet.eventId = SCR._scheduleSvc.activeRound.eventId;
      finalizedBet.event = SCR._scheduleSvc.activeRound.eventIdToday;
    }
    var error = BetslipService.validateBet(
      finalizedBet,
      false
    );

    if (error.msg) {
      NabNotifications.show({
        message: error.msg,
        type: 'warning',
        delay: 3000
      });
    } else {
      BetslipService.addToBetslip(finalizedBet);
    }
  };

  this.addBet = function (activeBet) {
    var SCR = SevenGamesSvc.getGame('SlotCarRaces');
    var BettingSvc = SCR.getBettingSvc();
    var self = this;

    if (activeBet.key == 'reverseForecast') {
      self.addReverseForecastBet(activeBet);
      return;
    }
    bets = BetslipService.ticket.getBets();
    selectedBet = where(bets, { key: activeBet.bet, odd: activeBet.value })[0];

    if ((this[activeBet.bet] && (this[activeBet.bet]['r' + activeBet.racers[0]] || this[activeBet.bet][activeBet.racers]))
        && (SCR.isScheduleActive ? this.scheduledEvents[SCR._scheduleSvc.activeRound.eventId].forecast[activeBet.racers] : true)) {
      if (SCR.isScheduleActive) {
        self.scheduledEvents[SCR._scheduleSvc.activeRound.eventId].forecast[activeBet.racers] = false;
        selectedBet = where(bets, { key: activeBet.bet, eventId: SCR._scheduleSvc.activeRound.eventId, displayValue: activeBet.racers })[0];
      }

      BetslipService.removeFromBetslip(selectedBet.uid, false);
      return;
    }

    bet = where(SCR.configuredBets, { key: activeBet.bet });
    BettingSvc.activateBet(bet[0]);
    finalizedBet = BettingSvc.finalizeTicketInput()[0];
    finalizedBet.disableEdit = true; // disable editing - winner,place
    finalizedBet.odd = activeBet.value;
    finalizedBet.eventId = SCR.gameInfo.eventId;

    if (SCR.isScheduleActive) {
      finalizedBet.eventId = SCR._scheduleSvc.activeRound.eventId;
      finalizedBet.event = SCR._scheduleSvc.activeRound.eventIdToday;
    }

    var error = BetslipService.validateBet(
      finalizedBet,
      false
    );

    if (error.msg) {
      NabNotifications.show({
        message: error.msg,
        type: 'warning',
        delay: 3000
      });
    } else {
      BetslipService.addToBetslip(finalizedBet);
    }
  };
}

export default ScrUiService;
