export default function fullBetName() {
  // define global variables / config

  var sLetters = ['X', 'Y', 'Z', 'Q']; // letters in bet name that are to be replaced

  /**
     *  Filters serves purpose of generating full bet name for MTS products bets.
     *  It replaces patterns from bet name (mostly X-es) with provided special values
     *  @param {Object} => {
     *                       betName : required -> original bet name,
     *                       specialValue : optional -> value to be inserted on found pattern name,
     *                     }
     *   @returns {String}
     * */
  return function (betData) {
    var betName;
    var sValues;
    var letter;
    var patLett;
    // no special value - simply return bet name
    if (!betData.specialValue || betData.specialValue === '*') {
      return betData.betName;
    }

    betName = betData.betName || '';

    sValues = betData.specialValue.split('|');

    if (sValues.some(function (specValue, idx) {
      letter = sLetters[idx];
      patLett = new RegExp('\\[' + letter + '\\]', 'i');

      if (patLett.test(betName)) {
        betName = betName.replace(patLett, specValue);
        return false;
      }

      return true;
    })) {
      betName = betData.betName + ' (' + betData.specialValue + ')';
    }
    return betName;
  };
}
