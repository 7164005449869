<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { AxiosError } from 'axios';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useAuthStore, authService } from '@/modules/auth';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import TModal from '@/common/components/TModal.vue';
import TPasswordInput from '@/common/components/TPasswordInput.vue';

const authStore = useAuthStore();
const { isPINLoginModalOpened } = storeToRefs(authStore);
const enteredPin = ref('');
const pinChanged = ref(false);
const showPinError = computed(() => !enteredPin.value && pinChanged.value);
const isFormValid = computed(() => !showPinError.value && pinChanged.value);

const { t } = i18n.global;

const onLoginClicked = () => {
  authService.login({
    ...authService.cardDataCredentials.value,
    pin: enteredPin.value,
  }).then((response) => {
    isPINLoginModalOpened.value = false;
    authService.switchToTBO(response.data);
    authService.clearCardData();
  }).catch((error: AxiosError) => {
    const notificationsStore = useNotificationsStore();

    notificationsStore.show({
      message: error.message || error.response?.statusText || error.status?.toString() || '',
      type: TNotificationTypeEnum.error,
      delay: 6000,
    });
  });
};

const cleanUpForm = () => {
  enteredPin.value = '';
  pinChanged.value = false;
};

</script>

<template>
  <TModal
    v-model="isPINLoginModalOpened"
    with-close-icon
    modal-id="pin-login"
    top="88px"
    @update:model-value="cleanUpForm"
  >
    <template #title>
      {{ t('auth_pin_login') }}
    </template>

    <template #content>
      <div class="w-100">
        <TPasswordInput
          v-model="enteredPin"
          :label="t('user.pin')"
          :error="showPinError"
          :hint="showPinError ? t('validation_pin_required') : ''"
          @update:model-value="pinChanged = true"
        />
      </div>
    </template>

    <template #actions>
      <SCButton
        :variant="ButtonVariant.Primary"
        width="100%"
        :disabled="!isFormValid"
        @click="onLoginClicked"
      >
        {{ t('main.log_in') }}
      </SCButton>
    </template>
  </TModal>
</template>
