import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';
import StrategyNames from '../enums/StrategyNames';
import availableActionsHelper from './availableActionsHelper';
import TicketCheckResult from '../types/TicketCheckResult';

const loadActions = (
  result: TicketCheckResult | null,
  detectedStrategy?: StrategyNames,
) => {
  if (!result) {
    return [];
  }
  const gravitySettingsStore = useGravitySettingsStore();
  const isTicket = betslipApiHelpers.isTicketInstance(result);
  const productId = isTicket ? result.getProductDisplayId() : 'Voucher';
  const showActionsInFrame = gravitySettingsStore.getModuleDataKeyValue<boolean>(
    `module.${productId}.ticketCheck`,
    'showActionsInFrame',
  );

  if (!isTicket) {
    return availableActionsHelper.loadAnonymousVoucherActions(result);
  }

  if (detectedStrategy !== StrategyNames.slave && !showActionsInFrame) {
    return availableActionsHelper.loadAnonymousTicketActions(result);
  }

  return [];
};

export default {
  loadActions,
};
