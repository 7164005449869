/**
 * @memberOf module:"7Terminal.Core"
 * @class GravitySettingsProvider
 */
function GravitySettingsProvider() {
  var self = this;
  var log;

  /**
   * @memberOf module:"7Terminal.Core".GravitySettings
   */
  this._data = {};

  /**
     * @memberOf module:"7Terminal.Core".GravitySettings
     */
  this.setData = function (applicationSettings) {
    this._data = applicationSettings;
  };

  /**
   * @memberOf module:"7Terminal.Core".GravitySettings
   */
  this.getByKey = function (key) {
    return this._data[key];
  };

  /**
   * @memberOf module:"7Terminal.Core".GravitySettings
   * @param {String} moduleName
   * @param {String} key
   * @returns {*}
   */
  this.getModuleDataKeyValue = function (moduleName, key) {
    var moduleData = this.getByKey(moduleName) || {};
    return moduleData[key];
  };

  /**
   * @memberOf module:"7Terminal.Core".GravitySettings
   * @returns {*}
   */
  this.getData = function () {
    return this._data;
  };

  /**
   * @memberOf module:"7Terminal.Core".GravitySettings
   * @param {String} moduleName
   * @param {Object} data
   * @returns {*}
   */
  this.updateSetting = function (moduleName, newData) {
    var moduleData = this.getByKey(moduleName);

    if (moduleData) {
      log.debug('[7Terminal.Gravity] Setting updated:', moduleName);
      angular.extend(moduleData, newData);
    }
  };

  /**
   *
   */
  this.$get = function ($log) {
    'ngInject';

    log = $log;
    return self;
  };
}

export default GravitySettingsProvider;
