import { setConfig as setSevenComponentsConfig } from '@nsftx/seven-components';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useLanguageStore } from '@/common/stores/language';
import type { VirtualKeyboardConfig } from './types';

const getKeyboardLayout = () => {
  const { getByKey } = useGravitySettingsStore();
  const { defaultLayout, layoutByLanguage } = getByKey<VirtualKeyboardConfig>('virtualKeyboard') || {};
  const { getCurrentLanguage } = useLanguageStore();
  const lang = getCurrentLanguage();

  return layoutByLanguage?.[lang] || defaultLayout;
};

const init = () => {
  setSevenComponentsConfig({
    SCKeyboard: {
      layout: getKeyboardLayout(),
    },
  });
};

export {
  init,
  getKeyboardLayout,
};
