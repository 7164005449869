import i18n from '@/plugins/i18n';
import { DmPrinterState } from '@nsftx/systems-sdk';
import type { PrinterStatusResponse } from './index';
import type { PrinterSpsResponseData, PrinterSpsStatus, PrinterStatus } from './interfaces/printerMessageData';
import { PrinterStatusCode, PrinterSpsStatusSeverity } from './enums';
import {
  isSpsPaperLow,
  isSpsPaperOut,
  getPrinterDamagedStatus,
  getTranslatedDamagedStatusMessage,
  isDmPrinterStatus,
} from './printerHelpers';

const getPrinterStatusByDamagedState = (
  statuses: (PrinterSpsStatus | DmPrinterState)[],
  canPrint: boolean,
) => {
  const status: PrinterStatus = {
    valid: false,
    code: PrinterStatusCode.Undefined,
    message: '',
    paperLow: false,
    paperOut: false,
  };
  const damagedStatus = getPrinterDamagedStatus(statuses, canPrint);
  if (!damagedStatus) {
    return status;
  }

  const { name, severity } = damagedStatus;
  status.code = severity === PrinterSpsStatusSeverity.Error
    ? PrinterStatusCode.Error
    : PrinterStatusCode.Warning;
  status.valid = severity !== PrinterSpsStatusSeverity.Error;
  status.message = getTranslatedDamagedStatusMessage(name);
  status.paperLow = isSpsPaperLow(name);
  status.paperOut = isSpsPaperOut(name);

  return status;
};

const parsePrinterSpsResponse = (response: PrinterSpsResponseData): PrinterStatus => {
  const damagedStatusName = response.damagedStatus?.name || response.damagedStatusName || '';
  const isValid = response.valid !== false;
  const statusMessage = response.message
  || (damagedStatusName ? getTranslatedDamagedStatusMessage(damagedStatusName) : '');
  const statusCode = !isValid
    ? PrinterStatusCode.Error
    : response.damagedStatus?.status as PrinterStatusCode || PrinterStatusCode.Ok;
  const status: PrinterStatus = {
    valid: isValid,
    code: statusCode,
    message: statusMessage,
    paperLow: isSpsPaperLow(damagedStatusName),
    paperOut: isSpsPaperOut(damagedStatusName),
  };

  return status;
};

const parsePrinterStatusResponse = (response: PrinterStatusResponse) => {
  const { t } = i18n.global;
  const { status: printerStatuses } = response;
  const isPrinterOnline = isDmPrinterStatus(response)
    ? response.can_print
    : response.online;
  let status: PrinterStatus = {
    valid: false,
    code: PrinterStatusCode.Undefined,
    message: '',
    paperLow: false,
    paperOut: false,
  };

  if (!printerStatuses?.length) {
    if (!isPrinterOnline) {
      status.code = PrinterStatusCode.Error;
      status.message = t('print.printer_offline');
      return status;
    }

    status.code = PrinterStatusCode.Ok;
    status.valid = true;
    return status;
  }

  status = getPrinterStatusByDamagedState(
    printerStatuses,
    !!isPrinterOnline,
  );
  if (status.code !== PrinterStatusCode.Undefined) {
    return status;
  }

  status.code = PrinterStatusCode.Ok;
  status.valid = true;

  return status;
};

export {
  parsePrinterStatusResponse,
  parsePrinterSpsResponse,
};
