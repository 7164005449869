/* @ngInject */
function ProductTicketService(
  $filter,
  LOCAL_TICKET_ACTIONS
) {
  /**
     *
     * @class ProductTicket
     */
  function ProductTicket() {
    this.ticket = null;
    this.slip = null;
    this.product = null;
    this.availableActions = [];
    this.actions = [];
    this.taxVerified = false;
    this.tax = {};
  }

  ProductTicket.prototype.setData = function (data) {
    this.ticket = data.ticket;
    this.slip = data.slip;
    this.product = data.slip.productDisplayId;
    this.availableActions = [];
    this.actions = [];
    this.tax = data.tax || {};
  };

  ProductTicket.prototype.addAvailableActions = function (actions) {
    var action;
    var self = this;

    angular.forEach(actions, function (value, key) {
      action = LOCAL_TICKET_ACTIONS[key.toUpperCase()];
      if (action && value.active === true) {
        self.availableActions.push(action);
      }
    });
  };

  /**
    * Get display id of ticket
    *
    * @memberof ProductTicket
    */
  ProductTicket.prototype.getDisplayId = function () {
    var sevenCode = $filter('filter')(this.slip.codes, { type: 'svnbcd1' }, true);
    var code39 = $filter('filter')(this.slip.codes, { type: 'code39' }, true);
    return (sevenCode.length && sevenCode[0].id) || (code39.length && code39[0].id);
  };

  /**
  * Get request uuid of ticket
  *
  * @memberof ProductTicket
  */
  ProductTicket.prototype.getRequestUuid = function () {
    return this.slip && this.slip.requestId;
  };

  /**
  * Add request uuid
  *
  * @memberof ProductTicket
  * @returns {number}
  */
  ProductTicket.prototype.addRequestUuid = function (requestUuid) {
    this.slip.requestId = requestUuid;
  };

  /**
  * Get Product Display Id
  *
  * @memberof ProductTicket
  * @returns {String}
  */
  ProductTicket.prototype.getProductDisplayId = function () {
    return this.product;
  };

  /**
  * Get ticket server status
  *
  * @memberof ProductTicket
  * @returns {String}
  */
  ProductTicket.prototype.getStatus = function () {
    return this.slip.status;
  };

  /**
  * Sets ticketPin property inside slip. It represents pin entered in Retail popup.
  *
  * @memberof ProductTicket
  * @param {String} pin
  */
  ProductTicket.prototype.setPin = function (pin) {
    this.slip.ticketPin = pin;
  };

  /**
  * Sets ticketPin property inside slip. It represents pin entered in Retail popup.
  *
  * @memberof ProductTicket
  * @returns {String} pin
  */
  ProductTicket.prototype.getPin = function () {
    return this.slip.ticketPin;
  };

  /**
  * Returns full amount to payout - including taxes and bonuses
  *
  * @memberof ProductTicket
  * @returns {number | undefined}
  */
  ProductTicket.prototype.getFullPayoutAmount = function () {
    return this.slip && this.slip.payout && this.slip.payout.total;
  };

  /**
  * Returns full amount to payout - including taxes and bonuses
  *
  * @memberof ProductTicket
  * @returns {number | undefined}
  */
  ProductTicket.prototype.getPossiblePayoutAmount = function () {
    return this.slip && this.slip.payout && this.slip.payout.total;
  };

  /**
  * Returns amount with included tax to payout - without bonuses
  *
  * @memberof ProductTicket
  * @returns {number}
  */
  ProductTicket.prototype.getPayoutAmountWithTax = function () {
    let amount = 0;
    if (this.slip && this.slip.payout) {
      amount = this.slip.payout.winnings;

      if (this.slip.payout.tax) {
        this.slip.payout.tax.forEach(function (taxItem) {
          amount += (taxItem.total || 0);
        });
      }

      return amount;
    }

    return 0;
  };

  /**
  * Get stake
  *
  * @memberof ProductTicket
  * @returns {number}
  */
  ProductTicket.prototype.getPayment = function () {
    return this.slip.payin.total;
  };

  /**
   * Ticket is verified by tax authorities
   * @memberof ProductTicket
   */
  ProductTicket.prototype.taxAuthVerified = function () {
    this.taxVerified = true;
  };

  /**
  * Append tax data
  *
  * @memberof ProductTicket
  * @returns {number}
  */
  ProductTicket.prototype.setTaxData = function (data) {
    this.tax = data;
  };

  /**
  * Get tax data
  *
  * @memberof ProductTicket
  * @returns {number}
  */
  ProductTicket.prototype.getTaxData = function () {
    return this.tax;
  };

  ProductTicket.prototype.getPayoutTax = function () {
    // do nothing, we don't need this logic in legacy part of app
  };

  ProductTicket.prototype.getLoyaltyCard = function () {
    // do nothing, we don't need this logic in legacy part of app
  };

  return ProductTicket;
}

export default ProductTicketService;
