import * as cardReaderService from './cardReaderService';
import * as apiService from './apiService';

export { useCardReaderStore } from './store';
export {
  CARD_IN_EVENT_ID,
  cardInEventPubSub,
  CARD_OUT_EVENT_ID,
  cardOutEventPubSub,
  CARD_READ_EVENT_ID,
  cardReadEventPubSub,
} from './cardReaderEventsService';
export type {
  OperatorCardDataInfo,
  OperatorCardDataResponse,
  SocketCardDataIn,
} from './types';
export type {
  CardDataInfo,
  NarCardData,
} from './interfaces';
export { cardReaderService, apiService };
export { CardNid } from './enums';

export const initCardReaderModule = () => {
  cardReaderService.init();
};
