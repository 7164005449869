import * as lobbyService from './lobbyService';
import { addRoutes } from './routes';

export * as lobbyService from './lobbyService';
export * from './types';

export const init = () => {
  lobbyService.init();
  addRoutes();
};
