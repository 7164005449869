import { range, each } from 'underscore';
import templateUrl from './tricastBettingArea.html';

(function () {
  angular.module('terminalApp.nabGreyhoundRacesUi')
    .component('greyhoundTricastBettingArea', {
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        $state,
        nabMessaging,
        GreyhoundRacesUiService
      ) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); var // switch virtual and regular greyhound races
          GreyhoundRaces = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = GreyhoundRaces.getBettingSvc();
        var self = this;

        this.$onInit = function () {
          this.racers = range(1, GreyhoundRaces.numberOfRacers + 1);
          this.activeGame = GreyhoundRaces;
          this.grehoundRacesUISvc = GreyhoundRacesUiService;
          this.createRacers();
          this.grehoundRacesUISvc.disableReverseTricast = false;
          this.grehoundRacesUISvc.disableTricast = false;
        };

        /** Create objects of racers for tricast and reverse tricast
                 * (set them unchecked)
                 * and  racers array* */

        this.createRacers = function () {
          GreyhoundRacesUiService.clearTricastActiveState();
          each(this.racers, function (position) {
            self.grehoundRacesUISvc.tricastFirstPlace['r' + (position)] = false;
            self.grehoundRacesUISvc.tricastSecondPlace['r' + (position)] = false;
            self.grehoundRacesUISvc.tricastThirdPlace['r' + (position)] = false;
            self.grehoundRacesUISvc.tricastAny['r' + (position)] = false;
          });
        };

        // listen for config changes
        nabMessaging.subscribe('config:gameConfigChanged', function (event, game) {
          if (game == 'GreyhoundRaces' || game == 'VirtualGreyhoundRaces') {
            var active_game = SevenGamesSvc.getGame(game);
            if (self.racers.length != active_game.numberOfRacers) {
              self.racers = range(1, active_game.numberOfRacers + 1);
            }
          }
        });

        /** Check first place racer in tricast bet
                 * @param - Number - racer postion* */

        this.checkTricastFirstPlace = function (racer) {
          this.grehoundRacesUISvc.tricastFirstPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.tricastFirstPlace[racer] = !this.grehoundRacesUISvc.tricastFirstPlace[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.tricastFirstPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.tricastFirstPlaces.push(key.substring(1));
            }
          });

          this.validateTricast();

          if (GreyhoundRaces.isScheduleActive) {
            // this.scheduleBet('tricastFirstPlace', racer);
          }
        };

        /** Check second place racer in tricast bet
                 * @param - Number - racer postion* */

        this.checkTricastSecondPlace = function (racer) {
          this.grehoundRacesUISvc.tricastSecondPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.tricastSecondPlace[racer] = !this.grehoundRacesUISvc.tricastSecondPlace[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.tricastSecondPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.tricastSecondPlaces.push(key.substring(1));
            }
          });

          this.validateTricast();

          if (GreyhoundRaces.isScheduleActive) {
            // this.scheduleBet('tricastSecondPlace', racer);
          }
        };

        /** Check third place racer in tricast bet
                 * @param - Number - racer postion* */

        this.checkTricastThirdPlace = function (racer) {
          this.grehoundRacesUISvc.tricastThirdPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.tricastThirdPlace[racer] = !this.grehoundRacesUISvc.tricastThirdPlace[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.tricastThirdPlace, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.tricastThirdPlaces.push(key.substring(1));
            }
          });

          this.validateTricast();

          if (GreyhoundRaces.isScheduleActive) {
            // this.scheduleBet('tricastThirdPlace', racer);
          }
        };

        /**
                 * Check if there are same numbers in combination
                 * hide any bet if anything is checked
                 * */

        this.validateTricast = function () {
          for (var i in this.grehoundRacesUISvc.tricastFirstPlace) {
            if (((this.grehoundRacesUISvc.tricastFirstPlace[i] && this.grehoundRacesUISvc.tricastSecondPlace[i])
                            || (this.grehoundRacesUISvc.tricastFirstPlace[i] && this.grehoundRacesUISvc.tricastThirdPlace[i])
                            || (this.grehoundRacesUISvc.tricastSecondPlace[i] && this.grehoundRacesUISvc.tricastThirdPlace[i]))
                            && (this.grehoundRacesUISvc.tricastFirstPlaces.length < 3 || this.grehoundRacesUISvc.tricastSecondPlaces.length < 3
                                || this.grehoundRacesUISvc.tricastThirdPlaces.length < 3)) {
              this.grehoundRacesUISvc.validForecast = false;
              flashButton.flash = false;
              return;
            }
          }

          this.grehoundRacesUISvc.disableReverseTricast = this.grehoundRacesUISvc.tricastFirstPlaces.length > 0
                                                                    || this.grehoundRacesUISvc.tricastSecondPlaces.length > 0 || this.grehoundRacesUISvc.tricastThirdPlaces.length > 0;
          flashButton.flash = !(this.grehoundRacesUISvc.tricastFirstPlaces.length < 1 || this.grehoundRacesUISvc.tricastSecondPlaces.length < 1
                                            || this.grehoundRacesUISvc.tricastThirdPlaces.length < 1);
          this.grehoundRacesUISvc.validForecast = true;
        };

        this.checkReverseTricastBet = function (racer) {
          this.grehoundRacesUISvc.reverseTricastPlaces = [];
          BettingSvc.clearAll();

          this.grehoundRacesUISvc.tricastAny[racer] = !this.grehoundRacesUISvc.tricastAny[racer]; // toggle racer status

          each(this.grehoundRacesUISvc.tricastAny, function (checked, key) {
            if (key.substring(0, 1) == 'r' && checked) {
              self.grehoundRacesUISvc.reverseTricastPlaces.push(key.substring(1));
            }
          });

          this.validateReverseTricastBet();

          if (GreyhoundRaces.isScheduleActive) {
            // this.scheduleBet('tricastAny', racer);
          }
        };

        /**
                 * Disable tricast if any bet is checked
                 * flash add button if there are two racers
                 * */

        this.validateReverseTricastBet = function () {
          this.grehoundRacesUISvc.disableTricast = this.grehoundRacesUISvc.reverseTricastPlaces.length > 0;

          if (this.grehoundRacesUISvc.reverseTricastPlaces.length < 3) {
            flashButton.flash = false;
            this.grehoundRacesUISvc.validReverseTricast = false;
          } else {
            flashButton.flash = true;
            this.grehoundRacesUISvc.validReverseTricast = true;
          }
        };

        this.scheduleBet = function (bet, racer) {
          self.grehoundRacesUISvc.scheduledEvents[self.activeGame._scheduleSvc.activeRound.eventId][bet][racer] = true;
        };
      }

    });
})();
