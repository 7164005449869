<script setup lang="ts">
import {
  computed, ref, type VNode,
} from 'vue';
import { SCDrawer, SCThumbnail, SCScrollButtons } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { getService } from '@/common/services/ngBridge';
import { useSideMenuStore } from '@/common/stores/side-menu';
import { useHeaderMenuStore } from '@/common/stores/header-menu';
import { useMaintenanceStore } from '@/modules/maintenance';
import { getLoadedImage, getLoadedGhostImage } from '@/common/services/CmsProductImageLoader';
import { MenuItem } from '@/common/services/menu';
import { cmsHelper } from '@/modules/cms';
import { googleAnalyticsService } from '@/modules/google-analytics';

// stores
const sideMenuStore = useSideMenuStore();
const headerMenuStore = useHeaderMenuStore();
const { isGameInMaintenanceState } = useMaintenanceStore();
// data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const switchView: any = getService('switchView');
const { t } = i18n.global;
const sideMenuItemsEl = ref<VNode | undefined>(undefined);
// computed
const isDrawerShown = computed(() => (
  !!sideMenuStore.sideMenuItems
));
const sideMenuListClasses = computed(() => ({
  'side-menu-container__item-list--odd-len': (sideMenuStore.sideMenuItems?.length || 0) % 2 !== 0,
}));

// methods
const onDrawerClose = () => {
  headerMenuStore.resolvePreviousTabIndex();
};
const selectGame = (menuItem: MenuItem) => {
  switchView.selectService(
    menuItem.context.id,
    true,
    {
      genericId: menuItem.context.id,
      gameId: menuItem.context.id,
    },
  );
  onDrawerClose();
};
</script>

<template>
  <SCDrawer
    :model-value="isDrawerShown"
    with-close-icon
    @update:model-value="onDrawerClose()"
  >
    <div class="h-100 d-flex flex-column side-menu-container">
      <!-- Title -->
      <div class="sc-pt-6 sc-pb-5 sc-px-6 sc-text-display-4">
        {{ t('games.virtual_games') }}
      </div>

      <!-- Items list -->
      <div
        ref="sideMenuItemsEl"
        class="
          flex-grow
          overflow-auto
          sc-pt-5
          sc-px-6
          sc-pb-6
          side-menu-container__item-list
        "
        :class="sideMenuListClasses"
      >
        <SCThumbnail
          v-for="menu in sideMenuStore.sideMenuItems"
          :key="menu.id"
          :image="getLoadedImage(
            menu.item.url || menu.context?.classId
          )"
          :background-image="getLoadedGhostImage(
            menu.item.url || menu.context?.classId
          )"
          :background-color="menu.item.customData?.backgroundColor || '' "
          :overlay-background-image="menu.item.customData?.overlayBackgroundImage || '' "
          :alt="menu.item.url"
          :blended="false"
          :label="cmsHelper.getProductCardLabel(menu)"
          :disabled="isGameInMaintenanceState(menu.item.url) ? 'DISABLED' : ''"
          min-height="240px"
          tabindex="0"
          class="side-menu-container__item-list__item"
          @click="selectGame(menu)"
        >
          <template #title>
            {{ menu.translation }}
          </template>
        </SCThumbnail>
      </div>

      <SCScrollButtons
        block
        :to-top="false"
        :content="sideMenuItemsEl"
        @content-scrolled="(direction) => googleAnalyticsService.trackScrollEvent(
          direction,
          'Side menu'
        )"
      />
    </div>
  </SCDrawer>
</template>

<style lang="scss">
.side-menu-container {
  width: 31vw;
  min-width: 464px;

  &__item-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;

    &--odd-len &__item:last-child {
      grid-column: 1 / span 2;
    }
  }
}
</style>
