import angular from 'angular';
import ngLibModule from '../../modules/ng_lib';
import '@nsftx/seven-ng-client-lucky-x/dist/luckyx';
import commonModule from '../../common';
import coreModule from '../../core';
import sevenGamesModule from '../index';
import resultsTemplateUrl from '../results/results.html';
import resultsContentTemplateUrl from './results/lucky_x.html';

angular.module('terminalApp.nabLuckyXUi', [
  ngLibModule,
  'nabLuckyX',
  commonModule,
  coreModule,
  sevenGamesModule
]).config(function ($stateProvider) {
  for (var i = 0; i < window.BootStrapData.clientAppModules.length; i++) {
    var config = window.BootStrapData.clientAppModules[i];
    if (config.id == 'LuckyX') {
      window.SevenLuckyX.getConfigService()
        .set({
          companyUuid: window.BootStrapData.company.uuid
        });
    }
  }

  $stateProvider.state('app.games.luckyx', {
    url: '/luckyX',
    template: '<lucky-x-ui></lucky-x-ui>'
  });

  $stateProvider.state('app.results.luckyx', {
    url: '/luckyx?id',
    templateUrl: resultsTemplateUrl,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.luckyx.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentTemplateUrl,
        controller: 'LuckyXResultsController'
      }
    }
  });
}).run(function (
  LuckyXUiService,
  $rootScope
) {
  $rootScope.$on('LuckyX:ConfigLoaded', function () {
    LuckyXUiService.boot();
  });

  $rootScope.$on('7T:Slave.Shown', function (e, data) {
    if (data.productId === 'LuckyX') {
      $rootScope.$broadcast('7T:Betslip.ActivateTicket', {
        data: {
          betslipId: data.productId,
          ticketId: 'single'
        }
      });
    }
  });
});
