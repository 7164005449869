<script setup lang="ts">
import {
  computed, onMounted, onUnmounted, reactive, ref, watch,
} from 'vue';
import { dateTimeFilter } from '@/common/filters';
import {
  SCInput,
  SCButton,
  SCInlineNotification,
  NotificationVariant,
  ButtonVariant,
  SCKeyboard,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import TModal from '@/common/components/TModal.vue';
import { useSevenStore } from '@/modules/seven';
import { useIntelligentGamingStore } from '@/modules/intelligent-gaming/store';
import { savePlayerData } from '@/modules/intelligent-gaming/reports/apiService';
import { PlayerData } from '@/modules/intelligent-gaming/reports/PlayerData';
import { axiosErrorParser } from '@/common/services/error-parser';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { CLOSE_TICKET_CHECK_MODALS_ID, TicketCheckAbortedError, closeTicketCheckModalsEventPubSub } from '@/modules/ticket-check';

const intelligentGamingStore = useIntelligentGamingStore();
const notificationsStore = useNotificationsStore();

const { t } = i18n.global;
const fullName = ref<string>('');
const idNumber = ref<string>('');
const contact = ref<string>('');
const address = ref<string>('');

const isSubmitted = ref<boolean>(false);
const isAlertVisible = ref<boolean>(false);

const showKeyboard = reactive({
  fullName: false,
  idNumber: false,
  contact: false,
  address: false,
});

const fullNameLabel = t('ig_player_form_full_name');
const idNumberLabel = t('ig_player_form_id_number');
const contactLabel = t('ig_player_form_contact');
const addressLabel = t('ig_player_form_address');

const fullNameHasError = computed(() => isSubmitted.value && !fullName.value);
const idNumberHasError = computed(() => isSubmitted.value && !idNumber.value);
const contactHasError = computed(() => isSubmitted.value && !contact.value);
const addressHasError = computed(() => isSubmitted.value && !address.value);

const fullNameErrorMessage = computed(
  () => (fullNameHasError.value
    ? t('main_field_is_required', { field: fullNameLabel })
    : ''
  ),
);

const idNumberErrorMessage = computed(
  () => (idNumberHasError.value
    ? t('main_field_is_required', { field: idNumberLabel })
    : ''
  ),
);

const contactErrorMessage = computed(
  () => (contactHasError.value
    ? t('main_field_is_required', { field: contactLabel })
    : ''
  ),
);

const addressErrorMessage = computed(
  () => (addressHasError.value
    ? t('main_field_is_required', { field: addressLabel })
    : ''
  ),
);
const formHasError = computed(() => [
  fullNameHasError.value,
  idNumberHasError.value,
  contactHasError.value,
  addressHasError.value,
].some((value) => value));

const resetForm = () => {
  fullName.value = '';
  idNumber.value = '';
  contact.value = '';
  address.value = '';
  isSubmitted.value = false;
};

const handleSubmit = () => {
  const sevenStore = useSevenStore();
  isSubmitted.value = true;
  isAlertVisible.value = formHasError.value;

  if (!formHasError.value) {
    const ticket = intelligentGamingStore.getTicket();

    const transactionType = intelligentGamingStore.getTransactionType();
    const payinAmount = ticket.getPayment();
    const payoutAmount = ticket.getPayoutAmountWithTax();
    const requestUuid = ticket.getRequestUuid();

    // Send it only on ticket payout
    const ticketId = ticket.getDisplayId();

    const payload: PlayerData = {
      address: address.value,
      contact: contact.value,
      date: dateTimeFilter(new Date(), 'YYYY-MM-DD'),
      fullName: fullName.value,
      idNumber: idNumber.value,
      terminalId: sevenStore.terminal.uuid,
      requestUuid,
      ticketId,
      time: dateTimeFilter(new Date(), 'HH:mm'),
      transactionAmount: transactionType === 'Bet' ? payinAmount : payoutAmount,
      transactionType,
    };

    if (transactionType === 'Bet') {
      // We don't have ticketId on ticket payin, so we don't need to send it
      // Also, on some products, ticket id is equal to ticket type (combo, single...)
      delete payload.ticketId;
    }

    savePlayerData(payload).then(() => {
      intelligentGamingStore.resolveCallback(true);
      intelligentGamingStore.setIsModalShown(false);
      resetForm();
    }).catch((err) => {
      notificationsStore.show({
        message: axiosErrorParser.parseMessage(err),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });

      logService.error('[IntelligentGaming] Fail to save player data.', {
        ...payload,
        ...axiosErrorParser.parseUpstream(err),
        code: 'T_IG_FAIL_TO_SAVE_PLAYER_DATA',
      });
    });
  }
};

const handleUpdateModelValue = (): void => {
  const transactionType = intelligentGamingStore.getTransactionType();

  intelligentGamingStore.rejectCallback({
    message: t('ig_modal_closed_notification'),
    code: transactionType === 'Bet' ? 'IG_PAYIN_CANCELED' : 'IG_PAYOUT_CANCELED',
  });
  intelligentGamingStore.setIsModalShown(false);
  resetForm();
};

watch(formHasError, (value) => {
  isAlertVisible.value = value;
});

let closeModalsSubscriptionId = '';

const errorMessage = [t('ig_player_form_error_message')];

onMounted(() => {
  closeModalsSubscriptionId = closeTicketCheckModalsEventPubSub
    .subscribe(CLOSE_TICKET_CHECK_MODALS_ID, (msg, data) => {
      if (intelligentGamingStore.isModalShown) {
        const code = 'T_IG_PLAYER_INFORMATION_MODAL_CLOSE_MODAL_EVENT';
        const error = new TicketCheckAbortedError('Closing PlayerInformationModal modal due to closeTicketCheckModalsEventPubSub', code);

        logService.warn('[PlayerInformationModal] Closing modal due to closeTicketCheckModalsEventPubSub', {
          code,
          upstream_message: data?.reason,
        });

        intelligentGamingStore.rejectCallback(error);
        intelligentGamingStore.setIsModalShown(false);
        resetForm();
      }
    });
});

onUnmounted(() => {
  if (closeModalsSubscriptionId) {
    closeTicketCheckModalsEventPubSub.unsubscribe(closeModalsSubscriptionId);
  }
});

</script>
<template>
  <TModal
    v-model="intelligentGamingStore.isModalShown"
    modal-id="player-information-modal"
    with-close-icon
    @update:model-value="handleUpdateModelValue"
  >
    <template #title>
      {{ t("ig_player_modal_title") }}
    </template>
    <template #content>
      <div class="w-100 d-flex flex-column align-items-start sc-pt-9">
        <SCKeyboard
          v-model="fullName"
          v-model:show="showKeyboard.fullName"
          class="w-100 sc-p-0"
        >
          <SCInput
            v-model="fullName"
            block
            :label="fullNameLabel"
            :error="fullNameHasError"
            :error-message="fullNameErrorMessage"
          />
        </SCKeyboard>

        <SCKeyboard
          v-model="idNumber"
          v-model:show="showKeyboard.idNumber"
          class="w-100 sc-p-0"
        >
          <SCInput
            v-model="idNumber"
            block
            :label="idNumberLabel"
            :error="idNumberHasError"
            :error-message="idNumberErrorMessage"
          />
        </SCKeyboard>

        <SCKeyboard
          v-model="contact"
          v-model:show="showKeyboard.contact"
          class="w-100 sc-p-0"
        >
          <SCInput
            v-model="contact"
            block
            :label="contactLabel"
            :error="contactHasError"
            :error-message="contactErrorMessage"
          />
        </SCKeyboard>

        <SCKeyboard
          v-model="address"
          v-model:show="showKeyboard.address"
          class="w-100 sc-p-0"
        >
          <SCInput
            v-model="address"
            block
            :label="addressLabel"
            :error="addressHasError"
            :error-message="addressErrorMessage"
          />
        </SCKeyboard>

        <div class="sc-mb-7 w-100">
          <SCInlineNotification
            v-model="isAlertVisible"
            :messages="errorMessage"
            :variant="NotificationVariant.Error"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <SCButton
        :variant="ButtonVariant.Primary"
        width="100%"
        block
        @click="handleSubmit"
      >
        {{ t("ig_player_form_submit") }}
      </SCButton>
    </template>
  </TModal>
</template>
<style lang="scss">
p.sc-alert__text {
  // Bootstrap adds padding to paragraphs
  margin-bottom: 0;
}
</style>
