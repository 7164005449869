import { getService } from '@/common/services/ngBridge';
import {
  integratorService,
  slaveShownEventPubSub,
  SLAVE_SHOWN_EVENT_ID,
  TicketCheckSlaveShownData,
} from '@/modules/integrator';
import TicketCheckStrategyParams from '../types/TicketCheckStrategyParams';

const checkTicketCode = (params: TicketCheckStrategyParams) => {
  const slaveShownSubscription = slaveShownEventPubSub
    .subscribe(SLAVE_SHOWN_EVENT_ID, (e, data) => {
      const eventData = data as TicketCheckSlaveShownData;
      if (eventData?.productId === params.productId) {
        integratorService.sendEvent(
          'Ticket.CheckWebCode',
          {
            data: {
              code: params.code,
            },
            context: {
              productId: params.productId,
            },
          },
        );
        slaveShownEventPubSub.unsubscribe(slaveShownSubscription);
      }
    });

  const switchView: any = getService('switchView');

  return switchView.selectService(params.productId, true, {
    gameId: params.productId,
    genericId: params.productId,
  }).then(() => Promise.resolve(undefined));
};

export default {
  checkTicketCode,
};
