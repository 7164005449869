import { integratorService } from '@/modules/integrator';
import { useUserFundsStore } from '../userFundsStore';

// Constants
const USER_BALANCE_CHANGE_EVENT = 'User.BalanceChanged';

const emitBalanceChangeEvent = () => {
  const { balance } = useUserFundsStore();

  integratorService.sendEvent(USER_BALANCE_CHANGE_EVENT, {
    context: {
      productId: '*',
    },
    data: {
      balance,
    },
    config: {
      enforceEvent: true,
    },
  });
};

export {
  emitBalanceChangeEvent,
  USER_BALANCE_CHANGE_EVENT,
};
