import Dexie from 'dexie';

/**
 *
 * @class TicketActionStore
 * @constructor
 * @ngInject
 */
function TicketActionStore(
  $q,
  $filter,
  $log,
  errorParser
) {
  // create db
  // eslint-disable-next-line no-undef
  var db = new Dexie('SevenTicketActionStore');
  db.version(1).stores(
    {
      ticketAdd: '&requestUuid, localStatus, pendingStartedAt',
      ticketCancel: '++clientId, localStatus, id, pendingStartedAt',
      ticketPayout: '++clientId, localStatus, id, pendingStartedAt'
    }
  );

  return {

    /**
           * Add ticket to db
           *
           * @memberof TicketActionStore
           * @param ticket
           * @returns {Promise}
           */
    add: function (ticket) {
      return db[ticket.store].add(ticket.data);
    },

    /**
           * Update ticket with new data
           *
           * @memberof TicketActionStore
           * @param ticket
           * @returns {*}
           */
    update: function (ticket) {
      var deferred = $q.defer();
      var findBy = ticket.findBy === 'id' ? 'clientId' : ticket.findBy;

      $log.debug('[SevenTicketCenter] TicketStore update', {
        request_id: ticket.data && ticket.data.requestUuid,
        ticket_code: ticket.data && ticket.data.id,
        product_displayid: ticket.data && ticket.data.product,
        ticket: ticket
      });

      this.findByProp(ticket).then(
        function (response) {
          $log.debug('[SevenTicketCenter] TicketStore update - trying to update data...', {
            request_id: response && response.data && response.data.length && response.data[0].requestUuid,
            ticket_code: response && response.data && response.data.length && response.data[0].id,
            product_displayid: ticket.data && ticket.data.product,
            data: response.data
          });
          // modifying primary key is forbidden,
          // see https://stackoverflow.com/questions/22727346/update-single-column-of-indexeddb-records-using-id-key?rq=1
          if (findBy === 'clientId') {
            delete ticket.data.clientId;
          }

          db[ticket.store].update(
            response.data[0][findBy],
            ticket.data
          ).then(function (updated) {
            if (updated) {
              deferred.resolve();
            } else {
              $log.error('[SevenTicketCenter] TicketStore update failed', {
                request_id: ticket.data && ticket.data.requestUuid,
                ticket_code: ticket.data && ticket.data.id,
                product_displayid: ticket.data && ticket.data.product,
                ticket: ticket.data,
                code: 'T_TICKET_STORE_UPDATE_FAILED'
              });
              deferred.reject();
            }
          }).catch(function (err) {
            $log.error('[SevenTicketCenter] TicketStore update failed', {
              request_id: ticket.data && ticket.data.requestUuid,
              ticket_code: ticket.data && ticket.data.id,
              product_displayid: ticket.data && ticket.data.product,
              ticket: ticket.data,
              code: 'T_TICKET_STORE_UPDATE_FAILED'
            });
            deferred.reject(err);
          });
        },
        function (err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    },

    /**
           * Remove ticket from db
           *
           * @memberof TicketActionStore
           * @param {Object} ticket
           * @param {String} ticket.store - Store name
           * @param {String} ticket.findBy - Name of property to search
           * @returns {*}
           */
    remove: function (ticket) {
      var deferred = $q.defer();
      var findBy = ticket.findBy === 'id' ? 'clientId' : ticket.findBy;

      $log.debug('[SevenTicketCenter] TicketStore remove', {
        request_id: ticket.data && ticket.data.requestUuid,
        ticket_code: ticket.data && ticket.data.id,
        product_displayid: ticket.data && ticket.data.product
      });

      this.findByProp(ticket).then(
        function (response) {
          if (response.data.length) {
            db[ticket.store].delete(response.data[0][findBy]).then(
              function () {
                deferred.resolve();
              }
            );
          } else {
            $log.error('[SevenTicketCenter] TicketStore remove failed', {
              request_id: ticket.data && ticket.data.requestUuid,
              ticket_code: ticket.data && ticket.data.id,
              product_displayid: ticket.data && ticket.data.product,
              code: 'T_TICKET_STORE_REMOVE_FAILED'
            });
            deferred.reject();
          }
        },
        function () {
          deferred.reject();
        }
      );

      return deferred.promise;
    },

    /**
           * Find ticket by property
           *
           * @memberof TicketActionStore
           * @param {Object} ticket
           * @param {String} ticket.store - Store name
           * @param {String} ticket.findBy - Name of property to search
           * @param filterParams - object literal indicating which property to use for filter
           * @returns {*}
           */
    findByProp: function (ticket, filterParams) {
      var deferred = $q.defer();
      var table = db[ticket.store];

      if (table) {
        table.where(ticket.findBy)
          .equals(ticket[ticket.findBy])
          .toArray()
          .then(
            function (response) {
              var result = response;
              if (filterParams) {
                result = $filter('orCondition')(response, filterParams.params);
              }

              deferred.resolve({ value: true, data: result });
            },
            function () {
              $log.error('[SevenTicketCenter] TicketStore couldn`t find ticket in store (findByProp)', {
                request_id: ticket.data && ticket.data.requestUuid,
                ticket_code: ticket.data && ticket.data.id,
                product_displayid: ticket.data && ticket.data.product,
                ticket: ticket.data,
                code: 'T_FIND_TICKET_IN_STORE_FAILED'
              });
              deferred.reject({ value: false, data: [] });
            }
          );
      } else {
        $log.error('[SevenTicketCenter] TicketStore couldn`t find ticket in store (findByProp)', {
          request_id: ticket.data && ticket.data.requestUuid,
          ticket_code: ticket.data && ticket.data.id,
          product_displayid: ticket.data && ticket.data.product,
          ticket: ticket.data,
          code: 'T_FIND_TICKET_IN_STORE_FAILED'
        });
        deferred.reject({ value: false, data: [] });
      }

      return deferred.promise;
    },

    /**
           * Get all db tables entries and concat to one array
           *
           * @memberof TicketActionStore
           * @param store
           * @returns {*}
           */
    getAll: function (store) {
      var deferred = $q.defer();
      var collection = [];
      var collectionsAdded = 0;

      if (store === 'all') {
        db.tables.forEach(function (table) {
          table.toArray().then(function (data) {
            collection = collection.concat(data);
            collectionsAdded += 1;

            if (collectionsAdded === db.tables.length) {
              deferred.resolve(collection);
            }
          });
        });
      } else {
        db[store].toArray().then(function (data) {
          deferred.resolve(data);
        });
      }

      return deferred.promise;
    },

    /**
           * Get all tickets from store with passed status
           *
           * @memberof TicketActionStore
           * @param {String} store
           * @param {String} status
           * @return {Collection<T, Key>}
           */
    getWithStatus: function (store, status) {
      return db[store].where('localStatus')
        .equals(status);
    },

    /**
           * Clear store
           *
           * @memberof TicketActionStore
           * @return {*|boolean|void|Promise<void>}
           */
    removeAll: function () {
      db.tables.forEach(function (table) {
        table.clear().catch(function (err) {
          $log.warn('[SevenTicketCenter] Removing ticket from ticket store failed on shift conclude.', {
            ...errorParser.parseUpstream(err),
            code: 'T_REMOVE_ALL_FROM_STORE_FAILED'
          });
        });
      });
    },

    getDb: function () {
      return db;
    }
  };
}

export default TicketActionStore;
