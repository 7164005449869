/* eslint-disable no-param-reassign */
import { Ref } from 'vue';
import { logService } from '@/common/services/logger';

type Value = {
  imgSrc: string,
  errImgSrc: string,
};
type Binding = Ref<Value>;

const setImage = (img: HTMLImageElement, elem: HTMLElement) => {
  elem.style.minWidth = `${img.width}px`;
  elem.style.backgroundImage = `url(${img.src})`;
  elem.style.backgroundRepeat = 'no-repeat';
  elem.style.backgroundPosition = 'center';
};

export const vLoadImage = {
  beforeMount: (elem: HTMLElement, binding: Binding) => {
    const loadImg = (imgUrl: string): Promise<boolean> => {
      const img = new Image();
      return new Promise((resolve, reject) => {
        img.src = imgUrl;

        img.onload = () => {
          setImage(img, elem);
          resolve(true);
        };

        img.onerror = () => {
          reject();
          logService.debug(`[LoadImage] can't find or load image: ${imgUrl}`, {});
        };
      });
    };

    loadImg(binding.value.imgSrc).catch(() => {
      loadImg(binding.value.errImgSrc)
        .catch(() => {
          logService.debug('[LoadImage] Couldn\'t load image', {});
        });
    });
  },
};
