import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sc-overlay-default h-100 sc-px-3 sc-py-2 text-right t-header-balance-mw-fc" }
const _hoisted_2 = { class: "sc-text-body-2" }
const _hoisted_3 = { class: "sc-text-h6" }

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import i18n from '@/plugins/i18n';
import { sevenCurrency } from '@/common/filters';
import { usePaymentsStore } from '@/modules/payments';
import { useUserFundsStore } from '@/modules/user-funds';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderBalance',
  setup(__props) {

const { t } = i18n.global;
const paymentsStore = usePaymentsStore();

const { balance } = storeToRefs(useUserFundsStore());
const balanceTitle = computed(() => {
  if (paymentsStore.paymentSettings.methodName) {
    return paymentsStore.paymentSettings.headerBalanceSubtitle;
  }
  return t('main.cash_balance');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(balanceTitle.value), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(sevenCurrency)(_unref(balance))), 1 /* TEXT */)
  ]))
}
}

})