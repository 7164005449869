import angular from 'angular';
import commonModule from '../../common';
import gravityModule from '../gravity';
import modulesModule from '../index';
import integratorModule from '../integrator';
import PerformanceMonitorService from './performanceMonitor';

const moduleName = angular.module('7Terminal.MemoryOptimizator', [
  'pascalprecht.translate',
  'SevenClientLib',
  'SevenClientCore',
  'SevenClient.Config',
  commonModule,
  gravityModule,
  modulesModule,
  integratorModule
])
  .service('PerformanceMonitor', PerformanceMonitorService)
  .run(function (
    PerformanceMonitor
  ) {
    PerformanceMonitor.init();
  }).name;

export default moduleName;
