import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import PubSub from 'pubsub-js';
import i18n from '@/plugins/i18n';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { type AcceptorHookParam, acceptorsService, AcceptorAction } from '@/modules/acceptors';
import HooksManager from '@/common/services/HooksManager';
import { logService } from '@/common/services/logger';
import { googleAnalyticsService } from '@/modules/google-analytics';
import { useIntelligentGamingStore } from '@/modules/intelligent-gaming';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';

const { t } = i18n.global;

const isServiceModeActive = (): boolean => !!JSON.parse(localStorage.getItem('settings.serviceModeActive') || 'false');

const registerHooks = (): void => {
  HooksManager.getHook('BeforeAcceptorsStart').tap({
    name: 'BeforeAcceptorsStart.ServiceMode',
    fn: (params: AcceptorHookParam) => {
      if (isServiceModeActive()) {
        params.errorResponses.push({
          id: 'acceptors-start-error-service-mode',
          message: t('acceptors_start_error_service_mode_active'),
          code: 'T_BEFORE_ACCEPTORS_START_SERVICE_MODE_ACTIVE',
        });
      }
    },
  });
};

const setServiceMode = (isActive: boolean) => {
  if (isActive) {
    localStorage.setItem('settings.serviceModeActive', 'true');
    googleAnalyticsService.trackEvent('Terminal_Backoffice', {
      event: 'Service Mode on',
    });
  } else {
    localStorage.removeItem('settings.serviceModeActive');
  }
};

const handleServiceModeActiveState = () => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.show({
    id: 'login-notification',
    message: t('settings.service_active_warning'),
    type: TNotificationTypeEnum.error,
    closeDisabled: true,
    delay: null,
  });
  PubSub.publish('7T:Login.ShowLoginModal', {
    preventClose: true,
  });

  acceptorsService.onInitializationFinished(AcceptorAction.STOP).then(() => {
    logService.info('[serviceModeHandler] Stopping acceptors...', {
      code: 'T_DCD_STOP_ACCEPTORS_REQUEST',
      upstream_code: 'ServiceMode',
      upstream_message: 'STOP is called on acceptors initialization finished',
    });
    acceptorsService.stopAcceptors().catch(() => {});
  }).catch(() => {
    logService.error('[serviceModeHandler] Acceptors initialization has failed.');
  });
};

const addWatcherForProvinceModal = () => {
  const { isProvinceModalShown } = storeToRefs(useIntelligentGamingStore());

  watch(isProvinceModalShown, (newIsProvinceModalShown: boolean) => {
    if (!newIsProvinceModalShown && isServiceModeActive()) {
      handleServiceModeActiveState();
    }
  }, { immediate: true });
};

const init = () => {
  registerHooks();

  const settingsStore = useGravitySettingsStore();
  const igEnabled = settingsStore.getByKey('modules')?.intelligent_gaming?.enabled;

  if (igEnabled) {
    addWatcherForProvinceModal();
    return;
  }

  if (isServiceModeActive()) {
    handleServiceModeActiveState();
  }
};

export {
  init,
  isServiceModeActive,
  setServiceMode,
};
