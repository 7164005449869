import { findWhere, property } from 'underscore';
import i18n from '@/plugins/i18n';
import { useGravityMenusStore } from '@/modules/cms/menus';
import { CmsMenuItem } from '@/modules/cms/menus/types';
import { useClientGamesStore } from '@/modules/client-games';
import { useAppModulesStore } from '@/modules/anonymous-app';
import type { MenuItem } from './types';
import { logService } from '../logger';

const { t } = i18n.global;

const getMainMenuList = (): MenuItem[] => {
  const gravityMenusStore = useGravityMenusStore();
  const appModulesStore = useAppModulesStore();
  const gamesStore = useClientGamesStore();
  const menuItems: CmsMenuItem[] = [...gravityMenusStore.getMenu('home_products')];
  const moduleList = appModulesStore.getGeneralModules();
  const games = gamesStore.getGames();
  const parsedMenuItems: MenuItem[] = [];
  let menuItem: MenuItem;

  menuItems.forEach((item: CmsMenuItem) => {
    const contextGame = findWhere(games, { id: item.url });
    const contextGeneralModule = findWhere(moduleList, { id: item.url });
    const context = contextGame || contextGeneralModule;
    const id = property(['context', 'id'])(item) || item.url;
    menuItem = {
      item,
      context,
      route: property(['id'])(context) || property(['customData', 'state', 'to'])(item)
        || 'games',
      params: {
        gameId: id, genericId: id,
      },
      translation: (() => {
        if (item.customData?.translationKey) {
          return t(`${item.customData.translationKey}`);
        }

        // why includes('_')? This key comes from backend. if it contains _ it meas that
        // it is not yet transled in Phrase app (e.g. product_module_live_betting)
        if (contextGame?.info.translation && !contextGame.info.translation.includes('_')) {
          return contextGame.info.translation;
        }

        if (contextGeneralModule?.translation) {
          return contextGeneralModule.translation;
        }

        return item.title;
      })(),
      type: property(['customData', 'type'])(item) || (contextGame ? 'product' : 'general'),
    };

    // add item to result if it is item from seven (product or general) and that product is active
    // on current device or if this is some generic item and it is not product
    // (does not have type defined at all)
    const menuItemType = menuItem.item.customData?.type || '';
    if ((context && ['product', 'general'].indexOf(menuItemType) >= 0)
      || (menuItem.item.customData?.type === undefined && menuItem.item.customData?.type !== 'product')) {
      parsedMenuItems.push(menuItem);
    }
  });

  return parsedMenuItems;
};

const getHeaderMenuList = (): MenuItem[] => {
  const gravityMenusStore = useGravityMenusStore();
  const gamesStore = useClientGamesStore();
  const appModulesStore = useAppModulesStore();
  const menuItems: CmsMenuItem[] = [...gravityMenusStore.getMenu('header')];
  const moduleList = appModulesStore.getGeneralModules();
  const games = gamesStore.getGames();
  const parsedMenuItems: MenuItem[] = [];
  let menuItem: MenuItem;

  menuItems.forEach((item: CmsMenuItem) => {
    const id = property(['context', 'id'])(item) || item.url;
    const contextGame = findWhere(games, { id: item.url });
    const contextGeneralModule = findWhere(moduleList, { id: item.url });
    const context = contextGame || contextGeneralModule;

    menuItem = {
      item,
      context,
      route: property(['id'])(context) || property(['customData', 'state', 'to'])(item)
        || 'games',
      params: {
        gameId: id, genericId: id,
      },
      translation: (() => {
        if (item.customData?.translationKey) {
          return t(`${item.customData.translationKey}`);
        }

        // why includes('_')? This key comes from backend. if it contains _ it meas that
        // it is not yet transled in Phrase app (e.g. product_module_live_betting)
        if (contextGame?.info.translation && !contextGame.info.translation.includes('_')) {
          return contextGame.info.translation;
        }

        if (contextGeneralModule?.translation) {
          return contextGeneralModule.translation;
        }

        return item.title;
      })(),
      type: property(['customData', 'type'])(item) || (contextGame ? 'product' : 'general'),
    };

    if (menuItem.context || item.customData?.type === 'group') {
      parsedMenuItems.push(menuItem);
    }
  });

  return parsedMenuItems;
};

const getSideMenuList = (sideMenuName: string): MenuItem[] | undefined => {
  const gravityMenusStore = useGravityMenusStore();
  const gamesStore = useClientGamesStore();
  const menuItems: CmsMenuItem[] = gravityMenusStore.getMenu(sideMenuName);
  const games = gamesStore.getGames();
  const parsedMenuItems: MenuItem[] = [];

  if (!menuItems) {
    logService.warn('[menuService] Failed to the list of side-menu items', {
      code: 'T_GET_SIDE_MENU_ITEMS_FAILED',
    });

    return undefined;
  }

  menuItems.forEach((item: CmsMenuItem) => {
    const game = findWhere(games, { id: item.url });
    if (game) {
      const menuItem = {
        context: game,
        item,
        translation: (
          (game && game.info.translation) || t(`${item.customData?.translationKey}`)
        ),
      };
      parsedMenuItems.push(menuItem);
    }
  });

  return parsedMenuItems;
};

export {
  getMainMenuList,
  getHeaderMenuList,
  getSideMenuList,
};
