import {
  ProductTicketResponse,
  TicketResponse,
  betslipApiHelpers,
  TicketEventErrorResponse,
  ClientTicketActions,
} from '@/modules/seven-betslip-api';
import { logService } from '@/common/services/logger';
import { jsErrorParser } from '@/common/services/error-parser';
import HooksManager from '@/common/services/HooksManager';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { useTicketCheckStore } from '../ticket-check';
import { TicketResolveResponse } from './types/TicketResolveResponse';
import { TicketActionResponse } from './types/TicketActionResponse';
import { resolveTicket } from './ticketResolveService';
import TicketResolveError from './errors/TicketResolveError';
import { useTicketsStore } from './ticketsStore';
import { handlePayoutTicketPrint } from './helpers';
import { ticketActionSuccessEventPubSub } from './events/ticketActionSuccessEvent';
import { TicketActionSuccessEvent } from './enum';

const LOG_PREFIX = '[ticketActionsResponseHandler]';

const handleBeforeActionNotificationShow = (
  response: TicketResolveResponse,
  actionResponse: TicketActionResponse,
) => {
  const { action, showMessage } = actionResponse;

  if (showMessage !== false) {
    const params = {
      action,
      errorResponses: [],
    };
    HooksManager.BeforeTicketActionNotificationShow.promise(params).then(() => {
      if (!params.errorResponses.length) {
        const notificationsStore = useNotificationsStore();
        notificationsStore.show({
          message: response.reason,
          type: TNotificationTypeEnum.success,
          delay: 3000,
        });
      } else {
        logService.debug(`${LOG_PREFIX} Skipping showing notification - BeforeTicketActionNotificationShow hook`, {
          errorResponses: params.errorResponses.join(),
          code: 'T_SKIP_SHOW_TICKET_ACTION_NOTIFICATION',
        });
      }
    }).catch((error) => {
      logService.debug(`${LOG_PREFIX} Skipping showing notification - BeforeTicketActionNotificationShow hook`, {
        ...error,
        code: 'T_SKIP_SHOW_TICKET_ACTION_NOTIFICATION',
      });
    });
  }
};

const handleTicketResolveSuccess = (
  response: TicketResolveResponse,
  actionResponse: TicketActionResponse,
) => {
  const { action, ticketData: serverResponse } = actionResponse;

  handleBeforeActionNotificationShow(response, actionResponse);

  const ticketCheckStore = useTicketCheckStore();
  ticketCheckStore.setResult(response.localTicket);

  if (action === ClientTicketActions.Payout) {
    handlePayoutTicketPrint(
      response.localTicket,
      serverResponse as (TicketResponse | ProductTicketResponse),
    );
    ticketActionSuccessEventPubSub.publish(TicketActionSuccessEvent.PayoutSuccess, {
      localTicket: response.localTicket,
    });
  } else if (action === ClientTicketActions.Cancel) {
    ticketActionSuccessEventPubSub.publish(TicketActionSuccessEvent.CancelSuccess, {
      localTicket: response.localTicket,
    });
  }
};

const handleTicketActionError = (
  errorResponse: TicketEventErrorResponse | TicketResolveError | Error,
  errorMsg: string,
  productId: string,
  showMessage?: boolean,
) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('ticket.pending_action');

  if (!errorResponse) return;

  if (showMessage === undefined || showMessage !== false) {
    notificationsStore.show({
      message: errorMsg,
      type: TNotificationTypeEnum.warning,
      delay: 3000,
      id: 'ticket-error',
    });
  }
};

const isErrorResponse = (
  actionResponse: TicketResponse | ProductTicketResponse | TicketEventErrorResponse,
): actionResponse is TicketEventErrorResponse => !!(actionResponse as TicketEventErrorResponse)
  .httpCode;

const handleTicketActionResponse = (
  actionResponse: TicketActionResponse,
) => {
  const ticketStore = useTicketsStore();
  const { action, showMessage } = actionResponse;

  if (isErrorResponse(actionResponse.ticketData)) {
    const errorResponse = actionResponse.ticketData;
    const errorMessage = betslipApiHelpers.getSevenBetslipErrorMessage(errorResponse).join();
    handleTicketActionError(
      errorResponse,
      errorMessage,
      errorResponse.product,
      showMessage,
    );
    logService.error(
      `${LOG_PREFIX} Failed to resolve ticket.`,
      {
        response: errorResponse,
        upstream_message: errorMessage,
        action: actionResponse.action,
        code: 'T_TICKET_RESOLVE_ERROR',
      },
    );

    const ticketsStore = useTicketsStore();
    const ticketFromStore = ticketsStore.getTicket(
      errorResponse.requestUuid,
      errorResponse.id,
      actionResponse.action,
    );
    if (ticketFromStore) {
      ticketStore.updateTicketActionAsFailed(ticketFromStore);
    } else {
      logService.warn(
        `${LOG_PREFIX} Could not update ticket as failed - cannot find ticket in store.`,
        {
          response: errorResponse,
          upstream_message: errorMessage,
          action: actionResponse.action,
          code: 'T_TICKET_RESOLVE_TICKET_AS_FAILED_ERROR',
        },
      );
    }
    return;
  }

  const { ticketData } = actionResponse;
  const ticketInstance = betslipApiHelpers.createTicket(ticketData);
  const productId = ticketInstance.getProductDisplayId();
  const requestUuid = ticketInstance.getRequestUuid();
  const ticketId = ticketInstance.getDisplayId();
  let resolveResponse: TicketResolveResponse;

  try {
    resolveResponse = resolveTicket(
      ticketData,
      action,
    );

    logService.info(`${LOG_PREFIX} Ticket successfully resolved.`, {
      code: 'T_TICKET_RESOLVE_SUCCESS',
      request_id: requestUuid,
      ticket_code: ticketId,
      ticket: betslipApiHelpers.createLogDataFromTicket(resolveResponse.localTicket),
      product_displayid: productId,
    });
    handleTicketResolveSuccess(resolveResponse, actionResponse);
  } catch (error) {
    const errorDetails = jsErrorParser.parse(error);

    handleTicketActionError(
      error as TicketResolveError | Error,
      errorDetails.message,
      productId,
      showMessage,
    );
    logService.error(`${LOG_PREFIX} Failed to resolve ticket.`, {
      request_id: requestUuid,
      ticket_code: ticketId,
      product_displayid: productId,
      response: ticketData,
      upstream_message: errorDetails.message,
      upstream_code: errorDetails.code,
      action,
      code: 'T_TICKET_RESOLVE_ERROR',
    });
  }
};

export {
  resolveTicket,
  handleTicketActionResponse,
};
