import { v4 as uuidV4 } from 'uuid';
import { SevenApiRoutes } from '@/modules/seven';
import { type VoucherResponse } from '@/modules/vouchers';
import type {
  TicketResponse,
  ProductTicketResponse,
  CashoutParams,
  CashoutResponse,
  TboCashoutResponse,
} from './types';
import type TicketActionBetslipResponse from './interfaces/TicketActionBetslipResponse';
import type CashoutPayload from './interfaces/CashoutPayload';
import type PayoutPayload from './interfaces/PayoutPayload';
import type TicketActionPayload from './interfaces/TicketActionPayload';
import axiosInstance from './axiosInstance';

const AXIOS_TIMEOUT = 10000;

export type BetslipApiResponse =
  TicketResponse | ProductTicketResponse | VoucherResponse;

const checkTicketByAnonymousPlayer = (id: string) => axiosInstance
  .get<BetslipApiResponse>(
  `${SevenApiRoutes.routes.mainUrl}/api/terminal/tickets/check/${id.toUpperCase()}.json`,
  {
    timeout: AXIOS_TIMEOUT,
  },
);

const checkTicketByOperator = (id: string) => axiosInstance
  .get<BetslipApiResponse>(`${SevenApiRoutes.routes.tboMainUrl}tickets/${id.toUpperCase()}.json`, {
  timeout: AXIOS_TIMEOUT,
});

const payoutTicketByOperator = (payload: PayoutPayload) => axiosInstance
  .put<TicketActionBetslipResponse>(
  `${SevenApiRoutes.routes.tboMainUrl}tickets.json?requestUuid=${payload.metadata.requestUuid}`,
  payload,
);

const payoutTicket = (payload: PayoutPayload) => axiosInstance.put<TicketActionBetslipResponse>(
  `${SevenApiRoutes.routes.mainUrl}/api/terminal/tickets/payout.json?requestUuid=${payload.metadata.requestUuid}`,
  payload,
);

const cancelTicket = (data: TicketActionPayload) => axiosInstance
  .delete<TicketActionBetslipResponse>(
  `${SevenApiRoutes.routes.mainUrl}/api/terminal/tickets/cancel.json?requestUuid=${data.metadata.requestUuid}`,
  { data },
);

const cashoutTicketByOperator = (payload: CashoutPayload) => {
  const params: CashoutParams = {
    requestUuid: uuidV4(),
  };

  return axiosInstance.put<TboCashoutResponse>(
    `${SevenApiRoutes.routes.tboMainUrl}tickets/cashout.json`,
    payload,
    {
      params,
    },
  );
};

const cashoutTicket = (payload: CashoutPayload) => {
  const params: CashoutParams = {
    requestUuid: uuidV4(),
  };

  return axiosInstance.put<CashoutResponse>(
    `${SevenApiRoutes.routes.mainUrl}/api/terminal/tickets/cashout.json`,
    payload,
    {
      params,
    },
  );
};

const checkTicketPayInStatus = (requestuuid: string, productDisplayId: string) => axiosInstance
  .get<TicketResponse | ProductTicketResponse>(
  `${SevenApiRoutes.routes.mainUrl}/api/terminal/tickets/request/${requestuuid}/product/${productDisplayId}/check.json`,
  {
    timeout: AXIOS_TIMEOUT,
  },
);

export {
  checkTicketByAnonymousPlayer,
  checkTicketByOperator,
  payoutTicketByOperator,
  cashoutTicketByOperator,
  checkTicketPayInStatus,
  payoutTicket,
  cashoutTicket,
  cancelTicket,
};
