import axios from 'axios';
import * as httpInterceptor from '@/modules/interceptors/httpInterceptor';
import requestSuccessInterceptor from './interceptor';

const axiosInstance = axios.create({
  baseURL: process.env.INTELLIGENT_GAMING_PLA_REPORTS_URL,
});

const defaultErrorHandler = (error: any) => Promise.reject(error);

axiosInstance.interceptors.request.use(
  requestSuccessInterceptor,
  defaultErrorHandler,
);

axiosInstance.interceptors.request.use(
  httpInterceptor.requestSuccess,
  httpInterceptor.requestError,
);

export default axiosInstance;
