import { defineStore } from 'pinia';
import { ref } from 'vue';

const useUserFundsStore = defineStore('userFundsStore', () => {
  const balance = ref(0);

  const setBalance = (balanceAmount: number) => {
    balance.value = balanceAmount;
  };

  return {
    balance,
    setBalance,
  };
});

export {
  useUserFundsStore,
};
