import { generateIframeLoadData, getProductData } from '@/modules/gateway';
import { useClientGamesStore } from '@/modules/client-games';

(function () {
  angular.module('7Terminal.Results')
    .controller('ResultsIntegrationController', ResultsIntegrationController);

  function ResultsIntegrationController(
    $stateParams,
    $rootScope,
    Widgets,
  ) {
    const vm = this;
    const gamesStore = useClientGamesStore();

    function init() {
      vm.gameId = $stateParams.gameId;
      vm.iframeUrl = false;
      vm.WIDGET_SOURCE_TYPES = Widgets.WIDGET_SOURCE_TYPES;
      vm.resultsWidget = false;
      const game = gamesStore.getGameById(vm.gameId);
      vm.widgetLoadData = generateIframeLoadData(getProductData(game));
      loadResultsView();
    }

    function loadResultsView() {
      Widgets.getByType(['PRODUCT_RESULTS'], {
        value: vm.gameId
      }).then(function (widget) {
        vm.resultsWidget = widget;
      });
    }

    vm.onFinally = function (config) {
      // 7T:Slave.Shown needs to be @deprecated in favor of 7T:UI.Show
      $rootScope.$emit('7T:Slave.Shown', {
        productId: config.iframeId,
        route: {
          to: 'results'
        }
      });
      $rootScope.$emit('7T:UI.Show', {
        productId: config.iframeId,
        name: ['Results']
      });
    };

    init();
  }
})();
