import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center sc-text-caption-2 sc-text-primary-2 h-100" }
const _hoisted_2 = { key: 0 }

import { useSevenStore } from '@/modules/seven';
import { useENVStore } from '@/common/stores/env';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminalInfo',
  setup(__props) {

const sevenStore = useSevenStore();
const envStore = useENVStore();
const gravitySettingsStore = useGravitySettingsStore();

const terminalName = sevenStore.device.name;
const betshopName = sevenStore.betshop.name;
const versions = gravitySettingsStore.getByKey('versions');
const showBuildVersion = gravitySettingsStore.getModuleDataKeyValue('config', 'showBuildVersion');
const clientVersion = (versions && versions[sevenStore.tenant.uuid]) || envStore
  .data.client_version;
const buildVersion = envStore.data.build;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fontBold" }, "V:", -1 /* HOISTED */)),
      _createTextVNode(" " + _toDisplayString(_unref(clientVersion)) + " ", 1 /* TEXT */),
      (_unref(showBuildVersion))
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(B: " + _toDisplayString(_unref(buildVersion)) + ")", 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", null, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fontBold" }, "BID", -1 /* HOISTED */)),
      _createTextVNode(": " + _toDisplayString(_unref(betshopName)), 1 /* TEXT */)
    ]),
    _createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fontBold" }, "TID", -1 /* HOISTED */)),
      _createTextVNode(": " + _toDisplayString(_unref(terminalName)), 1 /* TEXT */)
    ])
  ]))
}
}

})