import HooksManager from '@/common/services/HooksManager';
import type { VoucherPayoutPayload } from '../types';

const validatePayout = (
  voucherData: VoucherPayoutPayload,
): Promise<void> => HooksManager.BeforeVoucherPayout.promise(voucherData);

export default {
  validatePayout,
};
