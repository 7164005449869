import i18n from '@/plugins/i18n';
import { MenuItem } from '@/common/services/menu';

const { t } = i18n.global;

const getProductCardLabel = (product: MenuItem) => {
  const defaultProductCardLabelTransKey = 'thumbnail_label';

  // display a translated label message set in CMS menu for products
  // otherwise display a default string regardless of what language is active
  return product.item.customData?.label?.value === defaultProductCardLabelTransKey
    ? t(defaultProductCardLabelTransKey)
    : product.item.customData?.label?.value;
};
export {
  getProductCardLabel,
};
