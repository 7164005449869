import i18n from '@/plugins/i18n';
import { cardReaderService } from '@/modules/card-reader';
import { useTboModulesStore } from '@/modules/tbo';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';

const { t } = i18n.global;

const handleTboCardReadEvent = () => {
  const notificationsStore = useNotificationsStore();
  const tboModulesStore = useTboModulesStore();
  const passCardsModuleEnabled = tboModulesStore.getModule('cardOperations')
    ?.submodules?.find((subModule) => subModule.id === 'passCards');

  if (!passCardsModuleEnabled) {
    notificationsStore.show({
      message: t('notifications.invalid_card_data'),
      type: TNotificationTypeEnum.info,
      id: 'invalid_card_data',
      delay: null,
      actions: [{
        callback() {
          cardReaderService.formatCard().then(() => {
            notificationsStore.show({
              message: t('notifications.card_format_success'),
              type: TNotificationTypeEnum.warning,
              delay: 3000,
            });
          }, () => {
            notificationsStore.show({
              message: t('notifications.card_format_failed'),
              type: TNotificationTypeEnum.warning,
              delay: 3000,
            });
          });
        },
        text: t('actions.format_card'),
      }],
    });
    return;
  }

  notificationsStore.show({
    message: t('customer_cards_pass_card_scanned_tbo'),
    type: TNotificationTypeEnum.info,
    id: 'valid_card_data',
    delay: null,
    actions: [{
      callback() {
        cardReaderService.formatCard().then(() => {
          notificationsStore.show({
            message: t('notifications.card_format_success'),
            type: TNotificationTypeEnum.success,
            delay: 3000,
          });
        }).catch(() => {
          notificationsStore.show({
            message: t('notifications.card_format_failed'),
            type: TNotificationTypeEnum.warning,
            delay: 3000,
          });
        });
      },
      text: t('actions.format_card'),
    }],
  });
};

export default {
  handleTboCardReadEvent,
};
