(function () {
  angular.module('7Terminal.Bootstrap')
    .factory('lanugageLoader', shopLanugageLoader);

  function shopLanugageLoader($q, Bootstrap) {
    return function () {
      var deferred = $q.defer();
      var translations = Bootstrap.getTranslations();
      if (translations) {
        deferred.resolve(translations);
      } else {
        deferred.resolve({});
      }
      return deferred.promise;
    };
  }
})();
