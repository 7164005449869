<script setup lang="ts">
import { ref, computed } from 'vue';
import router from '@/router';
import resourcesService from '@/modules/cms/resources/resourcesService';
import { vLoadImage } from '@/common/directives/LoadImage';

const activeState = ref('');
const showLogo = computed(() => activeState.value === '/home');

router.beforeEach((route) => {
  activeState.value = route.path;
});

const imgSrc = resourcesService.getLogoUrl();
const errImgSrc = resourcesService.getS3LogoUrl();
</script>

<template>
  <div
    v-if="showLogo"
    v-load-image="{ imgSrc, errImgSrc }"
    class="h-100 t-header-logo sc-ml-5"
  />
</template>

<style scoped lang="scss">
.t-header-logo {
    max-height: 3rem;
}
</style>
