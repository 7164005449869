import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12"
}
const _hoisted_2 = { class: "t-header-anonymous-gap d-flex align-items-center justify-content-end h-100" }

import { computed } from 'vue';
import router from '@/router';
import { SCAppBar } from '@nsftx/seven-components';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import LobbyHeader from '@/modules/lobby/components/LobbyHeader.vue';
import THeaderHome from './THeaderHome.vue';
import THeaderLogo from './THeaderLogo.vue';
import THeaderMenu from './THeaderMenu.vue';
import THeaderBalanceHelper from './THeaderBalanceHelper.vue';
import THeaderBalance from './THeaderBalance.vue';
import THeaderVoucher from './THeaderVoucher.vue';
import THeaderAcceptorAmount from './THeaderAcceptorAmount.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeader',
  setup(__props) {

const showLobbyHeader = computed(() => router.currentRoute.value.name === 'LobbyItem');

const classes = computed(() => ({
  'h-100 d-flex align-items-center': true,
  'flex-container': showLobbyHeader.value,
  'col-12 t-header-anonymous-spacing sc-pr-11': !showLobbyHeader.value,
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SCAppBar), { class: "row t-header-anonymous" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(classes.value)
      }, [
        _createVNode(LobbyHeader),
        _createVNode(THeaderHome),
        _createVNode(THeaderLogo),
        _createVNode(THeaderMenu)
      ], 2 /* CLASS */),
      (!showLobbyHeader.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(THeaderBalanceHelper),
              _createVNode(WidgetsRenderer, { position: "headerRight" }),
              _createVNode(THeaderVoucher),
              _createVNode(THeaderBalance),
              _createVNode(THeaderAcceptorAmount)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})