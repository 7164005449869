/**
 * @ngInject
 */
export default function (
  ScrUiService,
  $rootScope
) {
  $rootScope.$on('SCR:ConfigLoaded', function () {
    $rootScope.$emit('SCR:SetRunningProduct', {
      runningProduct: '7Terminal'
    });

    ScrUiService.boot();
  });

  $rootScope.$on('7T:Slave.Shown', function (e, data) {
    if (data.productId === 'SlotCarRaces') {
      $rootScope.$broadcast('7T:Betslip.ActivateTicket', {
        data: {
          betslipId: data.productId
        }
      });
    }
  });
}
