import * as angular from 'angular';

const mainModule = 'terminalApp';

const getService = <T = unknown>(serviceName: string): T | undefined => {
  const module = angular.element(document.getElementById('terminal-app') as Element);
  if ('injector' in module === false || !module.injector()) {
    return undefined;
  }
  const injector = module.injector();
  if (!injector) {
    return undefined;
  }

  if ('get' in injector === false) {
    return undefined;
  }
  const service = injector.get(serviceName) as T;
  return service;
};

const getMainModule = (): angular.IModule => angular.module(mainModule);

export {
  getMainModule,
  getService,
};
