<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import {
  SCRadioGroup, SCRadioButton, SCButton, ButtonVariant,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useOddTypeStore } from '@/common/stores/odd-type';
import TModal from '@/common/components/TModal.vue';

const { t } = i18n.global;
const props = defineProps<{
  modelValue: boolean,
}>();
// eslint-disable-next-line @typescript-eslint/object-curly-spacing
const emit = defineEmits<{(e: 'update:modalOpenValue', modelValue: boolean): void
}>();
const { modelValue } = toRefs(props);

const oddTypeStore = useOddTypeStore();
const isModalOpen = ref<boolean>(false);
const selectedItem = ref<string>(oddTypeStore.activeOddType.type);

const availableOddList = oddTypeStore.getAvailableOddTypes();

watch(modelValue, () => {
  isModalOpen.value = modelValue.value;

  // reset selected item when modal re-opened
  if (isModalOpen.value) {
    selectedItem.value = oddTypeStore.activeOddType.type;
  }
});

watch(isModalOpen, () => {
  emit(
    'update:modalOpenValue',
    isModalOpen.value,
  );
});

const saveOddType = () => {
  oddTypeStore.setOddType(selectedItem.value);
  isModalOpen.value = false;
};

</script>

<template>
  <TModal
    v-model="isModalOpen"
    with-close-icon
    modal-id="oddsTypePicker"
  >
    <template #title>
      {{ t('main.odds_type') }}
    </template>

    <template #content>
      <div class="w-100">
        <SCRadioGroup
          v-model="selectedItem"
          block
        >
          <SCRadioButton
            v-for="oddType in availableOddList"
            :key="oddType.id"
            :value="oddType.type"
            :label="`${t(oddType.name)} ${oddType.format}`"
          />
        </SCRadioGroup>
      </div>
    </template>

    <template #actions>
      <SCButton
        :variant="ButtonVariant.Primary"
        size="md"
        width="100%"
        :disabled="!selectedItem"
        @click="saveOddType"
      >
        {{ t('actions.save') }}
      </SCButton>
    </template>
  </TModal>
</template>
