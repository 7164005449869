import master from '@nsftx/seven-gravity-gateway/master';
import { extend } from 'underscore';
import { logService } from '@/common/services/logger';
import type IntegratorEventData from '@/modules/integrator/types/IntegratorEventData';
import { useWidgetsStore } from '@/common/stores/widgets';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { handleGatewayCancelEvent, handleGatewayValidateCancelEvent } from './gravityGatewayCancelService';
import { handleGatewayPayoutEvent, handleGatewayValidatePayoutEvent } from './gravityGatewayPayoutService';
import { handleGatewayCashoutEvent } from './gravityGatewayCashoutService';
import { handleUpdateTicketCheckStateEvent } from './gravityGatewayUpdateTicketCheckStateService';
import { handleAnalyticsSendEvent } from './gravityGatewayAnalyticsService';
import { handleGatewayNotificationShow, handleGatewayDialogShow, handleGatewayDialogClose } from './gravityGatewayNotificationsService';

const LOG_PREFIX = '[gravityGatewayEventsHandler]';

const prepareData = (data: unknown) => {
  const dataToSend = extend({}, data);
  // we must send pure json, without any methods
  return JSON.parse(JSON.stringify(dataToSend));
};

const getGateway = () => {
  const envHandlerStore = useEnvironmentHandlerStore();
  return master({
    debug: envHandlerStore.isDevelopmentMode() || logService.isDebugMode(),
  });
};

const attachListeners = () => {
  const gateway = getGateway();

  gateway.subscribe(
    'Tickets.Cancel',
    handleGatewayCancelEvent,
  );
  gateway.subscribe(
    'Tickets.ValidateCancel',
    handleGatewayValidateCancelEvent,
  );

  gateway.subscribe('Tickets.Payout', handleGatewayPayoutEvent);
  gateway.subscribe('Tickets.ValidatePayout', handleGatewayValidatePayoutEvent);

  gateway.subscribe('Tickets.Cashout', handleGatewayCashoutEvent);

  gateway.subscribe('Tickets.UpdateTicketCheckState', handleUpdateTicketCheckStateEvent);

  gateway.subscribe('Analytics.Send', handleAnalyticsSendEvent);
  gateway.subscribe('Dialog.Show', handleGatewayDialogShow);
  // deprecated in favor of Dialog.Show
  gateway.subscribe('Notifications.Show', handleGatewayNotificationShow);
  gateway.subscribe('Dialog.Close', handleGatewayDialogClose);
};

const sendEvent = (eventName: string, eventData: IntegratorEventData) => {
  const gatewayInstance = getGateway();
  if (!gatewayInstance) {
    logService.warn(`${LOG_PREFIX} Gravity Gateway master not yet initialized.`, {
      eventName,
      code: 'T_GRAVITY_GATEWAY_MASTER_NOT_INITIALIZED',
    });
    return;
  }

  const { data, context: { productId, widgetType }, config } = eventData;
  const enforceEvent = config?.enforceEvent;

  if (productId === '*') {
    gatewayInstance
      .sendToAll({
        action: eventName,
        data: prepareData(data),
        enforceEvent,
      });
  } else {
    const widgetStore = useWidgetsStore();
    let frameId = productId;
    if (widgetType) {
      const widgetFound = widgetStore.getWidgetByType([widgetType], productId);
      if (widgetFound?.source.config?.frameId) {
        frameId = widgetFound.source.config.frameId;
      }
    }

    gatewayInstance
      .sendMessage(frameId, {
        action: eventName,
        productId,
        data: prepareData(data),
        enforceEvent,
      });
  }
};

export default {
  sendEvent,
  attachListeners,
  getGateway,
};
