/* eslint-disable import/no-cycle */
import { each, find } from 'underscore';
import { lobbyService } from '@/modules/lobby';

const TERMINAL_MODULES = {
  list: {
    anonymous_app: {
      ng: {
        moduleName: '7Terminal.AnonymousApp'
      },
      lazyLoad: {
        // eslint-disable-next-line import/no-cycle
        load: () => import(
          /* webpackChunkName: "anonymous_app" */
          './modules/anonymous_app'
        )
      }
    },
    customer_card: {
      lazyLoad: {
        position: 'afterAppReady',
        load: () => import(
          /* webpackChunkName: "customer_card" */
          '@/modules/customer-card'
        )
      }
    },
    tax_auth: {
      lazyLoad: {
        position: 'afterCustomerCardModuleReady',
        load: () => import(
          /* webpackChunkName: "tax_auth" */
          '@/modules/customer-card/tax-auth'
        )
      }
    },
    pass_card: {
      lazyLoad: {
        position: 'afterCustomerCardModuleReady',
        load: () => import(
          /* webpackChunkName: "pass_card" */
          '@/modules/customer-card/pass-card'
        )
      }
    },
    ticket_claim: {
      lazyLoad: {
        position: 'afterCustomerCardModuleReady',
        load: () => import(
          /* webpackChunkName: "ticket_claim" */
          '@/modules/customer-card/ticket-claim'
        )
      }
    },
    devicecashdeposit: {
      lazyLoad: {
        position: 'beforeAppReady',
        load: () => import(
          /* webpackChunkName: "device_cash_deposit" */
          '@/modules/device-cash-deposit'
        )
      }
    },
    oktopay: {
      lazyLoad: {
        position: 'beforeAppReady',
        load: () => import(
          /* webpackChunkName: "oktopay" */
          '@/modules/oktopay'
        )
      }
    },
    superbet_web_terminal_payment: {
      lazyLoad: {
        position: 'beforeAppReady',
        load: () => import(
          /* webpackChunkName: "superbet_web_terminal_payment" */
          './modules/superbet_web_terminal_payment'
        )
      }
    },
    lobby_module: {
      lazyLoad: {
        position: 'afterAppReady',
        load: () => import(
          /* webpackChunkName: "lobby_module" */
          '@/modules/lobby'
        )
      },
      isEnabled: () => lobbyService.isActive()
    },
    eventsAudit: {
      lazyLoad: {
        position: 'afterAppReady',
        load: () => import(
          /* webpackChunkName: "events_audit" */
          '@/modules/audit'
        )
      }
    },
    intelligent_gaming: {
      lazyLoad: {
        position: 'afterAppReady',
        load: () => import(
          /* webpackChunkName: "intelligent_gaming" */
          '@/modules/intelligent-gaming'
        )
      }
    },
    rvo: {
      lazyLoad: {
        position: 'beforeAppReady',
        load: () => import(
          /* webpackChunkName: "rvo" */
          '@/modules/rvo'
        )
      }
    },
    belgiumCompliance: {
      lazyLoad: {
        position: 'beforeAppReady',
        load: () => import(
          /* webpackChunkName: "belgium_compliance" */
          '@/modules/belgium-compliance'
        )
      }
    },
    fiscal: {
      lazyLoad: {
        position: 'afterAppReady',
        load: () => import(
          /* webpackChunkName: "fiscal" */
          '@/modules/fiscal'
        )
      }
    }
  },

  filterLazyLoadModules: function (position) {
    const result = [];

    each(this.list, function (module, key) {
      if (module.lazyLoad && module.lazyLoad.position === position) {
        module.id = key;
        result.push(module);
      }
    });

    return result;
  },

  getModules: function () {
    each(this.list, function (module, key) {
      module.id = key;
    });

    return this.list;
  },

  getModule: function (moduleName) {
    return this.list[moduleName];
  },

  getModuleByNgName: function (ngModuleName) {
    return find(this.list, function (module, key) {
      module.id = key;
      return module.ng?.moduleName === ngModuleName;
    });
  }

};

export default TERMINAL_MODULES;
