import { range, each, where } from 'underscore';

(function () {
  angular.module('terminalApp.sevenVirtualMotorcycleSpeedway')
    .service('VmsUiService', VmsUiService);

  function VmsUiService(
    $rootScope,
    nabMessaging,
    nabTrans,
    SevenGamesSvc,
    BetslipService,
    NabNotifications,
    ticketService,
    GravitySettings
  ) {
    var TICKET_TYPES = {
      SINGLE: {
        id: 3,
        name: 'single'
      },
      COMBO: {
        id: 1,
        name: 'combo'
      },
      SYSTEM: {
        id: 2,
        name: 'system'
      }
    };
    var bet;
    var finalizedBet;
    var bets;
    var self = this;

    this.winner = {};
    this.place = {};
    this.forecast = {};
    this.headToHead = {};
    this.reverseForecastActive = false;
    this.scheduledEvents = {};

    this.createScheduledEvents = function (events) {
      var i = 0;
      var event;
      for (i; i < events.length; i++) {
        event = events[i].eventId;
        this.scheduledEvents[event] = {};

        this.createRacecardSchedule(event);
        this.createForecastSchedule(event);
        this.createH2HSchedule(event);
      }
    };

    this.updateScheduledEvents = function (events) {
      self.createScheduledEvents([events[events.length - 1]]);
    };

    this.clearScheduledEvents = function (currentEvent) {
      delete this.scheduledEvents[currentEvent];
    };

    this.createRacecardSchedule = function (event) {
      this.scheduledEvents[event].winner = {};
      this.scheduledEvents[event].place = {};
    };

    this.createRacecardSchedule = function (event) {
      this.scheduledEvents[event].winner = {};
      this.scheduledEvents[event].place = {};
      this.scheduledEvents[event].show = {};
    };

    this.createForecastSchedule = function (event) {
      var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
      var racersFirstPlace = range(1, VMS.numberOfRacers + 1);
      var racersSecondPlace = range(1, VMS.numberOfRacers + 1);

      self.scheduledEvents[event].forecast = {};

      each(racersFirstPlace, function (firstPlace) {
        each(racersSecondPlace, function (secondPlace) {
          if (firstPlace != secondPlace) {
            self.scheduledEvents[event].forecast[firstPlace + '-' + secondPlace] = false;
          }
        });
      });
    };

    this.createH2HSchedule = function (event) {
      var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
      var racersFirstPlace = range(1, VMS.numberOfRacers + 1);
      var racersSecondPlace = range(1, VMS.numberOfRacers + 1);

      self.scheduledEvents[event].headToHead = {};

      each(racersFirstPlace, function (firstPlace) {
        each(racersSecondPlace, function (secondPlace) {
          if (firstPlace != secondPlace) {
            self.scheduledEvents[event].headToHead[firstPlace + '-' + secondPlace] = false; // keep track of active bet
          }
        });
      });
    };

    this.deactivateScheduleForecast = function () {
      for (var i in self.scheduledEvents) {
        self.createForecastSchedule(i);
      }
    };

    this.clearActiveBets = function () {
      each(self.headToHead, function (value, position, racer) {
        racer[position] = false;
      });

      each(self.forecast, function (value, position, racer) {
        racer[position] = false;
      });

      each(self.place, function (value, position, racer) {
        racer[position] = false;
      });

      each(self.winner, function (value, position, racer) {
        racer[position] = false;
      });

      if (SevenGamesSvc.getGame('VirtualMotorcycleSpeedway').isScheduleActive) {
        self.deactivateScheduleForecast();
      }
    };

    this.enableActiveBets = function (bets) {
      var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
      var selectedBet;
      var bets;
      var combination;
      bets?.forEach(function (item) {
        if (item.clientId < 10) { // just winner,place
          self[item.key]['r' + item.displayValue] = true;

          if (VMS.isScheduleActive && (item.eventId >= VMS.gameInfo.eventId)) {
            self.scheduledEvents[item.eventId][item.key]['r' + item.displayValue] = true;
          }
        }
        if (item.clientId == 10 && !self.reverseForecastActive) { // update forecast
          self.forecast[item.displayValue] = true;

          if (VMS.isScheduleActive && (item.eventId >= VMS.gameInfo.eventId)) {
            self.scheduledEvents[item.eventId].forecast[item.displayValue] = true;
          }
        }

        if (item.clientId == 12 && self.reverseForecastActive) {
          self.forecast[item.input[0] + '-' + item.input[1]] = true;
          self.forecast[item.input[1] + '-' + item.input[0]] = true;

          if (VMS.isScheduleActive && (item.eventId >= VMS.gameInfo.eventId)) {
            self.scheduledEvents[item.eventId].forecast[item.input[0] + '-' + item.input[1]] = true;
            self.scheduledEvents[item.eventId].forecast[item.input[1] + '-' + item.input[0]] = true;
          }
        }

        if (item.clientId == 14) { // update h2h
          bets = BetslipService.ticket.getBets();
          combination = item.combination.split('').reverse().join('');

          self.headToHead[item.combination] = true;

          if (VMS.isScheduleActive && (item.eventId >= VMS.gameInfo.eventId)) {
            self.scheduledEvents[item.eventId].headToHead[item.combination] = true;

            if (self.scheduledEvents[item.eventId].headToHead[item.combination.split('').reverse().join('')]) {
              selectedBet = where(bets, { key: 'headToHead', combination: combination, eventId: item.eventId })[0];
              self.scheduledEvents[item.eventId].headToHead[combination] = false;
              BetslipService.removeFromBetslip(selectedBet.uid, false);
            }
          } else if (self.headToHead[item.combination.split('').reverse().join('')]) {
            selectedBet = where(bets, { key: 'headToHead', combination: combination })[0];
            self.headToHead[combination] = false;
            BetslipService.removeFromBetslip(selectedBet.uid, false);
          }
        }
      });
    };

    function createTicket() {
      var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');

      if (VMS
                && !BetslipService.getTicket(TICKET_TYPES.SINGLE.name, 'VirtualMotorcycleSpeedway')) {
        $rootScope.$broadcast('7T:Betslip.Create', {
          data: {
            betslip: {
              id: 'VirtualMotorcycleSpeedway',
              tickets: [
                {
                  id: TICKET_TYPES.SINGLE.name,
                  active: true,
                  ticketGroup: 'VirtualMotorcycleSpeedway',
                  betsCollection: 'VirtualMotorcycleSpeedway',
                  group: {
                    id: 'VirtualMotorcycleSpeedway',
                    taxesConfig: VMS.config.taxes
                  },
                  ticketType: TICKET_TYPES.SINGLE,
                  oddProp: 'mboOddValue',
                  stake: VMS.getRule('minPayinAmount', {
                    ticketType: TICKET_TYPES.SINGLE.id
                  }),
                  stakeTouched: false,
                  setStakeStrategy: 'sevenVirtualSetStake',
                  winningStrategy: false,
                  setFormatPayin: 'sevenVirtualFormataPayin',
                  hasSystem: false,
                  emptyBetslipText: nabTrans.translate(
                    'betslip.pick_numbers_message_first_line', {}, true
                  ),
                  autoStakeSetting: true
                }
              ]
            }
          }
        });
      }
    }

    this.formatTicketBets = function (message) {
      message.ticketFormatted = formatter(message.ticket);

      function formatter(ticket) {
        var ticket = ticket;
        var bets = ticket.bets;
        var result = { bets: [], payin: 0 };

        for (var j = 0; j < bets.length; j++) {
          result.bets.push({
            payin: bets[j].stake ? bets[j].stake : false,
            type: bets[j].id,
            value: bets[j].value,
            eventId: bets[j].eventId,
            numEvents: bets[j].numEvents ? bets[j].numEvents : 1
          });

          result.payin += bets[j].stake;
        }
        return result;
      }
    };

    // listen for config changes
    nabMessaging.subscribe('NCM:gameConfigChanged', function (event, message) {
      if (message.game == 'VirtualMotorcycleSpeedway') {
        var game = SevenGamesSvc.getGame(message.game);
        game.setConfig().then(function () {
          game.doInitialConfiguration();
          $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
            data: {
              betslipId: game.id,
              ticketId: TICKET_TYPES.SINGLE.name,
              config: {
                group: {
                  id: game.id,
                  taxesConfig: game.config.taxes
                },
                stake: game.getRule('minPayinAmount', {
                  ticketType: TICKET_TYPES.SINGLE
                })
              }
            }
          });

          nabMessaging.publish('config:gameConfigChanged', message.game);
        });
      }
    });

    nabMessaging.subscribe('VMS:raceStart', function (e, data) {
      var betsToRemove = [];
      var eventId = data.eventId;
      var VMS = SevenGamesSvc.getGame(data.game);
      var ticket = BetslipService.getTicket('single', data.game);

      if (ticket) {
        ticket.getBets().forEach(function (bet) {
          if (bet.eventId == VMS.gameInfo.eventId) {
            bet.locked = true;
            betsToRemove.push(bet.uid);
          }
        });

        if (betsToRemove.length) {
          ticket.locked = true;
        }
      }
    });

    nabMessaging.subscribe('VMS:updateEventsNew', function (e, data) {
      var game = SevenGamesSvc.getGame(data.game);
      self.updateScheduledEvents(game.offer, game)
    });

    // update rounds and odds on betslip
    nabMessaging.subscribe('VMS:updateEventsOld', function (e, data) {
      var betsToUpdate = [];
      var eventAfter = data.param;
      var eventId = data.eventId;
      var VMS = SevenGamesSvc.getGame(data.game);
      var ticket = BetslipService.getTicket('single', data.game);

      if (ticket) {
        ticket.getBets()
          .forEach(function (bet) {
            betsToUpdate.push({
              event: eventAfter,
              eventId: eventId,
              uid: bet.uid
            });
            // add if

            /** Update odds when new round starts* */
            if (VMS.isScheduleActive) {

            } else {
              if (bet.id == 10) { // update forecast
                bet.odd = VMS._bettingSvc.systemBets.forecastOdds[bet.displayValue];
              }

              if (bet.id == 14) { // update h2h
                bet.odd = VMS._bettingSvc.normalBetsOdds.h2h[bet.combination];
              }

              if (bet.id < 10) {
                bet.odd = VMS._bettingSvc.normalBetsOdds[bet.key][bet.displayValue];
              }
            }
          });

        if (VMS.isScheduleActive) {
          self.updateScheduledEvents(VMS.offer);
        } else {
          $rootScope.$broadcast('7T:Betslip.UpdateBets', {
            data: {
              betslipId: data.game,
              bets: betsToUpdate
            }
          });
        }
      }
    });

    // listen for betslip payin action
    $rootScope.$on('7T:Betslip.Payin',
      /**
             *
             * @param event
             * @param {Object} message
             * @param {String} message.productId
             * @param {Object} message.ticket
             */
      function (event, message) {
        if (message.productId === 'VirtualMotorcycleSpeedway') {
          self.formatTicketBets(message);
          ticketService.payinTicket(message).catch(angular.noop);
        }
      });

    $rootScope.$on('7T:Tickets.PrePrint', function (e, eventData) {
      var VMS;
      var BettingSvc;
      const productId = eventData.productId;
      const isInIframe = GravitySettings
        .getModuleDataKeyValue(`module.${productId}`, 'frame') === true;

      if (isInIframe) {
        return;
      }

      if (eventData.productId === 'VirtualMotorcycleSpeedway') {
        VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
        BettingSvc = VMS.getBettingSvc();
        angular.merge(eventData.responseData, BettingSvc.prepareForPrint(
          eventData.responseData,
          VMS.config
        ));

        $rootScope.$emit('7T:Tickets.Print', eventData);
      }
    });

    this.boot = function () {
      createTicket();

      $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
        data: {
          betslipId: 'VirtualMotorcycleSpeedway',
          ticketId: TICKET_TYPES.SINGLE.name,
          config: {
            emptyBetslipText: nabTrans.translate(
              'betslip.pick_numbers_message_first_line', {}, true
            )
          }
        }
      });
    };

    this.addReverseForecastBet = function (activeBet) {
      var bets = BetslipService.ticket.getBets();
      var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
      var BettingSvc = VMS.getBettingSvc();

      var selectedBetComb1 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[0] })[0];
      var selectedBetComb2 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[1] })[0];

      if (VMS.isScheduleActive) {
        selectedBetComb1 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[0], eventId: VMS._scheduleSvc.activeRound.eventId })[0];
        selectedBetComb2 = where(bets, { key: 'reverseForecast', displayValue: activeBet.combinations[1], eventId: VMS._scheduleSvc.activeRound.eventId })[0];
      }

      if (selectedBetComb1 || selectedBetComb2) {
        var toRemove = selectedBetComb1 ? selectedBetComb1.uid : selectedBetComb2.uid;
        BetslipService.removeFromBetslip(toRemove, false);
        return;
      }

      var bet = where(VMS.configuredBets, { key: 'reverseForecast' });

      BettingSvc.activateBet(bet[0]);

      if (BettingSvc.ticketInput.systemOdd) {
        delete BettingSvc.ticketInput.systemOdd;
      }
      var finalizedBet = BettingSvc.finalizeTicketInput()[0];

      if (VMS.isScheduleActive) {
        finalizedBet.eventId = VMS._scheduleSvc.activeRound.eventId;
        finalizedBet.event = VMS._scheduleSvc.activeRound.eventIdToday;
      }
      var error = BetslipService.validateBet(
        finalizedBet,
        false
      );

      if (error.msg) {
        NabNotifications.show({
          message: error.msg,
          type: 'warning',
          delay: 3000
        });
      } else {
        BetslipService.addToBetslip(finalizedBet);
      }
    };

    this.addBet = function (activeBet) {
      var VMS = SevenGamesSvc.getGame('VirtualMotorcycleSpeedway');
      var BettingSvc = VMS.getBettingSvc();
      var error;
      var selectedBet;
      bets = BetslipService.ticket.getBets();

      if (activeBet.key == 'reverseForecast') {
        self.addReverseForecastBet(activeBet);
        return;
      }

      selectedBet = where(bets, { key: activeBet.bet, odd: activeBet.value })[0];

      /** If same bet is clicked - set it inactive and remove it from betslip* */
      if (this[activeBet.bet] && (this[activeBet.bet]['r' + activeBet.racers[0]] || this[activeBet.bet][activeBet.racers])
               && (VMS.isScheduleActive ? (this.scheduledEvents[VMS._scheduleSvc.activeRound.eventId][activeBet.bet]['r' + activeBet.racers[0]] || this.scheduledEvents[VMS._scheduleSvc.activeRound.eventId][activeBet.bet][activeBet.racers]) : true)) {
        if (VMS.isScheduleActive) {
          if (activeBet.bet == 'winner') {
            self.scheduledEvents[VMS._scheduleSvc.activeRound.eventId][activeBet.bet]['r' + activeBet.racers[0]] = false;
          } else {
            self.scheduledEvents[VMS._scheduleSvc.activeRound.eventId][activeBet.bet][activeBet.racers] = false;
          }
          selectedBet = where(bets, { key: activeBet.bet, eventId: VMS._scheduleSvc.activeRound.eventId, odd: activeBet.value })[0];
        }

        BetslipService.removeFromBetslip(selectedBet.uid, false);
        return;
      }

      bet = where(VMS.configuredBets, { key: activeBet.bet });
      BettingSvc.activateBet(bet[0]);
      finalizedBet = BettingSvc.finalizeTicketInput()[0];
      finalizedBet.disableEdit = true; // disable editing - winner,place
      finalizedBet.odd = activeBet.value;
      finalizedBet.eventId = VMS.gameInfo.eventId;

      if (VMS.isScheduleActive) {
        finalizedBet.eventId = VMS._scheduleSvc.activeRound.eventId;
        finalizedBet.event = VMS._scheduleSvc.activeRound.eventIdToday;
      }

      error = BetslipService.validateBet(
        finalizedBet,
        false
      );

      if (error.msg) {
        NabNotifications.show({
          message: error.msg,
          type: 'warning',
          delay: 3000
        });
      } else {
        BetslipService.addToBetslip(finalizedBet);
      }
    };
  }
})();
