import { where, uniq } from 'underscore';
import templateUrl from './horse_races.html';
import inactiveImage from '../../../assets/images/app/horses_inactive_bg.jpg';

(function () {
  angular.module('terminalApp.nabHorseRacesUi')
    .component('horseRacesUi', {
      controllerAs: 'horseRacesVm',
      templateUrl: templateUrl,
      controller: function (
        flashButton,
        SevenGamesSvc,
        nabTrans,
        NabNotifications,
        BetslipService,
        HorseRacesUiService,
        $rootScope,
        $state,
        $scope,
        loaderService,
        GamesUiService,
        arrayService
      ) {
        var odds;
        var finalizedBet;
        var bet;
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString(); // switch virtual and regular horse races
        var HorseRaces = SevenGamesSvc.getGame(activeGame);
        var gameState = GamesUiService.checkGameActivity(HorseRaces);
        var betslipResetListener;
        var self = this;
        var BettingSvc = HorseRaces.getBettingSvc();

        this.$onInit = function () {
          this.inactiveImage = inactiveImage;
          this.activeGame = HorseRaces;
          this.gameInfo = HorseRaces.getGameInfo();
          odds = HorseRaces.odds;
          this.resolutionMode = $rootScope.resolutionMode;
          BettingSvc.initializeService(HorseRaces);
          this.betslipSvc = BetslipService;
          this.horseRacesUISvc = HorseRacesUiService;
          this.tabs = {
            name: ['racecard', 'forecast', 'tricast']
          };

          if (this.activeGame.isScheduleActive) {
            this.horseRacesUISvc.createScheduledEvents(this.activeGame.offer);
            this.watchDisabled();
          }

          $scope.futureRounds = arrayService.getArray(10, true);
        };

        /**
                 * Send created bet to betslip
                 */

        this.sendBet = function () {
          if (!gameState.value) {
            NabNotifications.show(
              {
                message: nabTrans.translate(gameState.messageKey, { game: this.activeGame.translation }, true),
                type: 'warning',
                delay: 3000
              }
            );
            return;
          }

          if (!flashButton.flash) {
            return;
          }

          if (this.horseRacesUISvc.reverseForecastPlaces.length > 0) {
            this.formatReverseForecastBet();
          }

          if (this.horseRacesUISvc.reverseTricastPlaces.length > 0) {
            this.formatReverseTricastBet();
          }

          if (this.horseRacesUISvc.forecastFirstPlaces.length > 0 && this.horseRacesUISvc.forecastSecondPlaces.length > 0) {
            this.formatForecastBet();
          }

          if (this.horseRacesUISvc.tricastFirstPlaces.length > 0
                        && this.horseRacesUISvc.tricastSecondPlaces.length > 0 && this.horseRacesUISvc.tricastThirdPlaces.length > 0) {
            this.formatTricastBet();
          }

          finalizedBet = BettingSvc.finalizeTicketInput();

          if (this.activeGame.isScheduleActive) {
            finalizedBet[0].eventId = this.activeGame._scheduleSvc.activeRound.eventId;
            finalizedBet[0].event = this.activeGame._scheduleSvc.activeRound.eventIdToday;
          }

          var error = BetslipService.validateBet(
            finalizedBet,
            false
          );

          if (error.msg) {
            NabNotifications.show({
              message: error.msg,
              type: 'warning',
              delay: 3000
            });
          } else {
            BetslipService.addToBetslip(finalizedBet);
          }

          this.resetBet();
        };

        /** Format reverse forecast bet for sending to server* */

        this.formatReverseForecastBet = function () {
          /** Remove duplicates form array* */

          this.horseRacesUISvc.reverseForecastPlaces = uniq(this.horseRacesUISvc.reverseForecastPlaces);

          bet = where(HorseRaces.configuredBets, { key: 'reverseForecast' });
          BettingSvc.activateBet(bet[0]);

          /** Remove set forecast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.horseRacesUISvc.reverseForecastPlaces;
        };

        /** Format  forecast bet for sending to server* */

        this.formatForecastBet = function () {
          bet = where(HorseRaces.configuredBets, { key: 'forecast' });

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Remove duplicates form array* */

          this.horseRacesUISvc.forecastFirstPlaces = uniq(this.horseRacesUISvc.forecastFirstPlaces);
          this.horseRacesUISvc.forecastSecondPlaces = uniq(this.horseRacesUISvc.forecastSecondPlaces);

          BettingSvc.activateBet(bet[0]);

          /** Add odd for forecast bet* */

          if (this.horseRacesUISvc.forecastFirstPlaces.length == 1 && this.horseRacesUISvc.forecastSecondPlaces.length == 1) {
            BettingSvc.ticketInput.systemOdd = HorseRaces._bettingSvc.systemBets.forecastOdds[this.horseRacesUISvc.forecastFirstPlaces[0] + '-' + this.horseRacesUISvc.forecastSecondPlaces[0]];

            if (this.activeGame.isScheduleActive) {
              BettingSvc.ticketInput.systemOdd = this.activeGame._scheduleSvc.activeRound.bets.systemBets.forecastOdds[this.horseRacesUISvc.forecastFirstPlaces[0] + '-' + this.horseRacesUISvc.forecastSecondPlaces[0]];
            }
          } else {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Format array for bet service - same format as 7shop * */
          BettingSvc.ticketInput.input = this.horseRacesUISvc.forecastFirstPlaces;
          BettingSvc.ticketInput.secondPlace = this.horseRacesUISvc.forecastSecondPlaces;
        };

        /** Format  tricast bet for sending to server* */

        this.formatTricastBet = function () {
          /** Remove set forecast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          bet = where(HorseRaces.configuredBets, { key: 'tricast' });

          /** Remove duplicates form array* */

          this.horseRacesUISvc.tricastFirstPlaces = uniq(this.horseRacesUISvc.tricastFirstPlaces);
          this.horseRacesUISvc.tricastSecondPlaces = uniq(this.horseRacesUISvc.tricastSecondPlaces);
          this.horseRacesUISvc.tricastThirdPlaces = uniq(this.horseRacesUISvc.tricastThirdPlaces);

          if (this.horseRacesUISvc.tricastFirstPlaces.length == 1 && this.horseRacesUISvc.tricastSecondPlaces.length == 1 && this.horseRacesUISvc.tricastThirdPlaces.length == 1) {
            BettingSvc.ticketInput.systemOdd = HorseRaces._bettingSvc.systemBets.tricastOdds[this.horseRacesUISvc.tricastFirstPlaces[0] + '-' + this.horseRacesUISvc.tricastSecondPlaces[0] + '-' + this.horseRacesUISvc.tricastThirdPlaces[0]];

            if (this.activeGame.isScheduleActive) {
              BettingSvc.ticketInput.systemOdd = this.activeGame._scheduleSvc.activeRound.bets.systemBets.tricastOdds[this.horseRacesUISvc.tricastFirstPlaces[0] + '-' + this.horseRacesUISvc.tricastSecondPlaces[0] + '-' + this.horseRacesUISvc.tricastThirdPlaces[0]];
            }
          } else {
            delete BettingSvc.ticketInput.systemOdd;
          }

          BettingSvc.activateBet(bet[0]);

          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.horseRacesUISvc.tricastFirstPlaces;
          BettingSvc.ticketInput.secondPlace = this.horseRacesUISvc.tricastSecondPlaces;
          BettingSvc.ticketInput.thirdPlace = this.horseRacesUISvc.tricastThirdPlaces;
        };

        /** Format reverse tricast bet for sending to server* */

        this.formatReverseTricastBet = function () {
          /** Remove set forecast odd if exist* */

          if (BettingSvc.ticketInput.systemOdd) {
            delete BettingSvc.ticketInput.systemOdd;
          }

          /** Remove duplicates form array* */

          this.horseRacesUISvc.reverseTricastPlaces = uniq(this.horseRacesUISvc.reverseTricastPlaces);
          bet = where(HorseRaces.configuredBets, { key: 'reverseTricast' });

          BettingSvc.activateBet(bet[0]);
          /** Format array for bet service - same format as 7shop * */

          BettingSvc.ticketInput.input = this.horseRacesUISvc.reverseTricastPlaces;
        };

        this.clearAll = function () {
          this.horseRacesUISvc.disableReverseForecast = false;
          this.horseRacesUISvc.disableForecast = false;
          this.horseRacesUISvc.disableReverseTricast = false;
          this.horseRacesUISvc.disableTricast = false;
          this.horseRacesUISvc.forecastFirstPlaces = [];
          this.horseRacesUISvc.forecastSecondPlaces = [];
          this.horseRacesUISvc.reverseForecastPlaces = [];
          this.horseRacesUISvc.tricastFirstPlaces = [];
          this.horseRacesUISvc.tricastSecondPlaces = [];
          this.horseRacesUISvc.tricastThirdPlaces = [];
          this.horseRacesUISvc.reverseTricastPlaces = [];
          this.horseRacesUISvc.deactivateForecastRacers();
          this.horseRacesUISvc.deactivateTricastRacers();
          this.horseRacesUISvc.deactivateRacecardRacers();
        };

        this.checkState = function (btn) {
          if (this.horseRacesUISvc.reverseForecastPlaces.length > 0) {
            btn = true;
          }

          if (this.horseRacesUISvc.reverseTricastPlaces.length > 0) {
            btn = true;
          }

          if (this.horseRacesUISvc.forecastFirstPlaces.length > 0 && this.horseRacesUISvc.forecastSecondPlaces.length > 0) {
            btn = true;
          }

          if (this.horseRacesUISvc.tricastFirstPlaces.length > 0
                        && this.horseRacesUISvc.tricastSecondPlaces.length > 0 && this.horseRacesUISvc.tricastThirdPlaces.length > 0) {
            btn = true;
          }

          return btn;
        };

        this.watchDisabled = function () {
          $scope.$watch(function () {
            return self.activeGame.gameInfo.betsBlocked;
          }, function (newValue) {
            if (newValue) {
              flashButton.flash = false;
            } else if (self.checkState(flashButton.flash)) flashButton.flash = true;
          }, true);
        };
        /**
                 * Clear currently selected bet
                 */

        this.resetBet = function () {
          BettingSvc.clearAll();
          this.clearAll();
          flashButton.flash = false;
        };

        betslipResetListener = $rootScope.$on('7T:Betslip.Reset', function () {
          self.resetBet();
        });

        var betslipBetsChangedListener = $rootScope.$on('7T.Betslip.BetsChanged', function (e, data) {
          if (data.type === 'betRemove') {
            self.resetBet();
          }
        });

        $scope.$on('$destroy', function () {
          betslipResetListener();
          betslipBetsChangedListener();
        });

        flashButton.flash = false;
        loaderService.endLoader();
      }
    });
})();
