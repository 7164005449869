import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "h-100 w-100" }

import {
  ref, computed, onMounted,
} from 'vue';
import { storeToRefs } from 'pinia';
import PubSub from 'pubsub-js';
import { SCTabs, SCTab, SCHorizontalScroll } from '@nsftx/seven-components';
import router from '@/router';
import { getHeaderMenuList, type MenuItem } from '@/common/services/menu';
import { getService } from '@/common/services/ngBridge';
import { useHeaderMenuStore } from '@/common/stores/header-menu';
import { vGaTrackEvent } from '@/modules/google-analytics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderMenu',
  setup(__props) {

const switchView: any = getService('switchView');
// data
const headerMenuStore = useHeaderMenuStore();
const { selectedTabIndex } = storeToRefs(headerMenuStore);
const activeState = ref('');
const menus = ref<MenuItem[]>([]);
const showMenu = computed(() => activeState.value !== '/home' && !activeState.value.includes('/lobby/'));

// lifecycle hooks
onMounted(() => {
  menus.value = getHeaderMenuList();
});

// subscriptions
PubSub.subscribe('app:configFetchingSuccess', () => {
  menus.value = getHeaderMenuList();
});

router.beforeEach((route) => {
  activeState.value = route.path;
  const index = menus.value.findIndex((el: MenuItem) => (
    el.params?.gameId === route.query?.gameId
    || activeState.value.includes(el.params?.gameId.toLowerCase() || '')
  ));

  headerMenuStore.setPreviousTabIndex();

  // In header menu there can be only links to games and ticket check routes
  headerMenuStore.setCurrentTabIndex(
    index !== -1 && (route.path === '/games' || route.path === '/results/ticketCheck')
      ? index
      : undefined,
  );
});

// methods
const onTabSelectionChanged = (selectedMenuIndex: number) => {
  const selectedMenu = menus.value[selectedMenuIndex];

  if (selectedMenu.type !== 'group') {
    switchView.selectService(
      selectedMenu.route,
      selectedMenu.type === 'product',
      selectedMenu.params,
    );
  }

  headerMenuStore.setCurrentTabIndex(selectedMenuIndex);
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(SCHorizontalScroll), null, {
      default: _withCtx(() => [
        _createVNode(_unref(SCTabs), {
          "model-value": _unref(selectedTabIndex),
          variant: "secondary"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menus.value, (menu, index) => {
              return _withDirectives((_openBlock(), _createBlock(_unref(SCTab), {
                key: index,
                onClick: ($event: any) => (onTabSelectionChanged(index))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(menu.translation), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])), [
                [_unref(vGaTrackEvent), {
            eventName: 'Open_Module',
            eventParams: { module: menu.item.url, source: 'Header' },
          }]
              ])
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model-value"])
      ]),
      _: 1 /* STABLE */
    })
  ], 512 /* NEED_PATCH */)), [
    [_vShow, showMenu.value]
  ])
}
}

})