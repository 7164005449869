// Note: It will round decimal places only if received value
// has more than desired decimal places
// 1.345 => 1.34
// 1.1 => 1.1
const decimalsLimiter = (value: string, decimals = 2): string => {
  let numOfDecimals = 0;
  let roundedValue = value;
  roundedValue = roundedValue.replace(/\./, '#').replace(/\./g, '').replace(/#/, '.');// remove multiple dots

  // calculate how many decimal places are entered in received value
  if (value.includes('.')) {
    numOfDecimals = roundedValue.split('.')[1].length || 0;

    if (numOfDecimals > decimals) {
      const splitted = roundedValue.split('.');
      const base = splitted[0];
      let decimalPart = splitted[1];
      decimalPart = decimalPart.substring(0, decimals);
      roundedValue = `${base}.${decimalPart}`;
    }
    if (decimals === 0) { // case: decimalsLimitter('17.', 0)
      roundedValue = roundedValue.replace('.', '');
    }
  }

  return roundedValue;
};

export default decimalsLimiter;
