import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row" }
const _hoisted_2 = { class: "t-footer-button sc-my-1 sc-ml-1" }
const _hoisted_3 = { class: "sc-ml-3 footer-button-label" }

import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import i18n from '@/plugins/i18n';
import router from '@/router';
import { SCButton } from '@nsftx/seven-components';
import { getService } from '@/common/services/ngBridge';
import { useAuthStore, authService } from '@/modules/auth';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import AuthModalsHandler from '@/modules/auth/components/AuthModalsHandler.vue';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import { vGaTrackEvent } from '@/modules/google-analytics';

// Constants

export default /*@__PURE__*/_defineComponent({
  __name: 'TFooterActionsLeft',
  setup(__props) {

const gravitySettingsStore = useGravitySettingsStore();
const isHelpActionHidden = gravitySettingsStore.getModuleDataKeyValue('config', 'helpSectionHidden');
const { t } = i18n.global;
const activeState = ref('home');
const authStore = useAuthStore();
const { isLoginModalOpened } = storeToRefs(authStore);

// Vars
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let $state: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let switchView: any;

router.beforeEach((route) => {
  activeState.value = route.path;
});

const showLoginModal = () => {
  authService.validateLogin().then(() => {
    isLoginModalOpened.value = true;
  }).catch(() => {});
};

const openTermsAndConditions = () => {
  // if we are already on termsAndConditions return to previous state
  if ($state.includes('app.termsandconditions')) {
    switchView.resolvePreviousView();
  } else {
    switchView.selectService('termsandconditions', false, false);
  }
};

const openInstructions = () => {
  // if we are already on howToPlay/instructions return to previous state
  if ($state.includes('app.instructions')) {
    switchView.resolvePreviousView();
  } else {
    $state.go('app.instructions');
  }
};

const openResultsAndTicketCheck = () => {
  if ($state.current.name === 'app.results.ticketcheck') {
    $state.go('app.home');
  } else {
    $state.go('app.results.ticketcheck');
  }
};

onMounted(() => {
  $state = getService('$state');
  switchView = getService('switchView');
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_unref(SCButton), {
      class: "sc-my-1",
      icon: "french-key",
      active: _unref(isLoginModalOpened),
      width: "64px",
      height: "60px",
      onClick: showLoginModal
    }, null, 8 /* PROPS */, ["active"]), [
      [_unref(vGaTrackEvent), {
        eventName: 'Open_Module',
        eventParams: { module: 'Login modal', source: 'Footer' },
      }]
    ]),
    _withDirectives(_createVNode(_unref(SCButton), {
      class: "sc-my-1 sc-ml-1",
      icon: "eighteen",
      active: activeState.value.includes('terms'),
      width: "64px",
      height: "60px",
      onClick: openTermsAndConditions
    }, null, 8 /* PROPS */, ["active"]), [
      [_unref(vGaTrackEvent), {
        eventName: 'Open_Module',
        eventParams: { module: 'Terms and conditions', source: 'Footer' },
      }]
    ]),
    (!_unref(isHelpActionHidden))
      ? _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
          key: 0,
          class: "sc-my-1 sc-ml-1",
          icon: "question",
          active: activeState.value.includes('help'),
          width: "64px",
          height: "60px",
          onClick: openInstructions
        }, null, 8 /* PROPS */, ["active"])), [
          [_unref(vGaTrackEvent), {
        eventName: 'Open_Module',
        eventParams: { module: 'Help', source: 'Footer' },
      }]
        ])
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
        width: "100%",
        "prepend-icon": "live-betting",
        active: activeState.value.includes('results'),
        height: "60px",
        onClick: openResultsAndTicketCheck
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('common_results_ticket_check')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active"])), [
        [_unref(vGaTrackEvent), {
          eventName: 'Open_Module',
          eventParams: { module: 'Results', source: 'Footer' },
        }]
      ])
    ]),
    _createVNode(AuthModalsHandler),
    _createVNode(WidgetsRenderer, { position: "footerLeft" })
  ]))
}
}

})