import { ref } from 'vue';
import { logService } from '@/common/services/logger';
import { axiosErrorParser } from '@/common/services/error-parser';
import { getConnectionStatus } from './apiService';

const isConnected = ref(false);

const init = () => {
  getConnectionStatus().then((response) => {
    isConnected.value = response.data.ok === 'true';
  }).catch((err) => {
    logService.warn('[connectionStatusService] Get connection status route failed', {
      code: 'T_CONNECTION_STATUS_HTTP_FAILED',
      ...axiosErrorParser.parseUpstream(err),
    });
  });
};

export {
  init,
  isConnected,
};
