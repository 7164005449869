import { errorParser } from '@/common/services/error-parser';

angular.module('7Terminal.Common').factory('modalSvc', function modalSvc(
  $animate,
  $compile,
  $controller,
  $http,
  $rootScope,
  $q,
  $templateCache,
  $log,
  modalSvcConfig
) {
  var activeModals = {};

  var Service = function () {
    var self = this;

    // Returns a promise which gets the template, either
    // from the template parameter or via a request to the
    // template url parameter.
    var getTemplate = function (template, templateUrl) {
      var deferred = $q.defer();
      var cachedTemplate;

      if (template) {
        deferred.resolve(template);
      } else if (templateUrl) {
        // Check to see if the template has already been loaded
        cachedTemplate = $templateCache.get(templateUrl);
        if (cachedTemplate !== undefined) {
          deferred.resolve(cachedTemplate);
        } else {
          // Get the template for the first time
          $http({
            method: 'GET',
            url: templateUrl,
            cache: true
          }).then(function (result) {
            // Save template into the cache and return the template
            $templateCache.put(templateUrl, result.data);
            deferred.resolve(result.data);
          }, function (error) {
            deferred.reject(error);
          });
        }
      } else {
        deferred.reject('No template or templateUrl has been specified.');
      }

      return deferred.promise;
    };

    // Show modal
    self.showModal = function (options) {
      // Create a deferred we'll resolve when the modal is ready.
      var deferred = $q.defer();
      var controllerName;

      if (options.id) {
        if (activeModals[options.id]) return deferred.promise;
        activeModals[options.id] = {};
      }

      // Validate the input parameters.
      controllerName = options.controller;
      if (!controllerName) {
        deferred.reject('No controller has been specified.');
        return deferred.promise;
      }

      // If a 'controllerAs' option has been provided, we change the controller
      // name to use 'as' syntax. $controller will automatically handle this.
      if (options.controllerAs) {
        controllerName = controllerName + ' as ' + options.controllerAs;
      }

      // Get the actual html of the template.
      getTemplate(options.template, options.templateUrl).then(function (template) {
        // Create a new scope for the modal.
        var modalScope = $rootScope.$new();
        var modalElementTemplate;
        var linkFn;
        var modalElement;
        var modalController;
        var appendElement;
        var modal;
        var closeModal;
        // Create the inputs object to the controller - this will include
        // the scope, as well as all inputs provided.
        // We will also create a deferred that is resolved with a provided
        // close function. The controller can then call 'close(result)'.
        // The controller can also provide a delay for closing - this is
        // helpful if there are closing animations which must finish first.
        var closeDeferred = $q.defer();
        var inputs = {
          $scope: modalScope,
          close: function (result) {
            // Remove scroll class from body when modal is closed
            // eslint-disable-next-line
            angular.element(document.body).removeClass('scroll-hidden');
            if (closeDeferred) closeDeferred.resolve(result);
            angular.element(document).off('keydown', closeModal);

            $animate.leave(modalElement).then(function () {
              if (modalScope) modalScope.$destroy();
              if (inputs) inputs.close = null;
              deferred = null;
              closeDeferred = null;
              modal = null;
              inputs = null;
              modalElement = null;
              modalScope = null;
              if (options.id) delete activeModals[options.id];

              // Run callback
              if (_.isFunction(options.closeCallback)) {
                options.closeCallback();
              }
            }).catch((err) => {
              $log.debug(
                '[modalService] An error has ocurred while removing element from DOM',
                errorParser.parseUpstream(err)
              );
            });
          }
        };

        // If we have provided any inputs, pass them to the controller.
        if (options.inputs) {
          // eslint-disable-next-line
          for (var inputName in options.inputs) {
            inputs[inputName] = options.inputs[inputName];
          }
        }

        // Parse the modal HTML into a DOM element (in template form).
        modalElementTemplate = angular.element(template);

        // Compile then link the template element, building the actual element.
        // Set the $element on the inputs so that it can be injected if required.
        linkFn = $compile(modalElementTemplate);
        modalElement = linkFn(modalScope);
        inputs.$element = modalElement;

        // Create the controller, explicitly specifying the scope to use.
        modalController = $controller(controllerName, inputs);

        // Finally, append the modal to the dom
        if (options.appendElement) {
          // Append to custom append element
          // eslint-disable-next-line
          appendElement = document.getElementById(options.appendElement);
          $animate.enter(modalElement, angular.element(appendElement));
        } else {
          // Append to default when no custom append element is specified
          // eslint-disable-next-line
          appendElement = document.getElementById(modalSvcConfig.rootEl);
          $animate.enter(modalElement, angular.element(appendElement));
        }
        // Remove scroll from body when modal is active
        // eslint-disable-next-line
        angular.element(document.body).addClass('scroll-hidden');
        // We now have a modal object
        modal = {
          controller: modalController,
          scope: modalScope,
          element: modalElement,
          close: closeDeferred.promise
        };

        closeModal = function (e) {
          if (e.which === 27 && modal && modal.scope) {
            if (modal.scope.$ctrl) modal.scope.$ctrl.close();
            else if (modal.scope.model) modal.scope.model.close();
            else modal.scope.close();
          }
        };

        // Close on ESC key
        angular.element(document).on('keydown', closeModal);
        activeModals[options.id] = modal;
        // Modal object is passed to the caller via the promise.
        deferred.resolve(modal);
      }).then(null, function (error) {
        // Catch doesn't work in IE8
        deferred.reject(error);
      });

      return deferred.promise;
    };

    self.getModal = function (id) {
      return activeModals[id];
    };

    self.isModalActive = function () {
      return Object.keys(activeModals).length > 0;
    };

    self.getActiveModalId = function () {
      return Object.keys(activeModals)[0];
    };

    self.closeModalById = function (id) {
      const modal = this.getModal(id);
      if (modal && modal.scope) {
        modal.scope.close();
      }
    };
  };

  return new Service();
});
