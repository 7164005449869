<script setup lang="ts">
import { storeToRefs } from 'pinia';
import {
  SCButton,
  SCInlineNotification,
  NotificationVariant,
  ButtonVariant,
} from '@nsftx/seven-components';
import TModal from '@/common/components/TModal.vue';
import i18n from '@/plugins/i18n';
import logService from '@/common/services/logger/logService';
import { useIntelligentGamingStore } from '../store';
import { checkProvinceForBetshop } from '../provinceService';

const { t } = i18n.global;

const { isProvinceModalShown } = storeToRefs(useIntelligentGamingStore());

const handleTryAgain = () => {
  checkProvinceForBetshop();
  logService.info('[IntelligentGaming] - Province not found, try again clicked', {
    code: 'T_IG_PROVINCE_MODAL_CHECK_PROVINCE',
  });
};
</script>

<template>
  <TModal
    v-model="isProvinceModalShown"
    modal-id="province-not-set-modal"
    prevent-close
  >
    <template #title>
      {{ t('ig_province_modal_title') }}
    </template>
    <template #content>
      <SCInlineNotification
        v-model="isProvinceModalShown"
        block
        :messages="[t('ig_province_modal_notification_message')]"
        :variant="NotificationVariant.Error"
      />
    </template>
    <template #actions>
      <SCButton
        :variant="ButtonVariant.Primary"
        width="100%"
        @click="handleTryAgain"
      >
        {{ t('ig_province_modal_submit') }}
      </SCButton>
    </template>
  </TModal>
</template>
