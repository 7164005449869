import { defineStore } from 'pinia';
import { ref } from 'vue';

const useHeaderMenuStore = defineStore('headerMenu', () => {
  // data
  const previousTabIndex = ref<number | undefined>(undefined);
  const selectedTabIndex = ref<number | undefined>(undefined);

  // setters
  const setPreviousTabIndex = () => {
    previousTabIndex.value = selectedTabIndex.value;
  };
  const setCurrentTabIndex = (index?: number) => {
    selectedTabIndex.value = index;
  };

  // methods
  const resolvePreviousTabIndex = () => {
    selectedTabIndex.value = previousTabIndex.value;
  };

  return {
    selectedTabIndex,
    setPreviousTabIndex,
    setCurrentTabIndex,
    resolvePreviousTabIndex,
  };
});

export {
  useHeaderMenuStore,
};
