import axios, { AxiosError } from 'axios';
import * as tokenInterceptor from '@/modules/interceptors/tokenInterceptor';
import * as httpInterceptor from '@/modules/interceptors/httpInterceptor';
import * as sevenInterceptor from '@/modules/interceptors/sevenInterceptor';
import * as cashoutInterceptor from '@/modules/tickets/interceptors/cashoutInterceptor';

const axiosInstance = axios.create({
  timeout: 5000,
});
const defaultErrorHandler = (error: AxiosError) => Promise.reject(error);

axiosInstance.interceptors.request.use(sevenInterceptor.requestSuccess, defaultErrorHandler);
axiosInstance.interceptors.request.use(tokenInterceptor.requestSuccess, defaultErrorHandler);
axiosInstance.interceptors.request
  .use(httpInterceptor.requestSuccess, httpInterceptor.requestError);
axiosInstance.interceptors.request.use(cashoutInterceptor.requestSuccess, defaultErrorHandler);

axiosInstance.interceptors.response.use(tokenInterceptor.responseSuccess, defaultErrorHandler);
axiosInstance.interceptors.response
  .use(httpInterceptor.responseSuccess, httpInterceptor.responseError);

export default axiosInstance;
