import templateUrl from './bettingBalls_v6.html';

(function () {
  angular.module('terminalApp.nabLuckySixUi')
    .component('luckySixBettingBallsV6', {
      templateUrl: templateUrl,
      controller: LuckySixBettingBallsController,
      require: {
        bettingAreaComponent: '^luckySixBettingArea'
      }
    });

  function LuckySixBettingBallsController(
    arrayService,
    flashButton,
    SevenGamesSvc
  ) {
    var LuckySix = SevenGamesSvc.getGame('LuckySix');
    var BettingSvc = LuckySix.getBettingSvc();

    this.$onInit = function () {
      this.activeBets = BettingSvc;
      this.futureNmbr = arrayService.getArray(10, true);
      this.ballTd = arrayService.getArray(8, true);
      this.ballTr = arrayService.getArray(6, true);
      flashButton.flash = false;
    };

    /**
         * Sets class for normal bet (ball selected)
         * */

    this.checkBallClass = function (index) {
      var selectedBet;
      var ticketInput;
      // ui-router destroy scope before we leave
      // state so check do we have this property
      if (!BettingSvc.selected) {
        return false;
      }

      selectedBet = BettingSvc.selected.bet;
      ticketInput = BettingSvc.ticketInput;

      return ((selectedBet.clientId < 7 || selectedBet.clientId === 12)
            && (ticketInput.input && ticketInput.input.indexOf(index) > -1));
    };
  }
})();
