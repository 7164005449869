import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import PubSub from 'pubsub-js';
import i18n from '@/plugins/i18n';
import VueHooksManager from '@/common/services/HooksManager';
import { gravityNotificationsApiService } from '@/modules/seven-gravity-notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { logService } from '@/common/services/logger';
import { useSevenStore } from '@/modules/seven';
import {
  type AcceptorHookParam, acceptorsService, AcceptorAction,
} from '@/modules/acceptors';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import { errorParser } from '@/common/services/error-parser';
import { getService } from '@/common/services/ngBridge';
import { googleAnalyticsService } from '@/modules/google-analytics';
import { usePrinterStatusStore } from './printerStatusStore';
import { usePrinterStore } from './printerStore';
import { PrinterPaperStatusChangeAction } from './enums';
import { PrinterStatus } from './interfaces/printerMessageData';

// Constants
const { t } = i18n.global;
const PAPER_LOW_NOTIFICATION_TIME_INTERVAL = 30;
const LOG_PREFIX = '[printerMessagesService]';

// Vars
let isTicketPayinOnPaperLowDisabled: boolean;
let areAcceptorsDisabledOnPaperLow: boolean;

const hasTimeForSendingNotificationPassed = () => {
  const notificationTimer = moment(Number(localStorage.getItem('notification_timer')));
  const currentTime = moment(Date.now());
  const differenceInMinutes = moment.duration(currentTime.diff(notificationTimer)).minutes();

  return differenceInMinutes >= PAPER_LOW_NOTIFICATION_TIME_INTERVAL;
};

const increaseNotificationCounter = () => {
  const notificationCounter = Number(sessionStorage.getItem('notification_counter'));
  sessionStorage.setItem('notification_counter', (notificationCounter + 1).toString());
};

const shouldSendSystemNotification = () => {
  const gravitySettingsStore = useGravitySettingsStore();
  const sendSystemNotificationToShop = gravitySettingsStore.getModuleDataKeyValue('print', 'sendSystemNotificationToShop');

  if (sendSystemNotificationToShop) {
    const notificationCounter = Number(sessionStorage.getItem('notification_counter'));

    if (hasTimeForSendingNotificationPassed() && notificationCounter >= 1) {
      localStorage.setItem('notification_timer', Date.now().toString());
      sessionStorage.setItem('notification_counter', '0');

      return true;
    }

    increaseNotificationCounter();
  }

  return false;
};

const sendPaperLowSystemNotification = () => {
  if (shouldSendSystemNotification()) {
    const sevenStore = useSevenStore();
    logService.debug(`${LOG_PREFIX} Send low paper notification`);

    gravityNotificationsApiService.sendSystemNotification(
      {
        title: `print.paper_low (${sevenStore.device.name})`,
        body: `print.paper_low (${sevenStore.device.name})`,
        type: 'PaperLow',
      },
    ).catch((err: any) => {
      logService.error(`${LOG_PREFIX} Sending paper-low system notification failed.`, {
        upstream_message: err.message || err.response?.statusText || err.status || '',
        upstream_code: err.code || err.response?.status || '',
        code: 'T_SYSTEM_NOTIFICATION_SEND_FAILED',
      });
    });
  }
};

const validatePaperLowOnTicketPayin = (): Promise<void> => {
  const { isStatusPaperLow } = usePrinterStatusStore();

  if (isStatusPaperLow()) {
    return Promise.reject({
      message: t('printer_status_paper_low_ticket_payin_disabled'),
      code: 'T_PAYIN_VALIDATION_PRINT_LOW_PAPER',
    });
  }

  return Promise.resolve();
};

const validatePaperOutOnTicketPayin = (): Promise<void> => {
  const { isStatusPaperOut } = usePrinterStatusStore();

  if (isStatusPaperOut()) {
    return Promise.reject({
      message: t('printer_status_paper_out_ticket_payin_disabled'),
      code: 'T_PAYIN_VALIDATION_PRINT_PAPER_OUT',
    });
  }

  return Promise.resolve();
};

const closeAcceptorsNotifications = () => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('acceptors-start-error-paper-low');
  notificationsStore.closeNotificationWithId('acceptors-start-error-paper-out');
  notificationsStore.closeNotificationWithId('acceptors-start-error-printer-not-selected');
  notificationsStore.closeNotificationWithId('acceptors-start-error-printer-offline');
};

const validatePaperLowOnStartAcceptors = (params: AcceptorHookParam) => {
  closeAcceptorsNotifications();

  const { isStatusPaperLow } = usePrinterStatusStore();

  if (isStatusPaperLow()) {
    params.errorResponses.push({
      id: 'acceptors-start-error-paper-low',
      message: t('printer_status_paper_low_acceptors_start_error'),
      code: 'T_BEFORE_ACCEPTORS_START_PAPER_LOW_STATUS',
    });
  }
};

const validatePaperOutOnStartAcceptors = (params: AcceptorHookParam) => {
  closeAcceptorsNotifications();

  const { isStatusPaperOut } = usePrinterStatusStore();

  if (isStatusPaperOut()) {
    params.errorResponses.push({
      id: 'acceptors-start-error-paper-out',
      message: t('printer_status_paper_out_acceptors_start_error'),
      code: 'T_BEFORE_ACCEPTORS_START_PAPER_OUT_STATUS',
    });
  }
};

const validatePrinterSelectedStateOnStartAcceptors = (params: AcceptorHookParam) => {
  closeAcceptorsNotifications();

  const { selectedPrinter } = usePrinterStore();

  if (!selectedPrinter) {
    params.errorResponses.push({
      id: 'acceptors-start-error-printer-not-selected',
      message: t('printer_status_not_selected_acceptors_start_error'),
      code: 'T_BEFORE_ACCEPTORS_START_PRINTER_NOT_SELECTED',
    });
  }
};

const validatePrinterOfflineStateOnStartAcceptors = (params: AcceptorHookParam) => {
  closeAcceptorsNotifications();

  const { isStatusOffline } = usePrinterStatusStore();

  if (isStatusOffline()) {
    params.errorResponses.push({
      id: 'acceptors-start-error-printer-offline',
      message: t('printer_offline_acceptors_start_error'),
      code: 'T_BEFORE_ACCEPTORS_START_PRINTER_OFFLINE',
    });
  }
};

const handleAcceptorsStateChange = (
  cause: PrinterPaperStatusChangeAction,
  action: AcceptorAction,
) => {
  const acceptorsStateChangeLogMessage = `${LOG_PREFIX} ${action} is called on acceptors initialization finished`;
  const acceptorsStateChangeActionFn = action === AcceptorAction.START
    ? acceptorsService.startAcceptors
    : acceptorsService.stopAcceptors;

  logService.info(
    acceptorsStateChangeLogMessage,
    {
      code: 'T_PRINTER_ACCEPTORS_REQUEST',
      details: {
        action,
        cause: `Printer${cause.charAt(0).toUpperCase() + cause.slice(1)}`,
      },
    },
  );

  acceptorsStateChangeActionFn().catch((error) => {
    logService.warn(`${LOG_PREFIX} Acceptors cannot be stopped.`, {
      ...errorParser.parseUpstream(error),
      code: `T_ACCEPTORS_${action}_ERROR`,
    });
  });
};

const handlePrinterOnAcceptorsStateChange = (
  isStatusActive: boolean,
  cause: PrinterPaperStatusChangeAction,
  notifId: string,
) => {
  const notificationsStore = useNotificationsStore();

  if (isStatusActive) {
    handleAcceptorsStateChange(cause, AcceptorAction.STOP);
  } else {
    notificationsStore.closeNotificationWithId(notifId);
    handleAcceptorsStateChange(cause, AcceptorAction.START);
  }
};

const getIsTicketPayinOnPaperLowDisabled = () => isTicketPayinOnPaperLowDisabled;

const getPaperLowStatusNotificationMessage = () => {
  let translationKey = '';

  if (areAcceptorsDisabledOnPaperLow && isTicketPayinOnPaperLowDisabled) {
    translationKey = 'printer_status_paper_low_acceptors_ticket_payin_disabled';
  } else if (areAcceptorsDisabledOnPaperLow) {
    translationKey = 'printer_status_paper_low_acceptors_disabled';
  } else if (isTicketPayinOnPaperLowDisabled) {
    translationKey = 'printer_status_paper_low_ticket_payin_disabled';
  }

  return t(translationKey);
};

const handlePaperLowNotification = () => {
  if (!areAcceptorsDisabledOnPaperLow && !isTicketPayinOnPaperLowDisabled) {
    return;
  }

  const notificationsStore = useNotificationsStore();
  const { isStatusPaperLow } = usePrinterStatusStore();

  if (isStatusPaperLow()) {
    notificationsStore.show({
      id: 'printer_status_paper_low',
      message: getPaperLowStatusNotificationMessage(),
      type: TNotificationTypeEnum.error,
      closeDisabled: true,
    });
  } else {
    notificationsStore.closeNotificationWithId('printer_status_paper_low');
  }
};

const onPaperLowStatusChange = () => {
  const { isStatusPaperLow } = usePrinterStatusStore();
  const shouldStopAcceptorsOnPaperLow = areAcceptorsDisabledOnPaperLow && isStatusPaperLow();

  handlePrinterOnAcceptorsStateChange(
    shouldStopAcceptorsOnPaperLow,
    PrinterPaperStatusChangeAction.PaperLow,
    'acceptors-start-error-paper-low',
  );
  handlePaperLowNotification();
  sendPaperLowSystemNotification();
};

const onPaperOutStatusChange = () => {
  const { isStatusPaperOut } = usePrinterStatusStore();

  handlePrinterOnAcceptorsStateChange(
    isStatusPaperOut(),
    PrinterPaperStatusChangeAction.PaperOut,
    'acceptors-start-error-paper-out',
  );
};

const onOfflineStatusChange = () => {
  const { isStatusOffline } = usePrinterStatusStore();

  handlePrinterOnAcceptorsStateChange(
    isStatusOffline(),
    PrinterPaperStatusChangeAction.Offline,
    'acceptors-start-error-printer-offline',
  );
};

const getChangedPrinterStatusAction = (
  newPrinterStatusDataValue: PrinterStatus,
  oldPrinterStatusDataValue: PrinterStatus,
): PrinterPaperStatusChangeAction | undefined => {
  if (
    newPrinterStatusDataValue.valid !== oldPrinterStatusDataValue.valid
    && !newPrinterStatusDataValue.paperLow
    && !newPrinterStatusDataValue.paperOut
  ) {
    return PrinterPaperStatusChangeAction.Offline;
  }

  if (newPrinterStatusDataValue.paperLow !== oldPrinterStatusDataValue.paperLow) {
    return PrinterPaperStatusChangeAction.PaperLow;
  }

  if (newPrinterStatusDataValue.paperOut !== oldPrinterStatusDataValue.paperOut) {
    return PrinterPaperStatusChangeAction.PaperOut;
  }

  return undefined;
};

const onPrinterPaperStatusChange = () => {
  const printerStatusStore = usePrinterStatusStore();

  watch(
    () => printerStatusStore.printerStatusData,
    (newPrinterStatusData, oldPrinterStatusData) => {
      const changedPrinterStatusAction = getChangedPrinterStatusAction(
        newPrinterStatusData,
        oldPrinterStatusData,
      );
      logService.debug(`${LOG_PREFIX} Printer status is changed, try to call status action handler`, {
        code: 'T_PRINTER_STATUS_CHANGE_ACTION',
        details: {
          oldPrinterStatusData,
          newPrinterStatusData,
          changedPrinterStatusAction,
        },
      });

      switch (changedPrinterStatusAction) {
        case PrinterPaperStatusChangeAction.PaperLow:
          onPaperLowStatusChange();
          break;

        case PrinterPaperStatusChangeAction.PaperOut:
          onPaperOutStatusChange();
          break;

        case PrinterPaperStatusChangeAction.Offline:
          onOfflineStatusChange();
          break;

        default:
          break;
      }
    },
  );
};

const limitPaperLowMessages = () => {
  const gravitySettingsStore = useGravitySettingsStore();
  const sendSystemNotificationToShop = gravitySettingsStore.getModuleDataKeyValue('print', 'sendSystemNotificationToShop');

  if (sendSystemNotificationToShop) {
    const notificationTimer = localStorage.getItem('notification_timer');

    if (!notificationTimer) {
      localStorage.setItem('notification_timer', Date.now().toString());
    }

    const notificationCounter = sessionStorage.getItem('notification_counter');

    if (!notificationCounter) {
      sessionStorage.setItem('notification_counter', '0');
    }
  }
};

const registerListeners = () => {
  PubSub.subscribe('7T:User.AuthorizationChanged', closeAcceptorsNotifications);
};

const registerHooks = () => {
  const NgHooksManager = getService<{ getHook: Function }>('HooksManager');

  // register paper low BeforeTicketPayin hook only if it's configured per tenant
  if (isTicketPayinOnPaperLowDisabled) {
    NgHooksManager?.getHook('BeforeTicketPayin').tapPromise({
      name: 'BeforeTicketPayin.printerMessagesService.PaperLow',
    }, validatePaperLowOnTicketPayin);
  }

  // register paper low BeforeAcceptorsStart hook only if it's configured per tenant
  if (areAcceptorsDisabledOnPaperLow) {
    VueHooksManager.BeforeAcceptorsStart.tap({
      name: 'BeforeAcceptorsStart.printerMessagesService.PaperLow',
    }, validatePaperLowOnStartAcceptors);
  }

  NgHooksManager?.getHook('BeforeTicketPayin').tapPromise({
    name: 'BeforeTicketPayin.printerMessagesService.PaperOut',
  }, validatePaperOutOnTicketPayin);

  VueHooksManager.BeforeAcceptorsStart.tap({
    name: 'BeforeAcceptorsStart.printerMessagesService.PaperOut',
  }, validatePaperOutOnStartAcceptors);

  VueHooksManager.BeforeAcceptorsStart.tap({
    name: 'BeforeAcceptorsStart.printerMessagesService.PrinterSelectedState',
  }, validatePrinterSelectedStateOnStartAcceptors);

  VueHooksManager.BeforeAcceptorsStart.tap({
    name: 'BeforeAcceptorsStart.printerMessagesService.PrinterOfflineState',
  }, validatePrinterOfflineStateOnStartAcceptors);
};

const handlePaperWarningAndErrorStatusMessages = () => {
  const { data: { printerPaperLowEffects } } = useConfigSettingsStore();
  isTicketPayinOnPaperLowDisabled = printerPaperLowEffects.disableTicketPayin;
  areAcceptorsDisabledOnPaperLow = printerPaperLowEffects.disableMoneyAcceptance;

  registerListeners();
  registerHooks();
  limitPaperLowMessages();
  onPrinterPaperStatusChange();
};

const trackEventsByPrinterStatus = () => {
  const { isStatusPaperLow, isStatusPaperOut } = usePrinterStatusStore();

  if (isStatusPaperLow()) {
    googleAnalyticsService.trackEvent('Paper_Event', {
      event: 'Paper low',
    });
  }

  if (isStatusPaperOut()) {
    googleAnalyticsService.trackEvent('Paper_Event', {
      event: 'Paper out',
    });
  }
};

const handlePrinterStatusChange = () => {
  const { printerStatusData } = storeToRefs(usePrinterStatusStore());

  watch(() => printerStatusData.value, () => {
    trackEventsByPrinterStatus();
  });
};

const handlePrinterErrorStatus = () => {
  const { printerStatusData, isStatusPaperOut } = usePrinterStatusStore();
  const { getByKey } = useGravitySettingsStore();
  const notificationsStore = useNotificationsStore();

  const { printerWarningUiBlocking } = getByKey('config') || {};
  const printerStatusMessage = printerStatusData.message ? `${printerStatusData.message}.` : '';
  const message = `${printerStatusMessage} ${t('notifications.printer_money_actions_blocked')}`;
  const notificationConfig = {
    id: 'printer_error',
    type: TNotificationTypeEnum.error,
    uiBlocking: false,
    closeDisabled: false,
    delay: null,
    message,
  };

  if (printerWarningUiBlocking && isStatusPaperOut()) {
    notificationConfig.message = t('notifications.printer_paper_error');
    notificationConfig.uiBlocking = true;
    notificationConfig.closeDisabled = true;
  }

  notificationsStore.show(notificationConfig);
};

export {
  sendPaperLowSystemNotification,
  handlePaperWarningAndErrorStatusMessages,
  getIsTicketPayinOnPaperLowDisabled,
  handlePrinterStatusChange,
  handlePrinterErrorStatus,
  onPrinterPaperStatusChange,
  onPaperLowStatusChange,
  onPaperOutStatusChange,
  validatePaperLowOnStartAcceptors,
  registerHooks,
  validatePaperLowOnTicketPayin,
  validatePaperOutOnTicketPayin,
  validatePaperOutOnStartAcceptors,
  handleAcceptorsStateChange,
  getChangedPrinterStatusAction,
};
