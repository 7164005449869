import angular from 'angular';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import coreModule from '../core';
import sevenTicketCenterModule from '../modules/ticket_center';
import gamesModule from '../games';
import integratorModule from '../modules/integrator';
import commonModule from '../common';

/**
  * @ngdoc overview
  * @name 7Terminal.Tickets
  *
  * @description
  * This module handles tickets paying,cancel and payout actions.
  * It is also used to check state of ticket on Seven Platform.
  *
  *
*/
angular.module('7Terminal.Tickets', [
  'SevenClient.Config',
  sevenTicketCenterModule,
  coreModule,
  gamesModule,
  integratorModule,
  commonModule
]);
