(function () {
  angular.module('7Terminal.Core')
    .provider('BootStrapData', BootStrapDataProvider);

  /**
     * @typedef {Object} LoginData
     *
     * @property {Object} appConfig
     * @property {Object} appRoutes
     * @property {Object} betshop
     * @property {Object} betshopGroup
     * @property {Array} clientAppModules
     * @property {Object} cloudMessaging
     * @property {Object} company
     * @property {Object} configRoutes
     * @property {Object} device
     * @property {Object} ticketActions
     * @property {Object} user
     * @property {String} userType
     */

  /**
     *
     * @constructor BootStrapDataProvider
     * @augments LoginData
     */
  function BootStrapDataProvider() {
    var self = this;
    this.setData = function (data) {
      angular.extend(self, data);
    };

    /**
       *
       * @returns {LoginData}
       */
    this.$get = function () {
      return self;
    };
  }
})();
