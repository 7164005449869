angular.module('7Terminal.Common')
  .service('systemCombinationsService', function systemCombinationsService() {
    var combinations = {};

    /**
     * @param n - number of played bets in system
     * @param k - number of bets in regular bet
     * @returns {number} - number of possible combinations
     */
    var calculateCombinations = function (n, k) {
      var regular = k;
      var system = n;

      var j = 1;
      var res = 1;

      if (k < 0 || k > n) {
        return 0;
      }

      if ((n - k) < k) {
        k = n - k;
      }

      while (j <= k) {
      // eslint-disable-next-line
      res *= n--;
        // eslint-disable-next-line
      res /= j++;
      }

      combinations[regular + '/' + system] = res;
      return res;
    };

    return {
      getCombNumber: function (regular, system) {
        if (combinations[regular + '/' + system]) {
          return combinations[regular + '/' + system];
        }
        return calculateCombinations(system, regular);
      }
    };
  });
