import { SevenApiRoutes } from '@/modules/seven';
import axiosInstance from './axiosInstance';
import type { TboShiftLogResponse, TboShiftDetails } from './types';

/**
 * Gets 10 last shift logs
 */
const getShiftLog = (requestUuid: string) => axiosInstance.get<TboShiftLogResponse>(`${SevenApiRoutes.routes.tboMainUrl}shift/log`, {
  params: {
    requestUuid,
  },
});

const getShiftStatusByCashRegisterUuid = (params: {
  cashRegisterUuid: string,
  requestUuid: string,
}) => axiosInstance.get<TboShiftDetails>(`${SevenApiRoutes.routes.tboMainUrl}shift/status?cashRegisterUuid`, {
  params,
});

export {
  getShiftLog,
  getShiftStatusByCashRegisterUuid,
};
