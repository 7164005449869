import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-100 sc-pb-4" }
const _hoisted_2 = { class: "sc-text-paragraph" }
const _hoisted_3 = { class: "w-100 d-flex" }
const _hoisted_4 = { class: "w-50 sc-pr-3" }
const _hoisted_5 = { class: "w-50" }

import {
  ref, computed, toRefs, onUnmounted, onMounted,
} from 'vue';
import {
  SCInput,
  SCButton,
  SCKeyboard,
  ButtonVariant,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import TModal from '@/common/components/TModal.vue';
import { closeTicketCheckModalsEventPubSub, CLOSE_TICKET_CHECK_MODALS_ID, TicketCheckAbortedError } from '@/modules/ticket-check';
import { usePinValidationStore } from './pinValidationStore';

const LOG_PREFIX = '[SevenSecurityPin]';

export default /*@__PURE__*/_defineComponent({
  __name: 'SevenSecurityPin',
  setup(__props) {

const { t } = i18n.global;
const pinValidationStore = usePinValidationStore();
const { showSecurityPinModal } = toRefs(pinValidationStore);
const isKeyboardShown = ref(false);
const enteredPin = ref('');
const pinChanged = ref(false);
const showRequiredError = computed(() => !enteredPin.value && pinChanged.value);

const onUpdateSecurityPin = () => {
  pinChanged.value = true;
};

const onCancel = () => {
  logService.info(`${LOG_PREFIX} Security Pin modal has been closed by player`, {
    code: 'T_SECURITY_PIN_CLOSED_BY_PLAYER',
  });
  pinValidationStore.rejectSecurityPin({
    message: 'Security Pin enter aborted',
    code: 'T_SECURITY_PIN_ENTER_ABORTED_BY_USER',
    delay: 3000,
  });

  showSecurityPinModal.value = false;
  enteredPin.value = '';
  pinChanged.value = false;
};

const onSend = () => {
  if (enteredPin.value) {
    pinValidationStore.resolveSecurityPin(enteredPin.value);
  } else {
    pinValidationStore.rejectSecurityPin({
      message: 'Empty security pin field',
      code: 'T_SECURITY_PIN_NOT_ENTERED',
      delay: 3000,
    });
  }

  showSecurityPinModal.value = false;
  enteredPin.value = '';
  pinChanged.value = false;
};

let closeModalsSubscriptionId = '';

onMounted(() => {
  closeModalsSubscriptionId = closeTicketCheckModalsEventPubSub
    .subscribe(CLOSE_TICKET_CHECK_MODALS_ID, (msg, data) => {
      if (showSecurityPinModal.value) {
        logService.info(`${LOG_PREFIX} Closing security pin modal.`, {
          code: 'T_SECURITY_PIN_CLOSE_MODAL_EVENT',
          upstream_message: data?.reason,
        });
        const error = new TicketCheckAbortedError(`Security Pin modal has been closed, ${data?.reason}`, 'T_SECURITY_PIN_CLOSE_MODAL_EVENT');
        pinValidationStore.rejectSecurityPin(error);

        showSecurityPinModal.value = false;
        enteredPin.value = '';
        pinChanged.value = false;
      }
    });
});

onUnmounted(() => {
  if (closeModalsSubscriptionId) {
    closeTicketCheckModalsEventPubSub.unsubscribe(closeModalsSubscriptionId);
    closeModalsSubscriptionId = '';
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TModal, {
    modelValue: _unref(showSecurityPinModal),
    "onUpdate:modelValue": [
      _cache[3] || (_cache[3] = ($event: any) => (_isRef(showSecurityPinModal) ? (showSecurityPinModal).value = $event : null)),
      onCancel
    ],
    "modal-id": "security-pin",
    width: "30rem",
    "with-close-icon": ""
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('seven_security_pin_title')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('seven_security_pin_subtitle')), 1 /* TEXT */),
        _createVNode(_unref(SCKeyboard), {
          show: isKeyboardShown.value,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((isKeyboardShown).value = $event)),
          modelValue: enteredPin.value,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((enteredPin).value = $event)),
            onUpdateSecurityPin
          ],
          numeric: "",
          selector: "keyboard-ticket-pin-input",
          class: "w-100 sc-p-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SCInput), {
              modelValue: enteredPin.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((enteredPin).value = $event)),
                onUpdateSecurityPin
              ],
              class: "sc-pb-9",
              error: showRequiredError.value,
              "error-message": _unref(t)('seven_security_pin_not_entered'),
              label: _unref(t)('seven_security_pin_enter'),
              block: "",
              "data-testid": "pin"
            }, null, 8 /* PROPS */, ["modelValue", "error", "error-message", "label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["show", "modelValue"])
      ])
    ]),
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(SCButton), {
            width: "100%",
            "data-testid": "cancel",
            onClick: onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('actions.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(SCButton), {
            variant: _unref(ButtonVariant).Primary,
            width: "100%",
            disabled: !enteredPin.value,
            "data-testid": "send",
            onClick: onSend
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('actions.send')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["variant", "disabled"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})