<script setup lang="ts">
import {
  ref, computed, onMounted,
} from 'vue';
import { storeToRefs } from 'pinia';
import PubSub from 'pubsub-js';
import { SCTabs, SCTab, SCHorizontalScroll } from '@nsftx/seven-components';
import router from '@/router';
import { getHeaderMenuList, type MenuItem } from '@/common/services/menu';
import { getService } from '@/common/services/ngBridge';
import { useHeaderMenuStore } from '@/common/stores/header-menu';
import { vGaTrackEvent } from '@/modules/google-analytics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const switchView: any = getService('switchView');
// data
const headerMenuStore = useHeaderMenuStore();
const { selectedTabIndex } = storeToRefs(headerMenuStore);
const activeState = ref('');
const menus = ref<MenuItem[]>([]);
const showMenu = computed(() => activeState.value !== '/home' && !activeState.value.includes('/lobby/'));

// lifecycle hooks
onMounted(() => {
  menus.value = getHeaderMenuList();
});

// subscriptions
PubSub.subscribe('app:configFetchingSuccess', () => {
  menus.value = getHeaderMenuList();
});

router.beforeEach((route) => {
  activeState.value = route.path;
  const index = menus.value.findIndex((el: MenuItem) => (
    el.params?.gameId === route.query?.gameId
    || activeState.value.includes(el.params?.gameId.toLowerCase() || '')
  ));

  headerMenuStore.setPreviousTabIndex();

  // In header menu there can be only links to games and ticket check routes
  headerMenuStore.setCurrentTabIndex(
    index !== -1 && (route.path === '/games' || route.path === '/results/ticketCheck')
      ? index
      : undefined,
  );
});

// methods
const onTabSelectionChanged = (selectedMenuIndex: number) => {
  const selectedMenu = menus.value[selectedMenuIndex];

  if (selectedMenu.type !== 'group') {
    switchView.selectService(
      selectedMenu.route,
      selectedMenu.type === 'product',
      selectedMenu.params,
    );
  }

  headerMenuStore.setCurrentTabIndex(selectedMenuIndex);
};
</script>

<template>
  <div
    v-show="showMenu"
    class="h-100 w-100"
  >
    <SCHorizontalScroll>
      <SCTabs
        :model-value="selectedTabIndex"
        variant="secondary"
      >
        <SCTab
          v-for="(menu, index) in menus"
          :key="index"
          v-ga-track-event="{
            eventName: 'Open_Module',
            eventParams: { module: menu.item.url, source: 'Header' },
          }"
          @click="onTabSelectionChanged(index)"
        >
          {{ menu.translation }}
        </SCTab>
      </SCTabs>
    </SCHorizontalScroll>
  </div>
</template>
