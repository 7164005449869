import moduleName from './app';
import './directives/animateClick/animateClickDirective';
import './directives/animateBtnFlash/btnFlashService';
import './directives/cursorSettings/cursorSettingsDirective';
import './directives/customScroll/scrollDirective';
import './directives/datepicker/datepickerDirective';
import './directives/datepicker/datepickerRestoreValueDirective';
import './directives/resolutionMode/resolutionModeDirective';
import './directives/numPad/numPadDirective';
import './directives/preventDefault';
import './filters/capitalizeFilter';
import './filters/dateToISOFilter';
import './filters/orConditionFilter';
import './filters/replaceFilter';
import './gamesScheduleTab';
import './hooks/hooksManagerService';
import './req_loading/loaderReqDirective';
import './services/modal/modalService';
import './services/modal/modalServiceProvider';
import './services/translation/transBootstrap';
import './services/translation/transService';
import './services/createArrayService';
import './services/payinAmountService';
import './services/randomService';
import './services/systemCombinationsService';
import './services/routesServiceProvider';
import './vgTabs/tabs';

export default moduleName;
