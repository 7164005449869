import templateUrl from './firstBallColorPopUp.html';

(function () {
  angular
    .module('terminalApp.nabLuckySixUi')
    .component('luckySixUiFirstBallColor', {
      require: {
        luckySixBettingArea: '^luckySixBettingArea'
      },
      templateUrl: templateUrl,
      controller: function (popupService) {
        var ctrl = this;

        ctrl.close = function () {
          ctrl.luckySixBettingArea.clearAll();
          popupService.closePopup('luckySixUiFirstBallColor');
        };

        ctrl.accept = function () {
          if (ctrl.luckySixBettingArea.activeBets.ticketInput.input.length < 1) {
            ctrl.luckySixBettingArea.clearAll();
          }
          popupService.closePopup('luckySixUiFirstBallColor');
        };
      }
    });
})();
