import BaseError from '@/common/errors/BaseError';
import { type DmConfigDataErrorType } from '../deviceManagementTypes';

export default class DmConfigDataError extends BaseError<DmConfigDataErrorType> {
  constructor(
    public message: string,
    public code: DmConfigDataErrorType,
    public options?: {
      cause?: unknown;
    },
  ) {
    super(message, code, options);
    Object.setPrototypeOf(this, DmConfigDataError.prototype);
    this.name = this.constructor.name;
  }
}
