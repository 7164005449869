import { createI18n } from 'vue-i18n';
import { nextTick, isRef, App } from 'vue';
import enUS from '../locales/en-US.json';

type MessageSchema = typeof enUS;

const i18n = createI18n<[MessageSchema], 'en-US'>({
  legacy: false, // use Composition API
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS,
  },
});

const setI18nLanguage = (locale: string) => {
  if (isRef(i18n.global.locale)) {
    i18n.global.locale.value = locale;
  }
};

export function setTranslations(translations: MessageSchema, locale = 'en') {
  const data = { ...translations };
  type T = keyof typeof translations;
  Object.keys(data).forEach((key) => {
    data[key as T] = data[key as T].replaceAll('{{', '{').replaceAll('}}', '}');
  });

  i18n.global.setLocaleMessage(locale, data);
  setI18nLanguage(locale);
  return nextTick();
}

const { t } = i18n.global;

const translationPlugin = {
  install: (app: App) => {
    const translate = (key: string) => t(key);
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$t = translate;
    app.provide('$t', translate);
  },
};

export { translationPlugin };
export type { MessageSchema };
export default i18n;
