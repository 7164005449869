<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import i18n from '@/plugins/i18n';
import router from '@/router';
import { SCButton } from '@nsftx/seven-components';
import { getService } from '@/common/services/ngBridge';
import { useAuthStore, authService } from '@/modules/auth';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import AuthModalsHandler from '@/modules/auth/components/AuthModalsHandler.vue';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import { vGaTrackEvent } from '@/modules/google-analytics';

// Constants
const gravitySettingsStore = useGravitySettingsStore();
const isHelpActionHidden = gravitySettingsStore.getModuleDataKeyValue('config', 'helpSectionHidden');
const { t } = i18n.global;
const activeState = ref('home');
const authStore = useAuthStore();
const { isLoginModalOpened } = storeToRefs(authStore);

// Vars
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let $state: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let switchView: any;

router.beforeEach((route) => {
  activeState.value = route.path;
});

const showLoginModal = () => {
  authService.validateLogin().then(() => {
    isLoginModalOpened.value = true;
  }).catch(() => {});
};

const openTermsAndConditions = () => {
  // if we are already on termsAndConditions return to previous state
  if ($state.includes('app.termsandconditions')) {
    switchView.resolvePreviousView();
  } else {
    switchView.selectService('termsandconditions', false, false);
  }
};

const openInstructions = () => {
  // if we are already on howToPlay/instructions return to previous state
  if ($state.includes('app.instructions')) {
    switchView.resolvePreviousView();
  } else {
    $state.go('app.instructions');
  }
};

const openResultsAndTicketCheck = () => {
  if ($state.current.name === 'app.results.ticketcheck') {
    $state.go('app.home');
  } else {
    $state.go('app.results.ticketcheck');
  }
};

onMounted(() => {
  $state = getService('$state');
  switchView = getService('switchView');
});

</script>

<template>
  <div class="d-flex flex-row">
    <SCButton
      v-ga-track-event="{
        eventName: 'Open_Module',
        eventParams: { module: 'Login modal', source: 'Footer' },
      }"
      class="sc-my-1"
      icon="french-key"
      :active="isLoginModalOpened"
      width="64px"
      height="60px"
      @click="showLoginModal"
    />

    <SCButton
      v-ga-track-event="{
        eventName: 'Open_Module',
        eventParams: { module: 'Terms and conditions', source: 'Footer' },
      }"
      class="sc-my-1 sc-ml-1"
      icon="eighteen"
      :active="activeState.includes('terms')"
      width="64px"
      height="60px"
      @click="openTermsAndConditions"
    />

    <SCButton
      v-if="!isHelpActionHidden"
      v-ga-track-event="{
        eventName: 'Open_Module',
        eventParams: { module: 'Help', source: 'Footer' },
      }"
      class="sc-my-1 sc-ml-1"
      icon="question"
      :active="activeState.includes('help')"
      width="64px"
      height="60px"
      @click="openInstructions"
    />
    <div class="t-footer-button sc-my-1 sc-ml-1">
      <SCButton
        v-ga-track-event="{
          eventName: 'Open_Module',
          eventParams: { module: 'Results', source: 'Footer' },
        }"
        width="100%"
        prepend-icon="live-betting"
        :active="activeState.includes('results')"
        height="60px"
        @click="openResultsAndTicketCheck"
      >
        <span class="sc-ml-3 footer-button-label">
          {{ t('common_results_ticket_check') }}
        </span>
      </SCButton>
    </div>

    <AuthModalsHandler />
    <WidgetsRenderer position="footerLeft" />
  </div>
</template>
