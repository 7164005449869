<template>
  <div class="modal-container">
    <SCModal
      :id="modalId"
      :model-value="modelValue"
      :with-close-icon="props.withCloseIcon"
      :width="width"
      :top="top"
      :left="left"
      @update:model-value="onClose"
    >
      <template #title>
        <slot name="title" />
      </template>
      <template #content>
        <slot name="content" />
      </template>
      <template #actions>
        <slot name="actions" />
      </template>
    </SCModal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, toRefs } from 'vue';
import { SCModal } from '@nsftx/seven-components';
import { useModalsStore } from '@/common/stores/modals/index';

const modalsStore = useModalsStore();

const props = defineProps({
  modalId: { type: String, required: true },
  withCloseIcon: { type: Boolean, required: false, default: false },
  modelValue: { type: Boolean, required: true, default: false },
  width: { type: String, required: false, default: undefined },
  top: { type: String, required: false, default: undefined },
  left: { type: String, required: false, default: undefined },
  preventClose: { type: Boolean, required: false, default: false },
});
const {
  modelValue,
  width,
  top,
  left,
  preventClose,
  modalId,
} = toRefs(props);

const emit = defineEmits<{
  (e: 'update:modelValue', modalValue: boolean): void
}>();

const onClose = () => {
  if (!preventClose.value) {
    emit('update:modelValue', false);
  }
};

onMounted(() => {
  modalsStore.registerModal(props.modalId, modelValue, emit);
});
</script>
