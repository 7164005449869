import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';

/* @ngInject */
function installationService(
  $log,
  $rootScope,
  nabMessaging,
  errorParser
) {
  var self = this;

  function removeInstallationData() {
    var installer = self.getInstaller();
    installer.uninstall().catch(function (err) {
      $log.error('[7Terminal.Installation] Device data not removed', {
        code: 'T_INSTALLATION_DEVICE_NOT_REMOVED',
        ...errorParser.parseUpstream(err)
      });
    });
  }

  // on token invalidation remove device data
  nabMessaging.subscribe('device:tokenInvalidated', removeInstallationData);
  // on device uninstall remove device data
  nabMessaging.subscribe('NCM:uninstall', removeInstallationData);
  nabMessaging.subscribe('NCM:device', function (event, data) {
    data.forEach(function (deviceData) {
      if (deviceData.type === 'deviceStatus' && !deviceData.data.installed) {
        removeInstallationData();
      }
    });
  });
  // if any request from platform has code 17006 do removal of device data
  $rootScope.$on('$http:unauthorizedRequest', function (event, data) {
    // Invalidated token
    if (data.code === 17006) {
      removeInstallationData();
    }
  });

  this.getInstaller = function () {
    const environmentHandlerStore = useEnvironmentHandlerStore();
    const runtime = environmentHandlerStore.getApplicationRuntime();
    return runtime.service;
  };
}

export default installationService;
