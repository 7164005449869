import Cookies from 'js-cookie';
import { find, each } from 'underscore';
import { logService } from '@/common/services/logger';

(function () {
  angular.module('7Terminal.Core')
    .provider('Environment', EnvironmentProvider);

  function EnvironmentProvider($windowProvider) {
    var self = this;
    var states = {
      touch: false
    };
    var $ngWindow = $windowProvider.$get();
    var currentLogLevel = 5;
    var LOG_LEVELS = {
      error: 0,
      warn: 1,
      info: 2,
      log: 4,
      debug: 5
    };
    // this is maximum cookie expire time (~80 years)
    const COOKIE_EXPIRE_TIME = 2147483647;
    this.dc = null;

    function getUrlParameter(name) {
      var regex;
      var results;
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      results = regex.exec($ngWindow.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    function onRightClick(ev) {
      ev.preventDefault();
    }

    this.getUrlParameter = getUrlParameter;

    this.setState = function (state, value) {
      states[state] = value;
    };

    this.getState = function (state) {
      return states[state];
    };

    /**
     * Detect log level from query param or local storage.
     * logLevel as param will be applied only if locat storage or query param value is not set.
     * @param {String} [logLevel]
     * @returns {String}
     */
    this.detectLogLevel = function (logLevel) {
      const debugModeParam = getUrlParameter('debugMode');
      var ls = localStorage.getItem('settings.logLevel');
      var qp = this.getUrlParameter('logLevel');

      if (debugModeParam) {
        return 'debug';
      }

      if (ls === null && (qp === 'false' || qp === '' || qp === 'undefined')) {
        return logLevel;
      }
      return ls || qp;
    };

    /**
     * Set log level. By defualt it is set to error level.
     *
     * @param {String} logLevel - see LOG_LEVELS
     */
    this.setLogLevel = function (logLevel) {
      currentLogLevel = this.getLogLevel(logLevel);
      if (currentLogLevel < 5) {
        // if in not debug mode prevent right click (blocks inspect mode)
        $ngWindow.addEventListener('contextmenu', onRightClick);
      } else {
        $ngWindow.removeEventListener('contextmenu', onRightClick);
      }
      this.setState('LOG_LEVEL', currentLogLevel);
    };

    this.getLogLevel = function (logLevel) {
      return LOG_LEVELS[logLevel] || 0;
    };

    /**
     * Convert log level from number to string
     *
     * @param {Number} logLevel
     * @returns {String}
     */
    this.getLogLevelAsString = function (logLevel) {
      return find(Object.keys(LOG_LEVELS), function (key) {
        return LOG_LEVELS[key] === logLevel;
      });
    };

    this.$get = function ($window, $injector, GravitySettings) {
      'ngInject';

      /**
       * Set app channel
       * @param {string} betshopId
       * @param {string} deviceId
       * @returns {Boolean} - It will return true if app channel is changed
       */
      this.setChannel = function (betshopId, deviceId) {
        const noredirect = localStorage.getItem('settings.noredirect') !== null || getUrlParameter('noredirect');
        const { hostname } = $window.location;
        // works only with seven-terminal.7platform.net or seven-terminal.nsoft.app
        const domain = hostname.split('.').reverse().slice(0, 3).reverse()
          .join('.');
        var availableChannels = GravitySettings.getByKey('channels');
        var channelConfig = GravitySettings.getByKey('channel');
        var channelName = channelConfig.default;
        var overrideChannel = false;
        var foundChannel;

        // don't do any redirects if we are on localhost
        if (['localhost', '127.0.0.1'].indexOf(hostname) >= 0
          || noredirect
          || logService.isDebugMode()) {
          return false;
        }

        // it is possible to override default channel per betshop or device
        if (channelConfig.override) {
          overrideChannel = find(channelConfig.override, function (override) {
            return override.id === betshopId || override.id === deviceId;
          });

          if (overrideChannel) {
            channelName = overrideChannel.channel;
          }
        }

        // find channel url
        foundChannel = find(availableChannels.list, function (cha) {
          return cha.id === channelName;
        });

        // If current cookie does not have value of the curret channel, lets update it.
        // We will skip setting of channel if we are alredy on that URL (just to prevent unecessery reload)
        // See calling context.
        if (foundChannel && !Cookies.get(`s_channel_${foundChannel.id}`)
          && foundChannel.url.indexOf($window.location.origin) === -1) {
          // first remove any previous channel entires
          each(Cookies.get(), (value, key) => {
            if (key.indexOf('s_channel') !== -1) {
              // remove
              Cookies.remove(key, {
                domain: `.${domain}`
              });
            }
          });
          Cookies.set(`s_channel_${foundChannel.id}`, true, {
            domain: `.${domain}`,
            expires: COOKIE_EXPIRE_TIME
          });
          return true;
        }
        return false;
      };

      angular.extend(this, self);
      return self;
    };
  }
})();
