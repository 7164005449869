import { defineAsyncComponent } from 'vue';
import router from '@/router';

const LobbyItem = defineAsyncComponent(() => import(
  /* webpackChunkName: "lobby_item_component" */
  '@/modules/lobby/views/LobbyItem.vue'
));

export const addRoutes = () => {
  router.addRoute({
    path: '/lobby/:id',
    name: 'LobbyItem',
    component: LobbyItem,
  });
};
