import { each } from 'underscore';
import sevenTax from '@nsftx/seven-gravity-tax-service';
import { useUserFundsStore } from '@/modules/user-funds';

(function () {
  angular.module('7Terminal.Betslip')
    .service('BetslipService', BetslipService);

  /**
     * @constructor BetslipService
     * @memberof module:"7Terminal.Betslip"
     */
  function BetslipService(
    $rootScope,
    $filter,
    $log,
    $translate,
    $q,
    SevenGamesSvc,
    flashButton,
    nabValidator,
    currency,
    nabTrans,
    LimitsService
  ) {
    /**
         *
         * @param {Array | Object}bets
         * @param ticket
         * @param game
         * @param editMode
         * @return {*}
         */
    function validateBet(bets, ticket, game, editMode) {
      var betsCollection = ticket.betsCollection;
      var numOfBetsOnTicket = betsCollection.getBets().length;
      var gameRules = game.getRulesSvc();
      var numberOfNewBets = angular.isArray(bets) ? bets.length : 1;
      var ticketType = ticket.config.ticketType.id;

      // depending on lastFocused value (number, or false) call one of this two functions
      var nextFunction = editMode ? 'editTicket' : 'addToBetslip';

      // in edit mode validation would fail because if max bet is 2, he thinks that we are adding third bet
      var activeBetsLength = nextFunction === 'editTicket' ? numOfBetsOnTicket - 1 : numOfBetsOnTicket + numberOfNewBets;

      return nabValidator.validate(
        // get list of rules to validate
        gameRules.preBetSlip(
          {
            activeBetsLength: activeBetsLength,
            ticketType: ticketType
          }
        ),
        {
          bets: angular.isArray(bets) ? bets : [bets],
          betCount: activeBetsLength,
          ticketType: ticketType,
          currency: currency.value,
          nonBankBetCount: (function () {
            return betsCollection.getNonBankers().length + 1;
          }())
        },
        true,
        // override default rule creator if game luckySix
        game.info.category === 'NGS' ? function (rules) {
          return rules;
        } : false
      );
    }

    return {

      /**
             * Hold instance of Betslip
             * @memberOf BetslipService
             * @type {?Betslip}
             */
      betslip: null,

      /**
             * Hold instance of Ticket
             * @type {?Ticket}
             * @memberOf BetslipService
             */
      ticket: null,

      /**
             * @memberOf BetslipService
             */
      tabs: {
        current: ''
      },

      /**
             * @memberOf BetslipService
             */
      oddsChanged: false,

      /**
             * @memberOf BetslipService
             */
      blockers: [],

      /**
             * Holds betslip area state of each game
             * For now only is it hidden or showed in portrait mode
             * @memberOf BetslipService
             * */
      ticketGroups: {},

      /**
             * @memberOf BetslipService
             */
      getTicket: _7Ticket.ticket,

      /**
             *
             * @param {Object} config
             * @param {String} config.id - unique ticket id
             * @param {Array.<Object>} config.arg
             * @param {Boolean} config.arg.active - Active ticket in group
             * @param {String} config.arg.ticketGroup
             * @param {String} config.arg.betsCollection
             * @param {Object} config.arg.group
             * @param {String} config.arg.group.id
             * @param {String} config.arg.ticketType
             * @param {String} config.arg.oddProp
             * @param {String} config.arg.stake - default stake
             * @param {String} config.arg.stakeTouched
             * @param {String} config.arg.setStakeStrategy
             * @param {String} config.arg.winningStrategy
             * @param {String} config.arg.setFormatPayin
             * @param {String} config.arg.emptyBetslipText
             * @param {Object} config.arg.taxConfig
             *
             * @memberOf BetslipService
             *
             * @return {Ticket}
             */
      createTicket: function (config) {
        // let's register getHook if we have payin tax
        if (config.group.taxesConfig
                    && config.group.taxesConfig.payin
                    && !config.group.taxesConfig.payin.hideTax) {
          config.getStakeHook = function (stake) {
            if (!this.taxes) this.taxes = {};

            this.taxes.payin = sevenTax.calculateTax(
              config.group.taxesConfig.payin.policy,
              stake
            );

            return stake - this.taxes.payin.taxAmountRounded;
          };
        }

        return _7Ticket.createTicket(config);
      },

      /**
             * @memberOf BetslipService
             * @param betslip
             * @return {Object}
             */
      createBetslip: function (betslip) {
        betslip.tickets.forEach(function (ticket) {
          this.createTicket(ticket);
        }.bind(this));

        return _7Ticket.ts().getBetslip(betslip.id);
      },

      /**
             * Set active ticket and activate tab
             *
             * @memberOf BetslipService
             * @param serviceId {String | Boolean} - game client id
             * @param tabName {String | Boolean} - Tab tabName
             */
      activateTicket: function (serviceId, tabName) {
        var newBetslip = _7Ticket.ts().getBetslip(serviceId);

        if (this.betslip && this.betslip.config.id !== newBetslip.config.id) {
          this.betslip = newBetslip;
        } else if (!this.betslip) {
          this.betslip = newBetslip;
        }

        this.ticket = _7Ticket.ts().setActiveTicket(tabName, serviceId);

        // When activating ticket check does ticket supports duplicate bets (like single ticket),
        // if not clear double bets
        if (this.ticket.config.removeDuplicateBets) {
          this._filterDoubleBets();
        }

        // set active tab in view
        this.tabs.current = this.ticket.id;

        // In case there are no bets on ticket
        // send/restart stake to default value (from server config).
        // If there are bets on ticket send stake from previous ticket to
        // activated ticket
        if (this.ticket.getBets().length === 0) {
          this.updateStake(this.ticket.config.stake);
        } else {
          this.updateStake(this.ticket.stake);
        }

        this.setOddChangeValue();

        $rootScope.$broadcast('7T:Betslip.TicketActivated', {
          ticket: this.ticket,
          betslip: this.betslip,
          productId: serviceId
        });
      },

      /**
             * @memberOf BetslipService
             * @param id
             * @param bonusData
             */
      createBonuses: function (id, bonusData) {
        var bonuses = {};
        bonuses[id] = {
          bonuses: bonusData
        };
        _7Ticket.Bonuses.create(bonuses);
      },

      /**
             * Finds all bets with same idMatch
             * and removes all except last one added
             *
             * @memberOf BetslipService
             * @private
             */
      _filterDoubleBets: function () {
        var oldBets = angular.copy(this.ticket.getBets());
        var newBets = [];
        var i = oldBets.length - 1;
        var uniqueKey = this.ticket.config.removeDuplicateBets.uniqueKey;
        var filter = {};
        var found;

        // remove bets from each ticket from each bet group
        this.ticket.betsCollection.clear();

        // now create array of new bets
        for (i; i >= 0; i--) {
          if (i === oldBets.length - 1) {
            newBets.push(oldBets[i]);
          } else {
            filter = {};
            filter[uniqueKey] = oldBets[i][uniqueKey];
            found = $filter('filter')(newBets, filter, true);
            if (!found.length) {
              newBets.push(oldBets[i]);
            }
          }
        }

        // Because array above reversed bets,
        // get them back to starting point
        newBets.reverse();

        // add bulk of bets  to each ticket
        this.addToBetslip(newBets);
      },

      /**
             * Validate bet or array of bets
             *
             * @memberOf BetslipService
             * @param {Array | Object} bet
             * @param editMode
             * @return {*}
             */
      validateBet: function (bet, editMode) {
        var errors = validateBet(
          bet,
          this.ticket,
          SevenGamesSvc.activeGame,
          editMode
        );
        var limitActive = LimitsService.checkLimits(
          this.ticket.config.group.id
        );
        var errorName;
        var transKey;
        var trans;

        if (limitActive) {
          return {
            msg: limitActive
          };
        } if (errors.length) {
          errorName = errors[0].code;
          transKey = errors[0].transKey + errorName;

          trans = nabTrans.translate(
            transKey,
            errors[0].transRef,
            true
          );

          return {
            msg: trans
          };
        }
        return true;
      },

      /**
             * Add bet to betslip and update stake
             *
             * @memberOf BetslipService
             * @param {Bet | Array.<Bet>} bet
             * @param {Number} [stake]
             * @return {Bet} - new bet
             */
      addToBetslip: function (bet, stake) {
        // Use 2 randoms to avoid same number ever re-occuring var random = Math.random()
        var date = Date.now();
        var random = Math.random() * Math.random();
        var i = 0;

        // refresh random otherwise if two bets are sent we won't have unique ids
        if (angular.isArray(bet)) {
          for (i; i < bet.length; i++) {
            // refresh random otherwise if two bets are sent we won't have unique ids
            random = Math.random() * Math.random();
            bet[i].uid = Math.floor(date * random);
          }
        } else {
          bet.uid = Math.floor(date * Math.random() * Math.random());
        }

        this.ticket.addBets(bet);

        this.updateStake(stake);

        // Publish added bet and all bets data
        $rootScope.$emit('7T.Betslip.BetsChanged', {
          type: 'betAdd',
          data: {
            betChanged: angular.isArray(bet) ? bet : [bet],
            allBets: this.ticket ? this.ticket.getBets() : []
          }
        });

        this.ticket.lastFocused = false;

        return bet;
      },

      /**
             * Edit bets on current ticket
             *
             * @memberOf BetslipService
             * @param {Array.<Object>} updatedBet
             * @return {Array.<Object>}
             */
      editTicket: function (updatedBet) {
        this.ticket.updateBets(updatedBet, 'uid');
        this.updateStake();

        this.ticket.bets.forEach(function (bet) {
          bet.focus = false;
        });

        flashButton.flash = false;
        this.ticket.lastFocused = false;

        // Publish edited bet and all bets data
        $rootScope.$emit('7T.Betslip.BetsChanged', {
          type: 'betEdit',
          data: {
            betChanged: [updatedBet],
            allBets: this.ticket ? this.ticket.getBets() : []
          }
        });

        return updatedBet;
      },

      /**
             * Remove bet from betslip
             *
             * @memberOf BetslipService
             * @param uid
             * @param stake
             */
      removeFromBetslip: function (uid, stake) {
        var removedBets = this.ticket.betsCollection.removeBets([uid], 'uid');

        this.updateStake(stake);

        // Publish removed bet and all bets data
        $rootScope.$emit('7T.Betslip.BetsChanged', {
          type: 'betRemove',
          data: {
            betChanged: removedBets || [],
            allBets: this.ticket ? this.ticket.getBets() : [],
            wasSelected: this.ticket.lastFocused === uid
          }
        });

        $rootScope.$emit('7T:Betslip.BetRemoved', {
          productId: this.ticket.config.group.id,
          removedBets: removedBets || [],
          ticket: this.ticket
        });

        if (this.ticket.lastFocused === uid) {
          this.ticket.lastFocused = false;
        }

        this.setOddChangeValue();

        // If last bet is removed, toggle betslip when in portrait mode
        if (!this.ticket.bets.length) {
          // hide bet-slip area
          // note: only in portrait mode
          this.ticket.locked = false;
          this.toggleBetslip(false, false);
          $rootScope.$emit('NumPad:clearAll');
        }

        return removedBets;
      },

      /**
             * Set max stake
             *
             * @memberOf BetslipService
             */
      setMaxStake: function () {
        const { balance } = useUserFundsStore();
        var userBalance = parseFloat(balance);
        var maxTicketPayin = SevenGamesSvc.activeGame.getRule(
          'maxBetAmount',
          {
            ticketType: this.ticket.config.ticketType.id
          }
        );

        this.updateStake(
          userBalance < maxTicketPayin ? userBalance : maxTicketPayin,
          { stakeTouched: true }
        );
      },

      /**
             * /**
             * Update betslip stake
             *
             * @memberOf BetslipService
             * @param {number} [stake]
             * @param {Object} [config]
             * @param {Boolean} [config.stakeTouched] - Is this action from user triggered by user. bajo mojj....
             *                                        This is related to business rule. If user changed stake, don't do any auto
             *                                        calculation after that.
             */
      updateStake: function (stake, config) {
        var currentTicket = this.ticket;

        if (!currentTicket) {
          return;
        }

        if (config && angular.isDefined(config.stakeTouched)) {
          currentTicket.config.stakeTouched = config.stakeTouched;
        }

        // if user didn't change stake by himself
        // find min stake for current ticket in current active game.
        // Be aware, if value is send by external method it won't change
        // state of stakeTouched (e.g. that method is called by programmer)
        if (!currentTicket.config.stakeTouched) {
          // Because virtual games have a more complex combination of bets
          // it is really hard to find out what's min stake in current situation.
          // Actually it is not hard, it is because Game object is not that smart for now.
          // We could pass newPayin and payin to game service but then we are making
          // a lot of dependency between 7terminal, game service and ngticket.
          // Because of that we are going to find min stake here.
          if (currentTicket.config.autoStakeSetting) {
            stake = stake || 0;

            // if min stake is not sent, find it
            if (!stake) {
              currentTicket.getBets().forEach(function (bet) {
                stake += bet.stake || bet.payin;
              });

              // if min stake is not found (left to zero)
              // take min value defined by backend rule
              if (stake === 0) {
                stake = this.ticket.config.stake;
              }
            }
          } else {
            stake = this.ticket.config.stake;
          }
        }

        // don't allow 0 or undefined to be sent
        if (stake === 0 || stake === false || angular.isUndefined(stake)) {
          stake = this.ticket.stake;

          if (SevenGamesSvc.activeGame.gameInfo
                        && SevenGamesSvc.activeGame.gameInfo.equalStake
                         && SevenGamesSvc.activeGame.gameInfo.equalStake.active) {
            stake = this.calculateEqualStake(SevenGamesSvc.activeGame.gameInfo.equalStake.equalStakeAmount);
          }
        }

        currentTicket.setStake(stake);
        // update taxes
        if (currentTicket.config.getStakeHook) currentTicket.config.getStakeHook.apply(currentTicket, [stake]);
      },

      /**
             * Calculate stake based on equal stake input
             *
             * @memberOf BetslipService
             * @param {number} eqAmount - Equal stake amount
             */
      calculateEqualStake: function (eqAmount) {
        var currentTicket = this.ticket;
        var stake = 0;

        currentTicket.bets.forEach(function (bet) {
          bet.payin = eqAmount * bet.numEvents;
          bet.stake = eqAmount * bet.numEvents;
          stake += bet.stake;
        });

        return stake;
      },

      /**
             * Reset bet-slip to its previous state
             *
             * @memberOf BetslipService
             * @param {Object} options
             * @param {String} options.id - Id of game mapped in ticketGroups
             */
      resetBetslip: function (options) {
        var betsToRemove = this.ticket ? angular.copy(this.ticket.getBets()) : [];
        var store = _7Ticket.ts();
        if (this.ticket) {
          this.ticket.config.stakeTouched = false;
        }

        // reset betslip of passed game id
        if (options && options.id) {
          _7Ticket.ts().reinitialise({
            group: options.id,
            collection: options.id
          });
        } else {
          each(store.betslipCollection, function (value, i) {
            store.betslipCollection[i].reset();
          });
        }

        if (options && options.id) {
          $rootScope.$emit('7T:Betslip.Reset', {
            productId: options.id,
            ticket: this.ticket,
            type: 'reset',
            data: { betChanged: betsToRemove, allBets: [] }
          });
        }

        this.toggleBetslip(false, false);

        if (this.ticket) this.ticket.lastFocused = false;

        this.setOddChangeValue();
      },

      /**
             * Re-create betslip with data from server
             *
             * @memberOf BetslipService
             * @param ticket {Object}
             * @param ticket.type {String} - Server ticket type
             * @param ticket.product {String} - Game id
             * @param ticket.stake {Number}
             * @param rebets {Object} - List of bets re-created by betting service, ready to be added to betslip
             */
      reCreateBetslip: function (ticket, rebets) {
        var tickets = _7Ticket.ts().getBetslip(ticket.product).tickets;
        var possibleTickets = [];
        var betslipTicket;
        var i = 0;
        var rebet;

        each(tickets, function (tck, j) {
          var t = tickets[j];
          if (t.config.group.id === ticket.product) {
            possibleTickets.push(t);
          }
        });

        // get ticket type of passed ticket
        betslipTicket = (function () {
          if (possibleTickets.length === 1) {
            return possibleTickets[0];
          }

          each(possibleTickets, function (pTicket, l) {
            if (possibleTickets[l].config.ticketType.id === ticket.type) {
              return possibleTickets[l];
            }
            return possibleTickets[0];
          });
          return possibleTickets[0];
        }());

        this.resetBetslip({ id: ticket.product });

        this.activateTicket(ticket.product, betslipTicket.config.ticketType.name);

        // set stake as touched otherwise updateStake
        // will try to find minimal stake instead of picking one for ticket
        // object
        this.ticket.config.stakeTouched = true;

        for (i; i < rebets.length; i++) {
          rebet = rebets[i];
          this.addToBetslip(rebet, ticket.payin);
        }

        // allow automatic update of stake after ticket is
        // re-created
        this.ticket.config.stakeTouched = false;
      },

      // optional settings per products
      // additional notification besides value indicator can be displayed if value is changed
      setOddChangeValue: function () {
        if (!this.ticket) return;
        this.oddsChanged = this.ticket.config
                    && this.ticket.config.oddWarningActive
                    && this.ticket.getUpdatedBets().length > 0;
      },

      /**
             * Update ticket config
             *
             * @memberOf BetslipService
             * @param {String} ticketId - id of ticket
             * @param {String} betslipId - id of betslip/group
             * @param {Object} config - config wih updated values
             * @param {Number} config.stake - Default/min stake
             * @param {String} config.emptyBetslipText - Text when betslip is empty
             */
      updateTicketConfig: function (betslipId, ticketId, config) {
        var ticket = this.getTicket(ticketId, betslipId);
        if (ticket) {
          ticket.config.update(config);
        }

        return ticket;
      },

      /**
             * Update bets on ticket
             *
             * @memberOf BetslipService
             * @param {Array | Object} bets - list of bets or bet object with new data
             * @param {String} betslipId - betslip id is actually id of betslip collection too
             * @returns {Boolean | Array.<Bet>} returns false if bets are not updated or list of updated bets
             */
      updateBets: function (bets, betslipId) {
        var collection;
        var r;
        if (!this.ticket) return false;

        collection = _7Ticket.betsCollection().getBetCollection(betslipId);

        if (collection) {
          r = collection.updateBets(
            bets,
            'uid'
          );

          // update winnings
          this.ticket.setState();
          return r;
        }

        return false;
      },

      /**
             * Show/hide bet-slip area for specific game group when in portrait mode
             *
             * @memberOf BetslipService
             * @param {String} [betslipId] - Betslip id to toggle or of not sent we will
             *                              current active betslip
             * @param {Boolean} [state] - Defined new state of bet-slip
             */
      toggleBetslip: function (betslipId, state) {
        var betslip;
        if (!betslipId && !this.ticket) {
          return;
        }

        // if game id is not sent use currently active game
        betslipId = betslipId || this.ticket.config.group.id;

        betslip = _7Ticket.ts().getBetslip(betslipId);

        if (betslip) {
          // by default set false
          if (angular.isUndefined(betslip.config.showBetslip)) {
            betslip.config.showBetslip = false;
          }

          // Do toggling
          betslip.config.showBetslip = angular.isUndefined(state)
            ? !betslip.config.showBetslip : state;
        }
      },

      /**
           * @memberOf module:"7Terminal.Betslip".BetslipService
           *
           * @param {Object} data
           * @param {String} data.id
           * @param {String} data.message
           * @param {String} data.productId
           *
           */
      block: function (data) {
        var alreadyBlockedByThisEvent = $filter('filter')(this.blockers, { id: data.id }, true);
        if (alreadyBlockedByThisEvent.length) {
          if (_.isEqual(alreadyBlockedByThisEvent[0], data)) {
            return false;
          }

          angular.extend(alreadyBlockedByThisEvent[0], data);
          $log.debug('[7Terminal.Betslip] Blocked entry updated', data);
          return true;
        }
        this.blockers.push(data);
        $log.info('[7Terminal.Betslip] Blocked', {
          code: 'T_BETSLIP_BLOCKED',
          details: data
        });
        return true;
      },

      unblock: function (id) {
        var i = 0;
        var unblocked = [];
        for (i; i < this.blockers.length; i++) {
          if (this.blockers[i].id === id) {
            $log.debug('[7Terminal.Betslip] Betslip remove block.', {
              code: 'T_BETSLIP_REMOVE_BLOCKERS',
              details: JSON.stringify(this.blockers[i])
            });
            unblocked.push(this.blockers[i]);
            this.blockers.splice(i, 1);
            break;
          }
        }

        return unblocked;
      },

      /**
             * @memberOf module:"7Terminal.Betslip".BetslipService
             *
             * @param {String} id
             */
      isBlockedBy: function (id) {
        return this.blockers.find(function (blocker) {
          return blocker.id === id;
        });
      },

      /**
             * @memberOf module:"7Terminal.Betslip".BetslipService
             * @param {String} activeProduct
             *
             * @returns {Array | Boolan}
             */
      isBlocked: function (activeProduct) {
        var r = $filter('filter')(this.blockers, function (blocker) {
          return blocker.productId === activeProduct
                            || blocker.productId === '*';
        });

        return r.length ? r : false;
      },

      /**
             * @memberOf module:"7Terminal.Betslip".BetslipService
             *
             * @returns {Array}
             */
      getBlockers: function () {
        return this.blockers;
      },

      validatePayinAction: function () {
        var defer = $q.defer();
        var errorObj = {};

        if (this.ticket.bets.length === 0) {
          errorObj.message = this.ticket.config.emptyBetslipText + ' ' + $translate.instant('betslip.start_betting_message');
          errorObj.delay = 3000;
          errorObj.type = 'warning';
          defer.reject(errorObj);
        } else {
          defer.resolve();
        }

        return defer.promise;
      }
    };
  }
})();
