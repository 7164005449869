import { isAxiosError } from 'axios';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import BaseError from '@/common/errors/BaseError';
import { useCardReaderStore } from '../store';
import type { CardWritePayload, OperatorCardDataInfo } from '../types';
import type { CardWriteResponse, CardDataInfo } from '../interfaces';
import { CardNid } from '../enums';
import {
  cardInEventPubSub,
  CARD_IN_EVENT_ID,
} from '../cardReaderEventsService';
import { getCardData, writeCardData } from '../apiService';

const { t } = i18n.global;
const LOG_PREFIX = '[narCardReaderService]';
let formatInterval: number | undefined;

const setCardEmptyInfo = (cardData: CardDataInfo | null) => {
  const cardDetails = {
    cardEmpty: true,
    ...(cardData || {}),
  } as CardDataInfo;
  const validNids = [CardNid.hdioa762, CardNid.passCard];

  if (cardDetails.Nid) {
    cardDetails.cardEmpty = !validNids.includes(cardDetails.Nid);
  }

  return cardDetails;
};

const readCardData = (): Promise<CardDataInfo> => new Promise((resolve, reject) => {
  const cardReaderStore = useCardReaderStore();
  const currentStatus = cardReaderStore.getCurrentStatus();
  if (cardReaderStore.isCardIn()) {
    getCardData().then((response) => {
      const formattedCardDetails = setCardEmptyInfo(response.data);
      resolve(formattedCardDetails);
    }).catch((error) => {
      const parser = isAxiosError(error) ? axiosErrorParser : errorParser;
      const parsedError = parser.parseUpstream(error);
      const baseError = new BaseError(parsedError.upstream_message, parsedError.upstream_code);
      logService.warn(`${LOG_PREFIX} Read card data failed`, {
        code: 'T_CARD_READER_READ_CARD_DATA_ERROR',
        cardStatus: currentStatus,
        ...parsedError,
        errorData: error,
      });

      reject(baseError);
    });
  } else {
    const code = 'T_CARD_READER_CARD_NOT_INSERTED';
    logService.warn(`${LOG_PREFIX} Read card data failed. Card is not inserted.`, {
      code,
      cardStatus: currentStatus,
    });
    reject(new BaseError('Card is not inserted', code));
  }
});

const writeToCard = (data: CardWritePayload): Promise<CardWriteResponse> => {
  const cardReaderStore = useCardReaderStore();
  const finalData = {
    Message: [
      data,
    ],
  };

  return new Promise((resolve, reject) => {
    const currentStatus = cardReaderStore.getCurrentStatus();

    if (cardReaderStore.isCardIn()) {
      writeCardData(finalData).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        const parser = isAxiosError(error) ? axiosErrorParser : errorParser;
        const parsedError = parser.parseUpstream(error);
        const err = new BaseError(parsedError.upstream_message, parsedError.upstream_code);
        logService.warn(`${LOG_PREFIX} Write to card failed.`, {
          code: 'T_CARD_READER_WRITE_TO_CARD_ERROR',
          cardStatus: currentStatus,
          ...errorParser.parseUpstream(error),
        });
        reject(err);
      });
    } else {
      const code = 'T_CARD_READER_CARD_NOT_INSERTED';
      logService.warn(`${LOG_PREFIX} Write to card failed. Card is not inserted.`, {
        code,
        cardStatus: currentStatus,
        dataToWrite: finalData,
      });
      reject(new BaseError('Card is not inserted', code));
    }
  });
};

const writeToOperatorCard = (cardInfo: OperatorCardDataInfo): Promise<CardWriteResponse> => {
  const cardData = {
    Uuid: cardInfo.userUuid,
    Hash: cardInfo.password,
    pinRequired: cardInfo.pin ? 1 : 0,
    Nid: CardNid.hdioa762,
    Pin: '0000',
    Force: 'Yes',
  };

  return writeToCard(cardData);
};

const writeToPassCard = (): Promise<CardWriteResponse> => {
  const cardData = {
    Hash: '000000000000000000000000000000000000',
    Uuid: '000000000000000000000000000000000000',
    Nid: CardNid.passCard,
    Pin: '0000',
    Force: 'Yes',
    pinRequired: 0,
  };

  return writeToCard(cardData);
};

const formatCard = (): Promise<CardWriteResponse> => {
  const notificationsStore = useNotificationsStore();
  const cardReaderStore = useCardReaderStore();
  const finalData = {
    Message: [
      {
        Hash: '000000000000000000000000000000000000',
        Uuid: '000000000000000000000000000000000000',
        Nid: CardNid.formatted,
        pinRequired: 0,
        Pin: '0000',
        Force: 'Yes',
      },
    ],
  };
  const currentStatus = cardReaderStore.getCurrentStatus();

  if (!formatInterval) formatInterval = setInterval(formatCard, 5000, 5);

  return new Promise((resolve, reject) => {
    if (cardReaderStore.isCardIn()) {
      writeCardData(finalData).then((response) => {
        clearInterval(formatInterval);
        formatInterval = undefined;
        notificationsStore.closeNotificationWithId('invalid_card_data');
        resolve(response.data);
      }).catch((error) => {
        const parser = isAxiosError(error) ? axiosErrorParser : errorParser;
        const parsedError = parser.parseUpstream(error);
        const baseError = new BaseError(parsedError.upstream_message, parsedError.upstream_code);
        logService.warn(`${LOG_PREFIX} Format card failed`, {
          code: 'T_CARD_READER_WRITE_DATA_ERROR',
          cardStatus: currentStatus,
          dataToWrite: finalData,
          ...parsedError,
        });
        reject(baseError);
      });
    } else {
      const code = 'T_CARD_READER_CARD_NOT_INSERTED';
      logService.warn(`${LOG_PREFIX} Format card failed. Card is not inserted.`, {
        code,
        cardStatus: currentStatus,
        dataToWrite: finalData,
      });

      clearInterval(formatInterval);
      formatInterval = undefined;

      reject(new BaseError('Card is not inserted', code));
    }
  });
};

const isCardEmpty = (
  cardData: CardDataInfo | undefined,
): boolean => !cardData || cardData?.cardEmpty === true;

const onCardInEvent = () => {
  const notificationsStore = useNotificationsStore();
  const cardReaderStore = useCardReaderStore();

  readCardData()
    .then((res) => {
      cardReaderStore.onCardRead(res);
    }).catch((error) => {
      const parser = isAxiosError(error) ? axiosErrorParser : errorParser;
      notificationsStore.show({
        message: t('notifications.card_reading_failed'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });

      logService.error(`${LOG_PREFIX} On card In - An error has ocurred while reading card data`, {
        ...parser.parseUpstream(error),
        code: 'T_CARDS_READ_CARD_ERR',
      });
    });
};

const init = (): void => {
  formatInterval = undefined;
  cardInEventPubSub.subscribe(CARD_IN_EVENT_ID, onCardInEvent);
};

export default {
  init,
  readCardData,
  isCardEmpty,
  writeToOperatorCard,
  writeToPassCard,
  formatCard,
};
