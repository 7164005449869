/* eslint-disable import/no-cycle */
import angular from 'angular';
import commonModule from '../../common';
import coreModule from '../../core';
import betslipModule from '../../betslip';
import rootScopeDecorator from './rootScopeGravityDecorator';
import tGameIframeComponent from './gameIframeDirective';
import GravityGatewayService from './gravityGatewayService';
import run from './run';

/**
  * @ngdoc overview
  * @name 7Terminal.Integrator
  *
  * @description
  * This module integrates third party products into 7Terminal application.
  * See {@link tutorial/intergrations Intergrations} section for more details.
  *
  * @example
  *  <b>script.js</b>
  *  <pre>
  *  import MODULE_NAME from './modules/integrator'
  *  angular.module('myModule', [MODULE_NAME]);
  *  </pre>
  *
*/
const moduleName = angular.module('7Terminal.Integrator', [
  'SevenClientLib',
  commonModule,
  coreModule,
  betslipModule,
  'pascalprecht.translate'
])
  .decorator('$rootScope', rootScopeDecorator)
  .directive('tGameIframe', tGameIframeComponent)
  .service('GravityGatewayService', GravityGatewayService)
  .run(run)
  .name;

export default moduleName;
