/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getService } from '@/common/services/ngBridge';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';

let $rootScope: any;

function handleTimeoutedRejection(rejection: any): void {
  const { t } = i18n.global;

  if ((rejection.status === -1 || rejection.xhrStatus === 'timeout') && !rejection?.data?.message) {
    rejection.data = {
      message: t('notifications.network_error'),
      code: 'T_REQUEST_TIMEOUT',
    };
    logService.debug('[httpInterceptor] Request timeouted.', {
      message: t('notifications.network_error'),
      code: 'T_REQUEST_TIMEOUT',
      upstream_code: rejection.status || rejection.xhrStatus,
      url: rejection.config?.url,
      params: rejection.config?.params,
      method: rejection.config?.method,
    });
  }
}

export const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  $rootScope = $rootScope || getService('$rootScope');
  $rootScope.$emit('$http:request', request.url);

  return request;
};

export const requestError = (rejection: any): Promise<any> => {
  $rootScope = $rootScope || getService('$rootScope');

  handleTimeoutedRejection(rejection);
  $rootScope.$emit('$http:response', rejection.config.url);
  return Promise.reject(rejection);
};

export const responseSuccess = (response: AxiosResponse): AxiosResponse => {
  $rootScope = $rootScope || getService('$rootScope');

  $rootScope.$emit('$http:response', response.config.url);
  return response;
};

export const responseError = (rejection: AxiosResponse): Promise<AxiosResponse> => {
  $rootScope = $rootScope || getService('$rootScope');

  handleTimeoutedRejection(rejection);
  $rootScope.$emit('$http:response', rejection.config.url);

  if (rejection.status === 401) {
    $rootScope.$emit('$http:unauthorizedRequest', rejection.data);
  }

  return Promise.reject(rejection);
};
