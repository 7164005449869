/**
  * @ngdoc directive
  * @name 7Terminal.Integrator.directive:tGameIframe
  * @restrict 'E'
  * @element tGameIframe
  * @scope
  * priority: 0
  * @description
  * It loades integration in iframe element.
  *
  *
  * @param {Object} widget Definition of widget. See {@link Integrations}
  * @param {Object} data Data that will be sent to iframe using Gravity Gateway Load event
  * @param {Object} bindings Data that will be avilable for binding in URl params
  * @param {String} iframeId Id that will be set as iframe element id attribute
  * @param {Function} onFinally Callback to execute when iframe is loaded
  *
  * @example
  *  <t-game-iframe
  *       widget="$ctrl.widget"
  *       data="$ctrl.data"
  *       bindings="$ctrl.data"
  *       iframeId="'Roulette'"
  *       onFinally="$ctrl.onFinally()">
  *  </t-game-iframe>
* */

/**
 *
 * @ngInject
 */
function tGameIframe(
  $rootScope,
  $timeout,
  $log,
  $interpolate,
  GravityGatewayService
) {
  return {
    restrict: 'E',
    template: '<div class="row h-100"></div>',
    scope: {
      widget: '<',
      data: '<',
      bindings: '<',
      iframeId: '<',
      onFinally: '&'
    },
    link: function ($scope, $element) {
      let resizeObserver;
      function interpolateUrl(url) {
        var exp = $interpolate(url);
        return exp($scope.bindings || {});
      }

      function getFrameId() {
        return _.property(['config', 'frameId'])($scope.widget.source) || $scope.iframeId;
      }

      function init() {
        var useGateway = _.property(['config', 'useGateway'])($scope.widget.source);
        setListeners();
        // by defualt we will use gateway
        if (_.isUndefined(useGateway) || useGateway === true) {
          GravityGatewayService.initSlave({
            iframeSrc: $scope.widget.source.url,
            iframeId: getFrameId(),
            iframeConfig: $scope.widget.source.config,
            context: $scope.data
          }).catch(function () {
            $log.info(
              '[7Terminal.Integrator] Failed loading iframe',
              {
                frameId: getFrameId(),
                frameUrl: $scope.widget.source.url,
                code: 'T_IFRAME_LOAD_FAIL'
              }
            );
          }).finally(function () {
            $rootScope.$emit(
              '7T:Slave.Unmute',
              {
                productId: getFrameId()
              }
            );
            $rootScope.$emit(
              '7T:Slave.Awake',
              {
                productId: getFrameId()
              }
            );

            $scope.onFinally({
              config: {
                iframeSrc: $scope.widget.source.url,
                iframeId: getFrameId(),
                iframeConfig: $scope.widget.source.config,
                context: $scope.data
              }
            });
          });
        }
      }

      function createIframe() {
        var url = interpolateUrl($scope.widget.source.url);
        var iframe;

        url = new URL(url);
        url.searchParams.set('v', Date.now());
        url.searchParams.set('integrationType', 'gravityGateway');
        url.searchParams.set('application', 'terminal');
        url.searchParams.set('platform', 'seven');
        iframe = angular.element('<iframe>', {
          src: url,
          id: getFrameId(),
          class: 'game-iframe',
          style: 'visibility: hidden'
        }).appendTo('body');

        return iframe.first();
      }

      function getIframe() {
        var iframe = angular.element('iframe#' + getFrameId()).first();

        return !iframe.length ? false : iframe;
      }

      function setListeners() {
        var listener = $scope.$watch('iframeId', function (newValue) {
          if (newValue) {
            listener();
            setProductIframe();
          }
        });
      }

      function setProductIframe() {
        var iframe = getIframe();
        if (!iframe) {
          iframe = createIframe();
        }
        // create an Observer instance
        resizeObserver = new ResizeObserver(() => {
          var position = $element[0].getBoundingClientRect();
          iframe.css(angular.extend({
            visibility: 'visible',
            position: 'fixed',
            left: position.left,
            top: position.top,
            height: position.height,
            width: position.width
          }, ($scope.widget.source.config && $scope.widget.source.config.css) || {}));
        });

        // start observing a DOM node
        resizeObserver.observe($element[0]);
      }

      $scope.$on('$destroy', function () {
        var iframe = getIframe();
        resizeObserver.unobserve($element[0]);
        if (iframe) {
          iframe.css({
            visibility: 'hidden'
          });

          if ($scope.widget.source.config?.destroy) {
            GravityGatewayService.removeIframeOnDelay(iframe[0]);
          }
          $rootScope.$emit(
            '7T:Slave.Snooze',
            {
              productId: getFrameId()
            }
          );

          $rootScope.$emit(
            '7T:Slave.Mute',
            {
              productId: getFrameId()
            }
          );
        }
      });

      init();
    }
  };
}

export default tGameIframe;
