export default function ValidatorRules() {
  return {
    validateMinOrEqual: function (input, min) {
      return Number(input) >= Number(min);
    },

    validateMin: function (input, min) {
      return Number(input) > Number(min);
    },

    validateMaxOrEqual: function (input, max) {
      return Number(input) <= Number(max);
    },

    validateMax: function (input, max) {
      return Number(input) < Number(max);
    },

    validateBetween: function (input, min, max) {
      return Number(input) >= Number(min) && Number(input) <= Number(max);
    },

    validateBoolean: function (input, expected) {
      return !!input === expected;
    }
  };
}
