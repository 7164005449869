import PubSub from 'pubsub-js';
import AuthorizationChangedData from '../interfaces/AuthorizationChangedData';

const AUTHORIZATION_CHANGED_EVENT_ID = Symbol('7T:User.AuthorizationChanged');
const authorizationChangedEventPubSub: PubSubJS.Base<
AuthorizationChangedData,
typeof AUTHORIZATION_CHANGED_EVENT_ID
> = PubSub;

export {
  AUTHORIZATION_CHANGED_EVENT_ID,
  authorizationChangedEventPubSub,
};
