import { ref, reactive, readonly } from 'vue';
import { defineStore } from 'pinia';
import HooksManager from '@/common/services/HooksManager';
import type { CustomerCardUserData, RomTaxPlayerDataPromise, SevenCustomerCard } from './types';

const useCustomerCardStore = defineStore('customerCardStore', () => {
  const activeCustomerCardInstances = reactive<Array<SevenCustomerCard>>([]);
  const customerCardUserData = ref<CustomerCardUserData>({} as CustomerCardUserData);
  const isTaxAuthModalOpened = ref(false);
  const romTaxPlayerDataPromise = ref<RomTaxPlayerDataPromise>();

  const getCustomerCardUserData = () => customerCardUserData.value;

  const isCustomerCardScanned = () => Object.keys(customerCardUserData.value || {}).length !== 0;

  const setCustomerCardUserData = (data: CustomerCardUserData) => {
    Object.assign(customerCardUserData.value, data);
    HooksManager.run('CustomerCardDetailsFetched', { cardData: customerCardUserData.value });
  };

  const removeCustomerCardUserData = () => {
    HooksManager.CustomerCardOptOut.promise({
      cardData: customerCardUserData.value,
    });
    Object.keys(customerCardUserData.value).forEach((key: string) => {
      delete customerCardUserData.value[key as keyof CustomerCardUserData];
    });
  };

  return {
    customerCardUserData: readonly(customerCardUserData),
    activeCustomerCardInstances,
    isTaxAuthModalOpened,
    romTaxPlayerDataPromise,
    getCustomerCardUserData,
    setCustomerCardUserData,
    removeCustomerCardUserData,
    isCustomerCardScanned,
  };
});

export {
  useCustomerCardStore,
};
