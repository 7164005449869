import BaseError from '@/common/errors/BaseError';
import type { PrinterStatus } from '@/modules/print';
import type { DmPrintErrorType } from '../types/dmPrintTypes';

export default class DmPrintError extends BaseError<DmPrintErrorType> {
  constructor(
    public message: string,
    public code: DmPrintErrorType,
    public options?: {
      cause?: unknown;
      context?: {
        printerStatus: PrinterStatus
      };
    },
  ) {
    super(message, code, options);
    Object.setPrototypeOf(this, DmPrintError.prototype);
    this.name = this.constructor.name;
  }
}
