import { createApp, h } from 'vue';

import ResultsNavigation from '@/modules/results/components/ResultsNavigation.vue';

(function () {
  angular.module('7Terminal.Results')
    .controller('ResultsController', ResultsController);

  function ResultsController(
    $element,
    $scope,
  ) {
    const vm = this;

    function init() {
      vm.app = createApp({
        render: () => h(ResultsNavigation)
      });
      const navigationEl = $element.find('#result-navigation-el')[0];
      vm.app.mount(navigationEl);
    }

    function onDestroyCtrlListener() {
      $scope.$on('$destroy', () => {
        vm.app.unmount();
        vm.app = null;
      });
    }

    init();
    onDestroyCtrlListener();
  }
})();
