import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useLanguageStore } from '@/common/stores/language';

export const getNumberDelimiter = () : {
  group: string,
  decimal: string
} | null => {
  const gravitySettingsStore = useGravitySettingsStore();
  const cmsNumberDelimiter = gravitySettingsStore.getModuleDataKeyValue('config', 'numberDelimiter');
  if (!cmsNumberDelimiter) return null;

  let groupSeparator = cmsNumberDelimiter[1];
  const decimalSeparator = cmsNumberDelimiter[cmsNumberDelimiter.length - 3];
  if (groupSeparator === '#') { groupSeparator = ''; }

  return {
    group: groupSeparator,
    decimal: decimalSeparator,
  };
};

const numberFormatter = (number: number, numberOfDecimalPlaces: number = 2): string => {
  const numberDelimiter = getNumberDelimiter();
  const languageStore = useLanguageStore();
  const locale = languageStore.getCurrentLanguage();
  let formatter;

  if (numberOfDecimalPlaces) {
    formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: numberOfDecimalPlaces,
      maximumFractionDigits: numberOfDecimalPlaces,
    });
  } else {
    formatter = new Intl.NumberFormat(locale);
  }
  if (numberDelimiter) {
    return formatter.formatToParts(number).map(({ type, value }) => {
      switch (type) {
        case 'group': return numberDelimiter?.group || '';
        case 'decimal': return numberDelimiter?.decimal || '';
        default: return value;
      }
    }).reduce((string, part) => string + part);
  }
  return formatter.format(number);
};

export default numberFormatter;
