(function () {
  angular.module('7Terminal.Core')
    .service('GeneralModulesService', GeneralModulesService);

  /**
   * @name 7Terminal.Core.services:GeneralModulesService
   */
  function GeneralModulesService(
    $filter,
    $log
  ) {
    /**
     *
     * @memberOf module:"7Terminal.Core".GeneralModulesService
     * @type {Array}
     */
    this.modules = [];

    /**
     *
     * @memberOf module:"7Terminal.Core".GeneralModulesService
     * @param {Array} modules
     */
    this.setModules = function (modules) {
      this.modules = modules;
    };

    /**
     *
     * @memberOf module:"7Terminal.Core".GeneralModulesService
     * @param {String} moduleId moduleId to append submodules to
     * @param {Array} submodules array of submodules to append
     */
    this.appendSubmodules = function (moduleId, submodules) {
      const foundModule = this.getModule(moduleId);

      if (foundModule) {
        if (!foundModule.submodules) {
          foundModule.submodules = [];
        }
        foundModule.submodules.push(...submodules);
      } else {
        $log.warn('[7Terminal.Core] Submodules cannot be appended, since module id not found.', {
          details: {
            moduleId
          },
          code: 'T_CORE_SUBMODULE_APPEND_NOT_FOUND'
        });
      }
    };

    /**
    * Return general modules
    *
    * @memberOf module:"7Terminal.Core".GeneralModulesService
    * @param orderBy
    * @returns {*|Array}
    */
    this.getModules = function (orderBy) {
      var modules = this.modules;

      if (orderBy) {
        modules = $filter('orderBy')(modules, orderBy);
      }

      return modules;
    };

    /**
     * Get general module or its submodule
     *
     * @memberOf module:"7Terminal.Core".GeneralModulesService
     * @param {String} moduleId
     * @param {String} [submoduleId]
     * @returns {Object}
     */
    this.getModule = function (moduleId, submoduleId) {
      var foundModule = $filter('filter')(this.modules, { id: moduleId }, true)[0];

      if (submoduleId) {
        foundModule = $filter('filter')(foundModule.submodules, { id: submoduleId }, true)[0];
      }

      return foundModule;
    };
  }
})();
