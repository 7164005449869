import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sc-px-0 sc-bg-300 h-100 tbo-sidebar" }
const _hoisted_2 = { class: "sc-bg-600" }

import {
  ref, reactive, onMounted,
} from 'vue';
import { SCNavigationList, SCNavigationListItem, SCIcon } from '@nsftx/seven-components';
import router from '@/router';
import { useTboModulesStore, type TboSevenModule } from '@/modules/tbo';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';


export default /*@__PURE__*/_defineComponent({
  __name: 'TboSidebar',
  setup(__props) {

const tboModulesStore = useTboModulesStore();
const gravitySettingsStore = useGravitySettingsStore();
const globalModules = reactive<Array<TboSevenModule>>(tboModulesStore.getModules());
const activeState = ref('admin/wallet');// first view to redirect on after login
const activeItemIndex = ref(0);

const iconList: Record<string, string> = {
  tickets: 'n-i-betslip-a',
  wallet: 'n-i-balance',
  players: 'n-i-user-details',
  reporting: 'n-i-reporting',
  systeminfo: 'n-i-terminal-a',
  cardoperations: 'n-i-user-name',
  billing: 'n-i-bills',
  certificate: 'n-i-article',
};

const onNavItemClicked = () => {
  const item = globalModules[activeItemIndex.value];
  const routePath = router.currentRoute.value.fullPath;
  const isRouteActive = routePath.includes(item.id.toLowerCase());

  if (isRouteActive) {
    return;
  }

  router.push({
    name: item.id,
  });
};

const setActiveIndex = () => {
  activeItemIndex.value = globalModules
    .findIndex((el: TboSevenModule) => activeState.value.includes(`admin/${el.id.toLowerCase()}`));
};

router.beforeEach((route) => {
  activeState.value = route.path;
  setActiveIndex();
});

const loadAdditionalNavigationItems = () => {
  const certificateEnabled = !!gravitySettingsStore.data.config?.certificate;

  if (certificateEnabled) {
    globalModules.push({
      id: 'Certificate',
      translation: 'Certifikat',
      mode: null,
    });
  }
};

onMounted(() => {
  loadAdditionalNavigationItems();
  setActiveIndex();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(SCNavigationList), {
        modelValue: activeItemIndex.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((activeItemIndex).value = $event)),
          onNavItemClicked
        ],
        large: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(globalModules, (module) => {
            return (_openBlock(), _createBlock(_unref(SCNavigationListItem), {
              key: module.id
            }, {
              "prepend-icon": _withCtx(() => [
                _createVNode(_unref(SCIcon), {
                  icon: iconList[module.id.toLowerCase()],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(module.translation), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}
}

})