angular.module('7Terminal.Common')
  .factory('nabTransBootstrap', function nabTransBootstrap($q, nabTrans) {
  /**
     * This gets called when language is changed and service
     * can not find translation table for selected language
     * In our case, it happens on user login
     */
    return function () {
      var deferred = $q.defer();

      nabTrans.getTransObj().then(function (translations) {
        deferred.resolve(translations);
      });

      return deferred.promise;
    };
  });
