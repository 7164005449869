/**
 *
 * @ngInject
 */
export default function ($stateProvider) {
  $stateProvider.state('app.results.keno', {
    url: '/keno?date',
    template: '<keno-results class="max-height"></keno-results>'
  });
}
