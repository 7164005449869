import { each } from 'underscore';

(function () {
  angular.module('terminalApp.nabHorseRacesUi')
    .service('HorseRacesUiService', HorseRacesUiService);

  function HorseRacesUiService(
    $rootScope,
    nabMessaging,
    BetslipService,
    nabTrans,
    SevenGamesSvc,
    ticketService,
    GravitySettings
  ) {
    var TICKET_TYPES = {
      SINGLE: {
        id: 3,
        name: 'single'
      }
    };
    var self = this;

    function createTicket() {
      var HorseRaces = SevenGamesSvc.getGame('HorseRaces');
      var VirtualHorseRaces = SevenGamesSvc.getGame('VirtualHorseRaces');

      if (HorseRaces
                && !BetslipService.getTicket(TICKET_TYPES.SINGLE.name, 'HorseRaces')) {
        $rootScope.$broadcast('7T:Betslip.Create', {
          data: {
            betslip: {
              id: 'HorseRaces',
              tickets: [
                {
                  id: TICKET_TYPES.SINGLE.name, // if of ticket build of game id (PrematchBetting) and ticket type (normal)
                  active: true, // active in current group (LuckySix)
                  ticketGroup: 'HorseRaces',
                  betsCollection: 'HorseRaces',
                  group: {
                    id: 'HorseRaces',
                    taxesConfig: HorseRaces.config.taxes
                  },
                  ticketType: TICKET_TYPES.SINGLE, // this is only needed when when formatting data for server, it represent server id
                  oddProp: 'mboOddValue', // this is badd :((
                  stake: HorseRaces.getRule('minPayinAmount', {
                    ticketType: TICKET_TYPES.SINGLE.id
                  }),
                  stakeTouched: false,
                  setStakeStrategy: 'sevenVirtualSetStake',
                  winningStrategy: false,
                  setFormatPayin: 'sevenVirtualFormataPayin',
                  hasSystem: false,
                  emptyBetslipText: nabTrans.translate('betslip.pick_numbers_message_first_line', {}, true),
                  autoStakeSetting: true
                }
              ]
            }
          }
        });
      }

      if (SevenGamesSvc.getGame('VirtualHorseRaces')
                && !BetslipService.getTicket(TICKET_TYPES.SINGLE.name, 'VirtualHorseRaces')) {
        $rootScope.$broadcast('7T:Betslip.Create', {
          data: {
            betslip: {
              id: 'VirtualHorseRaces',
              tickets: [
                {
                  id: TICKET_TYPES.SINGLE.name, // if of ticket build of game id (PrematchBetting) and ticket type (normal)
                  active: true, // active in current group (LuckySix)
                  ticketGroup: 'VirtualHorseRaces',
                  betsCollection: 'VirtualHorseRaces',
                  group: {
                    id: 'VirtualHorseRaces',
                    taxesConfig: VirtualHorseRaces.config.taxes
                  },
                  ticketType: TICKET_TYPES.SINGLE, // this is only needed when when formatting data for server, it represent server id
                  oddProp: 'mboOddValue', // this is badd :((
                  stake: VirtualHorseRaces.getRule('minPayinAmount', {
                    ticketType: TICKET_TYPES.SINGLE.id
                  }),
                  stakeTouched: false,
                  setStakeStrategy: 'sevenVirtualSetStake',
                  winningStrategy: false,
                  setFormatPayin: 'sevenVirtualFormataPayin',
                  hasSystem: false,
                  emptyBetslipText: nabTrans.translate('betslip.pick_numbers_message_first_line', {}, true),
                  autoStakeSetting: true
                }
              ]
            }
          }
        });
      }
    }

    /** Status of bets and active racers* */

    this.disableReverseForecast = false;
    this.disableForecast = false;
    this.disableReverseTricast = false;
    this.disableTricast = false;
    this.activeRacer = {};
    this.winner = {};
    this.place = {};
    this.show = {};
    this.forecastFirstPlace = {};
    this.forecastSecondPlace = {};
    this.forecastAny = {};
    this.forecast = {};
    this.tricastFirstPlace = {};
    this.tricastSecondPlace = {};
    this.tricastThirdPlace = {};
    this.tricastAny = {};
    this.forecastFirstPlaces = [];
    this.forecastSecondPlaces = [];
    this.tricastFirstPlaces = [];
    this.tricastSecondPlaces = [];
    this.tricastThirdPlaces = [];
    this.reverseForecastPlaces = [];
    this.reverseTricastPlaces = [];

    /** Scheduled events */
    this.scheduledEvents = {};

    this.createScheduledEvents = function (events) {
      for (var i = 0; i < events.length; i++) {
        var event = events[i].eventId;
        this.scheduledEvents[event] = {};

        this.createRacecardSchedule(event);
        this.createForecastSchedule(event);
        this.createTricastSchedule(event);
      }
    };

    this.updateScheduledEvents = function (events) {
      var ids = Object.keys(this.scheduledEvents);

      self.createScheduledEvents([events[events.length - 1]]);
    };

    this.clearScheduledEvents = function (currentEvent) {
      delete this.scheduledEvents[currentEvent];
    };

    this.createRacecardSchedule = function (event) {
      this.scheduledEvents[event].winner = {};
      this.scheduledEvents[event].place = {};
      this.scheduledEvents[event].show = {};
    };

    this.createForecastSchedule = function (event) {
      this.scheduledEvents[event].forecastFirstPlace = {};
      this.scheduledEvents[event].forecastSecondPlace = {};
      this.scheduledEvents[event].forecastThirdPlace = {};
      this.scheduledEvents[event].forecastAny = {};
      this.scheduledEvents[event].forecast = {};
    };

    this.createTricastSchedule = function (event) {
      this.scheduledEvents[event].tricastFirstPlace = {};
      this.scheduledEvents[event].tricastSecondPlace = {};
      this.scheduledEvents[event].tricastThirdPlace = {};
      this.scheduledEvents[event].tricastAny = {};
    };

    this.clearForecastActiveState = function () {
      this.forecastFirstPlace = {};
      this.forecastSecondPlace = {};
      this.forecastAny = {};
    };

    this.clearTricastActiveState = function () {
      this.tricastFirstPlace = {};
      this.tricastSecondPlace = {};
      this.tricastThirdPlace = {};
      this.tricastAny = {};
    };

    this.clearRacecardActiveState = function () {
      this.winner = {};
      this.place = {};
      this.show = {};
    };

    this.deactivateForecastRacers = function () {
      each(this.forecastFirstPlace, function (value, position, racer) {
        racer[position] = false;
      });

      each(this.forecastSecondPlace, function (value, position, racer) {
        racer[position] = false;
      });

      each(this.forecastAny, function (value, position, racer) {
        racer[position] = false;
      });
    };

    this.deactivateTricastRacers = function () {
      each(this.tricastFirstPlace, function (value, position, racer) {
        racer[position] = false;
      });

      each(this.tricastSecondPlace, function (value, position, racer) {
        racer[position] = false;
      });

      each(this.tricastThirdPlace, function (value, position, racer) {
        racer[position] = false;
      });

      each(this.tricastAny, function (value, position, racer) {
        racer[position] = false;
      });
    };

    this.deactivateRacecardRacers = function () {
      each(this.racers, function (position) {
        self.winner['r' + (position)] = false;
        self.show['r' + (position)] = false;
        self.place['r' + (position)] = false;
      });
    };

    this.formatTicketBets = function (message) {
      message.ticketFormatted = formatter(message.ticket);

      function formatter(ticket) {
        var ticket = ticket;
        var bets = ticket.bets;
        var result = { bets: [], payin: 0 };

        for (var j = 0; j < bets.length; j++) {
          result.bets.push({
            payin: bets[j].stake ? bets[j].stake : false,
            type: bets[j].id,
            value: bets[j].value,
            eventId: bets[j].eventId,
            numEvents: bets[j].numEvents ? bets[j].numEvents : 1
          });

          result.payin += bets[j].stake;
        }
        return result;
      }
    };

    // listen for config changes
    nabMessaging.subscribe('NCM:gameConfigChanged', function (event, message) {
      if (message.game == 'HorseRaces' || message.game == 'VirtualHorseRaces') {
        var game = SevenGamesSvc.getGame(message.game);
        game.setConfig().then(function () {
          game.doInitialConfiguration();
          $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
            data: {
              betslipId: game.id,
              ticketId: TICKET_TYPES.SINGLE.name,
              config: {
                group: {
                  id: game.id,
                  taxesConfig: game.config.taxes
                },
                stake: game.getRule('minPayinAmount', {
                  ticketType: TICKET_TYPES.SINGLE
                })
              }
            }
          });

          nabMessaging.publish('config:gameConfigChanged', message.game);
        });
      }
    });

    nabMessaging.subscribe('Horse:raceStart', function (e, data) {
      var betsToRemove = [];
      var eventId = data.eventId;
      var HorseRaces = SevenGamesSvc.getGame(data.game);
      var ticket = BetslipService.getTicket('single', data.game);

      if (ticket) {
        ticket.getBets().forEach(function (bet) {
          if (bet.eventId == HorseRaces.gameInfo.eventId) {
            bet.locked = true;
            betsToRemove.push(bet.uid);
          }
        });

        if (betsToRemove.length) {
          ticket.locked = true;
        }
      }
    });

    nabMessaging.subscribe('Horse:updateEventsNew', function (e, data) {
      var game = SevenGamesSvc.getGame(data.game);
      self.updateScheduledEvents(game.offer, game)
    });

    // update rounds on betslip
    nabMessaging.subscribe('Horse:updateEventsOld', function (e, data) {
      var betsToUpdate = [];
      var betsToRemove = [];
      var eventAfter = data.param;
      var eventId = data.eventId;
      var HorseRaces = SevenGamesSvc.getGame(data.game);
      var ticket = BetslipService.getTicket('single', data.game);

      if (ticket) {
        ticket.getBets()
          .forEach(function (bet) {
            betsToUpdate.push({
              event: eventAfter,
              eventId: eventId,
              uid: bet.uid
            });

            if (HorseRaces.isScheduleActive) {
              // if(bet.eventId < eventId) {
              //  betsToRemove.push(bet.uid);
              // }
            } else {
              // Update odds when new round starts
              if (bet.id >= 10 && bet.numOfComb == 1) { // odds are display just for one combination
                bet.systemOdd = bet.id == 10 ? HorseRaces._bettingSvc.systemBets.forecastOdds[bet.displayValue] : HorseRaces._bettingSvc.systemBets.tricastOdds[bet.displayValue];
              }

              if (bet.id < 10) {
                bet.odd = HorseRaces._bettingSvc.normalBetsOdds[bet.key][bet.displayValue];
              }
            }
          });

        if (HorseRaces.isScheduleActive) {
          self.updateScheduledEvents(HorseRaces.offer, HorseRaces);
        } else {
          $rootScope.$broadcast('7T:Betslip.UpdateBets', {
            data: {
              betslipId: data.game,
              bets: betsToUpdate
            }
          });
        }
      }
    });

    // listen for betslip payin action
    $rootScope.$on('7T:Betslip.Payin',
      /**
             *
             * @param event
             * @param {Object} message
             * @param {String} message.productId
             * @param {Object} message.ticket
             */
      function (event, message) {
        if (message.productId === 'HorseRaces'
                    || message.productId === 'VirtualHorseRaces') {
          self.formatTicketBets(message);
          ticketService.payinTicket(message).catch(angular.noop);
        }
      });

    $rootScope.$on('7T:Tickets.PrePrint', function (e, eventData) {
      const productId = eventData.productId;
      if (eventData.productId === 'HorseRaces' || eventData.productId === 'VirtualHorseRaces') {
        const isInIframe = GravitySettings
        .getModuleDataKeyValue(`module.${productId}`, 'frame') === true;
        var gameService = SevenGamesSvc.getGame(eventData.productId);
        var bettingService = gameService.getBettingSvc();

        if (isInIframe) {
          return;
        }

        angular.merge(eventData.responseData, bettingService.prepareForPrint(
          eventData.responseData,
          gameService.config
        ));

        $rootScope.$emit('7T:Tickets.Print', eventData);
      }
    });

    this.boot = function (game) {
      if (!game) return;

      createTicket();

      $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
        data: {
          betslipId: game,
          ticketId: 'single',
          config: {
            emptyBetslipText: nabTrans.translate(
              'betslip.pick_numbers_message_first_line', {}, true
            )
          }
        }
      });
    };
  }
})();
