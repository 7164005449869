import i18n from '@/plugins/i18n';
import { getService } from '@/common/services/ngBridge';
import { localAppConfigService } from '@/common/services/local-app-config';
import { operatorService } from '@/modules/operator';
import { SevenUserTypes, useSevenStore } from '@/modules/seven';
import { useENVStore } from '@/common/stores/env';
import { useOddTypeStore } from '@/common/stores/odd-type';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { AdditionalGatewayLoadData, IframeLoadData } from '@/modules/gateway/types';
import { useCustomerCardStore } from '@/modules/customer-card';
import { ClientGame } from '@/modules/client-games';
import { useLanguageStore } from '@/common/stores/language';
import { virtualKeyboardService } from '@/common/services/virtual-keyboard';
import { useUserFundsStore } from '@/modules/user-funds';

const generateIframeLoadData = (data?: AdditionalGatewayLoadData, productDisplayId?: string) => {
  // legacy services
  const BetslipService: any = getService('BetslipService');
  const Peripherals: any = getService('Peripherals');
  // stores
  const sevenStore = useSevenStore();
  const oddsTypeStore = useOddTypeStore();
  const langStore = useLanguageStore();
  const configSettingsStore = useConfigSettingsStore();
  const customerCardStore = useCustomerCardStore();
  // constants
  const { t } = i18n.global;
  const isOperatorLoggedIn = operatorService.isLoggedIn();
  const userType = isOperatorLoggedIn ? SevenUserTypes.Operator : SevenUserTypes.Anonymous;
  const cardSerial = customerCardStore.getCustomerCardUserData().card_serial;
  const { balance } = useUserFundsStore();

  const loadData: IframeLoadData = {
    company: { // deprecated
      currency: sevenStore.tenant.currency, // moved to currency
      datacenter: sevenStore.tenant.datacenter, // deprecated
      id: sevenStore.tenant.id, // deprecated, moved to tenant
      locale: sevenStore.tenant.locale, // deprecated, moved to locale.iso2
      name: sevenStore.tenant.name, // deprecated, moved to tenant
      uuid: sevenStore.tenant.uuid, // deprecated, moved to tenant
      fractionDigits: sevenStore.tenant.fractionDigits, // deprecated, moved to tenant
    },
    account: {
      // will be dereacted, token will be used instead of uuid and pass
      uuid: localAppConfigService.data.appConfig.terminalAccount.uuid,
      // will be dereacted, token will be used instead of uuid and pass
      password: localAppConfigService.data.appConfig.terminalAccount.password,
    },
    betshop: {
      id: sevenStore.betshop.id,
      uuid: sevenStore.betshop.uuid,
    },
    channel: 'Terminal',
    currency: {
      symbol: sevenStore.tenant.currency,
      virtualSymbol: sevenStore.tenant.currency,
      displayName: sevenStore.tenant.currency, // deprecated, use symbol
    },
    device: {
      id: sevenStore.device.deviceId,
      uuid: sevenStore.device.uuid,
      deviceUuid: sevenStore.device.uuid, // deprecated
      name: sevenStore.device.name,
    },
    locale: {
      iso1: langStore.currentLang?.iso1 || sevenStore.tenant.locale,
    },
    terminal: {
      uuid: sevenStore.terminal.uuid,
      balance,
    },
    dp: 'Terminal', // deprecated
    ENV: useENVStore().data.type,
    scrollButtons: configSettingsStore.data.scrollButtons, // deprecated
    betslip: {
      blockers: BetslipService.isBlocked(productDisplayId) || [],
    },
    peripherals: Peripherals.getState(),
    product: data?.product,
    settings: {
      moneyDenominations: sevenStore.terminal.moneyDenominations,
      scrollButtons: configSettingsStore.data.scrollButtons,
      oddType: oddsTypeStore.activeOddType.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      showCursor: configSettingsStore.data.terminalCursor.value,
      virtualKeyboard: {
        layout: virtualKeyboardService.getKeyboardLayout(),
      },
      numberFormat: configSettingsStore.data.numbersFormat.value,
    },
    tenant: {
      name: sevenStore.tenant.name,
      uuid: sevenStore.tenant.uuid,
      deviceUuid: sevenStore.terminal.deviceUuid,
    },
    translations: { // deprecated
      game: t('main.game'),
      ticketId: t('main.ticket_id'),
      stake: t('main.stake'),
      winnings: t('main.winnings'),
      payout: t('main.payout'),
      status: t('main.status'),
      type: t('main.type'),
      event: t('main.event'),
      bet: t('match.bet'),
      payin: t('main.payin'),
      payinTax: t('main.payin_tax'),
      payoutTax: t('main.payout_tax'),
    },
    user: {
      auth: {
        // will be dereacted, token will be used instead of uuid and pass one day
        // (after platofrm provides token)
        password: localAppConfigService.data.appConfig.terminalAccount.password,
        token: localStorage.getItem('user.token') || '',
      },
      uuid: localAppConfigService.data.appConfig.terminalAccount.uuid,
      type: userType.toUpperCase(),
    },
    cashRegister: {
      uuid: sevenStore.terminal.cashRegisterUuid,
    },
  };

  if (data?.account) {
    // We can receive password and uuid in data as '', so they would
    // overwrite those that are populated from localAppConfigService.
    // Which would result that password and uuid are not send to slave at all.
    // So we need special handling here, to take only values that are not empty strings.
    loadData.account = {
      ...loadData.account,
      ...Object.fromEntries(
        Object.entries(data.account).filter(([, value]) => value !== ''),
      ),
    };
  }

  if (data?.ticket) {
    loadData.ticket = data.ticket;
  }

  if (data?.config) {
    loadData.config = data.config;
  }

  if (cardSerial) {
    loadData.customerCard = {
      card: {
        serial: customerCardStore.getCustomerCardUserData().card_serial,
      },
    };
  }

  return loadData;
};

const getProductData = (game: ClientGame): AdditionalGatewayLoadData => ({
  account: {
    linkId: game.account.linkId,
    password: '',
    uuid: '',
    // uuid: product.uuid, we cannot send this until we
    // move current account.uuid to another node
  },
  product: { // deprecated, use account node
    cpvUuid: game.account.linkId, // moved to account.linkId
    translation: game.info.translation, // deprecated
    uuid: game.account.uuid, // will be moved to account.uuid (see comment in account section)
  },
  // deprecated
  config: game.gameConfig,
});

export {
  generateIframeLoadData,
  getProductData,
};
