import { integratorService } from '@/modules/integrator';
import {
  generateIframeLoadData,
  getProductData,
} from '@/modules/gateway/utilsService';
import gravityGatewayEventsHandler from './services/gravityGatewayEventsHandler';
import TGatewayIframe from './TGatewayIframe.vue';

const initGravityGateway = () => {
  gravityGatewayEventsHandler.attachListeners();
  integratorService.registerIntegratorService(
    'gravityGateway',
    gravityGatewayEventsHandler,
  );
};

export type {
  GravityIframeProductData,
  GravityIframeConfig,
  GravityIframeBindings,
  IframeLoadData,
} from './types';

export {
  TGatewayIframe,
  generateIframeLoadData,
  getProductData,
  gravityGatewayEventsHandler,
  initGravityGateway,
};
