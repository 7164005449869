import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

import { computed, ComputedRef, toRaw } from 'vue';
import { useWidgetsStore, type Widget } from '@/common/stores/widgets';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetsRenderer',
  props: {
    position: {}
  },
  setup(__props: any) {

const props = __props;

const widgetsStore = useWidgetsStore();
const widgets: ComputedRef<Array<Widget>> = computed(() => widgetsStore
  .getWidgetsByPosition(props.position));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" widget.component is a Proxy so toRaw is used "),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(widgets.value, (widget) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(toRaw(widget.component)), {
        key: widget.name
      }))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}
}

})