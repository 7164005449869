import { useLanguageStore } from '@/common/stores/language';

(function () {
  angular.module('7Terminal.Core')
    .provider('lang', langProvider);

  /**
   * @memberOf module:"7Terminal.Core"
   * @class langProvider
   */
  function langProvider() {
    var self = this;

    /**
     * @memberOf module:"7Terminal.Core".langProvider
     * @type {String}
     */
    self.currentLang = '';

    /**
     * @memberOf module:"7Terminal.Core".langProvider
     */
    this.$get = function () {
      const langStore = useLanguageStore();
      self.currentLang = langStore.currentLang?.value?.id || 'en';
      return self;
    };
  }
})();
