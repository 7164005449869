<script lang="ts" setup>
import { computed, ComputedRef, toRaw } from 'vue';
import { useWidgetsStore, type Widget } from '@/common/stores/widgets';

const props = defineProps<{
  position: string
}>();

const widgetsStore = useWidgetsStore();
const widgets: ComputedRef<Array<Widget>> = computed(() => widgetsStore
  .getWidgetsByPosition(props.position));

</script>

<template>
  <!-- widget.component is a Proxy so toRaw is used -->
  <Component
    :is="toRaw(widget.component)"
    v-for="widget in widgets"
    :key="widget.name"
  />
</template>
