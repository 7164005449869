import axios from 'axios';
import * as tokenInterceptor from '@/modules/interceptors/tokenInterceptor';
import * as sevenInterceptor from '@/modules/interceptors/sevenInterceptor';
import * as httpInterceptor from '@/modules/interceptors/httpInterceptor';
import { useLanguageStore } from '@/common/stores/language';
import { SevenApiRoutes, SevenAppType } from '@/modules/seven';
import {
  type SevenAuthCredentials,
} from './types';

const axiosInstance = axios.create({});
const defaultErrorHandler = (error: any) => Promise.reject(error);

axiosInstance.interceptors.request.use(sevenInterceptor.requestSuccess, defaultErrorHandler);
axiosInstance.interceptors.request.use(tokenInterceptor.requestSuccess, defaultErrorHandler);
axiosInstance.interceptors.request
  .use(httpInterceptor.requestSuccess, httpInterceptor.requestError);

axiosInstance.interceptors.response.use(tokenInterceptor.responseSuccess, defaultErrorHandler);
axiosInstance.interceptors.response
  .use(httpInterceptor.responseSuccess, httpInterceptor.responseError);

const authorizeOperator = (data: SevenAuthCredentials) => {
  const langStore = useLanguageStore();
  return axiosInstance.post(
    `${SevenApiRoutes.routes.mainUrl}/jwt/login_check`,
    data,
    {
      headers: {
        'SEVEN-APP': SevenAppType.TerminalAdmin,
        'SEVEN-LOCALE': langStore.currentLang?.id || 'en',
      },
      timeout: 60000,
    },
  );
};

export {
  authorizeOperator,
};
