import { getService } from '@/common/services/ngBridge';
import HooksManagerVue from '@/common/services/HooksManager';
import type { Ticket } from '@/modules/seven-betslip-api';
import { WidgetSourceType, useWidgetsStore } from '@/common/stores/widgets';
import { useIntelligentGamingStore } from './store';
import PlayerInformationModal from './components/PlayerInformationModal.vue';
import ProvinceNotSetModal from './components/ProvinceNotSetModal.vue';
import { useGravitySettingsStore } from '../cms/gravity-settings';
import { checkProvinceForBetshop } from './provinceService';

const registerWidgets = () => {
  const widgetsStore = useWidgetsStore();
  widgetsStore.registerWidget({
    name: 'IntelligentGamingPlayerInformationModal',
    component: PlayerInformationModal,
    order: 1,
    source: {
      type: WidgetSourceType.VUE_COMPONENT,
    },
    positions: [
      {
        id: 'appModals',
      },
    ],
  });

  widgetsStore.registerWidget({
    name: 'IntelligentGamingProvinceNotSetModal',
    component: ProvinceNotSetModal,
    order: 1,
    source: {
      type: WidgetSourceType.VUE_COMPONENT,
    },
    positions: [
      {
        id: 'appModals',
      },
    ],
  });
};

const registerHooks = () => {
  const HooksManager: any = getService('HooksManager');
  const gravitySettingsStore = useGravitySettingsStore();
  const intelligentGamingStore = useIntelligentGamingStore();
  const config = gravitySettingsStore.getByKey('module.IntelligentGaming');

  // Before ticket payin
  HooksManager.getHook('BeforeTicketPayin').tapPromise({
    name: 'BeforeTicketPayin.IntelligentGamingPlayerInformation',
    fn({ data: { localTicket } }: { data: { localTicket: Ticket } }) {
      const payinAmount = localTicket.getPayment();

      if (payinAmount > config.payinAmountTreshold) {
        intelligentGamingStore.setTicket(localTicket);
        intelligentGamingStore.setIsModalShown(true);
        intelligentGamingStore.setTransactionType('Bet');

        return new Promise((resolve, reject) => {
          intelligentGamingStore.setResolveCallback(resolve);
          intelligentGamingStore.setRejectCallback((value) => {
            reject(value);
          });
        });
      }

      return Promise.resolve();
    },
  });

  // Before ticket payout
  HooksManagerVue.BeforeTicketPayout.tapPromise({
    name: 'BeforeTicketPayout.IntelligentGamingPlayerInformation',
  }, ({ ticket }: { ticket: Ticket }) => {
    const payoutAmount = ticket.getPayoutAmountWithTax();

    if (payoutAmount > config.payoutAmountTreshold) {
      intelligentGamingStore.setTicket(ticket);
      intelligentGamingStore.setIsModalShown(true);
      intelligentGamingStore.setTransactionType('Payout');

      return new Promise((resolve, reject) => {
        intelligentGamingStore.setResolveCallback(resolve);
        intelligentGamingStore.setRejectCallback((value) => {
          reject(value);
        });
      });
    }

    return Promise.resolve();
  });
};

const init = () => {
  registerHooks();
  registerWidgets();
  checkProvinceForBetshop();
};

export {
  init,
};
