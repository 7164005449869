angular.module('7Terminal.Common').directive('cursor', function cursor() {
  /**
     * sets cursor visibility, depending on global (bootstrap/admin) settings
     */
  return {
    link: function (scope, element, attrs) {
      scope.$watch(attrs.cursorState, function (value) {
        $('body').css('cursor', (value ? 'pointer' : 'none'));
      });
    }
  };
});
