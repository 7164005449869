import { toRaw } from 'vue';
import { omit } from 'underscore';
import { SubscribeCallback } from '@nsftx/seven-gravity-gateway/master';
import {
  TNotificationConfig,
  TNotificationTypeEnum,
  useNotificationsStore,
  type TNotificationAction,
} from '@/common/stores/notifications';
import { integratorService } from '@/modules/integrator';
import { useWidgetsStore } from '@/common/stores/widgets';
import { IntegratorNotificationShow } from '../types';

const emitAction = (
  params: {
    notification: TNotificationConfig,
    action: TNotificationAction,
    slaveId: string,
  },
) => {
  const { notification, action, slaveId } = params;
  const widgetStore = useWidgetsStore();
  const widget = widgetStore.getWidgetByFrameId(slaveId);
  const finalNotification = toRaw(notification);

  finalNotification.actions = finalNotification.actions?.map((notiAction) => {
    const oldAction = notiAction;
    delete oldAction.callback;
    delete oldAction.callbackParams;
    return oldAction;
  });

  integratorService.sendEvent('Widget.Event', {
    context: {
      productId: slaveId,
      widgetType: widget?.type,
    },
    data: {
      type: 'DialogAction',
      action: omit(toRaw(action), ['callback', 'callbackParams']),
      notification: finalNotification,
    },
  });
};

const handleGatewayDialogShow: SubscribeCallback<
IntegratorNotificationShow
> = (
  data,
) => {
  const notificationsStore = useNotificationsStore();
  const newEvent = data;
  newEvent.data.actions = Array.isArray(newEvent?.data?.actions)
    ? newEvent.data.actions?.map((action) => {
      const oldAction = {
        ...action,
      };
      const newAction = {
        ...oldAction,
        callbackParams: {
          notification: data.data,
          action,
          slaveId: data.slaveId,
        },
        callback: emitAction,
      };
      return newAction;
    }) : [];

  notificationsStore.show(newEvent.data);
};

const handleGatewayDialogClose: SubscribeCallback<
{ id: string }
> = (
  data,
) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId(data.data.id);
};

const handleGatewayNotificationShow: SubscribeCallback<
{ description: string, type: TNotificationTypeEnum, timeout: number }
> = (
  data,
) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.show({
    message: data.data.description,
    type: data.data.type || 'info',
    delay: data.data.timeout,
  });
};

export {
  handleGatewayDialogShow,
  handleGatewayDialogClose,
  handleGatewayNotificationShow,
};
