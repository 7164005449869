import templateUrl from './datepicker.html';
import DatepickerController from './datepickerController';

(function () {
  angular.module('7Terminal.Common')
    .directive('datepicker', function datepicker(
      $timeout
    ) {
      return {
        transclude: true,
        require: 'ngModel',
        scope: {
          ngModel: '=',
          yearVisible: '='
        },
        link: function (scope, el) {
          el.on('click', function () {
            scope.parseDate(scope.ngModel);

            $timeout(function () {
              scope.toggleDatepicker();
              scope.selectPartial('month');
            });
          });

          scope.applyDate = function () {
            var finalDate;

            scope.validateDate();

            finalDate = new Date(
              scope.selected.year,
              scope.selected.month - 1,
              scope.selected.day
            );

            scope.ngModel = finalDate;
          };
        },
        controller: DatepickerController,
        templateUrl: templateUrl
      };
    });
})();
