import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { apiService as lpsSocketApiService } from '@/modules/lps-socket';
import { useSevenStore } from '@/modules/seven';
import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { safeAppReload } from '@/common/services/appHelpersService';
import type { SevenLanguage } from './types';

const defaultLangObj = {
  id: 'en',
  iso1: 'en',
  iso3: 'eng',
  name: 'English',
};
const LOG_PREFIX = '[languageStore]';

const useLanguageStore = defineStore('languageStore', () => {
  const currentLang = ref<SevenLanguage | undefined>();
  const availableLanguages = ref<Array<SevenLanguage>>([]);
  const disableLanguageSelector = ref(false);

  const normalizeLanguage = (language: string): string => language.substring(0, 2);

  const mergeLanguages = (serverLangList: Array<string>): void => {
    const gravitySettingsStore = useGravitySettingsStore();
    const sevenStore = useSevenStore();
    const cmsLanguages: Array<SevenLanguage> = gravitySettingsStore.getModuleDataKeyValue('languages', 'supportedLanguages');
    const companyLang = sevenStore.tenant.locale;
    let companyLangSupported = false;
    availableLanguages.value.length = 0;

    serverLangList.forEach((serverLang: string) => {
      // note: in cause of suffixed lang use always first two letters
      const cmsLang = cmsLanguages
        .find((lang:SevenLanguage) => lang.id === normalizeLanguage(serverLang));
      const alreadyAdded = !!availableLanguages.value
        .find((lang: SevenLanguage) => lang.id === normalizeLanguage(serverLang));

      if (cmsLang && !alreadyAdded) {
        if (cmsLang.id === normalizeLanguage(companyLang)) {
          companyLangSupported = true;
        }

        availableLanguages.value.push(cmsLang);
      }
    });

    // If company language is not on the list, add it
    if (!companyLangSupported) {
      const companyLangObject = cmsLanguages
        .find((lang:SevenLanguage) => lang.id === normalizeLanguage(companyLang));

      if (companyLangObject) {
        availableLanguages.value.unshift(companyLangObject);
      }
    }
  };
  const isLanguageSupported = (language: string): boolean => !!availableLanguages.value
    .find((lang:SevenLanguage) => lang.id === language);

  const getAvailableLanguage = (language: string): SevenLanguage | undefined => availableLanguages
    .value.find((lang:SevenLanguage) => lang.id === normalizeLanguage(language));

  const getLanguageById = (language: string): SevenLanguage => {
    const sevenStore = useSevenStore();
    let lang: SevenLanguage;

    // handle specific situation when lang is set before we fetch available langs
    if (availableLanguages.value.length === 0) {
      return {
        id: language,
        iso1: language,
        iso3: language,
        name: language,
      };
    }

    // verify that currently set language is supported
    // and do fallback if it's not
    if (isLanguageSupported(language)) {
      lang = availableLanguages.value
        .find((item:any) => item.id === normalizeLanguage(language)) || defaultLangObj;
    } else {
      const globalLang = getAvailableLanguage(language);
      const companyLang = getAvailableLanguage(sevenStore.tenant.locale);

      if (globalLang) {
        lang = globalLang;
      } else if (companyLang) {
        lang = companyLang;
      } else {
        // just take first language from of locally available languages
        // eslint-disable-next-line prefer-destructuring
        lang = availableLanguages.value[0];

        if (!lang) { // if languages not yet loaded
          lang = defaultLangObj;
        }
      }
    }

    return lang;
  };

  const setCurrentLanguage = (language: string) => {
    currentLang.value = getLanguageById(language);
    localStorage.setItem('settings.language', currentLang.value?.id);
  };

  const setLanguages = (): void => {
    const configSettingsStore = useConfigSettingsStore();
    mergeLanguages(configSettingsStore.data.availableLanguages.value);
    setCurrentLanguage(currentLang.value?.id || '');// currentLang can be set before calling setLanguages
  };

  const changeLanguage = (newLang: string) => {
    setCurrentLanguage(newLang);
    lpsSocketApiService.dipatchOddsLanguageChange({
      language: newLang,
    })
      .then(() => lpsSocketApiService.setOddsLanguageState({
        language: currentLang.value?.id || '',
      }))
      .catch((err) => {
        logService.error('[languageStore] Failed to publish language change to LPS', {
          ...errorParser.parseUpstream(err),
          code: 'T_ERROR_LPS_PUBLISH_STATE',
        });
      })
      .finally(() => {
        logService.info(`${LOG_PREFIX} Reload on language change.`, {
          code: 'T_LANGUAGE_RELOAD_APP',
        });
        safeAppReload();
      });
  };

  const getCurrentLanguage = (): string => currentLang.value?.id || '';

  const shouldHideLangPicker = (): boolean => availableLanguages.value.length <= 1
    && disableLanguageSelector.value;

  const setDisableLanguageSelector = (setting: boolean): void => {
    disableLanguageSelector.value = setting;
  };

  return {
    availableLanguages,
    currentLang,
    disableLanguageSelector,
    setDisableLanguageSelector,
    setLanguages,
    changeLanguage,
    setCurrentLanguage,
    getCurrentLanguage,
    shouldHideLangPicker,
  };
});

export {
  useLanguageStore,
};
