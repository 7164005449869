type AcceptorsStatus = Record<string, {
  status: boolean
}>;

type MoneyNotificationEvent = { // TODO in future move this type to pheripherals module
  message : {
    amount: number
  },
  type: string
};

type AcceptorTransaction = { // received from backend
  value: string,
  device: string,
  status: string,
  time: number | string | Date
};

type TerminalAcceptorTransaction = AcceptorTransaction & { // formatted for front
  device: AcceptorName,
  moneyUuid: string,
  status: AcceptorsTransactionStatus,
  timeObject?: Date,
  time?: Date,
  value: number | string
};

type AcceptorTransactionHistoryResponse = {
  transactions: Array<AcceptorTransaction>
};

type AcceptorApoen = {
  device: 'BillAcceptor' | 'CoinAcceptor',
  value: number,
  count: number,
  pending?: string,
};

type AcceptorAppoensReportResponse = {
  apoens: Array<AcceptorApoen>,
  message: string,
  pending: string,
  status_code: number
  totalSum: number,
};

type AcceptorType = 'bill' | 'coin';

type AcceptorHookErrorResponse = {
  id?: string,
  message: string,
  code: string
};

type AcceptorHookParam = {
  errorResponses: Array<AcceptorHookErrorResponse>
};

enum AcceptorsTransactionStatus {
  DELIVERED = 'delivered',
  PENDING = 'pending', // will be deprecated in favor of unprocessed
  FAILED = 'failed', // when platform returns unknown error to nbs
  UNPROCESSED = 'unprocessed', // transaction is not sent but money is inside bill&coin
  OPEN = 'open', // transaction is sent but no confirmation/rejection was received from platform
  REJECTED = 'rejected', // rejected from platform
  CANCELED = 'canceled',
}

enum AcceptorName {
  CoinAcceptor = 'coin_acceptor', // paylink name
  BillAcceptor = 'bill_acceptor', // paylink name
  bill_acceptor = 'bill_acceptor', // old b&c service name
  coin_acceptor = 'coin_acceptor', // old b&c service name
}

enum AcceptorAction {
  START = 'START',
  STOP = 'STOP',
}

export type {
  AcceptorsStatus,
  MoneyNotificationEvent,
  AcceptorTransaction,
  TerminalAcceptorTransaction,
  AcceptorAppoensReportResponse,
  AcceptorApoen,
  AcceptorType,
  AcceptorTransactionHistoryResponse,
  AcceptorHookErrorResponse,
  AcceptorHookParam,
};

export {
  AcceptorsTransactionStatus,
  AcceptorName,
  AcceptorAction,
};
