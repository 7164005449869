/* eslint-disable import/no-cycle */
import angular from 'angular';
import router from 'angular-ui-router';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import { errorParser } from '@/common/services/error-parser';
import commonModule from '../common';
import gravityModule from '../modules/gravity';
import modulesModule from '../modules/index';
import switchView from './services/switchViewService';
import scanService from './services/scanService';
import SevenScmSvc from './services/sevenScmService';

/**
  * @ngdoc overview
  * @name 7Terminal.Core
  *
  * @description
  * Core logic used across application.
  *
  * @example
  *  <b>script.js</b>
  *  <pre>
  *  import coreModule from './core'
  *  angular.module('myModule', [coreModule]);
  *  </pre>
  *
*/
angular.module('7Terminal.Core', [
  router,
  'SevenClientLib',
  'SevenClientCore',
  'SevenClient.Config',
  commonModule,
  gravityModule,
  modulesModule
])
  .service('switchView', switchView)
  .service('errorParser', function ErrorParser() {
    return errorParser;
  })
  .service('ScanService', scanService)
  .service('SevenScmSvc', SevenScmSvc)
  .run(function (ScanService) {
    ScanService.listenForScannerEvents();
  });
