<script setup lang="ts">
import { ref } from 'vue';
import {
  SCRadioButton,
  SCButton,
  ButtonVariant,
  SCScrollButtons,
  SCModalNext,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useLanguageStore } from '@/common/stores/language';
import { vGaTrackEvent } from '@/modules/google-analytics';

type LanguagePickerEmits = (e: 'close') => void;

const { t } = i18n.global;
const emit = defineEmits<LanguagePickerEmits>();

const langStore = useLanguageStore();
const selectedItem = ref<string>(langStore.currentLang?.id || '');
const languagePickerRef = ref<HTMLElement | null>(null);
const showScrollButtons = ref(false);

const closeModal = () => {
  emit('close');
};

const saveLanguage = () => {
  langStore.changeLanguage(selectedItem.value);
  closeModal();
};

const onLangSelected = (value: string) => {
  selectedItem.value = value;
};
</script>

<template>
  <SCModalNext
    with-close-icon
    @close="closeModal()"
    @opened="showScrollButtons=true"
  >
    <template #title>
      {{ t('main.language') }}
    </template>
    <template #content>
      <div
        ref="languagePickerRef"
        class="w-100 lang-picker d-flex flex-column overflow-auto"
      >
        <SCRadioButton
          v-for="lang in langStore.availableLanguages"
          :id="lang.id"
          :key="lang.id"
          :selected-value="selectedItem"
          :value="lang.id"
          :label="lang.name"
          class="sc-mb-3"
          block
          @update:model-value="onLangSelected"
        >
          <i
            class="sc-pr-7 ml-auto"
            :class="`flag flag-${lang.iso3.toUpperCase()}`"
          />
        </SCRadioButton>
      </div>
      <SCScrollButtons
        v-if="showScrollButtons"
        :content="languagePickerRef"
      />
    </template>

    <template #actions>
      <SCButton
        v-ga-track-event="{
          eventName: 'Language_Change',
          eventParams: { action: 'Language Change (on Save)' }
        }"
        :variant="ButtonVariant.Primary"
        size="md"
        width="100%"
        :disabled="!selectedItem"
        data-testid="saveButton"
        @click="saveLanguage"
      >
        {{ t('actions.save') }}
      </SCButton>
    </template>
  </SCModalNext>
</template>
