import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, isRef as _isRef, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, toRefs } from 'vue';
import { SCInput, SCKeyboard } from '@nsftx/seven-components';

export interface TPasswordInputProps {
  modelValue: string;
  label?: string;
  error?: boolean;
  hint?: string;
  type?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TPasswordInput',
  props: {
    modelValue: { default: '' },
    label: { default: '' },
    error: { type: Boolean, default: false },
    hint: { default: '' },
    type: { default: 'password' }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  modelValue,
  label,
} = toRefs(props);

const emit = __emit;

const emitModelValue = (value: string) => {
  emit('update:modelValue', value);
};

const showKeyboard = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SCKeyboard), {
    modelValue: _unref(modelValue),
    "onUpdate:modelValue": [
      _cache[1] || (_cache[1] = ($event: any) => (_isRef(modelValue) ? (modelValue).value = $event : null)),
      emitModelValue
    ],
    show: showKeyboard.value,
    "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((showKeyboard).value = $event)),
    class: "w-100"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(SCInput), {
        modelValue: _unref(modelValue),
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (_isRef(modelValue) ? (modelValue).value = $event : null)),
          emitModelValue
        ],
        block: "",
        class: _normalizeClass([_ctx.type === 'password' ? 'password-field' : '', "mb-auto"]),
        label: _unref(label),
        error: _ctx.error,
        hint: _ctx.hint
      }, {
        icon: _withCtx(() => [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["modelValue", "class", "label", "error", "hint"])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "show"]))
}
}

})