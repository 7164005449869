import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  type SevenModuleGeneral,
  type SevenModuleGame,
} from '@/modules/seven';

const useAppModulesStore = defineStore('appModules', () => {
  const appModules = ref<Array<SevenModuleGeneral | SevenModuleGame>>([]);

  const getGeneralModules = () => appModules
    .value.filter((m): m is SevenModuleGeneral => m.type === 'general');

  return {
    appModules,
    getGeneralModules,
  };
});

export {
  useAppModulesStore,
};
