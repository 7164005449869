(function (_7) {
  function sevenSportFormatPayin() {
    /**
         * @param {Object} params
         * @param {Ticket} params.ticket - instance of ng ticket
         * @param {Object} params.ticketType
         */
    return function formatter(params) {
      var ticket = params.ticket;
      var ticketType = params.ticketType;
      var bets = ticket.bets;
      var result = {
        bets: [],
        payin: ticket.getStake(),
        ticketComment: '',
        ticketCombinationGroups: [],
        ticketOddsOptions: ticket.config.ticketOddsOptions
      };
      var j = 0;
      var isBanker;
      var activeSystems;

      result.ticketType = ticketType.id;

      for (j; j < bets.length; j++) {
        // eslint-disable-next-line eqeqeq
        isBanker = (ticketType.id == 1 || (ticketType.id == 2 && bets[j].banker)) ? 1 : 0;

        result.bets.push({
          idMatchBetOutcome: bets[j].idMbo,
          ticketBetBank: isBanker,
          ticketBetWay: 0,
          idBet: bets[j].idMb,
          outcomeType: bets[j].mboType,
          outcomeOdd: bets[j].mboOddValue,
          mboOddValue: bets[j].mboOddValue,
          betSpecialValue: bets[j].mbSpecialValue,
          gameName: bets[j].gameName
        });
      }

      // if system
      if (ticketType.name === 'system') {
        activeSystems = ticket.systems.getActiveSystems();
        // eslint-disable-next-line guard-for-in
        activeSystems.forEach(function (system) {
          result.ticketCombinationGroups.push({
            parlays: system.id,
            events: system.of
          });
        });
      }

      // if singles, we need to set them like systems
      if (ticketType.name === 'single') {
        result.ticketCombinationGroups.push({
          parlays: 1,
          events: bets.length
        });
      }

      result.payin = ticket.stake;

      return result;
    };
  }

  function sevenVirtualFormataPayin() {
    /**
         * @param params
         * @param {Object} params.ticket - instance of ng ticket
         * @param {Object} params.ticketType
         */
    return function formatter(params) {
      var ticket = params.ticket;
      var bets = ticket.bets;
      var result = { bets: [], payin: 0 };
      var j = 0;

      for (j = 0; j < bets.length; j++) {
        result.bets.push({
          payin: bets[j].stake ? bets[j].stake : false,
          type: bets[j].id,
          value: bets[j].value,
          numEvents: bets[j].numEvents ? bets[j].numEvents : 1
        });

        result.payin += bets[j].stake;
      }
      return result;
    };
  }

  _7.registerService('sevenSportFormatPayin', sevenSportFormatPayin);
  _7.registerService('sevenVirtualFormataPayin', sevenVirtualFormataPayin);
// eslint-disable-next-line angular/window-service
})(window._7);
