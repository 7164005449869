/**
 *
 * @ngInject
 */
function numPadInput($rootScope, $timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      numPadCssClass: '@'
    },
    link: function (scope, el, attr, ngModel) {
      // grab input id defined by programmer
      const id = attr.numPadInput;
      let eventData;
      $rootScope.numPadCssClass = scope.numPadCssClass;

      // set listener for receiving value from numPad
      const setInputListener = $rootScope.$on('NumPad:setInputValue', function (e, data) {
        if (data.id === id) {
          ngModel.$setViewValue(data.value);
          ngModel.$render();
        }
      });

      // When input focused send id and value to numPad
      el.on('focus', function () {
        eventData = {
          id: id,
          value: ngModel.$viewValue
        };
        if (attr.numPadContent) {
          eventData.numPadContent = attr.numPadContent;
        }
        $timeout(function () {
          $rootScope.$broadcast('NumPad:focusInput', eventData);
        });
      });

      scope.$on('$destroy', function () {
        setInputListener();
      });
    }
  };
}

export default numPadInput;
