import * as customerCardService from './customerCardService';

// called by Module.loadModule
const init = () => customerCardService.init();

export { useCustomerCardStore } from './store';
export type {
  SevenCustomerCard,
  SevenCustomerCardTask,
  CustomerCardUserData,
} from './types';
export { customerCardService, init };
