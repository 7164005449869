<script setup lang="ts">
import PubSub from 'pubsub-js';
import { onUnmounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import {
  SCInput,
  SCButton,
  ButtonVariant,
  SCKeyboard,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { authService, useAuthStore } from '@/modules/auth';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import TModal from '@/common/components/TModal.vue';
import QrCode from '@/common/components/QrCode.vue';
import { logService } from '@/common/services/logger';
import TPasswordInput from '@/common/components/TPasswordInput.vue';
import { googleAnalyticsService } from '@/modules/google-analytics';
import { ACTIVITY_SESSION_END_EVENT_ID, activitySessionEndEventPubSub } from '@/common/services/idle/activitySessionEndEvent';

const authStore = useAuthStore();
const isLoginInProgress = ref(false);
const enteredUsername = ref('');
const enteredPassword = ref('');
const usernameInputChanged = ref(false);
const passwordInputChanged = ref(false);
const { preventLoginModalClose, isLoginModalOpened } = storeToRefs(authStore);
const showUsernameError = computed(() => !enteredUsername.value && usernameInputChanged.value);
const showPasswordError = computed(() => !enteredPassword.value && passwordInputChanged.value);
const isFormValid = computed(() => (!showUsernameError.value && !showPasswordError.value)
    && (usernameInputChanged.value && passwordInputChanged.value));
const showQR = ref(authService.isQrCodeEnabled());
const modalWidth = computed(() => (showQR.value ? '720px' : '480px'));
const contentClass = computed(() => (showQR.value ? '' : 'login-content'));
const showKeyboard = ref(false);

const { t } = i18n.global;

const notificationsStore = useNotificationsStore();
const ERRORS = [
  {
    status: 401,
    text: t('user.error_wrong_credentials'),
  },
  {
    status: 402,
    text: t('notifications.permissions_forbidden'),
  },
  {
    status: 403,
    text: t('notifications.request_too_long'),
  },
];

const inactivityTimeEndListener = activitySessionEndEventPubSub.subscribe(
  ACTIVITY_SESSION_END_EVENT_ID,
  () => {
    if (!preventLoginModalClose.value) {
      isLoginModalOpened.value = false;
    }
  },
);

onUnmounted(() => {
  if (inactivityTimeEndListener) {
    PubSub.unsubscribe(inactivityTimeEndListener);
  }
});

const onLoginClicked = () => {
  notificationsStore.closeNotificationWithId('login-err');
  isLoginInProgress.value = true;
  authService.login({
    username: enteredUsername.value,
    password: enteredPassword.value,
  }).then((response) => {
    isLoginInProgress.value = false;
    authService.switchToTBO(response.data);
  }).catch((err) => {
    isLoginInProgress.value = false;
    const fondErr = ERRORS.find((error) => error.status.toString() === err
      .response?.status?.toString());
    const message = fondErr?.text || err.message || err.response?.data?.message;
    logService.warn('[TLogin] Login failed', {
      upstream_message: message,
      err,
      code: 'T_LOGIN_FAILED',
    });
    googleAnalyticsService.trackEvent('Terminal_Backoffice', {
      event: 'TBO Login failed',
    });
    notificationsStore.show({
      id: 'login-err',
      message,
      type: TNotificationTypeEnum.error,
      delay: 6000,
    });
  });
};

const cleanUpForm = (value: boolean) => {
  enteredUsername.value = '';
  enteredPassword.value = '';
  usernameInputChanged.value = false;
  passwordInputChanged.value = false;
  isLoginInProgress.value = false;
  isLoginModalOpened.value = value;
};

</script>

<template>
  <TModal
    v-model="isLoginModalOpened"
    with-close-icon
    modal-id="t-login"
    :width="modalWidth"
    :prevent-close="preventLoginModalClose"
    top="88px"
    @update:model-value="cleanUpForm"
  >
    <template #title>
      {{ t('main.login') }}
    </template>
    <template #content>
      <div
        v-if="showQR"
        class="sc-mr-6 d-flex"
      >
        <div class="sc-p-6 qr-wrapper">
          <QrCode
            :config="authService.getQrCodeData()"
          />
          <p class="sc-text-secondary-1 sc-text-caption-2 qr-descritpion">
            {{ t('auth_qr_scan') }}
          </p>
        </div>
      </div>

      <form
        class="w-100 d-flex flex-column align-items-start"
        :class="contentClass"
        @submit.prevent="() => {}"
      >
        <SCKeyboard
          v-model="enteredUsername"
          v-model:show="showKeyboard"
          class="w-100"
          @update:model-value="usernameInputChanged = true"
        >
          <SCInput
            v-model="enteredUsername"
            :label="t('user.username')"
            :error="showUsernameError"
            :hint="showUsernameError ? t('validation_username_required') : ''"
            block
            data-testid="inputUsername"
            @input="usernameInputChanged = true"
          />
        </SCKeyboard>
        <TPasswordInput
          v-model="enteredPassword"
          class="sc-mt-2"
          :label="t('main.password')"
          :error="showPasswordError"
          :hint="showPasswordError ? t('validation_password_required') : ''"
          data-testid="inputPassword"
          @update:model-value="passwordInputChanged = true"
        />

        <SCButton
          :variant="ButtonVariant.Primary"
          width="100%"
          :disabled="!isFormValid || isLoginInProgress"
          :loading="isLoginInProgress"
          type="button"
          data-testid="loginButton"
          class="mt-auto"
          @click="onLoginClicked"
        >
          {{ t('main.log_in') }}
        </SCButton>
      </form>
    </template>
  </TModal>
</template>

<style scoped lang="scss">
.qr {
  &-descritpion {
    max-width: fit-content;
  }

  &-wrapper {
    background-color: #fff;
  }
}

.login-content {
  min-height: 248px;
}
</style>
