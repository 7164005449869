import { extend } from 'underscore';
import { logService } from '@/common/services/logger';
import { useNotificationsStore } from '@/common/stores/notifications';
import {
  type TicketResponse,
  type ProductTicketResponse,
  type TicketInstance,
  type ProductTicketSportsbookSMResponse,
  ClientTicketActions,
  betslipApiHelpers,
} from '@/modules/seven-betslip-api';
import { useTicketCheckStore } from '@/modules/ticket-check';
import { isTicketFailed, isTicketUnresolved } from './helpers';
import { useTicketsStore } from './ticketsStore';
import { TicketResolveResponse } from './types/TicketResolveResponse';
import actionStatusValidator from './actionStatusValidator';
import ticketStatusMapper from './ticketStatusMapper';
import TicketResolveError from './errors/TicketResolveError';

const LOG_PREFIX = '[ticketResolveService]';

const ticketActionsHandlers = {
  [ClientTicketActions.Payout]: actionStatusValidator.isPayoutSuccessful,
  [ClientTicketActions.Cancel]: actionStatusValidator.isCancelSuccessful,
};

const updateTicketStatus = (
  ticketResponse: TicketResponse | ProductTicketResponse,
  ticket: TicketInstance,
) => {
  const ticketsStore = useTicketsStore();
  const createdTicket = betslipApiHelpers.createTicket(ticketResponse);
  const ticketStatus = createdTicket.getStatus();
  const localStatus = ticketStatusMapper.mapServerStatusToLocal(ticketStatus);

  const ticketToUpdate = ticket;
  ticketToUpdate.localStatus = localStatus;

  ticketsStore.updateTicket(ticketToUpdate);
};

const handleTicketSuccess = (
  ticketFromStore: TicketInstance,
  ticketData: TicketResponse | ProductTicketResponse,
) : TicketResolveResponse => {
  const ticketsStore = useTicketsStore();

  logService.debug(`${LOG_PREFIX} Handling ticket success.`, {
    product_displayid: ticketFromStore.getProductDisplayId(),
    ticket_code: ticketFromStore.getDisplayId(),
    request_id: ticketFromStore.getRequestUuid(),
    action: ticketFromStore.action,
  });

  ticketsStore.endChecker(ticketFromStore);

  const method = ticketActionsHandlers[
    ticketFromStore.action as keyof typeof ticketActionsHandlers
  ];

  if (typeof method === 'function') {
    try {
      const reason = method(ticketData, ticketFromStore);
      const ticketCheckStore = useTicketCheckStore();
      const ticketInstance = betslipApiHelpers.createTicket(ticketData);

      ticketCheckStore.setResult(ticketInstance);
      updateTicketStatus(ticketData, ticketFromStore);

      return {
        localTicket: ticketFromStore,
        reason,
      };
    } catch (err) {
      ticketsStore.updateTicketActionAsFailed(ticketFromStore);
      throw err;
    }
  }

  const error = new Error('Could not handle ticket action', {
    cause: {
      ticket: ticketFromStore,
      serverResponse: ticketData,
    },
  });
  error.name = 'T_TICKET_RESOLVE_ACTION_UNKNOWN';
  throw error;
};

const resolveTicket = (
  ticketResponse: TicketResponse | ProductTicketResponse | ProductTicketSportsbookSMResponse,
  action: ClientTicketActions,
) : TicketResolveResponse => {
  const ticketsStore = useTicketsStore();
  const notificationsStore = useNotificationsStore();
  const createdTicket = betslipApiHelpers.createTicket(ticketResponse);
  const ticketFromStore = ticketsStore
    .getTicket(createdTicket.getRequestUuid(), createdTicket.getDisplayId(), action);

  logService.debug(
    `${LOG_PREFIX} Resolving ticket action.`,
    {
      request_id: createdTicket.getRequestUuid(),
      ticket_code: createdTicket.getDisplayId(),
      ticket: ticketResponse,
      product_displayid: createdTicket.getProductDisplayId(),
      action,
    },
  );

  if (!ticketFromStore) {
    const error = new TicketResolveError('Ticket not found in store', 'T_TICKET_NOT_IN_MEMORY', {
      context: {
        ticket: ticketFromStore,
        serverResponse: ticketResponse,
      },
    });
    throw error;
  }
  const mergedTicket = extend(ticketFromStore.ticket || {}, ticketResponse);
  ticketFromStore.ticket = mergedTicket;
  ticketsStore.updateTicket(ticketFromStore);

  // clear pending ticket actions, allow next ticket to be sent to server
  ticketsStore.removePendingAction(`${ticketFromStore.getProductDisplayId()}.${ticketFromStore.action?.toLowerCase()}`);
  notificationsStore.closeNotificationWithId('ticket.pending_action');

  if (!isTicketUnresolved(ticketFromStore) && !isTicketFailed(ticketFromStore)) {
    const error = new TicketResolveError('Ticket is already resolved.', 'T_TICKET_ALREADY_RESOLVED', {
      context: {
        ticket: ticketFromStore,
        serverResponse: ticketResponse,
      },
    });
    throw error;
  }

  return handleTicketSuccess(ticketFromStore, ticketResponse);
};

export {
  resolveTicket,
};
