/* eslint-disable import/no-extraneous-dependencies */
import { InternalAxiosRequestConfig } from 'axios';

function isAnonymousApi(url: string): boolean {
  return /api\/terminal/.test(url);
}

export const requestSuccess = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem('user.token');

  if (request.url && token && !request.headers.Authorization && !isAnonymousApi(request.url)) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

export const responseSuccess = (response: any): any => {
  const responseToken = response.headers['access-token'];

  if (responseToken) {
    localStorage.setItem('user.token', responseToken);
  }

  return response;
};
