class BaseError<T extends string> extends Error {
  public readonly context?: unknown;

  constructor(
    public message: string,
    public code: T,
    public options?: {
      cause?: unknown;
      context?: unknown
    },
  ) {
    const { cause, context } = options || {};
    super(message, { cause });
    Object.setPrototypeOf(this, BaseError.prototype);
    this.name = this.constructor.name;
    this.code = code;
    this.context = context;
  }
}

export default BaseError;
