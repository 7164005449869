import { DeviceInstaller } from '../interfaces/DeviceInstaller';

const LSInstaller: DeviceInstaller = {
  getData() {
    return Promise.resolve({
      appConfig: {
        data: JSON.parse(localStorage.getItem('device.appConfig') || ''),
      },
      device: {
        data: JSON.parse(localStorage.getItem('device.data') || ''),
      },
    });
  },
  run() {
    return localStorage.getItem('device.appConfig') ? Promise.resolve() : Promise.reject('App Config data not found in local storage');
  },
  save(data) {
    localStorage.setItem('device.appConfig', JSON.stringify({
      sevenUrl: data.sevenUrl,
      sevenSocketUrl: data.sevenSocketUrl,
      appConfig: {
        terminalAccount: data.terminalAccount,
      },
    }));
    localStorage.setItem('device.data', JSON.stringify(data));

    window.location.reload();
    return Promise.resolve();
  },
  onBack() {
    window.location.reload();
  },
  uninstall() {
    localStorage.removeItem('device.appConfig');
    localStorage.removeItem('device.data');
    return Promise.resolve();
  },
  switchUpperScreen() {
    return Promise.resolve();
  },
};

export {
  LSInstaller,
};
