<script lang="ts" setup>
import { computed } from 'vue';
import router from '@/router';
import { lobbyService } from '@/modules/lobby';

const index = computed(() => parseInt(router.currentRoute.value.params.id as string, 10));
const title = computed(() => lobbyService.getMenu()[index.value].title);
</script>

<template>
  <div class="sc-text-button sc-px-5 sc-ml-2">
    {{ title }}
  </div>
</template>
