import angular from 'angular';
import '@nsftx/seven-ng-client-greyhound-races/dist/greyhoundraces';
import commonModule from '../../common';
import coreModule from '../../core';
import gamesModule from '../index';
import resultsTemplate from '../results/results.html';
import resultsContentTemplate from '../results/racer_games/content.html';

angular.module('terminalApp.nabGreyhoundRacesUi', [
  'nabGreyhoundRaces',
  coreModule,
  commonModule,
  gamesModule
]).config(function ($stateProvider) {
  for (var i = 0; i < window.BootStrapData.clientAppModules.length; i++) {
    var config = window.BootStrapData.clientAppModules[i];
    if (config.id === 'GreyhoundRaces' || config.id === 'VirtualGreyhoundRaces') {
      window.SevenGreyhoundRaces.getConfigService()
        .set({
          companyUuid: window.BootStrapData.company.uuid
        });
    }
  }

  $stateProvider.state('app.games.greyhoundraces', {
    url: '/greyhoundRaces',
    template: '<greyhound-races-ui></greyhound-races-ui>'
  });

  $stateProvider.state('app.results.greyhoundraces', {
    url: '/greyhoundraces?id',
    templateUrl: resultsTemplate,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.greyhoundraces.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentTemplate,
        controller: 'RacerGameResultsController'
      }
    }
  });

  $stateProvider.state('app.games.virtualgreyhoundraces', {
    url: '/virtualGreyhoundRaces',
    template: '<greyhound-races-ui></greyhound-races-ui>'

  });

  $stateProvider.state('app.results.virtualgreyhoundraces', {
    url: '/virtualgreyhoundraces?id',
    templateUrl: resultsTemplate,
    controller: 'GamesResultsController'
  });

  $stateProvider.state('app.results.virtualgreyhoundraces.content', {
    url: '/?count&start_date&end_date',
    views: {
      resultsContent: {
        templateUrl: resultsContentTemplate,
        controller: 'RacerGameResultsController'
      }
    }
  });
}).run(function (
  GreyhoundRacesUiService,
  $rootScope
) {
  $rootScope.$on('GreyhoundRaces:ConfigLoaded', function (e, game) {
    $rootScope.$emit('GreyhoundRaces:SetRunningProduct', {
      runningProduct: '7Terminal'
    });

    GreyhoundRacesUiService.boot(game);
  });

  $rootScope.$on('7T:Slave.Shown', function (e, data) {
    if (data.productId === 'GreyhoundRaces'
            || data.productId === 'VirtualGreyhoundRaces') {
      $rootScope.$broadcast('7T:Betslip.ActivateTicket', {
        data: {
          betslipId: data.productId,
          ticketId: 'single'
        }
      });
    }
  });
});
