/* eslint-disable max-len */
import PubSub from 'pubsub-js';
import { logService } from '@/common/services/logger';
import { cardReadEventPubSub, CARD_READ_EVENT_ID } from '@/modules/card-reader';
import { SCAN_EVENT_ID } from '@/modules/handheld-scan';
import type { IdleServiceEvent } from './types';

const listeners: IdleServiceEvent[] = [];

const idleService = {
  getListenerIndexByName(name: string): number {
    return listeners.findIndex((item) => item.name === name);
  },
  clearEvents(index: number): void {
    const listener = listeners[index];

    if (listener.intervalInstance) clearInterval(listener.intervalInstance);

    if (listener.scannerListener) PubSub.unsubscribe(listener.scannerListener || '');

    if (listener.cardInListener) PubSub.unsubscribe(listener.cardInListener || '');

    if (listener.billAcceptorListener) PubSub.unsubscribe(listener.billAcceptorListener || '');

    if (listener.coinAcceptorListener) PubSub.unsubscribe(listener.coinAcceptorListener || '');
  },
  startInactivityTimer(name: string): void {
    const index = this.getListenerIndexByName(name);
    if (index === -1) {
      logService.debug('[idleService] Idle listener not found.', {
        name,
        code: 'T_IDLE_LISTENER_NOT_FOUND',
      });
      return;
    }

    if (listeners[index].intervalInstance) clearInterval(listeners[index].intervalInstance);

    listeners[index].intervalInstance = window.setInterval(() => {
      // fetch again index, because meanwhile some other listener can be removed,
      // so current one changes index
      const indexByName = this.getListenerIndexByName(name);
      if (indexByName === -1) return;

      listeners[indexByName].currentInactivityTime -= 1000;

      if (listeners[indexByName].additionalActionData) {
        const runOnlyOnce = listeners[indexByName]?.additionalActionData?.runOnlyOnce;
        const timeToRunAdditionalAction = listeners[indexByName]?.additionalActionData?.timeToRunAdditionalAction;
        const currentInactivityTime = listeners[indexByName]?.currentInactivityTime;

        if (!runOnlyOnce && (timeToRunAdditionalAction && (currentInactivityTime <= timeToRunAdditionalAction))) {
          listeners[indexByName]?.additionalActionData?.action(listeners[indexByName].currentInactivityTime);
        } else if (runOnlyOnce && (currentInactivityTime === timeToRunAdditionalAction)) {
          listeners[indexByName]?.additionalActionData?.action(listeners[indexByName].currentInactivityTime);
        }
      }

      if (listeners[indexByName].currentInactivityTime <= 0) {
        const disableResetListeners = listeners[indexByName]?.disableResetListeners;
        if (listeners[indexByName].onEndInactivityTimer) {
          listeners[indexByName].onEndInactivityTimer();
        }

        if (!disableResetListeners && listeners[indexByName]?.callResetAfterEndInactivity) {
          this.resetInactivityTimer(name);
        }

        if (!listeners[indexByName]?.callResetAfterEndInactivity) {
          this.endInactivityTimer(name);
        }
      }
    }, 1000);
  },
  registerListener(listenerData: IdleServiceEvent): void {
    if (this.getListenerIndexByName(listenerData.name) !== -1) {
      logService.debug('[idleService] Idle Service listener already registered.', {
        name: listenerData.name,
        code: 'T_IDLE_LISTENER_REGISTER_ERROR',
      });
      return;
    }

    let inactivityTime = listenerData.time;

    if (listenerData.transitionDelay) {
      inactivityTime = listenerData.time + listenerData.transitionDelay;
    }

    if (listenerData.additionalActionData) {
      // eslint-disable-next-line no-param-reassign
      listenerData.additionalActionData.timeToRunAdditionalAction = inactivityTime - (listenerData.additionalActionData.time || 0);
    }

    let { callResetAfterEndInactivity } = listenerData;

    if (callResetAfterEndInactivity === undefined) {
      callResetAfterEndInactivity = true;
    }

    listeners.push({
      ...listenerData,
      callResetAfterEndInactivity,
      inactivityTime,
      currentInactivityTime: inactivityTime,
    });

    this.runInactivityTimer(listenerData.name);
  },
  runInactivityTimer(name: string): void {
    const index = this.getListenerIndexByName(name);
    if (index === -1) {
      logService.debug('[idleService] Idle listener not found.', {
        name,
        code: 'T_IDLE_LISTENER_NOT_FOUND',
      });
      return;
    }

    this.startInactivityTimer(name);

    if (!listeners[index].disableResetListeners) {
      ['click', 'touchstart'].forEach((event) => {
        document.addEventListener(event, () => {
          this.resetInactivityTimer(name);
        });
      });

      listeners[index].scannerListener = PubSub.subscribe(SCAN_EVENT_ID, () => {
        this.resetInactivityTimer(name);
      });
      listeners[index].cardInListener = cardReadEventPubSub.subscribe(CARD_READ_EVENT_ID, () => {
        this.resetInactivityTimer(name);
      });
      listeners[index].coinAcceptorListener = PubSub.subscribe('CoinAcceptor:moneyProcessing', () => {
        this.resetInactivityTimer(name);
      });
      listeners[index].billAcceptorListener = PubSub.subscribe('BillAcceptor:moneyProcessing', () => {
        this.resetInactivityTimer(name);
      });
    }
  },
  resetInactivityTimer(name: string): void {
    const index = this.getListenerIndexByName(name);
    if (index === -1) {
      logService.debug('[idleService] Idle listener not found.', {
        name,
        code: 'T_IDLE_LISTENER_NOT_FOUND',
      });
      return;
    }

    // reset to begining, for ex. 40.000 ms
    listeners[index].currentInactivityTime = listeners[index].inactivityTime;
    if (listeners[index].intervalInstance) {
      clearInterval(listeners[index].intervalInstance);
    }
    if (listeners[index]?.onResetInactivityTimer) {
      listeners[index].onResetInactivityTimer?.();
    }

    this.startInactivityTimer(name);
  },
  cancelInactivityTimer(name: string): void {
    const index = this.getListenerIndexByName(name);
    if (index === -1) return;
    this.clearEvents(index);
    listeners.splice(index, 1); // remove listener from array
  },
  endInactivityTimer(name: string): void {
    const index = this.getListenerIndexByName(name);
    if (index === -1) return;

    if (listeners[index].intervalInstance) {
      clearInterval(listeners[index].intervalInstance);
    }
  },
};

export default idleService;
