angular.module('7Terminal.Common').provider('modalSvcConfig', function modalSvcConfig() {
  return {
    options: {
      rootEl: ''
    },
    $get: function () {
      return this.options;
    },
    set: function (keyOrHash, value) {
      var k; var v; var
        _results;
      if (typeof keyOrHash === 'object') {
        _results = [];
        // eslint-disable-next-line
        for (k in keyOrHash) {
          v = keyOrHash[k];
          _results.push(this.options[k] = v);
        }
        return _results;
      }
      // eslint-disable-next-line
      return this.options[keyOrHash] = value;
    }
  };
});
