import { errorParser } from './error-parser';
import { logService } from './logger';

const LOG_PREFIX = '[appHelperService]';

export function safeAppReload() {
  return logService.sendLogsAndClearLogsQueue()
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`${LOG_PREFIX} Failed to send logs on app reload`, {
        code: 'T_APP_SAFE_RELOAD_ERR',
        ...errorParser.parseUpstream(err),
      });
      // we don't want to block app reload in any case
      // especially if grape xhr fails
      return Promise.resolve();
    }).finally(() => {
      window.location.reload();
    });
}

export const isRunBySevenCli = () => {
  // We should improve this in future because this can lead to false positive.
  // CLI indeed sends debugMode=true by default but no one can guarantee that someone else
  // will provide same param in different contenxt
  // We must provide explicit flag when running shop from seven cli .e.g sevenCli=true
  const urlParams = new URLSearchParams(window.location.search);
  const debugMode = urlParams.get('debugMode');
  return debugMode;
};

export const isRunOnLocalhost = () => ['localhost', '127.0.0.1'].indexOf(window.location.hostname) >= 0;
