import { v4 as uuidV4 } from 'uuid';
import { toRaw } from 'vue';
import { useClientGamesStore } from '@/modules/client-games';
import {
  TicketInstance,
  LocalTicketStatus,
  TicketResponse,
  ProductTicketResponse,
  TicketMetaData,
  ClientTicketActions,
} from '@/modules/seven-betslip-api';
import { copyObject, mergeObjects } from '@/common/services/helpers';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { operatorService } from '@/modules/operator';
import { isTicketResponse } from '@/modules/seven-betslip-api/helpers';
import { localAppConfigService } from '@/common/services/local-app-config';
import { useSevenStore } from '@/modules/seven';
import { printService } from '@/modules/print';
import { logService } from '@/common/services/logger';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { TicketForPrint } from './types/TicketForPrint';

const isTicketUnresolved = (ticket: TicketInstance) => (
  ticket.localStatus === LocalTicketStatus.PENDING
  || ticket.localStatus === LocalTicketStatus.UNKNOWN
);

const isTicketFailed = (ticket: TicketInstance) => (
  ticket.localStatus === LocalTicketStatus.FAILED
);

const isTicketPaidOut = (ticket: TicketInstance) => (
  ticket.localStatus === LocalTicketStatus.PAIDOUT
  || ticket.localStatus === LocalTicketStatus.PAYEDOUT
  || ticket.localStatus === LocalTicketStatus.USED
);

const shouldPrintPayoutTicket = (productId: string) => {
  const gravitySettingsStore = useGravitySettingsStore();
  return operatorService.isLoggedIn() && !gravitySettingsStore.getModuleDataKeyValue(
    `module.${productId}.ticketCheck`,
    'showTicketDetailsFrameInBackOffice',
  );
};

const prepareTicketForPrint = (
  ticket: TicketInstance,
  ticketResponse: TicketResponse | ProductTicketResponse,
): TicketForPrint => {
  /**
     * We need to adjust data we send to print because of existing templates
     * in SPS and NPS. So we need to adjust it to look similar as
     * in old (angularjs) code.
     * We send local information (everything from TicketInstance except ticket itself)
     * plus data received from backend (ticketResponse).
     */
  const copyOfTicket = copyObject(ticket);
  delete copyOfTicket.ticket;
  let ticketData: ProductTicketResponse;

  if (isTicketResponse(ticketResponse)) {
    ticketData = ticketResponse.ticket;
  } else {
    ticketData = ticketResponse;
  }

  return mergeObjects(copyOfTicket, ticketData);
};

const createTicketMetaData = (gameId: string, requestUuid?: string): TicketMetaData => {
  const clientGamesStore = useClientGamesStore();
  const game = clientGamesStore.getGameById(gameId);
  const sevenStore = useSevenStore();

  return {
    product: game?.id || gameId,
    cpvUuid: game?.account.linkId || '',
    uuid: game?.account.uuid || '',
    requestUuid: requestUuid || uuidV4(),
    deliveryPlatform: 'Terminal',
    paymentMethod: 'VirtualMoney',
    appDeviceUuid: sevenStore.device.uuid,
    sources: [
      {
        type: 'betshop',
        uuid: sevenStore.betshop.uuid,
      },
      {
        type: 'terminal',
        uuid: localAppConfigService.data.appConfig.terminalAccount.uuid,
      },
      {
        type: 'device',
        uuid: sevenStore.device.uuid,
      },
      {
        type: 'productInstance',
        uuid: game?.account.uuid || '',
      },
    ],
  };
};

const handlePayoutTicketPrint = (
  ticket: TicketInstance,
  ticketResponse: TicketResponse | ProductTicketResponse,
) => {
  const productId = ticket.getProductDisplayId();
  if (shouldPrintPayoutTicket(productId)) {
    const notificationsStore = useNotificationsStore();
    // we recieve ProductTicket as Proxy so we need to do toRaw here
    const ticketForPrint = prepareTicketForPrint(toRaw(ticket), ticketResponse);

    printService.printJob(
      {
        type: 'ticket',
        action: ClientTicketActions.Payout,
        productId,
      },
      ticketForPrint,
    ).catch((err: any) => {
      logService.error(
        '[ticketsHelpers] Failed to print ticket payout from Operator action!',
        {
          ticketData: ticketForPrint,
          code: 'T_TICKET_PAYOUT_PRINT_OPERATOR_ERROR',
          product_displayid: productId,
          request_id: ticket.getRequestUuid(),
          ticket_code: ticket.getDisplayId(),
          operator_id: operatorService.data.uuid,
          operator_name: operatorService.data.username,
          upstream_code: err.upstream_code,
          upstream_message: err.upstream_message,
        },
      );
      notificationsStore.show({
        message: err.message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
    });
  }
};

export {
  isTicketFailed,
  isTicketUnresolved,
  shouldPrintPayoutTicket,
  isTicketPaidOut,
  prepareTicketForPrint,
  createTicketMetaData,
  handlePayoutTicketPrint,
};
