import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { localAppConfigService } from '@/common/services/local-app-config';
import { SevenApiRoutes, useSevenStore } from '@/modules/seven';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import axiosInstance from '../axiosInstance';
import {
  type VoucherGetPayload,
  type VoucherPayoutPayload,
  type VoucherActionResponse,
  type VoucherAdditionalData,
  type VoucherResponse,
  VoucherStatus,
  SEVEN_VOUCHER_DISPLAY_ID,
} from '../types';

let isVoucherPayoutDisabled = false;

const generatePayoutVoucherData = (): VoucherPayoutPayload => {
  const sevenStore = useSevenStore();
  return {
    user: localAppConfigService.data.appConfig.terminalAccount.uuid,
    deliveryPlatform: 'Terminal',
    paymentMethod: 'VirtualMoney',
    userProcessor: {
      type: 'terminal',
      uuid: localAppConfigService.data.appConfig.terminalAccount.uuid,
    },
    deviceUuid: sevenStore.device.uuid,
  };
};

const prepareGetVoucherData = (amount: number, requestUuid: string) => {
  const voucherData: VoucherGetPayload = {
    amount,
    totalAmount: amount,
    requestUuid,
  };
  const voucher: VoucherAdditionalData = {
    product: SEVEN_VOUCHER_DISPLAY_ID,
    requestUuid,
    action: 'Add',
    amount,
  };

  return { voucher, voucherData };
};

const preparePayoutVoucherData = (voucherDataId: string | undefined) => ({
  id: voucherDataId,
  product: SEVEN_VOUCHER_DISPLAY_ID,
  action: 'Payout',
  requestUuid: uuidv4(),
});

const getVoucher = (
  amount: number,
  options: { requestUuid: string },
): Promise<VoucherActionResponse> => {
  const { requestUuid } = options;
  const getVoucherRoute = `${SevenApiRoutes.routes.mainUrl}/api/terminal/vouchers.json`;
  const { voucher, voucherData } = prepareGetVoucherData(amount, requestUuid);

  return axiosInstance
    .post<VoucherResponse>(getVoucherRoute, voucherData)
    .then((response) => {
      const obj: VoucherActionResponse = response.data || {};
      Object.assign(obj, voucher);
      return Promise.resolve(obj);
    });
};

const getVoucherByOperator = (
  amount: number,
  options: { requestUuid: string },
): Promise<VoucherActionResponse> => {
  const { requestUuid } = options;
  const getVoucherRoute = `${SevenApiRoutes.routes.tboMainUrl}vouchers.json`;
  const { voucher, voucherData } = prepareGetVoucherData(amount, requestUuid);

  return axiosInstance
    .post<VoucherResponse>(getVoucherRoute, voucherData)
    .then((response) => {
      const obj: VoucherActionResponse = response.data || {};
      Object.assign(obj, voucher);
      return Promise.resolve(obj);
    });
};

const payoutVoucher = (voucherData: VoucherPayoutPayload, appendId?: boolean)
: Promise<VoucherActionResponse> => {
  const voucherToPayout: VoucherAdditionalData = preparePayoutVoucherData(voucherData.id);
  let payoutRoute = `${SevenApiRoutes.routes.mainUrl}/api/terminal/vouchers.json`;

  if (appendId) {
    payoutRoute += `${voucherData.id?.toUpperCase()}.json`;
  }

  return axiosInstance
    .put<VoucherResponse>(payoutRoute, voucherData)
    .then((response) => {
      const responseData: VoucherActionResponse = response.data;
      Object.assign(responseData, voucherToPayout);
      return Promise.resolve(responseData);
    });
};

const payoutVoucherByOperator = (voucherData: VoucherPayoutPayload, appendId?: boolean)
: Promise<VoucherActionResponse> => {
  const voucherToPayout: VoucherAdditionalData = preparePayoutVoucherData(voucherData.id);
  let payoutRoute = `${SevenApiRoutes.routes.tboMainUrl}vouchers/`;

  if (appendId) {
    payoutRoute += `${voucherData.id?.toUpperCase()}.json`;
  }

  return axiosInstance
    .put<VoucherResponse>(payoutRoute, voucherData)
    .then((response) => {
      const responseData: VoucherActionResponse = response.data;
      Object.assign(responseData, voucherToPayout);
      return Promise.resolve(responseData);
    });
};

const getVoucherList = () => {
  const sevenStore = useSevenStore();
  const configSettingsStore = useConfigSettingsStore();

  const url = `${SevenApiRoutes.routes.tboMainUrl}vouchers/list/${sevenStore.device.uuid}.json`;
  const timeFrom = new Date();
  const timeTo = new Date();
  timeFrom.setMilliseconds(timeTo.getMilliseconds()
                  - (configSettingsStore.data.terminalAdminTicketHistoryRange.value || 300000));

  return axiosInstance.get<Array<VoucherResponse>>(url, {
    params: {
      timeFrom: moment.utc(timeFrom).format('yyyy-MM-DD HH:mm:ss'),
      timeTo: moment.utc(timeTo).format('yyyy-MM-DD HH:mm:ss'),
      count: 20,
    },
  });
};

const isVoucherCode = (code: string): boolean => /^00.{7}$/.test(code);

const disableVoucherPayout = (): void => {
  isVoucherPayoutDisabled = true;
};

const enableVoucherPayout = (): void => {
  isVoucherPayoutDisabled = false;
};

const checkIsVoucherPayoutDisabled = (): boolean => isVoucherPayoutDisabled;

export default {
  VOUCHER_STATUS: VoucherStatus,
  getVoucher,
  getVoucherByOperator,
  payoutVoucher,
  payoutVoucherByOperator,
  disableVoucherPayout,
  enableVoucherPayout,
  checkIsVoucherPayoutDisabled,
  isVoucherCode,
  generatePayoutVoucherData,
  getVoucherList,
};
