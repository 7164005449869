import templateUrl from './specialBets.html';

(function () {
  angular.module('terminalApp.nabLuckySixUi')
    .component('luckySixUiSpecialBets', {
      templateUrl: templateUrl,
      require: {
        luckySixUi: '^luckySixUi'
      },
      controller: function (SevenGamesSvc) {
        var ctrl = this;
        var LuckySix = SevenGamesSvc.getGame('LuckySix');
        var BettingSvc = LuckySix.getBettingSvc();

        ctrl.specialBets = LuckySix.getSpecialBets();
        ctrl.activeBets = BettingSvc;

        ctrl.$onInit = function () {
          ctrl.selectSpecialBet = ctrl.luckySixUi.selectSpecialBet;
        };
      }
    });
})();
