import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { TicketEventErrorResponse, TicketInstance, betslipApiHelpers } from '@/modules/seven-betslip-api';
import { slaveShownEventPubSub, SLAVE_SHOWN_EVENT_ID, integratorService } from '@/modules/integrator';
import { getService } from '@/common/services/ngBridge';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { TicketCheckAbortedError, isTicketCheckAbortedError } from '@/modules/ticket-check';
import { googleAnalyticsService } from '@/modules/google-analytics';
import ticketManager from '../ticketManager';
import { createTicketMetaData } from '../helpers';
import TicketPayoutError from '../errors/TicketPayoutError';
import { useTicketsStore } from '../ticketsStore';
import { validatePayout } from './ticketValidationService';

const { t } = i18n.global;
const LOG_PREFIX = '[payoutService]';
const PAYOUT_ERR_NOTIF = 'payout_error_notif';
const { createLogDataFromTicket } = betslipApiHelpers;

const shouldSkipTicketPendingCheck = (productId: string) => {
  const notificationsStore = useNotificationsStore();
  const gravitySettingsStore = useGravitySettingsStore();
  const ticketsStore = useTicketsStore();
  let skipTicketPendingCheck = gravitySettingsStore.getModuleDataKeyValue(`module.${productId}`, 'skipTicketPendingCheck');

  // We want to enable skip ticket pending check for all new games,
  // so if they don't add this prop to module definition, it will be enabled by default
  if (skipTicketPendingCheck === undefined) skipTicketPendingCheck = true;

  if (!skipTicketPendingCheck && ticketsStore.isActionPending(`${productId}.payout`)) {
    notificationsStore.show({
      delay: 5000,
      message: t('notifications.wait_payout_ticket'),
      type: TNotificationTypeEnum.warning,
    });
    return true;
  }

  return false;
};

const handlePayoutFromGameFrame = (ticket: TicketInstance) => {
  const switchView: any = getService('switchView');
  const productId = ticket.getProductDisplayId();
  const notificationsStore = useNotificationsStore();

  const onSlaveLoadedListener = slaveShownEventPubSub.subscribe(SLAVE_SHOWN_EVENT_ID, () => {
    integratorService.sendEvent('Tickets.Payout', {
      data: {
        ticket: ticket.ticket,
      },
      context: {
        productId,
      },
    });
    slaveShownEventPubSub.unsubscribe(onSlaveLoadedListener);
  });
  switchView.selectService(productId, true, {
    gameId: productId,
    genericId: productId,
  });
  notificationsStore.closeNotificationWithId('ticket.pending_action');
};

const showPayoutFailedNotification = (error: TicketEventErrorResponse | Error) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('ticket.pending_action');

  if (!isTicketCheckAbortedError(error)) {
    notificationsStore.show({
      message: error.message || '',
      delay: 3000,
      type: TNotificationTypeEnum.warning,
      id: PAYOUT_ERR_NOTIF,
    });
  }
};

const payoutTicket = (ticket: TicketInstance) => {
  const productDisplayId = ticket.getProductDisplayId();
  const notificationsStore = useNotificationsStore();
  const gravitySettingsStore = useGravitySettingsStore();
  const ticketSettings = gravitySettingsStore.getByKey(`module.${productDisplayId}.tickets`);
  notificationsStore.closeNotificationWithId(PAYOUT_ERR_NOTIF);
  googleAnalyticsService.trackEvent('Ticket_Payout', {
    event: 'Ticket Payout',
    module: productDisplayId,
  });

  return validatePayout(ticket).then(() => {
    if (ticketSettings?.payout?.fromGameFrame) {
      handlePayoutFromGameFrame(ticket);
      return Promise.resolve({
        response: null,
        localTicket: ticket,
      });
    }

    if (shouldSkipTicketPendingCheck(productDisplayId)) {
      logService.info(`${LOG_PREFIX} Ticket payout call has been skipped since another pending actions is running.`, {
        product_displayid: productDisplayId,
        ticket_code: ticket.getDisplayId(),
        request_id: ticket.getRequestUuid(),
        code: 'T_TICKET_PAYOUT_SKIP',
      });
      return Promise.resolve({
        response: null,
        localTicket: ticket,
      });
    }

    const metadata = createTicketMetaData(
      productDisplayId,
      ticket.getRequestUuid(),
    );

    notificationsStore.show({
      message: t('ticket.pending_action'),
      type: TNotificationTypeEnum.info,
      id: 'ticket.pending_action',
    });
    logService.info(`${LOG_PREFIX} Sending ticket on payout.`, {
      ticket,
      localTicket: createLogDataFromTicket(ticket),
      product_displayid: productDisplayId,
      ticket_code: ticket.getDisplayId(),
      request_id: ticket.getRequestUuid(),
      code: 'T_TICKET_PAYOUT_SEND',
    });
    return ticketManager.payoutTicket(ticket, metadata)
      .catch((payoutError: TicketEventErrorResponse | Error) => {
        ticket.setPin('');
        showPayoutFailedNotification(payoutError);

        return Promise.reject(payoutError);
      });
  }).catch((err: TicketCheckAbortedError | Error) => {
    const formattedError = new TicketPayoutError(
      err.message,
      'T_TICKET_PAYOUT_VALIDATE_ERROR',
      {
        context: {
          notificationDetails: {
            type: TNotificationTypeEnum.warning,
          },
        },
        cause: err,
      },
    );

    logService.error(`${LOG_PREFIX} BeforeTicketPayout error detected.`, {
      code: 'T_TICKET_PAYOUT_VALIDATE_ERROR',
      upstream_message: err.message,
      upstream_code: (err as any).code,
      product_displayid: productDisplayId,
      ticket_code: ticket.getDisplayId(),
      request_id: ticket.getRequestUuid(),
    });
    showPayoutFailedNotification(err);

    return Promise.reject(formattedError);
  });
};

const isPayoutDisabled = (payoutAmount: number) => {
  const configSettingsStore = useConfigSettingsStore();
  const settings = configSettingsStore.data.aggregatorSettings;
  const payoutDisabled = !settings.anonymousPayout;
  const isPayoutAmountOverTheLimit = settings.maxPayout > 0 && payoutAmount > settings.maxPayout;

  return payoutDisabled || isPayoutAmountOverTheLimit;
};

export {
  payoutTicket,
  isPayoutDisabled,
};
