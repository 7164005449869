import BaseError from '@/common/errors/BaseError';
import type { ParserError, UpstreamParserError } from './types';

const parseMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  return '';
};

const parseCode = (error: unknown): string => {
  if (error instanceof BaseError) {
    return error.code || error.name;
  }
  if (error instanceof Error) {
    return error.name;
  }

  return '';
};

const parse = (error: unknown): ParserError => ({
  message: parseMessage(error),
  code: parseCode(error),
});

const parseUpstream = (error: unknown): UpstreamParserError => ({
  upstream_message: parseMessage(error),
  upstream_code: parseCode(error),
});

const jsErrorParser = {
  parse,
  parseUpstream,
  parseMessage,
  parseCode,
};

export default jsErrorParser;
