import { createApp, h } from 'vue';
import i18n from '@/plugins/i18n';
import THeader from '@/common/components/header/THeader.vue';
import TFooter from '@/common/components/footer/TFooter.vue';
import TSideMenu from '@/common/components/TSideMenu.vue';

import templateUrl from './defaultLayout.html';

const tDefaultLayout = {
  templateUrl,
  controller
};

function controller($element) {
  var ctrl = this;
  ctrl.$onInit = function () {
    // header
    ctrl.headerApp = createApp({
      render: () => h(THeader)
    });
    const headerEl = $element.find('#t-header')[0];
    ctrl.headerApp.use(i18n);
    ctrl.headerApp.mount(headerEl);

    // side-menu
    ctrl.sideMenuApp = createApp({
      render: () => h(TSideMenu)
    });
    const sideMenuEl = $element.find('#t-side-menu')[0];
    ctrl.sideMenuApp.mount(sideMenuEl);

    // footer
    ctrl.footerApp = createApp({
      render: () => h(TFooter)
    });
    const footerEl = $element.find('#footer')[0];
    ctrl.footerApp.mount(footerEl);
  };

  ctrl.$onDestroy = function () {
    ctrl.headerApp.unmount();
    angular.element(ctrl.headerApp.$el).remove();
    ctrl.headerApp = null;

    ctrl.footerApp.unmount();
    angular.element(ctrl.footerApp.$el).remove();
    ctrl.footerApp = null;
  };
}

angular.module('terminalApp')
  .component('tDefaultLayout', tDefaultLayout);
