<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { ref, computed, onUnmounted } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import {
  SCButton,
  SCIcon,
  IconSize,
  ButtonVariant,
} from '@nsftx/seven-components';
import PubSub from 'pubsub-js';
import i18n from '@/plugins/i18n';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import { voucherManager } from '@/modules/vouchers';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { usePaymentsStore } from '@/modules/payments';
import { vGaTrackEvent } from '@/modules/google-analytics';
import { printService } from '@/modules/print';
import { useUserFundsStore, userFundsService } from '@/modules/user-funds';

const { t } = i18n.global;
const configSettingsStore = useConfigSettingsStore();
const notificationsStore = useNotificationsStore();
const paymentsStore = usePaymentsStore();
let processingTimeout: number;

const acceptorAmount = ref<number | null>(null);
const processingRequest = ref<boolean>(false);
const { balance: balanceAmount } = storeToRefs(useUserFundsStore());
const showVoucherPrint = computed(() => configSettingsStore.data.transactionTypes
  ?.withdrawal?.voucher?.value
  && !acceptorAmount.value
  && balanceAmount.value > 0);

const acceptorAmounChangeListener = PubSub.subscribe('Acceptor.Amount.Change', (e, data) => {
  acceptorAmount.value = data;
});

const printVoucher = (balance: number) => printService.getPrinterStatus().then(() => {
  processingRequest.value = true;

  if (processingTimeout) {
    window.clearTimeout(processingTimeout);
  }

  /**
         * If there is no response in 5 secs, remove flag so
         * action doesn't get stuck in processing
         * 5 sec is the period before request checks by
         * req uuid will be started
         */
  processingTimeout = window.setTimeout(() => {
    processingRequest.value = false;
  }, 5000);

  const requestUuid = uuidv4();

  return voucherManager.getVoucher(balance, { requestUuid }).then(
    (response) => {
      processingRequest.value = false;
      logService.info('[THeaderVoucher] Backend accepted voucher payout to anonymous player.', {
        details: {
          balance,
          serverResposne: response,
        },
        code: 'T_VOUCHER_PAIDOUT',
        request_id: requestUuid,
        product_displayid: 'Voucher',
      });
      userFundsService.updateBalance().catch((err) => {
        logService.error('[THeaderVoucher] Failed to update balance after printing voucher.', {
          upstream_code: err.code,
          upstream_message: err.upstream_message,
          request_id: requestUuid,
          product_displayid: 'Voucher',
          code: 'T_BALANCE_UPDATE_FETCH_FAILED',
        });
      });

      printService.printJob(
        { type: 'voucher' },
        response,
        {
          clientPrintJobIdentifier: {
            uuid: requestUuid,
            id: 'VoucherPayout',
          },
        },
      ).catch((printErr) => {
        logService.warn('[THeaderVoucher] Failed to print voucher.', {
          response,
          request_id: requestUuid,
          product_displayid: 'Voucher',
          code: 'T_VOUCHER_BALANCE_PRINTER_STATUS_ERROR',
        });
        notificationsStore.show({
          message: printErr.message,
          type: TNotificationTypeEnum.warning,
          delay: 3000,
        });
      });

      return Promise.resolve(response);
    },
  ).catch((error) => {
    processingRequest.value = false;
    logService.warn('[THeaderVoucher] Failed to print voucher.', {
      request_id: requestUuid,
      product_displayid: 'Voucher',
      upstream_code: error?.code || error?.response?.status || '',
      upstream_message: error?.message || error?.response?.statusText || t('notifications.default_error_message'),
      code: 'T_VOUCHER_BALANCE_WITHDRAW_FAILED',
    });
    notificationsStore.show(
      {
        message: t('ticket.voucher_error'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    return Promise.reject(error);
  });
}).catch((printerStatusErr) => {
  logService.warn('[THeaderVoucher] Failed to print voucher due to issues with printer.', {
    ...errorParser.parseUpstream(printerStatusErr),
    code: 'T_VOUCHER_PRINTER_STATUS_ERROR',
  });
  // Something is wrong with printer
  notificationsStore.show(
    {
      message: printerStatusErr.message,
      type: TNotificationTypeEnum.error,
      delay: 3000,
      id: 'printer_error',
    },
  );

  return Promise.reject(printerStatusErr);
});

const onPrintVoucherClicked = () => {
  const terminalBalance = parseFloat(balanceAmount.value.toString());

  if (terminalBalance <= 0) {
    notificationsStore.show(
      {
        message: t('notifications.insufficient_funds'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    return;
  }
  printVoucher(terminalBalance).then(() => {
    if (paymentsStore.paymentSettings.methodName) {
      paymentsStore.restoreToDefaultMethod();
    }
  }).catch(() => {});
};

onUnmounted(() => {
  if (acceptorAmounChangeListener) {
    PubSub.unsubscribe(acceptorAmounChangeListener);
  }
});

</script>

<template>
  <div
    v-if="showVoucherPrint"
    class="print-voucher h-100"
  >
    <SCButton
      v-ga-track-event="{
        eventName: 'Print_Voucher',
        eventParams: { module: 'Print voucher', source: 'Header' },
      }"
      :variant="ButtonVariant.Primary"
      :disabled="processingRequest"
      :loading="processingRequest"
      class="print-voucher__btn"
      @click="onPrintVoucherClicked"
    >
      <div class="print-voucher__btn__inner">
        <SCIcon
          icon="n-i-vouchers"
          :size="IconSize.LargeX"
        />
        <span class="print-voucher__btn__text text-capitalize sc-ml-3">
          {{ $t('ticket.print_voucher') }}
        </span>
      </div>
    </SCButton>
  </div>
</template>

<style lang="scss">
.print-voucher {
  &__btn {
    &__inner {
      display: flex;

      @media screen and (max-width: 1280px) {
        align-items: center;
      }
    }

    &__text {
      white-space: nowrap;
    }
  }
}
</style>
