import { useSevenStore } from '@/modules/seven';
import numberFormatter from '@/common/filters/numberFormatter';

const sevenCurrency = (amount: string | number, decimals: number = 2): string => {
  const sevenStore = useSevenStore();
  const formattedAmount = numberFormatter(Number(amount), decimals);
  return `${formattedAmount} ${sevenStore.tenant.currency}`;
};

export default sevenCurrency;
