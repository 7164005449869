<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { SCInput, SCKeyboard } from '@nsftx/seven-components';

export interface TPasswordInputProps {
  modelValue: string;
  label?: string;
  error?: boolean;
  hint?: string;
  type?: string;
}

const props = withDefaults(defineProps<TPasswordInputProps>(), {
  modelValue: '',
  error: false,
  label: '',
  hint: '',
  type: 'password',
});

const {
  modelValue,
  label,
} = toRefs(props);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const emitModelValue = (value: string) => {
  emit('update:modelValue', value);
};

const showKeyboard = ref(false);
</script>
<template>
  <SCKeyboard
    v-model="modelValue"
    v-model:show="showKeyboard"
    class="w-100"
    @update:model-value="emitModelValue"
  >
    <SCInput
      v-model="modelValue"
      block
      :class="type === 'password' ? 'password-field' : ''"
      class="mb-auto"
      :label="label"
      :error="error"
      :hint="hint"
      @update:model-value="emitModelValue"
    >
      <template #icon>
        <slot name="icon" />
      </template>
    </SCInput>
  </SCKeyboard>
</template>

<style scoped lang="scss">
// Password field is using text input type
// but we style it as password field to prevent
// browser to offer password remembering
.password-field :deep(input) {
  -webkit-text-security: disc;
}
</style>
