// Module
var code = `<div class="row main__body is--small h-100"> <div class="col-sm-24 greyhoundraces--racecardForecast"> <div class="row px-0-5"> <p ng-class="{'col-sm-3':(\$ctrl.activeGame.config.bets[10].value && \$ctrl.activeGame.config.bets[12].value),
                        'col-sm-4':!\$ctrl.activeGame.config.bets[10].value || !\$ctrl.activeGame.config.bets[12].value}" class="text-warning text-sm-center text-info mb-0"> </p> <p ng-if="\$ctrl.activeGame.config.bets[10].value" ng-class="{'col-sm-7':\$ctrl.activeGame.config.bets[12].value}" class="text-warning racers-bet-header text-sm-center pl-0 text-info mb-0"> {{ 'virtualgame_first' | translate}} </p> <p ng-if="\$ctrl.activeGame.config.bets[10].value" ng-class="{'col-sm-7':\$ctrl.activeGame.config.bets[12].value}" class="text-warning racers-bet-header text-sm-center text-info mb-0"> {{ 'virtualgame_second' | translate}} </p> <p ng-if="\$ctrl.activeGame.config.bets[12].value" ng-class="{'col-sm-7':\$ctrl.activeGame.config.bets[10].value}" class="text-warning racers-bet-header text-sm-center text-info mb-0"> {{ 'virtualgame_bets_12' | translate}} </p> <div ng-class="{'col-sm-3':(\$ctrl.activeGame.config.bets[10].value && \$ctrl.activeGame.config.bets[12].value),
                        'col-sm-4':!\$ctrl.activeGame.config.bets[10].value || !\$ctrl.activeGame.config.bets[12].value}"> <div ng-repeat="num in \$ctrl.racers" class="btn btn-block mb-0-5 mt-0" ng-class="{'racer-{{::num}}':true,'py-0':num == 6}"> <div> {{::num}} </div> </div> </div> <div ng-if="\$ctrl.activeGame.config.bets[10].value" ng-class="{'col-sm-7':\$ctrl.activeGame.config.bets[12].value}" class="pl-0"> <div ng-repeat="(racer,status) in \$ctrl.grehoundRacesUISvc.forecastFirstPlace"> <button class="btn btn-primary btn-block mb-0-5" ng-click="\$ctrl.checkForecastFirstPlace(racer)" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive || \$ctrl.grehoundRacesUISvc.disableForecast"> <i ng-class="{'n-i n-i-circle' : !\$ctrl.grehoundRacesUISvc.forecastFirstPlace[racer],
                                  'n-i n-i-check-a': \$ctrl.grehoundRacesUISvc.forecastFirstPlace[racer],
                                  'is--active':\$ctrl.grehoundRacesUISvc.forecastFirstPlace[racer]}"> </i> </button> </div> </div> <div ng-if="\$ctrl.activeGame.config.bets[10].value" ng-class="{'col-sm-7':\$ctrl.activeGame.config.bets[12].value}" class="pl-0"> <div ng-repeat="(racer,status) in \$ctrl.grehoundRacesUISvc.forecastSecondPlace"> <button class="btn btn-primary btn-block mb-0-5" ng-click="\$ctrl.checkForecastSecondPlace(racer)" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive || \$ctrl.grehoundRacesUISvc.disableForecast"> <i ng-class="{'n-i n-i-circle ' : !\$ctrl.grehoundRacesUISvc.forecastSecondPlace[racer],
                                  'n-i n-i-check-a': \$ctrl.grehoundRacesUISvc.forecastSecondPlace[racer],
                                  'is--active':\$ctrl.grehoundRacesUISvc.forecastSecondPlace[racer]}"> </i> </button> </div> </div> <div ng-if="\$ctrl.activeGame.config.bets[12].value" ng-class="{'col-sm-7':\$ctrl.activeGame.config.bets[10].value}" class="pl-0"> <div ng-repeat="(racer,status) in \$ctrl.grehoundRacesUISvc.forecastAny"> <button class="btn btn-primary btn-block mb-0-5" ng-click="\$ctrl.checkReverseForecastBet(racer)" ng-disabled="\$ctrl.activeGame.gameInfo.betsBlocked || !\$ctrl.activeGame.gameInfo.isActive || \$ctrl.grehoundRacesUISvc.disableReverseForecast"> <i ng-class="{'n-i n-i-circle' : !\$ctrl.grehoundRacesUISvc.forecastAny[racer],
                                  'n-i n-i-check-a': \$ctrl.grehoundRacesUISvc.forecastAny[racer],
                                  'is--active': \$ctrl.grehoundRacesUISvc.forecastAny[racer]}"> </i> </button> </div> </div> </div> </div> </div>`;
// Exports
var _module_exports =code;;
var path = '/games/greyhound_races/forecast/check/forecastBettingArea.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;