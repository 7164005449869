import { SevenApiRoutes } from '@/modules/seven';
import axiosInstance from '../userFundsAxiosInstance';
import type { UserFundsBalanceResponse } from '../userFundsTypes';

const getUserFunds = () => {
  const BALANCE_URL = `${SevenApiRoutes.routes.mainUrl}/api/terminal/funds.json`;

  return axiosInstance.get<UserFundsBalanceResponse>(BALANCE_URL);
};

export {
  getUserFunds,
};
