<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { SCButton, ButtonVariant } from '@nsftx/seven-components';
import TModal from '@/common/components/TModal.vue';
import i18n from '@/plugins/i18n';
import { logService } from '@/common/services/logger';
import { safeAppReload } from '@/common/services/appHelpersService';
import { useAuthStore } from '../authStore';

const LOG_PREFIX = '[TLoginFailedModal]';
const { t } = i18n.global;
const authStore = useAuthStore();
const { isLoginFailedModalOpened } = storeToRefs(authStore);

const reloadApp = () => {
  logService.info(`${LOG_PREFIX} Reload on login failed.`, {
    code: 'T_LOGIN_FAILED_RELOAD',
  });
  safeAppReload();
};
</script>

<template>
  <TModal
    v-model="isLoginFailedModalOpened"
    modal-id="t-login-failed"
    prevent-close
  >
    <template #title>
      {{ t('login_error') }}
    </template>

    <template #content>
      {{ t('login_error_description') }}
    </template>

    <template #actions>
      <SCButton
        :variant="ButtonVariant.Primary"
        width="100%"
        @click="reloadApp"
      >
        {{ t('main_reload') }}
      </SCButton>
    </template>
  </TModal>
</template>
