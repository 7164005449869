import { useTboModulesStore } from '@/modules/tbo/tboModulesStore';

export const getDefaultChildRoute = (moduleName: string) => {
  const tboModulesStore = useTboModulesStore();
  return tboModulesStore.getModule(moduleName)?.submodules?.[0].id;
};

export const getDefaultAdminRoute = () => {
  const tboModulesStore = useTboModulesStore();
  return tboModulesStore.getModules()[0].id;
};

export const checkRoutePermission = (moduleName: string) => {
  const tboModulesStore = useTboModulesStore();
  return !!tboModulesStore.getModule(moduleName);
};
