<script lang="ts" setup>
import { computed } from 'vue';
import router from '@/router';
import { SCAppBar } from '@nsftx/seven-components';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import LobbyHeader from '@/modules/lobby/components/LobbyHeader.vue';
import THeaderHome from './THeaderHome.vue';
import THeaderLogo from './THeaderLogo.vue';
import THeaderMenu from './THeaderMenu.vue';
import THeaderBalanceHelper from './THeaderBalanceHelper.vue';
import THeaderBalance from './THeaderBalance.vue';
import THeaderVoucher from './THeaderVoucher.vue';
import THeaderAcceptorAmount from './THeaderAcceptorAmount.vue';

const showLobbyHeader = computed(() => router.currentRoute.value.name === 'LobbyItem');

const classes = computed(() => ({
  'h-100 d-flex align-items-center': true,
  'flex-container': showLobbyHeader.value,
  'col-12 t-header-anonymous-spacing sc-pr-11': !showLobbyHeader.value,
}));

</script>

<template>
  <SCAppBar class="row t-header-anonymous">
    <div :class="classes">
      <LobbyHeader />
      <THeaderHome />
      <THeaderLogo />
      <THeaderMenu />
    </div>

    <div
      v-if="!showLobbyHeader"
      class="col-12"
    >
      <div class="t-header-anonymous-gap d-flex align-items-center justify-content-end h-100">
        <THeaderBalanceHelper />
        <WidgetsRenderer position="headerRight" />
        <THeaderVoucher />
        <THeaderBalance />
        <THeaderAcceptorAmount />
      </div>
    </div>
  </SCAppBar>
</template>

<style lang="scss" scoped>
.t-header-anonymous {
  $header-items-gap: var(--sc-spacing-3);

  color: #fff;

  &-gap {
    gap: $header-items-gap;
  }

  &-spacing{
    margin-top: -4px;
  }
}
</style>
