import templateUrl from './betslipBlockOverlay.html';

(function () {
  angular.module('7Terminal.Betslip').component(
    'betslipOverlay',
    {
      controller: BetslipOverlay,
      templateUrl: templateUrl,
      controllerAs: 'model'
    }
  );

  function BetslipOverlay(
    BetslipService,
    SevenGamesSvc
  ) {
    var vm = this;

    vm.$doCheck = function () {
      var blockers = BetslipService.isBlocked(SevenGamesSvc.getActiveGameId());
      var isBlocked = blockers ? blockers[0] : false;
      vm.isBlocked = !!isBlocked;
      vm.errMessage = (isBlocked && isBlocked.message) || false;
    };

    vm.$onInit = function () {
      vm.errMessage = '';
      vm.isBlocked = false;
    };
  }
})();
