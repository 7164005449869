import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  computed, onMounted, onUnmounted, ref,
} from 'vue';
import { TimerProgression } from '../types';

type BarPosition = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

interface TimerBarProps {
  time: number;
  orientation?: string;
  transitionDelay?: number;
  position: BarPosition;
  loadingColor: string;
  bgColor: string;
  onTimeoutCallback?: (() => void);
  progressionStyle?: TimerProgression;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TimerBar',
  props: {
    time: {},
    orientation: { default: 'vertical' },
    transitionDelay: { default: 0 },
    position: {},
    loadingColor: {},
    bgColor: {},
    onTimeoutCallback: { type: Function, default: undefined },
    progressionStyle: { default: TimerProgression.DESC }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const startProgress = props.progressionStyle === TimerProgression.DESC ? '0' : '100%';
const endProgress = props.progressionStyle === TimerProgression.DESC ? '100%' : '0';
const progress = ref(startProgress);
const transition = ref(
  `${props.orientation === 'horizontal' ? 'width' : 'height'} ${props.time}ms linear ${props.transitionDelay}ms`,
);
const timer = ref<ReturnType<typeof setTimeout>>();

const verticalBarStyle = computed(() => ({
  height: progress.value,
  transition: transition.value,
  background: props.loadingColor,
  ...props.position,
}));

const horizontalBarStyle = computed(() => ({
  width: progress.value,
  transition: transition.value,
  background: props.loadingColor,
  ...props.position,
}));

const fullBarStyle = {
  background: props.bgColor,
  ...props.position,
};

const progressBarStyle = props.orientation === 'horizontal' ? horizontalBarStyle : verticalBarStyle;

const setTimer = () => {
  if (props.onTimeoutCallback) {
    timer.value = setTimeout(() => {
      props.onTimeoutCallback?.();
    }, props.time);
  }
};

const resetTimerAnimation = () => {
  // reset progress to 0 without animation
  progress.value = startProgress;
  transition.value = 'none';

  clearTimeout(timer.value);
  setTimer();

  setTimeout(() => {
    // start animation again
    progress.value = endProgress;
    // set transition to starting value
    transition.value = `${props.orientation === 'horizontal' ? 'width' : 'height'} ${props.time}ms linear ${props.transitionDelay}ms`;
  }, 100);
};

onMounted(() => {
  resetTimerAnimation();
});

onUnmounted(() => {
  clearTimeout(timer.value);
});

__expose({
  resetTimerAnimation,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["loading-bar", [_ctx.orientation]]),
      style: fullBarStyle
    }, null, 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(['loading-progress-bar-' + _ctx.orientation]),
      style: _normalizeStyle(_unref(progressBarStyle))
    }, null, 6 /* CLASS, STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})