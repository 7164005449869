angular.module('terminalApp').controller('GamesResultsController', function (
  $scope,
  $state,
  $filter,
  $stateParams
) {
  var start = $stateParams.start_date && $stateParams.start_date !== 'false' ? new Date($stateParams.start_date) : new Date();

  $scope.todayDate = $filter('date')(start, 'yyyy-MM-dd');

  if ($stateParams.id) {
    $scope.totalRounds = 10;

    $state.go('app.results.' + $stateParams.id.toLowerCase() + '.content', {
      count: 10,
      start_date: null,
      end_date: null
    });
  }

  /**
     * On datepicker change, validate selected date
     * and call for child view reload with new params
     */
  $scope.$watch('todayDate', function (newValue, oldValue) {
    if (newValue && newValue != oldValue) {
      var selectedDate = new Date(newValue);
      var endDate = new Date(newValue);
      var now = new Date();

      selectedDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      var thisMonth = (now.getFullYear() + now.getMonth())
                == (selectedDate.getFullYear() + selectedDate.getMonth());

      var isFuture = now.getFullYear() < selectedDate.getFullYear()
                || now.getMonth() < selectedDate.getMonth();

      // Check if today or future date is selected
      if ((thisMonth && selectedDate.getDate() > now.getDate()) || isFuture) {
        // set start of today
        selectedDate = new Date();
        selectedDate.setHours(0, 0, 0, 0);

        // set end to current time
        endDate = new Date();
      } else if (thisMonth && selectedDate.getDate() == now.getDate()) {
        endDate = new Date();
      }

      if ($stateParams.id) {
        $scope.totalRounds = '*';

        $state.go('app.results.' + $stateParams.id.toLowerCase() + '.content', {
          count: '*',
          start_date: selectedDate,
          end_date: endDate
        });
      }
    }
  });
});
