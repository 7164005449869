import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useCmsMarketingStore, type MarketingSlotItem } from '@/modules/cms/marketing/store';

const SLOT_SCREEN_SAVER_NAME = 'screen_saver';

const useMarketingScreenSaverStore = defineStore('marketingScreenSaverStore', () => {
  const isMarketingScreenSaverVisible = ref<boolean>(false);

  const getScreenSaverMarketingSlot = (): MarketingSlotItem | undefined => {
    const marketingSlotStore = useCmsMarketingStore();
    const slot = marketingSlotStore.getSlot(SLOT_SCREEN_SAVER_NAME);
    const slotItem = slot?.items[0];

    if (!slotItem) {
      return undefined;
    }

    const { active, startDate, endDate } = slotItem;

    if (!active) {
      return undefined;
    }

    if (!startDate || !endDate) {
      return slotItem;
    }

    const date = new Date();
    const dateTime = date.getTime();
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();
    const isValidDateTimeRange = dateTime >= startDateTime && dateTime <= endDateTime;

    if (!isValidDateTimeRange) {
      return undefined;
    }

    return slotItem;
  };

  const showScreenSaver = (): void => {
    isMarketingScreenSaverVisible.value = true;
  };

  const hideScreenSaver = (): void => {
    isMarketingScreenSaverVisible.value = false;
  };

  const isScreenSaverEnabled = (): boolean => !!getScreenSaverMarketingSlot();

  return {
    isMarketingScreenSaverVisible,
    getScreenSaverMarketingSlot,
    showScreenSaver,
    hideScreenSaver,
    isScreenSaverEnabled,
  };
});

export {
  useMarketingScreenSaverStore,
};
