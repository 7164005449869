import { range, each } from 'underscore';
import templateUrl from './racecardBettingArea.html';

(function () {
  angular.module('terminalApp.sevenVirtualMotorcycleSpeedway')
    .component('vmsRacecardBettingArea', {
      templateUrl: templateUrl,
      controller: function (flashButton,
        SevenGamesSvc,
        BetslipService,
        $scope,
        $state,
        $filter,
        nabTrans,
        NabNotifications,
        VmsUiService,
        $rootScope) {
        var currentUrl = $state.current.url.substring(1, $state.current.url.length);
        var activeGame = (currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1)).toString();
        var VMS = SevenGamesSvc.getGame(activeGame);
        var BettingSvc = VMS.getBettingSvc();
        var self = this;
        var odds;

        this.$onInit = function () {
          odds = VMS.odds;
          this.activeGame = VMS;
          this.racers = range(1, VMS.numberOfRacers + 1);
          this.resolutionMode = $rootScope.resolutionMode;
          this.ticketInput = BettingSvc.ticketInput;
          this.bets = {};
          this.betslipSvc = BetslipService;
          this.vmsUISvc = VmsUiService;
          this.deactivateRacers();
        };

        /** Set racers for winner,place as inactive* */

        this.deactivateRacers = function () {
          each(this.racers, function (position) {
            self.vmsUISvc.winner['r' + (position)] = false;
            self.vmsUISvc.place['r' + (position)] = false;
          });
        };

        /** Update odds on racecard betting area and create racers* */
        this.updateOdds = function () {
          var i;
          var j;

          this.bets = {
            winner: odds.winner,
            place: odds.place
          };

          for (i in this.bets) {
            for (j = 0; j < this.bets[i].length; j++) {
              this.bets[i][j].bet = i;
            }
          }
        };

        this.sendBet = function (bet) {
          BettingSvc.clearAll();
          BettingSvc.ticketInput.input[0] = bet.racers[0].toString();
          VmsUiService.addBet(bet);
        };

        /**
                 * Update odds when new round starts
                 *i have to use angular.bind because i didn't want to set activeGame on $scope* */

        $scope.$watch(angular.bind(this, function () {
          return self.activeGame.odds; // this IS the this above!!
        }), function (newValue) {
          if (newValue && VMS.odds) {
            VMS.gameInfo.isActive = true;
            odds = VMS.odds;
            self.updateOdds();
          }

          if (!VMS.odds) {
            VMS.gameInfo.isActive = false;
          }
        });
      }

    });
})();
