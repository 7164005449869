import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives } from "vue"

import { ref } from 'vue';
import {
  SCRadioButton,
  SCButton,
  ButtonVariant,
  SCScrollButtons,
  SCModalNext,
} from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useLanguageStore } from '@/common/stores/language';
import { vGaTrackEvent } from '@/modules/google-analytics';

type LanguagePickerEmits = (e: 'close') => void;


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguagePicker',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { t } = i18n.global;
const emit = __emit;

const langStore = useLanguageStore();
const selectedItem = ref<string>(langStore.currentLang?.id || '');
const languagePickerRef = ref<HTMLElement | null>(null);
const showScrollButtons = ref(false);

const closeModal = () => {
  emit('close');
};

const saveLanguage = () => {
  langStore.changeLanguage(selectedItem.value);
  closeModal();
};

const onLangSelected = (value: string) => {
  selectedItem.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SCModalNext), {
    "with-close-icon": "",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (closeModal())),
    onOpened: _cache[1] || (_cache[1] = ($event: any) => (showScrollButtons.value=true))
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('main.language')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "languagePickerRef",
        ref: languagePickerRef,
        class: "w-100 lang-picker d-flex flex-column overflow-auto"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(langStore).availableLanguages, (lang) => {
          return (_openBlock(), _createBlock(_unref(SCRadioButton), {
            id: lang.id,
            key: lang.id,
            "selected-value": selectedItem.value,
            value: lang.id,
            label: lang.name,
            class: "sc-mb-3",
            block: "",
            "onUpdate:modelValue": onLangSelected
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(["sc-pr-7 ml-auto", `flag flag-${lang.iso3.toUpperCase()}`])
              }, null, 2 /* CLASS */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "selected-value", "value", "label"]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 512 /* NEED_PATCH */),
      (showScrollButtons.value)
        ? (_openBlock(), _createBlock(_unref(SCScrollButtons), {
            key: 0,
            content: languagePickerRef.value
          }, null, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true)
    ]),
    actions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
        variant: _unref(ButtonVariant).Primary,
        size: "md",
        width: "100%",
        disabled: !selectedItem.value,
        "data-testid": "saveButton",
        onClick: saveLanguage
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('actions.save')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["variant", "disabled"])), [
        [_unref(vGaTrackEvent), {
          eventName: 'Language_Change',
          eventParams: { action: 'Language Change (on Save)' }
        }]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})