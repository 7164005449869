import { AxiosResponse } from 'axios';
import { toRaw } from 'vue';
import type { SevenPrinter, SevenPrinterState } from '@nsftx/seven-print-js';
import BaseError from '@/common/errors/BaseError';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { logService } from '@/common/services/logger';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { getSevenPrint } from './sevenPrintService';
import { getTemplateDataForPrint } from './printTemplateService';
import { getTranslatedDamagedStatusMessage } from '../printerHelpers';
import { parsePrinterSpsResponse } from '../printerMessagesParser';
import { usePrinterStatusStore } from '../printerStatusStore';
import { usePrinterStore } from '../printerStore';
import {
  PrintDataAdditionalData,
  PrintDataInfo,
  PrintGetStatusSpsResponse,
  type SevenDetectedPrintersResponse,
  type SevenDetectedPrinters,
} from '../types';
import { TPrintErrorCodes } from '../TPrintErrorCodes';
import { PrinterSpsStatusSeverity } from '../enums';
import type { PrinterSpsResponseData } from '../interfaces/printerMessageData';
import * as spsApiService from './spsApiService';

// Constants
const LOG_PREFIX = '[spsService]';
const sevenPrint = getSevenPrint();

const getPrinterToSave = (
  printer: SevenPrinter | null,
  detectedPrinters: SevenDetectedPrinters,
) => {
  if (!printer) {
    return detectedPrinters[0].printer;
  }

  sevenPrint.setSelectedPrinter(printer);
  const isSelectedPrinterFound = sevenPrint.getSelectedPrinter();
  if (isSelectedPrinterFound) {
    return printer;
  }

  return detectedPrinters[0].printer;
};

const saveSelectedPrinter = (printer: SevenPrinter) => {
  const { setSelectedPrinter } = usePrinterStore();

  sevenPrint.setSelectedPrinter(printer);
  setSelectedPrinter(printer);
};

const setPrinterData = (printer: SevenPrinter | null, detectedPrinters: SevenDetectedPrinters) => {
  const printerStore = usePrinterStore();

  sevenPrint.setDetectedPrinters(detectedPrinters);
  printerStore.setDetectedPrinters(detectedPrinters);

  const selectedPrinter = getPrinterToSave(printer, detectedPrinters);
  saveSelectedPrinter(selectedPrinter);
};

const getDetectedPrinters = (cachedResponse: boolean = false) => {
  if (!cachedResponse) {
    return spsApiService.getDetectedPrinters();
  }

  return Promise.resolve(sevenPrint.getDetectedPrinters());
};

const init = async (selectedPrinter: SevenPrinter | null) => {
  const response = await getDetectedPrinters();
  const detectedPrinters = (response as SevenDetectedPrintersResponse).data.printers;

  if (!detectedPrinters?.length) {
    logService.warn(`${LOG_PREFIX} No detected printers.`, {
      detectedPrinters,
      selectedPrinter,
      code: 'T_SPS_NO_DETECTED_PRINTERS',
    });
    return Promise.reject(new BaseError(
      'Cannot set printer because there is no detected printers',
      'T_SPS_NO_DETECTED_PRINTERS',
    ));
  }

  setPrinterData(selectedPrinter, detectedPrinters);
  if (!sevenPrint.getSelectedPrinter()) {
    logService.error(`${LOG_PREFIX} Cannot set printer`, {
      code: 'T_SPS_PRINTER_NOT_SELECTED',
      selectedPrinter,
      detectedPrinters,
    });
  }

  return Promise.resolve(selectedPrinter);
};

const getSelectedPrinter = () => sevenPrint.getSelectedPrinter();

const isGetPrinterStatusResponseSuccess = (response: unknown):
response is AxiosResponse<SevenPrinterState> => (response as AxiosResponse).status === 200;

const getPrinterStatus = (): PrintGetStatusSpsResponse => sevenPrint.getPrinterStatus()
  .then((response) => {
    if (!isGetPrinterStatusResponseSuccess(response)) {
      return Promise.reject(new BaseError(
        'Invalid response type',
        'T_SPS_GET_PRINTER_STATUS_RESPONSE_INVALID',
      ));
    }

    if (!response.data?.damagedStatus) {
      return Promise.resolve(response.data);
    }

    if (response.data.damagedStatus.severity === PrinterSpsStatusSeverity.Warning) {
      const notificationsStore = useNotificationsStore();
      const message = getTranslatedDamagedStatusMessage(response.data.damagedStatus.name || '');

      logService.debug(`${LOG_PREFIX} getPrinterStatus - damagedStatus detected.`, {
        damagedStatus: response.data.damagedStatus,
        code: 'T_PRINTER_DAMAGED_STATUS',
      });

      notificationsStore.closeNotificationWithId('printer_error');
      notificationsStore.closeNotificationWithId('printer_warning');
      notificationsStore.show({
        id: 'printer_warning',
        type: TNotificationTypeEnum.warning,
        delay: 3000,
        message,
      });

      return Promise.resolve(response.data);
    }

    logService.error(`${LOG_PREFIX} getPrinterStatus - damagedStatus detected.`, {
      damagedStatus: response.data.damagedStatus,
      code: 'T_PRINTER_DAMAGED_STATUS',
    });

    return Promise.reject({
      damagedStatus: response.data.damagedStatus,
      message: response.data.damagedStatus.name,
    });
  })
  .catch((error) => {
    logService.error(`${LOG_PREFIX} Printer status failed.`, {
      message: error?.message || '',
      upstream_code: error?.status || '',
      code: TPrintErrorCodes.T_PRINTER_STATUS_ERROR,
    });

    return Promise.reject({
      valid: false,
      damagedStatusName: error.damagedStatus?.name,
      message: getTranslatedDamagedStatusMessage(error.message),
      code: error.damagedStatus?.status || error.status,
    });
  });

const getStatus = () => {
  const { setPrinterStatusData } = usePrinterStatusStore();

  return getPrinterStatus()
    .then((response) => {
      const printerStatus = parsePrinterSpsResponse(response as PrinterSpsResponseData);
      setPrinterStatusData({
        ...printerStatus,
        statusUpdateSource: 'onRequest',
      });

      return printerStatus;
    })
    .catch((error) => {
      setPrinterStatusData({
        ...parsePrinterSpsResponse(error),
        statusUpdateSource: 'onRequest',
      });
      throw error;
    });
};

const printJob = async (
  printDataInfo: PrintDataInfo,
  context?: any,
  additionalData?: PrintDataAdditionalData,
) => {
  const { getModuleDataKeyValue } = useGravitySettingsStore();
  const config = getModuleDataKeyValue<Record<string, any>>(
    'sps',
    'templateConfig',
  );
  const {
    requestUuid,
    ticketCode,
    lang,
    template,
    templateName,
    mainData,
    addData,
    options,
    translations,
  } = getTemplateDataForPrint(printDataInfo, context, additionalData);
  const bindings = {
    additionalData: addData,
    mainData,
    context,
    translations,
    options,
    lang,
    config,
  };

  logService.debug(`${LOG_PREFIX} Sending template to print service.`, {
    additionalData: addData,
    ticket_code: ticketCode,
    request_id: requestUuid,
    templateName,
    options: toRaw(options),
    lang,
    code: 'T_SPS_SEND_TEMPLATE_TO_PRINT',
  });

  return sevenPrint.printHtmlTemplate(template, bindings, {
    requestUuid,
  });
};

export {
  init,
  saveSelectedPrinter,
  getSelectedPrinter,
  getDetectedPrinters,
  getStatus,
  printJob,
};
