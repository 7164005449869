import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "print-voucher h-100"
}
const _hoisted_2 = { class: "print-voucher__btn__inner" }
const _hoisted_3 = { class: "print-voucher__btn__text text-capitalize sc-ml-3" }

import { storeToRefs } from 'pinia';
import { ref, computed, onUnmounted } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import {
  SCButton,
  SCIcon,
  IconSize,
  ButtonVariant,
} from '@nsftx/seven-components';
import PubSub from 'pubsub-js';
import i18n from '@/plugins/i18n';
import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import { voucherManager } from '@/modules/vouchers';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { usePaymentsStore } from '@/modules/payments';
import { vGaTrackEvent } from '@/modules/google-analytics';
import { printService } from '@/modules/print';
import { useUserFundsStore, userFundsService } from '@/modules/user-funds';


export default /*@__PURE__*/_defineComponent({
  __name: 'THeaderVoucher',
  setup(__props) {

const { t } = i18n.global;
const configSettingsStore = useConfigSettingsStore();
const notificationsStore = useNotificationsStore();
const paymentsStore = usePaymentsStore();
let processingTimeout: number;

const acceptorAmount = ref<number | null>(null);
const processingRequest = ref<boolean>(false);
const { balance: balanceAmount } = storeToRefs(useUserFundsStore());
const showVoucherPrint = computed(() => configSettingsStore.data.transactionTypes
  ?.withdrawal?.voucher?.value
  && !acceptorAmount.value
  && balanceAmount.value > 0);

const acceptorAmounChangeListener = PubSub.subscribe('Acceptor.Amount.Change', (e, data) => {
  acceptorAmount.value = data;
});

const printVoucher = (balance: number) => printService.getPrinterStatus().then(() => {
  processingRequest.value = true;

  if (processingTimeout) {
    window.clearTimeout(processingTimeout);
  }

  /**
         * If there is no response in 5 secs, remove flag so
         * action doesn't get stuck in processing
         * 5 sec is the period before request checks by
         * req uuid will be started
         */
  processingTimeout = window.setTimeout(() => {
    processingRequest.value = false;
  }, 5000);

  const requestUuid = uuidv4();

  return voucherManager.getVoucher(balance, { requestUuid }).then(
    (response) => {
      processingRequest.value = false;
      logService.info('[THeaderVoucher] Backend accepted voucher payout to anonymous player.', {
        details: {
          balance,
          serverResposne: response,
        },
        code: 'T_VOUCHER_PAIDOUT',
        request_id: requestUuid,
        product_displayid: 'Voucher',
      });
      userFundsService.updateBalance().catch((err) => {
        logService.error('[THeaderVoucher] Failed to update balance after printing voucher.', {
          upstream_code: err.code,
          upstream_message: err.upstream_message,
          request_id: requestUuid,
          product_displayid: 'Voucher',
          code: 'T_BALANCE_UPDATE_FETCH_FAILED',
        });
      });

      printService.printJob(
        { type: 'voucher' },
        response,
        {
          clientPrintJobIdentifier: {
            uuid: requestUuid,
            id: 'VoucherPayout',
          },
        },
      ).catch((printErr) => {
        logService.warn('[THeaderVoucher] Failed to print voucher.', {
          response,
          request_id: requestUuid,
          product_displayid: 'Voucher',
          code: 'T_VOUCHER_BALANCE_PRINTER_STATUS_ERROR',
        });
        notificationsStore.show({
          message: printErr.message,
          type: TNotificationTypeEnum.warning,
          delay: 3000,
        });
      });

      return Promise.resolve(response);
    },
  ).catch((error) => {
    processingRequest.value = false;
    logService.warn('[THeaderVoucher] Failed to print voucher.', {
      request_id: requestUuid,
      product_displayid: 'Voucher',
      upstream_code: error?.code || error?.response?.status || '',
      upstream_message: error?.message || error?.response?.statusText || t('notifications.default_error_message'),
      code: 'T_VOUCHER_BALANCE_WITHDRAW_FAILED',
    });
    notificationsStore.show(
      {
        message: t('ticket.voucher_error'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    return Promise.reject(error);
  });
}).catch((printerStatusErr) => {
  logService.warn('[THeaderVoucher] Failed to print voucher due to issues with printer.', {
    ...errorParser.parseUpstream(printerStatusErr),
    code: 'T_VOUCHER_PRINTER_STATUS_ERROR',
  });
  // Something is wrong with printer
  notificationsStore.show(
    {
      message: printerStatusErr.message,
      type: TNotificationTypeEnum.error,
      delay: 3000,
      id: 'printer_error',
    },
  );

  return Promise.reject(printerStatusErr);
});

const onPrintVoucherClicked = () => {
  const terminalBalance = parseFloat(balanceAmount.value.toString());

  if (terminalBalance <= 0) {
    notificationsStore.show(
      {
        message: t('notifications.insufficient_funds'),
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      },
    );
    return;
  }
  printVoucher(terminalBalance).then(() => {
    if (paymentsStore.paymentSettings.methodName) {
      paymentsStore.restoreToDefaultMethod();
    }
  }).catch(() => {});
};

onUnmounted(() => {
  if (acceptorAmounChangeListener) {
    PubSub.unsubscribe(acceptorAmounChangeListener);
  }
});


return (_ctx: any,_cache: any) => {
  return (showVoucherPrint.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_unref(SCButton), {
          variant: _unref(ButtonVariant).Primary,
          disabled: processingRequest.value,
          loading: processingRequest.value,
          class: "print-voucher__btn",
          onClick: onPrintVoucherClicked
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(SCIcon), {
                icon: "n-i-vouchers",
                size: _unref(IconSize).LargeX
              }, null, 8 /* PROPS */, ["size"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('ticket.print_voucher')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["variant", "disabled", "loading"])), [
          [_unref(vGaTrackEvent), {
        eventName: 'Print_Voucher',
        eventParams: { module: 'Print voucher', source: 'Header' },
      }]
        ])
      ]))
    : _createCommentVNode("v-if", true)
}
}

})