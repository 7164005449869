angular.module('7Terminal.Common')
  .service('payinAmountService', function payinAmountService(systemCombinationsService) {
    var minPayinPerCombination;
    var minPayin;

    return {
      activateRestriction: function (min, combMin) {
        minPayin = min;
        minPayinPerCombination = combMin;
      },

      getMin: function (data) {
        var min;
        var result = {
          combCount: 1,
          min: minPayin
        };
        var combinations;

        if (data.type === 'System') {
          combinations = systemCombinationsService.getCombNumber(data.regular, data.system);
          // eslint-disable-next-line
        min = parseInt(combinations * minPayinPerCombination * 100) / 100;
          min = min < minPayin ? minPayin : min;

          result.combCount = combinations;
          result.min = min;
        }

        if (data.future > 1) {
          result.min *= data.future;
        }

        return result;
      }
    };
  });
