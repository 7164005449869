<script setup lang="ts">
import { ref } from 'vue';
import { SCButton } from '@nsftx/seven-components';
import i18n from '@/plugins/i18n';
import { useOddTypeStore } from '@/common/stores/odd-type';
import { useLanguageStore } from '@/common/stores/language';
import OddTypePicker from '@/modules/settings/OddTypePicker.vue';
import LanguagePicker from '@/modules/settings/LanguagePicker.vue';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import { vGaTrackEvent } from '@/modules/google-analytics';

const { t } = i18n.global;
const isOddTypePickerModalOpen = ref(false);
const isLangPickerModalOpen = ref(false);

const oddTypeStore = useOddTypeStore();
const langStore = useLanguageStore();

const showOddsTypePicker = () => {
  isOddTypePickerModalOpen.value = true;
};

const showLangPicker = () => {
  isLangPickerModalOpen.value = true;
};

</script>

<template>
  <div class="d-flex flex-row-reverse">
    <div class="t-footer-button sc-my-1">
      <SCButton
        v-if="oddTypeStore.shouldShowOddsPicker()"
        v-ga-track-event="{
          eventName: 'Open_Module',
          eventParams: { module: 'Odds picker', source: 'Footer' },
        }"
        width="100%"
        prepend-icon="odds-on-coupon"
        :active="isOddTypePickerModalOpen"
        height="60px"
        @click="showOddsTypePicker"
      >
        <span class="sc-ml-3 footer-button-label">
          {{ t(oddTypeStore.activeOddType.name) }}
        </span>
      </SCButton>
    </div>

    <SCButton
      v-if="!langStore.shouldHideLangPicker()"
      v-ga-track-event="{
        eventName: 'Open_Module',
        eventParams: { module: 'Language modal', source: 'Footer' },
      }"
      class="sc-my-1 sc-mr-1"
      :active="isLangPickerModalOpen"
      width="64px"
      height="60px"
      @click="showLangPicker"
    >
      <i
        class="lang-flag"
        :class="`flag flag-${langStore.currentLang?.iso3?.toUpperCase()}`"
      />
    </SCButton>

    <WidgetsRenderer position="footerRight" />

    <OddTypePicker
      :model-value="isOddTypePickerModalOpen"
      @update:modal-open-value="(value) => isOddTypePickerModalOpen = value"
    />

    <LanguagePicker
      :model-value="isLangPickerModalOpen"
      @update:modal-open-value="(value) => isLangPickerModalOpen = value"
    />
  </div>
</template>

<style lang="scss" scoped>
.lang-flag {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
