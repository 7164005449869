import { v4 as uuidv4 } from 'uuid';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';
import { printService, TPrintErrorCodes, ClientIdentifierTiketActions } from '@/modules/print';
import HooksManager from '@/common/services/HooksManager';
import { ticketActionsResponseHandler, ticketActionSuccessEventPubSub, TicketActionSuccessEvent } from '@/modules/tickets';
import { googleAnalyticsService } from '@/modules/google-analytics';

const createLogDataFromTicket = betslipApiHelpers.createLogDataFromTicket;

(function () {
  angular.module('7Terminal.Tickets')
    .service('ticketService', ticketService);

  function ticketService(
    $rootScope,
    $log,
    $filter,
    $translate,
    ticketManager,
    nabMessaging,
    NabNotifications,
    MetadataService,
    SevenGamesSvc,
    nabTrans,
    SevenClientConfig,
    GravitySettings,
    TicketValidationService,
    ProductTicket,
    SevenTicket
  ) {
    /**
  * Print ticket
  *
  * @event "7T:Tickets.Print"
  * @memberof module:Tickets
  * @type {Object}
  * @property {String} printAction
  * @property {String} productId
  * @property {Object} responseData
  */
    $rootScope.$on('7T:Tickets.Print', function (e, eventData) {
      printTicket.call(null, eventData.data || eventData);
    });

    /**
  * Event for updating ticket state
  *
  * @event "7T:Tickets.Update"
  * @memberof module:Tickets
  * @type {Object}
  *
  * @property {String} action
  * @property {String} printAction
  * @property {Object} ticketData
  * @property {String} ticketData.requestUuid
  * @property {String} ticketData.id
  * @property {String} ticketData.product
  * @property {Object} ticketData.status
  * @property {String} ticketData.status.value
  * @property {String} [showMessage] - show final message to user or skip
  */
    $rootScope.$on(
      '7T:Tickets.Update',

      /**
  *
  * @function
  *
  * @param event
  *
  * @param {Object} data
  * @param {String} data.action
  * @param {String} data.printAction
  * @param {Object} data.ticketData.requestUuid
  * @param {Object} data.ticketData.id
  * @param {Object} data.ticketData.product
  * @param {Object} data.ticketData.status
  * @param {String} data.ticketData.status.value
  * @param {String} data.showMessage
  * @listens 7T:Tickets.Update
  */
      function (event, data) {
        const updatedTicket = generateTicket(data.ticketData);
        NabNotifications.closeNotificationWithId('ticket.pending_action');
        if (updatedTicket instanceof ProductTicket || data.action.toUpperCase() === 'CASHOUT') {
          // for now when have betslip v2 paload, do nothing
          return;
        }

        if (data.action.toLowerCase() === 'add') {
          handleTicketActionResponse({
            action: data.action,
            printAction: data.printAction,
            ticketData: data.ticketData,
            showMessage: data.showMessage
          });
        } else {
          ticketActionsResponseHandler.handleTicketActionResponse(data);
        }
      }
    );

    nabMessaging.subscribe('NCM:ticketUpdate', function (e, data) {
      var eventData = data.data;
      nabMessaging.publish('ticket:' + eventData.data.action + 'Response', {
        message: 'ticket:' + eventData.data.action + 'Response',
        ticketData: eventData.data
      });
    });

    /**
  * Listens to ticket events from server, from ticket add to ticket cancel/payout.
  * These are propagated from scm. They are occurring when user pay in ticket.
  * After user payin (more like sends request to payin ticket) ticket to server,
  * we will receive message from server about ticket status.
  */
    nabMessaging.subscribe('ticket:addResponse', function (event, data) {
      var tmpObj;

      NabNotifications.closeNotificationWithId('ticket.pending_action');
      tmpObj = {
        action: 'Add',
        printAction: '',
        ticketData: data.ticketData
      };

      handleTicketActionResponse(tmpObj);
    });

    nabMessaging.subscribe('ticket:cancelResponse', function (event, data) {
      const tmpObj = {
        action: 'Cancel',
        printAction: '',
        ticketData: data.ticketData
      };

      NabNotifications.closeNotificationWithId('ticket.pending_action');

      ticketActionsResponseHandler.handleTicketActionResponse(tmpObj);
    });

    nabMessaging.subscribe('ticket:payoutResponse', function (event, data) {
      const tmpObj = {
        action: 'Payout',
        printAction: '',
        ticketData: data.ticketData
      };

      NabNotifications.closeNotificationWithId('ticket.pending_action');

      ticketActionsResponseHandler.handleTicketActionResponse(tmpObj);
    });

    // When socket message about resovled ticket is not received after
    // X time, ticket:resolvedAction event will be broadcasted
    // from ticketAction service
    nabMessaging.subscribe('ticket:resolvedAction', function (event, data) {
      NabNotifications.closeNotificationWithId('ticket.pending_action');
      const action = data.localTicket.action;
      const tmpObj = {
        action,
        printAction: '',
        ticketData: data.response
      };

      if (action.toLowerCase() === 'add') {
        handleTicketActionResponse(tmpObj);
      } else {
        ticketActionsResponseHandler.handleTicketActionResponse(tmpObj);
      }
    });

    nabMessaging.subscribe('ticket:failedAction', function (event, data) {
      NabNotifications.closeNotificationWithId('ticket.pending_action');

      NabNotifications.show(
        {
          message: data.message,
          type: 'warning',
          delay: 3000
        }
      );
    });

    nabMessaging.subscribe('TicketManager:pendingTicketRejectPeriodPassed', function (event, data) {
      NabNotifications.closeNotificationWithId('ticket.pending_action');

      NabNotifications.show(
        {
          message: nabTrans.translate(data.messageTcKey, {}, true),
          type: 'warning',
          delay: 3000
        }
      );
    });

    function handleTicketActionResponse(actionObj) {
      const { action, showMessage } = actionObj;
      const productId = actionObj.ticketData?.product;
      var requestUuid = actionObj.ticketData.requestUuid;
      var ticketId = actionObj.ticketData.id;
      $log.debug(
        '[7Terminal.Tickets] Resolving ticket action.',
        {
          request_id: requestUuid,
          ticket_code: ticketId,
          ticket: actionObj.ticketData,
          product_displayid: productId
        }
      );
      ticketManager.resolveTicket(
        actionObj.ticketData,
        action
      ).then(
        function (response) {
          $log.info('[7Terminal.Tickets] Ticket resolved.', {
            code: 'T_TICKET_RESOLVE_SUCCESS',
            request_id: requestUuid,
            ticket_code: ticketId,
            ticket: createLogDataFromTicket(response.data),
            product_displayid: productId
          });
          googleAnalyticsService.trackEvent('Ticket_Payin', {
            module: productId,
            event: 'Ticket Payin Success'
          });
          handleSuccessfulAction(response, actionObj.action, showMessage);
        },
        function (response) {
          var errorMessage = handleTicketError(response, showMessage);
          $log.error(
            '[7Terminal.Tickets] Failed to resolve ticket.',
            {
              request_id: requestUuid,
              ticket_code: ticketId,
              product_displayid: productId,
              response: response,
              upstream_message: errorMessage,
              upstream_code: response.clientCode,
              action,
              code: 'T_TICKET_RESOLVE_ERROR'
            }
          );
          if (response.clientCode === 'T_TICKET_RESOLVE_BACKEND_ERROR') {
            googleAnalyticsService.trackEvent('Ticket_Payin', {
              module: productId,
              event: 'Ticket Payin Fail'
            });
          }
        }
      );
    }

    function handleSuccessfulAction(ticketResult, action, showMessage) {
      if (showMessage === undefined || showMessage !== false) {
        const params = {
          action,
          errorResponses: []
        };
        HooksManager.run('BeforeTicketActionNotificationShow', params).then(() => {
          if (!params.errorResponses.length) {
            NabNotifications.show({
              message: ticketResult.reason,
              type: 'success',
              delay: 3000
            });
          } else {
            $log.debug('[7Terminal.Tickets] Skipping showing notification - BeforeTicketActionNotificationShow hook', {
              ...params.errorResponses[0],
              code: 'T_SKIP_SHOW_TICKET_ACTION_NOTIFICATION'
            });
          }
        }).catch((error) => {
          $log.debug('[7Terminal.Tickets] Skipping showing notification - BeforeTicketActionNotificationShow hook', {
            ...error,
            code: 'T_SKIP_SHOW_TICKET_ACTION_NOTIFICATION'
          });
        });
      }

      $rootScope.$emit('7T:Tickets.UpdateTicketCheckState', {
        data: {
          ticket: ticketResult.data
        }
      });

      // give chance to game to modify ticket data
      // for print, this is something like pre-print hook
      if (ticketResult.data.action === 'Add' || ticketResult.data.action === 'Authorization') {
        prepareAddPrint(action, ticketResult.data);
      }

      if (ticketResult.data.action === 'Add') {
        /**
  * Emit if ticket is accepted on backend
  *
  * @event "7T:Tickets.AddSuccess"
  * @memberOf module:Tickets
  * @type {Object}
  * @property {String} productId - Product id
  * @property {Object} ticketData - Data that was sent to server
  * @property {String} ticketData.id - Ticket id
  * @property {String} ticketData.product - Product id
  * @property {String} ticketData.requestUuid - Request uuid
  */
        $rootScope.$emit('7T:Tickets.AddSuccess', {
          productId: ticketResult.data.product,
          ticketData: ticketResult.data
        });

        ticketActionSuccessEventPubSub.publish(TicketActionSuccessEvent.AddSuccess, {
          localTicket: ticketResult.data
        });
      }
    }

    function printTicket(ticketResult) {
      const productId = ticketResult.productId || ticketResult.responseData.product;
      const action = ticketResult.action || ticketResult.responseData.action;
      printService.performPrint({
        info: {
          type: 'ticket',
          productId
        },
        data: ticketResult.responseData,
        additionalData: {
          ...(ticketResult.additionalData || {}),
          clientPrintJobIdentifier: {
            id: ClientIdentifierTiketActions[action?.toLowerCase()],
            uuid: ticketResult.responseData.requestUuid
          }
        }
      }).then(() => {
        $log.debug('[7Terminal.Tickets] Ticket successfully printed.', {
          code: 'T_TICKETS_PRINT_SUCCESS',
          ticket_code: ticketResult?.responseData?.id || '',
          request_id: ticketResult?.responseData?.requestUuid || '',
          product_displayid: productId,
          ticketResult: ticketResult?.responseData
        });
      }).catch((err) => {
        $log.error('[7Terminal.Tickets] Failed to print ticket.', {
          ticket_code: ticketResult?.responseData?.id || '',
          request_id: ticketResult?.responseData?.requestUuid || '',
          product_displayid: productId,
          ticketResult: ticketResult?.responseData || ticketResult,
          code: 'T_TICKETS_PRINT_FAILED',
          upstream_code: err.upstream_code,
          upstream_message: err.upstream_message
        });

        if (err.type === TPrintErrorCodes.T_PRINT_ERROR) {
          NabNotifications.show({
            message: err.upstream_message,
            type: 'warning',
            delay: 3000
          });
        } else if (err.type === TPrintErrorCodes.T_PRINTER_STATUS_ERROR) {
          NabNotifications.show({
            message: err.upstream_message,
            type: 'error',
            delay: 5000,
            id: 'printer_error'
          });
        }
      });
    }

    function prepareAddPrint(printAction, responseData) {
      if (responseData.product === 'Voucher') {
        return;
      }

      /**
  * Emits paied in ticket to product. On this event product
  * can modify ticket data for upcoming print event.
  *
  * @event "7T:Tickets.PrePrint"
  * @memberOf module:Tickets
  * @type {Object}
  * @property {String} printAction
  * @property {Object} responseData
  * @property {String} productId
  */
      $rootScope.$emit('7T:Tickets.PrePrint', {
        printAction: printAction,
        responseData: responseData,
        productId: responseData.product
      });
    }

    /**
  * Handle ticket errors on scm response and on http req fail
  * @param {object} ticketData
  * @param {boolean} [showMessage]
  */
    function handleTicketError(ticketData, showMessage) {
      // Set default err msg
      var errorMsg = $translate.instant('notifications.type_error');

      NabNotifications.closeNotificationWithId('ticket.pending_action');

      if (!ticketData) return errorMsg;

      // Map err message
      if (ticketData.serverResponse.httpCode === 403 && ticketData.serverResponse.code === 19002) {
        errorMsg = $translate.instant('notifications.payout_ticket_on_cash_register');
        ticketData.reason = errorMsg;
      } else if (ticketData.serverResponse.httpCode === 412 && ticketData.serverResponse.code === 1123100) {
        errorMsg = $translate.instant('notifications.retail_location_closed');
        ticketData.reason = errorMsg;
      }
      errorMsg = ticketData.reason || $translate.instant('notifications.type_error');

      if (showMessage === undefined || showMessage !== false) {
        // Otherwise, show regular notification
        NabNotifications.show({
          message: errorMsg,
          type: 'error',
          delay: 3000,
          id: errorMsg
        });
      }

      return errorMsg;
    }

    function formatMetaData(ticketGame) {
      var metaObj = MetadataService.get();
      var productInstance = $filter('filter')(metaObj.ticketMeta.sources, {
        type: 'productInstance'
      }, true)[0];

      metaObj.ticketMeta.cpvUuid = ticketGame.info.cpvUuid;
      metaObj.ticketMeta.product = ticketGame.id;
      metaObj.ticketMeta.uuid = ticketGame.info.uuid;
      // Update metadata source instance
      productInstance.uuid = ticketGame.info.uuid;

      return metaObj;
    }

    /**
      * Generates SevenTicket or Product ticket depending on settings and what is passed to function
      * @param {TicketClientRequest | SevenTicket | ProductTicket} ticketData
      * @param {string} [productId]
      * @returns {SevenTicket | ProductTicket}
      */
    function generateTicket(ticketData, productId) {
      let isV2Ticket = false;
      const foundProductId = productId || ticketData.product || ticketData.slip?.productDisplayId || ticketData.config?.group?.id;
      const productConfig = SevenClientConfig.getProductSettings(foundProductId);
      let ticket;

      if (ticketData instanceof ProductTicket || ticketData instanceof SevenTicket) {
        return ticketData;
      }

      isV2Ticket = (productConfig && productConfig.v2Ticket);

      if (isV2Ticket) {
        ticket = new ProductTicket();
        ticket.setData(ticketData);
      } else {
        // in old implementation product was optional so assign if not already there
        if (!ticketData.product) {
          ticketData.product = foundProductId;
        }
        ticket = new SevenTicket(ticketData);
      }

      return ticket;
    }

    return {

      /**
       *
       * @property {Object} data
       * @property {String} data.productId
       * @property {ProductTicketRequest | TicketClientRequest} data.ticket
       * @property {Number} data.ticket.stake - Total ticket stake
       * @property {Object} data.ticket.config - deprecated
       * @property {Object} data.ticket.config.group - deprecated
       * @property {String} data.ticket.config.group.id - Product id. Depreacted
       * @property {Object} data.ticketFormatted - Ticket data to be sent to backend. Deprecated, instead we use eventData.data.ticket.
       * @property {boolean} [data.showMessage=true]
       * @returns {Promise}
       */
      payinTicket: function (data) {
        const activeGameId = SevenGamesSvc.activeGame.id;
        const { showMessage } = data;
        // create local instance of ticket, we don't want to mess with data.ticket
        data.localTicket = this.generateTicket(data.ticket, activeGameId);
        data.localTicket.addRequestUuid(data.localTicket.requestUuid || uuidv4());
        const requestUuid = data.localTicket.getRequestUuid();
        return TicketValidationService.validatePayin(data, activeGameId)
          .then((validatedData) => {
            if (showMessage === undefined || showMessage !== false) {
              const params = {
                action: 'Add',
                errorResponses: []
              };
              HooksManager.run('BeforeTicketActionNotificationShow', params)
                .then(() => {
                  if (!params.errorResponses.length) {
                    NabNotifications.show({
                      message: nabTrans.translate('ticket.pending_add_action', {}, true),
                      type: 'info',
                      focus: false,
                      actions: false,
                      id: 'ticket.pending_action'
                    });
                  } else {
                    $log.debug('[7Terminal.Tickets] Skipping showing notification - BeforeTicketActionNotificationShow hook', {
                      ...params.errorResponses[0],
                      code: 'T_SKIP_SHOW_TICKET_ACTION_NOTIFICATION'
                    });
                  }
                })
                .catch((error) => {
                  $log.debug('[7Terminal.Tickets] Skipping showing notification - BeforeTicketActionNotificationShow hook', {
                    ...error,
                    code: 'T_SKIP_SHOW_TICKET_ACTION_NOTIFICATION'
                  });
                });
            }

            const { doTicketStatusCheck } = GravitySettings.getByKey(`module.${activeGameId}.ticketCheck`);
            const metadataObj = formatMetaData(SevenGamesSvc.activeGame);
            metadataObj.ticketMeta.requestUuid = requestUuid;
            data.metadata = metadataObj.ticketMeta;
            data.localTicket.action = 'Add';
            data.localTicket.addRequestUuid(requestUuid);
            return ticketManager.payinTicket(
              validatedData,
              { ticketRoutes: metadataObj.ticketRoutes, requestUuid },
              { ticketCheckerActive: doTicketStatusCheck !== false }
            ).then(
              /**
                * @param {Object} ticket
                * @param {Boolean} ticket.error
                * @param {String} ticket.reason - Friendly error message
                * @param {String} ticket.type
                * @param {Object} ticket.serverResponse - Server response object
                * @param {SevenTicket | ProductTicket} ticket.localTicket
                */
              function (res) {
                const responeseReqUuid = res.response?.data?.requestUuid || res.localTicket.getRequestUuid();
                const code = res.response?.data?.httpCode || res.response?.data?.code || res.response?.status;
                if (res.error) {
                  const reason = handleTicketError(res);
                  NabNotifications.closeNotificationWithId('ticket.pending_action');
                  $log.info('[7Terminal.Tickets] Handling error from Betslip API pay in request.', {
                    code: 'T_PAYIN_HANDLE_REQUEST_FAILED',
                    product_displayid: activeGameId,
                    response: res,
                    error: [res.serverResponse],
                    localTicket: createLogDataFromTicket(res.localTicket),
                    request_id: requestUuid,
                    upstream_message: res.reason,
                    upstream_code: code
                  });
                  googleAnalyticsService.trackEvent('Ticket_Payin', {
                    module: activeGameId,
                    event: 'Ticket Payin Fail'
                  });
                  $rootScope.$emit('7T:Tickets.PayingFailed', {
                    productId: activeGameId,
                    errors: [res.serverResponse],
                    ticket: res.request,
                    errorMsg: reason
                  });
                  return {
                    message: res.reason,
                    code,
                    requestUuid,
                    productDisplayId: activeGameId
                  };
                }
                /**
                  * Emits if sending ticket to backend succeeded
                  *
                  * @event "7T:Tickets.PayingSuccess"
                  * @memberOf module:Tickets
                  * @type {Object}
                  * @property {String} productId
                  * @property {Object} ticket
                  * @property {String} ticket.status
                  * @property {String} ticket.requestUuid
                  */
                $log.debug('[7Terminal.Tickets] Handling successful pay in from Betslip API', {
                  code: 'T_TICKETS_PAYIN_HANDLE_REQUEST_SUCCESS',
                  product_displayid: activeGameId,
                  request_id: responeseReqUuid,
                  ticket_code: res?.response?.data?.id || '',
                  ticket: res?.response,
                  localTicket: res.localTicket
                });
                $rootScope.$emit('7T:Tickets.PayingSuccess', {
                  productId: activeGameId,
                  ticket: {
                    status: res?.response?.data?.status || res?.response?.status,
                    requestUuid: responeseReqUuid
                  }
                });
                return {
                  ticket: res.localTicket,
                  requestUuid
                };
              }
            );
          }).catch((err) => {
            NabNotifications.show({
              message: err.message,
              type: 'error',
              closeDisabled: false,
              delay: 3000
            });

            $log.error('[7Terminal.Tickets] Payin validation error detected.', {
              code: 'T_TICKET_PAYIN_VALIDATION_ERR',
              upstream_message: err.message,
              upstream_code: err.code,
              request_id: requestUuid,
              product_displayid: activeGameId,
              err: err
            });
            googleAnalyticsService.trackEvent('Ticket_Payin', {
              module: activeGameId,
              event: 'Ticket Payin Fail'
            });

            return {
              message: err.message,
              code: err.code,
              requestUuid,
              productDisplayId: activeGameId
            };
          });
      },

      /**
      * Generates SevenTicket or Product ticket depending on settings and what is passed to function
      * @param {TicketClientRequest | SevenTicket | ProductTicket} ticketData
      * @returns {SevenTicket | ProductTicket}
      */
      generateTicket: generateTicket
    };
  }
})();
