import { defineStore } from 'pinia';
import { ref } from 'vue';
import type ClientGame from './interfaces/ClientGame';
import type GameConfig from './interfaces/GameConfig';

export const useClientGamesStore = defineStore('clientGamesStore', () => {
  const games = ref<Array<ClientGame>>([]);

  const registerGame = (game: ClientGame) => {
    games.value.push(game);
  };

  const getGameById = (id: string): ClientGame | undefined => games.value
    .find((game) => game.id === id);

  const getGames = () => games.value;

  const addGameConfig = (id: string, gameConfig: GameConfig) => {
    const game = getGameById(id);
    if (game) {
      game.gameConfig = gameConfig;
    }
  };
  const getGamesByPriority = () => games.value
    .sort((a, b) => b.info.priority - a.info.priority);

  return {
    games,
    registerGame,
    getGameById,
    getGames,
    addGameConfig,
    getGamesByPriority,
  };
});
