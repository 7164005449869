import { useSevenStore } from '@/modules/seven';
import axiosInstance from './axiosInstance';
import SidResponse from './SidResponse';

const getSequentialId = (ticketId: string) => {
  const sevenStore = useSevenStore();

  return axiosInstance.post<SidResponse>('/api/v1/tickets/generateId', {
    tenantId: sevenStore.tenant.uuid,
    ticketHash: ticketId,
  });
};

export {
  getSequentialId,
};
